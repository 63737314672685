import { ApiClient } from "../../../lib/ApiClient";
import { addMutateUrlPrefix, useGenericSWR } from "../../../lib/useGenericSWR";
import * as Yup from 'yup';

export const AnnouncementApiEndpoint = 'api/v1/admin/Announcement';

let formIteration = 0;

const getAnnouncementsDefaultItem = () => {
    formIteration = formIteration + 1;
    return {
        formIteration: "new" + formIteration,
        id: 0,
        cepOrgId: 0,
        header: '',
        content: '',
        isPublished: false,
        emailsSent: 0,
        lastEmailsSent: null,
        isSendEmails: false,
        isPinned: false
    }
};

const announcementsValidationSchema = Yup.object().shape({
    header: Yup.string()
        .required("header")
        .max(255, 'Max of 255 characters'),
});

const apiClient = new ApiClient();
apiClient.getDefaultItem = () => getAnnouncementsDefaultItem();
apiClient.getObjectName = () => {
    return "Announcement";
};

const useAnnouncement = (id, swrOptions = {}) => {
    // SWR will prevent duplicate requests
    // It will also maintain data state as long as your are using the same urls for your requests
    return useGenericSWR(AnnouncementApiEndpoint + "/" + id, apiClient, { ...swrOptions });
};

const useAnnouncements = (filter, swrOptions = {}) => {
    // SWR will prevent duplicate requests
    // It will also maintain data state as long as your are using the same urls for your requests
    const params = new URLSearchParams();
    params.append('filter', JSON.stringify(filter));

    return useGenericSWR(AnnouncementApiEndpoint + "?" + params.toString(), apiClient, { ...swrOptions });
};

const saveAnnouncement = (Announcement) => {
    if (Announcement.id === 0) {
        return insertAnnouncement(Announcement);
    }

    return updateAnnouncement(Announcement);
}

const insertAnnouncement = (Announcement) => {
    return apiClient.poster(AnnouncementApiEndpoint, Announcement)
        .then((result) => {
            addMutateUrlPrefix("api/v1/admin/Announcement?");
            addMutateUrlPrefix("api/v1/UserNotification?");
            return result;
        });
};

const updateAnnouncement = (Announcement) => {
    return apiClient.putter(AnnouncementApiEndpoint, Announcement)
        .then((result) => {
            addMutateUrlPrefix("api/v1/admin/Announcement?");
            addMutateUrlPrefix("api/v1/UserNotification?");
            return result;
        });
};

const deleteAnnouncement = (Announcement) => {
    return apiClient.deleter(AnnouncementApiEndpoint + "/" + Announcement.id)
        .then((result) => {
            addMutateUrlPrefix("api/v1/admin/Announcement?");
            addMutateUrlPrefix("api/v1/UserNotification?");
            return result;
        });
};

export {
    useAnnouncement,
    useAnnouncements,
    saveAnnouncement,
    insertAnnouncement,
    updateAnnouncement,
    deleteAnnouncement,
    announcementsValidationSchema,
    getAnnouncementsDefaultItem,
};