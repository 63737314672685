import React, { useState, useLayoutEffect, useRef } from 'react';
import { DataGridPro } from '@mui/x-data-grid-pro';
import { useUserAccounts } from './UserAccountAPIEndpoints';
import { dateFormatter } from 'lib/Formatters';
import { getWholeRow } from 'lib/DataGridHelpers';
import { UcFullPage } from 'components/common/UcFullPage';
import { UcIconButtons } from 'components/common/UcIconButtons';
import { useTranslation } from "react-i18next";
import DeleteIcon from '@mui/icons-material/Delete';

export const UserAccountGrid = (props) => {
    const {t} = useTranslation();
    const [generalSearchInput] = useState(props.generalSearch || "");
    const initialSort = [{ field: "id", sort: "asc" }];
    const sort = props.sort || initialSort;

    const initialDataState = props.dataState || { skip: 0, take: 10, };

    const [filter, setFilter] = useState({
        accountId: parseInt(props.accountId),
        userId: parseInt(props.userId),
        take: initialDataState.take,
        skip: initialDataState.skip,
        sort: sort,
        generalSearch: generalSearchInput,
    });

    let initialFilterModel = {
        items: [
            {columnField: 'id', operatorValue:'contains', value: ''}
        ],
        linkOperator: 'and',
    };

    if (generalSearchInput) {
        initialFilterModel = {
            items: [
                {columnField: 'id', operatorValue:'contains', value: generalSearchInput}
            ],
            linkOperator: 'and',
        }
    }

    const [filterModel] = useState(initialFilterModel);
    const needsDataUpdate = useRef("");

    let defaultDataItems = {
        columnVisibilityModel: {
            id: true,
            cepOrgId: false,
            content: false,
            createdBy: false,
            updatedBy: false,
            sortPriority: false,
            extAccountId: false,
            extSource: false,
        },
        columns: [
            {field: 'sortPriority', headerName: t('Sort Priority')},
            {field: 'username', headerName: t('Ownership'),  width: 250,
                valueFormatter: (params) => {
                    if (params.value === props.user?.email) {
                        return t("This User");
                    }
                    else {
                        return params.value;
                    }
                }
            },
            {field: 'accountName', headerName: t('Account Name'), width: 250,
                valueGetter: getWholeRow,
                valueFormatter: (params) => {
                    return params.value.accountName + (params.value.accountAddress !== undefined ? " - " + params.value.accountAddress : "")
                }
            },
            {field: 'extAccountId', headerName: t('Account Id'), width: 200},
            {field: 'extAccountNumber', headerName: t('Account Number'), width: 150 },
            {field: 'metersJson', headerName: t('Meter Number'), flex: 1, minWidth: 200, valueFormatter: (params) => {
                   const metersJson = params.value !== undefined ? JSON.parse(params.value) : [];
                   const meterNumbers = metersJson.map(r => r.serialNo);
                   return meterNumbers.join(", ");
            }},
            {field: 'extSource', headerName: t('Source') },
            {field: 'id', headerName: '', width: 60,
                valueGetter: getWholeRow,
                renderCell: (params) => {
                    let match = undefined;

                    if (params.value.delegatesJson !== undefined) {
                        match = JSON.parse(params.value.delegatesJson).filter((value) => {
                            return value.email === props.user?.email;
                        })[0];
                    }

                    const actions = [
                        {
                            title: t('Delete Account Access'),
                            icon: <DeleteIcon />,
                            color: 'error',
                            isHidden: false,
                            isDisabled: false,
                            requiredPermission: 'UserAccount:Delete',
                            onClick: () => {
                                if (match !== undefined) {
                                    props.onDeleteSharedWithUser(match);
                                }
                                else {
                                    props.onDeleteItem(params.value);
                                }
                            }
                        }
                    ];

                    return (
                        <UcIconButtons actions={actions} />
                    );
                }
            }
        ],
        rows: [],
        rowCount: -1,
    };
    const [dataItems, setDataItems] = useState(defaultDataItems);

    const defaultSelectedItem = { dataItem: {}, selectedId: 0 };
    const [selectedItem, setSelectedItem] = useState(defaultSelectedItem);

    const dataReceived = (swrResult) => {
        var receivedDataItems = swrResult.result;

        var selectedDataItems = receivedDataItems.map((item) => {
            item.startDate = dateFormatter(item.startDate, true);
            item.endDate = dateFormatter(item.endDate, true);

            return { ...item, selected: item.id === selectedItem.selectedId };
        });
        selectedDataItems.iteration = receivedDataItems.iteration;
        selectedDataItems.rowCount = swrResult.rowCount;
        if (selectedDataItems.length > selectedDataItems.rowCount) {
            selectedDataItems.rowCount = selectedDataItems.length;
        }

        setDataItems({...defaultDataItems, rows: [...receivedDataItems], rowCount: swrResult.rowCount});
    };

    const swrUserAccounts = useUserAccounts(filter); //Pass the datastate directly as the filter.  The API will adjust filters, orderbys ect.
    if (!swrUserAccounts.result && defaultDataItems.rowCount !== -1)
    {
        setDataItems(defaultDataItems);
    }

    useLayoutEffect(() => {
        if (swrUserAccounts.result) {
            var filterString = JSON.stringify(filter);
            if (needsDataUpdate.current !== filterString) {
                needsDataUpdate.current = filterString;
                dataReceived(swrUserAccounts);
            }
        }
    });

    const updateFilter = (newFilter) => {
        setFilter(newFilter);
        props.onFilterChange(newFilter);
    };

    return (
        <UcFullPage {...props}>
            <DataGridPro
                disableColumnFilter={true}
                disableColumnSelector={true}
                autoHeight={!props.isFullPage}
                paginationModel={{page: filter.skip / filter.take, pageSize: filter.take}}
                onPaginationModelChange={(model) => updateFilter({ ...filter, skip: model.page * model.pageSize, take: model.pageSize })}
                sortingMode='server'
                paginationMode='server'
                filterMode='server'
                sortModel={filter.sort}
                onSortModelChange={(model) => updateFilter({...filter, sort: model})}
                filterModel={filterModel}

                rowsPerPageOptions={[5, 10, 20]}
                onRowSelectionModelChange={(newSelectionModel) => {
                    if (newSelectionModel.length !== 1)
                    {
                        setSelectedItem(defaultSelectedItem);
                        props.onSelected(defaultSelectedItem.dataItem);
                        return;
                    }
                    const selectedId = newSelectionModel[0];
                    var selectedDataItem = dataItems.rows.filter(obj => {return obj.id === selectedId});
                    if (selectedDataItem.length !== 1)
                    {
                        setSelectedItem(defaultSelectedItem);
                        props.onSelected(defaultSelectedItem.dataItem);
                        return;
                    }
                    selectedDataItem = selectedDataItem[0];
                    var newlySelectedItem = { dataItem: selectedDataItem, selectedId: selectedId };

                    setSelectedItem(newlySelectedItem);
                    props.onSelected(newlySelectedItem.dataItem);
                }}
                pagination
                {...dataItems}
                initialState={{ pinnedColumns: { right: ['id'] } }}
            />
        </UcFullPage>
    );
}

UserAccountGrid.defaultProps = {
    isFullPage: true,
    onSelected: (selectedItem) => { console.log("onSelected not implemented", selectedItem); },
    onFilterChange: (filter) => { console.log("onFilterChange not implemented", filter); },
};
