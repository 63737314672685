import React, { useContext } from 'react';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import Link from '@mui/material/Link';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import IconButton from '@mui/material/IconButton';
import { UcThemeContext } from 'lib/UcThemeProvider';
import '../ResponsiveAppBar.scss';
import NavMenu from '../NavMenu';
import { UcDrawerHeader } from '../common/UcDrawerHeader';
import { PageContext } from 'lib/PageProvider';
import { ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useResponsive } from 'lib/useResponsive';
import { getUrl } from 'domain';
import './UcCustomLogo.scss';

const drawerWidth = 270;

export const UcSharedDrawer = (props) => {
    const { t } = useTranslation();
    // eslint-disable-next-line no-unused-vars
    const [pageState, pageDispatch] = useContext(PageContext);
    // eslint-disable-next-line no-unused-vars
    const [themeState, themeDispatch] = useContext(UcThemeContext);
    // eslint-disable-next-line no-unused-vars
    const [open, isOpen] = React.useState(false);
    const { isMobile, isTablet} = useResponsive();

    const handleDrawerClose = () => {
        if (isMobile || isTablet) {
            props.onChange(false);
        }
    };

    return(
        <div className="scrollbar UcSharedDrawer" style={{ overflowY: "auto", overflowX: "hidden" }}>
            <UcDrawerHeader isMobile={isMobile}>
                <Link href={getUrl("/")} style={{ width: "100%", display: 'flex', justifyContent: 'center' }}>
                    <img
                        src={`${process.env.PUBLIC_URL}/themes/${themeState.logo}`}
                        alt="logo" className="ucCustomLogo"
                        style={{
                            padding: "10px 0 10px 20px",
                            margin: "auto"
                        }}
                    />
                </Link>
                <IconButton onClick={() => { props.onHeaderClick(); }}>
                    {props.isOpen ? <ChevronLeftIcon /> : <ChevronRightIcon />}
                </IconButton>
            </UcDrawerHeader>
            <Box
                sx={{ width: drawerWidth }}
                role="presentation"
                onClick={handleDrawerClose}
                onKeyDown={handleDrawerClose}
            >
                <List disablePadding>
                    <NavMenu isOpen={props.isOpen} onHeaderClick={props.onHeaderClick}/>
                </List>
                {pageState.tabs && open &&
                    <List disablePadding>
                        {pageState.tabs.map((tab, index) => (
                            <ListItemButton
                                key={"tabs-" + tab.title}
                                title={t(tab.title)}
                                selected={pageState.tabValue === tab.title}
                                onClick={(event) => {
                                    pageState.onTabChange(event, tab.title);
                                    handleDrawerClose();
                                }}>
                                <ListItemIcon title={t(tab.title)}>{tab.icon}</ListItemIcon>
                                <ListItemText primary={tab.title} />
                            </ListItemButton>
                        ))}
                    </List>
                }
            </Box>
    </div>
    )
};

export default UcSharedDrawer;