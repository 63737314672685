import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import {
    Grid,
    Typography,
    Button,
    Alert,
    Box,
    Snackbar,
    useTheme
} from "@mui/material";
import { MyBillsAPIWrapper } from "components/user/bills/MyBillsAPIEndpoint";
import { UcLoading } from "../common/UcLoading";
import { AccountContext } from "../../lib/AccountProvider";
import { UcProgress } from "../common/UcProgress";
// @ts-ignore
import { stringDateFormatter } from "../../lib/Formatters";
import EnsureUserAndModule from "../auth/EnsureUserAndModule";
import { Bill } from "../user/bills/MyBills.interfaces";
import UserPayMyBillDialog from "./UserPayMyBillDialog";
// @ts-ignore
import { useHasModule } from "components/auth/PermissionApiEndpoint";
import EnergySavingsLeafOutlinedIcon from '@mui/icons-material/EnergySavingsLeafOutlined';
import UserBillOptionsWidgetErrorDialog from "./UserBillOptionsWidgetErrorDialog";
import UserBillOptionsWidgetDialog from "./UserBillOptionsWidgetDialog";

export default function UserBillsWidget(props: any) {
    const { t } = useTranslation();
    const theme: any = useTheme();
    const { account } = useContext(AccountContext);
    const [isFetching, setIsFetching] = React.useState(true);
    const [billData, setBillData] = React.useState<Bill[] | null>(null);
    const [mostRecentBill, setMostRecentBill] = React.useState<Bill | null>(null);
    let noBillsAlert = false;
    const hasPaymentusOneTimePaymentModule = useHasModule("PaymentusOneTimePayment");

    const [hasError, setHasError] = useState(false);
    const [progress, setProgress] = useState({ current: 0, total: 100 });

    const [openUserPayMyBillDialog, setOpenUserPayMyBillDialog] = React.useState(false);

    const handleClickOpenUserPayMyBillDialog = () => {
        setOpenUserPayMyBillDialog(true);
    };

    const handleCloseUserPayMyBillDialog = () => {
        setOpenUserPayMyBillDialog(false);
    };

    React.useEffect(() => {
        if (
            !isFetching &&
            !hasError &&
            billData &&
            Object.keys(billData).length > 0
        ) {
            setMostRecentBill(
                billData.reduce(function (r: any, a: any) {
                    return r.statementDate > a.statementDate ? r : a;
                })
            );
        }
    }, [isFetching, hasError, billData]);

    if (!isFetching &&
        !hasError &&
        billData &&
        Object.keys(billData).length <= 0) {
        noBillsAlert = true;
    }

    const [dummyPaperlessBillingIsSubscribed, setDummyPaperlessBillingIsSubscribed] = React.useState(false);
    const [openPaperlessBillingDialog, setOpenPaperlessBillingDialog] = React.useState(false);
    const [openPaperlessBillingErrorDialog, setOpenPaperlessBillingErrorDialog] = React.useState(false);
    const [openSuccessSnackbar, setOpenSuccessSnackbar] = React.useState(false);

    const handleClickOpenPaperlessBillingDialog = () => {
        setOpenPaperlessBillingDialog(true);
    };

    const handleClosePaperlessBillingDialog = () => {
        setOpenPaperlessBillingDialog(false);
    };

    const handleClosePaperlessBillingErrorDialog = () => {
        setOpenPaperlessBillingErrorDialog(false);
    };

    const handleOnChangePaperlessBillingStatus = () => {
        // This setTimeout won't be needed when we are hitting the endpoint
        setTimeout(() => {
            setDummyPaperlessBillingIsSubscribed(
                !dummyPaperlessBillingIsSubscribed
            );
        }, 100);
    };

    const handleOnSuccessPaperlessBillingStatus = () => {
        setOpenPaperlessBillingDialog(false);
        setOpenSuccessSnackbar(true);
        handleOnChangePaperlessBillingStatus();
    };

    const handleOnErrorPaperlessBillingStatus = () => {
        setOpenPaperlessBillingDialog(false);
        setOpenPaperlessBillingErrorDialog(true);
    };

    const handleCloseSuccessSnackbar = (reason: any) => {
        if (reason === "clickaway") {
            return;
        }

        setOpenSuccessSnackbar(false);
    };

    // TODO : Balance Forward is a hard-coded currently as we do not receive this value
    return (
        <EnsureUserAndModule
            permissions={["Org:Modify"]}
            modules={["Billing"]}
        >
            <div
                className="dataCyId"
                data-cy-id="billingSummaryDashboard_widget"
            />
            <UcLoading
                hasFade={false}
                hasError={hasError}
                isLoading={isFetching}
                height={"20vh"}
                errorMessage={t(
                    "An error occurred retrieving account bills."
                )}
                loadingRender={<UcProgress {...progress} />}
            >
                {noBillsAlert ? (
                    <Alert severity="warning">
                        {t(
                            "There are no bills available for your account."
                        )}
                    </Alert>
                ) : (
                        <>
                        <Grid container spacing={0}>
                            <Grid item xs={12}>
                                <Box
                                    sx={{
                                        display: "flex",
                                    }}
                                    title={t("Balance Forward $" + (mostRecentBill?.balanceForward ?? "0.00"))}
                                >
                                    <Typography variant="h6" width={"100%"}>
                                        {t("Balance Forward")}
                                    </Typography>
                                    <Typography
                                        variant="h6"
                                        width={"100%"}
                                        align="right"
                                    >
                                        ${mostRecentBill?.balanceForward ?? "0.00"}
                                    </Typography>
                                </Box>
                                <Box
                                    sx={{
                                        display: "flex",
                                    }}
                                    title={t("Subtotal $") + mostRecentBill?.totalAmountDue}
                                >
                                    <Typography variant="h6" width="200px">
                                        {t("Subtotal")}
                                    </Typography>
                                    <Typography
                                        variant="h6"
                                        width={"100%"}
                                        align="right"
                                    >
                                        ${mostRecentBill?.totalAmountDue}
                                    </Typography>
                                </Box>
                            </Grid>
                        </Grid>
                        <Grid container spacing={0}>
                            <Grid item xs={12}>
                                <Box
                                    sx={{
                                        display: "flex",
                                        mt: 3
                                    }}
                                    title={t("Total $") + mostRecentBill?.totalAmountDue}
                                >
                                    <Typography variant="h5" width={"100%"}>
                                        {t("Total")}
                                    </Typography>
                                    <Typography
                                        variant="h5"
                                        width={"100%"}
                                        align="right"
                                        sx={{ fontWeight: "bold" }}
                                    >
                                        ${mostRecentBill?.totalAmountDue}
                                    </Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={12}>                              
                                <Typography
                                    width={"100%"}
                                    align="left"
                                    fontSize="14px"
                                    sx={{ color: "grey" }}
                                    title={t("Due Date: ") + stringDateFormatter(mostRecentBill?.dueDate)}
                                >
                                    {t("Due Date: ")}{stringDateFormatter(mostRecentBill?.dueDate)}
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid
                            container
                            spacing={2}
                            sx={{
                                mt: 2
                            }}
                        >
                            <Grid item xs={12} md={hasPaymentusOneTimePaymentModule ? 6 : 12}>
                                <Button
                                    variant="outlined"
                                    color="info"
                                    data-cy-id="my_bills_widget_download_button"
                                    title={t("View my bill")}
                                    href={`api/v1/MyBills/getPDF?accountNumber=${mostRecentBill?.accountNumber}`}
                                    target="_blank"
                                    size="large"
                                    fullWidth
                                >
                                    {t("View my bill")}
                                </Button>
                            </Grid>
                            {hasPaymentusOneTimePaymentModule && (
                                <Grid item xs={12} md={6}>
                                    <Button
                                        variant="contained"
                                        data-cy-id="my_bills_widget_pay_button"
                                        title={t("Pay bill")}
                                        size="large"
                                        sx={{
                                            color: "#fff",
                                            display: "inline-flex",
                                        }}
                                        fullWidth
                                        onClick={handleClickOpenUserPayMyBillDialog}
                                    >
                                        {t("Pay my bill")}
                                    </Button>
                                    <UserPayMyBillDialog
                                        open={openUserPayMyBillDialog}
                                        handleClose={handleCloseUserPayMyBillDialog}
                                    />
                                </Grid>
                            )}    
                            <Grid item xs={12}>
                                <Box
                                    title={(dummyPaperlessBillingIsSubscribed ? t("You're saving paper: ") : t("Save paper: ")) +
                                        (dummyPaperlessBillingIsSubscribed ? t("Unsubscribe from Paperless Billing") : t("Subscribe to Paperless Billing"))}
                                    sx={{
                                        display: "flex",
                                        borderRadius: 1,
                                        bgcolor: theme.props?.Commodity?.kWhColor,
                                        padding: '8px !important',
                                        alignItems: 'center',
                                        mt: 2,
                                        justifyContent: 'center'
                                    }}
                                >
                                        <EnergySavingsLeafOutlinedIcon sx={{ color: "#fff", mr: 2, ml: 1}} />
                                        <Typography sx={{ color: "#fff", mr: 1 }}>
                                            {dummyPaperlessBillingIsSubscribed ? t("You're saving paper: ") : t("Save paper: ")}
                                            <span
                                                style={{ cursor: 'pointer', textDecoration: 'underline' }}
                                                onClick={handleClickOpenPaperlessBillingDialog}
                                                data-cy-id="my_bills_widget_subscribe_button"
                                            >
                                                {dummyPaperlessBillingIsSubscribed ? t("Unsubscribe from Paperless Billing") : t("Subscribe to Paperless Billing")}
                                            </span>   
                                    </Typography>
                                </Box>                             
                            </Grid>
                        </Grid>
                    </>
                )}
            </UcLoading>
            {account?.id !== null && (
                <MyBillsAPIWrapper
                    readyForData={!hasError}
                    onDataCompleted={(newData) => {
                        setIsFetching(false);
                        setBillData(newData);
                        setHasError(false);
                    }}
                    onProgress={(progressLocal) => {
                        if (progressLocal.current !== progress.current) {
                            setProgress({ ...progressLocal });
                        }
                    }}
                    onReset={() => {
                        setProgress({ current: 0, total: 100 });
                        setIsFetching(true);
                        setHasError(false);
                    }}
                    onError={(error) => {
                        console.error(error);
                        setIsFetching(false);
                        setHasError(true);
                    }}
                />
            )}
            <UserBillOptionsWidgetDialog
                open={openPaperlessBillingDialog}
                isSubscribed={dummyPaperlessBillingIsSubscribed}
                handleClose={handleClosePaperlessBillingDialog}
                handleSuccess={handleOnSuccessPaperlessBillingStatus}
                handleError={handleOnErrorPaperlessBillingStatus}
            />
            <UserBillOptionsWidgetErrorDialog
                open={openPaperlessBillingErrorDialog}
                isSubscribed={dummyPaperlessBillingIsSubscribed}
                handleClose={handleClosePaperlessBillingErrorDialog}
            />
            <Snackbar
                open={openSuccessSnackbar}
                autoHideDuration={3000}
                onClose={handleCloseSuccessSnackbar}
            >
                <Alert
                    onClose={handleCloseSuccessSnackbar}
                    severity="success"
                    variant="filled"
                    sx={{ width: "100%", color: "#fff" }}
                >
                    {t("Successfully {{status}} paperless billing!", { status: dummyPaperlessBillingIsSubscribed ? "subscribed to" : "unsubscribed from" })}
                </Alert>
            </Snackbar>
        </EnsureUserAndModule>
    );
}
