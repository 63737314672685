import { getBillingCycleesDefaultItem, billingCycleesValidationSchema } from './BillingCycleAPIEndpoints';
import PropTypes from 'prop-types';
import { Formik, Form, Field } from 'formik';
import { useRef, useEffect } from 'react';
import { TextField } from 'formik-mui';
import { UcEnumSelect } from 'components/common/UcEnumSelect';
import { useTranslation } from "react-i18next";

export const BillingCycleForm = (props) => {
    const itemToEdit = props.itemToEdit || getBillingCycleesDefaultItem();
    const dialogSubmit = useRef(props.dialogSubmit);
    const formRef = useRef();
    const { t } = useTranslation();

    useEffect(() => {
        if (props.dialogSubmit !== dialogSubmit.current) {
            dialogSubmit.current = props.dialogSubmit;
            if (formRef.current) {
                formRef.current.handleSubmit();
            }
        }
    });


    return (
        <>
            <Formik
                innerRef={formRef}
                initialValues={itemToEdit}
                validationSchema={billingCycleesValidationSchema}
                onSubmit={(values) => {
                    props.onSubmit(values);
                }}
            >
                <Form readOnly={props.readOnly}>
                    <Field
                        name={"name"}
                        component={TextField}
                        disabled={props.disabled}
                        label={t("Name")}
                    />
                    <Field
                        name={"description"}
                        component={TextField}
                        disabled={props.disabled}
                        label={t("Description")}
                    />
                    <Field
                    name={"status"}
                    >
                        {({ field: { value }, form: { setFieldValue } }) => (
                            <UcEnumSelect
                                value={value}
                                enumName="Status"
                                label={t("Status")}
                                disabled={props.disabled}
                                onChange={(_e, selectedValue) => setFieldValue("status", selectedValue.id)} />
                        )}
                    </Field>
                    <Field
                        name={"startDay"}
                        component={TextField}
                        disabled={props.disabled}
                        label={t("Start Day (1 - 28)")}
                    />
                    <Field
                        name={"u1020Key"}
                        component={TextField}
                        disabled={true}
                        label={t("U1020 Key")}
                    />

                </Form>
            </Formik>
        </>
    );
}

BillingCycleForm.defaultProps = {
    id: 0,
    dialogSubmit: 0,
    changeIteration: 0,
    onSubmit: (values) => { console.log("onSubmit not implemented", values); },
    onError: (error) => { console.error("onError not implemented", error); },
};

BillingCycleForm.propTypes = {
    id: PropTypes.number,
    changeIteration: PropTypes.number,
    dialogSubmit: PropTypes.number,
};