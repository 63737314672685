import { ensureLuxonDate, getDateFromMui } from "lib/Formatters";
import React from "react";
import { DateTime } from 'luxon';
import { MobileDateTimePicker } from '@mui/x-date-pickers/MobileDateTimePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import {TextField} from '@mui/material';

export const UcDateTimePickerFormik = (props) => {
    const { sendValueToForm, label } = props;
    const [value, setValue] = React.useState(DateTime.now().toISO());
 
    const handleChangeDate = (value) => {
        setValue(ensureLuxonDate(getDateFromMui(value)));
        sendValueToForm(ensureLuxonDate(getDateFromMui(value)));
    };

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <MobileDateTimePicker
                label={label}
                value={value}
                onChange={handleChangeDate}
                renderInput={(params) => <TextField {...params} />}
            />
        </LocalizationProvider>
    );
};