import React, { useState } from 'react';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { useTranslation } from "react-i18next";
import Menu from '@mui/material/Menu';
import IconButton from '@mui/material/IconButton';


export const UcCardMenu = (props) => {
    const { t } = useTranslation();
    const {actions} = props;
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);     
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <>
            <IconButton sx={{ color: 'primary.contrastText'}}
                onClick={handleClick}
                title={t('actions')} 
            >
                 <MoreVertIcon />
            </IconButton>
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}               
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
            >
                {actions}
            </Menu>
        </>
    )
}