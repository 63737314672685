import * as React from 'react';
import { getUsersDefaultItem, usersValidationSchema } from './UserAPIEndpoints';
import PropTypes from 'prop-types';
import { Formik, Form, Field } from 'formik';
import { useRef, useEffect } from 'react';
import { TextField } from 'formik-mui';
import { RoleMultiSelect } from 'components/admin/Roles/RoleMultiSelect';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { useHasPermission } from 'components/auth/PermissionApiEndpoint';

/**
 * This component handles the form for creating and editing
 * */
export const UserForm = (props) => {
    const itemToEdit = props.itemToEdit || getUsersDefaultItem();
    const dialogSubmit = useRef(props.dialogSubmit);
    const formRef = useRef();
    const userType = props.userType;
    const residentialRoles = ["Utility Residential"];
    const adminRoles = ["Utility Support", "Utility Admin"];
    
    useEffect(() => {
        if (props.dialogSubmit !== dialogSubmit.current) {
            dialogSubmit.current = props.dialogSubmit;
            if (formRef.current) {
                formRef.current.values.selectedRoleIds = roleIds;
                formRef.current.handleSubmit();
            }
        }
    });

    let defaultRole = [];
    const rolesArr = itemToEdit.rolesJson !== '' && itemToEdit.rolesJson !== undefined ? JSON.parse(itemToEdit.rolesJson) : [];
    const [roleNames, setRoleNames] = React.useState(rolesArr.map(r => r.name).length > 0 ? rolesArr.map(r => r.name) : defaultRole);
    const [roleIds, setRoleIds] = React.useState(rolesArr.map(r => r.id) || []);
    
    props.setNumOfSelectedRoles(roleIds.length);

    const handleRolesChange = (event, obj) => {
        const {
          target: { value },
        } = event;
        
        // This is for the multi-select text options
        setRoleNames(
          // On autofill we get a stringified value.
          typeof value === 'string' ? value.split(',') : value,
        );
        
        // This is how we get/set the role Ids for the user
        const objId = parseInt(obj.key.replace('.$', ''));
        let tempRoleIds = [...roleIds];
        if (tempRoleIds.includes(objId))
        {
            tempRoleIds.splice(tempRoleIds.indexOf(objId), 1);
        }
        else {
            tempRoleIds.push(objId);
        }

        props.setNumOfSelectedRoles(roleIds.length);
        setRoleIds([...tempRoleIds]);
    };

    return (
        <Formik
            innerRef={formRef}
            initialValues={itemToEdit}
            validationSchema={usersValidationSchema}
            onSubmit={(values) => {
                props.onSubmit(values);
            }}
        >
            <Form>
                {useHasPermission("RoleUser:Modify") && 
                    userType === 'SuperAdmin' && (
                        <Field
                        name={"roles"}
                        onChange={handleRolesChange}
                        roles={roleNames}
                        setRoleNames={setRoleNames}
                        setRoleIds={setRoleIds}
                        component={RoleMultiSelect}
                        label={"Roles"}
                        data-cy-id={"user_roles"}
                        allowDelete
                    />       
                    )
                }
                {useHasPermission("RoleUser:Modify") && 
                    userType === 'UtilityAdmin' && (
                        <Field
                        name={"roles"}
                        onChange={handleRolesChange}
                        roles={roleNames}
                        setRoleNames={setRoleNames}
                        setRoleIds={setRoleIds}
                        component={RoleMultiSelect}
                        label={"Roles"}
                        validRoles={adminRoles}
                        data-cy-id={"user_roles"}
                        allowDelete
                    />       
                    )
                }
                {useHasPermission("RoleUser:Modify") && 
                    userType === 'UtilitySupport' && (
                        <Field
                        name={"roles"}
                        onChange={handleRolesChange}
                        roles={roleNames}
                        setRoleNames={setRoleNames}
                        setRoleIds={setRoleIds}
                        component={RoleMultiSelect}
                        label={"Roles"}
                        validRoles={adminRoles}
                        defaultRole="Utility Support"
                        data-cy-id={"user_roles"}
                        allowDelete
                    />       
                    )
                }
                {userType === 'UtilityResidential' && (
                    <Field
                        name={"roles"}
                        onChange={handleRolesChange}
                        roles={roleNames}
                        setRoleNames={setRoleNames}
                        setRoleIds={setRoleIds}
                        component={RoleMultiSelect}
                        label={"Roles"}
                        validRoles={residentialRoles}
                        defaultRole="Utility Residential"
                        data-cy-id={"user_roles"}
                        disableSelect
                    />       
                    )
                }
                <Field
                    name={"email"}
                    component={TextField}
                    disabled={itemToEdit.id > 0}
                    label={"Email Address"}
                    data-cy-id={"user_email"}
                />
                <Field
                    name={"firstName"}
                    component={TextField}
                    label={"First Name"}
                    data-cy-id={"user_first_name"}
                />
                <Field
                    name={"middleName"}
                    component={TextField}
                    label={"Middle Name"}
                    data-cy-id={"user_middle_name"}
                />
                <Field
                    name={"lastName"}
                    component={TextField}
                    label={"Last Name"}
                    data-cy-id={"user_last_name"}
                />
                <Field
                    name={"address"}
                    component={TextField}
                    label={"Address"}
                    data-cy-id={"user_address"}
                />
                <Field
                    name={"phoneNumber"}
                    component={TextField}
                    label={"Phone Number"}
                    data-cy-id={"user_phone_number"}
                />
                <div>
                <Field
                name={"isSendInvite"}
                data-cy-id={"user_is_send_invite"}
                >
                    {({ field: { value }, form: { setFieldValue } }) => (
                        <FormControlLabel
                            control={
                                <Checkbox
                                    value={value}
                                    onChange={(e, value) => setFieldValue("isSendInvite", value)}
                                />
                            }
                            label={!itemToEdit.id ? ("Send Invite") : ("Re-send Invite")}
                        />
                    )}
                </Field>
                {(!!itemToEdit.id && 
                    <>
                        <Field
                            name={"isSendUserModified"}
                            data-cy-id={"user_is_send_user_modified"}
                        >
                            {({ field: { value }, form: { setFieldValue } }) => (
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            value={value}
                                            onChange={(e, value) => setFieldValue("isSendUserModified", value)}
                                        />
                                    }
                                    label="Notify User of Changes"
                                />
                            )}
                        </Field>
                    </>
                )} 
                </div>
            </Form>
        </Formik>
    );
}

UserForm.defaultProps = {
    id: 0,
    dialogSubmit: 0,
    changeIteration: 0,
    onSubmit: (values) => { console.log("onSubmit not implemented", values); },
    onError: (error) => { console.error("onError not implemented", error); },
};

UserForm.propTypes = {
    id: PropTypes.number,
    changeIteration: PropTypes.number,
    dialogSubmit: PropTypes.number,
};