import { updateUserPreference } from "./UserPreferenceApiEndpoint";
import { usePermissions } from "components/auth/PermissionApiEndpoint";
import { useState } from 'react';

const cachedPrefValue = [];

const useUserPref = ( userPreferenceName, defaultValue ) => {
    const permissionSwr = usePermissions();

    const setUserPref = (userPreferenceName, pref) => {
        updateUserPreference(userPreferenceName, pref.toString());
        cachedPrefValue[userPreferenceName] = pref.toString(); // set forceStorage to the actual value
        result.value = pref.toString();
        result.valueBool = pref.toString()?.toString().toLowerCase() === 'true';
        setResult({...result});
    }

    const initialValue = cachedPrefValue[userPreferenceName] || defaultValue;

    // Default the state to forceStorage[userPreferenceName]
    const [result, setResult] = useState(
    {
        value: initialValue,
        valueBool: initialValue?.toString().toLowerCase() === 'true',
        setUserPref: setUserPref,
    });


    if (cachedPrefValue[userPreferenceName] === undefined) {

        let preferenceValue = permissionSwr.result?.userPreferences?.find(x => x.name ===  userPreferenceName)?.value;

        if (preferenceValue !== undefined && preferenceValue !== null) {
            cachedPrefValue[userPreferenceName] = preferenceValue;
            result.value = preferenceValue;
            result.valueBool = preferenceValue?.toString().toLowerCase() === 'true';
            setResult({...result});
        }
    }

    return result;
};

export {
    useUserPref
}