import { ApiClient } from "../../../lib/ApiClient";
import { useGenericSWR } from "../../../lib/useGenericSWR";
import * as Yup from 'yup';

export const orgEmailSettingApiEndpoint = 'api/v1/admin/uc/OrgEmailSetting';

let formIteration = 0;

const getOrgEmailSettingsDefaultItem = () => {
    formIteration = formIteration + 1;
    return {
        formIteration: "new" + formIteration,
        id: 0,
        cepOrgId: null,
        fromEmail: '',
        fromName: '',
    }
};

const orgEmailSettingsValidationSchema = Yup.object().shape({
    fromEmail: Yup.string()
        .required("Required")
        .max(256, 'Max of 256 characters'),
    fromName: Yup.string()
        .required("Required")
        .max(256, 'Max of 256 characters'),
});

const apiClient = new ApiClient();
apiClient.getDefaultItem = () => getOrgEmailSettingsDefaultItem();
apiClient.getObjectName = () => {
    return "Email Settings";
};

const useOrgEmailSettings = (cepOrgId, swrOptions = {}) => {
    const filter = {
        take: 1,
        skip: 0,
        cepOrgId: cepOrgId,
    };

    const params = new URLSearchParams();
    params.append('filter', JSON.stringify(filter));
    return useGenericSWR(orgEmailSettingApiEndpoint + "?" + params.toString(), apiClient, { ...swrOptions });
};

const saveOrgEmailSettings = (OrgEmailSettings) => {
    if (OrgEmailSettings.id === 0) {
        return insertOrgEmailSettings(OrgEmailSettings);
    }
    return updateOrgEmailSettings(OrgEmailSettings);
}
const insertOrgEmailSettings = (OrgEmailSettings) => {
    return apiClient.poster(orgEmailSettingApiEndpoint, OrgEmailSettings);
};

const updateOrgEmailSettings = (OrgEmailSettings) => {
    return apiClient.putter(orgEmailSettingApiEndpoint, OrgEmailSettings);
};

export {
    useOrgEmailSettings,
    saveOrgEmailSettings,
    insertOrgEmailSettings,
    updateOrgEmailSettings,
    orgEmailSettingsValidationSchema,
    getOrgEmailSettingsDefaultItem,
};