import React, { useState, useLayoutEffect, useRef, useEffect } from 'react';
import { UcLoading } from 'components/common/UcLoading';
// @ts-ignore
import UcDataItemCards from '../../common/UcDataItemCards';
import { AccountContext } from '../../../lib/AccountProvider';
// @ts-ignore
import { dateFormatter } from '../../../lib/Formatters';
// @ts-ignore
import { getWholeRow } from '../../../lib/DataGridHelpers';
import LaunchIcon from '@mui/icons-material/Launch';
// @ts-ignore
import { UcIconButtonsWithLabel } from 'components/common/UcIconButtonsWithLabel';
import { useTranslation } from 'react-i18next';
import { MyBillsAPIWrapper } from 'components/user/bills/MyBillsAPIEndpoint';
import { UcProgress } from '../../common/UcProgress';
import RequestPageIcon from '@mui/icons-material/RequestPage';

interface MyBillsCardListProps {
    generalSearch: string | undefined | null,
    id: number | null,
    onSelected: (newValues: { dataItem: {}; selectedId: number; }) => void;
}

export const MyBillsCardList = (props: MyBillsCardListProps) => {
    const pageSize = 30;
    const initialSort = [{ field: "statementDate", sort: "desc" }];
    const initialDataState = { skip: 0, take: pageSize, };
    const needsDataUpdate = useRef("");
    const isDataLoaded = useRef(false);
    const { t } = useTranslation();
    const { account } = React.useContext(AccountContext);
    const [isFetching, setIsFetching] = React.useState(true);
    const [billData, setBillData] = React.useState(null);
    const [hasError, setHasError] = useState(false);
    const [progress, setProgress] = useState({ current: 0, total: 100 });

    const [loading, setLoading] = useState(false); // Loading state
    const observerRef = useRef(null); // Ref for observer

    const actions = React.useMemo(() => {
        return [
            {
                title: t('Open Bill'),
                icon: <LaunchIcon data-cy-id={"myBillsOpenButton"} />,
                label: t('Open Bill'),
                labelSx: { display: 'inline-block', width: '100%', ml: 1, textAlign: 'justify' },
                color: 'primary',
                isHidden: false,
                isDisabled: false,
                requiredPermission: 'Org:Modify',
                href: "",
                target: '_blank',
            },
        ];
    }, [t]);
    
    const downloadActionHandler = (itemValue: any) => {
        actions[0]['href'] = `api/v1/MyBills/getPDF?accountNumber=${itemValue?.accountNumber}`;

        return (
            <UcIconButtonsWithLabel actions={actions} />
        );
    }

    let defaultDataItems = React.useMemo(() => {
        return {
            columns: [
                { field: 'accountAddress', headerName: t('Address'), width: 200 },
                { field: 'accountNumber', headerName: t('Account Number'), width: 200, cardColumnWidth: 12 },
                { field: 'statementDate', headerName: t('Statement Date'), width: 150, cardColumnWidth: 12, valueFormatter: (params: any) => { return dateFormatter(params.value, true); } },
                { field: 'dueDate', headerName: t('Bill Due Date'), width: 150, cardColumnWidth: 12, valueFormatter: (params: any) => { return dateFormatter(params.value, true); } },
                { field: 'balanceForward', type: 'number', headerName: t('Balance Forward'), width: 150, cardColumnWidth: 12 },
                { field: 'totalAmountDue', type: 'number', headerName: t('Amount Due'), width: 150, cardColumnWidth: 12, valueFormatter: (params: any) => { return (Math.round(params.value * 100) / 100).toFixed(2); } },
                {
                    field: 'open', headerName: t('Open'), width: 100, cardHide: true,
                    valueGetter: getWholeRow,
                    renderCell: (params: any) => {
                        actions[0]['href'] = `api/v1/MyBills/getPDF?accountNumber=${params.value}`;

                        return (
                            <UcIconButtonsWithLabel actions={actions} />
                        );
                    }
                }
            ],
            cardHeaderField: { field: "statementDate", valueFormatter: (params: any) => { return dateFormatter(params.value, true); } } ,
            rows: [],
            rowCount: -1,
        }
    }, [t, actions]);

    const [dataItems, setDataItems] = useState<any>(defaultDataItems);
    const [page, setPage] = useState(1);
    const [hasMore, setHasMore] = useState(true);

    const [filter, setFilter] = useState({
        take: initialDataState.take,
        skip: initialDataState.skip,
        sort: initialSort,
        generalSearch: props.generalSearch,
    });

    useLayoutEffect(() => {
        const dataReceived = (swrResult: any) => {
            let newItems = swrResult;
            setDataItems({...defaultDataItems, rows: [...dataItems.rows, ...newItems], rowCount: swrResult.rowCount});
            if (newItems.length === 0 || newItems.length < pageSize) {
                setHasMore(false);
            }
        };

        setLoading(true);
        if (billData) {
            isDataLoaded.current = true;
            let filterString = JSON.stringify(filter);
            if (needsDataUpdate.current !== filterString) {
                needsDataUpdate.current = filterString;
                dataReceived(billData);
            }
            setLoading(false);
        }
        
    }, [billData, filter, dataItems.rows, defaultDataItems]);

    useEffect(() => {
        const fetchData = () => {
            let newPage = page + 1;
            setPage(newPage);

            let newItemAmount = newPage * pageSize - pageSize;
            let newFilter = {...filter, skip: newItemAmount, take: pageSize}
            setFilter(newFilter);
        };

        if (loading || !hasMore) return; 

        const observer = new IntersectionObserver(
        (entries) => {
            if (entries[0].isIntersecting) { //check if the viewport intersects the obeserverRef div
            fetchData();
            }
        },
        { threshold: 0.5 } 
        );

        if (observerRef.current) {
            observer.observe(observerRef.current); 
        }

        return () => observer.disconnect(); 
    }, [page, loading, hasMore, filter]);

 
    return (
        <>
            <UcLoading hasFade={false}
                hasError={hasError}
                isLoading={isFetching}
                height={'20vh'}
                loadingRender={<UcProgress {...progress} />}
            >
                <UcDataItemCards
                    loading={!isDataLoaded.current}
                    dataItems={dataItems}
                    actionsHandler={downloadActionHandler}
                    avatarDefault={<RequestPageIcon />}
                />
            </UcLoading>
            {account?.id !== null && (
                <MyBillsAPIWrapper // TODO: remove wrapper and use UcInfiniteScrollList instead
                    readyForData={!hasError}
                    onDataCompleted={(newData) => {
                        setIsFetching(false);
                        setBillData(newData);
                        setHasError(false);
                    }}
                    onProgress={(progressLocal) => {
                        if (progressLocal.current !== progress.current) {
                            setProgress({ ...progressLocal });
                        }
                    }}
                    onReset={() => {
                        setProgress({ current: 0, total: 100 });
                        setIsFetching(true);
                        setHasError(false);
                    }}
                    onError={(error) => {
                        console.error(error);
                        setIsFetching(false);
                        setHasError(true);
                    }}
                />
            )}
        </>
    );
}
