import { Slide } from "@mui/material";
import PropTypes from 'prop-types';
import useScrollTrigger from '@mui/material/useScrollTrigger';

export const UcHideOnScroll = (props) => {
    const { children } = props;

    const trigger = useScrollTrigger();

    return (
      <Slide appear={false} direction="down" in={!trigger}>
        {children}
      </Slide>
    );
}

UcHideOnScroll.propTypes = {
    children: PropTypes.element.isRequired,
};