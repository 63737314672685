// @ts-ignore
import { ApiClient } from "lib/ApiClient";
import { useGenericSWR } from "lib/useGenericSWR";
import { SwrOptions } from "lib/useGenericSWR.interfaces";

const apiClient = new ApiClient();
const apiEndpoint = 'api/v1/Paymentus';

const useOneTimePayment = (accountId: any, swrOptions = {} as SwrOptions) => {
    const params = new URLSearchParams();
    params.append('accountId', accountId);

    let url = apiEndpoint + "/oneTimePayment?" + params.toString();
    if (accountId === null) {
        url = "";
    }
    return useGenericSWR(url, apiClient, { ...swrOptions });
}

const useMyWallet = (swrOptions = {} as SwrOptions) => {
    const params = new URLSearchParams();
    let url = apiEndpoint + "/wallet?" + params.toString();

    return useGenericSWR(url, apiClient, { ...swrOptions });
}

const useAutoPay = (swrOptions = {} as SwrOptions) => {
    const params = new URLSearchParams();
    let url = apiEndpoint + "/autoPay?" + params.toString();

    return useGenericSWR(url, apiClient, { ...swrOptions });
}


export {
    useOneTimePayment,
    useMyWallet,
    useAutoPay
}