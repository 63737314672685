import React from "react";
import { UcTabStripStateful } from "./UcTabStripStateful";

export const UcAdminDetails = (props) => {

    return (
        <>
            {props.detailsRender}
            <UcTabStripStateful
                tabs={props.tabs}
                value={props.tabInitValue}
                showInMenu={props.showTabsInMenu || false} />
        </>
    );
}

UcAdminDetails.defaultProps = {
    detailsRender: <div>Admin Details</div>,
    tabs: [ // Note:  Same tab structure as UcTabStrip (this is where ts would come in handy)
        {
            title: "default tag",
            tabRender: <div>default tab</div>,
            contentRender: <div>someContent</div>
        }
    ],
    tabInitValue: "default tag",
};




