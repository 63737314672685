import { useElementSize } from "@mantine/hooks";
import { Swipe, SwipeVertical } from "@mui/icons-material";
import { useResponsive } from '../../lib/useResponsive';
import "./UcTouch.scss";
import { useState } from "react";

export const UcTouch = (props) => {
    const { isMobile, isTablet } = useResponsive();
    const [touchX, setTouchX] = useState(0);
    const [touchY, setTouchY] = useState(0);
    const { ref, width, height } = useElementSize();

    let showSwipeXAnimation = props.showSwipeXAnimation;
    let showSwipeYAnimation = props.showSwipeYAnimation;

    let iconLeft = null;
    let iconTopX = null;
    let iconTopY = null;
    if (ref.current) {
        iconLeft = ref.current.offsetLeft + (width / 2) - 50;
        iconTopX = ref.current.offsetTop + (height / 2) - 50;
        iconTopY = ref.current.offsetTop + (height / 2) - 50;

        if (showSwipeXAnimation && showSwipeYAnimation) {
            iconTopY = iconTopY + 50;
            iconTopX = iconTopX - 50;
        }
    }

    const columnsToShow = props.columnsToShow || 100;
    const totalColumns = props.totalColumns || 1; // if not specified basically disable it

    const rowsToShow = props.rowsToShow || 100;
    const totalRows = props.totalRows || 1; // if not specified basically disable it

    const [startXScroll, setStartXScroll] = useState(props.startX || 0);
    const [startYScroll, setStartYScroll] = useState(props.startY || 0);

    const handleTouchYChange = (touchMoveY) => {
        if (touchY === 0) {
            return; // do nothing
        }

        if (rowsToShow > totalRows)
        {
            return;
        }

        const ratio = (rowsToShow) / (height);
        const delta = Math.round((touchMoveY - touchY) * ratio);
        let newStart = startYScroll - delta;
        let newEnd = newStart + (rowsToShow - 1);

        if (newStart < 0) {
            newStart = 0;
            newEnd = rowsToShow - 1;
        }
        else if (newStart > (totalRows - (rowsToShow))) {
            newStart = totalRows - (rowsToShow);
            newEnd = totalRows - 1;
        }

        if (newStart !== startYScroll)
        {
            // only update the chart if we are moving to another section
            setTouchY(touchMoveY);
            setStartYScroll(newStart);

            props.onRangeChangeY(newStart, newEnd);
        }
    }

    const handleTouchXChange = (touchMoveX) => {
        if (touchX === 0) {
            return; // do nothing
        }

        if (columnsToShow > totalColumns)
        {
            return;
        }

        const ratio = (columnsToShow) / (width);
        const delta = Math.round((touchMoveX - touchX) * ratio);
        let newStart = startXScroll - delta;
        let newEnd = newStart + (columnsToShow - 1);

        if (newStart < 0) {
            newStart = 0;
            newEnd = columnsToShow - 1;
        }
        else if (newStart > (totalColumns - (columnsToShow))) {
            newStart = totalColumns - (columnsToShow);
            newEnd = totalColumns - 1;
        }

        if (newStart !== startXScroll)
        {
            // only update the chart if we are moving to another section
            setTouchX(touchMoveX);
            setStartXScroll(newStart);

            props.onRangeChangeX(newStart, newEnd);
        }
    }

    const checkSwipeEnabled = () => {
        let swipeEnabled = true;
        if ((columnsToShow > totalColumns && rowsToShow > totalRows) || (!isMobile && !isTablet))
        {
            swipeEnabled = false;
        }

        return swipeEnabled;
    }

    let grabClassName = "ucTouch-grab";
    if (!checkSwipeEnabled()) {
        grabClassName = "";
        showSwipeXAnimation = false;
        showSwipeYAnimation = false;
    }

    return(
        <div
            ref={ref}
            onMouseDown={(event) => {
                setTouchX(event.clientX);
                setTouchY(event.clientY);

                if(event.stopPropagation) event.stopPropagation();
                if(event.preventDefault) event.preventDefault();
                event.cancelBubble=true;
                event.returnValue=false;
                return false;
            }}
            onMouseUp={() => {
                setTouchX(0);
                setTouchY(0);
            }}
            onMouseMove={(event) => {
                handleTouchXChange(event.clientX);
                handleTouchYChange(event.clientY);
            }}
            onMouseLeave={() => {
                setTouchX(0);
                setTouchY(0);
            }}
            onTouchStart={(event) => {
                setTouchX(event.touches[0].clientX);
                setTouchY(event.touches[0].clientY);
            }}
            onTouchMove={(event) => {
                handleTouchXChange(event.touches[0].clientX)
                handleTouchYChange(event.touches[0].clientY)
            }}
            style={{...props.style}}
            className={"ucTouch " + grabClassName + " " + props.className || ""}
            >
            { showSwipeXAnimation &&
                <div className={"ucTouch-swipeX"} style={{left: iconLeft, top: iconTopX}}><Swipe className={"ucTouch-swipeX__icon"}/></div>
            }
            { showSwipeYAnimation &&
                <div className={"ucTouch-swipeY"} style={{left: iconLeft, top: iconTopY}}><SwipeVertical className={"ucTouch-swipeY__icon"}/></div>
            }
            {props.children}
        </div>
    );
}

UcTouch.defaultProps = {
    onRangeChangeX: (event, value) => { console.log("UcTouch onRangeChangeX not implemented", event, value); },
    onRangeChangeY: (event, value) => { console.log("UcTouch onRangeChangeY not implemented", event, value); },
    showSwipeXAnimation: true,
    showSwipeYAnimation: false,
};