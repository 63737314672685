import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from "react-i18next";
import Draggable from 'react-draggable';
import LoadingButton from '@mui/lab/LoadingButton';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Paper } from '@mui/material';
import { useResponsive } from 'lib/useResponsive';
import RateBasicRuleForm from './RateBasicRuleForm';
import { saveRateBasicRule, deleteRateBasicRule, getRateBasicRulesDefaultItem } from './RateBasicRuleAPIEndpoints';
import { enumFormatter } from 'lib/EnumFormatter';
import { useEnums } from 'components/auth/PermissionApiEndpoint';
import { useTheme } from '@mui/material/styles';

function PaperComponent(props) {
    return (
        <Draggable
            handle="#draggable-dialog-title"
            cancel={'[class*="MuiDialogContent-root"]'}
        >
            <Paper {...props} />
        </Draggable>
    );
}

export default function RateBasicRuleFormDialog(props) {
    const { t } = useTranslation();
    const rateChargeTypeEnums = useEnums("RateChargeType");
    const rateChargeType = props.rateChargeType;
    const rateChargeTypeDisplay = enumFormatter(rateChargeTypeEnums, rateChargeType);
    const [saving, setSaving] = React.useState(false);
    const [dialogSubmit, setDialogSubmit] = useState(0);
    const { isMobile } = useResponsive();
    const theme = useTheme();

    let formId = props.id;
    if (!props.open) {
        formId = 0;
    }

    const handleCancel = () => {
        props.onCancel();
    };

    const handleSave = () => {
        setDialogSubmit(dialogSubmit + 1);
    };

    const handleDelete = async () => {
        let data = await deleteRateBasicRule(props.itemToEdit);

        if (data) {
            props.onSave(data);
        }
    };

    return (
        <>
            {props.delete && props.open &&
                <Dialog
                    fullScreen={isMobile}
                    fullWidth={true}
                    maxWidth={"sm"}
                    open={props.open}
                    PaperComponent={PaperComponent}
                    aria-labelledby="draggable-dialog-title"
                >
                    <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
                        {t('Delete {{rateChargeTypeDisplay}} Rule', {rateChargeTypeDisplay: rateChargeTypeDisplay})}
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            {t('Are you sure you want to delete this {{rateChargeTypeDisplay}} Rule?', {rateChargeTypeDisplay: rateChargeTypeDisplay})}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button autoFocus onClick={handleCancel} variant={theme.props?.CancelButton?.variant}>
                            {t("Cancel")}
                        </Button>
                        <Button onClick={handleDelete} variant={theme.props?.CancelButton?.variant} color={theme.props?.CancelButton?.color}>
                            {t("Delete")}
                        </Button>
                    </DialogActions>
                </Dialog>
            }
            {!props.delete && props.open &&
                <Dialog
                    fullScreen={isMobile}
                    fullWidth={true}
                    maxWidth={"sm"}
                    open={props.open}
                    PaperComponent={PaperComponent}
                    aria-labelledby="draggable-dialog-title"
                >
                    <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
                        {t('{{rateChargeTypeDisplay}} Rule', {rateChargeTypeDisplay: rateChargeTypeDisplay})}
                    </DialogTitle>
                    <DialogContent>
                        <RateBasicRuleForm
                            id={formId}
                            rateChargeType={rateChargeType}
                            itemToEdit={props.itemToEdit}
                            existingItems={props.existingItems}
                            dialogSubmit={dialogSubmit}
                            onSubmit={async (values) => {
                                setSaving(true);
                                try {
                                    let data = await saveRateBasicRule(values);

                                    if (data) {
                                        props.onSave(data);
                                    }
                                } finally {
                                    setSaving(false);
                                }
                            }}
                            onError={(error) => {
                                props.onError(error);

                                setSaving(false);
                            }}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button autoFocus onClick={handleCancel} variant={theme.props?.CancelButton?.variant || "outlined"} color={theme.props?.CancelButton?.color}>
                            {t('Cancel')}
                        </Button>
                        <LoadingButton
                            loading={saving}
                            onClick={handleSave}
                            variant="contained"
                        >
                            {t('Save')}
                        </LoadingButton>
                    </DialogActions>
                </Dialog>
            }
        </>
    );
}

RateBasicRuleFormDialog.defaultProps = {
    open: false,
    id: 0,
    itemToEdit: getRateBasicRulesDefaultItem(),
    onCancel: () => { console.log("onCancel not implemented"); },
    onSave: (data) => { console.log("onSave not implemented"); },
    onError: (error) => { console.error("onError not implemented", error); },
};

RateBasicRuleFormDialog.propTypes = {
    open: PropTypes.bool,
    id: PropTypes.number,
    itemToEdit: PropTypes.object,
};