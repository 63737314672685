import { ApiClient } from "../../../lib/ApiClient";
import { useGenericSWR } from "../../../lib/useGenericSWR";
import * as Yup from 'yup';

const apiEndpoint = 'api/v1/admin/uc/Org';

let formIteration = 0;

const getOrgsDefaultItem = () => {
    formIteration = formIteration + 1;
    return {
        formIteration: "new" + formIteration,
        id: 0,
        udmOrgId: null,
        name: '',
        description: '',
        logoUrl: '',
        status: '',
        latitude: 0,
        longitude: 0,
        createdBy: 'REACT_FE',
        updatedBy: 'REACT_FE',
        contactInformation: ''
    }
};

const orgsValidationSchema = Yup.object().shape({
    name: Yup.string()
        .required("Required")
        .max(256, 'Max of 256 characters'),
    description: Yup.string()
        .max(1028, 'Max of 1028 characters'),
    status: Yup.string()
        .required()
        .oneOf(['ACTIVE', 'INACTIVE']),
    timezone: Yup.string()
        .required()
        .oneOf(['America/Chicago', 'America/Toronto']), // for now... we will need to add to these later
    udmOrgId: Yup.number().nullable(),
    latitude: Yup.number()
        .min(-90, "Latitude must be between -90 and 90")
        .max(90, "Latitude must be between -90 and 90"),
    longitude: Yup.number()
        .min(-180, "Longitude must be between -180 and 180")
        .max(180, "Longitude must be between -180 and 180"),
    activationTs: Yup.date()
});

// See definition from Org.cs
const apiClient = new ApiClient();
apiClient.getDefaultItem = () => getOrgsDefaultItem();
apiClient.getObjectName = () => {
    return "Organization";
};

const useOrg = (id, swrOptions = {}) => {
    // SWR will prevent duplicate requests
    // It will also maintain data state as long as your are using the same urls for your requests
    return useGenericSWR(apiEndpoint + "/" + id, apiClient, { ...swrOptions });
};

const useOrgs = (filter, swrOptions = {}) => {
    // SWR will prevent duplicate requests
    // It will also maintain data state as long as your are using the same urls for your requests
    const params = new URLSearchParams();
    params.append('filter', JSON.stringify(filter));
    return useGenericSWR(apiEndpoint + "?" + params.toString(), apiClient, { ...swrOptions });
};

const saveOrg = (Org) => {
    if (Org.id === 0) {
        return insertOrg(Org);
    }
    return updateOrg(Org);
}
const insertOrg = (Org) => {
    return apiClient.poster(apiEndpoint, Org);
};

const updateOrg = (Org) => {
    return apiClient.putter(apiEndpoint, Org);
};

const deleteOrg = (Org) => {
    return apiClient.deleter(apiEndpoint + "/" + Org.id);
};

export {
    useOrg,
    useOrgs,
    saveOrg,
    insertOrg,
    updateOrg,
    deleteOrg,
    orgsValidationSchema,
    getOrgsDefaultItem,
};