import React from "react";
import { Navigate } from 'react-router-dom';
import { useMyAccounts } from "./MyAccountAPIEndpoints";
import { usePermissionIsLoading, useUser } from "components/auth/PermissionApiEndpoint";
import { UcLoading } from "components/common/UcLoading";
import { EnsureUser } from "components/auth/EnsureUser";
import { routePaths } from '../../../routes';
import { getUrl } from "domain";
import { useIsUtilitySupportOrAdmin } from "../../../lib/useIsUserRole";

/***
 * Renders the page if there is an account request
 * Otherwise pops up a request form or lists all account requests
 * Pass props to ensureUser so you can use this as both ensureUser and ensureAccount
 ***/
export const EnsureAccount = (props) => {
    const user = useUser();
    const accounts = useMyAccounts();
    const isLoading = (accounts.isLoading);
    const permissionLoading = usePermissionIsLoading();
    const isUtilitySupportOrAdmin = useIsUtilitySupportOrAdmin();

    let hasAccounts = true;

    if (accounts && !isLoading) {
        hasAccounts = (accounts.result?.length > 0);
    }

    return (
        <EnsureUser {...props}>
            <UcLoading isLoading={isLoading} hasFade={false}>
                {!isLoading && !permissionLoading &&
                    <>
                        { hasAccounts &&
                            <>
                                {props.children}
                            </>
                        }
                        { !hasAccounts && (
                            <Navigate replace to={getUrl(`${routePaths.myProfilePage.path}?profileNav_link=Accounts&autoPopup=true`)} />
                        )}
                        { !hasAccounts && isUtilitySupportOrAdmin && !user.isImpersonating && !isLoading && !permissionLoading && (
                            <Navigate replace to={getUrl(`${routePaths.adminDashboardPage.path}`)} />
                        )}
                    </>
                }
            </UcLoading>
        </EnsureUser>
    );
}