import * as React from 'react';
import { 
    Dialog, 
    DialogContent
 } from '@mui/material';
import { AccountAuthorizationPage } from './AccountAuthorizationPage';
import { AccountVerificationPage } from './AccountVerificationPage';
import { AccountLookupPage } from './AccountLookupPage';

const components = {
    AUTHORIZATION: 'authorization',
    LOOKUP: 'lookup',
    VERIFICATION: 'verification'
};

export default function AccountAuthorizationDialog(props) {
    const { open, onClose, onReset }  = props;
    const [activeLookup, setActiveLookup] = React.useState('');
    const [activeComponent, setActiveComponent] = React.useState(components.AUTHORIZATION);

    const handleClose = () => {
        onClose();
        setActiveComponent(components.AUTHORIZATION);
    };

    return (
        <Dialog 
            fullWidth
            maxWidth={'md'}
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            { activeComponent === components.AUTHORIZATION && (
                <DialogContent>
                    <AccountAuthorizationPage 
                        isRenderedInDialog={true}                             
                        onCancel={handleClose}
                        onProceed={() => setActiveComponent(components.VERIFICATION)} 
                        onLookupAccount={(lookupBy) => {
                            setActiveLookup(lookupBy.toLowerCase());
                            setActiveComponent(components.LOOKUP);
                        }}
                    />
                </DialogContent>
            )}
            { activeComponent === components.VERIFICATION && (
                <AccountVerificationPage 
                    isRenderedInDialog={true} 
                    onCancel={() => setActiveComponent(components.AUTHORIZATION)}
                    onDone={handleClose}
                    onReset={() => {
                        onReset();
                    }}
                />
            )}
            { (activeComponent === components.LOOKUP && activeLookup !== '') && (
                <AccountLookupPage 
                    isRenderedInDialog={true} 
                    lookupBy={activeLookup}
                    onCancel={() => setActiveComponent(components.AUTHORIZATION)}
                    onProceed={() => setActiveComponent(components.VERIFICATION)} 
                />
            )}
        </Dialog>
    );
}