import React from 'react';
import { Typography, Box } from '@mui/material';
import { useTranslation } from "react-i18next";

export const UnableToLoginPage = () => {
    const { t } = useTranslation();

    return (
        <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            minHeight="100vh"
        >
            <div>
                <Typography variant='h1' align='center' sx={{ mb:1 }}>
                    {t('403')}
                </Typography>
                <Typography variant='subtitle1' align='center'>
                    {t('Sorry, we are unable to log you in.')}
                </Typography>
            </div>
        </Box>
    );
};