// @ts-ignore
import { UcPagination } from "components/common/UcPagination";
import { useState, useLayoutEffect, useRef } from "react";
// @ts-ignore
import { dateFormatterTimezone } from "lib/Formatters";
import { useMyTimezone } from 'components/customer/orgs/MyOrgAPIEndpoints';
import {
    DataGridPro,
    GridFilterModel,
    GridLogicOperator,
} from "@mui/x-data-grid-pro";
import ListIcon from '@mui/icons-material/List';
import { updateBlacklistedIpAddress, useBlacklistedIpAddresses } from "./BlacklistedIpAddressAPIEndpoints";
import { useTranslation } from "react-i18next";
// @ts-ignore
import { UcEnumSelect } from 'components/common/UcEnumSelect';
// @ts-ignore
import { useHasPermission } from 'components/auth/PermissionApiEndpoint';
// @ts-ignore
import { UcIconButtons } from 'components/common/UcIconButtons';
import { BlacklistedIpAddressProps } from "./BlacklistedIpAddress.interfaces";

export const BlacklistedIpAddressGrid = (props: BlacklistedIpAddressProps) => {
    const { t } = useTranslation();
    const timezone = useMyTimezone();
    const [generalSearchInput] = useState(props.generalSearch || "");
    const initialSort = [{ field: "createdTs", sort: "desc" }];
    const initialDataState = props.dataState || { skip: 0, take: 10 };
    const allowStatusEdit = useHasPermission("BlacklistedIpAddress:Modify");

    const [filter, setFilter] = useState({
        take: initialDataState.take,
        skip: initialDataState.skip,
        sort: initialSort,
        generalSearch: generalSearchInput,
    });

    let initialFilterModel: GridFilterModel | undefined = {
        items: [{ field: "name", operator: "contains", value: "" }],
        logicOperator: GridLogicOperator.And,
    };

    const [filterModel] = useState<GridFilterModel | undefined>(
        initialFilterModel
    );

    const swrBlacklistedIpAddress = useBlacklistedIpAddresses(filter);

    const needsDataUpdate = useRef("");

    let defaultDataItems = {
        columnVisibilityModel: {
            id: false,
            createdBy: false
        },
        columns: [
            { field: 'id', headerName: t('Id') },
            {
                field: 'history', headerName: t(''), width: 50,
                renderCell: (params: any) => {
                    const actions = [
                        {
                            title: t('IP Address History'),
                            icon: <ListIcon data-cy-id={"ipAddressHistoryButton"} />,
                            color: 'primary',
                            isHidden: false,
                            isDisabled: params.value.length < 1,
                            onClick: () => {
                                props.setDrawerOpen(true);
                            }
                        },
                    ];
                    return (
                        <UcIconButtons actions={actions} />
                    );
                }
            },
            { field: 'ipAddress', headerName: t('IP Address'), width: 150 },
            { field: 'userAgent', headerName: t('User Agent'), width: 200 },
            { field: "requestUrl", headerName: t('Request URL'), width: 350 },
            {
                field: 'status', headerName: t('Status'), width: 200, renderCell: (params: any) => {
                    return (
                        <UcEnumSelect
                            enumName={"IpAddressStatus"}
                            disableClearable
                            disabled={!allowStatusEdit}
                            value={params.value}
                            onChange={async (event: any, value: {id: string, display: string}) => {
                                const blacklistedIpAddress: any = {
                                    ...params.row,
                                    status: value?.id
                                }
                                try {
                                    await updateBlacklistedIpAddress(blacklistedIpAddress, t("Updated IP Spam Watchlist"), true);
                                } finally {
                                    props.mutateHandler();
                                }
                            }}
                        />
                    );
                }
            },
            { field: 'createdBy', headerName: t('Created By'), width: 200 },
            { field: "createdTs", headerName: t('Created On'), width: 200, valueFormatter: (params: any) => { return dateFormatterTimezone(params.value, timezone); }},
            { field: 'updatedBy', headerName: t('Updated By'), width: 200 },
            { field: "updatedTs", headerName: t('Updated On'), width: 200, valueFormatter: (params: any) => { return dateFormatterTimezone(params.value, timezone); }},           
        ],
        rows: [],
        rowCount: -1,
    };
    const [dataItems, setDataItems] = useState<any>(defaultDataItems);

    const defaultSelectedItem = { dataItem: {}, selectedId: 0 };
    const [selectedItem, setSelectedItem] = useState<any>(defaultSelectedItem);
 
    const isDataLoaded = useRef(false);

    const dataReceived = (swrResult: any) => {
        let dataItems = swrResult.result;
        let selectedDataItems = dataItems.map((item: any) => {
            return { ...item, selected: item.id === selectedItem.selectedId };
        });

        selectedDataItems.iteration = dataItems.iteration;
        selectedDataItems.rowCount = swrResult.rowCount;
        if (selectedDataItems.length > selectedDataItems.rowCount) {
            selectedDataItems.rowCount = selectedDataItems.length;
        }

        setDataItems({
            ...defaultDataItems,
            rows: [...dataItems],
            rowCount: swrResult.rowCount,
        });
    };

    if (!swrBlacklistedIpAddress.result && defaultDataItems.rowCount !== -1) {
        setDataItems(defaultDataItems);
    }

    const updateFilter = (filter: any) => {
        setFilter(filter);
        props.onFilterChange(filter);
    };

    useLayoutEffect(() => {
        if (swrBlacklistedIpAddress.result) {
            isDataLoaded.current = true;
            let filterString = JSON.stringify(filter);
            if (needsDataUpdate.current !== filterString) {
                needsDataUpdate.current = filterString;
                dataReceived(swrBlacklistedIpAddress);
            }
        }
    });

    return (
        <div data-cy-id={"BlacklistedIpAddressGridWrapper"}>
            <DataGridPro
                autoHeight={true}
                loading={!isDataLoaded.current}
                paginationModel={{
                    page: filter.skip / filter.take,
                    pageSize: filter.take,
                }}
                onPaginationModelChange={(model) =>
                    updateFilter({
                        ...filter,
                        skip: model.page * model.pageSize,
                        take: model.pageSize,
                    })
                }
                sortingMode="server"
                paginationMode="server"
                filterMode="server"
                sortModel={filter.sort}
                onSortModelChange={(model) =>
                    updateFilter({ ...filter, sort: model })
                }
                filterModel={filterModel}
                rowsPerPageOptions={[5, 10, 20]}
                onRowSelectionModelChange={(newSelectionModel) => {
                    if (newSelectionModel.length !== 1) {
                        setSelectedItem(defaultSelectedItem);
                        props.onSelected(defaultSelectedItem);
                        return;
                    }

                    const selectedId = newSelectionModel[0];
                    let selectedItem = dataItems.rows.filter((obj: any) => {
                        return obj.id === selectedId;
                    });

                    if (selectedItem.length !== 1) {
                        setSelectedItem(defaultSelectedItem);
                        props.onSelected(defaultSelectedItem);

                        return;
                    }

                    selectedItem = selectedItem[0];
                    let newlySelectedItem = {
                        dataItem: selectedItem,
                        selectedId: selectedId,
                    };

                    setSelectedItem(newlySelectedItem);
                    props.onSelected(newlySelectedItem.dataItem);
                }}
                pagination
                slots={{
                    toolbar: () => {
                        return props.toolbar || <></>;
                    },
                    pagination: UcPagination,
                }}
                {...dataItems}
                initialState={{ pinnedColumns: { left: ['history'] } }}       
            />
        </div>
    );
};

BlacklistedIpAddressGrid.defaultProps = {
    onSelected: (selectedItem: any) => {
        console.log("onSelected not implemented", selectedItem);
    },
    onFilterChange: (filter: any) => {
        console.log("onFilterChange not implemented", filter);
    },
};
