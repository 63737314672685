import { useTranslation } from 'react-i18next';
// @ts-ignore
import { useOrgModule } from './OrgModuleApiEndpoints';
// @ts-ignore
import { UcLoading } from 'components/common/UcLoading';
import { Alert, Grid, Switch, IconButton, Popover, Box} from '@mui/material';
import Typography from '@mui/material/Typography';
import { useLayoutEffect, useState } from 'react';
import { mutateUrls } from 'lib/useGenericSWR';
import { useSWRConfig } from 'swr';
import InfoIcon from '@mui/icons-material/Info';

export const OrgModuleGrid = (props: any) => {
    const { t } = useTranslation();
    const swrOrgModule = useOrgModule();
    const isLoading = swrOrgModule.isLoading;

    const [reMutateUrls, setReMutateUrls] = useState(false);
    const swrConfig = useSWRConfig();

    const [anchorELBillingModuleInfo, setAnchorElBillingModuleInfo] = useState(null);
    const openBillingModuleInfoPopover = Boolean(anchorELBillingModuleInfo);
    const idBillingModuleInfoPopover = openBillingModuleInfoPopover ? 'simple-popover' : undefined;

    const [anchorELRateClassComparisonModuleInfo, setAnchorElRateClassComparisonModuleInfo] = useState(null);
    const openRateClassComparisonModuleInfoPopover = Boolean(anchorELRateClassComparisonModuleInfo);
    const idRateClassComparisonModuleInfoPopover = openRateClassComparisonModuleInfoPopover ? 'simple-popover' : undefined;

    const [anchorELOutageModuleInfo, setAnchorElOutageModuleInfo] = useState(null);
    const openOutageModuleInfoPopover = Boolean(anchorELOutageModuleInfo);
    const idOutageModuleInfoPopover = openOutageModuleInfoPopover ? 'simple-popover' : undefined;

    const handleClickBillingModuleInfo = (event: any) => {
        setAnchorElBillingModuleInfo(event.currentTarget);
    };

    const handleClickRateClassComparisonModuleInfo = (event: any) => {
        setAnchorElRateClassComparisonModuleInfo(event.currentTarget);
    };

    const handleClickOutageModuleInfo = (event: any) => {
        setAnchorElOutageModuleInfo(event.currentTarget);
    };
    
    const handleChange = (moduleName: string) => {
        const moduleToChange: OrgModule | undefined = findModule(moduleName);

        if(moduleToChange) {
            props.onEditItem(moduleToChange);
        }
        else {
            props.onEditItem({moduleName: moduleName});
        }
        
        setReMutateUrls(true);
    };

    const findModule = (moduleName: string): OrgModule | undefined => {
       return swrOrgModule?.result
            ?.find((orgModule: OrgModule) => orgModule.moduleName === moduleName);
    };

    useLayoutEffect(() => {
        if (reMutateUrls) {
            mutateUrls(swrConfig);
            setReMutateUrls(false);
        }
    }, [reMutateUrls, swrConfig]);
    
    return (
        <UcLoading isLoading={isLoading} hasFade={false}>
            <h1 className="pageHeader">{t("Module Setup")}</h1>
            {swrOrgModule.isError && (
                <Alert severity="error" sx={{ mb: 2 }}>
                    {t('Error retrieving Modules')}
                </Alert>
            )}
            <Grid container spacing={4} minHeight="30vh" padding="1rem" direction="column" sx={{mt: 3}}  wrap="nowrap">
                <Grid container direction="row" wrap="nowrap">
                    <Grid item>
                        <Switch
                            onChange={() => handleChange("Billing")}
                            checked={findModule("Billing") !== undefined}
                        />
                    </Grid>
                    <Grid item>
                        <Typography variant="h6" gutterBottom>{t("Enable Billing Module")}</Typography>
                    </Grid>
                    <Grid item>
                        <IconButton color="primary" onClick={handleClickBillingModuleInfo} aria-label="billing info" size='small' sx={{ p: 0, ml: 2, mt: 0.5 }}>
                            <InfoIcon sx={{ fontSize: 20 }} />
                        </IconButton>
                        <Popover
                            id={idBillingModuleInfoPopover}
                            open={openBillingModuleInfoPopover}
                            anchorEl={anchorELBillingModuleInfo}
                            onClose={() => setAnchorElBillingModuleInfo(null)}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                            }}
                        >
                            <Box
                                sx={{ p: 2 }}>
                                <Typography variant='body2'>
                                    {t('Enabling will rearrange the dashboard and show the Billing Summary tile, Billing Options tile and Billing side menu icon. ')}
                                    <br />
                                    {t('Payment processor and Billing module set up is still required to make all Billing functions operable.')}
                                </Typography>
                            </Box>
                        </Popover>
                    </Grid>
                </Grid>

                <Grid container direction="row" wrap="nowrap">
                    <Grid item>
                        <Switch
                            onChange={() => handleChange("RateClassComparison")}
                            checked={findModule("RateClassComparison") !== undefined}
                        />
                    </Grid>
                    <Grid item>
                        <Typography variant="h6" gutterBottom>{t("Enable Rate Class Comparison Module")}</Typography>
                    </Grid>
                    <Grid item>
                        <IconButton color="primary" onClick={handleClickRateClassComparisonModuleInfo} aria-label="rate comparison info" size='small' sx={{ p: 0, ml: 2, mt: 0.5 }}>
                            <InfoIcon sx={{ fontSize: 20 }} />
                        </IconButton>
                        <Popover
                            id={idRateClassComparisonModuleInfoPopover}
                            open={openRateClassComparisonModuleInfoPopover}
                            anchorEl={anchorELRateClassComparisonModuleInfo}
                            onClose={() => setAnchorElRateClassComparisonModuleInfo(null)}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                            }}
                        >
                            <Box
                                sx={{ p: 2 }}>
                                <Typography variant='body2'>
                                    {t('Enabling Rate Comparison will give users access to the Rate Comparisons side menu icon,')}
                                    <br />
                                    {t(' the rate comparison page and Change Rate Req in the Admin.')}
                                </Typography>
                            </Box>
                        </Popover>
                    </Grid>
                </Grid>

                <Grid container direction="row" wrap="nowrap">
                    <Grid item>
                        <Switch
                            onChange={() => handleChange("OutageManual")}
                            checked={findModule("OutageManual") !== undefined}
                        />
                    </Grid>
                    <Grid item>
                        <Typography variant="h6" gutterBottom>{t("Enable Outage Module")}</Typography>
                    </Grid>
                    <Grid item>
                        <IconButton color="primary" onClick={handleClickOutageModuleInfo} aria-label="outage info" size='small' sx={{ p: 0, ml: 2, mt: 0.5 }}>
                            <InfoIcon sx={{ fontSize: 20 }} />
                        </IconButton>
                        <Popover
                            id={idOutageModuleInfoPopover}
                            open={openOutageModuleInfoPopover}
                            anchorEl={anchorELOutageModuleInfo}
                            onClose={() => setAnchorElOutageModuleInfo(null)}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                            }}
                        >
                            <Box
                                sx={{ p: 2 }}>
                                <Typography variant='body2'>
                                    {t('Enabling will give users access to the outage map menu icon, the outage map page,')}
                                    <br />
                                    {t('the outage send function under Admin Users and Outage page in the Admin.')}
                                </Typography>
                            </Box>
                        </Popover>
                    </Grid>
                </Grid>
            </Grid>
        </UcLoading>
    );
}