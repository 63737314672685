import { ApiClient } from "../../lib/ApiClient";

const apiClient = new ApiClient();
const apiEndpoint = 'api/v1/OneSignalUser';

const insertOneSignalUser = (playerId) => {
    const oneSignalUser = {cepUserId: -1, playerId: playerId}
    return apiClient.poster(apiEndpoint, oneSignalUser, "", false, null, false);
};

export {
    insertOneSignalUser
}