// must have this in a seperate file or you get circular reference errors
export const defaultUserInfo = {
    username: "",
    isLoggedIn: false,
    isLoading: true,
    isFirstAccess: true,
    emailVerified: false,
    isIdle: false,
}

export const userInfo = {
    ...defaultUserInfo
}

export const updateUserInfo = (newUserInfo) => {
    userInfo.username = newUserInfo.username;
    userInfo.isLoggedIn = newUserInfo.isLoggedIn;
    userInfo.isLoading = newUserInfo.isLoading;
    userInfo.isFirstAccess = newUserInfo.isFirstAccess;
    userInfo.emailVerified = newUserInfo.emailVerified;

    userInfo.error = newUserInfo.error;
    userInfo.accessToken = newUserInfo.accessToken;
    userInfo.expiresAt = newUserInfo.expiresAt;
    window.accessToken = userInfo.accessToken;
    window.expiresAt = userInfo.expiresAt;

    userInfo.expiresAtRaw = newUserInfo.expiresAtRaw;

    userInfo.isIdle = newUserInfo.isIdle;
}

