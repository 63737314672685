import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import { Account, AccountContext, getAccountActiveString } from "lib/AccountProvider";
import { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
// @ts-ignore
import { EnsureUser } from '../../auth/EnsureUser';
import "./CurrentAccount.scss";
// @ts-ignore
import { UcDraggable } from "components/common/UcDraggable";
import { useTheme } from '@mui/material/styles';
// @ts-ignore
import { AccountSelect } from 'components/customer/accounts/AccountSelect';
import { ThemeProps } from "components/common/Common.interfaces";

export const CurrentAccount = () => {
    const { account, setAccount } = useContext(AccountContext);
    const theme = useTheme() as ThemeProps;
    const { t } = useTranslation();
    const accountActiveString = getAccountActiveString(account);
    const [changeAccountOpen, setChangeAccountOpen] = useState(false);

    const handleCloseChangeAccount = () => {
        setChangeAccountOpen(false);
    };

    const handleOpenChangeAccount = () => {
        setChangeAccountOpen(true);
    };

    const handleClickPreventPropagation = (event: React.MouseEvent<HTMLElement>) => {
        event.stopPropagation();
        return 0;
    }

    const handleAccountChange = (_event: React.MouseEvent<HTMLElement>, newAccount: Account) => {
        setAccount(newAccount);
        setChangeAccountOpen(false);
    }

    return (
        <div className="currentAccount"
            style={{backgroundColor: "#E3F8FF"}}
            onClick={handleClickPreventPropagation}>
            <div>
                <h4 style={{marginTop: '0px', marginBottom: '0px'}}>
                    {t("Current Account")}
                </h4>
            </div>
            <div>
                {account?.extAccountNumber}
            </div>
            <div>
                {account?.accountAddress}
            </div>
            <div>
                {accountActiveString}
            </div>
            <div className="currentAccount__button">
                <Button variant="contained" onClick={handleOpenChangeAccount}>{t("Change Account")}</Button>
            </div>
            <Dialog
                fullWidth={true}
                maxWidth={"sm"}
                open={changeAccountOpen}
                onClose={handleCloseChangeAccount}
                PaperComponent={UcDraggable}
                aria-labelledby="draggable-dialog-title"
            >
                <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
                    {t('Change Current Account')}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        <EnsureUser>
                            <div style={{minHeight: "100px", paddingTop: "20px"}}>
                                <AccountSelect
                                    allowDelegations={true}
                                    onChange={handleAccountChange}
                                    showAddressOnly={true}
                                    showAccountNumber={true}
                                    value={account}
                                    showNickname={true}
                                />
                            </div>
                        </EnsureUser>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button autoFocus
                        onClick={handleCloseChangeAccount}
                        variant={theme.props?.CancelButton?.variant}
                        color={theme.props?.CancelButton?.color}>
                        {t('Close')}
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};