import { ApiClient } from "../../lib/ApiClient";
import { useGenericSWR } from "../../lib/useGenericSWR";
import { mutateMyUserDelegations } from './MyUserDelegationAPIEndpoints';
import * as Yup from 'yup';

const apiEndpoint = 'api/v1/MyAccount';

let formIteration = 0;

const getUserAccountsDefaultItem = () => {
    formIteration = formIteration + 1;
    return {
        formIteration: "new" + formIteration,
        id: 0,
        userAccountId: 0,
    }
};

const UserAccountsValidationSchema = Yup.object().shape({

    accountId: Yup.number().required("Required").integer(),
    userId: Yup.number().required("Required").integer(),
});

const apiClient = new ApiClient();
apiClient.getDefaultItem = () => getUserAccountsDefaultItem();
apiClient.getObjectName = () => {
    return "My User Account";
};

const mutateMyUserAccounts = () => {
    apiClient.mutateFetchUrls();
}

const useUserAccounts = (filter, swrOptions = {}) => {
    // SWR will prevent duplicate requests
    // It will also maintain data state as long as your are using the same urls for your requests
    const params = new URLSearchParams();
    params.append('filter', JSON.stringify(filter));
    return useGenericSWR(apiEndpoint + "?" + params.toString(), apiClient, { ...swrOptions });
};

const saveUserAccount = (UserAccount) => {
    if (UserAccount.id === 0) {
        return insertUserAccount(UserAccount);
    }
    return updateUserAccount(UserAccount);
}
const insertUserAccount = (UserAccount) => {
    return apiClient.poster(apiEndpoint, UserAccount);
};

const updateUserAccount = (UserAccount) => {
    return apiClient.putter(apiEndpoint, UserAccount);
};

const deleteUserAccount = (UserAccount) => {
    mutateMyUserDelegations();
    return apiClient.deleter(apiEndpoint + "/" + UserAccount.id);
};

export {
    getUserAccountsDefaultItem,
    UserAccountsValidationSchema,
    useUserAccounts,
    saveUserAccount,
    insertUserAccount,
    updateUserAccount,
    deleteUserAccount,
    mutateMyUserAccounts,
};