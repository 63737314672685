import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

export const SignInOidc = () => {
  const navigate = useNavigate();

  useEffect(() => {
        navigate('/');
  }, [navigate]);

  return <div>Loading...</div>;
};
