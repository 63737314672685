export const filterTypes = {
    YEAR: 'year',
    MONTH: 'month',
    DAY: 'day'
}

export const serviceTypes = {
    ELECTRIC: 'KWH',
    WATER: 'GAL'
}

