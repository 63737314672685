import React from 'react';
import { EnsureUser } from "components/auth/EnsureUser";
import { PageContext } from "lib/PageProvider";
import { useContext, useLayoutEffect, useMemo } from "react";
import { UcTabStripStateful } from '../common/UcTabStripStateful';
import { useTranslation } from "react-i18next";
import { UserNotificationList } from "./UserNotificationList";
import { UserNotificationPreferenceList } from "./UserNotificationPreferenceList";
import { UcWidget } from '../common/obsolete/UcWidget';
import { UcMobileNotificationPage } from '../common/UcMobileNotificationPage';
import { useStatefulParam } from 'lib/useStatefulParam';
import { useResponsive } from 'lib/useResponsive';
import { useTheme } from '@mui/material/styles';
import { useLocation } from 'react-router';
import NotificationsIcon from '@mui/icons-material/Notifications';
import SettingsIcon from '@mui/icons-material/Settings';

export const UserNotificationPage = (props) => {
    const { t } = useTranslation();
    const { isMobile } = useResponsive();
    const [pageState, pageDispatch] = useContext(PageContext);
    const statefulParam = useStatefulParam({paramName: "notificationNav_link", paramValue: 'Notifications'});
    const params = new URLSearchParams(window.location.search);
    const theme = useTheme();
    const location = useLocation();
    let from = location.state?.from?.pathname;

    const views = useMemo(() => {
        return [
            {
                title: t("Notifications"),
                subtitle: t("Notifications"),
                tabRender: <div>{t("Notifications")}</div>,
                icon: <NotificationsIcon />,
                contentRender: <UserNotificationList />
            },
            {
                title: t("Notification Setup"),
                subtitle: t("Setup"),
                tabRender: <div data-cy-id={"notification_preference_setup_tab"}>{t("Notification Set Up")}</div>,
                icon: <SettingsIcon />,
                contentRender: <UserNotificationPreferenceList />
            }
        ];
    }, [t]);

    useLayoutEffect(() => {
        pageDispatch({
            header: t("Notifications"),
            tabs: views
        });
    }, [pageState.header, pageDispatch, t, views]);

    return (
        <UcWidget contentPadding={0}>
            <EnsureUser>
                { isMobile && (
                    <UcMobileNotificationPage
                        contentBackgroundColor={theme.palette.background.default}
                        pageTitle={t('Notifications')}
                        onChange={statefulParam.onSetValue}
                        defaultView={views[0]}
                        views={views}
                        navigateBack={from}
                        navLink={params.get("notificationNav_link") !== null ? statefulParam.value : "Notifications"}
                    />
                )}
                { !isMobile && statefulParam.isRenderReady && (
                    <UcTabStripStateful
                        value={t("Notifications")}
                        tabs={views}
                        showInMenu={true}
                        {...props}>
                    </UcTabStripStateful>
                )}
            </EnsureUser>
        </UcWidget>
    );
}
