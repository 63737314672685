import React from 'react';
import { PageContext, PageState, PageAction } from 'lib/PageProvider';
import OutageMapWidget from '../widgets/OutageMapWidget';
import { useTranslation } from 'react-i18next';
import EnsureUserAndModule from 'components/auth/EnsureUserAndModule';
import { Typography } from '@mui/material';

export const OutageMapListPage = () => {
    const { t } = useTranslation();
    const [pageState, pageDispatch] = React.useContext<[PageState, React.Dispatch<PageAction>]>(PageContext);

    React.useEffect(() => {
        pageDispatch({
            header: t(''),
            actionItems: [] as any[],
            variableHeader: "",
            tabs: [],
            onTabChange: () => { },
            tabValue: ''
        });
    }, [pageState.header, pageDispatch, t]);
    
    console.log("OutageMapListPage - Loaded");
    return (
        <EnsureUserAndModule modules={['OutageManual']}>
            <div data-cy-id="outageMapPage">
                <Typography fontWeight={500}>
                    {t("Outage Map")}
                </Typography>
                    <OutageMapWidget mobileHeight="80vh" />          
            </div>
        </EnsureUserAndModule>
    );
}