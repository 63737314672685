import * as React from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
import { useBillingCyclees } from './BillingCycleAPIEndpoints';
import { useDebouncedValue } from '@mantine/hooks';

// From: https://mui.com/components/autocomplete/#Asynchronous.js
export const BillingCycleAdminSelect = (props) => {
    const [open, setOpen] = React.useState(false);
    const [options, setOptions] = React.useState([]);
    const [value, setValue] = React.useState(props.value || null);
    const needsUpdate = React.useRef(true);
    const firstValueSet = React.useRef(false);

    const [filter, setFilter] = React.useState({
        take: 100,
        skip: 0,
        sort: [{ field: "name", sort: "asc" }],
        generalSearch: "",
    });

    let loading = true;
    let loadedOptions = [];

    const swrBillingCyclees = useBillingCyclees(filter);
    if (swrBillingCyclees.result && !swrBillingCyclees.isLoading && !swrBillingCyclees.isError)
    {
        loadedOptions = swrBillingCyclees.result;
        loading = false;
        if (needsUpdate.current) {
            needsUpdate.current = false;

            setOptions([...loadedOptions]);
        }
    }
    else
    {
        needsUpdate.current = true;
        loading = true;
    }

    const propOnChange = props.onChange;

    React.useEffect(() => {
        // Set the value to the first option if it is not set
        if (!value && options && options.length >= 1)
        {
            // Only do this once
            if (!firstValueSet.current)
            {
                firstValueSet.current = true;
                // Adding this auto select causes a strange error when switching tabs
                propOnChange(null, options[0]);
                setValue(options[0]);
            }
        }
        else if (value) {
            firstValueSet.current = true;
        }
    }, [options, value, propOnChange]);

    const [generalSearch, setGeneralSearch] = React.useState(filter.generalSearch);
    const [debounced] = useDebouncedValue(generalSearch, 500);

    React.useEffect(() => {
        if (filter.generalSearch !== debounced) {
            needsUpdate.current = true;
            setFilter({
                ...filter,
                generalSearch: debounced,
            });
        }
    }, [debounced, filter]);

    return (
        <Autocomplete
            id={props.id || "billingCycleAdminSelect"}
            open={open}
            value={value}
            disabled={(value?.id <= -1 && value?.name !== "")}
            onChange={(event, newValue) => {
                setValue(newValue);
                props.onChange(event, newValue);
            }}
            onOpen={() => {
                setOpen(true);
            }}
            onClose={() => {
                if (filter.generalSearch !== "")
                {
                    needsUpdate.current = true;
                    setFilter({...filter, generalSearch: ""});
                }
                setOpen(false);
            }}
            isOptionEqualToValue={(option, selectedValue) => option.id === selectedValue.id}
            getOptionLabel={(option) => option.name}
            options={options}
            loading={loading}
            renderInput={(params) => (
                <TextField
                {...params}
                onChange={(e) => {
                    setGeneralSearch(e.target.value);
                }}
                label={props.label || "Rate Classes"}
                InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                    <React.Fragment>
                        {loading ? <CircularProgress color="inherit" size={20} /> : null}
                        {params.InputProps.endAdornment}
                    </React.Fragment>
                    ),
                }}
                />
        )}
        />
    );
};

BillingCycleAdminSelect.defaultProps = {
    onChange: (event, value) => { console.log("billingCycleAdminSelect onChange not implemented", event, value); },
};