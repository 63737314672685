import React from 'react';
import { Formik, Form, Field } from 'formik';
import { TextField } from 'formik-mui';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import { useTranslation } from "react-i18next";
import { LinkValidationSchema, linkAccount} from './AccountAuthorizationApiEndpoints';

export const AccountVerificationStep4 = (props) => {    
    const { t } = useTranslation();
    const formRef = React.useRef();
    const dialogSubmit = React.useRef(props.dialogSubmit);
    const { account, code } = props;
    const initialValues =  { email: props.account?.result?.email || '' };
    let hasEmail = initialValues?.email!== undefined && initialValues?.email!== '';

    React.useEffect(() => {
        if (props.dialogSubmit !== dialogSubmit.current) {
            dialogSubmit.current = props.dialogSubmit;
            if (formRef.current) {
                formRef.current.handleSubmit();
            }
        }
    });

    return (
        <div className='ignoreCustomFormStyles'>
           <Formik
                innerRef={formRef}
                validationSchema={LinkValidationSchema}
                initialValues={initialValues}
                onSubmit={async (values) => {
                    props.onIsLoading(true);

                    const data = await linkAccount(account.result.extAccountNumber, account.result.name, values.email, code);
                    let alertSettings = { severity: '', isNewUser: false, text: ''}
                    if (data.isSuccessful) {
                        if (data.isNewUser && !data.ensureAuthorityUserResult.isNewUser) {
                            alertSettings.text = t('Account was linked successfully! You should receive an email invitation momentarily.');
                            alertSettings.severity = 'success';
                        }
                        else if (data.ensureAuthorityUserResult.isNewUser) {
                            alertSettings.text = t('Account was linked successfully! You should receive an email momentarily, with instructions on how to get started.');
                            alertSettings.severity = 'success';
                            alertSettings.isNewUser = true;
                        }
                        else {
                            alertSettings.text = t('Account was linked successfully!');
                            alertSettings.severity = 'success';
                        }
                    }
                    else { 
                        alertSettings.text = t('Something went wrong when attempting to link your account. If the problem persists please contact customer support.');
                        alertSettings.severity = 'error';
                    }
                    
                    props.onIsLoading(false);
                    props.onNext(alertSettings);
                }}
            >
                <Form>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Typography component={'p'} variant='body1'>
                                { hasEmail ? (
                                        t('This account will be linked to the email below.')
                                    ) : (
                                        t('This account will be linked to the email below. If you want to link the account to another email, modify the text field before proceeding.')
                                    )
                                }
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Field
                                disabled={hasEmail}
                                name={"email"}
                                placeholder={'(e.g. john@example.com)'}
                                component={TextField}
                                label={t("Email")}
                            />
                        </Grid>
                    </Grid>
                </Form>
            </Formik>
        </div>
    );
}