import * as React from 'react';
import Box from '@mui/material/Box';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Chip from '@mui/material/Chip';
import { useRoles } from 'components/adminUc/Roles/RoleAPIEndpoints';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

export const RoleMultiSelect = (props) => {
    const [options, setOptions] = React.useState([]);
    const {useBackendDefaultRole, defaultRole, validRoles, roles} = props; 
    let getDefaultRoles = null;

    const filter = {
    take: 100,
    skip: 0,
    sort: [],
    generalSearch: ""
    };

    let loading = true;
    const swrRoles = useRoles(filter);

    let roleResult = [];
    
    if (swrRoles.result && !swrRoles.isLoading && !swrRoles.isError)
    {
        if(validRoles){
            roleResult = swrRoles.result.filter(i => validRoles.includes(i.name));
        }
        else{
            roleResult = swrRoles.result;
        }
        
    }

    let loadedOptions = React.useMemo(() => {
    if (swrRoles.result && !swrRoles.isLoading && !swrRoles.isError)
        if(validRoles){
            return swrRoles.result.filter(i => validRoles.includes(i.name));
        }
        else{
            return swrRoles.result;
        }
    return [];
    }, [swrRoles, validRoles]);

    if ((defaultRole || useBackendDefaultRole) && roleResult && !swrRoles.isLoading && !swrRoles.isError)
    {
        if (roles.length < 1) {
            if(useBackendDefaultRole){
                getDefaultRoles = roleResult.filter(i => i.isDefault);
            }
            else{
                getDefaultRoles = roleResult.filter(i => i.name === defaultRole);
            }
            props.setRoleIds([getDefaultRoles[0].id]);
            props.setRoleNames([getDefaultRoles[0].name]);
        }       
        loading = false;
    }

    const handleDelete = (value) => () => {
        props.setRoleNames((chips) => chips.filter((chip) => chip !== value));   

        let getChipId = swrRoles.result.find(e => e.name === value);
        props.setRoleIds((chips) => chips.filter((chip) => chip !== getChipId.id));
    };

    React.useEffect(() => {
        setOptions([...loadedOptions]);
    }, [loading, loadedOptions]);

    return (
    <div>
        <FormControl sx={{ m: 1, width: 300 }}>
        <InputLabel id="roles-multiple-chip-label">Roles</InputLabel>
        <Select
            labelId="roles-multiple-chip-label"
            id="roles-multiple-chip"
            data-cy-id={"user_roles"}
            multiple
            value={roles}
            disabled={props.disableSelect}
            onChange={(event, obj) => {
                props.onChange(event, obj);
            }}
            inputProps={props.disableSelect && { IconComponent: () => null }}
            input={<OutlinedInput id="select-multiple-chip" label="Roles" />}
            renderValue={(selected) => (
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                {selected.map((value) => (
                    <Chip
                        key={value}
                        label={value}
                        onDelete={props.allowDelete && handleDelete(value)}
                        data-cy-id={"user_chip_"+value}
                        onMouseDown={(event) => {
                            event.stopPropagation();
                        }}
                    />
                ))}
            </Box>
            )}
            MenuProps={MenuProps}
        >
            {options.map((role) => (
                <MenuItem
                key={role.id}
                value={role.name}
                sx={{ color: role.name.includes('Admin') ? 'red' : 'black' }}
                data-cy-id={"user_role_"+role.name}
            >
                {role.name}
            </MenuItem>
            ))}
        </Select>
        </FormControl>
    </div>
    );
};