import React from 'react';
import { useTranslation } from "react-i18next";
import { Formik, Form, Field } from 'formik';
import { RadioGroup } from 'formik-mui';
import { AccountVerificationStep2Schema } from './AccountAuthorizationApiEndpoints';

import Radio from '@mui/material/Radio';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import Alert from '@mui/material/Alert';
import Typography from '@mui/material/Typography';
import  Grid from '@mui/material/Grid';
import Popover from '@mui/material/Popover';
import { IconButton } from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

export const AccountVerificationStep2 = (props) => {
    const { t } = useTranslation();
    const account = React.useRef(props.account);
    const formRef = React.useRef();
    const dialogSubmit = React.useRef(props.dialogSubmit);
    const initialValues = { method: account.current.result?.email !== undefined ? 'email' : undefined };

    const [anchorEl, setAnchorEl] = React.useState(null);
    const openInfo = Boolean(anchorEl);
    const id = openInfo ? 'simple-popover' : undefined;

    let hasEmail = account.current.result?.email !== undefined && account.current.result?.email !== "";
    let hasPhone = account.current.result?.phone1 !== undefined && account.current.result?.phone1 !== "";

    const handleClickInfo = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleCloseInfo = () => {
        setAnchorEl(null);
    };
    
    React.useEffect(() => {
        if (props.dialogSubmit !== dialogSubmit.current) {
            dialogSubmit.current = props.dialogSubmit;
            if (formRef.current) {
                formRef.current.handleSubmit();
            }
        }

        if (props.account !== account.current) {
            account.current = props.account;
        }

        if (!hasEmail && !hasPhone) {
            props.onDisableNextBtn();
        }
    });

    return (
        <div className='ignoreCustomFormStyles'>
            { !account.current.isLoading && (account.current.result === undefined || account.current.result === null) && (
                <Alert severity='warning'>
                    {t('No results found.')}
                </Alert>
            )}
            { !account.current.isLoading && (account.current.result !== undefined && account.current.result !== null) && (
                <>
                    <Alert severity='success' sx={{mb: 2}}>
                        {t('We found your account! Please select a contact method from the options below, to receive a verification code.')}
                    </Alert>
                    <Typography gutterBottom component='p' variant='h6'sx={{mb: 3}}>
                        {t('Account: {{hint}}', { hint: account.current.result?.name })}
                    </Typography>
                    <Formik
                        innerRef={formRef}
                        initialValues={initialValues}
                        validationSchema={AccountVerificationStep2Schema}
                        onSubmit={async (values) => {
                            props.onNext(values.method);
                        }}
                    >
                        <Form>
                            <FormControl>
                                <FormLabel id="demo-radio-buttons-group-label">{t("Verification Options")}</FormLabel>
                                {!hasEmail && !hasPhone &&
                                  (
                                    <Alert severity='error'>
                                        {t('No verification options were found. If the problem persists please contact customer support.')}
                                    </Alert>
                                )}
                                <Field component={RadioGroup} name="method">
                                    { hasEmail && (
                                        <FormControlLabel value="email" control={<Radio />} label={t('Send an email to {{hint}}', {hint: account.current.result?.email})} />
                                    )}
                                    { hasPhone && (
                                        <>
                                        <Grid container direction='row'>
                                            <Grid Item>
                                                <>
                                                <FormControlLabel 
                                                    value="sms" 
                                                    control={<Radio />} 
                                                    label={t('Send a text to {{hint}}', {hint: account.current.result?.phone1})} 
                                                />
                                                <IconButton
                                                aria-describedby={id}
                                                variant="contained"
                                                onClick={handleClickInfo}
                                                onMouseOver={handleClickInfo}
                                                sx={{color: 'primary.main'}}
                                            >
                                                <InfoOutlinedIcon />
                                            </IconButton>
                                                <Popover
                                                    id={id}
                                                    open={openInfo}
                                                    anchorEl={anchorEl}
                                                    onClose={handleCloseInfo}
                                                    onMouseOut={handleCloseInfo}
                                                    anchorOrigin={{
                                                        vertical: 'bottom',
                                                        horizontal: 'left',
                                                    }}
                                                    PaperProps={{
                                                        style: { width: '400px', paddingRight: '20px', paddingLeft: '20px', paddingBottom: '10px' },
                                                    }}
                                                >
                                                    <Typography sx={{ pt: 2 }}>
                                                        {t('This number should be from a mobile phone. A landline phone will not be able to receive texts.')}
                                                    </Typography>
                                                </Popover>
                                                </>
                                            </Grid>
                                        </Grid>
                                            <FormControlLabel value="call" control={<Radio />} label={t('Call me at {{hint}}', {hint: account.current.result?.phone1})} />
                                        </>
                                    )}
                                </Field>
                            </FormControl>
                        </Form>
                    </Formik>
                </>
            )}
        </div>
    );
}