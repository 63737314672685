import React from "react";
//@ts-ignore
import { ApiClient } from "../../../lib/ApiClient";
import { useGenericSWR } from "../../../lib/useGenericSWR";
import { SwrOptions } from "../../../lib/useGenericSWR.interfaces";
// @ts-ignore
import { ensureLuxonDate } from "lib/Formatters";
// @ts-ignore
import { UserInfoContext } from "lib/UserInfoProvider";

const baseApiEndpoint = 'api/v1/admin/Metrics';
const apiClient = new ApiClient();

apiClient.getObjectName = () => {
    return "Metrics";
};

const useUserMetrics = (roleType: string, sinceDate: Date, swrOptions = {}) => {
    const params = new URLSearchParams();
    params.append('roleType', roleType);
    params.append('sinceDate', ensureLuxonDate(sinceDate).toUTC().toISO());
    return useGenericSWR(baseApiEndpoint + "/UserMetrics?" + params.toString(), apiClient, { ...swrOptions as SwrOptions });
};

const useRateExpirationMetrics = (swrOptions = {}) => {
    return useGenericSWR(baseApiEndpoint + "/RateExpirationMetrics", apiClient, { ...swrOptions as SwrOptions });
};

const useHelpRequestMetrics = (swrOptions = {}) => { 
    const { userInfo }: any = React.useContext(UserInfoContext);
    const params = new URLSearchParams();
    params.append('args', JSON.stringify(userInfo.cepOrgId));
    return useGenericSWR(baseApiEndpoint + "/HelpRequest?" + params.toString(), apiClient, { ...swrOptions as SwrOptions });
};

const mutateMetrics = () => {
    apiClient.mutateFetchUrls();
}

export {
    useUserMetrics,
    useRateExpirationMetrics,
    useHelpRequestMetrics,
    mutateMetrics
};