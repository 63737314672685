import * as React from 'react';
import { dateRangeFormatterIgnoreUtc } from 'lib/Formatters';
import { UcSelect } from 'components/common/UcSelect';
import { billPeriodApiEndpoint } from '../../readings/BillPeriodAPIEndpoint';
import { DateTime } from 'luxon';
import { useTranslation } from 'react-i18next';

export const BillPeriodAdminSelect = (props) => {
    const { t } = useTranslation();
    const filter = {
        take: 100,
        skip: 0,
        excludeFutureStart: props.excludeFutureStart || null,
        excludeFutureEnd: props.excludeFutureEnd || null,
        sort: [{ field: "StartTimestamp", sort: "desc" }],
        generalSearch: "",
        startTimeUTC: DateTime.fromJSDate(props.startDate).toUTC().toISO(),
        endTimeUTC: DateTime.fromJSDate(props.endDate).toUTC().toISO(),
        primaryBillStartTimeUtc: DateTime.fromJSDate(props.primaryBillStartTime).toUTC().toISO(),
        accountId: props.accountId,
        status: "ACTIVE",
        uom: props.uom
    };

    const filterRefresh = (props.accountId || "") + "~" + (props.primaryBillStartTime || "") + "~" + (props.uom || "");
    const valueRefresh = (props.accountId || "") + "~" + (props.primaryBillStartTime || "") + "~" + (props.uom || "");

    const getOptionLabel = (option) => {
        return dateRangeFormatterIgnoreUtc(option.startDate, option.endDate, true);
    }

    const handleBillingDataReceived = (data) => {
        if (data.length > 0) {
            props.setAreBillingPeriods(true);
        }
        else {
            props.setAreBillingPeriods(false);
        }
    }

    return (
        <UcSelect {...props}
            value={null}
            filterRefresh={filterRefresh}
            valueRefresh={valueRefresh}
            apiEndPoint={billPeriodApiEndpoint}
            defaultLabel={props.label || t("Bill Periods")}
            getOptionLabel={getOptionLabel}
            filter={filter}
            onDataReceived={handleBillingDataReceived}
        />
    );
}