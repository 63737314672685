import { useState } from 'react';
import MarkEmailReadIcon from '@mui/icons-material/MarkEmailRead';
import MarkEmailUnreadIcon from '@mui/icons-material/MarkEmailUnread';
import { UcInfiniteScrollList } from '../common/UcInfiniteScrollList';

export interface UcUserNotificationCardListProps {
    sort: { field: string; sort: string; }[];
    defaultDataItems: any;
    actionsHandler: any;
}

export const UserNotificationCardList = (props: UcUserNotificationCardListProps) => {

    const pageSize = 5;
    const [generalSearchInput] = useState("");
    const initialSort = [{ field: "isAcknowledged", sort: "asc" }];
    const sort = props.sort || initialSort;
    const initialDataState = { skip: 0, take: pageSize, };

    const filter = ({
        take: initialDataState.take,
        skip: initialDataState.skip,
        sort: sort,
        generalSearch: generalSearchInput,
    });

    
    return (
       <>
            <UcInfiniteScrollList
                apiEndPoint={'api/v1/UserNotification'}
                defaultDataItems={props.defaultDataItems}
                filter={filter}
                actionsHandler={props.actionsHandler}
                renderCondition='isAcknowledged'
                avatarIfRenderConditionTrue={<MarkEmailReadIcon />}
                avatarIfRenderConditionFalse={<MarkEmailUnreadIcon />}
             />
        </>
    );
}


