import { quickActionValidationSchema, getQuickActionDefaultItem, QuickAction } from './QuickActionsAPIEndpoints';
import PropTypes from 'prop-types';
import { Formik, Field } from 'formik';
import { useRef, useEffect, useState } from 'react';
import { TextField } from 'formik-mui';
import { useTranslation } from 'react-i18next';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { FormControl } from '@mui/material';

interface QuickActionsWidgetFormProps {
    id: number;
    itemToEdit: QuickAction;
    dialogSubmit: any;
    onSubmit: (values: any) => Promise<void>;
    onError: (error: any) => void;
}

export const QuickActionsWidgetForm = (props: QuickActionsWidgetFormProps) => {
    const { t } = useTranslation();
    const itemToEdit = props.itemToEdit || getQuickActionDefaultItem();
    const dialogSubmit = useRef(props.dialogSubmit);
    const formRef = useRef<any>();
    const [initialLoad, setInitialLoad] = useState(true);

    useEffect(() => {
        if (props.dialogSubmit !== dialogSubmit.current) {
            dialogSubmit.current = props.dialogSubmit;
            if (formRef.current) {
                formRef.current.handleSubmit();
            }
        }
        if (initialLoad) {
            setInitialLoad(false);
        }
    }, [initialLoad, props]);

    return (
        <Formik
            innerRef={formRef}
            validationSchema={quickActionValidationSchema}
            initialValues={itemToEdit}
            onSubmit={async (values) => {
                props.onSubmit(values);
            }}
        >  
            <FormControl fullWidth margin="normal">                         
                <Field
                    name={"title"}
                    data-cy-id={"quick_actions_admin_field_title"}
                    component={TextField}
                    label={t("Title")}
                    helperText={t("This text is displayed as the main heading of the tile.")}
                    style={{
                        marginBottom: 20
                    }}
                />           
                <Field
                    name={"description"}
                    data-cy-id={"quick_actions_admin_field_description"}
                    component={TextField}
                    label={t("Description")}
                    helperText={t("This text will appear under the title. It should explain what the tile represents or where the user will be navigated to when clicking the button.") }
                    multiline
                    minRows={2}
                    maxRows={2}
                    style={{
                        marginBottom: 25
                    }}
                />           
                <Field
                    name={"buttonName"}
                    data-cy-id={"quick_actions_admin_field_buttonName"}
                    component={TextField}
                    label={t("Button Name")}
                    helperText={t("Specify the label for the button on the tile. This should describe the button's action or destination.")}
                    style={{
                        marginBottom: 20
                    }}
                />          
                <Field
                    name={"buttonLink"}
                    data-cy-id={"quick_actions_admin_field_buttonLink"}
                    component={TextField}
                    label={t("Button Link")}
                    helperText={t("Enter the URL that the user will be directed to when they click the button. Make sure it is a valid and relevant link.")}
                />
                <div style={{ display: 'flex', justifyContent: 'flex-end', width: '100%', paddingTop: '20px' }}>
                    <Field
                        name={"isDisplayed"}
                        data-cy-id={"quick_actions_admin_field_isDisplayed"}
                    >
                        {({ field: { value }, form: { setFieldValue }}: { field: any; form: any }) => (
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        defaultChecked={itemToEdit.isDisplayed}
                                        value={value}
                                        onChange={(e, value) => { setFieldValue("isDisplayed", value); }}
                                    />
                                }
                                label={t("Display tile in widget?")}
                            />
                        )}
                    </Field>
                </div>      
            </FormControl>
        </Formik>
    );
}

QuickActionsWidgetForm.propTypes = {
    id: PropTypes.number,
    changeIteration: PropTypes.number,
    dialogSubmit: PropTypes.number,
};