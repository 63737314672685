import React, { useContext, useLayoutEffect, useEffect } from 'react';
import { PageContext } from "lib/PageProvider";
import { DateTime } from 'luxon';
import { CustomerSummaryFilterDialog } from './CustomerSummaryFilterDialog';
import { EnsureAccount } from 'components/customer/accounts/EnsureAccount';
import "./CustomerSummary.scss";
import { useTranslation } from "react-i18next";
import { useUser } from 'components/auth/PermissionApiEndpoint';
import { UcLoading } from 'components/common/UcLoading';
import { CustomerSummaryChart } from './CustomerSummaryChart';
import IconButton from '@mui/material/IconButton';
import { UcWidget } from '../common/UcWidget';
import { UcSummaryTable } from '../common/UcSummaryTable';
import { UcButtonChartDownload } from '../common/UcButtonChartDownload';
import { filterTypes, serviceTypes } from '../../utils/Enums';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { UserInfoContext } from '../../lib/UserInfoProvider';
import { useMyAccounts } from '../customer/accounts/MyAccountAPIEndpoints';
import { useMyTimezone } from 'components/customer/orgs/MyOrgAPIEndpoints';
import { dateRangeFormatterTimezone, dateFormatterTimezone, ensureLuxonDate, dateFormatter, uomToCommodity, commodityToUom } from '../../lib/Formatters';
import { getAccountPopoverTitle, AccountContext } from '../../lib/AccountProvider';
import { Box } from '@mui/material';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import { useResponsive } from 'lib/useResponsive';
import { UcHint } from 'components/common/UcHint';
import useOrgAppSettings from 'lib/useOrgAppSettings';
import { useLocation } from "react-router-dom";
import { UcMeterSelectV2 } from '../common/UcMeterSelectV2';
import { isMeterInRange } from 'utils/helpers/general.helpers';

export const CustomerSummaryPage = (props) => {
    const { account } = React.useContext(AccountContext);
    const [selectedOption, setSelectedOption] = React.useState(null);
    const [meterOptions, setMeterOptions] = React.useState([]);

    const { t } = useTranslation();
    const { orgAppSettings } = useOrgAppSettings();
    const [csvDataToDownload, setCsvDataToDownload] = React.useState([]);
    const [csvTempUom, setCsvTempUom] = React.useState('F');
    const [open, setOpen] = React.useState(false);
    const location = useLocation();

    const yearlyHeader = t("Yearly  Usage");
    const yearlyDescription = t("This chart shows how much electricity, water, and gas you used each month for the selected date range.");
    const monthlyHeader = t("Monthly Usage");
    const monthlyDescription = t("This chart shows how much electricity, water, or gas you used each month, for the selected date range and their costs in this range with their respective rates.");
    const dailyHeader = t("Daily Usage");
    const dailyDescription = t("This chart shows how much electricity, water, or gas you used each day for the selected date range and their costs in this range with their respective rates.");

    const user = useUser();
    const [pageState, pageDispatch] = useContext(PageContext);
    const showEST = user.isImpersonating;
    const [chartType, setChartType] = React.useState('day');
    const [uom, setUom] = React.useState(location.state?.locUom || null);
    const [chartHeader, setChartHeader] = React.useState(dailyHeader);
    const [chartDescription, setChartDescription] = React.useState(dailyDescription);
    const timezone = useMyTimezone();
    const { isMobile, isTablet } = useResponsive();
    const [rateCategories, setRateCategories] = React.useState(null);

    const today = new Date();
    today.setDate(today.getDate() - 1);
    const [yearStart, setYearStart] = React.useState(DateTime.fromObject({ year: today.getFullYear(), month: today.getMonth() + 1, day: 1 }, { zone: timezone }).minus({ months: 12 }).startOf("month").toJSDate());
    const [yearEnd, setYearEnd] = React.useState(DateTime.fromObject({ year: today.getFullYear(), month: today.getMonth() + 1, day: today.getDate() }, { zone: timezone }).endOf("month").toJSDate());
    const [monthStart, setMonthStart] = React.useState(DateTime.fromObject({ year: today.getFullYear(), month: today.getMonth() + 1, day: today.getDate() }, { zone: timezone }).startOf("month").toJSDate());
    const [monthEnd, setMonthEnd] = React.useState(DateTime.fromObject({ year: today.getFullYear(), month: today.getMonth() + 1, day: today.getDate() }, { zone: timezone }).endOf("month").toJSDate());
    const [hourStart, setHourStart] = React.useState(
        DateTime.fromObject(
            { year: today.getFullYear(), month: today.getMonth() + 1, day: today.getDate() },
            { zone: timezone })
            .startOf("day")
            .plus({ hours: 1 })
            .toJSDate());
    const [hourEnd, setHourEnd] = React.useState(
        DateTime.fromObject(
            { year: today.getFullYear(), month: today.getMonth() + 1, day: today.getDate() },
            { zone: timezone })
            .endOf("day")
            .plus({ hours: 1 })
            .toJSDate());
    const [startDate, setStartDate] = React.useState(hourStart);
    const [endDate, setEndDate] = React.useState(hourEnd);

    const [requestXmlDownload, setRequestXmlDownload] = React.useState(0);
    const [isXmlGenerating, setIsXmlGenerating] = React.useState(false);

    const [dateRangeStartText, setDateRangeStartText] = React.useState(hourStart);
    const [dateRangeEndText, setDateRangeEndText] = React.useState(hourEnd);

    let dateRangeText = dateRangeFormatterTimezone(ensureLuxonDate(dateRangeStartText, true), ensureLuxonDate(dateRangeEndText, true));

    const swrMyAccounts = useMyAccounts();

    const handleChangeChartType = (event) => {
        const type = event.target.value;

        if (type === filterTypes.YEAR) {
            setDateRanges(yearStart, yearEnd);
            setChartHeader(yearlyHeader);
            setChartDescription(yearlyDescription);
        }
        else if (type === filterTypes.MONTH) {
            setDateRanges(monthStart, monthEnd);
            setChartHeader(monthlyHeader);
            setChartDescription(monthlyDescription);
        }
        else if (type === filterTypes.DAY) {
            setDateRanges(hourStart, hourEnd);
            setChartHeader(dailyHeader);
            setChartDescription(dailyDescription);

        }

        setChartType(type);
    };

    let alertText = "";
    if (isMobile) {
        alertText = t("Tap a bar to get more info about it. \nSwipe left or right on the graph to reveal more dates if they're available.");
    }
    else {
        alertText = t("Click on a bar to get more info about it.");
    }

    const handleInitGeoCoordinates = () => {
        return (JSON.parse(account?.metersJson)
            .filter(meter =>
                meter.latitude !== undefined
                && meter.longitude !== undefined
                && meter.meterCommodity === uomToCommodity(uom)
            ).map(meter => {
                return {
                    latitude: meter.latitude,
                    longitude: meter.longitude
                }
            }));
    }

    const handleChangeGeoCoordinates = (newUom) => {
        setGeoCoordinates(JSON.parse(account?.metersJson)
            .filter(meter =>
                meter.latitude !== undefined
                && meter.longitude !== undefined
                && meter.meterCommodity === uomToCommodity(newUom)
            ).map(meter => {
                return {
                    latitude: meter.latitude,
                    longitude: meter.longitude
                }
            }));
    }

    const [geoCoordinates, setGeoCoordinates] = React.useState(handleInitGeoCoordinates() || []);

    let defaultValues = {
        accountId: swrMyAccounts?.result?.[0]?.id ?? 0,
        serialNo: "",
        uom: "KWH",
        uomName: "KWH",
        startDate: startDate,
        endDate: endDate,
        endingOn: dateFormatterTimezone(startDate, timezone)
    }

    const handleOpen = () => {
        setOpen(true);
    }

    const onChangeOpen = (isOpen) => {
        setOpen(isOpen);
    }

    const setDateRanges = (start, end) => {
        setStartDate(start);
        setEndDate(end);
    }

    const handleXMLClick = () => {
        setRequestXmlDownload(requestXmlDownload + 1);
    }

    const downloadReady = (downloadCheck) => {
        setIsXmlGenerating(downloadCheck);
    }

    useLayoutEffect(() => {
        pageDispatch({
            header: t("Home")
        });
    }, [pageState.header, pageDispatch, t]);

    const selectedAccount = account;

    const getGroupBy = () => {
        if (chartType === filterTypes.MONTH) {
            return "day";
        }
        if (chartType === filterTypes.DAY) {
            return "none";
        }
        return "month"; // default to month
    }

    const currentDate = new Date();
    const formattedCurrentDate = dateFormatter(currentDate, true);

    const getFileName = () => {
        switch (getGroupBy()) {
            case "month":
                return `myYearlyUsage_${account.extAccountNumber}_${formattedCurrentDate}.csv`;
            case "day":
                return `myMonthlyUsage_${account.extAccountNumber}_${formattedCurrentDate}.csv`;
            case "none":
                return `myDailyUsage_${account.extAccountNumber}_${formattedCurrentDate}.csv`;
            default:
                return `myUsage_${account.extAccountNumber}_${formattedCurrentDate}.csv`;
        }
    }

    // Update uom
    useLayoutEffect(() => {
        if (account?.meterServices?.length > 0) {
            let activeMetersForUom = (JSON.parse(account?.metersJson || '{}')
                .filter((item) => item.meterCommodity === uomToCommodity(uom) && isMeterInRange(item, startDate, endDate)));

            if (activeMetersForUom.length < 1) {
                let activeMeters = (JSON.parse(account?.metersJson || '{}')
                    .filter((item) => isMeterInRange(item, startDate, endDate))
                    .sort((a, b) => a.meterCommodity.localeCompare(b.meterCommodity)));

                if (activeMeters.length > 0) {
                    setUom(commodityToUom(activeMeters[0].meterCommodity));
                }
            }
        }
    }, [account?.meterServices, account?.metersJson, endDate, startDate, swrMyAccounts, uom]);

    // Runs whenever account, uom, or meter options change
    useEffect(() => {
        if (account && account.metersJson) {
            const commodity = uomToCommodity(uom);
            const meters = JSON.parse(account.metersJson);
            let metersAdjusted = [];

            let electricMeters = meters
                .filter((item) => item.meterCommodity === "ELECTRIC" && isMeterInRange(item, startDate, endDate));

            if (electricMeters.length > 1) {
                metersAdjusted = [(
                    {
                        id: "-1",
                        meterCommodity: "ELECTRIC",
                        serialNo: "All",
                    }
                )];
            }

            metersAdjusted = [...metersAdjusted, ...electricMeters];

            let waterMeters = meters
                .filter((item) => item.meterCommodity === "WATER" && isMeterInRange(item, startDate, endDate));

            if (waterMeters.length > 1) {
                metersAdjusted = [...metersAdjusted, (
                    {
                        id: "-2",
                        meterCommodity: "WATER",
                        serialNo: "All",
                    }
                )]
            }

            metersAdjusted = [...metersAdjusted, ...waterMeters];
           
            const metersAdjustedIds = metersAdjusted.map(meter => meter.id);
            const meterOptionsIds = meterOptions.map(meter => meter.id);

            const arraysHaveSameIds = metersAdjustedIds.every(id => meterOptionsIds.includes(id)) 
                                        && meterOptionsIds.every(id => metersAdjustedIds.includes(id));

            const meterExistsInNewOptions = metersAdjustedIds.includes(selectedOption?.id);

            if (!arraysHaveSameIds) {
                setMeterOptions(metersAdjusted);
                if (metersAdjusted.length > 0 && !meterExistsInNewOptions) {
                    let option = metersAdjusted.find((x) => x.meterCommodity === commodity);
                    setSelectedOption(option);
                }
            }
        }
    }, [account, endDate, meterOptions, selectedOption, startDate, uom]);

    const handleSelectChange = (option) => {
        setSelectedOption(option);
        setUom(option?.uom);
        handleChangeGeoCoordinates(option?.uom);
    };

    const actionItems = [
        {
            component: (
                <UcMeterSelectV2
                    account={account}
                    startDate={startDate}
                    endDate={endDate}
                    uom={uom}
                    forcedMargin='0.5rem 0 0.5rem 0'
                    forcedTransform={(!isMobile && !isTablet) && 'translateY(8px)'}
                    forcedWidth={(!isMobile && !isTablet) ? '50%' : '100%'}
                    value={selectedOption}
                    onChange={handleSelectChange}
                    options={meterOptions}
                />
            )
        },
        {
            component: (
                <ToggleButtonGroup
                    value={chartType}
                    size="small"
                    exclusive
                    onChange={handleChangeChartType}
                    aria-label="ChartType"
                    sx={{ pt: 0.5, pl: !isMobile && 0.5 }}
                >
                    <ToggleButton color='primary' value="day">{t('Day')}</ToggleButton>
                    <ToggleButton color='primary' value="month">{t('Month')}</ToggleButton>
                    <ToggleButton color='primary' value="year">{t('Year')}</ToggleButton>
                </ToggleButtonGroup>
            )
        },
        {
            component: (
                <Box /> //used to push commodity icons to next row
            )
        },
        {
            component: (
                <IconButton title={t('Date Filter')}
                    onClick={handleOpen}
                    aria-label="filter"
                    variant="contained"
                    sx={{
                        color: 'primary.main'
                    }}
                >
                    <CalendarMonthIcon />
                </IconButton>
            )
        },
        {
            component: (
                csvDataToDownload.length > 0 ?
                    <UcButtonChartDownload
                        data={csvDataToDownload}
                        selectedMeter={selectedOption?.serialNo}
                        uom={uom}
                        csvTempUom={csvTempUom}
                        filename={getFileName()}
                        onXMLClick={handleXMLClick}
                        isXmlGenerating={isXmlGenerating}
                        palletColor='primary.main'
                    />
                    : <></>
            )
        },
        {
            component: (
                <UcHint
                    hintText={chartDescription}
                    alertText={alertText}
                />
            )
        },
    ];
    const { userInfo } = React.useContext(UserInfoContext);
    const popoverTitle = getAccountPopoverTitle(selectedAccount);

    if (getGroupBy() === "none") {
        dateRangeText = dateRangeFormatterTimezone(hourStart, hourEnd, timezone);
    }

    return (
        <EnsureAccount>
            <UcLoading isLoading={swrMyAccounts.isLoading} hasFade={false}>
                <CustomerSummaryFilterDialog
                    open={open}
                    onChangeOpen={onChangeOpen}
                    filterTypes={filterTypes}
                    serviceTypes={serviceTypes}
                    chartType={chartType}
                    defaultValues={defaultValues}
                    isAdmin={props.isAdmin}
                    username={userInfo.username}
                    customerId={props.customerId}
                    uom={uom}
                    onSubmit={(values) => {
                        if (chartType === filterTypes.DAY) {
                            setHourStart(values.startDate);
                            setHourEnd(values.endDate);
                        }
                        else if (chartType === filterTypes.YEAR) {
                            setYearStart(values.startDate);
                            setYearEnd(values.endDate);
                        }
                        else if (chartType === filterTypes.MONTH) {
                            setMonthStart(values.startDate);
                            setMonthEnd(values.endDate);
                        }
                        setDateRanges(values.startDate, values.endDate);
                    }}
                />
                <UcWidget
                    actionItems={actionItems}
                    title={chartHeader + " - " + selectedAccount?.accountAddress}
                    showTitlesOnMobile={true}
                    subTitle={dateRangeText}
                    popoverTitle={popoverTitle}
                    contentPadding={'0 0 0 0'}
                    alignTitleLeft={true}
                >
                    <UcLoading isLoading={selectedAccount === null} hasFade={false}>
                        <CustomerSummaryChart
                            showStackedAvg={false}
                            setRateCategories={setRateCategories}
                            showPredictionToggle={false}
                            showTemperatureToggle={false}
                            requestXmlDownload={requestXmlDownload}
                            isXmlGenerating={downloadReady}
                            groupBy={getGroupBy()}
                            showEST={showEST}
                            geoCoordinates={geoCoordinates}
                            uom={uom}
                            accountId={selectedAccount?.id}
                            formattedCurrentDate={formattedCurrentDate}
                            accountNumber={selectedAccount?.extAccountNumber}
                            startDate={startDate}
                            endDate={endDate}
                            setDateRangeStartText={setDateRangeStartText}
                            setDateRangeEndText={setDateRangeEndText}
                            selectedMeter={selectedOption?.serialNo}
                            onCsvReady={(data, tempUom) => {
                                setCsvDataToDownload(data);
                                setCsvTempUom(tempUom);
                            }}
                        />
                    </UcLoading>
                </UcWidget>
                <br />
                <UcSummaryTable
                    rateCategories={rateCategories}
                    uom={orgAppSettings?.result?.uomMappings[uom]?.safeUomDisplay}
                />
            </UcLoading>
        </EnsureAccount>
    );
};