import { ApiClient } from "../../../lib/ApiClient";
import { useGenericSWR } from "../../../lib/useGenericSWR";
import { ensureLuxonDateOnly } from "lib/Formatters";
import * as Yup from 'yup';

export const HolidayApiEndpoint = 'api/v1/admin/Holiday';

let formIteration = 0;

const getHolidaysDefaultItem = () => {
    formIteration = formIteration + 1;
    return {
        formIteration: "new" + formIteration,
        id: 0,
        cepOrgId: 0,
        holidayDate: ensureLuxonDateOnly(new Date()),
        holidayName: '',
        holidayDescription: ''
    }
};

const holidaysValidationSchema = Yup.object().shape({
    holidayDate: Yup.date()
        .required("Required"),
    holidayName: Yup.string()
        .required("Required")
        .max(256, 'Max of 256 characters'),
    holidayDescription: Yup.string()
        .max(256, 'Max of 4096 characters'),
});

const apiClient = new ApiClient();
apiClient.getDefaultItem = () => getHolidaysDefaultItem();
apiClient.getObjectName = () => {
    return "Holiday";
};

const useHoliday = (id, swrOptions = {}) => {
    // SWR will prevent duplicate requests
    // It will also maintain data state as long as your are using the same urls for your requests
    return useGenericSWR(HolidayApiEndpoint + "/" + id, apiClient, { ...swrOptions });
};

const useHolidays = (filter, swrOptions = {}) => {
    // SWR will prevent duplicate requests
    // It will also maintain data state as long as your are using the same urls for your requests
    const params = new URLSearchParams();
    params.append('filter', JSON.stringify(filter));

    return useGenericSWR(HolidayApiEndpoint + "?" + params.toString(), apiClient, { ...swrOptions });
};

const saveHoliday = (Holiday) => {
    if (Holiday.id === 0) {
        return insertHoliday(Holiday);
    }

    return updateHoliday(Holiday);
}

const insertHoliday = (Holiday) => {
    return apiClient.poster(HolidayApiEndpoint, Holiday);
};

const updateHoliday = (Holiday) => {
    return apiClient.putter(HolidayApiEndpoint, Holiday);
};

const deleteHoliday = (Holiday) => {
    return apiClient.deleter(HolidayApiEndpoint + "/" + Holiday.id);
};

export {
    useHoliday,
    useHolidays,
    saveHoliday,
    insertHoliday,
    updateHoliday,
    deleteHoliday,
    holidaysValidationSchema,
    getHolidaysDefaultItem,
};