import React, { useState, useContext, useEffect, useLayoutEffect } from 'react';
import { useTranslation } from "react-i18next";
import { useSWRConfig } from "swr";
import AddIcon from '@mui/icons-material/Add';
import { PageContext } from 'lib/PageProvider';
import { mutateUrls } from "lib/useGenericSWR";
import { EnsureUser } from 'components/auth/EnsureUser';
import { UcToolbar } from 'components/common/UcToolbar';
import { useHasPermission } from 'components/auth/PermissionApiEndpoint';
import { getRateBasicRulesDefaultItem } from './RateBasicRuleAPIEndpoints';
import RateBasicRuleGridStateful from "./RateBasicRuleGridStateful"
import RateBasicRuleFormDialog from './RateBasicRuleFormDialog';
import { enumFormatter } from 'lib/EnumFormatter';
import { useEnums } from 'components/auth/PermissionApiEndpoint';
import { COLOR_PICKER_OPTIONS } from '../../common/Common.constants'

export default function RateBasicRuleList(props) {
    const { t } = useTranslation();
    const rateChargeTypeEnums = useEnums("RateChargeType");
    const rateChargeId = props.rateChargeId;
    const rateChargeType = props.rateChargeType;
    const rateChargeTypeDisplay = enumFormatter(rateChargeTypeEnums, rateChargeType);
    const [editDialog, setEditDialog] = useState({ open: false, delete: false });
    const [selectedItem, setSelectedItem] = useState(null);
    const [pageState, pageDispatch] = useContext(PageContext);
    const existingItems = props.existingItems;

    const [reMutateUrls, setReMutateUrls] = useState(false);
    const swrConfig = useSWRConfig();

    const closeHandler = () => {
        setEditDialog({ open: false, itemToEdit: editDialog.dataItem, existingItems: editDialog.existingItems });
    }

    const saveHandler = () => {
        closeHandler();
        setReMutateUrls(true);
    }

    const openDialog = (dataItem) => {
        if (!dataItem) {
            return;
        }
        if (!dataItem.formIteration) {
            dataItem.formIteration = "edit" + dataItem.id;
        }
        setEditDialog({ open: true, itemToEdit: dataItem, delete: false, existingItems: existingItems });
    }

    const deleteDialog = (dataItem) => {
        if (!dataItem) {
            return;
        }
        if (!dataItem.formIteration) {
            dataItem.formIteration = "delete" + dataItem.id;
        }
        setEditDialog({ open: true, itemToEdit: dataItem, delete: true, setDefault: false });
    }

    const createItem = () => {
        openDialog(getRateBasicRulesDefaultItem(rateChargeId));
    }

    const editItem = (item) => {
        openDialog(item);
    }

    const deleteItem = (item) => {
        deleteDialog(item);
    }

    const actions = [
        {
            icon: <AddIcon />,
            title: t('Add {{rateChargeTypeDisplay}} Rule', {rateChargeTypeDisplay: rateChargeTypeDisplay}),
            toolTip: t('Add {{rateChargeTypeDisplay}} Rule', {rateChargeTypeDisplay: rateChargeTypeDisplay}),
            onClick: () => { createItem(); },
            isHidden: !useHasPermission("RateBasicRule:Modify"),
            isDisabled: existingItems?.length - 1 >= COLOR_PICKER_OPTIONS.length
        }
    ];

    useLayoutEffect(() => {
        if (reMutateUrls) {
            mutateUrls(swrConfig);
            setReMutateUrls(false);
        }
    }, [reMutateUrls, swrConfig]);

    useEffect(() => {
        pageDispatch({
            header: `Rate ${rateChargeTypeDisplay} Rule`,
        });
    }, [pageState.header, selectedItem, pageDispatch, rateChargeTypeDisplay]);

    return (
        <div>
            <EnsureUser permissions={["RateBasicRule:Read"]}>
                <UcToolbar actions={actions} />
                <RateBasicRuleFormDialog
                    rateChargeType={rateChargeType}
                    open={editDialog.open}
                    delete={editDialog.delete}
                    itemToEdit={editDialog.itemToEdit}
                    existingItems={existingItems}
                    onCancel={closeHandler}
                    onSave={saveHandler}
                />
                {!reMutateUrls &&
                    <RateBasicRuleGridStateful
                        onEditItem={editItem}
                        onDeleteItem={deleteItem}
                        rateChargeId={rateChargeId}
                        rateChargeType={rateChargeType}
                        onSelected={(selectedDataItem) => {
                            setSelectedItem({ ...selectedDataItem });
                        }} />
                }

            </EnsureUser>
        </div>
    );
};