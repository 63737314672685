export const defaultOneSignalInfo = {
    isOneSignal: false,    
    info: {}
}

let oneSignalInfo = {
    ...defaultOneSignalInfo
}

export const getOneSignalInfo = () => {
    return oneSignalInfo;
}

export const setOneSignalInfo = (info) => {
    oneSignalInfo.isOneSignal = true;
    oneSignalInfo.info = info
}