// @ts-ignore
import React from 'react';
import { useTranslation } from 'react-i18next';
import { PageContext, PageState, PageAction } from '../../../lib/PageProvider';
// @ts-ignore
import { OrgModuleGrid } from './OrgModuleGrid';
import EnsureUserAndModule from 'components/auth/EnsureUserAndModule';
import {mutateUrls} from '../../../lib/useGenericSWR';
import {useSWRConfig} from 'swr';
import {OrgModuleFormDialog} from './OrgModuleFormDialog';

export const OrgModuleListPage = () => {
    const { t } = useTranslation();
    const [pageState, pageDispatch] = React.useContext<[PageState, React.Dispatch<PageAction>]>(PageContext);
    const [editDialog, setEditDialog] = React.useState<any>({
        open: false,
        delete: false,
    });

    const swrConfig = useSWRConfig();
    const [reMutateUrls, setReMutateUrls] = React.useState(false);

    React.useEffect(() => {
        pageDispatch({
            header: t('Org Module'),
            actionItems: [] as any[],
            variableHeader: "",
            tabs: [],
            onTabChange: () => { },
            tabValue: ''
        });
    }, [pageState.header, pageDispatch, t]);

    const closeHandler = () => {
        setEditDialog({ open: false, itemToEdit: editDialog.dataItem });
    };

    const openDialog = (dataItem: any) => {
        if (dataItem && !dataItem.formIteration) {
            dataItem.formIteration = "edit" + dataItem.id;
        }
        setEditDialog({ open: true, itemToEdit: dataItem, delete: false });
    };

    const saveHandler = () => {
        closeHandler();
        setReMutateUrls(true);
    };

    const editItem = (item: any) => {
        openDialog(item);
    };

    React.useLayoutEffect(() => {
        if (reMutateUrls) {
            mutateUrls(swrConfig);
            setReMutateUrls(false);
        }
    }, [reMutateUrls, swrConfig]);

    console.log("OrgModuleListPage - Loaded");
    return (
        <EnsureUserAndModule permissions={["OrgModule:Read"]} modules={['OrgModule']}>
        {!reMutateUrls &&
            <OrgModuleGrid
                    onEditItem={editItem}
                />
        }
            <OrgModuleFormDialog
                    open={editDialog.open}
                    itemToEdit={editDialog.itemToEdit}
                    onCancel={closeHandler}
                    onSave={saveHandler}
                />
        </EnsureUserAndModule>
    );
}