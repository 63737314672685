import React, { useState } from 'react';
import LoadingButton from '@mui/lab/LoadingButton';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Paper from '@mui/material/Paper';
import Draggable from 'react-draggable';
import PropTypes from 'prop-types';
import { MyOrgForm } from './MyOrgForm';
import { updateMyOrg, getMyOrgsDefaultItem } from 'components/customer/orgs/MyOrgAPIEndpoints';
import { useResponsive } from 'lib/useResponsive';
import { useTranslation } from "react-i18next";

function PaperComponent(props) {
    return (
        <Draggable
            handle="#draggable-dialog-title"
            cancel={'[class*="MuiDialogContent-root"]'}
        >
            <Paper {...props} />
        </Draggable>
    );
}

export const MyOrgFormDialog = (props) => {
    const { t } = useTranslation();
    const [saving, setSaving] = React.useState(false);
    const [dialogSubmit, setDialogSubmit] = useState(0);
    const { isMobile } = useResponsive();

    let formId = props.id;
    if (!props.open) {
        formId = 0;
    }

    const handleCancel = () => {
        props.onCancel();
    };

    const handleSave = () => {
        setDialogSubmit(dialogSubmit + 1);
    };

    return (
        <>
            {props.open &&
                <Dialog
                    fullScreen={isMobile}
                    fullWidth={true}
                    maxWidth={"md"}
                    open={props.open}
                    PaperComponent={PaperComponent}
                    aria-labelledby="draggable-dialog-title"
                >
                    <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
                        Editing {props.itemToEdit?.name}
                    </DialogTitle>
                    <DialogContent>
                        <MyOrgForm
                            id={formId}
                            itemToEdit={props.itemToEdit}
                            dialogSubmit={dialogSubmit}
                            onSubmit={async (values) => {
                                setSaving(true);
                                try {
                                    let data = await updateMyOrg(values);

                                    if (data) {
                                        props.onSave(data);
                                    }
                                } finally {
                                    setSaving(false);
                                }
                            }}
                            onError={(error) => {
                                props.onError(error);

                                setSaving(false);
                            }}
                        />
                    </DialogContent>
                    <DialogActions>
                    <Button 
                        autoFocus
                        data-cy-id={"cancel_button"}
                        onClick={handleCancel}
                        variant="outlined"
                    >
                        {t("Cancel")}
                    </Button>
                        <LoadingButton
                            data-cy-id={"save_button"}
                            loading={saving}
                            onClick={handleSave}
                            variant="contained"
                        >
                            {t("Save")}
                        </LoadingButton>
                    </DialogActions>
                </Dialog>
            }
        </>
    );
}

MyOrgFormDialog.defaultProps = {
    open: false,
    id: 0,
    itemToEdit: getMyOrgsDefaultItem(),
    onCancel: () => { console.log("onCancel not implemented"); },
    onSave: (data) => { console.log("onSave not implemented"); },
    onError: (error) => { console.error("onError not implemented", error); },
};

MyOrgFormDialog.propTypes = {
    open: PropTypes.bool,
    id: PropTypes.number,
    itemToEdit: PropTypes.object,
};