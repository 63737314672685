import { getOrgUomsDefaultItem, orgUomsValidationSchema } from './OrgUomAPIEndpoints';
import PropTypes from 'prop-types';
import { Formik, Form, Field } from 'formik';
import { useRef, useEffect } from 'react';
import { TextField } from 'formik-mui';
import { MeterClassSelect } from 'components/customer/accounts/MeterClassSelect';

/**
 * This component handles the form for creating and editing
 * */
export const OrgUomForm = (props) => {
    const cepOrgId = props.cepOrgId;
    const itemToEdit = props.itemToEdit || getOrgUomsDefaultItem();
    const dialogSubmit = useRef(props.dialogSubmit);
    const formRef = useRef();

    useEffect(() => {
        if (props.dialogSubmit !== dialogSubmit.current) {
            dialogSubmit.current = props.dialogSubmit;
            if (formRef.current) {
                formRef.current.handleSubmit();
            }
        }
    });


    return (
        <>
        <Formik
            innerRef={formRef}
            initialValues={itemToEdit}
            validationSchema={orgUomsValidationSchema}
            onSubmit={(values) => {
                values.cepOrgId = cepOrgId;
                props.onSubmit(values);
            }}
        >
        <Form readonly={props.readonly}>
            <Field
                name={"uom"}
                component={TextField}
                disabled={props.disabled}
                label={"Uom"}
            />
            <Field
                name={"uomDisplay"}
                component={TextField}
                disabled={props.disabled}
                label={"Uom Display"}
            />
            <Field
                name={"externalUomMapping"}
                component={TextField}
                disabled={props.disabled}
                label={"External UOM Mapping"}
            />
            <Field
                name={"externalUomMappingDisplay"}
                component={TextField}
                disabled={props.disabled}
                label={"External UOM Mapping Display"}
            />
            <Field
                name={"meterClass"}
                >
                    {({ field: { value }, form: { setFieldValue } }) => (
                    <MeterClassSelect
                        value={{ id: value, meterCommodity: itemToEdit.meterCommodity }}
                        onChange={(e, meterClassValue) => {
                            setFieldValue("meterClass", meterClassValue.id);

                        }}
                    />
                )}

            </Field>
        </Form>
        </Formik>
        </>
    );
}

OrgUomForm.defaultProps = {
    id: 0,
    dialogSubmit: 0,
    changeIteration: 0,
    onSubmit: (values) => { console.log("onSubmit not implemented", values); },
    onError: (error) => { console.error("onError not implemented", error); },
};

OrgUomForm.propTypes = {
    id: PropTypes.number,
    changeIteration: PropTypes.number,
    dialogSubmit: PropTypes.number,
};