import { UserManager } from "oidc-client-ts";
import { AuthProviderProps } from "react-oidc-context";
import {
    getDomainObj,
    validSubDomains,
    permanentSubDomains
} from '../../domain';

let redirectUri = process.env.REACT_APP_UCAUTH_REDIRECT_URI ?? '';
let signinOidcUri = `${process.env.REACT_APP_UCAUTH_REDIRECT_URI ?? ''}/signin-oidc`;
//let silentRenewUri  = `${process.env.REACT_APP_UCAUTH_REDIRECT_URI ?? ''}/silent-renew`;

let domain = getDomainObj();

if (permanentSubDomains.includes(domain.actualSubDomain)) {
    redirectUri = redirectUri.replace("{subdomain}", "");
    if (domain.subDomain) {
        redirectUri += "?subdomain=" + domain.subDomain;
        signinOidcUri += "?subdomain=" + domain.subDomain;
        //silentRenewUri += "?subdomain=" + domain.subDomain;
    }
}
else if (validSubDomains.includes(domain.actualSubDomain)) {
    redirectUri = redirectUri.replace("{subdomain}", domain.actualSubDomain + ".");
    signinOidcUri = signinOidcUri.replace("{subdomain}", domain.actualSubDomain + ".");
    //silentRenewUri = silentRenewUri.replace("{subdomain}", domain.actualSubDomain + ".");
}
else {
    redirectUri = redirectUri.replace("{subdomain}", "");
    signinOidcUri = signinOidcUri.replace("{subdomain}", "");
    //silentRenewUri = silentRenewUri.replace("{subdomain}", "");
}

// For now always make sure we have subdomain in redirect uri
if (domain.subDomain && !redirectUri.includes("?subdomain=")) {
    redirectUri += "?subdomain=" + domain.subDomain;
    signinOidcUri += "?subdomain=" + domain.subDomain;
    //silentRenewUri += "?subdomain=" + domain.subDomain;
}

export const OIDC_CONFIG: AuthProviderProps = {
    authority: process.env.REACT_APP_UCAUTH_AUTHORITY??'',
    client_id: process.env.REACT_APP_UCAUTH_CLIENT_ID??'',
    redirect_uri: signinOidcUri,
    scope: process.env.REACT_APP_UCAUTH_SCOPE,
    loadUserInfo: process.env.REACT_APP_UCAUTH_LOAD_USER_INFO === "true",
    post_logout_redirect_uri: redirectUri,
    monitorSession: false, // See CustomCheckSessionIFrame.ts
    automaticSilentRenew: true,
    //silent_redirect_uri: silentRenewUri,
  };

export const OIDC_CHECK_SESSION_URL = `${process.env.REACT_APP_UCAUTH_AUTHORITY ?? ''}/connect/checksession?redirect_uri=${encodeURIComponent(redirectUri)}`;

export const userManager = new UserManager(OIDC_CONFIG);