import React, { useState, useContext, useEffect, useLayoutEffect } from 'react';
import { PageContext } from '../../../lib/PageProvider';
import { AccountGridStateful } from "./AccountGridStateful"
import { mutateUrls } from "../../../lib/useGenericSWR";
import { useSWRConfig } from "swr";
import { UcListPage } from '../../common/UcListPage';
import './AccountListPage.scss';
import { EnsureUser } from '../../auth/EnsureUser';
import { UcToolbar } from '../../common/UcToolbar';
import { UcDrawerHeader } from 'components/common/UcDrawerHeader';
import { Box, Divider, Drawer} from '@mui/material';
import { useTranslation } from "react-i18next";
import { AccountDetailsPage } from './AccountDetailsPage';

export const AccountListPage = (props) => {
    const { t } = useTranslation();
    const [selectedItem, setSelectedItem] = useState(null);
    const [pageState, pageDispatch] = useContext(PageContext);
    const [generalSearch, setGeneralSearch] = useState('');
    const [drawerOpen, setDrawerOpen] = useState(false);

    const [reMutateUrls, setReMutateUrls] = useState(false);
    const swrConfig = useSWRConfig();

    useLayoutEffect(() => {
        if (reMutateUrls) {
            mutateUrls(swrConfig);
            setReMutateUrls(false);
        }
    }, [reMutateUrls, swrConfig]);

    useEffect(() => {
        pageDispatch({
            header: t("Accounts"),
        });
    }, [pageState.header, selectedItem, pageDispatch, t]);

    const handleDrawerClose = () => {
        setSelectedItem(null);
        setDrawerOpen(false);
    }

    const doubleClick = () => {
        if (selectedItem !== null && selectedItem?.id !== 0) {
            setDrawerOpen(true);
        }
    };

    const actions = [];
    const drawerWidth = "50vw";

    console.log("AccountListPage - Loaded");
    return (
        <UcListPage>
            <EnsureUser permissions={["Account:Read"]}>
                <h1 className="pageHeader">{t("Accounts")}</h1>
                <UcToolbar
                    hasGeneralSearch={true}
                    onGeneralSearchChange={(value) => {
                        setGeneralSearch(value);
                    }}
                    actions={actions}>
                </UcToolbar>
                {!reMutateUrls &&
                    <AccountGridStateful
                        setDrawerOpen={setDrawerOpen}
                        doubleClick={doubleClick}
                        generalSearch={generalSearch}
                        onSelected={(selectedSearchItem) => setSelectedItem({ ...selectedSearchItem })} />
                }
                <Drawer
                    anchor="right"
                    open={drawerOpen}
                    onClose={handleDrawerClose}
                >
                    <UcDrawerHeader justify="flex-start"/>
                    <Box
                        sx={{ width: drawerWidth, margin: '10px' }}
                        role="presentation"
                    >
                        <Divider />
                        {selectedItem &&
                            <AccountDetailsPage accountId={selectedItem.id} showDetails={false} />
                        }
                    </Box>
                </Drawer>
            </EnsureUser>
        </UcListPage>
    );
};