import React from 'react';
import { setOneSignalInfo } from './OneSignalInfo';
import { useAuth } from "react-oidc-context";
import { insertOneSignalUser } from 'components/oneSignal/oneSignalUserAPIEndpoints';
import { useUser } from 'components/auth/PermissionApiEndpoint';
import { UserInfoContext } from './UserInfoProvider';

export const GoNativeProvider = ({ children }) => {
    const { userInfo } = React.useContext(UserInfoContext);
    const auth = useAuth();
    const user = useUser();
    const [isInitialState, setIsInitialState] = React.useState(true);
    const [sendOneSignalPost, setSendOneSignalPost] = React.useState(false);
    const [oneSignalUserId, setOneSignalUserId] = React.useState("");

    if (sendOneSignalPost) {
        insertOneSignalUser(oneSignalUserId);
        setSendOneSignalPost(false);
    }

    const maxGoNativeTries = 20;
    function tryGoNative(retries) {
        if (retries > maxGoNativeTries) {
            return;
        }

        setTimeout(() => {
            if (window.gonative) {
                window.gonative.onesignal?.onesignalInfo().then(function (oneSignalInfo) {
                    setOneSignalInfo(oneSignalInfo);

                    if (!sendOneSignalPost && !userInfo.oneSignalUsers?.includes(oneSignalInfo.oneSignalUserId)) {
                        setOneSignalUserId(oneSignalInfo.oneSignalUserId);
                        setSendOneSignalPost(true);
                    }
                });
            }
            else {
                tryGoNative(retries + 1);
            }
        }, 60);
    }

    if (isInitialState && auth.isAuthenticated && user.isLoaded) {
        tryGoNative(0);
        setIsInitialState(false);
    }

    return (
        <>
            {children}
        </>
    );
};