import { ApiClient } from "../../../lib/ApiClient";
import { useGenericSWR } from "../../../lib/useGenericSWR";
import * as Yup from 'yup';
import { mutateRateCharge } from './RateChargeApiEndpoints';

const apiEndpoint = 'api/v1/admin/RateTierRule';

let formIteration = 0;

const getRateTierRulesDefaultItem = (rateChargeId) => {
    formIteration = formIteration + 1;
    return {
        formIteration: "new" + formIteration,
        id: 0,
        rateChargeId: rateChargeId,
        name: '',
        description: '',
        rate: 1,
        startingUnit: 1,
        htmlColor: null,
        createdBy: 'REACT_FE',
        updatedBy: 'REACT_FE',
    }
};

const rateTierRulesValidationSchema = (allowZero, rateChargeType, t) => 
{
    return Yup.object().shape({
        rateChargeId: Yup.number()
            .required(t('Rate Charge is required'))
            .min(1, t('Rate Charge is required')),
        name: Yup.string()
            .required(t("Required"))
            .max(256, t('Max of 256 characters')),
        htmlColor: Yup.string()
            .required(t("Required")),
        rate: Yup.number()
            .required(t('Rate is required'))
            .when([], {
                is: () => rateChargeType === 'DISCOUNT',
                then: Yup.number()
                    .negative(t('Rate must be a negative number')),
                otherwise: Yup.number()
                .min(0, 'Rate is required')
            }),
        startingUnit: Yup.number()
            .required(t("Required"))
            .min((allowZero ? 0 : 1), "Unit must be greater than 0."),
    });
}

// See definition from RateTierRule.cs
const apiClient = new ApiClient();
apiClient.getDefaultItem = () => getRateTierRulesDefaultItem();
apiClient.getObjectName = () => {
    return "Tier Rule";
};

const useRateTierRule = (id, swrOptions = {}) => {
    // SWR will prevent duplicate requests
    // It will also maintain data state as long as your are using the same urls for your requests
    return useGenericSWR(apiEndpoint + "/" + id, apiClient, { ...swrOptions });
};

const useRateTierRules = (filter, swrOptions = {}) => {
    // SWR will prevent duplicate requests
    // It will also maintain data state as long as your are using the same urls for your requests
    const params = new URLSearchParams();
    params.append('filter', JSON.stringify(filter));
    return useGenericSWR(apiEndpoint + "?" + params.toString(), apiClient, { ...swrOptions });
};

const saveRateTierRule = (RateTierRule) => {
    if (RateTierRule.id === 0) {
        return insertRateTierRule(RateTierRule);
    }
    return updateRateTierRule(RateTierRule);
}
const insertRateTierRule = (RateTierRule) => {
    return apiClient.poster(apiEndpoint, RateTierRule)
    .then((result) => {
        mutateRateCharge();
        return result;
    });
};

const updateRateTierRule = (RateTierRule) => {
    return apiClient.putter(apiEndpoint, RateTierRule)
    .then((result) => {
        mutateRateCharge();
        return result;
    });
};

const deleteRateTierRule = (RateTierRule) => {
    return apiClient.deleter(apiEndpoint + "/" + RateTierRule.id)
    .then((result) => {
        mutateRateCharge();
        return result;
    });
};

export {
    useRateTierRule,
    useRateTierRules,
    saveRateTierRule,
    insertRateTierRule,
    updateRateTierRule,
    deleteRateTierRule,
    rateTierRulesValidationSchema,
    getRateTierRulesDefaultItem,
};