import React, { useState, useLayoutEffect, useRef, useEffect } from 'react';
import { DataGridPro } from '@mui/x-data-grid-pro';
import { useOutgoingNotifications } from './OutgoingNotificationAPIEndpoints';
import { dateFormatter } from '../../../lib/Formatters';
import { UcPagination } from 'components/common/UcPagination';

export const OutgoingNotificationGrid = (props) => {
    const initialSort = [{ field: "sentDate", sort: "desc" }];
    const sort = props.sort || initialSort;

    const initialDataState = props.dataState || { skip: 0, take: 10, };

    const [filter, setFilter] = useState({
        take: initialDataState.take,
        skip: initialDataState.skip,
        sort: sort,
        generalSearch: props.generalSearch,
    });

    useEffect(() => {
        if (props.generalSearch !== filter.generalSearch) {
            setFilter({ ...filter, generalSearch: props.generalSearch });
        }
    }, [filter, setFilter, props.generalSearch]);

    let initialFilterModel = {
        items: [
            {columnField: 'name', operatorValue:'contains', value: ''}
        ],
        linkOperator: 'and',
    };

    const [filterModel] = useState(initialFilterModel);

    const needsDataUpdate = useRef("");

    let defaultDataItems = {
        columnVisibilityModel: {
            id: false,
            cepOrgId: false,
            content: false,
            createdBy: false,
            updatedBy: false,
        },
        columns: [
            {field: 'id', headerName: 'Id'},
            {field: 'toAddress', headerName: 'To Address', width: 200},
            {field: 'fromAddress', headerName: 'From Address', flex: 1, minWidth: 200 },
            {field: 'deliveryMethod', headerName: 'Delivery Method', flex: 1, minWidth: 200 },
            {field: 'subject', headerName: 'Subject', width: 200 },
            {field: 'isSent', headerName: 'Sent', width: 120},
            {field: "sentDate", headerName: 'Sent Date', width: 200, valueFormatter: ( params ) => {return dateFormatter(params.value);}},
            {field: "retryCount", headerName: 'Send Attempts', width: 100},
            {field: "lastRetryDate", headerName: 'Last Retry', width: 200, valueFormatter: ( params ) => {return dateFormatter(params.value);}},
            {field: 'errorMessage', headerName: 'Error Message', width: 400},
        ],
        rows: [],
        rowCount: -1,
    };
    const [dataItems, setDataItems] = useState(defaultDataItems);

    const defaultSelectedItem = { dataItem: {}, selectedId: 0 };
    const [selectedItem, setSelectedItem] = useState(defaultSelectedItem);

    const isDataLoaded = useRef(false);

    const dataReceived = (swrResult) => {
        var dataItems = swrResult.result;

        var selectedDataItems = dataItems.map((item) => {
            return { ...item, selected: item.id === selectedItem.selectedId };
        });
        selectedDataItems.iteration = dataItems.iteration;
        selectedDataItems.rowCount = swrResult.rowCount;
        if (selectedDataItems.length > selectedDataItems.rowCount) {
            selectedDataItems.rowCount = selectedDataItems.length;
        }

        setDataItems({...defaultDataItems, rows: [...dataItems], rowCount: swrResult.rowCount});
    };

    const swrOutgoingNotifications = useOutgoingNotifications(filter); //Pass the datastate directly as the filter.  The API will adjust filters, orderbys ect.
    if (!swrOutgoingNotifications.result && defaultDataItems.rowCount !== -1)
    {
        setDataItems(defaultDataItems);
    }

    useLayoutEffect(() => {
        if (swrOutgoingNotifications.result) {
            isDataLoaded.current = true;
            var filterString = JSON.stringify(filter);
            if (needsDataUpdate.current !== filterString) {
                needsDataUpdate.current = filterString;
                dataReceived(swrOutgoingNotifications);
            }
        }
    });

    const updateFilter = (filter) => {
        setFilter(filter);
        props.onFilterChange(filter);
    };

    return (
        <div
            data-cy-id={"outgoingNotificationUserGridWrapper"}
        >
            <DataGridPro
                autoHeight={true}
                loading={!isDataLoaded.current}
                paginationModel={{page: filter.skip / filter.take, pageSize: filter.take}}
                onPaginationModelChange={(model) => updateFilter({ ...filter, skip: model.page * model.pageSize, take: model.pageSize })}
                sortingMode='server'
                paginationMode='server'
                filterMode='server'
                sortModel={filter.sort}
                onSortModelChange={(model) => updateFilter({...filter, sort: model})}
                filterModel={filterModel}

                rowsPerPageOptions={[5, 10, 20]}
                onRowSelectionModelChange={(newSelectionModel) => {
                    if (newSelectionModel.length !== 1)
                    {
                        setSelectedItem(defaultSelectedItem);
                        props.onSelected(defaultSelectedItem.dataItem);
                        return;
                    }
                    const selectedId = newSelectionModel[0];
                    var selectedItem = dataItems.rows.filter(obj => {return obj.id === selectedId});
                    if (selectedItem.length !== 1)
                    {
                        setSelectedItem(defaultSelectedItem);
                        props.onSelected(defaultSelectedItem.dataItem);
                        return;
                    }
                    selectedItem = selectedItem[0];
                    var newlySelectedItem = { dataItem: selectedItem, selectedId: selectedId };

                    setSelectedItem(newlySelectedItem);
                    props.onSelected(newlySelectedItem.dataItem);
                }}
                pagination
                components={{
                    Toolbar: () => {return  props.toolbar || <></>},
                    Pagination: UcPagination,
                }}
                {...dataItems}
            />
        </div>
    );
}

OutgoingNotificationGrid.defaultProps = {
    onSelected: (selectedItem) => { console.log("onSelected not implemented", selectedItem); },
    onFilterChange: (filter) => { console.log("onFilterChange not implemented", filter); },
};
