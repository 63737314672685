import React from 'react';

import './UcListPage.scss';

export const UcListPage = (props) => {
    return (
        <div className="ucListPage">
            { props.children }
        </div>
    );
};