import React from 'react';
// @ts-ignore
import { useMyTimezone } from 'components/customer/orgs/MyOrgAPIEndpoints';
// @ts-ignore
import { DateTime } from 'luxon';
// @ts-ignore
import { ensureLuxonDateOnlyTimezone } from 'lib/Formatters'
// @ts-ignore
import { globalTranslator } from "lib/GlobalTranslator";
// @ts-ignore
import { dateFormatter } from 'lib/Formatters';

export interface Account {
    id: number | null,
    metersJson: string,
    extAccountNumber: string,
    accountName: string,
    accountNickname?: string,
    accountAddress?: string,
    isImpersonate?: boolean,
    delegatesJson: string,
    maxEndTimestamp: DateTime,
    minStartTimestamp: DateTime,
    meterServices: string[],
    metaDataSet: boolean,
    username?: string
}
export interface AccountContextType {
    account: Account | null;
    setAccount: React.Dispatch<React.SetStateAction<Account | null>>;
}

// must have this in a seperate file or you get circular reference errors
export const defaultAccount: Account  = {
    id: null,
    metersJson: "[]",
    extAccountNumber: "",
    accountName: "",
    accountNickname: "",
    accountAddress: "",
    isImpersonate: false,
    delegatesJson: "{}",
    maxEndTimestamp: DateTime.now(),
    minStartTimestamp: DateTime.now(),
    meterServices: [],
    metaDataSet: false,
}

export const AccountContext = React.createContext<AccountContextType>({
    account: null,
    setAccount: () => {},
});

interface AccountProviderProps {
    children: React.ReactNode;
}

export const getAccountActiveString = (account: Account | null) => {
    if (!account) {
        return "N/A";
    }

    const t = globalTranslator;

    const metersJson = JSON.parse(account.metersJson) || [];

    const startTimestampArray = metersJson.map((r: any) => r.startTimestamp);
    let startTimestamp = DateTime.local().minus({ months: 12 }).startOf("month").toJSDate();
    // TODO: Filter the start / end times by UOM?  Might have to add details to metersJson
    if (!startTimestampArray) {
        startTimestamp = Math.min(...startTimestampArray.map((date: any) => Date.parse(date)));
    }

    const endTimestampArray = metersJson.map((r: any) => r.endTimestamp);
    const endTimestamp = new Date(Math.max(...endTimestampArray.map((date: any) => Date.parse(date))));
    const today = new Date();

    if (endTimestamp < today) {
        return t("Active from {{start}} to {{end}}", { start: dateFormatter(startTimestamp, true), end: dateFormatter(endTimestamp, true) });
    }
    return t("Active since {{start}}", { start: dateFormatter(startTimestamp, true)});
}

export const getAccountPopoverTitle = (account: Account) => {
    if (!account) {
        return "N/A";
    }

    return account.extAccountNumber + "\n" +
           account.accountName + "\n" +
           account?.accountAddress + "\n" +
           getAccountActiveString(account);
}

export const AccountProvider = (props: AccountProviderProps) => {
    const [account, setAccount] = React.useState<Account | null>(defaultAccount);
    const timezone = useMyTimezone();

    const extractMetadata = (account: Account | null, timezone: string) => {
        if (account === null || account.id === null || timezone === null) {
            return false;
        }

        if (account.metaDataSet) {
            return false;
        }

        account.metaDataSet = true;
        const metersJson = JSON.parse(account.metersJson) || [];
        account.meterServices = metersJson.map((r: any) => r.meterCommodity);
        const startTimestampArray = metersJson.map((r: any) => r.startTimestamp);
        let startTimestamp = DateTime.fromObject({}, {zone: timezone}).minus({ months: 12 }).startOf("month").toJSDate();

        if (startTimestampArray?.length > 0) {
            const minDate = Math.min(...startTimestampArray.map((date: any) => Date.parse(date)));
            startTimestamp = ensureLuxonDateOnlyTimezone(minDate, timezone);
        }


        const endTimestampArray = metersJson.map((r: any) => r.endTimestamp);
        const maxDate = Math.max(...endTimestampArray.map((date: any) => Date.parse(date)));
        const endTimestamp = ensureLuxonDateOnlyTimezone(maxDate, timezone);

        account.minStartTimestamp = startTimestamp as DateTime;
        account.maxEndTimestamp = endTimestamp as DateTime;

        return true;
    }

    if (extractMetadata(account, timezone)) {
        setAccount(account);
    }

    return (
        <AccountContext.Provider value={{account, setAccount}}>
            { props.children }
        </AccountContext.Provider>
    );
};