import React, { useState, useLayoutEffect, useRef } from 'react';
import { DataGridPro } from '@mui/x-data-grid-pro';
import { useHolidays } from './HolidayAPIEndpoints';
import { UcPagination } from 'components/common/UcPagination';
import { useTranslation } from "react-i18next";
import { UcIconButtons } from 'components/common/UcIconButtons';
import { getWholeRow } from '../../../lib/DataGridHelpers';
import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/Delete';
import { dateFormatter} from '../../../lib/Formatters';

export const HolidayGrid = (props) => {
    const { t } = useTranslation();
    const [generalSearchInput] = useState(props.generalSearch || "");
    const initialSort = [{ field: "holidayName", sort: "asc" }];
    const sort = props.sort || initialSort;

    const initialDataState = props.dataState || { skip: 0, take: 10, };

    const [filter, setFilter] = useState({
        take: initialDataState.take,
        skip: initialDataState.skip,
        sort: sort,
        generalSearch: generalSearchInput,
    });

    let initialFilterModel = {
        items: [
            { columnField: 'holidayName', operatorValue: 'contains', value: '' }
        ],
        linkOperator: 'and',
    };

    if (generalSearchInput) {
        initialFilterModel = {
            items: [
                { columnField: 'holidayName', operatorValue: 'contains', value: generalSearchInput }
            ],
            linkOperator: 'and',
        }
    }

    const [filterModel] = useState(initialFilterModel);

    const needsDataUpdate = useRef("");

    let defaultDataItems = {
        columnVisibilityModel: {
            id: true,
            cepOrgId: false,
            holidayDescription: false,
            createdBy: false,
            updatedBy: false,
        },
        columns: [
            { field: 'id', headerName: t('Id') },
            { field: 'cepOrgId', headerName: t('CEP Org ID'), width: 200 },
            { field: "holidayDate", headerName: t('Holiday Date'), width: 200, valueFormatter: (params) => { return dateFormatter(params.value, true, "", true); } },
            { field: 'holidayName', headerName: t('Holiday Name'), width: 200 },
            { field: 'holidayDescription', headerName: t('Holiday Description'), width: 200 },
            { field: 'createdBy', headerName: t('Created By'), width: 200 },
            { field: "createdTs", headerName: t('Created On'), width: 200, valueFormatter: (params) => { return dateFormatter(params.value); } },
            { field: 'updatedBy', headerName: t('Updated By'), width: 200 },
            { field: "updatedTs", headerName: t('Updated On'), width: 200, valueFormatter: (params) => { return dateFormatter(params.value); } },
            {
                field: 'id', headerName: '',
                valueGetter: getWholeRow,
                renderCell: (params) => {
                    const actions = [
                        {
                            title: t('Edit Holiday'),
                            icon: <EditIcon data-cy-id={"holiday_edit_" + params.row.holidayName}/>,
                            color: 'primary',
                            isHidden: false,
                            isDisabled: false,
                            requiredPermission: 'Holiday:Modify',
                            onClick: () => { props.onEditItem(params.value); },                          
                        },
                        {
                            title: t('Delete Holiday'),
                            icon: <DeleteIcon data-cy-id={"holiday_delete_" + params.row.holidayName}/>,
                            color: 'error',
                            isHidden: false,
                            isDisabled: false,
                            requiredPermission: 'Holiday:Delete',
                            onClick: () => { props.onDeleteItem(params.value); },
                        }
                    ];

                    return (
                        <UcIconButtons actions={actions} />
                    );
                }
            },
        ],
        rows: [],
        rowCount: -1,
    };
    const [dataItems, setDataItems] = useState(defaultDataItems);

    const defaultSelectedItem = { dataItem: {}, selectedId: 0 };
    const [selectedItem, setSelectedItem] = useState(defaultSelectedItem);

    const isDataLoaded = useRef(false);

    const dataReceived = (swrResult) => {
        var dataItems = swrResult.result;

        var selectedDataItems = dataItems.map((item) => {
            return { ...item, selected: item.id === selectedItem.selectedId };
        });

        selectedDataItems.iteration = dataItems.iteration;
        selectedDataItems.rowCount = swrResult.rowCount;

        if (selectedDataItems.length > selectedDataItems.rowCount) {
            selectedDataItems.rowCount = selectedDataItems.length;
        }

        setDataItems({ ...defaultDataItems, rows: [...dataItems], rowCount: swrResult.rowCount });
    };

    const swrHolidays = useHolidays(filter); //Pass the datastate directly as the filter.  The API will adjust filters, orderbys ect.
    if (!swrHolidays.result && defaultDataItems.rowCount !== -1) {
        setDataItems(defaultDataItems);
    }

    useLayoutEffect(() => {
        if (swrHolidays.result) {
            isDataLoaded.current = true;
            var filterString = JSON.stringify(filter);
            if (needsDataUpdate.current !== filterString) {
                needsDataUpdate.current = filterString;
                dataReceived(swrHolidays);
            }
        }
    });

    const updateFilter = (filter) => {
        setFilter(filter);
        props.onFilterChange(filter);
    };

    return (
        <div
            data-cy-id={"holidayGridWrapper"}
            onDoubleClick={props.doubleClick}
        >
            <DataGridPro
                autoHeight={true}
                disableColumnFilter={true}
                disableColumnSelector={true}
                loading={!isDataLoaded.current}
                paginationModel={{page: filter.skip / filter.take, pageSize: filter.take}}
                onPaginationModelChange={(model) => {
                    updateFilter({ ...filter, skip: model.page * model.pageSize, take: model.pageSize })
                }}
                sortingMode='server'
                paginationMode='server'
                filterMode='server'
                sortModel={filter.sort}
                onSortModelChange={(model) => updateFilter({ ...filter, sort: model })}
                filterModel={filterModel}
    
                rowsPerPageOptions={[5, 10, 20]}
                onRowSelectionModelChange={(newSelectionModel) => {
                    if (newSelectionModel.length !== 1) {
                        setSelectedItem(defaultSelectedItem);
                        props.onSelected(defaultSelectedItem.dataItem);
                        return;
                    }
                    const selectedId = newSelectionModel[0];
                    var selectedItem = dataItems.rows.filter(obj => { return obj.id === selectedId });
                    if (selectedItem.length !== 1) {
                        setSelectedItem(defaultSelectedItem);
                        props.onSelected(defaultSelectedItem.dataItem);
                        return;
                    }
                    selectedItem = selectedItem[0];
                    var newlySelectedItem = { dataItem: selectedItem, selectedId: selectedId };

                    setSelectedItem(newlySelectedItem);
                    props.onSelected(newlySelectedItem.dataItem);
                }}
                pagination
                components={{
                    Toolbar: () => {return  props.toolbar || <></>},
                    Pagination: UcPagination,
                }}
                {...dataItems}
                initialState={{ pinnedColumns: { right: ['id'] } }}
            />
        </div>
    );
}

HolidayGrid.defaultProps = {
    onSelected: (selectedItem) => { console.log("onSelected not implemented", selectedItem); },
    onFilterChange: (filter) => { console.log("onFilterChange not implemented", filter); },
};
