/// <summary>
/// Axis scales a min/max value appropriately for the purpose of graphs
/// <remarks>Code taken and modified from http://peltiertech.com/WordPress/calculate-nice-axis-scales-in-excel-vba/</remarks>
/// <remarks>Code taken and modified from https://stackoverflow.com/questions/11397239/rounding-up-for-a-graph-maximum</remarks>
/// </summary>
export class AxisCalc {
    /// <summary>
    /// Initialize Axis from range of values.
    /// </summary>
    /// <param name="x_min">Low end of range to be included</param>
    /// <param name="x_max">High end of range to be included</param>
    constructor(x_min, x_max)
    {
        //Check if the max and min are the same
        if(x_min===x_max)
        {
            x_max*=1.01;
            x_min/=1.01;
        }
        //Check if dMax is bigger than dMin - swap them if not
        if(x_max<x_min)
        {
            let temp = x_min;
            x_min = x_max;
            x_max = temp;
        }

        //Make dMax a little bigger and dMin a little smaller (by 1% of their difference)
        let delta=(x_max-x_min)/2;
        let x_mid=(x_max+x_min)/2;

        x_max=x_mid+1.01*delta;
        x_min=x_mid-1.01*delta;

        //What if they are both 0?
        if(x_max===0&&x_min===0)
        {
            x_max=1;
        }

        //This bit rounds the maximum and minimum values to reasonable values
        //to chart.  If not done, the axis numbers will look very silly
        //Find the range of values covered
        let pwr=Math.log(x_max-x_min)/Math.log(10);
        let scl=Math.pow(10, pwr-Math.floor(pwr));
        //Find the scaling factor
        if(scl>0&&scl<=2.5)
        {
            this.major_step=0.2;
            this.minor_step=0.05;
        }
        else if(scl>2.5&&scl<5)
        {
            this.major_step=0.5;
            this.minor_step=0.1;
        }
        else if(scl>5&&scl<7.5)
        {
            this.major_step=1;
            this.minor_step=0.2;
        }
        else
        {
            this.major_step=2;
            this.minor_step=0.5;
        }
        this.major_step=(Math.pow(10, Math.floor(pwr))*this.major_step);
        this.minor_step=(Math.pow(10, Math.floor(pwr))*this.minor_step);
        this.major_count=Math.ceil((x_max-x_min)/this.major_step);
        this.minor_count=Math.ceil((x_max-x_min)/this.minor_step);
        let i_1=Math.floor(x_min/this.major_step);
        let i_2=Math.ceil(x_max/this.major_step);
        this.min_value=i_1*this.major_step;
        this.max_value=i_2*this.major_step;

        this.min_value= Math.round(this.min_value * 100) / 100;
        this.max_value= Math.round(this.max_value * 100) / 100;
    }
}