import React, { useLayoutEffect, useState } from 'react';
import LoadingButton from '@mui/lab/LoadingButton';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import PropTypes from 'prop-types';
// @ts-ignore
import { useResponsive } from 'lib/useResponsive';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material/styles';
import { ThemeProps } from "components/common/Common.interfaces";
import CustomPaperComponentConstructor from 'components/utils/CustomPaperComponent';
import { OutageForm } from './OutageForm';
import { useSWRConfig } from 'swr';
import { mutateUrls } from 'lib/useGenericSWR';
import { updateOutage } from './OutageAPIEndpoints';

interface OutageFormDialogProps {
    id: number | null | undefined;
    itemToEdit: any;
    open: boolean;
    restore: boolean;
    cancel: boolean;
    onCancel: Function;
    onSave: Function;
    onError: Function;
}

export const OutageFormDialog = (props: OutageFormDialogProps) => {
    const { t } = useTranslation();
    const [saving, setSaving] = React.useState(false);
    const [dialogSubmit, setDialogSubmit] = useState<number>(0);
    const { isMobile } = useResponsive();
    const theme: ThemeProps = useTheme();
    const [reMutateUrls, setReMutateUrls] = useState(false);
    const swrConfig = useSWRConfig();

    let formId = props.id;
    if (!props.open) {
        formId = 0;
    }

    const handleCancel = () => {
        props.onCancel();
    };

    const handleSendOutageEmail = () => {
        console.log("outage pressed");
        setDialogSubmit(dialogSubmit + 1);
    };

    useLayoutEffect(() => {
        if (reMutateUrls) {
            mutateUrls(swrConfig);
            setReMutateUrls(false);
        }
    }, [reMutateUrls, swrConfig]);

    return (  
        <Dialog
            fullScreen={isMobile}
            fullWidth={true}
            maxWidth={"md"}
            open={props.open}
            PaperComponent={CustomPaperComponentConstructor}
            aria-labelledby="draggable-dialog-title"
        >
            <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
                {props.restore && t("Restore Outage")}
                {props.cancel && t("Cancel Outage")}
            </DialogTitle>
            <DialogContent>
                <OutageForm
                    id={formId}
                    disabled={false}
                    restore={props.restore}
                    cancel={props.cancel}
                    itemToEdit={props.itemToEdit}
                    dialogSubmit={dialogSubmit}

                    onSubmit={async (values: any) => {
                        setSaving(true);
                        try {
                            let data = await updateOutage(values);

                            if (data) {
                                props.onSave(data);
                            }
                        } finally {
                            setSaving(false);
                        }
                    }}
                    onError={(error: any) => {
                        props.onError(error);

                        setSaving(false);
                    }}
                />
            </DialogContent>
            <DialogActions>
                {props.restore && (
                    <LoadingButton
                        loading={saving}
                        onClick={handleSendOutageEmail}
                        variant="contained"
                        color="error"
                        style={{ justifyContent:"left", alignItems:"left", display:"flex"} }
                        data-cy-id={"restore_outage_button"}
                    >
                        {t('Restore Outage & Send Email')}
                    </LoadingButton>
                )}
                {props.cancel && (
                    <LoadingButton
                        loading={saving}
                        onClick={handleSendOutageEmail}
                        variant="contained"
                        color="error"
                        style={{ justifyContent:"left", alignItems:"left", display:"flex"} }
                        data-cy-id={"cancel_outage_button"}
                    >
                        {t('Cancel Outage & Send Email')}
                    </LoadingButton>
                )}
                <Button
                    autoFocus
                    onClick={handleCancel}
                    variant={theme.props?.CancelButton?.variant || "outlined"}
                    color={theme.props?.CancelButton?.color}
                    data-cy-id={"cancel_button"}
                    >
                        {t('Cancel')}
                </Button>
            </DialogActions>
        </Dialog>
    );
}

OutageFormDialog.defaultProps = {
    open: false,
    id: 0,
    onCancel: () => { console.log("onCancel not implemented"); }
};

OutageFormDialog.propTypes = {
    open: PropTypes.bool,
    id: PropTypes.number,
    itemToEdit: PropTypes.object,
};