import * as React from 'react';
import { useAvailPermissions, useRolePermissions } from 'components/adminUc/Roles/RolePermissionAPIEndpoints';
import FormGroup from '@mui/material/FormGroup';
import FormControl from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import Switch from '@mui/material/Switch';
import Grid from '@mui/material/Grid';

export const RolePermissionSwitches = (props) => {
    let loadedPermissionObj = {};

    const { syncSelectedPermissions } = props;
    const [options, setOptions] = React.useState([]);
    const [isInitialRender, setIsInitialRender] = React.useState(true);
    const [addAllRead, setAddAllRead] = React.useState(false);
    const [addAllModify, setAddAllModify] = React.useState(false);
    const [addAllDelete, setAddAllDelete] = React.useState(false);

    const [filter, setFilter] = React.useState({ // eslint-disable-line
        id: null,
        roleId: parseInt(props.roleId),
        take: 500,
        skip: 0,
        sort: null,
        generalSearch: "",
    });

    const getTypeFromPermissionName = (name) => {
        return name.substring(name.indexOf(':') + 1);
    }

    const getColorFromPermissionType = (type) => {
        let color;
        switch (type.toUpperCase()) {
            case 'READ':
                color = "primary";
                break;
            case 'MODIFY':
                color = "warning";
                break;
            case 'DELETE':
                color = "error";
                break;
            default:
                break;
        }

        return color;
    }

    const toggleAddAllByType = (type, toggleTo) => {
        switch (type.toUpperCase()) {
            case 'READ':
                setAddAllRead(toggleTo);
                break;
            case 'MODIFY':
                setAddAllModify(toggleTo);
                break;
            case 'DELETE':
                setAddAllDelete(toggleTo);
                break;
            default:
                break;
        }
    }

    const handlePermToggle = (perm, isChecked) => {
        let optionsClone = [];

        if (isChecked) {
            optionsClone = [...options];
            optionsClone.push(perm);

            setOptions([...optionsClone]);
        }
        else {
            optionsClone = options.filter((chip) => chip !== perm);

            setOptions([...optionsClone]);
        }

        syncSelectedPermissions(optionsClone);
    }

    const handleAddChipsByType = (type, isAdding) => {
        let permissionsToAdd = [];

        if (!isAdding) {
            Object.keys(loadedPermissionObj).forEach((area) => {
                let permName = `${area}:${type}`;

                if (!options.includes(permName)) {
                    permissionsToAdd.push(permName);
                }
            });

            setOptions([...options, ...permissionsToAdd]);
            syncSelectedPermissions([...options, ...permissionsToAdd]);

            toggleAddAllByType(type, true);
        }
        else {
            permissionsToAdd = options.filter((name) =>
                getTypeFromPermissionName(name).toUpperCase() !== type.toUpperCase()
            );

            setOptions([...permissionsToAdd]);
            syncSelectedPermissions([...permissionsToAdd]);

            toggleAddAllByType(type, false);
        }
    };

    const customSortPermissionAreaTypes = () => {
        Object.keys(loadedPermissionObj).forEach((area) => {
            loadedPermissionObj[area].sort().reverse();
        });
    }

    const swrRolePermissions = useRolePermissions(filter);

    let loadedOptions = React.useMemo(() => {
        if (swrRolePermissions.result && !swrRolePermissions.isLoading && !swrRolePermissions.isError) {
            return Object.values(swrRolePermissions.result).map((item) => {
                return item.name;
            });
        }
        return [];
    }, [swrRolePermissions]);

    const swrRolePermissionsAvail = useAvailPermissions(filter);
    if (swrRolePermissionsAvail.result && !swrRolePermissionsAvail.isLoading && !swrRolePermissionsAvail.isError) {
        Object.values(swrRolePermissionsAvail.result).forEach((obj) => {
            if (!(obj.friendlyName in loadedPermissionObj)) {
                loadedPermissionObj[obj.friendlyName] = [];
            }

            loadedPermissionObj[obj.friendlyName].push(obj.permission);
        });

        customSortPermissionAreaTypes();
    }

    React.useEffect(() => {
        const initData = async () => {
            setOptions([...loadedOptions]);

            if (syncSelectedPermissions !== undefined) {
                syncSelectedPermissions([...loadedOptions]);
            }
        }

        if (isInitialRender) {
            initData();

            if (loadedOptions.length > 0 || !swrRolePermissions.isLoading) {
                setIsInitialRender(false);
            }
        }
    }, [isInitialRender, loadedOptions, swrRolePermissions.isLoading, syncSelectedPermissions]);

    return (
        <>
            {!props.readonly &&
                <FormControl component="fieldset">
                    <FormGroup aria-label="position" row>
                        <FormControlLabel
                            value="end"
                            control={<Switch color="primary" />}
                            label="All Read Permissions"
                            labelPlacement="end"
                            onChange={() => handleAddChipsByType('Read', addAllRead)}
                        />
                        <FormControlLabel
                            value="end"
                            control={<Switch color="warning" />}
                            label="All Modify Permissions"
                            labelPlacement="end"
                            onChange={() => handleAddChipsByType('Modify', addAllModify)}
                        />
                        <FormControlLabel
                            value="end"
                            control={<Switch color="error" />}
                            label="All Delete Permissions"
                            labelPlacement="end"
                            onChange={() => handleAddChipsByType('Delete', addAllDelete)}
                        />
                    </FormGroup>
                </FormControl>
            }
            {
                Object.keys(loadedPermissionObj).sort().map((friendlyName) => (
                    <>
                        <Grid container spacing={2} sx={{ alignItems: 'center' }}>
                            {props.readonly &&
                                <>
                                    <Grid item xs={3} sx={{ p: 1 }}>
                                        <FormLabel sx={{ fontWeight: "bold" }}>{friendlyName}:</FormLabel>
                                    </Grid>
                                    <Grid item xs={9} sx={{ p: 1 }}>
                                        {
                                            Object.values(loadedPermissionObj[friendlyName]).map((perm) => (
                                                <FormControlLabel
                                                    readonly={true}
                                                    disabled={true}
                                                    control={<Switch color={getColorFromPermissionType(getTypeFromPermissionName(perm))} />}
                                                    label={getTypeFromPermissionName(perm)}
                                                    labelPlacement="end"
                                                    checked={options.includes(perm)}
                                                />
                                            ))
                                        }
                                    </Grid>
                                </>
                            }
                            {!props.readonly &&
                                <>
                                    <Grid item xs={3} sx={{ p: 1 }}>
                                        <FormLabel sx={{ fontWeight: "bold" }}>{friendlyName}:</FormLabel>
                                    </Grid>
                                    <Grid item xs={9} sx={{ p: 1 }}>
                                        {
                                            Object.values(loadedPermissionObj[friendlyName]).map((perm) => (
                                                <FormControlLabel
                                                    control={<Switch color={getColorFromPermissionType(getTypeFromPermissionName(perm))} />}
                                                    label={getTypeFromPermissionName(perm)}
                                                    labelPlacement="end"
                                                    checked={options.includes(perm)}
                                                    onChange={(event) => handlePermToggle(perm, event.target.checked)}

                                                />
                                            ))
                                        }
                                    </Grid>
                                </>
                            }
                        </Grid>
                    </>
                ))
            }
        </>
    );
};

RolePermissionSwitches.defaultProps = {
    onChange: (event, value) => { console.log("onChange not implemented", event, value); },
};