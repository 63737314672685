import React, { useContext, useEffect } from 'react';
import { UcAdminDetails } from "../../common/UcAdminDetails";
import { PageContext } from '../../../lib/PageProvider';
import { EnsureUser } from '../../auth/EnsureUser';

export const OutgoingNotificationDetailsPage = (props) => {
    const [pageState, pageDispatch] = useContext(PageContext);

    useEffect(() => {
        pageDispatch({
            header: "Outgoing Notification Details",
            actionItems: [],
        });
    }, [pageState.header, pageDispatch]);

    let detailsRender = <></>;
    let tabs = [];

    return (
        <EnsureUser permissions={["OutgoingNotification:Read"]}>
            <UcAdminDetails detailsRender={detailsRender} tabs={tabs} tabInitValue={"Users"} />
        </EnsureUser>
    );
};