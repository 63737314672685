// @ts-ignore
import { ApiClient } from "../../../lib/ApiClient";
import { addMutateUrlPrefix, useGenericSWR } from "../../../lib/useGenericSWR";
import { SwrOptions } from '../../../lib/useGenericSWR.interfaces';

const apiClient = new ApiClient();
const apiEndpoint = 'api/v1/admin/uc/OrgModule';

const useOrgModule = (swrOptions = {}) => {
    return useGenericSWR(apiEndpoint, apiClient, { ...swrOptions as SwrOptions});
};

const insertOrgModule = (OrgModule: OrgModule) => {
    return apiClient.poster(apiEndpoint, OrgModule)
        .then((result: any) => {
            addMutateUrlPrefix("api/v1/Permission");
            addMutateUrlPrefix("api/v1/admin/uc/OrgModule?");
            return result;
        });
}

const deleteOrgModule = (OrgModule: OrgModule) => {
    return apiClient.deleter(apiEndpoint + "/" + OrgModule.id)
        .then((result: any) => {
            addMutateUrlPrefix("api/v1/Permission");
            addMutateUrlPrefix("api/v1/admin/uc/OrgModule?");
            return result;
        });
}

export {
    useOrgModule,
    insertOrgModule,
    deleteOrgModule
}