import React, { useState, useLayoutEffect, useRef } from 'react';
import { DataGridPro } from '@mui/x-data-grid-pro';
import { useUserNotificationPreferences } from './UserNotificationPreferenceAPIEndpoints';
import { UcFullPage } from 'components/common/UcFullPage';

export const UserNotificationPreferenceGrid = (props) => {
    const [generalSearchInput] = useState(props.generalSearch || "");
    const initialSort = [{ field: "id", sort: "desc" }];
    const sort = props.sort || initialSort;
    const initialDataState = props.dataState || { skip: 0, take: 10, };

    const [filter, setFilter] = useState({
        take: initialDataState.take,
        skip: initialDataState.skip,
        sort: sort,
        generalSearch: generalSearchInput,
        ignoreCustomAlertCreation: true
    });

    let initialFilterModel = {
        items: [
            {columnField: 'id', operatorValue: 'contains', value: ''}
        ],
        linkOperator: 'and',
    };

    if (generalSearchInput) {
        initialFilterModel = {
            items: [
                {columnField: 'id', operatorValue: 'contains', value: generalSearchInput}
            ],
            linkOperator: 'and',
        }
    }

    const [filterModel] = useState(initialFilterModel);
    const needsDataUpdate = useRef("");

    const [dataItems, setDataItems] = useState(props.defaultDataItems);

    const defaultSelectedItem = { dataItem: {}, selectedId: 0 };
    const [selectedItem, setSelectedItem] = useState(defaultSelectedItem);
    const isDataLoaded = useRef(false);

    const dataReceived = (swrResult) => {
        var dataItems = swrResult.result;
        var selectedDataItems = dataItems.map((item) => {
            return { ...item, selected: item.id === selectedItem.selectedId };
        });
        selectedDataItems.iteration = dataItems.iteration;
        selectedDataItems.rowCount = swrResult.rowCount;
        if (selectedDataItems.length > selectedDataItems.rowCount) {
            selectedDataItems.rowCount = selectedDataItems.length;
        }

        setDataItems({ ...props.defaultDataItems, rows: [...dataItems], rowCount: swrResult.rowCount });
    };

    const swrUserNotificationPrefs = useUserNotificationPreferences(filter); //Pass the datastate directly as the filter.  The API will adjust filters, orderbys ect.
    if (!swrUserNotificationPrefs.result && props.defaultDataItems.rowCount !== -1)
    {
        setDataItems(props.defaultDataItems);
    }

    useLayoutEffect(() => {
        if (swrUserNotificationPrefs.result) {
            isDataLoaded.current = true;
            var filterString = JSON.stringify(filter);
            if (needsDataUpdate.current !== filterString) {
                needsDataUpdate.current = filterString;
                dataReceived(swrUserNotificationPrefs);
            }
        }
    });

    const updateFilter = (filter) => {
        setFilter(filter);
        props.onFilterChange(filter);
    };

    return (
        <div
            data-cy-id={"notificationPreferenceGridWrapper"}
        >
            <UcFullPage {...props}>
                <DataGridPro
                    autoHeight={!props.isFullPage}
                    paginationModel={{page: filter.skip / filter.take, pageSize: filter.take}}
                    loading={!isDataLoaded.current}
                    onPaginationModelChange={(model) => updateFilter({ ...filter, skip: model.page * model.pageSize, take: model.pageSize })}
                    sortingMode='server'
                    paginationMode='server'
                    filterMode='server'
                    sortModel={filter.sort}
                    onSortModelChange={(model) => updateFilter({...filter, sort: model})}
                    filterModel={filterModel}

                    rowsPerPageOptions={[5, 10, 20]}
                    onRowSelectionModelChange={(newSelectionModel) => {
                        if (newSelectionModel.length !== 1)
                        {
                            setSelectedItem(defaultSelectedItem);
                            props.onSelected(defaultSelectedItem.dataItem);
                            return;
                        }
                        const selectedId = newSelectionModel[0];
                        var selectedItem = dataItems.rows.filter(obj => {return obj.id === selectedId});
                        if (selectedItem.length !== 1)
                        {
                            setSelectedItem(defaultSelectedItem);
                            props.onSelected(defaultSelectedItem.dataItem);
                            return;
                        }
                        selectedItem = selectedItem[0];
                        var newlySelectedItem = { dataItem: selectedItem, selectedId: selectedId };

                        setSelectedItem(newlySelectedItem);
                        props.onSelected(newlySelectedItem.dataItem);
                    }}
                    pagination
                    {...dataItems}
                    initialState={{ pinnedColumns: { right: ['id'] } }}
                />
            </UcFullPage>
        </div>
    );
}

UserNotificationPreferenceGrid.defaultProps = {
    isFullPage: true,
    onSelected: (selectedItem) => { console.log("onSelected not implemented", selectedItem); },
    onFilterChange: (filter) => { console.log("onFilterChange not implemented", filter); },
};
