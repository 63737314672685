import React, { useContext, useState } from 'react';
import { PageContext } from '../../lib/PageProvider';
import Alert from '@mui/material/Alert';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { LineChart, Line, XAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
import { MyCostPredictionSummaryWrapper } from './MyCostPredictionApiEndpoints';
import { useTranslation } from "react-i18next";
import { UcLoading } from '../common/UcLoading';
import { useTheme } from '@mui/material/styles';
import { CostEstimateTooltip } from 'lib/CostEstimateTooltip';
import { useResponsive } from 'lib/useResponsive';
import { EnsureUser } from 'components/auth/EnsureUser';
import { currencyFormatter } from '../../lib/Formatters';
import { AccountContext } from '../../lib/AccountProvider';

export const MyCostPredictionSummary = (props) => {
    const { t } = useTranslation();
    const theme = useTheme();
    const [pageState, pageDispatch] = React.useContext(PageContext);
    const { account } = useContext(AccountContext);
    const [prevAccount, setPrevAccount] = useState(account);
    const selectedAccount = account;
    const [costSummary, setCostSummary] = React.useState(null);
    const [predicting, setPredicting] = React.useState(true);
    const [uom, setUom] = React.useState(props.uom);
    const { isMobile } = useResponsive();

    if (prevAccount?.id !== account?.id) {
        setPrevAccount(account);
        setPredicting(true);
    }

    React.useEffect(() => {
        pageDispatch({
            header: "Cost Summary",
        });
    }, [pageState.header, pageDispatch]);

    React.useEffect(() => {
        if (props.uom !== uom) {
            setUom(props.uom);
            setPredicting(true);
        }
    }, [props.uom, uom]);

    return (
        <EnsureUser>
            <Grid container spacing={2} style={{ textAlign: 'center' }} >
                {predicting && (
                    <MyCostPredictionSummaryWrapper
                        setState={setCostSummary}
                        setFetchingState={setPredicting}
                        accountId={selectedAccount.id}
                        groupBy={'day'}
                        uom={uom}
                        currentBill={props.currentBill}
                        groupDataPoints={false}
                    />
                )}
                <UcLoading isLoading={predicting || costSummary === null || costSummary?.isLoading} hasFade={false} style={{ height:"100%" }}>
                    {costSummary?.result && (
                        <>
                            <Grid item xs={12}>
                                <Typography variant="overline">
                                    {costSummary?.result?.timeframe}
                                </Typography>
                            </Grid>
                            {costSummary?.result?.estimatedTotal > 0 &&
                                <Grid item xs={12} style={{ paddingTop: 0 }}>
                                    <Typography variant={isMobile ? "h3" : "h1"} sx={{ display: 'inline-flex' }}>
                                        <Typography variant="h4" style={{ paddingTop: isMobile ? 2 : 12 }}>$</Typography> {costSummary?.result?.estimatedTotal.toFixed(2)}
                                    </Typography>
                                </Grid>
                            }
                            {costSummary?.result?.avgCostPerDayComparison > 0 && false && (
                                <Grid item xs={12} style={{ paddingTop: 0, marginBottom: 8 }}>
                                    <Alert severity="error">${costSummary?.result?.avgCostPerDayComparison} {t('over your average spend/day')}</Alert>
                                </Grid>
                            )}
                            {costSummary?.result?.alertMessage && (
                                <Grid item xs={12} style={{ paddingTop: 0, marginBottom: 8 }}>
                                    <Alert severity="warning">{costSummary?.result?.alertMessage}</Alert>
                                </Grid>
                            )}
                            <Grid item xs={12}>
                                <div style={{ width: '100%', height: 200 }}>
                                    <ResponsiveContainer width="100%" height="100%">
                                        <LineChart
                                            width={500}
                                            height={200}
                                            data={costSummary?.result?.dataPoints}
                                            margin={{
                                                top: 0,
                                                right: 21,
                                                left: 21,
                                                bottom: 10
                                            }}
                                        >
                                            <CartesianGrid strokeDasharray="3 3" />
                                            <XAxis dataKey="formattedDate" interval="preserveStartEnd" style={{ fontSize: 12 }} />
                                            <Tooltip labelStyle={{ color: 'black' }} content={CostEstimateTooltip} />
                                            <Line
                                                type="monotone"
                                                dataKey="costActual"
                                                name={t("Actual Cost")}
                                                stroke={theme.palette.primary.main}
                                                activeDot={{ r: 8 }}
                                                dot={(isMobile ? false : true)}
                                            />
                                            <Line
                                                type="monotone"
                                                dataKey="costPrediction"
                                                name={t("Predicted Cost")}
                                                stroke={theme.palette.primary.light}
                                                dot={(isMobile ? false : true)}
                                                strokeDasharray="5 5" />
                                        </LineChart>
                                    </ResponsiveContainer>
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={4} sx={{ mt: 1 }}>
                                <Paper elevation={3}
                                    style={{
                                        backgroundColor: theme.palette.primary.main,
                                        color: '#fff'
                                    }}
                                >
                                    <Grid container spacing={1}>
                                        <Grid item xs={12}>
                                            <Typography variant={isMobile ? "h5" : "h4"} sx={{ display: 'inline-flex' }}>
                                                <Typography variant="h6">$</Typography> { currencyFormatter(costSummary?.result?.costToDate).replace("$", "")}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12} style={{ paddingTop: 0 }}>
                                            <Typography variant="overline">
                                                {t('Spent So Far')}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Paper>
                            </Grid>
                            <Grid item xs={12} sm={4} sx={{ mt: 1 }}>
                                <Paper elevation={3}
                                    style={{
                                        backgroundColor: theme.palette.primary.main,
                                        color: '#fff'
                                    }}
                                >
                                    <Grid container spacing={1}>
                                        <Grid item xs={12}>
                                            <Typography variant={isMobile ? "h5" : "h4"} sx={{ display: 'inline-flex' }}>
                                                {costSummary?.result?.daysRemaining}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12} style={{ paddingTop: 0 }}>
                                            <Typography variant="overline">
                                                {t('Days Left')}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Paper>
                            </Grid>
                            <Grid item xs={12} sm={4} sx={{ mt: 1 }}>
                                <Paper elevation={3}
                                    style={{
                                        backgroundColor: theme.palette.primary.main,
                                        color: '#fff'
                                    }}
                                >
                                    <Grid container spacing={1}>
                                        <Grid item xs={12}>
                                            <Typography variant={isMobile ? "h5" : "h4"} sx={{ display: 'inline-flex' }}>
                                                <Typography variant="h6">$</Typography> {currencyFormatter(costSummary?.result?.avgCostPerDay, 2).replace("$", "")}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12} style={{ paddingTop: 0 }}>
                                            <Typography variant="overline">
                                                {t('Avg. Spend/Day')}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Paper>
                            </Grid>
                        </>
                    )}
                    {costSummary?.isError && (
                        <Grid item xs={12}>
                            <Alert severity="warning">{t('Insufficient historical data.')}</Alert>
                        </Grid>
                    )}
                </UcLoading>
            </Grid>
        </EnsureUser>
    );
};