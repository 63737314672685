// @ts-ignore
import { useTranslation } from "react-i18next";
// @ts-ignore
import { UcNotificationWidgetInfiniteScroll } from 'components/common/UcNotificationWidgetInfiniteScroll';
// @ts-ignore
import { dateFormatter, markdownFormatter } from 'lib/Formatters';
// @ts-ignore
import { timeAgoDateFormatter } from 'lib/Formatters';
// @ts-ignore
import { EnsureUser } from 'components/auth/EnsureUser';
import { useState } from 'react';

export const NotificationsWidget = () => {
    const { t } = useTranslation();

    const pageSize = 500;
    const [generalSearchInput] = useState("");
    const sort = [{ field: "createdTs", sort: "desc" }, { field: "isPinned", sort: "desc" }];
    const initialDataState = { skip: 0, take: pageSize, };

    const filter = ({
        take: initialDataState.take,
        skip: initialDataState.skip,
        sort: sort,
        generalSearch: generalSearchInput
    });

    let defaultDataItems = {
        columns: [
            { field: 'cepOrgId', hide: true, headerName: t('CEP Org ID'), width: 200 },
            { field: 'title', headerName: t('Title'), width: 200 },
            { field: 'content', hide: true, headerName: t('Content'), width: 200, valueFormatter: (params: any) => { return markdownFormatter(params.value); } },
            { field: 'id', headerName: '', flex: 1, minWidth: 100, cardHide: true },
            { field: "updatedTs", hide: true, headerName: t('Updated On'), width: 200, valueFormatter: (params: any) => { return dateFormatter(params.value); } },
            { field: "createdTs", hide: true, headerName: t('Created On'), width: 200, valueFormatter: (params: any) => { return dateFormatter(params.value); } },
        ],
        cardHeaderField: { field: "title" },
        cardSubHeaderField: { field: "createdTs", valueFormatter: (params: any) => { return timeAgoDateFormatter(params.value); } },
        cardDialogField: { field: "content", valueFormatter: (params: any) => { return markdownFormatter(params.value); } },
        rows: [],
        rowCount: -1,
    };

    return (
        <EnsureUser>
            <UcNotificationWidgetInfiniteScroll
                title={t('Notifications')}
                minHeight={'auto'}
                apiEndPoint={'api/v1/UserNotification'}
                defaultDataItems={defaultDataItems}
                contentPadding={'0'}
                filter={filter}
                pageSize={pageSize}
            />
        </EnsureUser>
    );
}
