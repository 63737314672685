import * as React from 'react';
import { meterClassApiEndpoint } from './MeterClassAPIEndpoints';
import { UcSelect } from 'components/common/UcSelect';

export const MeterClassSelect = (props) => {
    const filter = {
        take: 100,
        skip: 0,
        sort: [{ field: "MeterClass", sort: "desc" }],
        generalSearch: "",
    };

    const getOptionLabel = (option) => {
        return option.meterCommodity;
    }

    return (
        <UcSelect {...props}
            apiEndPoint={meterClassApiEndpoint}
            defaultLabel={"Meter Commodities"}
            getOptionLabel={getOptionLabel}
            filter={filter} />
    );
};