import { getMeterRatesDefaultItem, meterRatesValidationSchema } from './MeterRateAPIEndpoints';
import { convertToLocalTime } from "lib/DateRuleSetFormatter";
import { getDateFromMui } from "lib/Formatters"
import { useMyTimezone } from 'components/customer/orgs/MyOrgAPIEndpoints';
import PropTypes from 'prop-types';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { useRef, useEffect, useState } from 'react';
import { RateClassAdminSelect } from '../Rates/RateClassAdminSelect';
import { UcDatePickerFormik } from 'components/common/UcDatePicker';
import { MeterAdminSelect } from '../Customers/MeterAdminSelect';
import { Grid, FormControlLabel, Switch } from '@mui/material';
import { useTranslation } from 'react-i18next';

export const MeterRateForm = (props) => {
    const { t } = useTranslation();
    const timezone = useMyTimezone();
    const [hasEndDate, setHasEndDate] = useState(props.itemToEdit.rateEndTimestamp !== undefined || false);
    const itemToEdit = props.itemToEdit || getMeterRatesDefaultItem(props.accountMeterId);
    const dialogSubmit = useRef(props.dialogSubmit);
    const [selectedRateClass, setSelectedRateClass] = useState({id: props.itemToEdit.rateClassId || -1, name: props.itemToEdit.rateClassName || ""});

    const handleChangeRateStartTimestamp = (value) => {
        if (formRef.current !== undefined) {
            formRef.current.values.rateStartTimestamp = convertToLocalTime(getDateFromMui(value), timezone);
        }
    }

    const handleChangeRateEndTimestamp = (value) => {
        if (formRef.current !== undefined) {
            formRef.current.values.rateEndTimestamp = convertToLocalTime(getDateFromMui(value), timezone);
        }
    }

    const [selectedAccountMeter, setSelectedAccountMeter] = useState({
        id: props.itemToEdit.accountMeterId || -1,
        accountId: props.accountId,
        rateClassId: props.rateClassId,
        customerName: props.itemToEdit.customerName || "",
        extAccountNumber: props.itemToEdit.extAccountNumber || "",
        serialNo: props.itemToEdit.serialNo || "",
        startTimestamp: props.itemToEdit.meterStartTimestamp || "",
    });
    const formRef = useRef();

    useEffect(() => {
        if (props.dialogSubmit !== dialogSubmit.current) {
            dialogSubmit.current = props.dialogSubmit;
            if (formRef.current) {
                formRef.current.values.rateClassId = selectedRateClass?.id
                formRef.current.values.accountMeterId = selectedAccountMeter?.id;
                formRef.current.handleSubmit();
            }
        }
    });

    return (
        <div className='ignoreCustomFormStyles'>
            <Formik
                innerRef={formRef}
                initialValues={itemToEdit}
                validationSchema={meterRatesValidationSchema}
                onSubmit={(values) => {
                    props.onSubmit(values);
                }}
            >
                <Form readonly={props.readonly}>
                    <Grid container spacing={2} style={{paddingTop: '8px'}}>
                        <Grid item xs={12} sm={12} md={12}>
                            <Field fullWidth
                                name={"rateClassId"}
                                value={selectedRateClass.id !== -1 ? selectedRateClass : null}
                                rateClassId={props.rateClassId}
                                disabled={props.isFromRateClassPath}
                                component={RateClassAdminSelect}
                                onChange={(e, value) => {
                                    setSelectedRateClass(value);
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={12}>
                            <Field
                                name={"accountMeterId"}
                                value={selectedAccountMeter}
                                component={ MeterAdminSelect }
                                allMeters={false}
                                showFullLabel={false}
                                disabled={ itemToEdit.accountMeterId > 0 }
                                label={t("Meter")}
                                userId={props.userId}
                                onChange={(_event, value) => {
                                    setSelectedAccountMeter(value);
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={12}>
                            <FormControlLabel
                                label="Has End Date"
                                control={
                                    <Switch
                                        checked={hasEndDate}
                                        onChange={(e, value) => {
                                            if (value) {
                                                formRef.current.values.rateEndTimestamp = formRef.current.values.rateStartTimestamp;
                                            }
                                            else {
                                                formRef.current.values.rateEndTimestamp = null;
                                            }
                                            setHasEndDate(value);
                                        }}
                                    />
                                }
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={6}>
                            <Field fullWidth
                                name={"rateStartTimestamp"}
                                dateOnly={true}
                                component={UcDatePickerFormik}
                                disabled={props.disabled}
                                label={t("Start Date")}
                                sendValueToForm={handleChangeRateStartTimestamp}
                            />
                        </Grid>
                        { hasEndDate && (
                            <Grid item xs={12} sm={12} md={6}>
                                <Field fullWidth
                                    name={"rateEndTimestamp"}
                                    dateOnly={true}
                                    component={UcDatePickerFormik}
                                    disabled={props.disabled}
                                    label={t("End Date")}
                                    sendValueToForm={handleChangeRateEndTimestamp}
                                />
                            </Grid>
                        )}
                        <Grid item xs={12} sm={12} md={12}>
                            <ErrorMessage name="accountMeterId">
                                { msg => <div style={{ color: 'red' }}>{t("Account Meter Requred Error Message", {message: msg})}</div> }
                            </ErrorMessage>
                        </Grid>
                    </Grid>
                </Form>
            </Formik>
        </div>
    );
}

MeterRateForm.defaultProps = {
    id: 0,
    dialogSubmit: 0,
    changeIteration: 0,
    onSubmit: (values) => { console.log("onSubmit not implemented", values); },
    onError: (error) => { console.error("onError not implemented", error); },
};

MeterRateForm.propTypes = {
    id: PropTypes.number,
    changeIteration: PropTypes.number,
    dialogSubmit: PropTypes.number,
};