import { ApiClient } from "../../../lib/ApiClient";
import { useGenericSWR } from "../../../lib/useGenericSWR";
import * as Yup from 'yup';

export const roleApiEndpoint = 'api/v1/admin/uc/Role';
export const roleDefaultApiEndpoint = 'api/v1/admin/uc/RoleDefault';

let formIteration = 0;

const getRolesDefaultItem = () => {
    formIteration = formIteration + 1;
    return {
        formIteration: "new" + formIteration,
        id: 0,
        name: '',
        description: ''
    }
};

const rolesValidationSchema = Yup.object().shape({
    name: Yup.string()
        .required("Required")
        .max(255, 'Max of 255 characters'),
    description: Yup.string()
        .max(255, 'Max of 255 characters'),
});

const apiClient = new ApiClient();
apiClient.getDefaultItem = () => getRolesDefaultItem();
apiClient.getObjectName = () => {
    return "Role";
};

const useRole = (id, swrOptions = {}) => {
    // SWR will prevent duplicate requests
    // It will also maintain data state as long as your are using the same urls for your requests
    return useGenericSWR(roleApiEndpoint + "/" + id, apiClient, { ...swrOptions });
};

const useRoles = (filter, swrOptions = {}) => {
    // SWR will prevent duplicate requests
    // It will also maintain data state as long as your are using the same urls for your requests
    const params = new URLSearchParams();
    params.append('filter', JSON.stringify(filter));

    return useGenericSWR(roleApiEndpoint + "?" + params.toString(), apiClient, { ...swrOptions });
};

const saveRole = (Role) => {
    if (Role.id === 0) {
        return insertRole(Role);
    }

    return updateRole(Role);
}

const setDefault = (Role) => {
    return apiClient.putter(roleDefaultApiEndpoint, Role);
}

const insertRole = (Role) => {
    return apiClient.poster(roleApiEndpoint, Role);
};

const generateBuiltInRoles = (orgIds) => {
    return apiClient.poster(roleApiEndpoint + "/generateBuiltInRoles", orgIds);
};

const updateRole = (Role) => {
    return apiClient.putter(roleApiEndpoint, Role);
};

const deleteRole = (Role) => {
    return apiClient.deleter(roleApiEndpoint + "/" + Role.id);
};

export {
    useRole,
    useRoles,
    saveRole,
    insertRole,
    updateRole,
    deleteRole,
    setDefault,
    generateBuiltInRoles,
    rolesValidationSchema,
    getRolesDefaultItem,
};