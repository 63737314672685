import { useState } from 'react';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Alert from '@mui/material/Alert';

export const UcHint = (props) => {
    const {hintText, alertText} = props;
    const [anchorEl, setAnchorEl] = useState(null);
    const openInfo = Boolean(anchorEl);
    const popoverId = openInfo ? 'hint-popover' : undefined;

    const handleClickInfo = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleCloseInfo = () => {
        setAnchorEl(null);
    };

    return (
        <>
        <Tooltip title="Info">
            <IconButton
                aria-describedby={popoverId}
                variant="contained"
                onClick={handleClickInfo}
                onMouseOver={handleClickInfo}
                sx={{ color: 'primary.main', mr: 1 }}
            >
                <InfoOutlinedIcon />
            </IconButton>
        </Tooltip>
        <Popover
            id={popoverId}
            open={openInfo}
            anchorEl={anchorEl}
            onClose={handleCloseInfo}
            onMouseOut={handleCloseInfo}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
            PaperProps={{
                style: { width: '400px', paddingRight: '20px', paddingLeft: '20px', paddingBottom: '10px' },
            }}
        >
                <Typography sx={{ pt: 2 }}>{hintText}</Typography>
                {alertText && (
                    <Alert severity="info" sx={{ my: 1 }}>{alertText}</Alert>
                )}          
        </Popover>
        </>
    );
}




