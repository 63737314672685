import { ApiClient } from "../../../lib/ApiClient";
import { useGenericSWR } from "../../../lib/useGenericSWR";
import * as Yup from 'yup';
import { mutateRateCharge } from './RateChargeApiEndpoints';

const apiEndpoint = 'api/v1/admin/RateBasicRule';
let formIteration = 0;

const getRateBasicRulesDefaultItem = (rateChargeId) => {
    formIteration = formIteration + 1;
    return {
        formIteration: "new" + formIteration,
        id: 0,
        rateChargeId: rateChargeId,
        name: '',
        description: '',
        rate: 1,
        startingUnit: 0,
        htmlColor: null,
        createdBy: 'REACT_FE',
        updatedBy: 'REACT_FE',
    }
};

const rateBasicRulesValidationSchema = (t) => 
{
    return Yup.object().shape({
        rateChargeId: Yup.number()
            .required(t('Rate Charge is required'))
            .min(1, t('Rate Charge is required')),
        name: Yup.string()
            .required(t("Required"))
            .max(256, t('Max of 256 characters')),
        htmlColor: Yup.string()
            .required(t("Required")),
        rate: Yup.number()
            .required(t('Rate is required')),
    });
}

const apiClient = new ApiClient();
apiClient.getDefaultItem = () => getRateBasicRulesDefaultItem();

const useRateBasicRule = (id, swrOptions = {}) => {
    return useGenericSWR(apiEndpoint + "/" + id, apiClient, { ...swrOptions });
};

const useRateBasicRules = (filter, swrOptions = {}) => {
    const params = new URLSearchParams();
    params.append('filter', JSON.stringify(filter));
    return useGenericSWR(apiEndpoint + "?" + params.toString(), apiClient, { ...swrOptions });
};

const saveRateBasicRule = (rateBasicRule) => {
    if (rateBasicRule.id === 0) {
        return insertRateBasicRule(rateBasicRule);
    }
    return updateRateBasicRule(rateBasicRule);
}
const insertRateBasicRule = (rateBasicRule) => {
    return apiClient.poster(apiEndpoint, rateBasicRule)
    .then((result) => {
        mutateRateCharge();
        return result;
    });
};

const updateRateBasicRule = (rateBasicRule) => {
    return apiClient.putter(apiEndpoint, rateBasicRule)
    .then((result) => {
        mutateRateCharge();
        return result;
    });
};

const deleteRateBasicRule = (rateBasicRule) => {
    return apiClient.deleter(apiEndpoint + "/" + rateBasicRule.id)
    .then((result) => {
        mutateRateCharge();
        return result;
    });
};

export {
    useRateBasicRule,
    useRateBasicRules,
    saveRateBasicRule,
    insertRateBasicRule,
    updateRateBasicRule,
    deleteRateBasicRule,
    rateBasicRulesValidationSchema,
    getRateBasicRulesDefaultItem,
};