import { buildSeriesData } from 'components/readings/CustomerSummaryChartUtils';
// @ts-ignore
import { fixDataKeyName, dateRangeFormatterIgnoreUtc } from 'lib/Formatters';

function createNewSeries(readings: any[], groupBy: any, showEST: any, t: any, nameOverride: string) {
    let newSeries: any[] = [];
    let totalReadingValues: any[] = [];
    let totalCostValues: any[] = [];
    let totalReadingValue: number = 0;
    let totalCostValue: number = 0;

    readings.forEach((reading: any) => {
        buildSeriesData({
            series: newSeries,
            totalReadingValues: totalReadingValues,
            totalCostValues: totalCostValues,
            reading: reading,
            groupBy: groupBy,
            showEST: showEST,
            translator: t,
            alterSeriesTemperatureProps: {
                hasWeatherData: false,
                groupBy: groupBy,
                series: newSeries,
                reading: reading,
                showStackedAvg: false,
                weatherHistorySummaryData: [],
                weatherDataDays: []
            }
        });

        if (reading.showOnUsageChart) {
            totalReadingValues[reading.readTimestampLocal.ts] += reading.readingValue;
            totalReadingValue = totalReadingValues[reading.readTimestampLocal.ts];
        }

        // we still want to add Monthly cost now
        totalCostValues[reading.readTimestampLocal.ts] += reading.cost;
        totalCostValue = totalCostValues[reading.readTimestampLocal.ts];

        // in case of undefined reading (will cause an error if unhandled), skip
        if (!newSeries[reading.readTimestampLocal.ts]) {
            return;
        }

        newSeries[reading.readTimestampLocal.ts]["totalUsage"] = totalReadingValue;
        newSeries[reading.readTimestampLocal.ts][`Cost_${nameOverride}`] = totalCostValue;
        newSeries[reading.readTimestampLocal.ts][`${fixDataKeyName(reading.uniqueName)}_rate`] = reading.rate;
    });

    return newSeries;
}

function subtractMonths(date: Date, months: number) {
    const dateCopy = new Date(date);
    dateCopy.setMonth(dateCopy.getMonth() - months);
    return dateCopy;
}

function createBillDateRangeText(newItems: any) {
    let dateRangeText: string = "";

    if (newItems?.length > 0) {
        let minStart = new Date(newItems[0].billingPeriodStart);
        let maxEnd = new Date(newItems[0].billingPeriodEnd);

        for (const item of newItems) {
            let startDate = new Date(item.billingPeriodStart);
            let endDate = new Date(item.billingPeriodEnd);
            startDate.setUTCHours(0, 0, 0, 0);
            endDate.setUTCHours(0, 0, 0, 0);

            if (startDate < minStart) {
                minStart = startDate;
            }
            if (endDate > maxEnd) {
                maxEnd = endDate;
            }
        }

        if (minStart && maxEnd) {
            maxEnd.setUTCMinutes(maxEnd.getUTCMinutes() - 1);
            dateRangeText = dateRangeFormatterIgnoreUtc(minStart.toISOString(), maxEnd.toISOString(), false, "", 0, "");
        }
    }
   
    return dateRangeText;
}

export {
    createNewSeries,
    subtractMonths,
    createBillDateRangeText
}