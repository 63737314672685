import React from "react";
import PropTypes from 'prop-types';
import { Alert, Box, CircularProgress, Fade } from "@mui/material";
import { useTranslation } from "react-i18next";

interface UcLoadingProps {
    isLoading: boolean;
    hasFade: boolean;
    height?: string;
    width?: string;
    children?: JSX.Element | any;
    loadingRender: JSX.Element;
    fadeTimeout: number;
    hasError?: boolean;
    errorMessage?: any;
    errorRender?: JSX.Element;
}

export const UcLoading = (props:UcLoadingProps) => {
    // Some situations the fade component causes an error.
    // In order to still use this component added an option to turn fade off
    const { t } = useTranslation();
    const { isLoading, hasError, hasFade } = props;
    const errorMessage = props.errorMessage || t("An error has occurred")
    const errorRender = props.errorRender || <Alert severity="error">{errorMessage}</Alert>;

    return (
        <>
            {hasError && errorRender}
            {isLoading && !hasError &&
                <div style={{position: 'relative', height:  props.height, width: '100%'}}>
                    {props.loadingRender}
                </div>
            }
            {!isLoading && hasFade && !hasError &&
                <>
                    <Fade in={true} timeout={props.fadeTimeout}>
                        {props.children}
                    </Fade>
                </>
            }
            {!isLoading && !hasFade && !hasError &&
                <>
                    {props.children}
                </>
            }
        </>
    );
};

UcLoading.defaultProps = {
    loadingRender: <Box sx={{
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
        position: 'absolute',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    }}>
        <CircularProgress aria-label={"Loading..."} title={"Loading..."}/>
    </Box>,
    fadeTimeout: 1000,
    hasFade: true,
    height: '90vh',
    width: '100%',
    children: <></>
};

UcLoading.propTypes = {
    loadingRender: PropTypes.object,
    fadeTimeout: PropTypes.number,
};