///<summary>
/// dateRangeChargeChecker
///
/// This function is used to check if date ranges in rate charges overlap
///
/// </summary>
function dateFromDay(month, day) {
    return new Date(0, month - 1, day);
}

function checkDateInRange(dateTime, existingStartDate, existingEndDate) {
    return checkMonthInRange(dateTime, existingStartDate, existingEndDate) && checkDayInRangeForMonth(dateTime, existingStartDate, existingEndDate);
}

function checkMonthInRange(dateTime, existingStartDate, existingEndDate) {
    if (existingStartDate.getMonth() > existingEndDate.getMonth()) {
        return dateTime.getMonth() >= existingStartDate.getMonth() || dateTime.getMonth() <= existingEndDate.getMonth()
    }
    else {
        return dateTime.getMonth() >= existingStartDate.getMonth() && dateTime.getMonth() <= existingEndDate.getMonth()
    }
}

function checkDayInRangeForMonth(dateTime, existingStartDate, existingEndDate) {
    if (dateTime.getMonth() === existingStartDate.getMonth() && dateTime.getMonth() === existingEndDate.getMonth()) {
        return (dateTime.getDate() >= existingStartDate.getDate()) && (dateTime.getDate() <= existingEndDate.getDate());
    }
    else if (dateTime.getMonth() === existingStartDate.getMonth()) {
        return (dateTime.getDate() >= existingStartDate.getDate());
    }
    else if (dateTime.getMonth() === existingEndDate.getMonth()) {
        return (dateTime.getDate() <= existingEndDate.getDate());
    }
    else {
        return true;
    }
}

export const dateRangeChargeChecker = (newDateRange, rateChargeTypes, itemToEdit, checkedAllYearRound) => {
    let counter = 0;

    rateChargeTypes.forEach((item) => {
        if (item.id !== itemToEdit.id) {
            if (!checkedAllYearRound && !item.ruleSet.isYearRound) {
                const existingStartDate = dateFromDay(item.ruleSet.dateRanges[0]?.start.month, item.ruleSet.dateRanges[0]?.start.day);
                const existingEndDate = dateFromDay(item.ruleSet.dateRanges[0]?.end.month, item.ruleSet.dateRanges[0]?.end.day);

                if (checkDateInRange(newDateRange.startDate, existingStartDate, existingEndDate) || checkDateInRange(newDateRange.endDate, existingStartDate, existingEndDate)) {
                    counter = counter + 1;
                }
            }
            else {
                counter = counter + 1;
            }
        }
    });
    return counter;
}