import { DateTime } from "luxon";

// must have this in a seperate file or you get circular reference errors
const defaultLocalStorageInfo = {
    minOidcDate: null,
    maxOidcDate: null,
    maxCodeVerifier: null,
    minCodeVerifier: null,
    oidcCounter: 0,
}

export const localStorageInfo = {
    ...defaultLocalStorageInfo
}

// For some reason there can be a build up of oidc localstorage items
// This function will clean all but the newest ones
export const cleanOidcStorage = () => {
    let minDate = null;
    let maxDate = null;
    let minCodeVerifier = null;
    let maxCodeVerifier = null;
    let counter = [];
    let keyTracker = [];

    for ( let i = 0, len = localStorage.length; i < len; ++i ) {
        let key = localStorage.key( i );
        if (key.startsWith("oidc."))
        {
            let itemStr = localStorage.getItem(key);
            let item = JSON.parse(itemStr);
            let date = DateTime.fromJSDate(new Date(item.created * 1000)); // Javascript ticks are * 1000 compared to .NET ticks

            keyTracker.push({key: key, date: date, code_verifier: item.code_verifier});

            if (minDate === null || date < minDate)
            {
                minDate = date
                minCodeVerifier = item.code_verifier;
            }

            if (maxDate === null || date > maxDate)
            {
                maxDate = date
                maxCodeVerifier = item.code_verifier;
            }

            if (counter[date.day]) {
                counter[date.day]++;
            }
            else
            {
                counter[date.day] = 1;
            }
        }
    }

    console.log("LocalStorage Oidc Counter", keyTracker.length);
    if (keyTracker.length < 500)
    {
        // Don't do any cleanup until we have lots of keys
        return;
    }

    for(let index in keyTracker)
    {
        let item = keyTracker[index];
        if (item.code_verifier !== maxCodeVerifier)
        {
            localStorage.removeItem(item.key);
            console.log("LocalStorage removed", item.key);
        }
    }

    localStorageInfo.maxOidcDate = maxDate;
    localStorageInfo.minOidcDate = minDate;
    localStorageInfo.minCodeVerifier = minCodeVerifier;
    localStorageInfo.maxCodeVerifier = maxCodeVerifier;
    localStorageInfo.oidcCounter = keyTracker.length;
};

