import * as React from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
import { useMyOrgs } from './MyOrgAPIEndpoints';
import { useOriginalCepOrgId } from 'components/auth/PermissionApiEndpoint';

// From: https://mui.com/components/autocomplete/#Asynchronous.js
export const OrgSelect = (props) => {
    const [open, setOpen] = React.useState(false);
    const [options, setOptions] = React.useState([]);
    const needsUpdate = React.useRef(true);

    let loading = true;
    let loadedOptions = [];

    const swrOrgs = useMyOrgs();
    const originalCepOrgId = useOriginalCepOrgId();
    if (swrOrgs.result && !swrOrgs.isLoading && !swrOrgs.isError)
    {
        loadedOptions = swrOrgs.result;
        loading = false;
        if (needsUpdate.current) {
            needsUpdate.current = false;
            setOptions([...loadedOptions]);
        }
    }
    else
    {
        needsUpdate.current = true;
        loading = true;
    }

    const [defaultValue, setDefaultValue] = React.useState(props.value || null);

    React.useEffect(() => {
        if (!defaultValue) {
            const newValue = options.find(org => org.id === originalCepOrgId);
            if (newValue)
            {
                setDefaultValue(newValue);
            }
        }
    }, [options, defaultValue, originalCepOrgId]);

    return (
        <Autocomplete
            data-cy-id={"orgSelect"}
            id={props.id || "orgSelect"}
            open={open}
            value={defaultValue}
            onChange={(event, newValue) => {
                props.onChange(event, newValue);
                setDefaultValue(newValue);
            }}
            onOpen={() => {
                setOpen(true);
            }}
            onClose={() => {
                setOpen(false);
            }}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            getOptionLabel={(option) => option.name}
            options={options}
            loading={loading}
            renderInput={(params) => (
                <TextField
                color={props.color || "primary"}
                {...params}
                label={props.label || "Organizations"}
                InputLabelProps={{
                    ...params.InputLabelProps,
                    color:props.color || "primary",
                }}
                InputProps={{
                    ...params.InputProps,
                    color:props.color || "primary",
                    endAdornment: (
                    <React.Fragment>
                        {loading ? <CircularProgress color="inherit" size={20} /> : null}
                        {params.InputProps.endAdornment}
                    </React.Fragment>
                    ),
                }}
                />
        )}
        />
    );
};

OrgSelect.defaultProps = {
    onChange: (event, value) => { console.log("onChange not implemented", event, value); },
};