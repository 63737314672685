//@ts-ignore
import { ApiClient } from "../../lib/ApiClient";
import * as Yup from 'yup';
import { HelpRequest } from "./HelpRequest.interfaces";
import { useGenericSWR } from "lib/useGenericSWR";
import { SwrOptions } from "lib/useGenericSWR.interfaces";
import { mutateMetrics } from "components/admin/Metrics/MetricsAPIEndpoints";

const apiEndpoint = 'api/v1/MyHelpRequest';

let formIteration = 0;

const getMyHelpRequestsDefaultItem = () => {
    formIteration = formIteration + 1;
    return {
        formIteration: "new" + formIteration,
        id: 0,
        ticketId: '',
        name: '',
        accountNumber: '',
        email: '',
        subject: '',
        question: '',
        assignedUserId: 0,
        status: ''
    }
};

const myHelpRequestValidationSchemaPortal = Yup.object().shape({
    name: Yup.string()
        .required("Name is required.")
        .max(256, 'Max of 256 characters'),
    accountNumber: Yup.string()
        .required("Account is required.")
        .max(256, 'Max of 256 characters.'),
    email: Yup.string().email("Must be a valid email.")
        .required("Email is required.")
        .max(256, 'Max of 256 characters.'),
    subject: Yup.string()
        .required("Subject is required.")
        .max(256, 'Max of 256 characters.'),
    question: Yup.string()
        .required("Question is required.")
        .max(8096, 'Character limit reached.'),
});

const myHelpRequestValidationSchemaLanding = Yup.object().shape({
    name: Yup.string()
        .required("Name is required.")
        .max(256, 'Max of 256 characters'),
    accountNumber: Yup.string()
        .max(256, 'Max of 256 characters.'),
    email: Yup.string().email("Must be a valid email.")
        .required("Email is required.")
        .max(256, 'Max of 256 characters.'),
    subject: Yup.string()
        .required("Subject is required.")
        .max(256, 'Max of 256 characters.'),
    question: Yup.string()
        .required("Question is required.")
        .max(8096, 'Character limit reached.'),
});

const apiClient = new ApiClient();
apiClient.getDefaultItem = () => getMyHelpRequestsDefaultItem();
apiClient.getObjectName = () => {
    return "Help Request";
};

const useMyHelpRequest = (id: number, swrOptions = {}) => {
    // SWR will prevent duplicate requests
    // It will also maintain data state as long as your are using the same urls for your requests
    return useGenericSWR(apiEndpoint + "/" + id, apiClient, { ...swrOptions as SwrOptions });
};

const useMyHelpRequests = (filter: any, swrOptions = {}) => {
    // SWR will prevent duplicate requests
    // It will also maintain data state as long as your are using the same urls for your requests
    const params = new URLSearchParams();
    params.append('filter', JSON.stringify(filter));
    return useGenericSWR(apiEndpoint + "?" + params.toString(), apiClient, { ...swrOptions as SwrOptions });
};

const saveMyHelpRequest = (HelpRequest: HelpRequest) => {
    return insertMyHelpRequest(HelpRequest);
}
const insertMyHelpRequest = (HelpRequest: HelpRequest) => {
    return apiClient.poster(apiEndpoint, HelpRequest)
        .then((result: any) => {
            mutateMetrics();
            return result;
        });
};

export {
    useMyHelpRequest,
    useMyHelpRequests,
    saveMyHelpRequest,
    insertMyHelpRequest,
    myHelpRequestValidationSchemaPortal,
    myHelpRequestValidationSchemaLanding,
    getMyHelpRequestsDefaultItem,
};