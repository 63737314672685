import { useTranslation } from "react-i18next";
import './OutageMapWidget.scss';
// @ts-ignore
import { Alert } from "@mui/material";
// @ts-ignore
import { OrgAppSettingsContext } from '../../lib/OrgAppSettingsProvider';
import React, { useContext } from "react";
import { UcLoading } from 'components/common/UcLoading';
// @ts-ignore
import { useResponsive } from 'lib/useResponsive';
import { AccountContext } from "lib/AccountProvider";

interface MeterData {
    id: number;
    serialNo: string;
    meterCommodity: string;
    startTimestamp: string;
    endTimestamp: string;
    latitude: number;
    longitude: number;
    isValidCoordinates: boolean;
}

function OutageMapWidget(props: any) {
    const { t } = useTranslation();
    const { orgAppSettings } = React.useContext(OrgAppSettingsContext);
    let isLoading = true;
    let outageMapUrl = "";
    let paddingTop = "80vh";
    const { isMobile } = useResponsive();
    const { account } = useContext(AccountContext);
    let locationGetParams = new URLSearchParams();

    const metersJson = JSON.parse(account?.metersJson ?? "[]");
    const meter = metersJson.find((meterData: MeterData) => meterData.isValidCoordinates);
    
    if ((orgAppSettings && orgAppSettings?.isLoading)) {
        isLoading = true;
    }
    else {
        isLoading = false;
    }

    if (meter?.isValidCoordinates) {
        locationGetParams.append('userLatitudeOverride',meter.latitude);
        locationGetParams.append('userLongitudeOverride', meter.longitude);       
    }

    if (props.isLandingPage) {
        paddingTop = "92vh";
    }

    if (orgAppSettings && orgAppSettings.result) {
        // @ts-ignore
        outageMapUrl = orgAppSettings.result.outageMapUrl;
    }

    return (
        <>
            <UcLoading isLoading={isLoading} hasFade={false}>
                {outageMapUrl ?
                    <div className="ucOutageMapWidget"
                        style={{
                            paddingTop: (isMobile && props.mobileHeight) ? props.mobileHeight : paddingTop
                        }}
                    >
                        <iframe
                            style={{ border: "0px" }}
                            title="ucOutageMap"
                            src={outageMapUrl + "?" + locationGetParams.toString()}
                            className="responsive-iframe"
                        />
                    </div>         
                 :
                    <Alert severity="warning">{t("No outage map available for organization.")}</Alert>
                }
            </UcLoading>           
        </>
    );
}

export default OutageMapWidget;