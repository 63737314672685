import * as React from 'react';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import Avatar from '@mui/material/Avatar';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Typography from '@mui/material/Typography';
import Alert from '@mui/material/Alert';
import {UcCardMenu} from './UcCardMenu';
import { useTranslation } from "react-i18next";

export default function UcDataItemCards(props) {
    const { t } = useTranslation();
    const { loading, dataItems, onRowSelectionModelChange, actionsHandler, clickable} = props;

    const handleClick = (el, rowId) => {
        const allElements = Array.from(document.querySelectorAll('.selectedDataItem-card'))
        for (let element of allElements) {
          element.classList.remove('selectedDataItem-card')
        }

        document.getElementById(el).classList.add('selectedDataItem-card');
        onRowSelectionModelChange([rowId]);
    }

    const formatterDisplay = (col, row) => {
        if(col.valueFormatter !== undefined){
            let value = (col.valueGetter !== undefined) ? row : row[col.field]
            return col.valueFormatter({"id": row.id, "field": col.field, "value": value})
        }
        else{
            return row[col.field]
        }
    };

    return (
        <Grid container spacing={2} sx={{padding: '2px 2px'}}>
            { dataItems?.rows.map((row, index) => (
                <Grid item sm={12} md={12} xs={12} key={index}>
                    <Card
                        id={`card_dataItem_${row.id}`}
                        onClick={
                            clickable && ( handleClick(`card_dataItem_${row.id}`, row.id) )
                        }
                    >
                        <CardHeader
                            sx={{ backgroundColor: 'primary.main', color: 'primary.contrastText'}}
                            avatar={
                                (props.renderCondition !== undefined || props.avatarDefault) ? (
                                    <Avatar
                                        sx={{ bgcolor: row[props.renderCondition] ? 'primary.dark' : 'primary.light' }}
                                        aria-label={props.renderCondition}
                                    >
                                        {props.avatarDefault !== undefined && (props.avatarDefault)}
                                        {props.avatarDefault === undefined && (
                                            row[props.renderCondition] ? (props.avatarIfRenderConditionTrue) : (props.avatarIfRenderConditionFalse)
                                        )}
                                    </Avatar>
                                ) : (
                                    <></>
                                )
                              }
                            title={
                                <Typography variant="subtitle1" style={{wordBreak: "break-word", fontSize: '1.2rem'}}>
                                    {formatterDisplay(dataItems?.cardHeaderField, row)}
                                </Typography>
                            }
                            action={
                                (!row[props.renderCondition] && actionsHandler) ? (<UcCardMenu actions={actionsHandler(row, true)} />) : <></>
                            }
                        >
                        </CardHeader>
                        <CardContent>
                            <Grid container rowSpacing={1}>
                                { dataItems?.columns.map((col, i) => (!col.hide && !col.cardHide
                                && col.field!== dataItems?.cardHeaderField.field && (
                                    <>
                                    <Grid item xs={col.cardColumnWidth}>
                                        <Typography variant="caption" display="block">
                                            {col.headerName}
                                        </Typography>
                                        <Typography variant="subtitle2">
                                            {formatterDisplay(col, row)}
                                        </Typography>
                                    </Grid>
                                    </>
                                )))}
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>
            ))}
            { dataItems?.rows.length <= 0 && !loading && (
                <Grid item xs={12} sm={12} md={12}>
                    <Alert severity="info" sx={{mt: 2}}>{t("No records found")}</Alert>
                </Grid>
            )}
        </Grid>
    );
}