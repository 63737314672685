import React from 'react';
import { ApiClient } from "../../lib/ApiClient";
import { useGenericSWR } from "../../lib/useGenericSWR";

const apiClient = new ApiClient();
const apiEndpointDev = 'api/v1/DevPermission';
const apiEndpointCostPrediction = 'api/v1/CostPrediction';
const apiEndpointDataWarehouse = 'api/v1/TestDevNeighborCompare';

const useAccountMetersWithinRadius = (latitude, longitude, radiusInKilometers, swrOptions = {}) => {
    const params = new URLSearchParams();
    params.append('latitude', latitude);
    params.append('longitude', longitude);
    params.append('radiusInKilometers', radiusInKilometers);

    return useGenericSWR(apiEndpointCostPrediction + "?" + params.toString(), apiClient, { ...swrOptions });
};

const pushOneSignalNotification = (oneSignalUserId) => {
    return apiClient.poster(apiEndpointDev + "/oneSignal?oneSignalUserId=" + oneSignalUserId);
};

const AccountMetersWithinRadius = ({ setState, latitude, longitude, radiusInKilometers }) => {
    const data = useAccountMetersWithinRadius(latitude, longitude, radiusInKilometers);

    React.useEffect(() => {
        setState(data);
    }, [setState, data]);

    return null;
};

const useDataWarehouse = (accountMeterId, classification, swrOptions = {}) => {
    const params = new URLSearchParams();
    params.append('accountMeterId', accountMeterId);
    params.append('classification', classification);

    return useGenericSWR(apiEndpointDataWarehouse + "?" + params.toString(), apiClient, { ...swrOptions });
};

const DataWarehouseWrapper = ({ setState, setFetching, accountMeterId, classification }) => {
    const data = useDataWarehouse(accountMeterId, classification);

    React.useEffect(() => {
        setState(data);
        setFetching(false);
    }, [setState, setFetching, data]);

    return null;
};

export {
    useAccountMetersWithinRadius,
    AccountMetersWithinRadius,
    pushOneSignalNotification,
    useDataWarehouse,
    DataWarehouseWrapper
}