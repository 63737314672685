// @ts-ignore
import { useMyTimezone } from "components/customer/orgs/MyOrgAPIEndpoints";
// @ts-ignore
import { usePermissions } from "./PermissionApiEndpoint";
// @ts-ignore
import { useAuthUser } from "./useAuthUser";
import { UcLoading } from "components/common/UcLoading";
// @ts-ignore
import { SignInButton } from "./SignInButton";
import { Alert } from "@mui/material";

export default function EnsureUserAndModule(props: any) {
  const authUser = useAuthUser();
  const permissionSwr = usePermissions();
  const timezone = useMyTimezone();

  // @ts-ignore
  const isLoading = (authUser.isLoading && !window.isFirstAccess) || timezone === null;
  const isFull = props.isFull !== undefined ? props.isFull : true;

  let isLoggedIn = false;
  let permissionsSet = false;
  let allowedPermissions: any[] = [];
  let allowedModules: any[] = [];

  if (!authUser.isLoading && authUser.isLoggedIn) {
    isLoggedIn = true;
  }

  if (permissionSwr.result && !permissionSwr.isLoading && !permissionSwr.isError) {
    permissionsSet = true;
    allowedPermissions = permissionSwr.result.permissions;
    allowedModules = permissionSwr.result.orgModules;
  }
  
  const permissionsNeeded = props.permissions || [];
  let missingPermissions: any[] = [];
  permissionsNeeded.forEach((permission: any) => {
    let allowedPermission = allowedPermissions?.find(
      (allowed) => allowed.permission === permission
    );
    if (!allowedPermission) {
      missingPermissions.push(permission);
    }
  });

  const modulesNeeded = props.modules || [];
  let missingModules: any[] = [];
  modulesNeeded.forEach((module: any) => {
    let allowedModule = allowedModules
        ?.find((allowed: any) => allowed.module === module);
    
        if (!allowedModule) {
            missingModules.push(module);
    }
  });

  const hasAccess: boolean = (missingPermissions.length <= 0 && missingModules.length <= 0);

  let showPermissionError: boolean = props.showPermissionError;
  if (showPermissionError === undefined) {
    showPermissionError = true;
  }

  let className = (props.className || "") + " ensureUser";
  if (isFull) {
    className += " ensureUser__full";
  }

  return (
    <>
        {hasAccess &&
            <UcLoading isLoading={isLoading} hasFade={false} height={props.height}>
                <div className={className}>
                    {isLoggedIn && props.children}
                    {!isLoggedIn &&
                        <SignInButton />
                    }
                </div>
            </UcLoading>
        }
        {!hasAccess && showPermissionError && permissionsSet && !isLoading &&
            <>
              {missingPermissions.length > 0 && (
                <Alert severity="error">Missing the following permissions: {missingPermissions.join(", ")}</Alert>
              )}
              {missingModules.length > 0 && (
                <Alert severity="error">Missing the following modules: {missingModules.join(", ")}</Alert>
              )}
            </>
        }
    </>
  );
}
