import { v4 as uuidv4 } from 'uuid';
import axios from 'axios';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

// So I can use hooks in the error boundry
// Want to send to an endpoint to log
export const ErrorBoundaryMessage = (props) => {
    const { t } = useTranslation();
    const [referenceGuid] = useState(uuidv4());
    const [isSent, setIsSent] = useState(false);

    if (props.error && !isSent)
    {
        let clientError = {
            referenceGuid: referenceGuid,
            clientError: props.error.toString(),
            clientStacktrace: props.errorInfo.componentStack,
            url: window?.location?.href
        };

        // Send to clientLogger
        axios.post("api/v1/ClientLogger", clientError)
            .then(() => {
                console.log("Sent to ClientLogger", props);
            })
            .catch((error) => {
                console.error('Error sending to ClientLogger', props, error);
            });
        setIsSent(true);
    }

    return (
        <div>
            <h2>{t("Something went wrong.")}</h2>
            {t("Trace ID:")} {referenceGuid}
        </div>
    );

}