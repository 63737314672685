import { styled } from "@mui/material";

// Really simple class but didn't want to include this duplicate code everywhere we used drawer
export const UcDrawerHeader = styled('div')((props) => ({
    display: 'flex',
    alignItems: 'center',
    padding: props.isMobile && props.theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...props.theme.mixins.toolbar,
    justifyContent: props.justifyContent || 'flex-end',
}));