import React, { useContext, useEffect, useLayoutEffect, useState } from "react";
// @ts-ignore
import { UcListPage } from "components/common/UcListPage";
import { Trans, useTranslation } from "react-i18next";
import { useSWRConfig } from "swr";
import EnsureUserAndModule from "components/auth/EnsureUserAndModule";
import { mutateUrls } from "lib/useGenericSWR";
// @ts-ignore
import { UcToolbar } from "components/common/UcToolbar";
import { BlacklistedIpAddressGridStateful } from "./BlacklistedIpAddressGridStateful";
import { PageAction, PageContext, PageState } from "lib/PageProvider";
import { IconButton, Popover, Tooltip, Typography, Box, Drawer } from "@mui/material";
// @ts-ignore
import { UcDrawerHeader } from 'components/common/UcDrawerHeader';
// @ts-ignore
import { useResponsive } from 'lib/useResponsive';
import { IpAddressHistoryDetailsPage } from "./IpAddressHistoryDetailsPage";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

export const BlacklistedIpAddressListPage = () => {
    const { t } = useTranslation();
    const { isMobile } = useResponsive();
    const [pageState, pageDispatch] = useContext<[PageState, React.Dispatch<PageAction>]>(PageContext);
    const [selectedItem, setSelectedItem] = useState<any>(null);
    const [generalSearch, setGeneralSearch] = useState("");
    const [reMutateUrls, setReMutateUrls] = useState<boolean>(false);
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const toolTipText = (
        <Trans t={t}>
            <p>An IP address will be flagged if one of the following conditions are met:</p>
            <ul>
                <li>
                    A logged in user submits &gt; 100 Help Request forms within a minute, they will be flagged and blocked for a minute.
                </li>
                <li>
                    A logged in user with fake bearer token submits &gt; 11 Help Request forms within a minute, they will be flagged and blocked for a minute.
                </li>
                <li>
                    A non-logged in user submits &gt; 10 Help Request forms within a minute, they will be flagged and blocked for a minute.
                </li>
            </ul>
            <p>Flagged addresses are then listed in this grid and Admin users have the option to fully block the IP address.</p>
        </Trans>
    );

    const openInfo = Boolean(anchorEl);
    const popoverId = openInfo ? 'hint-popover' : undefined;

    const handleClickInfo = (event: any) => {
        setAnchorEl(event.currentTarget);
    };

    const handleCloseInfo = () => {
        setAnchorEl(null);
    };
    const swrConfig = useSWRConfig();

    const actions: any[] = [];

    const mutateHandler = () => {
        setReMutateUrls(true);
    }
    const handleDrawerClose = () => {
        setDrawerOpen(false);
    }

    const drawerWidth = isMobile ? "70vw" : "50vw";

    useEffect(() => {
        pageDispatch({
            header: t("IP Spam Watchlist"),
            actionItems: [] as any[],
            variableHeader: "",
            tabs: [],
            onTabChange: () => {},
            tabValue: "",
        });
    }, [pageState.header, pageDispatch, selectedItem, t]);

    useLayoutEffect(() => {
        if (reMutateUrls) {
            mutateUrls(swrConfig);
            setReMutateUrls(false);
        }
    }, [reMutateUrls, swrConfig]);

    console.log("BlacklistedIpAddressListPage - Loaded");

    return (
        <UcListPage>
            <EnsureUserAndModule permissions={["BlacklistedIpAddress:Read"]}>
                {toolTipText !== undefined && (
                    <Popover
                        id={popoverId}
                        open={openInfo}
                        anchorEl={anchorEl}
                        onClose={handleCloseInfo}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                        }}
                        slotProps={{
                            paper: {
                                sx: { width: '750px', paddingRight: '20px', paddingLeft: '20px', paddingBottom: '10px' }
                            }
                        }}
                    >
                        <Typography sx={{ pt: 2 }}>{toolTipText}</Typography>
                    </Popover>
                )}         
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                    <h1 className="pageHeader">
                        {t("IP Spam Watchlist")}
                    </h1>
                    <Tooltip title={t("Information")}>
                        <IconButton
                            color="info"
                            aria-describedby={popoverId}
                            onClick={handleClickInfo}
                            sx={{ padding: "0 0 5px 0" }}
                        >
                            <InfoOutlinedIcon sx={{ height: "30px", width: "30px" }} />
                        </IconButton>
                    </Tooltip>
                </div>          
                <Typography fontSize="14px" color="grey" sx={{ mb: 2}}>
                    {t("IP Addresses that have contradicted our anti-spam policies are flagged and placed in the below table. Administrators can block flagged IP addresses if they are deemed malicious.")}
                </Typography>
                <UcToolbar
                    hasGeneralSearch={true}
                    onGeneralSearchChange={(value: string) => {
                        setGeneralSearch(value);
                    }}
                    actions={actions}
                ></UcToolbar>
                {!reMutateUrls && (
                    <BlacklistedIpAddressGridStateful
                        setDrawerOpen={setDrawerOpen}
                        generalSearch={generalSearch}
                        mutateHandler={mutateHandler}
                        onSelected={(selectedItem: any) =>
                            setSelectedItem({ ...selectedItem })
                        }
                    />
                )}
                <Drawer
                    anchor="right"
                    open={drawerOpen}
                    onClose={handleDrawerClose}
                >
                    {!isMobile && (
                        <UcDrawerHeader justify="flex-start" />
                    )}
                    <Box
                        sx={{ width: drawerWidth, margin: '15px' }}
                        role="presentation"
                    >
                        {selectedItem &&
                            <IpAddressHistoryDetailsPage selectedItem={selectedItem} />
                        }
                    </Box>
                </Drawer>
            </EnsureUserAndModule>
        </UcListPage>
    );
};
