import React from "react";
import {FormControlLabel, Checkbox, FormLabel } from "@mui/material";

export const UcDayOfWeekSelector = (props) => {
    let checkedDays = [
        {
            name: "Sun",
            checked: false,
            value: 0
        },
        {
            name: "Mon",
            checked: false,
            value: 1
        },
        {
            name: "Tues",
            checked: false,
            value: 2
        },
        {
            name: "Wed",
            checked: false,
            value: 3
        },
        {
            name: "Thur",
            checked: false,
            value: 4
        },
        {
            name: "Fri",
            checked: false,
            value: 5
        },
        {
            name: "Sat",
            checked: false,
            value: 6
        }
    ];


    if (props.editing) {
        props.editing.forEach(
            validDay => checkedDays.find(day => day.value === validDay).checked = true
        );
    }

    const handleChange = (event, day) => {
        day.checked = event.target.checked;
        props.onClick(checkedDays.filter(day => day.checked).map(day => day.value));
    }

    return (
        <>
            <FormLabel component="legend">Days of the Week</FormLabel>
            {
                checkedDays.map((day) => {
                    return (
                        <FormControlLabel 
                            control={
                                <Checkbox size="small" onChange={(event) => { 
                                        handleChange(event, day) 
                                    }} 
                                    checked={day.checked} 
                                />
                            } 
                            label={day.name} 
                            name={day.name}
                        />
                    )
                })
            }
        </>
    );
}




