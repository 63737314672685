import React, { useEffect, useState } from 'react';
import { useAuth } from "react-oidc-context";
import { DateTime } from "luxon";
import { UserInfoContext } from "../../lib/UserInfoProvider";
import { updateUserInfo } from './UserInfo';
import { useLocalStorage } from '@mantine/hooks';

if (window.isFirstAccess === undefined)
{
    window.isFirstAccess = true;
}

const useAuthUser = () => {
    const { userInfo, setUserInfo } = React.useContext(UserInfoContext);
    const auth = useAuth();
    const [result, setResult] = useState({
        ...userInfo,
        auth: auth,
    });
    const [lastSignInOut, setLastSignInOut] = useLocalStorage({ key: 'lastSignInOut', defaultValue: null });

    console.log("LAST SIGN IN/OUT", lastSignInOut);

    useEffect(() => {
        // the `return` is important - addAccessTokenExpiring() returns a cleanup function
        return auth.events.addAccessTokenExpiring(() => {
            auth.signinSilent()
                .then((data) => {
                    window.accessToken = data.id_token;
                    window.expiresAt = DateTime.fromJSDate(new Date(data.expires_at * 1000));
                    setLastSignInOut("SignIn");
                })
                .catch((err) => {
                    console.log("ACCESS TOKEN SET SILENT ERROR", err);
                })
            })
      }, [auth, auth.events, auth.signinSilent, setLastSignInOut]);

    useEffect(() => {
        // the `return` is important - addAccessTokenExpiring() returns a cleanup function
        return auth.events.addUserSignedIn(() => {
          console.log("addUserSignedIn event");
        })
    }, [auth, auth.events]);

    useEffect(() => {
        // the `return` is important - addAccessTokenExpiring() returns a cleanup function
        return auth.events.addUserSignedOut(() => {
          window.localStorage.setItem("lastLogin", null);
          auth.removeUser();
        })
    }, [auth, auth.events]);

    useEffect(() => {
        // the `return` is important - addAccessTokenExpiring() returns a cleanup function
        return auth.events.addUserSessionChanged(() => {
          console.log("addUserSessionChanged");
        })
    }, [auth, auth.events]);

    useEffect(() => {
        if (result.isLoggedIn)
        {
            return;
        }

        const newUserInfo = {...userInfo};
        newUserInfo.isLoading = auth.isLoading && result.isLoading;
        if (auth.user)
        {
            newUserInfo.isLoading = false;
            newUserInfo.isFirstAccess = false;
            window.isFirstAccess = false;
        }
        newUserInfo.isLoggedIn = auth.isAuthenticated || auth.user || result.isLoggedIn;
        // Tokens will refresh ever 15 min (see UcAuth client settings for actual value)
        newUserInfo.username = auth.user?.profile?.email || auth.user?.profile?.name || "" || result.username;
        newUserInfo.emailVerified = (auth.user?.profile?.email_verified) || result.emailVerified;
        newUserInfo.error = auth.error;
        newUserInfo.accessToken = auth.user?.id_token || result.accessToken;
        newUserInfo.expiresAt = auth.user ? DateTime.fromJSDate(new Date(auth.user?.expires_at * 1000)) : result.expiresAt; // Javascript ticks are * 1000 compared to .NET ticks
        window.accessToken = newUserInfo.accessToken;
        window.exports = newUserInfo.expiresAt;

        newUserInfo.expiresAtRaw = auth.user?.expires_at || result.expiresAtRaw;
        updateUserInfo(newUserInfo)
        setUserInfo(newUserInfo);

        setResult({
            ...newUserInfo,
            auth: auth,
        })
    }, [auth, result.accessToken, result.emailVerified, result.expiresAt, result.expiresAtRaw, result.isLoading, result.isLoggedIn, result.username, setUserInfo, userInfo]);

    if (window.isFirstAccess && !window.isFirstAccessStarted) {
        window.isFirstAccessStarted = true;
        // Get a weird error when calling signinSilent() on first access
        // so we need to call it from a separate thread
        setTimeout(() => {
            auth.signinSilent()
                .then((data) => {
                    window.accessToken = data.id_token;
                    window.expiresAt = DateTime.fromJSDate(new Date(data.expires_at * 1000));
                    userInfo.isFirstAccess = false;
                    window.isFirstAccess = false;
                    setLastSignInOut("SignIn");
                    updateUserInfo({...userInfo});
                    setUserInfo({...userInfo});
                    setResult({
                        ...userInfo,
                        auth: auth,
                    });
                })
                .catch((err) => {
                    console.log("ACCESS TOKENSET SILENT ERR2", err);
                    userInfo.isFirstAccess = false;
                    userInfo.isLoading = false;
                    window.isFirstAccess = false;
                    updateUserInfo({...userInfo});
                    setUserInfo({...userInfo});
                    setResult({
                        ...userInfo,
                        auth: auth,
                    });
                })
        }, 100)
    }

    return result;
};

const useIsLoggedIn = () => {
    const authUser = useAuthUser();

    return authUser.isLoggedIn;
}

export {
    useAuthUser,
    useIsLoggedIn,
};