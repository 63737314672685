import React, { useContext, useEffect } from 'react';
import EnsureUserAndModule from "components/auth/EnsureUserAndModule";
import { Typography} from '@mui/material';
// @ts-ignore
import { dateFormatterTimezone } from "lib/Formatters";
import { useMyTimezone } from 'components/customer/orgs/MyOrgAPIEndpoints';
import { useTranslation } from "react-i18next";
import { PageAction, PageContext, PageState } from "lib/PageProvider";
import { DataGridPro, GridColDef } from '@mui/x-data-grid-pro';
import { BlacklistedIpAddress, BlacklistedIpAddressHistory } from "./BlacklistedIpAddress.interfaces";

interface IpAddressHistoryDetailsPageProps {
    selectedItem: BlacklistedIpAddress
};

export const IpAddressHistoryDetailsPage = (props: IpAddressHistoryDetailsPageProps) => {
    const { t } = useTranslation();
    const timezone = useMyTimezone();
    let selectedItem = props.selectedItem;

    const dataItems: BlacklistedIpAddressHistory[] = selectedItem.history.map((item: BlacklistedIpAddressHistory, index: number) => ({
        ...item,
        id: index
    }));

    const columns: GridColDef<(any)[number]>[] = [
        { field: 'requestUrl', headerName: t('Request Url'), minWidth: 350, flex: 1 },
        { field: 'userAgent', headerName: t('User Agent'), minWidth: 200, flex: 1 },
        { field: 'createdTs', headerName: t('Timestamp'), minWidth: 200, valueFormatter: (params: any) => { return dateFormatterTimezone(params.value+"Z", timezone); } },
    ];

    const [pageState, pageDispatch] =
        useContext<[PageState, React.Dispatch<PageAction>]>(PageContext);

    useEffect(() => {
        pageDispatch({
            header: selectedItem.ipAddress + " Spam History",
            actionItems: [] as any[],
            variableHeader: selectedItem.ipAddress,
            tabs: [],
            onTabChange: () => { },
            tabValue: ""         
        });
    }, [pageState.header, pageDispatch, selectedItem.ipAddress]);

    return (
        <EnsureUserAndModule permissions={["BlacklistedIpAddress:Read"]} isFull={false}>
            <Typography variant="h5" gutterBottom data-cy-id={"ipAddressHistory"}>{selectedItem.ipAddress} {t(" Spam History")}</Typography>
            <DataGridPro
                autoHeight={true}
                rows={dataItems}
                columns={columns}
                initialState={{
                    pagination: {
                        paginationModel: {
                            pageSize: 10,
                        },
                    },
                    sorting: {
                        sortModel: [
                            {
                                field: 'createdTs',
                                sort: 'desc',
                            },
                        ],
                    },
                }}
                pageSizeOptions={[5, 10, 15]}
            />
        </EnsureUserAndModule>
    );
};