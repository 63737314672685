// @ts-ignore
import { DateTime } from "luxon";
import { Theme as MuiTheme} from '@mui/material';
// @ts-ignore
import { fixDataKeyName, monthFormatter, dayFormatter, hourFormatter } from '../../lib/Formatters';

// TODO: Implement Theme interface it properly
export interface ChartInstanceOfMuiTheme extends MuiTheme {
    props: any
}

export interface UomAxisLabel {
    KWH: string,
    GAL: string,
}

export interface SeriesCategory {

    rateChargeId:           number;
    showOnUsageChart:       boolean;
    timeOfUseHtmlColor:     string;
    timeOfUseName:          string;
    timeOfUseRate:          number;
    uniqueName:             string;
}

export interface SeriesGroup {

    dataKeyName:            string;
    showOnUsageChart:       boolean;
    timeOfUseHtmlColor:     string;
    timeOfUseName:          string;
    timeOfUseRate:          number;
    uniqueName:             string;
}

// TODO: Convert type any to actual types
export interface AlterSeriesTemperatureProps {
    hasWeatherData: boolean;
    groupBy: string;
    series: any;
    reading: any;
    showStackedAvg: boolean;
    weatherHistorySummaryData: any;
    weatherDataDays: any;
}

// TODO: Convert type any to actual types
export interface BuildSeriesDataProps {
    series:                 any[];
    totalReadingValues:     any[];
    totalCostValues:        any[];
    reading:                any;
    groupBy:                string;
    showEST:                boolean;
    translator:             any;
    alterSeriesTemperatureProps: AlterSeriesTemperatureProps;
}

export interface CustomTickProps {
    x: number;
    y: number;
    payload: {
        value: any;
    };
    barWidth: number;
}

function alterSeriesTemperature(props: AlterSeriesTemperatureProps): any {
    const { hasWeatherData, groupBy, reading, series, showStackedAvg, weatherHistorySummaryData, weatherDataDays } = props;

    // Might revise this code block depending on what is needed for the dashboard widgets
    if (hasWeatherData && groupBy === 'month') {
        const readingYear = new Date(reading.readTimestampLocal).getFullYear();
        const currentSeries = series[reading.readTimestampLocal.ts];

        if (weatherHistorySummaryData) {
            for (const element of weatherHistorySummaryData) {
                let targetMonth = element?.location.values.filter((data: any) => {
                    return currentSeries.name.includes(data.period) && readingYear === element.location.year;
                });

                if (targetMonth?.length > 0) {
                    series[reading.readTimestampLocal.ts]["Temperature"] = targetMonth[0].temp;
                    series[reading.readTimestampLocal.ts]["High-Temperature"] = targetMonth[0].maxTemp;
                    series[reading.readTimestampLocal.ts]["Low-Temperature"] = targetMonth[0].minTemp;
                    series[reading.readTimestampLocal.ts]["Precip"] = targetMonth[0].precip;

                    if (showStackedAvg) {
                        series[reading.readTimestampLocal.ts]["Chart-Only-Area-Stack-Hidden"] = targetMonth[0].minTemp;
                        series[reading.readTimestampLocal.ts]["Chart-Only-Area-Stack-Visible"] = targetMonth[0].maxTemp - targetMonth[0].minTemp;
                    }
                    else {
                        series[reading.readTimestampLocal.ts]["Chart-Only-Area-Stack-Visible"] = targetMonth[0].temp;
                    }

                    break;
                }
            };
        }
    }

    else if (hasWeatherData) {
        let weatherDataMatch = weatherDataDays.filter((data: any) => {
            const weatherDataDate = DateTime.fromJSDate(new Date(data.dateTime)).startOf("day").toUTC().toISO();
            const readingDate = DateTime.fromJSDate(new Date(reading.readTimestampLocal)).startOf("day").toUTC().toISO();

            return weatherDataDate === readingDate;
        })[0];

        if (groupBy === 'none' && weatherDataMatch !== undefined) {
            const readingDate = DateTime.fromJSDate(new Date(reading.readTimestampLocal)).toUTC().toISO();

            weatherDataMatch = weatherDataMatch?.hours?.filter((hour: any) => {
                let datetimeWithHour: any = DateTime.fromJSDate(new Date(weatherDataMatch.dateTime)).startOf("day").toUTC().toISO();
                datetimeWithHour = DateTime.fromISO(datetimeWithHour).set({
                    hour: hour.dateTime.substring(0, 2)
                });

                const hourData = DateTime.fromJSDate(new Date(datetimeWithHour)).toUTC().toISO();

                return hourData === readingDate;
            })[0];

            series[reading.readTimestampLocal.ts]["Temperature"] =  weatherDataMatch?.temp;
            series[reading.readTimestampLocal.ts]["Chart-Only-Area-Stack-Visible"] = weatherDataMatch?.temp;
            series[reading.readTimestampLocal.ts]["Precip"] = weatherDataMatch?.precip;
        }
        else {
            series[reading.readTimestampLocal.ts]["Temperature"] =  weatherDataMatch?.temp;
            series[reading.readTimestampLocal.ts]["High-Temperature"] =  weatherDataMatch?.tempMax;
            series[reading.readTimestampLocal.ts]["Low-Temperature"] = weatherDataMatch?.tempMin;
            series[reading.readTimestampLocal.ts]["Precip"] = weatherDataMatch?.precip;

            if (showStackedAvg) {
                series[reading.readTimestampLocal.ts]["Chart-Only-Area-Stack-Hidden"] = weatherDataMatch?.minTemp;
                series[reading.readTimestampLocal.ts]["Chart-Only-Area-Stack-Visible"] = weatherDataMatch?.maxTemp - weatherDataMatch?.minTemp;
            }
            else {
                series[reading.readTimestampLocal.ts]["Chart-Only-Area-Stack-Visible"] = weatherDataMatch?.temp;
            }
        }
    }
}

const getName = (reading: any, groupBy: string) => {
    switch(groupBy)
    {
        case "month":
            return monthFormatter(reading.readTimestampLocal);
        case "day":
            return dayFormatter(reading.readTimestampLocal);
        case "none":
            return hourFormatter(reading.readTimestampLocalStart, "", true);
        default:
            return monthFormatter(reading.readTimestampLocal);
    }
}

function createSeriesObject(series: any[], reading: any, name: string) {
    if (series[reading.readTimestampLocal.ts] === undefined) {
        series[reading.readTimestampLocal.ts] = {
            name: name,
            billingPeriodStart: reading.billingPeriodStart,
            billingPeriodEnd: reading.billingPeriodEnd,
            localTimestamp: new Date(reading.readTimestampLocal.ts).toLocaleTimeString('en-IT', { hour12: false })
        };
    }
}

function getWeatherDataForDay(timestamp: number, hasWeatherData: boolean, weatherData: any) {
    if (hasWeatherData) {
        const readingDate = DateTime.fromJSDate(new Date(timestamp)).toUTC().toISO()

        let weatherDataMatch = weatherData?.filter((data: any) => {
            const weatherDataDate = DateTime.fromJSDate(new Date(data.dateTime)).startOf("day").toUTC().toISO();
            const readingDate = DateTime.fromJSDate(new Date(timestamp)).startOf("day").toUTC().toISO();

            return weatherDataDate === readingDate;
        })[0];

        weatherDataMatch = weatherDataMatch?.hours?.filter((hour: any) => {
            let datetimeWithHour: any = DateTime.fromJSDate(new Date(weatherDataMatch.dateTime)).startOf("day").toUTC().toISO();
            datetimeWithHour = DateTime.fromISO(datetimeWithHour).set({
                hour: hour.dateTime.substring(0, 2)
            });

            const hourData = DateTime.fromJSDate(new Date(datetimeWithHour)).toUTC().toISO();

            return hourData === readingDate;
        })[0];

        return weatherDataMatch?.temp;
    }
    else {
        return null;
    }
}

function getWeatherDataForYear(timestamp: number, hasWeatherData: boolean, weatherData: any, tempType: string | null = null) {
    if (hasWeatherData) {
        const readingYear = new Date(timestamp).getFullYear();
        const currentMonth = new Date(timestamp).toLocaleString('default', { month: 'short' });

        if (weatherData) {
            for (const element of weatherData) {
                let targetMonth = element?.location.values.filter((data: any) => {
                    return currentMonth.includes(data.period) && readingYear === element.location.year;
                });

                if (targetMonth?.length > 0) {
                    if (tempType === "high") {
                        return targetMonth[0].maxTemp;
                    }
                    else if (tempType === "low") {
                        return targetMonth[0].minTemp;
                    }
                    else if (tempType === "precip") {
                        return targetMonth[0].precip;
                    }
                    else {
                        return targetMonth[0].temp;
                    }
                }
            };
        }
    }
    else {
        return null;
    }
}

function alterHourName(currentName: string) {
    let currentNameDateTime:any = DateTime.fromFormat(currentName, 'h a');
    return hourFormatter(currentNameDateTime, "", true);
}

function fillMissingHourData(series: any[], startDate: any, endDate: any, hasWeatherData: boolean, weatherData: any) {
    let newSeriesHours = []

    const readingHourTimestamps: any[] = Array.from(
        { length: Math.round((endDate - startDate) / (60 * 60 * 1000))-1},
        (_, index) => startDate.getTime() + (index) * (60 * 60 * 1000)  // Normally this would be startDate.getTime() + (index-1) * (60 * 60 * 1000) but we want to exclude the first time
        );

    newSeriesHours = readingHourTimestamps.map((timestamp: any) =>
        series[timestamp] === undefined ? ({
            name: alterHourName(hourFormatter(timestamp, "", true)),
            localTimestamp: new Date(timestamp).toLocaleTimeString('en-IT', { hour12: false }),
            "Temperature": getWeatherDataForDay(timestamp, hasWeatherData, weatherData),
            "Chart-Only-Area-Stack-Visible": getWeatherDataForDay(timestamp, hasWeatherData, weatherData),
            "Cost": 0,
            }) : (
            series[timestamp]
        ));

    return newSeriesHours;
}

function fillMissingYearData(series: any[], startDate: any, endDate: any, hasWeatherData: boolean, weatherData: any) {
    let maxBillingPeriodStart = null;

    // Default the timestamps we have in the series to the first of the month, only for comparison purposes
    let updatedSeries: any = {};
    for (let key in series) {
        const date = new Date(parseInt(key));
        const firstDayOfMonth: any = new Date(date.getFullYear(), date.getMonth(), 1).getTime();
        updatedSeries[firstDayOfMonth] = series[key];

        const billingPeriodStart = new Date(series[key].billingPeriodStart);

        if (maxBillingPeriodStart === null || billingPeriodStart > new Date(maxBillingPeriodStart)) {
            maxBillingPeriodStart = series[key].billingPeriodStart;
        }
    }

    let newSeriesHours = []
    let readingMonthTimestamps = [];

    let currentMonth = startDate.getMonth();
    let currentYear = startDate.getFullYear();

    // Create a full year of mocked timestamps defaulted to the first of the month
    while (currentYear < endDate.getFullYear() || (currentYear === endDate.getFullYear() && currentMonth < endDate.getMonth())) {
        readingMonthTimestamps.push(new Date(currentYear, currentMonth, 1).getTime());
        if (currentMonth === 11) {
            currentYear++;
            currentMonth = 0;
        } else {
            currentMonth++;
        }
    }
    const lastMockedTimestamp = readingMonthTimestamps[readingMonthTimestamps.length - 1];
    const monthDifference = Math.abs(new Date(lastMockedTimestamp).getMonth() - new Date().getMonth());

    // First check is for when billing cycle Start Day is past the current day of the month
    // Second check will pop the most recent  mocked month if that is the case (i.e. Oct - Oct. but we are not past Oct 20th. Pop the last "fake" Oct)
    if ((new Date(maxBillingPeriodStart).getUTCDate() > new Date().getDate()) && (monthDifference < 1)) {
        readingMonthTimestamps.pop();
    }

    // Check if our current series has a timestamp for each month of the year, if not we create a bar containing weather data
    newSeriesHours = readingMonthTimestamps.map((timestamp: any) =>
        updatedSeries[timestamp] === undefined ? ({
            name: monthFormatter(timestamp),
            localTimestamp: new Date(timestamp).toLocaleTimeString('en-IT', { hour12: false }),
            "High-Temperature": getWeatherDataForYear(timestamp, hasWeatherData, weatherData, "high"),
            "Low-Temperature": getWeatherDataForYear(timestamp, hasWeatherData, weatherData, "low"),
            "Temperature": getWeatherDataForYear(timestamp, hasWeatherData, weatherData),
            "Precip": getWeatherDataForYear(timestamp, hasWeatherData, weatherData, "precip"),
            "Chart-Only-Area-Stack-Visible": getWeatherDataForYear(timestamp, hasWeatherData, weatherData),
            "Cost": 0,
        }) : (
           updatedSeries[timestamp]
        ));

    return newSeriesHours;
}

function buildSeriesData(buildSeriesDataProps: BuildSeriesDataProps) {
    let currentName: any = getName(buildSeriesDataProps.reading, buildSeriesDataProps.groupBy);

    if (buildSeriesDataProps.groupBy === "none") {
        currentName = alterHourName(currentName);
    }

    if (Object.keys(buildSeriesDataProps.series).length === 0 && currentName === '12 AM' && buildSeriesDataProps.groupBy === 'none') {
        return;
    }

    createSeriesObject(buildSeriesDataProps.series, buildSeriesDataProps.reading, currentName);


    if (isNaN(buildSeriesDataProps.totalReadingValues[buildSeriesDataProps.reading.readTimestampLocal.ts]))
    {
        buildSeriesDataProps.totalReadingValues[buildSeriesDataProps.reading.readTimestampLocal.ts] = 0;
    }

    if (isNaN(buildSeriesDataProps.totalCostValues[buildSeriesDataProps.reading.readTimestampLocal.ts])) {
        buildSeriesDataProps.totalCostValues[buildSeriesDataProps.reading.readTimestampLocal.ts] = 0;
    }

    const dataKeyName = fixDataKeyName(buildSeriesDataProps.reading.uniqueName);

    if (buildSeriesDataProps.series[buildSeriesDataProps.reading.readTimestampLocal.ts][dataKeyName] === undefined) {
        buildSeriesDataProps.series[buildSeriesDataProps.reading.readTimestampLocal.ts][dataKeyName] = 0;
        buildSeriesDataProps.series[buildSeriesDataProps.reading.readTimestampLocal.ts][`${dataKeyName}_cost`] = 0;

        if (buildSeriesDataProps.showEST && buildSeriesDataProps.reading.readingValueEst !== 0) {
            buildSeriesDataProps.series[buildSeriesDataProps.reading.readTimestampLocal.ts][buildSeriesDataProps.translator("EST {{estimatedItem}}", { estimatedItem: buildSeriesDataProps.reading.name })] = 0;
        }
    }

    buildSeriesDataProps.series[buildSeriesDataProps.reading.readTimestampLocal.ts][dataKeyName] += buildSeriesDataProps.reading.readingValue;
    buildSeriesDataProps.series[buildSeriesDataProps.reading.readTimestampLocal.ts][`${dataKeyName}_cost`] += buildSeriesDataProps.reading.cost;

    if (buildSeriesDataProps.showEST && buildSeriesDataProps.reading.readingValueEst !== 0)
    {
        // only add an est field if there is an est
        buildSeriesDataProps.series[buildSeriesDataProps.reading.readTimestampLocal.ts][buildSeriesDataProps.translator("EST {{estimatedItem}}", { estimatedItem: buildSeriesDataProps.reading.timeOfUseName })] += buildSeriesDataProps.reading.readingValueEst;
    }

    alterSeriesTemperature(buildSeriesDataProps.alterSeriesTemperatureProps);
}

function updateReadTimestampLocalDates(readings: any[], groupBy: string) {
    readings.forEach((reading: any) => {
        let date = DateTime.fromISO(reading.readTimestampLocal);
        if (groupBy === "none") {
            date = DateTime.fromISO(reading.readTimestampLocalStart);
            reading.readTimestampLocalDate = DateTime.fromObject({ year: date.year, month: date.month, day: date.day, hour: date.hour });
            reading.readTimestampLocal = reading.readTimestampLocalDate;
        }
        else {
            reading.readTimestampLocalDate = DateTime.fromObject({ year: date.year, month: date.month, day: date.day }); // only care about days here)
            reading.readTimestampLocal = reading.readTimestampLocalDate;
        }
    });
}

export {
    buildSeriesData,
    updateReadTimestampLocalDates,
    fillMissingHourData,
    fillMissingYearData
}