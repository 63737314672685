import { ApiClient } from "../../../lib/ApiClient";
import { useGenericSWR } from "../../../lib/useGenericSWR";
import * as Yup from 'yup';
import { ensureLuxonDateOnly } from "lib/Formatters";

export const rateApiEndpoint = 'api/v1/admin/Rate';

let formIteration = 0;

const getRatesDefaultItem = (rateClassId, rateType) => {
    formIteration = formIteration + 1;
    return {
        formIteration: "new" + formIteration,
        id: 0,
        rateClassId: rateClassId,
        name: '',
        description: '',
        flatRate: 1,
        rateType: rateType,
        initialRate: '0',
        startDate: ensureLuxonDateOnly(new Date()),
        endDate: ensureLuxonDateOnly(new Date()),
        createdBy: 'REACT_FE',
        updatedBy: 'REACT_FE',
    }
};

const ratesValidationSchema = Yup.object().shape({
    rateClassId: Yup.number()
        .required('Rate Class is required')
        .min(1, 'Rate Class is required'),
    name: Yup.string()
        .required("Required")
        .max(256, 'Max of 256 characters'),
    description: Yup.string()
        .max(1028, 'Max of 1028 characters').nullable(),
    rateType: Yup.string()
        .required("Required")
        .max(256, 'Max of 256 characters'),
    startDate: Yup.date()
        .required("Required"),
    endDate: Yup.date()
        .nullable(),
    flatRate: Yup.number()
        .when('rateType', {
            is: (rateType) => {
                return (rateType === 'FLATRATE');
            },
            then: Yup.number()
                .required('Flat Rate is required'),
        }),
    initialRate: Yup.number()
        .when('rateType', {
            is: (rateType) => {
                return (rateType === 'TIERED');
            },
            then: Yup.number()
                .required('Initial rate is required.'),
        }),
});

const ratesName = "Rates";
const rateName = "Rate";

// See definition from Rate.cs
const apiClient = new ApiClient();
apiClient.getDefaultItem = () => getRatesDefaultItem();
apiClient.getObjectName = () => {
    return ratesName;
};

const useRate = (id, swrOptions = {}) => {
    // SWR will prevent duplicate requests
    // It will also maintain data state as long as your are using the same urls for your requests
    return useGenericSWR(rateApiEndpoint + "/" + id, apiClient, { ...swrOptions });
};

const useRates = (filter, swrOptions = {}) => {
    // SWR will prevent duplicate requests
    // It will also maintain data state as long as your are using the same urls for your requests
    const params = new URLSearchParams();
    params.append('filter', JSON.stringify(filter));
    return useGenericSWR(rateApiEndpoint + "?" + params.toString(), apiClient, { ...swrOptions });
};

const saveRate = (Rate) => {
    if (Rate.id === 0) {
        return insertRate(Rate);
    }
    return updateRate(Rate);
}
const insertRate = (Rate) => {
    return apiClient.poster(rateApiEndpoint, Rate);
};

const updateRate = (Rate) => {
    return apiClient.putter(rateApiEndpoint, Rate);
};

const deleteRate = (Rate) => {
    return apiClient.deleter(rateApiEndpoint + "/" + Rate.id);
};

export {
    rateName,
    ratesName,
    useRate,
    useRates,
    saveRate,
    insertRate,
    updateRate,
    deleteRate,
    ratesValidationSchema,
    getRatesDefaultItem,
};