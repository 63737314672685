import React, { useState, useLayoutEffect, useRef } from 'react';
import { useUsers } from './UserAPIEndpoints';
import { getWholeRow } from '../../../lib/DataGridHelpers';
import { UcPagination } from 'components/common/UcPagination';
import { UcIconButtons } from 'components/common/UcIconButtons';
import { DataGridPro } from '@mui/x-data-grid-pro';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { useTranslation } from "react-i18next";
import ListIcon from '@mui/icons-material/List';

export const UserGrid = (props) => {
    const { t } = useTranslation();
    const [generalSearchInput] = useState(props.generalSearch || "");
    const initialSort = [{ field: "email", sort: "asc" }];
    const sort = props.sort || initialSort;

    const initialDataState = props.dataState || { skip: 0, take: 10, };
    let roleType = props.roleType;

    if(roleType === "SuperAdmin"){
        roleType = "";
    }

    if(roleType === "UtilityAdmin"){
        roleType = 'UtilitySupport,UtilityAdmin';
    }

    const [filter, setFilter] = useState({
        take: initialDataState.take,
        skip: initialDataState.skip,
        sort: sort,
        roleType: roleType,
        generalSearch: generalSearchInput,
        onlyLockedOut: props.onlyLockedOut || false
    });

    let initialFilterModel = {
        items: [
            { columnField: 'email', operatorValue:'contains', value: ''}
        ],
        linkOperator: 'and',
    };
    if (generalSearchInput) {
        initialFilterModel = {
            items: [
                { columnField: 'email', operatorValue:'contains', value: generalSearchInput}
            ],
            linkOperator: 'and',
        }
    }

    const [filterModel] = useState(initialFilterModel);
    const needsDataUpdate = useRef("");

    let defaultDataItems = {
        columns: [
            {
                field: 'details', headerName: '', width: 40,
                valueGetter: getWholeRow,
                renderCell: (params) => {
                    const actions = [
                        {
                            title: t('Users Details'),
                            icon: <ListIcon data-cy-id={"usersDetailsButton_" + params.row.email} />,
                            color: 'primary',
                            isHidden: false,
                            isDisabled: false,
                            requiredPermission: 'User:Read',
                            onClick: () => {
                                props.setDrawerOpen(true);
                            }
                        },
                    ];
                    return (
                        <UcIconButtons actions={actions} />
                    );
                }
            },
            { field: 'email', headerName: t('Email'), flex: 1, minWidth: 220 },
            { field: 'firstName', headerName: t('First Name'), flex: 1, minWidth: 200 },
            { field: 'lastName', headerName: t('Last Name'), minWidth: 200 },
            { field: 'rolesJson', headerName: t('Role'), flex: 1, minWidth: 220, valueFormatter: ( params ) => {
                const rolesJson = params.value !== undefined ? JSON.parse(params.value) : [];
                const roleNames = rolesJson.map(r => r.name);
                return roleNames.join(", ");
            }},
            { field: 'lockoutEnd', headerName: t('Locked Out'), flex: 1, minWidth: 100, valueFormatter: ( params ) => {
                return params.value !== undefined && new Date(params.value) > new Date() ? t("Yes") : t("No");
            }},
            { field: 'address', headerName: t('Address'), flex: 1, minWidth: 200 },
            { field: 'statsInviteCount', headerName: t('Invite Count'), flex: 1, minWidth: 100 },
            {
                field: 'id', headerName: '', flex: 1, minWidth: 100,
                valueGetter: getWholeRow,
                renderCell: (params) => {
                    const actions = [
                        {
                            title: t('Edit User'),
                            icon: <EditIcon data-cy-id={"user_edit_" + params.row.email}/>,
                            color: 'primary',
                            isHidden: false,
                            isDisabled: false,
                            requiredPermission: 'User:Modify',
                            onClick: () => { props.onEditItem(params.value); },
                        },
                        {
                            title: t('Delete User'),
                            icon: <DeleteIcon data-cy-id={"user_delete_" + params.row.email}/>,
                            color: 'error',
                            isHidden: false,
                            isDisabled: false,
                            requiredPermission: 'User:Delete',
                            onClick: () => { props.onDeleteItem(params.value); },
                        }
                    ];

                    return (
                        <UcIconButtons actions={actions} />
                    );
                }
            }
        ],
        rows: [],
        rowCount: -1,
    };
    const [dataItems, setDataItems] = useState(defaultDataItems);

    const defaultSelectedItem = { dataItem: {}, selectedId: 0 };
    const [selectedItem, setSelectedItem] = useState(defaultSelectedItem);

    const isDataLoaded = useRef(false);

    const dataReceived = (swrResult) => {
        var dataItems = swrResult.result;

        var selectedDataItems = dataItems.map((item) => {
            return { ...item, selected: item.id === selectedItem.selectedId };
        });
        selectedDataItems.iteration = dataItems.iteration;
        selectedDataItems.rowCount = swrResult.rowCount;
        if (selectedDataItems.length > selectedDataItems.rowCount) {
            selectedDataItems.rowCount = selectedDataItems.length;
        }

        setDataItems({...defaultDataItems, rows: [...dataItems], rowCount: swrResult.rowCount});
    };

    const swrUsers = useUsers(filter); //Pass the datastate directly as the filter.  The API will adjust filters, orderbys ect.
    if (!swrUsers.result && defaultDataItems.rowCount !== -1)
    {
        setDataItems(defaultDataItems);
    }

    useLayoutEffect(() => {
        if (swrUsers.result) {
            isDataLoaded.current = true;
            var filterString = JSON.stringify(filter);
            if (needsDataUpdate.current !== filterString) {
                needsDataUpdate.current = filterString;
                dataReceived(swrUsers);
            }
        }
    });

    const updateFilter = (filter) => {
        setFilter(filter);
        props.onFilterChange(filter);
    };

    return (
        <div
            data-cy-id={"userDataGridWrapper"}
            onDoubleClick={props.doubleClick}
        >
            <DataGridPro
                autoHeight={true}
                disableColumnFilter={true}
                disableColumnSelector={true}
                loading={(!isDataLoaded.current || !swrUsers.result)}
                paginationModel={{page: filter.skip / filter.take, pageSize: filter.take}}
                onPaginationModelChange={(model) => {
                    console.log("PAGE CHANGED", model, filterModel);
                    updateFilter({ ...filter, skip: model.page * model.pageSize, take: model.pageSize })
                }}
                sortingMode='server'
                paginationMode='server'
                filterMode='server'
                sortModel={filter.sort}
                onSortModelChange={(model) => {
                    console.log("SORT CHANGED", model, filterModel);
                    updateFilter({...filter, sort: model})
                }}
                filterModel={filterModel}
                rowsPerPageOptions={[5, 10, 20]}
                onRowSelectionModelChange={(newSelectionModel) => {
                    if (newSelectionModel.length !== 1)
                    {
                        setSelectedItem(defaultSelectedItem);
                        props.onSelected(defaultSelectedItem.dataItem);
                        return;
                    }
                    const selectedId = newSelectionModel[0];
                    let selectedItem = dataItems.rows.filter(obj => {return obj.id === selectedId});
                    if (selectedItem.length !== 1)
                    {
                        setSelectedItem(defaultSelectedItem);
                        props.onSelected(defaultSelectedItem.dataItem);
                        return;
                    }
                    selectedItem = selectedItem[0];
                    let newlySelectedItem = { dataItem: selectedItem, selectedId: selectedId };

                    setSelectedItem(newlySelectedItem);
                    props.onSelected(newlySelectedItem.dataItem);
                }}
                pagination
                components={{
                    Toolbar: () => {return  props.toolbar || <></>},
                    Pagination: UcPagination,
                }}
                {...dataItems}
                initialState={{ pinnedColumns: { right: ['id'] } }}
            />
        </div>
    );
}

UserGrid.defaultProps = {
    onSelected: (selectedItem) => { console.log("onSelected not implemented", selectedItem); },
    onFilterChange: (filter) => { console.log("onFilterChange not implemented", filter); },
};
