import { RoleUserGrid } from "./RoleUserGrid"
import { useState, useLayoutEffect } from "react";
import { useSearchParams } from "react-router-dom";

export const RoleUserGridStateful = (props) => {
    const [searchParams, setSearchParams] = useSearchParams();
    const queryParamName = (props.id || "RoleUserGrid") + "_state";
    const [isRenderReady, setIsRenderReady] = useState(true);
    const getQueryCurrent = () => {
        let defaultValue = {
            take: 10,
            skip: 0,
            pageSize: 10,
            generalSearch: null,
            roleId: props.roleId || null,
            userId: props.userId || null,
            sort: [{field: "id", sort: "asc"}],
        };

        let valueStr = searchParams.get(queryParamName);
        let result = false;

        try {
            result = JSON.parse(valueStr);
        }
        catch{
            console.error('Invalid Query Param ' + queryParamName, valueStr);
        }
        if (!result) {
            return defaultValue;
        }
        return result;
    };
    let queryCurrent = getQueryCurrent();
    const [current, setCurrent] = useState(queryCurrent);

    const handleFilterChange = (e) => {
        const newCurrent = {...current, ...e};
        setCurrent(newCurrent);
        searchParams.set(queryParamName, JSON.stringify(newCurrent));
        setSearchParams(searchParams);
    }

    useLayoutEffect(() => {
        if (JSON.stringify(queryCurrent) !== JSON.stringify(current) && isRenderReady)
        {
            setIsRenderReady(false);
        }

        if (!isRenderReady)
        {
            setIsRenderReady(true);
            setCurrent(queryCurrent);
        }
    }, [queryCurrent, current, isRenderReady, setIsRenderReady]);

    return (
        <>
            {isRenderReady &&
            <RoleUserGrid {...props}
                sort={current.sort}
                dataState={current}
                generalSearch={current.generalSearch}
                onFilterChange={handleFilterChange}
                />
            }
        </>
    );
}
