import React, { useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
// @ts-ignore
import { useResponsive } from 'lib/useResponsive';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material/styles';
import { ThemeProps } from "components/common/Common.interfaces";
import CustomPaperComponentConstructor from 'components/utils/CustomPaperComponent';
import {useOrgModule, deleteOrgModule, insertOrgModule} from './OrgModuleApiEndpoints';
import LoadingButton from '@mui/lab/LoadingButton';
import Typography from '@mui/material/Typography';

interface OrgModuleFormDialogProps {
    itemToEdit: any;
    open: boolean;
    onCancel: Function;
    onSave: Function;
}

export const OrgModuleFormDialog = (props: OrgModuleFormDialogProps) => {
    const { t } = useTranslation();
    const [dialogSubmit, setDialogSubmit] = useState<number>(0);
    const { isMobile } = useResponsive();
    const theme: ThemeProps = useTheme();
    const swrOrgModule = useOrgModule();
    const [saving, setSaving] = React.useState(false);

    const handleCancel = () => {
        props.onCancel();
    };

    const handleChangeModule = async () => {
        setSaving(true);
        try {
            // If we find the module we delete it, otherwise we insert it.
            const moduleToDelete: OrgModule | undefined = swrOrgModule?.result
                ?.find((orgModule: OrgModule) => orgModule.moduleName === props.itemToEdit?.moduleName);

            if (moduleToDelete) {
                deleteOrgModule(moduleToDelete);
            }
            else {
                insertOrgModule(props.itemToEdit);
            }
        }
        finally {
            setSaving(false);
            props.onSave();
            setDialogSubmit(dialogSubmit + 1);
            window.location.reload();
        }
    };

    return (  
        <Dialog
            fullScreen={isMobile}
            fullWidth={true}
            maxWidth={"md"}
            open={props.open}
            PaperComponent={CustomPaperComponentConstructor}
            aria-labelledby="draggable-dialog-title"
        >
            <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
                {t("Change Module")}
            </DialogTitle>
            <DialogContent>
                <Typography variant="body1" gutterBottom>
                    {t("Changing the dashboard can result in functionality change or loss. Are you sure you want to continue ?")}
                </Typography>
                <Typography variant="body1">
                    {t("Module being changed: ") + props.itemToEdit?.moduleName}
                </Typography>
            </DialogContent>
            <DialogActions>
                    <LoadingButton
                        loading={saving}
                        onClick={handleChangeModule}
                        variant="contained"
                        color="error"
                        style={{ justifyContent:"left", alignItems:"left", display:"flex"} }
                        data-cy-id={"change_module_button"}
                    >
                        {t('Change')}
                    </LoadingButton>
                <Button
                    autoFocus
                    onClick={handleCancel}
                    variant={theme.props?.CancelButton?.variant || "outlined"}
                    color={theme.props?.CancelButton?.color}
                    data-cy-id={"cancel_button"}
                    >
                        {t('Cancel')}
                </Button>
            </DialogActions>
        </Dialog>
    );
}