import React from 'react';
import Box from '@mui/material/Box';
import { styled, useTheme } from '@mui/material/styles';
import Toolbar from '@mui/material/Toolbar';
import Container from '@mui/material/Container';
import './ResponsiveAppBar.scss';
import { UcHideOnScroll } from 'components/common/UcHideOnScroll';
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import { useResponsive } from 'lib/useResponsive';
import UcSharedDrawer from './common/UcSharedDrawer';
import UcSharedAppBar from './common/UcSharedAppBar';

const drawerWidth = 270;

const openedMixin = (theme) => ({
    width: drawerWidth,
    transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen
    }),
    overflowX: "hidden"
});

const closedMixin = (theme) => ({
    transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen
    }),
    overflowX: "hidden",
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up("sm")]: {
        width: `calc(${theme.spacing(7)} + 1px )`
    }
});

const MiniDrawer = styled(MuiDrawer, {
    shouldForwardProp: (prop) => prop !== "open"
})(({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
    boxSizing: "border-box",
    ...(open && {
        ...openedMixin(theme),
        "& .MuiDrawer-paper": openedMixin(theme)
    }),
    ...(!open && {
        ...closedMixin(theme),
        "& .MuiDrawer-paper": closedMixin(theme)
    })
}));

const MiniAppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== "open"
})(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen
    }),
    ...(open && {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(["width", "margin"], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen
        })
    })
}));

// should move pages to app.js and just use here?
const ResponsiveAppBar = (props) => {
    const theme = useTheme();
    const { isMobile, isTablet} = useResponsive();
    const [open, setOpen] = React.useState(isMobile ? false : true);

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

    const dockMenu = isMobile || isTablet;

    return (
        <>
        <Box sx={{ display: "flex" }}>
            <UcHideOnScroll {...props}>
                {dockMenu ? ( <MuiAppBar {...theme.props?.MuiAppBar} position="fixed" open={open} enableColorOnDark>
                    <UcSharedAppBar {...props}
                        isOpen={open}
                        onChange={(value) => {
                            handleDrawerOpen();
                        }} />
                    </MuiAppBar> ) : (
                    <MiniAppBar {...theme.props?.MuiAppBar} position="fixed" open={open} enableColorOnDark>
                        <UcSharedAppBar {...props}
                            isOpen={open}
                            onChange={(value) => {
                            handleDrawerOpen();
                         }} />
                    </MiniAppBar> )
                }
             </UcHideOnScroll>
                <Toolbar disableGutters={true} />
                {dockMenu ? (<MuiDrawer open={open}
                    onClick={() => {
                        handleDrawerClose()
                    }}>
                    <UcSharedDrawer {...props}
                        isOpen={open}
                        onChange={(value) => {
                            handleDrawerClose()
                        }}
                        onHeaderClick={() => {
                            open ? handleDrawerClose() : handleDrawerOpen();
                        }}
                    />
                </MuiDrawer>) : (
                    <MiniDrawer open={open} variant='permanent'>
                            <UcSharedDrawer {...props}
                                isOpen={open}
                                onChange={(value) => {
                                    handleDrawerClose();
                                }}
                                onHeaderClick={() => {
                                    open ? handleDrawerClose() : handleDrawerOpen();
                                }}
                            />
                    </MiniDrawer>)
                }
                <Container className="responsiveAppBar" maxWidth={props.isOutageMapPage ? "100%" : "xl"}
                    sx={{
                        width: (open && !isMobile && !isTablet) ? `calc(100% - ${drawerWidth}px)`
                            : (isMobile || isTablet) ? "100%" : `calc(100% - ${theme.spacing(7)})`,
                        transition: open && theme.transitions.create(["width", "margin"], {
                            easing: theme.transitions.easing.sharp,
                            duration: theme.transitions.duration.leavingScreen
                        })
                    }}
                >
                <Box sx={{ mt: isMobile ? 2 : 4 }}>
                    {props.children}
                </Box>
             </Container>
        </Box>
        </>
    );
};
export default ResponsiveAppBar;