import React, { useEffect, useContext, useState } from 'react';
import { PageContext } from '../../lib/PageProvider';
import { useTranslation } from "react-i18next";
import { UcWidget } from '../common/UcWidget';
import { Grid, Typography, Slider, Button, Chip, Box } from '@mui/material';
import { useResponsive } from 'lib/useResponsive';
import { useTheme } from '@mui/material/styles';
import { DashboardStatWrapper } from 'components/readings/ReadingMultipleUomAPIEndpoint';
import { UcLoading } from '../common/UcLoading';
import Alert from '@mui/material/Alert';
import { UcProgress } from '../common/UcProgress';
import { calculateConvertedVal, milesDrivenCO2Conversion, smartPhonesChargedCO2Conversion } from '../../lib/EnvironmentalCalc';

export const EnvironmentalImpactWidget = (props) => {
    const { account } = props;
    const { t } = useTranslation();
    const { isMobile, isTablet } = useResponsive();
    const theme = useTheme();
    const orgMeasurementUnit = props.orgMeasurementUnit === 'Metric' ? 'km' : 'Miles';
    const [isFetching, setIsFetching] = React.useState(true);
    const [pageState, pageDispatch] = useContext(PageContext);

    useEffect(() => {
        pageDispatch({
            header: "Home",
            actionItems: [
            ]
        });
    }, [pageState.header, pageDispatch]);

    const [sliderValue, setSliderValue] = React.useState(30);

    const handleSliderChange = (event, newValue) => {
        setSliderValue(newValue);
    };

    const [readingData, setReadingData] = React.useState(null);
    const actionItems = [];

    const [hasError, setHasError] = useState(false);
    const [progress, setProgress] = useState({current: 0, total: 100});
    const [readingDataStats, setReadingDataStats] = React.useState(null);

    React.useEffect(() => {
        if (!isFetching && !hasError && readingData && Object.keys(readingData).length > 0) {
            setReadingDataStats(readingData?.readings_kWh?.readingStats);
        }
    }, [isFetching, hasError, readingData, readingDataStats]);


    const hasStats = (readingDataStats?.EnvironmentalImpactTotal !== null);

    let distanceDriven = calculateConvertedVal(readingDataStats?.environmentalImpactTotal,
                            milesDrivenCO2Conversion,
                            sliderValue);
    if (orgMeasurementUnit === 'km') { 
        distanceDriven = (distanceDriven * 1.60934).toFixed(0);
    }

    const smartPhonesCharged = calculateConvertedVal(readingDataStats?.environmentalImpactTotal,
                            smartPhonesChargedCO2Conversion,
                            sliderValue);

    useEffect(() => {
        if (account) {
            setHasError(false);
        }
    }, [account]);

    return (
        <UcWidget
            actionItems={actionItems}
            minHeight={isMobile || isTablet ? 'auto' : '402px'}
            title={t("Environmental Impact")}
        >
            <div classname="dataCyId" data-cy-id="environmental_impact_widget"/>
            <UcLoading hasFade={false}
                hasError={hasError}
                isLoading={isFetching}
                height={'20vh'}
                loadingRender={<UcProgress {...progress}/>}
            >
                <Grid container rowSpacing={1} sx={{px: 2, py:1}}>
                    { hasStats ? (
                        <>
                        <Grid item xs={12}>
                            <Typography variant="body1">
                                {t('How much of a difference does saving electricity really make?')}
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="h6">
                                {t('My Electricity Savings')}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sx={{ml: !isMobile && 2, mr: !isMobile && 5}}>
                            <Typography variant="h6">
                                {sliderValue+"%"}
                            </Typography>
                            <Slider
                                sx={{color:theme.props?.Widget?.slider, pb: 0}}
                                step={5}
                                value={sliderValue}
                                onChange={handleSliderChange}
                                aria-label="slider" />
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="h6">
                                {t('My Impact')}
                            </Typography>
                            <Typography variant="body1">
                                {t('If you were able to cut your electricity usage by {{sliderValue}}%, you would have the same impact every month as:',{ sliderValue: sliderValue })}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sx={{ml: !isMobile && 2, mt: isMobile && 1}}>
                            <Grid container direction="row">
                                    <Grid item xs={3} sm={1.5}>
                                    <Box display="flex" direction="column" justifyContent="flex-end">
                                        <Chip
                                            label={distanceDriven}
                                            sx={{
                                                fontSize: "16px",
                                                fontWeight: 500,
                                                mr: 2,
                                                maxWidth: 200,
                                            }}

                                        />
                                    </Box>
                                </Grid>
                                    <Grid item xs={8.5} sm={10.5}>
                                    <Typography variant="body1" data-cy-id="environmental_stat1">
                                        {t('{{orgMeasurementUnit}} driven by an average gas-powered vehicle (greenhouse gas emissions)', { orgMeasurementUnit: orgMeasurementUnit })}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid item xs={12} sx={{ml: !isMobile && 2, mt: isMobile && 1}}>
                            <Grid container direction="row">
                                    <Grid item xs={3} sm={1.5}>
                                    <Box display="flex" direction="column" justifyContent="flex-end">
                                        <Chip
                                            label={smartPhonesCharged}
                                            sx={{
                                                fontSize: "16px",
                                                fontWeight: 500,
                                                mr: 2,
                                                maxWidth: 200,

                                            }}
                                        />
                                    </Box>
                                </Grid>
                                <Grid item xs={8.5} sm={10.5}>
                                    <Typography variant="body1" data-cy-id="environmental_stat2">
                                        {t('Number of smartphones charged (CO2 Emissions)')}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Button
                                sx={{textTransform: 'none'}}
                                href="https://www.epa.gov/energy/greenhouse-gas-equivalencies-calculator"
                                target="_blank">
                                    {t('Reference: www.epa.gov/energy/greenhouse-gas-equivalencies-calculator')}
                            </Button>
                        </Grid>
                        </>
                    ) : (
                        <Alert severity="warning" sx={{mt:3}}>
                            {t("Sorry we don't have enough data for your account. Try again later.")}
                        </Alert>
                    )}
                    </Grid>
            </UcLoading>
            {account?.id !== null && (
                <DashboardStatWrapper
                    readyForData={!hasError}
                    onDataCompleted={(newData) => {
                        setIsFetching(false);
                        setReadingData(newData);
                        setHasError(false);
                    }}
                    onProgress={(progressLocal) => {
                        if (progressLocal.current !== progress.current) {
                            setProgress({...progressLocal});
                        }
                    }}
                    onReset={() => {
                        setProgress({current: 0, total: 100});
                        setIsFetching(true);
                        setHasError(false);
                    }}
                    onError={(error) => {
                        console.error(error);
                        setIsFetching(false);
                        setHasError(true);
                    }}
                />
            )}
        </UcWidget>
    );
}
