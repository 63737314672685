import React, { useState, useContext, useEffect, useLayoutEffect } from 'react';
import { PageContext } from '../../../lib/PageProvider';
import { RateChargeGridStateful } from "./RateChargeGridStateful"
import RateChargeFormDialog from './RateChargeFormDialog';
import RateChargeRulesDialog from './RateChargeRulesDialog';
import { mutateUrls } from "../../../lib/useGenericSWR";
import { useSWRConfig } from "swr";
import { EnsureUser } from '../../auth/EnsureUser';
import { UcToolbar } from '../../common/UcToolbar';
import { useHasPermission } from 'components/auth/PermissionApiEndpoint';
import { dateRangeFormatterIgnoreUtc } from '../../../lib/Formatters';
import AddIcon from '@mui/icons-material/Add';
import { getRateChargeDefaultItem } from './RateChargeApiEndpoints';
import { Typography } from '@mui/material';
import { useTranslation } from "react-i18next";
import { useRateCharges } from './RateChargeApiEndpoints';

export default function RateChargeList(props) {
    const { t } = useTranslation();
    const rateVersion = React.useRef(props.rateVersion);
    const [editDialog, setEditDialog] = useState({ open: false, delete: false });
    const [rulesDialog, setRulesDialog] = useState({ open: false });
    const [selectedItem, setSelectedItem] = useState(null);
    const [pageState, pageDispatch] = useContext(PageContext);
    const [generalSearch, setGeneralSearch] = useState('');
    const [reMutateUrls, setReMutateUrls] = useState(false);

    let existingItems = null;
    let selectedRateVersion = props.rateVersion;
    const swrConfig = useSWRConfig();

    const filter = {
        rateVersionId: parseInt(selectedRateVersion.id),
    };
    const swrRateCharges = useRateCharges(filter);

    if (swrRateCharges.result) {
        existingItems = swrRateCharges.result.flatMap(obj => {
            let result = [];

            if (obj.basicHtmlColors && obj.basicHtmlColors.length > 0) {
                result.push(...obj.basicHtmlColors.map(item => ({ id: item.id, htmlColor: item.htmlColor })));
            }

            if (obj.touHtmlColors && obj.touHtmlColors.length > 0) {
                result.push(...obj.touHtmlColors.map(item => ({ id: item.id, htmlColor: item.htmlColor })));
            }

            if (obj.tierHtmlColors && obj.tierHtmlColors.length > 0) {
                result.push(...obj.tierHtmlColors.map(item => ({ id: item.id, htmlColor: item.htmlColor })));
            }

            return result;
        });
    }

    const closeEditDialog = () => {
        setEditDialog({ open: false, itemToEdit: editDialog.dataItem });
    }

    const saveEditDialog = (values) => {
        closeEditDialog();
        setReMutateUrls(true);

        if (editDialog.itemToEdit.formIteration.includes('new') && (values.type === "TIERED" || values.type === "DISCOUNT" || values.type === "GENERATION")) {
            editRules(values);
        }
    }

    const openEditDialog = (dataItem) => {
        if (!dataItem) {
            return;
        }
        if (!dataItem.formIteration) {
            dataItem.formIteration = "edit" + dataItem.id;
        }
        setEditDialog({ open: true, itemToEdit: dataItem, delete: false });
    }

    const deleteEditDialog = (dataItem) => {
        if (!dataItem) {
            return;
        }
        if (!dataItem.formIteration) {
            dataItem.formIteration = "delete" + dataItem.id;
        }
        setEditDialog({ open: true, itemToEdit: dataItem, delete: true, setDefault: false });
    }

    const createItem = () => {
        openEditDialog(getRateChargeDefaultItem(rateVersion.current?.id));
    }

    const editItem = (item) => {
        openEditDialog(item);
    }

    const deleteItem = (item) => {
        deleteEditDialog(item);
    }

    const openRulesDialog = (dataItem) => {
        if (!dataItem) {
            return;
        }
        if (!dataItem.formIteration) {
            dataItem.formIteration = "editRules" + dataItem.id;
        }
        setRulesDialog({ open: true, itemToEdit: dataItem });
    }

    const closeRulesDialog = () => {
        setRulesDialog({ open: false, itemToEdit: rulesDialog.dataItem });
    }

    const editRules = (item) => {
        openRulesDialog(item);
    }

    const actions = [
        {
            icon: <AddIcon />,
            title: t('Add Charge'),
            toolTip: t('Add Charge'),
            onClick: () => { createItem(); },
            isHidden: !useHasPermission("RateCharge:Modify"),
        }
    ];

    useLayoutEffect(() => {
        if (reMutateUrls) {
            mutateUrls(swrConfig);
            setReMutateUrls(false);
        }
    }, [reMutateUrls, swrConfig]);

    useEffect(() => {
        pageDispatch({
            header: ' Rate Charges',
        });
    }, [pageState.header, selectedItem, pageDispatch]);

    React.useEffect(() => {
        if (props.rateVersion !== rateVersion.current) {
            rateVersion.current = props.rateVersion;

            setReMutateUrls(true);
        }
    }, [props.rateVersion]);

    console.log("RateChargeList - Loaded");
    return (
        <div className="rateChargeListPage">
            <EnsureUser permissions={["RateCharge:Read"]}>
                <Typography variant="h4">
                    {'Charges'}
                </Typography>
                <Typography variant="subtitle1" gutterBottom sx={{mb: 2}}>
                    {`${rateVersion.current?.name} (${dateRangeFormatterIgnoreUtc(rateVersion.current?.startDate, rateVersion.current?.endDate, true, "", 0)})`}
                </Typography>
                    <UcToolbar
                        hasGeneralSearch={true}
                        onGeneralSearchChange={(value) => {
                            setGeneralSearch(value);
                        } }
                        actions={actions}
                    />
                <RateChargeFormDialog
                    open={editDialog.open}
                    delete={editDialog.delete}
                    itemToEdit={editDialog.itemToEdit}
                    onCancel={closeEditDialog}
                    onSave={saveEditDialog}
                    rateVersion={rateVersion}
                    existingItems={existingItems}
                />
                <RateChargeRulesDialog
                    open={rulesDialog.open}
                    itemToEdit={rulesDialog.itemToEdit}
                    onCancel={closeRulesDialog}
                    existingItems={existingItems}
                />
                {!reMutateUrls &&
                    <RateChargeGridStateful
                        onEditItem={editItem}
                        onEditRules={editRules}
                        onDeleteItem={deleteItem}
                        generalSearch={generalSearch}
                        rateVersionId={rateVersion.current?.id}
                        onSelected={(selectedDataItem) => {
                            setSelectedItem({ ...selectedDataItem });
                        }}
                        hideGenerationRate={true}
                    />
                }
            </EnsureUser>
        </div>
    );
};