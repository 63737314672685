import { ApiClient } from "../../../lib/ApiClient";
import { globalTranslator } from "lib/GlobalTranslator";
import { useGenericSWR } from "../../../lib/useGenericSWR";
import * as Yup from 'yup';

const apiEndpoint = 'api/v1/MyAccount';

let formIteration = 0;
const t = globalTranslator;

const getMyAccountsDefaultItem = () => {
    formIteration = formIteration + 1;
    return {
        formIteration: "new" + formIteration,
        id: 0,
    }
};

const myAccountsValidationSchema = Yup.object().shape({
    cepOrgId: Yup.number().required(t("Required")),
});

const apiClient = new ApiClient();
apiClient.getDefaultItem = () => getMyAccountsDefaultItem();
apiClient.getObjectName = () => {
    return "MyAccount";
};

const mutateMyAccounts = () => {
    apiClient.mutateFetchUrls();
}

// I want to be clear about what these endpoints are returning
// The current endpoint is still just "/MyAccounts" but is actually returning UserAccounts
// I do not export these at the moment
// That may change
const useMyUserAccount = (id, swrOptions = {}) => {
    // SWR will prevent duplicate requests
    // It will also maintain data state as long as your are using the same urls for your requests
    return useGenericSWR(apiEndpoint + "/" + id, apiClient, { ...swrOptions });
};

const useMyUserAccounts = (filter, swrOptions = {}) => {
    // SWR will prevent duplicate requests
    // It will also maintain data state as long as your are using the same urls for your requests
    const params = new URLSearchParams();
    if (filter)
    {
        params.append('filter', JSON.stringify(filter));
    }
    else {
        // Default filter the same as the filter on MyAccountSelect
        params.append('filter', JSON.stringify({
                take: 100,
                skip: 0,
                sort: [{ field: "accountName", sort: "asc" }],
                generalSearch: "",
                allowDelegations: true
            }));
    }
    return useGenericSWR(apiEndpoint + "?" + params.toString(), apiClient, { ...swrOptions });
};

const convertUserAccountToAccount = (userAccount) => {
    return {
        ...userAccount,
        id: userAccount.accountId,
        address: userAccount.accountAddress,
        name: userAccount.accountName,
        cepOrgId: userAccount.cepOrgId,
        udmOrgId: userAccount.udmOrgId,
        extSource: userAccount.extSource,
        extAccountId: userAccount.extAccountId,
        extAccountNumber: userAccount.extAccountNumber,
    };
}

const useMyAccount = (id, swrOptions = {}) => {
    let myUserAccount = useMyUserAccount(id, swrOptions);

    if (myUserAccount.result && !myUserAccount.isLoading)
    {
        // Transform results;
        myUserAccount.result = myUserAccount.result.map((userAccount) => {
            return convertUserAccountToAccount(userAccount);
        });
    }
    return myUserAccount;

};

const useMyAccounts = (filter, swrOptions = {}) => {
    let myUserAccounts = useMyUserAccounts(filter, swrOptions);

    if (myUserAccounts.result && !myUserAccounts.isLoading)
    {
        // Transform results;
        if (Array.isArray(myUserAccounts.result))
        {
            myUserAccounts.result = myUserAccounts.result.map((userAccount) => {
                return convertUserAccountToAccount(userAccount);
            });
        }
    }
    return myUserAccounts;

};

export {
    useMyAccount,
    useMyAccounts,
    convertUserAccountToAccount,
    myAccountsValidationSchema,
    getMyAccountsDefaultItem,
    mutateMyAccounts
};