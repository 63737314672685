import React from 'react';
import { createRoot } from 'react-dom/client';
// @ts-ignore
import { App } from './App';
// @ts-ignore
import reportWebVitals from './reportWebVitals';
// @ts-ignore
import { AppProvider } from './lib/AppProvider';
import { BrowserRouter } from 'react-router-dom';
import { AuthProvider } from "react-oidc-context";
import './internationalization';
import { OIDC_CONFIG } from 'components/auth/oidc.constants';

const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href')??'';

const container = document.getElementById('root');
const root = createRoot(container as Element); // As Element because it could be null (event though it never will be)
root.render(
    <>
        <AuthProvider {...OIDC_CONFIG}>
        <BrowserRouter basename={baseUrl}>
            <AppProvider>
                <App />
            </AppProvider>
        </BrowserRouter>
        </AuthProvider>
    </>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();