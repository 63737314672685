import React, { useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { useTranslation } from "react-i18next";

export const IdleTimerProvider = ({ children }) => {
  const { t } = useTranslation();
  const [open] = useState(false);

  // TODO reimplement idle timer
  // We no longer have the need for this currently but may be a nice to have

  const handleContinue = () => {
    window.location.reload();
  }

  return (
      <>
          <Dialog
              fullWidth

              maxWidth={'xs'}
              open={open}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
          >
              <DialogTitle id="alert-dialog-title">
                  {t("Are you still here?")}
              </DialogTitle>
              <DialogContent>
              <DialogContentText id="alert-dialog-description">
                  {t('Click the button below to continue.')}
              </DialogContentText>
              </DialogContent>
              <DialogActions>
              <Button variant='contained' onClick={handleContinue} autoFocus>
                  {t('Continue')}
              </Button>
              </DialogActions>
          </Dialog>
          {children}
      </>
  );
};