// @ts-ignore
import { ApiClient } from "../../lib/ApiClient";
// @ts-ignore
import { useGenericSWR } from "../../lib/useGenericSWR";
// @ts-ignore
import { SwrOptions } from '../../lib/useGenericSWR.interfaces';
// @ts-ignore
import { DateTime } from "luxon";

const userUtilitiesEndpoint = 'api/v1/UserUtilities';

const apiClient = new ApiClient();
apiClient.requiresAuth = false;

const useUserUtilitiesClockSettings = (currentBrowserTime: DateTime, swrOptions = {}) => {
    const params = new URLSearchParams();
    params.append('currentBrowserTime', currentBrowserTime.toUTC().toISO());
    return useGenericSWR(userUtilitiesEndpoint + "/clockSettings?" + params.toString(), apiClient, { ...swrOptions as SwrOptions });
};

export {
    useUserUtilitiesClockSettings
};