import React from 'react';
import { ApiClient } from "../../lib/ApiClient";
import { useGenericSWR } from "../../lib/useGenericSWR";

const apiClient = new ApiClient();
const apiEndpoint = 'api/v1/MyCostPrediction';

const useMyCostPredictionSummary = (accountId, groupBy, uom, currentBill, groupDataPoints = true, swrOptions = {}) => {
    const params = new URLSearchParams();
    params.append('accountId', accountId);
    params.append('groupBy', groupBy);
    params.append('uom', uom);
    params.append('readingAlgos', 'PREDICT_PREV_YEAR');
    params.append('currentBill', currentBill);
    params.append('groupDataPoints', groupDataPoints);

    let url = apiEndpoint + "/Summary?" + params.toString();
    if (accountId === null) {
        url = "";
    }
    return useGenericSWR(url, apiClient, { ...swrOptions });
};

const MyCostPredictionSummaryWrapper = ({ setState, setFetchingState, accountId, groupBy, uom, currentBill, groupDataPoints }) => {
    const data = useMyCostPredictionSummary(accountId, groupBy, uom, currentBill, groupDataPoints);

    React.useEffect(() => {
        if (!data.isLoading) {
            setState(data);
            setFetchingState(false);
        }
    }, [setState, setFetchingState, data]);

    return null;
};

export {
    useMyCostPredictionSummary,
    MyCostPredictionSummaryWrapper
}