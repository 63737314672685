import { AnnouncementGrid } from "./AnnouncementGrid"
import { useState, useLayoutEffect, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { isEqual } from "lib/Compare";

export const AnnouncementGridStateful = (props) => {
    const [searchParams, setSearchParams] = useSearchParams();
    const queryParamName = (props.id || "announcementGrid") + "_state";
    const [isRenderReady, setIsRenderReady] = useState(true);
    const getQueryCurrent = () => {
        let defaultValue = {
            take: 10,
            skip: 0,
            pageSize: 10,
            generalSearch: props.generalSearch || null,
            sort: [{ field: "createdTs", sort: "desc" }],
        };

        let valueStr = searchParams.get(queryParamName);
        let result = false;

        try {
            result = JSON.parse(valueStr);
        }
        catch {
            console.error('Invalid Query Param ' + queryParamName, valueStr);
        }
        if (!result) {
            return defaultValue;
        }
        return result;
    };
    let queryCurrent = getQueryCurrent();
    const [current, setCurrent] = useState(queryCurrent);

    const handleFilterChange = (e) => {
        const newCurrent = { ...current, ...e };
        setCurrent(newCurrent);
        searchParams.set(queryParamName, JSON.stringify(newCurrent));
        setSearchParams(searchParams);
    }

    useEffect(() => {
        if (!isEqual(current.generalSearch, props.generalSearch)) {
            const newCurrent = { ...current, generalSearch: props.generalSearch, skip: 0 }
            setCurrent(newCurrent);
            searchParams.set(queryParamName, JSON.stringify(newCurrent));
            setSearchParams(searchParams);
            setIsRenderReady(false);
        }
    }, [current, props.generalSearch, queryParamName, searchParams, setSearchParams]);

    useLayoutEffect(() => {
        if (JSON.stringify(queryCurrent) !== JSON.stringify(current) && isRenderReady) {
            setIsRenderReady(false);
        }

        if (!isRenderReady) {
            setIsRenderReady(true);
            setCurrent(queryCurrent);
        }
    }, [queryCurrent, current, isRenderReady, setIsRenderReady]);

    return (
        <>
            {isRenderReady &&
                <AnnouncementGrid {...props}
                    sort={current.sort}
                    dataState={current}
                    onFilterChange={handleFilterChange}
                />
            }
        </>
    );
}