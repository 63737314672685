//@ts-ignore
import { EnsureUser } from "components/auth/EnsureUser";
//@ts-ignore
import { UcTabStrip } from '../../common/UcTabStrip';
//@ts-ignore
import { UcMobileNavPage } from '../../common/UcMobileNavPage';
import { PageContext, PageState, PageAction } from "lib/PageProvider";
import { useContext, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
//@ts-ignore
import { useResponsive } from 'lib/useResponsive';
//@ts-ignore
import { useStatefulParam } from 'lib/useStatefulParam';
//@ts-ignore
import { UcWidget } from '../../common/obsolete/UcWidget';
import { useTheme } from '@mui/material/styles';
import QuickActionsWidgetPage from './QuickActionsWidgetPage';

export const UtilityDashboardPage = () => {
    const { t } = useTranslation();
    const { isMobile } = useResponsive();
    const statefulParam = useStatefulParam({ paramName: "utilityDashboardNav_link", paramValue: 'Quick Actions Widget'});
    const [pageState, pageDispatch] = useContext<[PageState, React.Dispatch<PageAction>]>(PageContext);
    const params = new URLSearchParams(window.location.search);
    const theme = useTheme();

    const views = useMemo(() => {
        const commonViews = [
            {
                title: t("Quick Actions Widget"),
                subtitle: t("Modify Quick Actions Widget"),
                tabRender: <div>{t("Quick Actions Widget")}</div>,
                contentRender: <QuickActionsWidgetPage />
            }
        ];

        return commonViews;
        
    }, [t]);

    useEffect(() => {
        pageDispatch({
            header: t("Utility Settings Dashboard"),
            actionItems: [] as any[],
            variableHeader: "",
            tabs: views,
            onTabChange: () => { },
            tabValue: ''
        });
    }, [pageState.header, pageDispatch, t, views]);

    console.log("UtilityDashboard - Loaded");
    return (
        <EnsureUser>
            { isMobile && (
                <UcMobileNavPage
                    pageTitle={t("Utility Settings Dashboard")}
                    onChange={statefulParam.onSetValue}
                    views={views}
                    value={params.get("utilityDashboardNav_link") !== null ? statefulParam.value : 0}
                    contentBackgroundColor={theme.palette.background.default}
                />
            )}
            { !isMobile && statefulParam.isRenderReady && (
                <UcWidget contentPadding={0}>
                    <UcTabStrip
                        onChange={statefulParam.onSetValue}
                        showInMenu={true}
                        tabs={views}
                        value={statefulParam.value}
                    />
                </UcWidget>
            )}
        </EnsureUser>
    );
}