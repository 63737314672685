import React, { useState, useContext, useEffect, useLayoutEffect } from 'react';
import { getOrgUomsDefaultItem } from './OrgUomAPIEndpoints';
import { PageContext } from '../../../lib/PageProvider';
import { OrgUomGridStateful } from "./OrgUomGridStateful"
import { OrgUomFormDialog } from './OrgUomFormDialog';
import { mutateUrls } from "../../../lib/useGenericSWR";
import { useSWRConfig } from "swr";
import './OrgUomListPage.scss';
import { EnsureUser } from '../../auth/EnsureUser';
import { UcToolbar } from '../../common/UcToolbar';
import { UcListPage } from 'components/common/UcListPage';
import { useHasPermission } from 'components/auth/PermissionApiEndpoint';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';

export const OrgUomListPage = (props) => {
    const cepOrgId = props.cepOrgId;
    const [editDialog, setEditDialog] = useState({ open: false });
    const [selectedItem, setSelectedItem] = useState(null);
    const [pageState, pageDispatch] = useContext(PageContext);
    const [generalSearch, setGeneralSearch] = useState('');

    const [reMutateUrls, setReMutateUrls] = useState(false);
    const swrConfig = useSWRConfig();

    const closeHandler = () => {
        setEditDialog({ open: false, itemToEdit: editDialog.dataItem });
    }

    const saveHandler = () => {
        closeHandler();
        // Also, remutate
        setReMutateUrls(true);
    }

    const openDialog = (dataItem) => {
        if (!dataItem) {
            return;
        }
        if (!dataItem.formIteration) {
            dataItem.formIteration = "edit" + dataItem.id;
        }
        setEditDialog({ open: true, itemToEdit: dataItem });
    }

    const createItem = () => {
        openDialog(getOrgUomsDefaultItem());
    }

    const editItem = () => {
        openDialog(selectedItem);
    }

    const actions = [
        {
            icon: <AddIcon />,
            title: 'Add Uom',
            toolTip: 'Add Uom',
            onClick: () => { createItem(); },
            isHidden: !useHasPermission("OrgUom:Modify"),
            isDisabled: false
        },
        {
            icon: <EditIcon />,
            title: 'Edit Uom',
            toolTip: 'Edit Uom',
            onClick: () => { editItem(); },
            isHidden: !useHasPermission("OrgUom:Modify"),
            isDisabled: selectedItem !== null ? false : true
        }
    ];

    useLayoutEffect(() => {
        if (reMutateUrls) {
            mutateUrls(swrConfig);
            setReMutateUrls(false);
        }
    }, [reMutateUrls, swrConfig]);

    useEffect(() => {
        pageDispatch({
            header: "Unit of Measure",
        });
    }, [pageState.header, selectedItem, pageDispatch]);

    return (
        <UcListPage>
            <EnsureUser permissions={["OrgUom:Read"]}>
                <h1 className="pageHeader">Unit of Measure</h1>
                <UcToolbar
                    hasGeneralSearch={true}
                    onGeneralSearchChange={(value) => {
                        setGeneralSearch(value);
                    } }
                    actions={actions}>
                </UcToolbar>
                <OrgUomFormDialog
                    open={editDialog.open}
                    formType={editDialog.itemToEdit?.id > 0 ? "Edit" : "Add"}
                    itemToEdit={editDialog.itemToEdit}
                    id={editDialog.itemToEdit?.id}
                    cepOrgId={cepOrgId}
                    onCancel={closeHandler}
                    onSave={saveHandler}
                />
                {!reMutateUrls &&
                    <OrgUomGridStateful
                        generalSearch={generalSearch}
                        cepOrgId={cepOrgId}
                        onSelected={(gridSelectedItem) => setSelectedItem({ ...gridSelectedItem })} />
                }
            </EnsureUser>
        </UcListPage>
    );
};