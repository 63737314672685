import * as React from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
import { useEnums } from 'components/auth/PermissionApiEndpoint';

// From: https://mui.com/components/autocomplete/#Asynchronous.js
export const UcEnumSelect = React.forwardRef((props, ref) => {
    const enumName = props.enumName;
    const [open, setOpen] = React.useState(false);
    const [options, setOptions] = React.useState([]);
    const needsUpdate = React.useRef(true);

    let loading = true;
    let loadedOptions = [];

    let enums = useEnums(enumName);

    if (enums)
    {
        if (!props.showOutageTypes) {
            enums = enums.filter(x => x.id !== 'Planned' && x.id !== 'Confirmed' && x.id !== 'Proposed');
        }

        if (enumName === 'NotificationType') { // Used in UserNotificationPreferenceForm
            enums = enums.filter(x => x.id !== 'NewBill' && x.id !== 'BillAlmostDue' && x.id !== 'Outage');
        }
        enums = enums.filter(x => x.id !== 'Announcement');

        //filter out automated from frequency
        if (props.hideAutomatedFreq && enumName === 'Frequency') {
            enums = enums.filter(x => x.id !== 'Automated');          
        }

        //filter only for planned and confirmed outage for outagetype
        if (enumName === 'OutageType') {
            enums = enums.filter(x => x.id === 'Planned' || x.id === 'Confirmed');
        }

        //filter out generation from type dropdown 
        if (props.hideGenerationRate && enumName === 'RateChargeType') {
            enums = enums.filter(x => x.id !== 'GENERATION');
        }

        loadedOptions = enums;
        loading = false;
        if (needsUpdate.current)
        {
            needsUpdate.current = false;
            setOptions([...loadedOptions]);
        }
    }
    else
    {
        needsUpdate.current = true;
        loading = true;
    }

    let value = props.value;
    if (value && !value.id && enums){
        value = enums.find(x => x.id === value);
    }
    else {
        value = { id: undefined, display: 'Loading...' }
    }

    let id = React.useId();

    return (
        <Autocomplete
            disableClearable={props.disableClearable}
            ref={ref}
            id={props.id || "enumSelect" + id}
            data-cy-id={props.datacyid}
            open={open}
            value={value}
            onChange={(event, newValue) => {
                props.onChange(event, newValue);
            }}
            onOpen={() => {
                setOpen(true);
            }}
            onClose={() => {
                setOpen(false);
            }}
            isOptionEqualToValue={(option, value) => {
                if (value === undefined)
                {
                    return false;
                }
                return option.id === value || option.id === value.id
            }}
            getOptionLabel={(option) => option.display}
            options={options}
            loading={loading}
            fullWidth={false}
            disabled={props.disabled}
            size={props.forcedSize || 'normal'}
            style={{width: props.forcedWidth || '100%', padding: props.forcedPadding || 0, display: props.forcedDisplay || 'block'}}
            renderInput={(params) => (
                <TextField
                    color={props.color || "primary"}
                    {...params}
                    label={props.label}
                    InputLabelProps={{
                        ...params.InputLabelProps,
                        color:props.color || "primary",
                    }}
                    InputProps={{
                        ...params.InputProps,
                        color:props.color || "primary",
                        endAdornment: (
                        <React.Fragment>
                            {loading ? <CircularProgress color="inherit" size={20} /> : null}
                            {params.InputProps.endAdornment}
                        </React.Fragment>
                        ),
                    }}
                />
        )}
        />
    );
});

UcEnumSelect.defaultProps = {
    onChange: (event, value) => { console.log("onChange not implemented", event, value); },
};