import { useBillPDF } from './MyBillsAPIEndpoint';
import { useParams } from 'react-router-dom';
import { UcLoading } from 'components/common/UcLoading';

export const MyBillsPdfPage = () => {
  const accountNumber = useParams()['accountNumber'];

  const billsSWR = useBillPDF(true, accountNumber, "testFileName");

  if (billsSWR.result) {
    const url = window.URL.createObjectURL(new Blob([billsSWR.result], { type: 'application/pdf' }));
    window.location.href = url;
  }

  return (
    <UcLoading
        isLoading={true}
    />
  );
};