import React from "react";
import { NavLink } from 'react-router-dom';
import { useAuthUser } from './auth/useAuthUser';
import { SignOutButton } from './auth/SignOutButton';
import { SignInButton } from './auth/SignInButton';
import { useTheme } from '@mui/material/styles';
import Tooltip from '@mui/material/Tooltip';
import Avatar from '@mui/material/Avatar';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Divider from '@mui/material/Divider';
import Link from '@mui/material/Link';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import InfoIcon from '@mui/icons-material/Info';
import Box from '@mui/material/Box';
import { OrgSelect } from './customer/orgs/OrgSelect';
import { useUser, useAppInfo } from './auth/PermissionApiEndpoint';
import { useTranslation } from "react-i18next";
import { EnsureUser } from './auth/EnsureUser';
import { useMyOrgs } from 'components/customer/orgs/MyOrgAPIEndpoints';
import Badge from '@mui/material/Badge';
import { Notifications as NotificationsIcon } from '@mui/icons-material';
import { routePaths } from 'routes';
import { getUrl, redirectDomain } from "domain";
import { useResponsive } from 'lib/useResponsive';
import { useNavigate, useLocation } from 'react-router';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Paper from '@mui/material/Paper';
import Draggable from 'react-draggable';

import { dateFormatter } from 'lib/Formatters';
import GitInfo from 'react-git-info/macro';
import { Alert } from "@mui/material";

function PaperComponent(props) {
    return (
        <Draggable
            handle="#draggable-dialog-title"
            cancel={'[class*="MuiDialogContent-root"]'}
        >
            <Paper {...props} />
        </Draggable>
    );
}

const UserMenu = (props) => {
    const { t } = useTranslation();
    const authUser = useAuthUser();
    const theme = useTheme();
    const user = useUser();
    const navigate = useNavigate();
    const { isMobile } = useResponsive();
    const location = useLocation();

    const signedInSettings = [
        { title: t('Profile'), url: getUrl("/myProfile"), component: NavLink, sort: 1 },
        { title: t('Sign Out'), component: SignOutButton, sort: 3 }
    ];

    const signedOutSettings = [
        { title: t('Sign in'), component: SignInButton }
    ];

    const userOrg = useMyOrgs()?.result?.[0];

    if (userOrg !== null && userOrg?.externalLink !== undefined && userOrg?.externalLink?.length > 0)
    {
        signedInSettings[signedInSettings.length + 1] = { title: t('Bill Payment'), component: Link, url:  userOrg?.externalLink, isExternal: true, sort: 2};
        signedInSettings.sort(function(a, b) {
            return a.sort - b.sort;
          });
    }
    // External Link Snippet End

    const appInfo = useAppInfo();
    const version = appInfo.appInfo?.appVersion;
    const dbVersion = appInfo.appInfo?.dbAppVersion;
    const jsHash = appInfo.appInfo?.jsHash;
    const cssHash = appInfo.appInfo?.cssHash;
    const jsHashTarget = appInfo.appInfo?.jsHashTarget;
    const cssHashTarget = appInfo.appInfo?.cssHashTarget;

    const gitInfo = GitInfo();
    const gitHash = gitInfo.commit.shortHash;
    const gitDate = dateFormatter(gitInfo.commit.date, true);

    const [anchorElUser, setAnchorElUser] = React.useState(null);
    const [anchorElAbout, setAnchorElAbout] = React.useState(null);

    const handleOpenUserMenu = (event) => {
        setAnchorElUser(event.currentTarget);
    };
    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };

    const handleOpenAboutMenu = (event) => {
        setAnchorElAbout(event.currentTarget);
        handleCloseUserMenu();
    };
    const handleCloseAboutMenu = () => {
        setAnchorElAbout(null);
    };

    const handleGoToAlerts = () => {
        navigate(routePaths.notificationsPage.path, { state: { from: location } });
    }

    let isLoggedIn = false;
    if (!authUser.isLoading) {
        isLoggedIn = authUser.isLoggedIn;
    }

    return (
        <>
            <Tooltip data-cy-id={"userNotifications"} title={t("Notifications")}>
                <IconButton onClick={handleGoToAlerts} sx={{mr: 1.8}}>
                <Badge badgeContent={user.pendingUserNotificationsCount} color="error" invisible={user.pendingUserNotificationsCount <= 0}>
                    <NotificationsIcon sx={{color: 'primary.contrastText'}} />
                </Badge>
                </IconButton>
            </Tooltip>

            <Tooltip data-cy-id={"userMenu"} title={t("Profile")}>
                <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                    <Avatar alt={authUser.username} sx={{color: 'primary.contrastText'}} />
                </IconButton>
            </Tooltip>
            <Menu
                sx={{ mt: '45px' }}
                id="menu-appbar"
                anchorEl={anchorElUser}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
            >
                {isLoggedIn && (
                    <div>
                        <MenuItem style={{ pointerEvents: 'none' }}>
                            <Avatar sx={{ width: 24, height: 24, mr: 0.5, color: 'primary.contrastText' }}/>
                            {authUser.username}
                        </MenuItem>
                        <Divider />
                        <EnsureUser
                            permissions={["Org:Modify"]}
                            showPermissionError={false}
                        >
                            <MenuItem>
                                <Box
                                    sx={{ flexGrow: 0, width: "230px", padding: "15px 10px 5px 0" }}
                                    className={"input-slim__select"}
                                >
                                    <OrgSelect
                                        color={theme.props?.MuiAppBarMods?.inputColor || "secondary"}
                                        onChange={(_e, newValue) => {
                                            redirectDomain(newValue.subDomain);
                                        }}
                                    />
                                </Box>
                            </MenuItem>
                            <Divider />
                        </EnsureUser>
                        {signedInSettings.map((setting, index) => (
                            <>
                            { setting.isExternal ? (
                                <MenuItem
                                    key={"signed-in-" + setting.title + index}
                                    onClick={handleCloseUserMenu}
                                    component={setting.component}
                                    style={{ display: 'flex', width: '100%', justifyContent: 'left', textTransform: 'none' }}
                                    href={setting.url}
                                    target={'_blank'}
                                >
                                    <Typography textAlign="center">{setting.title}</Typography>
                                </MenuItem>
                            ) : (
                                <MenuItem
                                    key={"signed-in-" + setting.title + index}
                                    onClick={handleCloseUserMenu}
                                    component={setting.component}
                                    style={{ display: 'flex', width: '100%', justifyContent: 'left', textTransform: 'none' }}
                                    to={setting.url}>
                                    <Typography textAlign="center">{setting.title}</Typography>
                                </MenuItem>
                            )}
                            </>
                        ))}
                    </div>
                    )
                }
                {!isLoggedIn &&
                    signedOutSettings.map((setting, index) => (
                        <MenuItem
                            key={"signed-out-" + setting.title + index}
                            onClick={handleCloseUserMenu}
                            component={setting.component}
                            style={{ display: 'flex', width: '100%', justifyContent: 'left'}}
                            to={setting.url}>
                            <Typography textAlign="center">{setting.title}</Typography>
                        </MenuItem>
                    ))
                }
                <MenuItem
                    onClick={handleOpenAboutMenu}
                    style={{ display: 'flex', width: '100%', justifyContent: 'left', textTransform: 'none' }}
                    target={'_blank'}
                >
                    <Typography textAlign="center">{t('About')}</Typography>
                </MenuItem>
            </Menu>

            <Dialog
                fullScreen={isMobile}
                fullWidth={true}
                maxWidth={"sm"}
                open={Boolean(anchorElAbout)}
                onClose={handleCloseAboutMenu}
                PaperComponent={PaperComponent}
                aria-labelledby="draggable-dialog-title"
            >
                <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
                    <InfoIcon sx={{mr: 1, color: 'primary.main'}}/> {t('About')}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        <Typography>{t('Version: ')} {version}</Typography>
                        <Typography>{t('Version Date: ')} {gitDate} ({gitHash})</Typography>
                        <Typography>{t('Database Version: ')} {dbVersion}</Typography>
                        <Typography>{t('JS Hash: ')} {jsHash}</Typography>
                        <Typography>{t('CSS Hash: ')} {cssHash}</Typography>
                        { jsHash !== jsHashTarget &&
                        <>
                            <Typography><Alert severity="warning">{t('JS Hash Version Mismatch')}</Alert></Typography>
                            <Typography>{t('JS Hash Target: ')} {jsHashTarget}</Typography>
                            <Typography>{t('CSS Hash Target: ')} {cssHashTarget}</Typography>
                        </>
                        }
                        <EnsureUser
                            permissions={["Org:Modify"]}
                            showPermissionError={false}
                        >
                            <div style={{height: "20px"}}></div>
                            <Alert severity="warning">{t("Super Admin Debug Data")}</Alert>
                            <Typography>{t('Token Expiration ')} {dateFormatter(window.expiresAt)}</Typography>
                            <Typography>{t('Environment: ')} {appInfo.appInfo?.environment}</Typography>
                        </EnsureUser>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={handleCloseAboutMenu} variant={theme.props?.CancelButton?.variant} color={theme.props?.CancelButton?.color}>
                        {t('Close')}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default UserMenu;