import { getRatesDefaultItem, ratesValidationSchema } from './RateAPIEndpoints';
import PropTypes from 'prop-types';
import { Formik, Form, Field } from 'formik';
import { useRef, useEffect } from 'react';
import { TextField } from 'formik-mui';
import { UcEnumSelect } from 'components/common/UcEnumSelect';
import { RateAdminSelect } from './RateAdminSelect';
import { FormControlLabel, Switch, Grid } from '@mui/material';
import { UcDatePickerFormik } from 'components/common/UcDatePicker';
import { DateTime } from 'luxon';

export const RateForm = (props) => {
    const itemToEdit = props.itemToEdit || getRatesDefaultItem();
    const dialogSubmit = useRef(props.dialogSubmit);
    const formRef = useRef();

    if (props.rowCount >= 1) {
        itemToEdit.duplicateRateRules = true;
    }
    else {
        itemToEdit.duplicateRateRules = false;
    }

    if (itemToEdit.id > 0 && itemToEdit.rateType === 'TIERED') {
        itemToEdit['initialRate'] = 0;
    }

    const handleChangeStartDate = (value) => {
        if (formRef.current !== undefined) {
            formRef.current.values.startDate = DateTime.fromJSDate(new Date(value)).toUTC().toISO();
        }
    }

    useEffect(() => {
        if (props.dialogSubmit !== dialogSubmit.current) {
            dialogSubmit.current = props.dialogSubmit;
            if (formRef.current) {
                formRef.current.handleSubmit();
            }
        }
    });

    return (
        <div className='ignoreCustomFormStyles'>
            <Formik
                innerRef={formRef}
                initialValues={itemToEdit}
                validationSchema={ratesValidationSchema}
                onSubmit={(values) => {
                    props.onSubmit(values);
                }}
            >
                <Form readOnly={props.readOnly}>
                    <Grid container spacing={2} style={{paddingTop: '8px'}}>
                        <Grid item xs={12} sm={12} md={12}>
                            <Field fullWidth
                                name={"name"}
                                component={TextField}
                                disabled={props.disabled}
                                label={"Name"}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={6}>
                            <Field name={"rateType"} fullWidth> 
                                {({ field: { value }, form: { setFieldValue } }) => (
                                    <UcEnumSelect
                                        value={value}
                                        enumName="RateType"
                                        label={"Type"}
                                        disabled={true}
                                        onChange={(e, rateValue) => setFieldValue("rateType", rateValue.id)} />
                                )}
                            </Field>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6}>
                            <Field fullWidth
                                name={"startDate"}
                                dateOnly={true}
                                component={UcDatePickerFormik}
                                disabled={props.disabled}
                                label={"Start Date"}
                                sendValueToForm={handleChangeStartDate}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={12}>
                            <Field name={"rateType"}>
                                {({ field: { value }}) => (
                                    <>
                                    {props.itemToEdit.id <= 0 && value !== 'FLATRATE' && // New item.  Allow selection of previous rates to duplicate rules
                                        <Field name={"duplicateRateRules"}>
                                            {({ field: { value: fieldValue }, form: { setFieldValue } }) => (
                                            <Grid container spacing={1}>
                                                <Grid item xs={12} sm={12} md={12}>
                                                    <FormControlLabel 
                                                        label="Duplicate Rate Rules from previous rate" 
                                                        control={
                                                            <Switch
                                                                checked={fieldValue}
                                                                onChange={(e, changeValue) => {
                                                                    setFieldValue("duplicateRateRules", changeValue);
                                                                }}
                                                            />
                                                        } 
                                                    />
                                                </Grid>
                                                {fieldValue &&
                                                    <Grid item xs={12} sm={12} md={12}>
                                                        <Field name={"duplicateRateId"}>
                                                            {({ field: { rateValue }, form: { setFieldValue } }) => (
                                                                <RateAdminSelect
                                                                    rateClassId={props.itemToEdit.rateClassId}
                                                                    rateType={props.itemToEdit.rateType}
                                                                    onChange={(e, rateChangeValue) => setFieldValue("duplicateRateId", rateChangeValue.id)}
                                                                />
                                                            )}
                                                        </Field>
                                                    </Grid>
                                                }
                                            </Grid>
                                        )}
                                        </Field>
                                    }
                                    {value === 'FLATRATE' && // New item.  Allow selection of previous rates to duplicate rules
                                        <Field
                                            name={"flatRate"}
                                            component={TextField}
                                            disabled={props.disabled}
                                            label={"Flat Rate"}
                                        />
                                        }
                                    {value === 'TIERED' && itemToEdit.id <= 0 &&
                                        <Grid item xs={12} sm={12} md={12} style={{ paddingTop: '13px' }}>
                                            <Field
                                                name={"initialRate"}
                                                component={TextField}
                                                disabled={props.disabled}
                                                label={"Initial Rate ($)"}
                                            />
                                        </Grid>
                                    }
                                    </>
                                )}
                            </Field>
                        </Grid>
                    </Grid>
                </Form>
            </Formik>
        </div>
    );
}

RateForm.defaultProps = {
    id: 0,
    dialogSubmit: 0,
    changeIteration: 0,
    onSubmit: (values) => { console.log("onSubmit not implemented", values); },
    onError: (error) => { console.error("onError not implemented", error); },
};

RateForm.propTypes = {
    id: PropTypes.number,
    changeIteration: PropTypes.number,
    dialogSubmit: PropTypes.number,
};