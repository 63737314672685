import React, { forwardRef } from "react";
import { Switch, Typography, Stack } from '@mui/material';
import { setLanguage, useLanguage } from "components/user/useLanguage";
import { useTranslation } from "react-i18next";

export const UcLanguageToggleSwitch = forwardRef((props, ref) => {
    const lng = useLanguage();
    const { t, i18n } = useTranslation();

    const languageToggle = (event) => {
        const newLng = event.target.checked ? 'FR' : 'EN';

        // Saves to db
        setLanguage(newLng);

        // Sets the translation file
        i18n.changeLanguage(newLng);
    };

    return (
        <Stack direction="row" spacing={0} alignItems="center">
            <Typography>{t("English")}</Typography>
            <Switch onChange={languageToggle} checked={lng !== 'EN'} ref={ref} />
            <Typography>{t("French")}</Typography>
        </Stack>
    );
});