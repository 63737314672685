import React, { useState } from 'react';
import LoadingButton from '@mui/lab/LoadingButton';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Alert from '@mui/material/Alert';
import Paper from '@mui/material/Paper';
import Draggable from 'react-draggable';
import PropTypes from 'prop-types';
import { RateVersionForm } from './RateVersionForm';
import { saveRateVersion, deleteRateVersion, getRateVersionDefaultItem } from './RateVersionApiEndpoints';
import { useResponsive } from 'lib/useResponsive';
import { useTranslation } from "react-i18next";
import { useTheme } from '@mui/material/styles';
import { dateFormatter } from "lib/Formatters";

function PaperComponent(props) {
    return (
        <Draggable
            handle="#draggable-dialog-title"
            cancel={'[class*="MuiDialogContent-root"]'}
        >
            <Paper {...props} />
        </Draggable>
    );
}

export default function RateVersionFormDialog(props) {
    const { t } = useTranslation();
    const [saving, setSaving] = React.useState(false);
    const [versionOverlap, setVersionOverlap] = React.useState(0);
    const [openDelete, setOpenDelete] = React.useState(false);
    const [dialogSubmit, setDialogSubmit] = useState(0);
    const { isMobile } = useResponsive();
    const theme = useTheme();
    const rateVersions = props.rateVersions;

    let formId = props.id;
    if (!props.open) {
        formId = 0;
    }

    const handleCancel = () => {
        props.onCancel();
    };

    const handleCancelDelete = () => {
        setOpenDelete(false);
    };

    const handleSave = () => {
        setDialogSubmit(dialogSubmit + 1);
    };

    const handleClickDelete = () => {
        setOpenDelete(true);
    }   

    const handleDelete = async () => {
        setOpenDelete(false);
        let data = await deleteRateVersion(props.itemToEdit);

        if (data) {
            props.onSave(data);
        }
    };

    return (
        <>
            {openDelete &&
                <Dialog
                    fullScreen={isMobile}
                    fullWidth={true}
                    maxWidth={"sm"}
                    open={props.open}
                    PaperComponent={PaperComponent}
                    aria-labelledby="draggable-dialog-title"
                >
                    <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
                        {t('Delete Version')}
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            {t('Are you sure you want to delete this Version?')}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button autoFocus onClick={handleCancelDelete} variant={theme.props?.CancelButton?.variant}>
                            {t("Cancel")}
                        </Button>
                        <Button onClick={handleDelete} variant={theme.props?.CancelButton?.variant} color={theme.props?.CancelButton?.color}>
                            {t("Delete")}
                        </Button>
                    </DialogActions>
                </Dialog>
            }
            {props.open &&
                <Dialog
                    fullScreen={isMobile}
                    fullWidth={true}
                    maxWidth={"md"}
                    open={props.open}
                    PaperComponent={PaperComponent}
                    aria-labelledby="draggable-dialog-title"
                >
                    <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
                        {t('Version')}
                    </DialogTitle>
                    <DialogContent>
                        <RateVersionForm
                            id={formId}
                            itemToEdit={props.itemToEdit}
                            dialogSubmit={dialogSubmit}
                            setVersionOverlap={setVersionOverlap}
                            rateVersions={rateVersions}
                            onSubmit={async (values) => {
                                setSaving(true);
                                try {
                                    let data = await saveRateVersion(values);

                                    if (data) {
                                        props.onSave(data);
                                    }
                                } finally {
                                    setSaving(false);
                                }
                            }}
                            onError={(error) => {
                                props.onError(error);

                                setSaving(false);
                            }}
                        />
                    </DialogContent>
                    {versionOverlap > 0 && (
                        <Alert severity="warning">{t("Rate version cannot overlap with existing version(s).")} <br />
                            {rateVersions?.map(item => (
                                props.itemToEdit.id !== item.id && (
                                    <li key={item.id}>
                                        {dateFormatter(new Date(item.startDate), true)} to {dateFormatter(new Date(item.endDate), true)}
                                    </li>
                                )
                            ))}   
                        </Alert>
                    )}                  
                    
                    <DialogActions>
                        {props.itemToEdit.id > 0 && (
                            <Button  variant='contained' color='error' onClick={handleClickDelete}>
                                {t('Delete')}
                            </Button>
                        )}
                        <div style={{flex: '1 0 0'}} />
                        <Button autoFocus onClick={handleCancel} variant={theme.props?.CancelButton?.variant || "outlined"} color={theme.props?.CancelButton?.color}>
                            {t('Cancel')}
                        </Button>
                        <LoadingButton
                            loading={saving}
                            onClick={handleSave}
                            variant="contained"
                            disabled={versionOverlap > 0}
                        >
                            {t('Save')}
                        </LoadingButton>
                    </DialogActions>
                </Dialog>
            }
        </>
    );
}

RateVersionFormDialog.defaultProps = {
    open: false,
    id: 0,
    itemToEdit: getRateVersionDefaultItem(),
    onCancel: () => { console.log("onCancel not implemented"); },
    onSave: (data) => { console.log("onSave not implemented"); },
    onError: (error) => { console.error("onError not implemented", error); },
};

RateVersionFormDialog.propTypes = {
    open: PropTypes.bool,
    id: PropTypes.number,
    itemToEdit: PropTypes.object,
};