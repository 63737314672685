import React from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import './LayoutLanding.scss';
import { useTheme } from '@mui/material/styles';
import { UcThemeContext } from 'lib/UcThemeProvider';
import { useLocation } from 'react-router-dom';
import { routePaths } from 'routes';

export const LayoutAuthorization = (props) => {
    const theme = useTheme();
    const [themeState] = React.useContext(UcThemeContext);

    const location = useLocation();
    const currentRoutePath = location?.pathname;

    return (
        <div 
            className="layout-landing" 
            style={{paddingTop: "35px", background: theme.props?.LandingPage?.background, color: theme.props?.LandingPage?.color}}
        >
            <Container component="main" maxWidth={currentRoutePath === routePaths.accountAuthorizationPage.path ? "xs" : "md"}>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                    >
                    <img
                        src={theme.props?.LandingPage !== undefined ? theme.props?.LandingPage?.logo : `${process.env.PUBLIC_URL}/themes/${themeState.logo}`}
                        alt="logo"
                        className="responsiveSplash-img__logo"
                    />
                    {props.children}
                </Box>
            </Container>
        </div>
    );
}
