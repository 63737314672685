import { useTranslation } from "react-i18next";
import { Grid, Typography, Button, Alert, Divider } from "@mui/material";
import { UcLoading } from "../common/UcLoading";
import EnsureUserAndModule from "../auth/EnsureUserAndModule";
import {
    useQuickActions
} from "../admin/Dashboard/QuickActionsAPIEndpoints";

export const QuickActionsWidget = () => {
    const { t } = useTranslation();
    const swrQuickActions = useQuickActions();
    const filteredQuickActions = swrQuickActions?.result?.filter((item: any) => item.isDisplayed);

    return (
        <EnsureUserAndModule>
            <div className="dataCyId" data-cy-id="quickActions_widget" />
            <UcLoading
                hasFade={false}
                isLoading={swrQuickActions.isLoading}
                height={"20vh"}
                errorMessage={t(
                    "An error occurred retrieving quick actions."
                )}            
            >   
                {filteredQuickActions?.map((tile: any, index: number) => (
                    <>
                        <Grid container spacing={2}>
                            <Grid item xs>
                                <Typography variant="h6" title={tile.title} data-cy-id={"quick_actions_widget_title_" + tile.id}>
                                    {tile.title}
                                </Typography>
                                <Typography variant="caption" title={tile.description} data-cy-id={"quick_actions_widget_description_" + tile.id}>
                                    {tile.description}
                                </Typography>
                            </Grid>
                            <Grid item sx={{ display: "flex" }}>
                                <Button
                                    sx={{
                                        my: "auto",
                                        textAlign: 'center',
                                        width: 130
                                    }}
                                    variant="contained"
                                    fullWidth
                                    size="small"
                                    href={tile.buttonLink}
                                    target="_blank"
                                    title={tile.buttonName}
                                    data-cy-id={"quick_actions_widget_buttonName_" + tile.id}
                                >
                                    {tile.buttonName}
                                </Button>
                            </Grid>
                        </Grid>
                        {index !== filteredQuickActions?.length-1 && (
                            <Divider
                                sx={{
                                    my: 2,
                                    borderBottomWidth: 2,
                                }}
                            />   
                        )}                      
                    </>                   
                ))}
                {filteredQuickActions?.length < 1 && (
                    <Alert severity="warning">{t("No quick actions have currently been set up! Stay tuned for your utility to create events.")}</Alert>
                )}
            </UcLoading>
        </EnsureUserAndModule>
    );
};
