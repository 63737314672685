import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from "react-i18next";
import { Grid } from '@mui/material';
import { Formik, Form, Field } from 'formik';
import { TextField } from 'formik-mui';
import { UcColorCirclePicker } from 'components/common/UcColorCirclePicker';
import { getRateBasicRulesDefaultItem, rateBasicRulesValidationSchema } from './RateBasicRuleAPIEndpoints';

export default function RateBasicRuleForm(props) {
    const { t } = useTranslation();
    const itemToEdit = props.itemToEdit || getRateBasicRulesDefaultItem();
    const existingItems = props.existingItems || [];
    const dialogSubmit = React.useRef(props.dialogSubmit);
    const formRef = React.useRef();

    React.useEffect(() => {
        if (props.dialogSubmit !== dialogSubmit.current) {
            dialogSubmit.current = props.dialogSubmit;
            if (formRef.current) {
                formRef.current.handleSubmit();
            }
        }
    }, [props.dialogSubmit, itemToEdit.startingUnit, itemToEdit.id]);
    
    return (
        <div className='ignoreCustomFormStyles'>
            <Formik
                innerRef={formRef}
                initialValues={itemToEdit}
                validationSchema={() => rateBasicRulesValidationSchema(t)}  
                onSubmit={(values) => {
                    props.onSubmit(values);
                }}
            > 
                <Form readOnly={props.readonly}>
                    <Grid container spacing={2} style={{paddingTop: '8px'}}>
                        <Grid item xs={12} sm={12} md={12}>
                            <Field
                                name={"name"}
                                component={TextField}
                                disabled={props.disabled}
                                label={t("Name")}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={12}>
                            <Field
                                name={"description"}
                                component={TextField}
                                disabled={props.disabled}
                                label={t("Description")}                   
                                multiline
                                rows={4}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={6}>
                            <Field
                                name={"rate"}
                                type="number"
                                component={TextField}
                                disabled={props.disabled}
                                label={t("Rate ($)")}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={12}>
                            <Field
                                name={"htmlColor"}
                                existingItems={existingItems}
                                itemToEdit={itemToEdit}
                                value={formRef.current?.values?.htmlColor || itemToEdit.htmlColor}
                                component={UcColorCirclePicker}
                                onChange={(newValue) => {
                                    formRef.current.setFieldValue("htmlColor", newValue);
                                }}
                                label={t("Color")}
                                hint={t('This color will be associated with the rule when rendered on a usage graph.')}
                            />
                        </Grid>
                    </Grid>
                </Form>
            </Formik>
        </div>
    );
}

RateBasicRuleForm.defaultProps = {
    id: 0,
    dialogSubmit: 0,
    changeIteration: 0,
    onSubmit: (values) => { console.log("onSubmit not implemented", values); },
    onError: (error) => { console.error("onError not implemented", error); },
};

RateBasicRuleForm.propTypes = {
    id: PropTypes.number,
    changeIteration: PropTypes.number,
    dialogSubmit: PropTypes.number,
};