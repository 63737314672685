import { UserListPage } from "./UserListPage";
import {useEffect, useContext} from 'react';
import {PageContext} from '../../../lib/PageProvider';
import {useTranslation} from 'react-i18next';

export const AdminUserListPage = () => {
    const [pageState, pageDispatch] = useContext(PageContext);
    const { t } = useTranslation();

    useEffect(() => {
        pageDispatch({
            header: t("Admin Users"),
        });
    }, [pageState.header, pageDispatch, t]);

    return (
        /*user permission is to check for regular user permissions, and RateClass permission is used 
        to make sure the current user is at least a utility admin*/
        <UserListPage type={'UtilityAdmin'} permissions={["User:Read", "RateClass:Modify"]}/>
    );
};