import React, { useContext, useEffect } from 'react';
import { UcAdminDetails } from "../../common/UcAdminDetails";
import { rateClassName } from './RateClassAPIEndpoints';
import { PageContext } from '../../../lib/PageProvider';
import { useParams } from "react-router-dom";
import { EnsureUser } from '../../auth/EnsureUser';
import { useHasPermission } from 'components/auth/PermissionApiEndpoint';
import { MeterRateList } from 'components/admin/Customers/MeterRateList';
import { useTranslation } from 'react-i18next';

export const RateClassDetailsPage = (props) => {
    let { rateClassId } = useParams();
    rateClassId = rateClassId || props.rateClassId;

    const params = new URLSearchParams(window.location.search);
    const rateClassParamName = params.get("name");

    const [pageState, pageDispatch] = useContext(PageContext);
    const { t } = useTranslation();

    useEffect(() => {
        pageDispatch({
            header: rateClassName + " Details",
            variableHeader: rateClassParamName,
            actionItems: []
        });
    }, [pageState.header, pageDispatch, rateClassParamName]);

    let detailsRender = <></>;
    let tabs = [
        {
            title: t("Meter Rates"),
            contentRender: <MeterRateList rateClassId={rateClassId} isFromRateClassPath={true} />,
            isHidden: !useHasPermission("MeterRate:Read")
        },
    ];

    return (
        <EnsureUser permissions={["RateClass:Read"]}>
            <UcAdminDetails detailsRender={detailsRender} tabs={tabs} tabInitValue={"Meter Rates"} />
        </EnsureUser>
    );
};