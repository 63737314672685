import { useTranslation } from "react-i18next";
import {
    Switch,
    FormControlLabel,
    Typography,
    Grid,
    Box,
    Card,
    CardContent,
    Snackbar,
    Alert
} from "@mui/material";
// @ts-ignore
import { UcThemeToggleSwitch } from "../common/UcThemeToggleSwitch";
// @ts-ignore
import { UcLanguageToggleSwitch } from "../common/UcLanguageToggleSwitch";
// @ts-ignore
import { UcEnablePushNotificationsSwitch } from "../common/UcEnablePushNotificationsSwitch";
// @ts-ignore
import { useUserPref } from "components/user/useUserPref";
// @ts-ignore
import { useMyOrgs } from 'components/customer/orgs/MyOrgAPIEndpoints';
import { useState } from "react";
import { MyNotificationPreferences } from "./MyNotificationPreferences";
import { MyNicknamePreferences } from "./MyNicknamePreferences";

export const MyPreferences = (props: any) => {
    const { t } = useTranslation();
    const swrMyOrg = useMyOrgs();
    const showCost = useUserPref("showCost", false);
    const showTemp = useUserPref("showTemp", false);
    const showPrecip = useUserPref("showPrecip", false);
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [openErrorSnackbar, setOpenErrorSnackbar] = useState(false);
    let orgTempUnit: string | null = null;

    if (!swrMyOrg.isLoading && swrMyOrg.result?.length > 0) {
        orgTempUnit = swrMyOrg.result[0].temperatureUnit;
    }

    const handleChangeShowCost = (event: any) => {
        showCost.setUserPref("showCost", !showCost.valueBool);
        if (showPrecip.valueBool) {
            showPrecip.setUserPref("showPrecip", false);
        }
        setOpenSnackbar(true);
    };

    const handleChangeShowPrecip = (event: any) => {
        showPrecip.setUserPref("showPrecip", !showPrecip.valueBool);
        if (showCost.valueBool) {
            showCost.setUserPref("showCost", false);
        }
        setOpenSnackbar(true);
    };

    const handleChangeShowTemp = (event: any) => {
        showTemp.setUserPref("showTemp", !showTemp.valueBool);
        setOpenSnackbar(true);
    };

    const handleCloseSnackbar = (reason: any) => {
        if (reason === "clickaway") {
            return;
        }

        setOpenSnackbar(false);
    };

    const handleCloseErrorSnackbar = (reason: any) => {
        if (reason === "clickaway") {
            return;
        }

        setOpenErrorSnackbar(false);
    };

    return (
        <Box>
            <Grid item xs={12} sm={12} md={6}>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={12}>
                        <Card variant="outlined">
                            <CardContent>
                                <Typography
                                    color="text.secondary"
                                    gutterBottom
                                    sx={{ mb: 2 }}
                                >
                                    {t("Site & Appearance")}
                                </Typography>
                                {props.showDarkModeToggle && (
                                    <>
                                        <Typography
                                            sx={{ fontSize: 14 }}
                                            color="text.secondary"
                                        >
                                            {t("Theme")}
                                        </Typography>
                                        <UcThemeToggleSwitch />
                                    </>
                                )}
                                <Typography
                                    sx={{ fontSize: 14, mt: 2 }}
                                    color="text.secondary"
                                >
                                    {t("Charts & Graphs")}
                                </Typography>
                                <FormControlLabel
                                    label={
                                        <Typography>
                                            {t("Show Cost")}
                                        </Typography>
                                    }
                                    control={
                                        <Switch
                                            checked={showCost.valueBool}
                                            onChange={handleChangeShowCost}
                                            inputProps={{
                                                "aria-label": "controlled",
                                            }}
                                        />
                                    }
                                />
                                <FormControlLabel
                                    label={
                                        <Typography>
                                            {t("Show Precipitation")}
                                        </Typography>
                                    }
                                    control={
                                        <Switch
                                            checked={showPrecip.valueBool}
                                            onChange={handleChangeShowPrecip}
                                            inputProps={{
                                                "aria-label": "controlled",
                                            }}
                                        />
                                    }
                                />
                                {props.showTemperatureToggle && (
                                    <FormControlLabel
                                        label={
                                            <Typography>
                                                {t(`Show Temperature (${String.fromCharCode(176) + orgTempUnit})`)}
                                            </Typography>
                                        }
                                        control={
                                            <Switch
                                                checked={showTemp.valueBool}
                                                onChange={handleChangeShowTemp}
                                                inputProps={{
                                                    "aria-label": "controlled",
                                                }}
                                            />
                                        }
                                    />
                                )}
                                {props.showLanguageToggle && (
                                    <>
                                        <Typography
                                            sx={{ fontSize: 14, mt: 2 }}
                                            color="text.secondary"
                                            gutterBottom
                                        >
                                            {t("Language")}
                                        </Typography>
                                        <UcLanguageToggleSwitch />
                                    </>
                                )}
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={12}>
                        <MyNotificationPreferences />
                    </Grid>
                    <Grid item xs={12}>
                        <MyNicknamePreferences />
                    </Grid>
                    {props.showDebug && (
                        <Grid item xs={12} sm={12} md={12}>
                            <Card variant="outlined">
                                <CardContent>
                                    <Typography
                                        sx={{ mb: 2 }}
                                        color="text.secondary"
                                        gutterBottom
                                    >
                                        {t("Debug")}
                                    </Typography>
                                    <Typography gutterBottom
                                        sx={{ fontSize: 14 }}
                                        color="text.secondary"
                                    >
                                        {t("Push Notifications & One Signal Info")}
                                    </Typography>
                                    <UcEnablePushNotificationsSwitch />
                                </CardContent>
                            </Card>
                        </Grid>
                    )}
                </Grid>
            </Grid>
            <Snackbar
                open={openSnackbar}
                autoHideDuration={3000}
                onClose={handleCloseSnackbar}
            >
                <Alert
                    onClose={handleCloseSnackbar}
                    severity="success"
                    variant="filled"
                    sx={{ width: "100%", color: '#fff' }}
                >
                    {t("Preference updated!")}
                </Alert>
            </Snackbar>
            <Snackbar
                open={openErrorSnackbar}
                autoHideDuration={3000}
                onClose={handleCloseErrorSnackbar}
            >
                <Alert
                    onClose={handleCloseErrorSnackbar}
                    severity="error"
                    variant="filled"
                    sx={{ width: "100%", color: '#fff' }}
                >
                    {t("You must have at least one delivery method selected!")}
                </Alert>
            </Snackbar>
        </Box>
    );
};