import * as React from "react";
// @ts-ignore
import Typography from "@mui/material/Typography";
// @ts-ignore
import { useMyOrgs } from "components/customer/orgs/MyOrgAPIEndpoints";
// @ts-ignore
import { OrgAppSettingsContext } from "../../lib/OrgAppSettingsProvider";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import { useMyOrgFaqSettings } from "components/user/MyOrgFaqSettingAPIEndpoints";
import Alert from '@mui/material/Alert';
import { UserUtilitiesContext } from "lib/UserUtilitiesProvider";

export interface UcFooterProps {
    align: "right" | "left" | "center" | "inherit" | "justify" | undefined;
    disablePadding: boolean;
}

export const UcFooter = (props: UcFooterProps) => {
    const params = new URLSearchParams(window.location.search);
    const subdomain = params.get("subdomain");
    const queryParams = subdomain ? `?subdomain=${subdomain}` : "";
    const swrMyOrgFaqSettings = useMyOrgFaqSettings();
    const swrMyOrg = useMyOrgs();
    const { orgAppSettings } = React.useContext(OrgAppSettingsContext);
    const { clockSettings } = React.useContext(UserUtilitiesContext);
    
    // TODO: Out-of-scope changes required to properly type the context return value
    // @ts-ignore
    const browserClockOffset = clockSettings?.result?.clockOffset;
    // @ts-ignore
    const currentClockSkewWarn = clockSettings?.result?.currentClockSkewWarn;

    let currentOrg = null;
    if (!swrMyOrg.isLoading && swrMyOrg.result?.length > 0) {
        currentOrg = { ...swrMyOrg.result[0] };
    }

    // @ts-ignore
    const orgAppSettingFooter = orgAppSettings?.result?.footerInformation;
    // @ts-ignore
    const orgPhoneNumber = orgAppSettings.result?.phoneNumber;
    // @ts-ignore
    const orgEmail = orgAppSettings.result?.email;

    let orgAppSettingDefaultFooter = orgPhoneNumber + " | " + orgEmail;

    if (!orgEmail && !orgPhoneNumber) {
        orgAppSettingDefaultFooter = "";
    } else if (!orgEmail) {
        orgAppSettingDefaultFooter = orgPhoneNumber;
    } else if (!orgPhoneNumber) {
        orgAppSettingDefaultFooter = orgEmail;
    }

    const footer = currentOrg?.footerInformation ? currentOrg?.footerInformation : orgAppSettingFooter;
    
    return (
        <>
            {browserClockOffset >= currentClockSkewWarn && (
                <Alert severity="warning" sx={{ width: "100%", mt: 1 }}> Warning - Your current system time is incorrrect by {browserClockOffset} minutes. Please consider setting an accurate system time or you may not be able to sign-in.</Alert>
            )}
            <Typography
                variant="body2"
                color="text.secondary"
                sx={{ pt: props.disablePadding ? 0 : 1 }}
                align={props.align || "center"}
                data-cy-id="myorg_footerinfo"
            >
                <ReactMarkdown linkTarget="_blank" remarkPlugins={[remarkGfm]}>
                    {
                       `${(footer && footer !== "") ? footer : orgAppSettingDefaultFooter } 
                        ${(!swrMyOrgFaqSettings.isLoading && swrMyOrgFaqSettings.result?.length > 0) ? `| [FAQ](/faq${queryParams})` : ""}`
                    }
                </ReactMarkdown>
            </Typography>
        </>      
    );
};
