import PropTypes from 'prop-types';
import { useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Formik, Form } from 'formik';
import Typography from '@mui/material/Typography';
import EnsureUserAndModule from '../../auth/EnsureUserAndModule';
import { OutageValidationSchema } from './OutageAPIEndpoints';

interface OutageFormProps {
    itemToEdit: any;
    disabled: boolean;
    restore: boolean;
    cancel: boolean;
    dialogSubmit: number;
    onSubmit: Function;
}

export const OutageForm = (props: OutageFormProps) => {
    const { t } = useTranslation();
    const { itemToEdit} = props;
    const dialogSubmit = useRef<number>(props.dialogSubmit);
    const formRef = useRef<any>();

    useEffect(() => {
        if (props.dialogSubmit !== dialogSubmit.current) {
            dialogSubmit.current = props.dialogSubmit;
            if (formRef.current) {
                formRef.current.handleSubmit();
            }
        }
    });

    return (
        <EnsureUserAndModule modules={['OutageManual']} >
            <div className='ignoreCustomFormStyles'>
                <Typography>
                    {props.restore && 
                        t("Are you sure you want to restore this outage and send an outage alert restore email to this selected account?")
                    }
                    {props.cancel && 
                        t("Are you sure you want to cancel this outage and send an outage alert cancel email to this selected account?")
                    }
                    <p />
                    {t("Outage Type: ") + itemToEdit?.type} <br />
                    {t("Account address: ") + itemToEdit?.accountAddress} <br />
                    {t("Account name: ") + itemToEdit?.accountName} <br />
                    {t("Meter number: ") + itemToEdit?.serialNo} <br />
                    {t("Account number: ") + itemToEdit?.extAccountNumber} <br />
                    {t("UOM: ") + itemToEdit?.uom} <p />
                </Typography>

                <Formik
                    innerRef={formRef}
                    initialValues={itemToEdit}
                    validationSchema={OutageValidationSchema}
                    onSubmit={(values) => {
                        if(props.restore){
                            values.status = "Restored";
                            values.restoredTS = new Date();
                        }
                        else if(props.cancel){
                            values.status = "Cancelled";
                            values.cancelledTS = new Date();
                        }
                        else{
                            values.status = "Active";
                            values.activeTS = new Date();
                        }
                        props.onSubmit(values);
                    }}
                >
                    <Form>
                    </Form>
                </Formik>

            </div>
        </EnsureUserAndModule>
    );
}

OutageForm.defaultProps = {
    id: 0,
    dialogSubmit: 0,
    changeIteration: 0,
    onSubmit: (values: any) => { console.log("onSubmit not implemented", values); },
    onError: (error: any) => { console.error("onError not implemented", error); },
};

OutageForm.propTypes = {
    id: PropTypes.number,
    changeIteration: PropTypes.number,
    dialogSubmit: PropTypes.number,
};