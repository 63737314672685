import i18n from "i18next";
import detector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
import Backend from 'i18next-http-backend';

i18n
    .use(detector)
    .use(Backend)
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
        debug: false,
        interpolation: {escapeValue: false},
        fallbackLng : "en",
        initImmediate: false, // set initImmediate false -> init method finished only when all resources/translation finish loading (async behaviour)
        backend: {
            loadPath: `/api/v1/Locales/translation/{{lng}}`,
            parse(data) {
               return JSON.parse(data);
            },
        }
    });

export default i18n;