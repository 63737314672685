import { getRateVersionDefaultItem, rateVersionValidationSchema } from './RateVersionApiEndpoints';
import { useMyTimezone } from 'components/customer/orgs/MyOrgAPIEndpoints';
import { convertToLocalTime } from "lib/DateRuleSetFormatter";
import { getDateFromMui } from "lib/Formatters"
import PropTypes from 'prop-types';
import { Formik, Form, Field } from 'formik';
import React, { useRef, useEffect } from 'react';
import { TextField } from 'formik-mui';
import { Grid } from '@mui/material';
import { UcDatePickerFormik } from 'components/common/UcDatePicker';
import { useTranslation } from "react-i18next";
import { DateTime } from 'luxon';

export const RateVersionForm = (props) => {
    const { t } = useTranslation();
    const timezone = useMyTimezone();
    const itemToEdit = props.itemToEdit || getRateVersionDefaultItem();
    const dialogSubmit = useRef(props.dialogSubmit);
    const formRef = useRef();
    const [startDate, setStartDate] = React.useState(itemToEdit.startDate || DateTime.now().toISO());
    const [endDate, setEndDate] = React.useState(itemToEdit.endDate || DateTime.now().toISO());
    const rateVersions = props.rateVersions;
    props.setVersionOverlap(0);

    if (rateVersions?.length > 0) {
        rateVersions.forEach((item) => {
            if (itemToEdit.id !== item.id) {
                if (new Date(startDate) <= new Date(item.endDate) && new Date(item.startDate) <= new Date(endDate)) {
                    props.setVersionOverlap((versionOverlap) => versionOverlap + 1);
                }
            }
        });
    }

    const handleChangeStartDate = (value) => {
        if (formRef.current !== undefined) {
            formRef.current.setFieldValue('startDate', convertToLocalTime(getDateFromMui(value), timezone));
            setStartDate(DateTime.fromJSDate(new Date(value)).toUTC().toISO());
        }
    }

    const handleChangeEndDate = (value) => {
        if (formRef.current !== undefined) {
            formRef.current.setFieldValue('endDate', convertToLocalTime(getDateFromMui(value), timezone));
            setEndDate(DateTime.fromJSDate(new Date(value)).toUTC().toISO());
        }
    }

    useEffect(() => {
        if (props.dialogSubmit !== dialogSubmit.current) {
            dialogSubmit.current = props.dialogSubmit;
            if (formRef.current) {
                formRef.current.handleSubmit();
            }
        }
    });

    return (
        <div className='ignoreCustomFormStyles'>
            <Formik
                innerRef={formRef}
                initialValues={itemToEdit}
                validationSchema={rateVersionValidationSchema}
                onSubmit={(values) => {
                    props.onSubmit(values);
                }}
            >
                <Form readOnly={props.readOnly}>
                    <Grid container spacing={2} style={{paddingTop: '8px'}}>
                        <Grid item xs={12} sm={12} md={12}>
                            <Field fullWidth
                                name={"name"}
                                component={TextField}
                                disabled={props.disabled}
                                label={t("Name")}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={12}>
                            <Field fullWidth
                                name={"description"}
                                component={TextField}
                                multiline
                                rows={4}
                                disabled={props.disabled}
                                label={t("Description")}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={6}>
                            <Field fullWidth
                                name={"startDate"}
                                dateOnly={true}
                                component={UcDatePickerFormik}
                                disabled={props.disabled}
                                label={t("Start Date")}
                                sendValueToForm={handleChangeStartDate}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={6}>
                            <Field fullWidth
                                name={"endDate"}
                                dateOnly={true}
                                component={UcDatePickerFormik}
                                disabled={props.disabled}
                                label={t("End Date")}
                                sendValueToForm={handleChangeEndDate}
                            />
                        </Grid>
                    </Grid>
                </Form>
            </Formik>
        </div>
    );
}

RateVersionForm.defaultProps = {
    id: 0,
    dialogSubmit: 0,
    changeIteration: 0,
    onSubmit: (values) => { console.log("onSubmit not implemented", values); },
    onError: (error) => { console.error("onError not implemented", error); },
};

RateVersionForm.propTypes = {
    id: PropTypes.number,
    changeIteration: PropTypes.number,
    dialogSubmit: PropTypes.number,
};