import { useLayoutEffect, useState} from "react";
import { useSearchParams } from "react-router-dom";

export const useStatefulParam = (props) => {
    const { paramName, paramValue } = props;
    const [searchParams, setSearchParams] = useSearchParams();
    const [isRenderReady, setIsRenderReady] = useState(true);
    
    const getValueQuery = () => {
        let defaultValue = paramValue || 0;
        let valueStr = searchParams.get(paramName);

        if (!valueStr) {
            return defaultValue;
        }

        return valueStr;
    };

    let valueQueryTab = getValueQuery();
    const [value, setValue] = useState(valueQueryTab);

    const handleSetValue = (newValue) => { // need event param?
        searchParams.set(paramName, newValue);
        setSearchParams(searchParams);
    };

    // this will get called on any render (and back / forward)
    useLayoutEffect(() => {
        if (valueQueryTab !== value && isRenderReady)
        {
            setIsRenderReady(false);
        }

        if (!isRenderReady)
        {
            setIsRenderReady(true);
            setValue(valueQueryTab);
        }
    }, [searchParams, valueQueryTab, value, setValue, isRenderReady, setIsRenderReady]);

    return {
        isRenderReady: isRenderReady,
        onSetValue: handleSetValue,
        value: value
    };
};