import * as React from 'react';
import { Box, Typography, Grid } from '@mui/material';
// @ts-ignore
import { useResponsive } from '../../lib/useResponsive';
import Divider from '@mui/material/Divider';
import { useTranslation } from 'react-i18next';
// @ts-ignore
import { useEnums } from 'components/auth/PermissionApiEndpoint';
// @ts-ignore
import { enumFormatter } from 'lib/EnumFormatter';
// @ts-ignore
import { Rate } from './Common.interfaces';

export interface UcRateChargeCardListProps {
    uom: string;
    rates: Rate[];
}

export const  UcRateChargeCardList = (props: UcRateChargeCardListProps) => {
    const { t } = useTranslation();
    const { isMobile, isTablet } = useResponsive();
    const { uom, rates: rows } = props;
    const rateChargeTypeEnums = useEnums("RateChargeType");

    return (
        <div>
            {(rows?.length > 0 ?
                <>
                    {rows?.map((rate: Rate, index: number) => (
                        <div key={`rate_${rate?.id}`}>
                            <Grid container
                                spacing={0}
                                direction={"row"}
                                width={"100%"}
                                columnSpacing={0}
                            >    
                                <Grid item xs={6.5}>
                                    <Typography>
                                        <b> 
                                            {enumFormatter(rateChargeTypeEnums, rate?.rateChargeType)} - {rate?.name}:
                                        </b>
                                    </Typography>
                                </Grid>
                                <Grid item xs={3.5} justifyContent="flex-start"> 
                                    <Box 
                                        sx={{
                                        width: (isMobile || isTablet) ? "70%" : "30%",
                                        minWidth: "170px",
                                    }}>
                                        <Typography>
                                            ${rate?.rate}
                                            {rate?.rateChargeType === "MONTHLY" ? " per month" : "/" + uom }
                                        </Typography>
                                    </Box>
                                </Grid>
                                <Grid item xs={2}>
                                    <Box sx={{
                                        pl: 1.5
                                        }}>
                                            <div
                                                style={{
                                                    display: "flex",
                                                    width: "20px",
                                                    height: "20px",
                                                    backgroundColor: `${rate?.htmlColor}`,
                                                    borderRadius: "50%"
                                                }}>
                                            </div>
                                    </Box>
                                </Grid>
                                <Grid item xs={6.5}>
                                    <Typography
                                    display="inline"
                                    sx={{
                                        fontWeight: 'bold',
                                    }}>
                                        {t("Cost: ")}
                                    </Typography>
                                </Grid>
                                <Grid item xs={4}>
                                    <Typography
                                    display="inline"
                                    sx={{
                                        mb: (isMobile || isTablet) && "10px"
                                    }}>
                                        {"$"+rate?.cost.toFixed(2)}
                                    </Typography>
                                </Grid>
                                <Grid item xs={6.5}>
                                    <Typography
                                    display="inline"
                                    sx={{
                                        fontWeight: 'bold',
                                    }}>
                                        {t("Consumption: ")}
                                    </Typography>
                                </Grid>
                                <Grid item xs={4}>
                                    <Typography
                                    display="inline"
                                    >
                                        {rate?.consumption+" "+ uom}
                                    </Typography>
                                </Grid>
                            </Grid>
                            {(index !== (rows?.length - 1)) && (
                                <Divider sx={{mt: 1, mb: 1}}/>
                            )}                          
                        </div>
                    ))}                   
                </>
                :
                <>
                    <Typography>
                        {t('No rates are currently available.')}
                    </Typography>
                </>
            )}
        </div>
        
    );
};