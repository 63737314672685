// @ts-ignore
import { useState, useLayoutEffect } from "react";
import { mutateUrls } from "../../../lib/useGenericSWR";
import { useSWRConfig } from "swr";
// @ts-ignore
import { EnsureUser } from "../../auth/EnsureUser";
import { useTranslation } from "react-i18next";
import { UcWidget } from "../../common/UcWidget";
// @ts-ignore
import { useResponsive } from 'lib/useResponsive';
import { Box, Alert, Grid, Typography, Link } from "@mui/material";
import { useUserMetrics } from '../Metrics/MetricsAPIEndpoints';
import { UcLoading } from "../../common/UcLoading";
// @ts-ignore
import { DateTime } from 'luxon';
// @ts-ignore
import { routePaths } from "routes";
import { useNavigate } from "react-router-dom";
import { green, red } from "@mui/material/colors";

export const AdminResidentialUsersStatsWidget = () => {
    const { t } = useTranslation();
    const [reMutateUrls, setReMutateUrls] = useState(false);
    const swrConfig = useSWRConfig();
    const { isMobile } = useResponsive();
    const navigate = useNavigate();

    const [sinceDate] = useState(DateTime.local().minus({ days: 30 }).toJSDate());

    const swrUserMetrics = useUserMetrics("UtilityResidential", sinceDate);
    let latestUserMetrics = swrUserMetrics?.result;

    const handleClickGoToResidentialUsers = () => {
        navigate(routePaths.residentialUsersPage.path);
    };

    const handleClickGoToLockedOutResidentialUsers = () => {
        navigate(routePaths.residentialUsersPage.path, { state: { onlyLockedOut: true } });
    };

    useLayoutEffect(() => {
        if (reMutateUrls) {
            mutateUrls(swrConfig);
            setReMutateUrls(false);
        }
    }, [reMutateUrls, swrConfig]);

    console.log("AdminResidentialUsersStatsWidget - Loaded");

    return (
        <UcWidget
            title={t("Residential Users")}
            alignTitleLeft={false}
            contentPadding={'1rem'}
            minHeight={'150px'}
            maxHeight={isMobile ? '100%' : '195px'}
        >
            <EnsureUser permissions={["Metrics:Read"]}>
                <div data-cy-id="residential_users_stats_widget">
                    <UcLoading
                        hasFade={false}
                        hasError={swrUserMetrics?.isError}
                        isLoading={swrUserMetrics?.isLoading}
                        height={"8vh"}
                        errorMessage={t(
                            "An error occurred retrieving user metrics."
                        )}
                    >
                        {latestUserMetrics ?
                            <Grid container spacing={1} alignItems="center">
                                <Grid item xs={6}>
                                    <Box sx={{ borderRight: '4px solid', borderColor: 'background.default' }}>
                                        <Typography variant='subtitle2'>
                                            <Link component="button" onClick={handleClickGoToResidentialUsers}>{t("New In Last 30 Days")}</Link>
                                        </Typography>
                                        <Typography variant="h3" sx={{ color: green[500] }}>
                                            {latestUserMetrics?.usersAddedSinceDateCount}
                                        </Typography>
                                    </Box>
                                </Grid>
                                <Grid item xs={6}>
                                    <Box>
                                        <Typography variant='subtitle2'>
                                            <Link component="button" onClick={handleClickGoToLockedOutResidentialUsers}>{t("Locked Out")}</Link>
                                        </Typography>
                                        <Typography variant="h3" sx={{ color: red[500] }}>
                                            {latestUserMetrics?.lockedOutUsersCount}
                                        </Typography>
                                    </Box>
                                </Grid>
                            </Grid>
                            :
                            <Alert severity="warning">{t("No user metrics were found.")}</Alert>
                        }
                    </UcLoading>
                </div>
            </EnsureUser>
        </UcWidget>
    );
};
