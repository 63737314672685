import React from 'react';
import { Typography, Box } from '@mui/material';
import { useTranslation } from "react-i18next";

export const NotFoundPage = () => {
    const { t } = useTranslation();

    return (
        <>
            <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            minHeight="100vh"
            >
                <div>
                    <Typography variant='h1' align='center' sx={{mb:1}}>
                        {t('404')}
                    </Typography>
                    <Typography variant='subtitle1' align='center'>
                        {t('Page not found.')}
                    </Typography>
                </div>
            </Box>
        </>
    );
};