import { UserNotificationGrid } from "./UserNotificationGrid"
import { useState, useLayoutEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { useResponsive } from 'lib/useResponsive';
import { UserNotificationCardList } from "./UserNotificationCardList";

export const UserNotificationGridStateful = (props) => {
    const [searchParams, setSearchParams] = useSearchParams();
    const queryParamName = (props.id || "UserNotificationGrid") + "_state";
    const [isRenderReady, setIsRenderReady] = useState(true);
    const { isMobile } = useResponsive();

    const getQueryCurrent = () => {
        let defaultValue = {
            take: 10,
            skip: 0,
            pageSize: 10,
            generalSearch: props.generalSearch || null,
            sort: [{field: "createdTs", sort: "desc"}],
        };

        let valueStr = searchParams.get(queryParamName);
        let result = false;

        try {
            result = JSON.parse(valueStr);
        }
        catch{
            console.error('Invalid Query Param ' + queryParamName, valueStr);
        }
        if (!result) {
            return defaultValue;
        }
        return result;
    };
    let queryCurrent = getQueryCurrent();
    const [current, setCurrent] = useState(queryCurrent);

    const handleFilterChange = (e) => {
        const newCurrent = {...current, ...e};
        setCurrent(newCurrent);
        searchParams.set(queryParamName, JSON.stringify(newCurrent));
        setSearchParams(searchParams);
    }

    useLayoutEffect(() => {
        if (JSON.stringify(queryCurrent) !== JSON.stringify(current) && isRenderReady)
        {
            setIsRenderReady(false);
        }

        if (!isRenderReady)
        {
            setIsRenderReady(true);
            setCurrent(queryCurrent);
        }
    }, [queryCurrent, current, isRenderReady, setIsRenderReady]);

    return (
        <>
        {isRenderReady &&
            (!isMobile ? (
                <UserNotificationGrid {...props}
                    sort={current.sort}
                    dataState={current}
                    generalSearch={current.generalSearch}
                    onFilterChange={handleFilterChange}
                />
            ) : (
                <UserNotificationCardList {...props}
                    sort={current.sort}
                />
            ))   
        }
        </>
    );
}
