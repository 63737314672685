import { getHolidaysDefaultItem, holidaysValidationSchema } from './HolidayAPIEndpoints';
import PropTypes from 'prop-types';
import { Formik, Form, Field } from 'formik';
import { useRef, useEffect } from 'react';
import { TextField as TextFieldFormik } from 'formik-mui';
import { useTranslation } from 'react-i18next';
import { UcDatePickerFormik } from 'components/common/UcDatePicker';
import { DateTime } from 'luxon';

export const HolidayForm = (props) => {
    const { t } = useTranslation();
    const itemToEdit = props.itemToEdit || getHolidaysDefaultItem();
    const dialogSubmit = useRef(props.dialogSubmit);
    const formRef = useRef();

    useEffect(() => {
        if (props.dialogSubmit !== dialogSubmit.current) {
            dialogSubmit.current = props.dialogSubmit;
            if (formRef.current) {
                formRef.current.handleSubmit();
            }
        }
    });

    const handleChangeHolidayDate = (value) => {
        if (formRef.current !== undefined) {
            formRef.current.values.holidayDate = DateTime.fromJSDate(new Date(value)).toUTC().toISO();
        }
    }

    return (
        <Formik
            innerRef={formRef}
            initialValues={itemToEdit}
            validationSchema={holidaysValidationSchema}
            onSubmit={(values) => {
                props.onSubmit(values);
            }}
        >
            <Form readonly={props.readonly}>
                <Field
                    name={"holidayDate"}
                    data-cy-id={"holiday_date"}
                    dateOnly={true}
                    component={UcDatePickerFormik}
                    label={t('Holiday Date')}
                    disabled={props.disabled}
                    sendValueToForm={handleChangeHolidayDate}
        
                />
                <Field
                    name={"holidayName"}
                    data-cy-id={"holiday_name"}
                    component={TextFieldFormik}
                    label={t('Holiday Name')}
                    disabled={props.disabled}
                />
                <Field
                    name={"holidayDescription"}
                    data-cy-id={"holiday_description"}
                    component={TextFieldFormik}
                    label={t('Holiday Description')}
                    disabled={props.disabled}
                />
            </Form>
        </Formik>
    );
}

HolidayForm.defaultProps = {
    id: 0,
    dialogSubmit: 0,
    changeIteration: 0,
    onSubmit: (values) => { console.log("onSubmit not implemented", values); },
    onError: (error) => { console.error("onError not implemented", error); },
};

HolidayForm.propTypes = {
    id: PropTypes.number,
    changeIteration: PropTypes.number,
    dialogSubmit: PropTypes.number,
};