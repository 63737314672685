import React from 'react';
import { useTranslation } from "react-i18next";
import { Formik, Form, Field } from 'formik';
import { TextField } from 'formik-mui';
import { sendCheckVerificationCode, sendVerificationCode, AccountVerificationStep3Schema } from './AccountAuthorizationApiEndpoints';
import Alert from '@mui/material/Alert';
import CircularProgress from '@mui/material/CircularProgress';
import Button from '@mui/material/Button';

export const AccountVerificationStep3 = (props) => {    
    const { t } = useTranslation();
    const { method } = props;
    const account = React.useRef(props.account);
    const formRef = React.useRef();
    const dialogSubmit = React.useRef(props.dialogSubmit);
    const initialValues = { code: '' };
    const [showError, setShowError] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const initialtwoFactorSent = React.useRef(false);
    const twoFactorSending = React.useRef(false);

    const sendCode = async () => {
        if (twoFactorSending.current) {
            // already sending don't do anything
            return;
        }

        twoFactorSending.current = true;
        setLoading(true);

        let twoFactorStatusResult = await sendVerificationCode(account.current.result?.extAccountNumber, account.current.result?.name, method);
        
        if (twoFactorStatusResult.isSuccessful) {
            setShowError(false);
        }
        else {
            setShowError(true);
        }
        
        setLoading(false);
        twoFactorSending.current = false;
    }

    if (!initialtwoFactorSent.current)
    {
        sendCode();
        initialtwoFactorSent.current = true;
    }

    const resendButton = () => {
        return (
            <div>
                <Button sx={{pl: 0, textTransform: 'none'}}
                    type="button"
                    onClick={sendCode}
                >
                    {method === 'call' ? t('Call again') : t('Re-send code')}
                </Button>
            </div>
        )
    }

    React.useEffect(() => {
        if (props.dialogSubmit !== dialogSubmit.current) {
            dialogSubmit.current = props.dialogSubmit;
            if (formRef.current) {
                formRef.current.handleSubmit();
            }
        }
    });

    return (
        <div className='ignoreCustomFormStyles'>
            { !account?.current.isLoading && account?.current.result === undefined && (
                <Alert severity='warning'>
                    {t('No results found.')}
                </Alert>  
            )}

            { !account?.current.isLoading && account?.current.result !== undefined && (
                <>  
                    {loading ? (
                    <Alert severity="info" sx={{mb: 2}}>
                        {t('Sending code...')}
                        <div>
                            <CircularProgress size="1rem" sx={{mt: 2}} aria-label={"Loading..."} title={"Loading..."} />
                        </div>
                    </Alert>
                    ):(
                        <>
                        {!showError ? (
                            <Alert severity="success" sx={{mb: 2}}>
                                {t('Successfully sent code. If you want to change the MFA method, please go back to the previous page.')}
                                {resendButton()}
                            </Alert>
                        ):(
                            <Alert severity="error" sx={{mb: 2}}>
                                {t('Failed to send code. You can try to change the MFA method by going back to the previous page. If the problem persists, please contact customer support.')}
                                {resendButton()}
                            </Alert>
                        )
                        }
                        </>
                    )}

                    <Formik
                        innerRef={formRef}
                        initialValues={initialValues}
                        validationSchema={AccountVerificationStep3Schema}
                        onSubmit={async (values) => {
                            props.onIsLoading(true);

                            let data = await sendCheckVerificationCode(account.current.result.extAccountNumber, account.current.result?.name, values.code, method);

                            if (data.isSuccessful) {
                                setShowError(false);
                                props.onNext(data);
                            }
                            else {
                                setShowError(true);
                            }

                            props.onIsLoading(false);
                        }}
                    >
                        <Form>
                            <Field
                                name={"code"}
                                placeholder={'(e.g. 123456)'}
                                component={TextField}
                                label={t("Verification Code")}
                            />
                        </Form>
                    </Formik>
                </>
            )}
        </div>
    );
}