import * as React from 'react';
import PropTypes from 'prop-types';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import Button from '@mui/material/Button';
import { domain } from "../../domain";
import { useTranslation } from "react-i18next";
import { startImpersonate, stopImpersonate } from 'components/admin/Impersonate/ImpersonateAPIEndpoints';
import { useUser } from 'components/auth/PermissionApiEndpoint';
import { useDomainNavigate } from "domain";

export const UcImpersonateDialog = (props) => {
    const { t } = useTranslation();
    const navigate = useDomainNavigate();
    const fullImpersonation = false;  // turning this fullImpersonation off for now.  A lot of edge cases that are very insecure
    const user = useUser();
    const { selectedItem, open, isImpersonating, onClose } = props;

    const handleStartFullImpersonation = async () => {
        if (selectedItem !== null) {
            const endpoint = `${process.env.REACT_APP_UCAUTH_AUTHORITY}/impersonate/login`;
            const queryParams = `?impersonatingUserEmail=${encodeURIComponent(selectedItem.email)}&returnUrl=${encodeURIComponent(domain.redirectBaseUrl)}/customer/summary`;

            window.location = endpoint + queryParams;
        }

        onClose();
    }
    const handleStart = async () => {
        if (fullImpersonation)
        {
            handleStartFullImpersonation();
            return;
        }

        if (selectedItem !== null)
        {
            let impersonate = {
                userId: user.id,
                impersonatingUserId: selectedItem.id,
            };

            let impersonateResult = await startImpersonate(impersonate);
            if (impersonateResult)
            {
                onClose();
                // Reload after sucessful impersonation
                navigate("/", {replace: true});
                window.location.reload();
            }
        }
    };

    const handleStopFullImpersonation = () => {
        if (user?.isImpersonating) {
            const endpoint = `${process.env.REACT_APP_UCAUTH_AUTHORITY}/impersonate/logout`;
            const queryParams = `?actorEmail=${encodeURIComponent(user.email)}&returnUrl=${encodeURIComponent(domain.redirectBaseUrl)}/admin/uc/users`;

            window.location = endpoint + queryParams;
        }

        onClose();
    }
    const handleStop = async () => {
        if (fullImpersonation)
        {
            handleStopFullImpersonation();
            return;
        }

        if (user?.isImpersonating)
        {
            let impersonateResult = await stopImpersonate(user.impersonate);
            if (impersonateResult)
            {
                onClose();
                // Reload after sucessful impersonation
                window.location.reload();
            }
        }
    }

    const handleClose = async () => {
        onClose();
    };

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            {isImpersonating && (
                <>
                    <DialogTitle id="alert-dialog-title">
                        {t("Stop Impersonating Confirmation")}
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            {t("Are you sure you want to stop impersonating {{username}}?", {username: user.impersonatingUsername})}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose}>{t("Cancel")}</Button>
                        <Button onClick={handleStop} autoFocus data-cy-id={'impersonateUserDialogConfirm'}>
                            {t("Stop Impersonating")}
                        </Button>
                    </DialogActions>
                </>
            )}
            {!isImpersonating && (
                <>
                    <DialogTitle id="alert-dialog-title">
                        {t("Impersonate User Confirmation")}
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            {t("Are you sure you want to impersonate {{username}}?", {username: selectedItem?.email})}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose}>{t("Cancel")}</Button>
                        <Button onClick={handleStart} autoFocus data-cy-id={'impersonateUserDialogConfirm'}>
                            {t("Impersonate")}
                        </Button>
                    </DialogActions>
                </>
            )}
        </Dialog>
    );
}

UcImpersonateDialog.propTypes = {
    open: PropTypes.bool.isRequired,
    isImpersonating: PropTypes.bool.isRequired,
    selectedItem: PropTypes.object,
    onClose: PropTypes.func.isRequired,
};