// @ts-ignore
import { useState, useLayoutEffect, useRef, useEffect } from "react";
import {
  DataGridPro,
  GridFilterModel,
  GridRowId,
  GridRowSelectionModel,
} from "@mui/x-data-grid-pro";
// @ts-ignore
import { useRateVersions } from "../Rates/RateVersionApiEndpoints";
// @ts-ignore
import { getWholeRow } from "../../../lib/DataGridHelpers";
// @ts-ignore
import { UcPagination } from "components/common/UcPagination";
// @ts-ignore
import { UcIconButtons } from "components/common/UcIconButtons";
// @ts-ignore
import { dateFormatter } from "lib/Formatters";
import { useTranslation } from "react-i18next";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
// @ts-ignore
import { routePaths } from "routes";
import { Chip } from "@mui/material";

interface StatusChip {
  colour:
    | "default"
    | "primary"
    | "secondary"
    | "error"
    | "info"
    | "success"
    | "warning";
  label: string;
}

interface AdminRateExpirationGridProps {
  sort: {
    field: string;
    sort: string;
  }[] | undefined;
  generalSearch: string | undefined | null;
  status: string;
  dataState: any;
  onSelected: any;
  onFilterChange: any;
  toolbar: any;
}

export const AdminRateExpirationGrid = (
  props: AdminRateExpirationGridProps
) => {
  const { t } = useTranslation();
  const {
    generalSearch,
    status,
    dataState,
    onSelected,
    onFilterChange,
    toolbar,
  } = props;
  const today = new Date();
  const initialSort = [{ field: "endDate", sort: "asc" }];
  const sort = props.sort || initialSort;
  const initialDataState = dataState || { skip: 0, take: 5 };

  let statusChip: StatusChip = {
    colour: "success",
    label: "",
  };

  const [filter, setFilter] = useState({
    take: initialDataState.take,
    skip: initialDataState.skip,
    sort: sort,
    generalSearch: generalSearch,
    status: status,
  });

  useEffect(() => {
    if (generalSearch !== filter.generalSearch || status !== filter.status) {
      setFilter({ ...filter, generalSearch: generalSearch, status: status });
    }
  }, [filter, setFilter, generalSearch, status]);

  let initialFilterModel: GridFilterModel | undefined = {
    items: [{ field: "id", operator: "contains", value: "" }],
  };

  const [filterModel] = useState<GridFilterModel | undefined>(
    initialFilterModel
  );

  const needsDataUpdate = useRef("");

  const daysLeftFormatter = (endDate: Date) => {
    const formatEndDate = new Date(endDate);
    const diffTime = formatEndDate.valueOf() - today.valueOf();
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    return diffDays;
  };

  const statusFormatter = (endDate: Date) => {
    const daysLeft = daysLeftFormatter(endDate);

    if (daysLeft >= 90) {
      statusChip.colour = "success";
      statusChip.label = "✔ " + t("{{daysLeft}} days left", {daysLeft: daysLeft});
    } else if (daysLeft > 0 && daysLeft < 90) {
      statusChip.colour = "warning";
      statusChip.label = t("{{daysLeft}} days left", {daysLeft: daysLeft});
    } else {
      statusChip.colour = "error";
      statusChip.label = t("Rate expired");
    }

    return (
      <Chip
        sx={{ minWidth: "130px" }}
        title={statusChip.label}
        label={statusChip.label}
        color={statusChip.colour}
        variant="outlined"
      />
    );
  };

  let defaultDataItems = {
    columnVisibilityModel: {
      id: false,
    },
    columns: [
      {
        field: "details",
        headerName: "",
        width: 50,
        valueGetter: getWholeRow,
        renderCell: (params: any) => {
          const actions = [
            {
              title: t("Rate Class Details"),
              icon: (
                <OpenInNewIcon
                  data-cy-id={"adminRateExpirationDetailsButton"}
                />
              ),
              color: "primary",
              isHidden: false,
              isDisabled: false,
              requiredPermission: "RateClass:Read",
              onClick: () =>
                window.open(
                  routePaths.rateVersionListPage.getPath(
                    params.value.rateClassId,
                    params.value.rateClassName
                  ),
                  "_blank"
                ),
            },
          ];
          return <UcIconButtons actions={actions} />;
        },
      },
      { field: "rateClassName", headerName: t("Name"), minWidth: 200, flex: 1 },
      { field: "name", headerName: t("Version Name"), minWidth: 150, flex: 1 },
      {
        field: "status",
        headerName: t("Status"),
        headerAlign: "center",
        align: "center",
        minWidth: 150,
        flex: 1,
        sortable: false,
        renderCell: (params: any) => {
          return statusFormatter(params.row.endDate);
        },
      },
      {
        field: "endDate",
        headerName: t("Expires On"),
        minWidth: 250,
        flex: 1,
        valueFormatter: (params: any) => {
          return dateFormatter(params.value);
        },
      },
    ],
    rows: [],
    rowCount: -1,
  };
  const [dataItems, setDataItems] = useState<any>(defaultDataItems);

  const defaultSelectedItem = { dataItem: {}, selectedId: 0 };
  const [selectedItem, setSelectedItem] = useState<any>(defaultSelectedItem);

  const isDataLoaded = useRef(false);

  const dataReceived = (swrResult: any) => {
    let receivedDataItems = swrResult.result;

    let selectedDataItems = receivedDataItems.map((item: any) => {
      return { ...item, selected: item.id === selectedItem.selectedId };
    });
    selectedDataItems.iteration = receivedDataItems.iteration;
    selectedDataItems.rowCount = swrResult.rowCount;
    if (selectedDataItems.length > selectedDataItems.rowCount) {
      selectedDataItems.rowCount = selectedDataItems.length;
    }

    setDataItems({
      ...defaultDataItems,
      rows: [...receivedDataItems],
      rowCount: swrResult.rowCount,
    });
  };

  const swrRateVersions = useRateVersions(filter);
  const isLoading = swrRateVersions.isLoading || !isDataLoaded.current;
  if (!swrRateVersions.result && defaultDataItems.rowCount !== -1) {
    setDataItems(defaultDataItems);
  }

  useLayoutEffect(() => {
    if (swrRateVersions.result) {
      isDataLoaded.current = true;
      let filterString = JSON.stringify(filter);
      if (needsDataUpdate.current !== filterString) {
        needsDataUpdate.current = filterString;
        dataReceived(swrRateVersions);
      }
    }
  });

  const updateFilter = (newFilter: any) => {
    setFilter(newFilter);
    onFilterChange(newFilter);
  };

  console.log("AdminRateExpirationGrid - Loaded");
  return (
    <div data-cy-id={"adminRateExpirationGridWrapper"}>
      <DataGridPro
        autoHeight={true}
        disableColumnFilter={true}
        disableColumnSelector={true}
        loading={isLoading}
        sortingMode="server"
        filterMode="server"
        sortModel={filter.sort}
        onSortModelChange={(model) => updateFilter({ ...filter, sort: model })}
        filterModel={filterModel}
        paginationModel={{
          page: filter.skip / filter.take,
          pageSize: filter.take,
        }}
        onPaginationModelChange={(model) =>
          updateFilter({
            ...filter,
            skip: model.page * model.pageSize,
            take: model.pageSize,
          })
        }
        nge={(newPageSize: number) =>
          updateFilter({ ...filter, take: newPageSize })
        }
        paginationMode="server"
        rowsPerPageOptions={[5]}
        onRowSelectionModelChange={(
          newSelectionModel: GridRowSelectionModel
        ) => {
          if (newSelectionModel.length !== 1) {
            setSelectedItem(defaultSelectedItem);
            onSelected(defaultSelectedItem);
            return;
          }
          const selectedId: GridRowId = newSelectionModel[0];
          let selectedRateItem = dataItems.rows.filter((obj: any) => {
            return obj.id === selectedId;
          });
          if (selectedRateItem.length !== 1) {
            setSelectedItem(defaultSelectedItem);
            onSelected(defaultSelectedItem);
            return;
          }
          selectedRateItem = selectedRateItem[0];
          let newlySelectedItem = {
            dataItem: selectedRateItem,
            selectedId: selectedId,
          };

          setSelectedItem(newlySelectedItem as any);
          onSelected(newlySelectedItem.dataItem);
        }}
        pagination
        components={{
          Toolbar: () => {
            return toolbar || <></>;
          },
          Pagination: UcPagination,
        }}
        {...dataItems}
      />
    </div>
  );
};

AdminRateExpirationGrid.defaultProps = {
  onSelected: (selectedItem: any) => {
    console.log("onSelected not implemented", selectedItem);
  },
  onFilterChange: (filter: any) => {
    console.log("onFilterChange not implemented", filter);
  },
};
