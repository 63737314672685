import React, { useState, useContext, useEffect, useLayoutEffect } from 'react';
import { PageContext } from '../../../lib/PageProvider';
import { MeterCycleGridStateful } from "./MeterCycleGridStateful"
import { MeterCycleFormDialog } from './MeterCycleFormDialog';
import { mutateUrls } from "../../../lib/useGenericSWR";
import { useSWRConfig } from "swr";
import './MeterCycleListPage.scss';
import { EnsureUser } from '../../auth/EnsureUser';
import { UcToolbar } from '../../common/UcToolbar';
import { UcListPage } from 'components/common/UcListPage';
import { useHasPermission } from 'components/auth/PermissionApiEndpoint';
import AddIcon from '@mui/icons-material/Add';
import { getMeterCyclesDefaultItem } from './MeterCycleAPIEndpoints';
import { useTranslation } from 'react-i18next';

export const MeterCycleListPage = (props) => {
    const { t } = useTranslation();
    const meterId = props.meterId;
    const userId = props.userId;
    const accountId = props.accountId;
    const billingCycleId = props.billingCycleId;
    const [editDialog, setEditDialog] = useState({ open: false, delete: false });
    const [selectedItem, setSelectedItem] = useState(null);
    const [pageState, pageDispatch] = useContext(PageContext);
    const [generalSearch, setGeneralSearch] = useState('');

    const [reMutateUrls, setReMutateUrls] = useState(false);
    const swrConfig = useSWRConfig();

    const closeHandler = () => {
        setEditDialog({ open: false, itemToEdit: editDialog.dataItem });
    }

    const saveHandler = () => {
        closeHandler();
        // Also, remutate
        setReMutateUrls(true);
    }

    const openDialog = (dataItem) => {
        if (!dataItem) {
            return;
        }
        if (!dataItem.formIteration) {
            dataItem.formIteration = "edit" + dataItem.id;
        }
        setEditDialog({ open: true, itemToEdit: dataItem, delete: false });
    }

    const createItem = () => {
        openDialog(getMeterCyclesDefaultItem());
    }

    const editItem = (item) => {
        openDialog(item);
    }

    const actions = [
        {
            icon: <AddIcon />,
            title: props.billingCycleId ? t('Add Meter') : (t('Add Bill Cycle')),
            toolTip: props.billingCycleId ? t('Add Meter') : (t('Add Bill Cycle')),
            onClick: () => { createItem(); },
            isHidden: !useHasPermission("MeterCycle:Modify"),
        }
    ];

    useLayoutEffect(() => {
        if (reMutateUrls) {
            mutateUrls(swrConfig);
            setReMutateUrls(false);
        }
    }, [reMutateUrls, swrConfig]);

    useEffect(() => {
        pageDispatch({
            header: t("Bill Cycles"),
        });
    }, [pageState.header, selectedItem, pageDispatch, t]);

    console.log("MeterCycleListPage - Loaded");
    return (
        <UcListPage>
            <EnsureUser permissions={["MeterCycle:Read"]}>
                    <UcToolbar
                        hasGeneralSearch={true}
                        onGeneralSearchChange={(value) => {
                            setGeneralSearch(value);
                        } }
                        actions={actions}></UcToolbar>
                <MeterCycleFormDialog
                    userId={userId}
                    accountId={accountId}
                    billingCycleId={props.billingCycleId}
                    billingCycleName={props.billingCycleName}
                    open={editDialog.open}
                    delete={editDialog.delete}
                    itemToEdit={editDialog.itemToEdit}
                    onCancel={closeHandler}
                    onSave={saveHandler}
                />
                {!reMutateUrls &&
                    <MeterCycleGridStateful
                        userId={userId}
                        meterId={meterId}
                        accountId={accountId}
                        billingCycleId={billingCycleId}
                        generalSearch={generalSearch}
                        onEditItem={editItem}
                        onSelected={(newSelectedItem) => setSelectedItem({ ...newSelectedItem })} />
                }
            </EnsureUser>
        </UcListPage>
    );
};