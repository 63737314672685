import { getAnnouncementsDefaultItem, announcementsValidationSchema } from './AnnouncementAPIEndpoints';
import PropTypes from 'prop-types';
import { Formik, Form, Field } from 'formik';
import { useRef, useEffect, useState } from 'react';
import { TextField } from 'formik-mui';

import MarkdownIt from 'markdown-it';
import MdEditor from 'react-markdown-editor-lite';
import { useTranslation } from 'react-i18next';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import 'react-markdown-editor-lite/lib/index.css';
import { Typography } from '@mui/material';

export const AnnouncementForm = (props) => {
    const { t } = useTranslation();
    const itemToEdit = props.itemToEdit || getAnnouncementsDefaultItem();
    const dialogSubmit = useRef(props.dialogSubmit);
    const formRef = useRef();
    const [content, setContent] = useState(itemToEdit.content);
    const [initialLoad, setInitialLoad] = useState(true);

    const mdParser = new MarkdownIt(/* Markdown-it options */);

    function onImageUpload(file) {
        return new Promise(resolve => {
          const reader = new FileReader();
          reader.onload = data => {
            resolve(data.target.result);
          };
          reader.readAsDataURL(file);
        });
      }
    
    function handleEditorChange({ html, text }) {
        setContent(text);
        console.log('handleEditorChange', html, text);
    }

    useEffect(() => {
        if (props.dialogSubmit !== dialogSubmit.current) {
            dialogSubmit.current = props.dialogSubmit;
            if (formRef.current) {
                formRef.current.handleSubmit();
            }
        }
        if (initialLoad) {
            props.setPublishCheckbox(itemToEdit.isPublished);
            setInitialLoad(false);
        }
    }, [initialLoad, itemToEdit.isPublished, props]);

    return (
        <Formik
            innerRef={formRef}
            initialValues={itemToEdit}
            validationSchema={announcementsValidationSchema}
            onSubmit={(values) => {
                values.content = content;
                props.onSubmit(values);
            }}
        >
            <Form readonly={props.readonly}>
                <Field
                    name={"header"}
                    data-cy-id={"announcement_header"}
                    component={TextField}
                    label={t('Header')}
                    disabled={props.disabled}
                />
                {props.userIsUtilityAdmin && (
                    <Typography sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                        {t("Emails Sent:")} {props.itemToEdit.emailsSent}
                    </Typography>
                )}
                <div data-cy-id={"announcement_content"}>
                    <MdEditor
                        renderHTML={text => mdParser.render(text)}
                        onChange={handleEditorChange}
                        style={{ height: '400px' }}
                        value={content}
                        placeholder={t('Type in Announcement content here')}
                        onImageUpload={onImageUpload}
                    />
                </div> 
                <Field
                    name={"isPublished"}
                >
                    {({ field: { value }, form: { setFieldValue } }) => (
                        <FormControlLabel
                            control={
                                <Checkbox
                                    data-cy-id={"announcement_status"}
                                    defaultChecked={itemToEdit.isPublished}
                                    value={value}
                                    onChange={(e, value) => { setFieldValue("isPublished", value); props.setPublishCheckbox(value); }}
                                />
                            }
                            label={t("Publish Announcement")}
                        />
                    )}
                </Field>
                <Field
                    name={"isPinned"}
                >
                    {({ field: { value }, form: { setFieldValue } }) => (
                        <FormControlLabel
                            control={
                                <Checkbox
                                    data-cy-id={"announcement_pinned"}
                                    defaultChecked={itemToEdit.isPinned}
                                    value={value}
                                    onChange={(e, value) => { setFieldValue("isPinned", value); props.setPublishCheckbox(value); }}
                                />
                            }
                            label={t("Pin Announcement to top")}
                        />
                    )}
                </Field>
            </Form>
        </Formik>
    );
}

AnnouncementForm.defaultProps = {
    id: 0,
    dialogSubmit: 0,
    changeIteration: 0,
    onSubmit: (values) => { console.log("onSubmit not implemented", values); },
    onError: (error) => { console.error("onError not implemented", error); },
};

AnnouncementForm.propTypes = {
    id: PropTypes.number,
    changeIteration: PropTypes.number,
    dialogSubmit: PropTypes.number,
};