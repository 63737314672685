import React, { useState } from 'react';
import { getRateChargeDefaultItem, rateChargeValidationSchema, useRateCharges } from './RateChargeApiEndpoints';
import PropTypes from 'prop-types';
import { Formik, Form, Field } from 'formik';
import { useRef, useEffect } from 'react';
import { TextField } from 'formik-mui';
import { Alert, Checkbox, Grid, FormGroup, FormControlLabel, MenuItem } from '@mui/material';
import { UcEnumSelect } from 'components/common/UcEnumSelect';
import { useEnums } from 'components/auth/PermissionApiEndpoint';
import { useTranslation, Trans } from "react-i18next";
import { dateRangeChargeChecker } from 'lib/DateRangeChargeChecker';
import { UcColorCirclePicker } from 'components/common/UcColorCirclePicker';
import { enumFormatter } from 'lib/EnumFormatter';

const months = [
    { value: 1, key: 'January', maxDays: 31 },
    { value: 2, key: 'February', maxDays: 29 },
    { value: 3, key: 'March', maxDays: 31 },
    { value: 4, key: 'April', maxDays: 30 },
    { value: 5, key: 'May', maxDays: 31 },
    { value: 6, key: 'June', maxDays: 30 },
    { value: 7, key: 'July', maxDays: 31 },
    { value: 8, key: 'August', maxDays: 31 },
    { value: 9, key: 'September', maxDays: 30 },
    { value: 10, key: 'October', maxDays: 31 },
    { value: 11, key: 'November', maxDays: 30 },
    { value: 12, key: 'December', maxDays: 31 }
];


export const RateChargeForm = (props) => {
    const { t } = useTranslation();
    const itemToEdit = props.itemToEdit || getRateChargeDefaultItem();
    const rateVersionId = itemToEdit.rateVersionId;
    const dialogSubmit = useRef(props.dialogSubmit);
    const formRef = useRef();
    const [itemToEditType, setItemToEditType] = React.useState(itemToEdit.type);
    const [initialLoad, setInitialLoad] = React.useState(true);
    const rateChargeTypeEnums = useEnums("RateChargeType");

    const [initialRateAlert, setInitialRateAlert] = React.useState("");

    const initialSort = [{ field: "id", sort: "desc" }];
    const sort = props.sort || initialSort;
    const initialDataState = props.dataState || { skip: 0, take: 10, };

    const [filter] = useState({
        take: initialDataState.take,
        skip: initialDataState.skip,
        sort: sort,
        rateVersionId: parseInt(rateVersionId),
        generalSearch: props.generalSearch,
    });
    const swrRateCharges = useRateCharges(filter);

    const rateChargeTypes = swrRateCharges.result.filter(obj => {
        return obj.type.toUpperCase() === itemToEditType.toUpperCase();
    })

    const [showRateBasicRule, setShowRateBasicRule] = React.useState(false);
    const existingItems = props.existingItems || [];

    // NOTE: itemToEdit.ruleSet.dateRanges is a json object to future-proof the coloumn.
    // For now we only care about the first entry
    const [startMonth, setStartMonth] = React.useState(
        itemToEdit.ruleSet?.dateRanges === undefined || itemToEdit.ruleSet?.dateRanges[0]?.start === undefined
            ? months[0].value
            : months.filter((item) => item.value === itemToEdit.ruleSet?.dateRanges[0]?.start.month)[0]?.value
    );

    const [startDay, setStartDay] = React.useState(
        itemToEdit.ruleSet?.dateRanges === undefined || itemToEdit.ruleSet?.dateRanges[0]?.start === undefined
            ? 1
            : itemToEdit.ruleSet?.dateRanges[0]?.start.day
    );

    const [endMonth, setEndMonth] = React.useState(
        itemToEdit.ruleSet?.dateRanges === undefined || itemToEdit.ruleSet?.dateRanges[0]?.end === undefined
            ? months[0].value
            : months.filter((item) => item.value === itemToEdit.ruleSet?.dateRanges[0]?.end.month)[0]?.value
    );

    const [endDay, setEndDay] = React.useState(
        itemToEdit.ruleSet?.dateRanges === undefined || itemToEdit.ruleSet?.dateRanges[0]?.end === undefined
            ? 1
            : itemToEdit.ruleSet?.dateRanges[0]?.end.day
    );

    const [checkedAllYearRound, setCheckedAllYearRound] = React.useState(true);

    function dateFromDay(month, day) {
        return new Date(0, month - 1, day);
    }

    const newStartDate = dateFromDay(startMonth, startDay);
    const newEndDate = dateFromDay(endMonth, endDay);

    var newDateRange = {
        startDate: newStartDate,
        endDate: newEndDate
    }

    const overlapCount = dateRangeChargeChecker(newDateRange, rateChargeTypes, itemToEdit, checkedAllYearRound)
    props.setRateChargeOverlap(overlapCount);

    const handleChangeAllYearRound = (event) => {
      setCheckedAllYearRound(event.target.checked);
    };

    useEffect(() => {
        if (props.dialogSubmit !== dialogSubmit.current) {
            dialogSubmit.current = props.dialogSubmit;
            if (formRef.current) {
                formRef.current.handleSubmit();
            }
        }
    });

    useEffect(() => {
        if (initialLoad) {
            if (itemToEdit.ruleSet?.dateRanges !== undefined && itemToEdit.ruleSet?.dateRanges[0]?.start !== undefined)
            {
                setCheckedAllYearRound(false);
            }

            setInitialLoad(false);
        }
    }, [initialLoad, itemToEdit]);

    return (
        <div className='ignoreCustomFormStyles'>
            <Formik
                innerRef={formRef}
                initialValues={itemToEdit}
                validationSchema={rateChargeValidationSchema}
                onSubmit={(values) => {
                    values.name = values.name.trim();
                    values.description = values.description.trim();
                    values.ruleSet?.dateRanges?.pop();

                    if (!checkedAllYearRound) {
                        const dateRangeObjTemplate = { start: { month: 0, day: 0 }, end: { month: 0, day: 0 } };
                        const newDateRange = {
                            ...dateRangeObjTemplate,
                            start: {
                                    ...dateRangeObjTemplate.start,
                                    month: startMonth,
                                    day: startDay
                            },
                            end: {
                                ...dateRangeObjTemplate.end,
                                month: endMonth,
                                day: endDay
                             },
                        };
                        values.ruleSet?.dateRanges?.push(newDateRange);
                    }

                    props.onSubmit(values);
                }}
            >
                <Form readOnly={props.readOnly}>
                    <Grid container spacing={2} style={{paddingTop: '8px'}}>
                        { initialRateAlert && (
                            <Grid item xs={12} sm={12} md={12}>
                                <Alert severity="info">
                                <Trans i18nKey="Rate Charge Alert Info" values={{charge: initialRateAlert}} components={{b: <b></b>}} />
                                </Alert>
                            </Grid>
                        )}
                        <Grid item xs={12} sm={12} md={6}>
                            <Field fullWidth
                                name={"name"}
                                component={TextField}
                                disabled={props.disabled}
                                label={t("Name")}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={6}>
                            <Field
                                name={"type"}
                                >
                                    {({ field: { value }, form: { setFieldValue } }) => (
                                        <UcEnumSelect
                                            value={value}
                                            enumName="RateChargeType" 
                                            label={t("Type")}
                                            disabled={itemToEdit.id > 0}
                                            onChange={(e, typeValue) => {
                                                setFieldValue("type", typeValue.id);
                                                setItemToEditType(typeValue.id);
                                                if (typeValue.id === 'TIERED' || typeValue.id === 'DISCOUNT') {
                                                    setInitialRateAlert(enumFormatter(rateChargeTypeEnums, typeValue.id));
                                                }
                                                else {
                                                    setInitialRateAlert("");
                                                }
                                                if (typeValue.id === 'FIXED' || typeValue.id === 'MONTHLY' ) {
                                                    setShowRateBasicRule(true);
                                                }
                                                else {
                                                    setShowRateBasicRule(false);
                                                }
                                            }}
                                            hideGenerationRate={true}
                                        />
                                    )}
                            </Field>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12}>
                            <Field fullWidth
                                name={"description"}
                                label={t("Description")}
                                component={TextField}
                                multiline
                                rows={4}
                                disabled={props.disabled}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={12}>
                            <FormGroup sx={{display: 'inline-block'}}>
                                <FormControlLabel
                                    checked={checkedAllYearRound}
                                    onChange={handleChangeAllYearRound}
                                    inputProps={{ 'aria-label': 'controlled' }}
                                    control={<Checkbox defaultChecked />}
                                    label={t("Year-round")}
                                />
                            </FormGroup>
                        </Grid>
                        { !checkedAllYearRound && (
                            <>
                                <Grid item xs={12} sm={12} md={6}>
                                    <Field
                                        select
                                        name={'startMonth'}
                                        label={t("Start Month")}
                                        component={TextField}
                                        value={startMonth}
                                        onChange={(newValue) => {
                                            setStartMonth(newValue.target.value);
                                        }}
                                    >
                                        {months.map((month) => (
                                            <MenuItem key={month.key} value={month.value}>
                                                {month.key}
                                            </MenuItem>
                                        ))}
                                    </Field>
                                </Grid>
                                <Grid item xs={12} sm={12} md={6}>
                                    <Field
                                        select
                                        name={'startDay'}
                                        label={t("Start Day")}
                                        component={TextField}
                                        value={startDay}
                                        onChange={(newValue) => {
                                            setStartDay(newValue.target.value);
                                        }}
                                    >
                                        {Array(months[startMonth - 1]?.maxDays).fill(1).map((el, i) => (
                                            <MenuItem key={i + 1} value={i + 1}>
                                                {i + 1}
                                            </MenuItem>
                                        ))}
                                    </Field>
                                </Grid>
                                <Grid item xs={12} sm={12} md={6}>
                                    <Field
                                        select
                                        name={'endMonth'}
                                        label={t("End Month")}
                                        component={TextField}
                                        value={endMonth}
                                        onChange={(newValue) => {
                                            setEndMonth(newValue.target.value);
                                        }}
                                    >
                                        {months.map((month) => (
                                            <MenuItem key={month.key} value={month.value}>
                                                {month.key}
                                            </MenuItem>
                                        ))}
                                    </Field>
                                </Grid>
                                <Grid item xs={12} sm={12} md={6}>
                                    <Field
                                        select
                                        name={'endDay'}
                                        label={t("End Day")}
                                        component={TextField}
                                        value={endDay}
                                        onChange={(newValue) => {
                                            setEndDay(newValue.target.value);
                                        }}
                                    >
                                        {Array(months[endMonth - 1]?.maxDays).fill(1).map((el, i) => (
                                            <MenuItem key={i + 1} value={i + 1}>
                                                {i + 1}
                                            </MenuItem>
                                        ))}
                                    </Field>
                                </Grid>
                            </>
                        )}
                        {(showRateBasicRule || itemToEditType.toUpperCase() === "MONTHLY" || itemToEditType.toUpperCase() === "FIXED") && (
                            <>
                            <Grid item xs={12} sm={12} md={6}>
                                <Field
                                    name={"rate"}
                                    type="decimal"
                                    component={TextField}
                                    disabled={props.disabled}
                                    label={t("Rate ($)")}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={12}>
                                <Field
                                    name={"htmlColor"}
                                    existingItems={existingItems}
                                    itemToEdit={itemToEdit}
                                    value={ itemToEdit?.basicHtmlColorsJson?.[0]?.htmlColor || '' }
                                    component={UcColorCirclePicker}
                                    onChange={(newValue) => {
                                        formRef.current.setFieldValue("htmlColor", newValue);
                                    }}
                                    label={t("Color")}
                                    hint={t('This color will be associated with the rule when rendered on a usage graph.')}
                                />
                            </Grid>
                            </>
                        )}
                    </Grid>
                </Form>
            </Formik>
        </div>
    );
}

RateChargeForm.defaultProps = {
    id: 0,
    dialogSubmit: 0,
    changeIteration: 0,
    onSubmit: (values) => { console.log("onSubmit not implemented", values); },
    onError: (error) => { console.error("onError not implemented", error); },
};

RateChargeForm.propTypes = {
    id: PropTypes.number,
    changeIteration: PropTypes.number,
    dialogSubmit: PropTypes.number,
};