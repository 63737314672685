import { UcWidget } from '../common/UcWidget';
import { ActionItem, WidgetWrapperProps } from "../common/Common.interfaces";
import { useTranslation } from "react-i18next";
import { QuickActionsWidget } from './QuickActionsWidget';

export default function QuickActionsWidgetWrapper(props: WidgetWrapperProps) {
    const { t } = useTranslation();

    const actionItems: ActionItem[] = [];

    return (!props.hideWidgetBorder) ? (
        <UcWidget
            actionItems={actionItems}
            minHeight={"auto"}
            contentPadding={"0.5rem 1rem 1rem 1rem"}
            title={!props.hideWidgetTitle ? t("Quick Actions") : ""}
            alignTitleLeft={false}
        >
            <QuickActionsWidget />
        </UcWidget>
  ) : (
        <QuickActionsWidget />
  )
}
