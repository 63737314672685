import * as React from 'react';
// @ts-ignore
import { useEnums } from 'components/auth/PermissionApiEndpoint';
import { Box, Chip, MenuItem, OutlinedInput, Select } from '@mui/material';

interface Option {
    id: string | undefined;
    display: string;
  }
interface UcStatusSelectProps {
    roleType?: string;
    value: string;
    disableClearable?: boolean;
    id?: string;
    onChange: Function;
    forcedSize?: any;
    forcedWidth?: string;
    forcedPadding?: string;
    forcedDisplay?: string;
    label?: string;
    datacyid?: string;
    disabled?: boolean;
    forceChipHeight?: string;
    forceChipFontSize?: string;
}

export const UcStatusSelect = React.forwardRef((props: UcStatusSelectProps, ref) => {
    const [open, setOpen] = React.useState(false);
    const [options, setOptions] = React.useState<Option[]>([]);
    const needsUpdate = React.useRef(true);

    let enums = useEnums("HelpRequestStatus");

    if (needsUpdate.current) {
        needsUpdate.current = false;
        setOptions([...enums]);
    }

    let valueOption: Option = { id: undefined, display: 'Loading...' }

    if (props.value && enums){
        valueOption = enums.find((x: Option) => x.id === props.value);
    }   

    const [selectedValue, setSelectedValue] = React.useState<Option | undefined>(valueOption);

    let id = React.useId();

    const getChipColor = (type: string | undefined) => {
        switch (type) {
            case 'New':
                return 'info';
            case 'Closed':
                return 'success';
            case 'On Hold':
                return 'error';
            default:
                return 'warning';
        }
    };

    return (
        <Select
            ref={ref}
            id={props.id || "ucStatusSelect" + id}
            data-cy-id={props.datacyid}
            open={open}
            value={selectedValue || null}
            fullWidth={false}
            disabled={props.disabled}
            size={props.forcedSize || 'normal'}
            style={{width: props.forcedWidth || '100%', padding: props.forcedPadding || 0, display: props.forcedDisplay || 'block'}}
            onChange={(event) => {
                const {
                    target: { value },
                  } = event;
                let valueId = options.find((x: Option) => x.id === value)
                setSelectedValue(valueId);
                props.onChange(event, value);
            }}
            onOpen={() => {
                setOpen(true);
            }}
            onClose={() => {
                setOpen(false);
            }}
            input={<OutlinedInput id="select-single-chip" label="Chip" />}
            renderValue={(selected) => (
                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                <Chip
                    key={selected?.id}
                    label={selected?.display}
                    color={getChipColor(selected?.display)}
                    sx={{
                        color: 'white',
                        height: props.forceChipHeight,
                        fontSize: props.forceChipFontSize
                      }}
                />
                </Box>
            )}
        >
            {options?.map((option: Option) => (
            <MenuItem
                key={option.id}
                value={option.id}
            >
                {option.display}
            </MenuItem>
            ))}
        </Select>
    );
});

UcStatusSelect.defaultProps = {
    onChange: (event: any, value: any) => { console.log("onChange not implemented", event, value); },
};