// @ts-ignore
import React, { useContext, useEffect } from 'react';
// @ts-ignore
import { PageContext, PageState, PageAction } from 'lib/PageProvider';
import { useTranslation } from "react-i18next";
import { Grid } from '@mui/material';
// @ts-ignore
import { useResponsive } from 'lib/useResponsive';
import { AdminResidentialUsersStatsWidget } from './Widgets/AdminResidentialUsersStatsWidget';
import { AdminAccountSyncStatsWidget } from './Widgets/AdminAccountSyncStatsWidget';
import { RateExpirationStatsWidget } from './Widgets/RateExpirationStatsWidget';
// @ts-ignore
import { EnsureUser } from "../auth/EnsureUser";
import AdminHelpRequestStatsWidget from './Widgets/AdminHelpRequestStatsWidget';

export const AdminDashboardPage = () => {
    const { t } = useTranslation();
    const { isMobile } = useResponsive();
    const [pageState, pageDispatch] = useContext<[PageState, React.Dispatch<PageAction>]>(PageContext);

    useEffect(() => {
        pageDispatch({
            header: t("Admin Dashboard"),
            actionItems: [] as any[],
            variableHeader: "",
            tabs: [],
            onTabChange: () => { },
            tabValue: ''
        });
    }, [pageState.header, pageDispatch, t]);

    return (
        <EnsureUser permissions={["Metrics:Read"]}>
            <div className="adminDashboard" data-cy-id="admin_dashboard">
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={12}>
                        <AdminHelpRequestStatsWidget />
                    </Grid>
                    <Grid height={!isMobile ? '389px' : 'auto'} item xs={12} sm={12} md={6}>
                        <AdminAccountSyncStatsWidget />
                    </Grid>
                    <Grid height={!isMobile ? '389px' : 'auto'} item xs={12} sm={12} md={6}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <AdminResidentialUsersStatsWidget />
                            </Grid>
                            <Grid item xs={12}>
                                <RateExpirationStatsWidget /> 
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </div>
        </EnsureUser>
    );
}
