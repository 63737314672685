// @ts-ignore
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSWRConfig } from "swr";
import { mutateUrls } from "lib/useGenericSWR";
import {
    Alert,
    Button,
    Card,
    CardContent,
    Grid,
    IconButton,
    ListItemIcon,
    ListItemText,
    MenuItem,
    MenuList,
    Popover,
    Tooltip,
    Typography,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import DeleteIcon from "@mui/icons-material/Delete";
import {
    useQuickActions,
    bulkUpdateQuickActions,
    getQuickActionDefaultItem
} from "../../admin/Dashboard/QuickActionsAPIEndpoints";
import { useTheme } from "@mui/material/styles";
import {
    Add as AddIcon,
    Edit as EditIcon
} from '@mui/icons-material';
// @ts-ignore
import { QuickActionsWidgetFormDialog } from "./QuickActionsWidgetFormDialog";
// @ts-ignore
import { useResponsive } from 'lib/useResponsive';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import VisibilityIcon from '@mui/icons-material/Visibility';
import EnsureUserAndModule from "../../auth/EnsureUserAndModule";
import { UcLoading } from "../../common/UcLoading";

export default function QuickActionsWidgetPage() {
    const { t } = useTranslation();
    const { isMobile } = useResponsive();
    const theme = useTheme();
    const swrConfig = useSWRConfig();
    const [reMutateUrls, setReMutateUrls] = React.useState(false);
    const [activeItem, setActiveItem] = React.useState(null);
    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
    const open = Boolean(anchorEl);
    const id = open ? "simple-popover" : undefined;
    const [editDialog, setEditDialog] = useState<any>({ open: false, delete: false, itemToEdit: null });
    const swrQuickActions = useQuickActions();

    const handleClickPopover = (value: any, event: React.MouseEvent<HTMLButtonElement>) => {
        setActiveItem(value);
        setAnchorEl(event.currentTarget);
    };

    const closeHandler = () => {
        setEditDialog({ open: false, delete: false, itemToEdit: activeItem });
        handleClosePopover();
    }

    const saveHandler = () => {
        closeHandler();
        setReMutateUrls(true);
    }

    const openDialog = (dataItem: any) => {
        if (!dataItem) {
            return;
        }

        if (!dataItem.formIteration) {
            dataItem.formIteration = "edit" + dataItem.id;
        }

        setEditDialog({ open: true, itemToEdit: dataItem, delete: false });
    }

    const deleteDialog = (dataItem: any) => {
        if (!dataItem) {
            return;
        }
        if (!dataItem.formIteration) {
            dataItem.formIteration = "delete" + dataItem.id;
        }
        setEditDialog({ open: true, itemToEdit: dataItem, delete: true });
    }

    const createItem = () => {
        openDialog(getQuickActionDefaultItem());
    }

    const editItem = () => {
        openDialog(activeItem);
    }

    const deleteItem = () => {
        deleteDialog(activeItem);
    }

    const handleClosePopover = () => {
        setAnchorEl(null);
    };

    const onDragEnd = (result: any) => {
        if (!result.destination) {
            return;
        }

        const items: any = reorder(
            swrQuickActions.result,
            result.source.index,
            result.destination.index
        );

        swrQuickActions.result = items;
        bulkUpdateQuickActions(swrQuickActions.result);
    };

    const reorder = (list: any, startIndex: any, endIndex: any) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);

        for (let index = 0; index < result.length; index++) {
            const element: any = result[index];
            element.displayOrder = index;
        }

        return result;
    };

    const getDraggableItemStyle = (isDragging: boolean, draggableStyle: any) => ({
        userSelect: "none",
        background: isDragging ? theme.palette.info.light : "inherit",
        color: isDragging ? theme.palette.info.contrastText : "inherit",
        ...draggableStyle,
    });

    React.useLayoutEffect(() => {
        if (reMutateUrls) {
            mutateUrls(swrConfig);
            setReMutateUrls(false);
        }
    }, [reMutateUrls, swrConfig]);

    return (
        <EnsureUserAndModule permissions={["QuickActions:Modify"]}>
            <Typography fontSize="14px" color="grey" sx={{ mb: 1, mt: isMobile && 2 }}>
                {t("Select which tiles you want to appear on the Quick Actions widget on the dashboard. You can select from existing tiles or add new tiles.")}
            </Typography>
            <Button
                data-cy-id={"quick_actions_admin_add_button"}
                variant="contained"
                startIcon={<AddIcon />}
                sx={{ mb: 2 }}
                onClick={createItem}
                title={t("Add new tile")}>
                {t("Add new tile")}
            </Button>
            <UcLoading isLoading={swrQuickActions.isLoading} hasFade={false} height={"400px"}>
                {swrQuickActions?.result?.length < 1 && (
                    <Alert severity="warning">{t("No quick action tiles have been created! Add a new tile now to get started.")}</Alert>
                )}
                <DragDropContext onDragEnd={onDragEnd}>
                    <Droppable droppableId="droppable">
                        {(provided: any) => (
                            <div
                                {...provided.droppableProps}
                                ref={provided.innerRef}
                            >
                                {swrQuickActions.result?.map(
                                    (item: any, index: any) => {
                                        return (
                                            <Draggable
                                                key={item.id}
                                                draggableId={item.id.toString()}
                                                index={index}
                                            >
                                                {(provided: any, snapshot: any) => (
                                                    <Card
                                                        sx={{ mt: 1 }}
                                                        elevation={3}
                                                        key={`entry_${item.id}`}
                                                        ref={provided.innerRef}
                                                        style={getDraggableItemStyle(
                                                            snapshot.isDragging,
                                                            provided.draggableProps
                                                                .style
                                                        )}
                                                        {...provided.draggableProps}
                                                        {...provided.dragHandleProps}
                                                    >
                                                        <CardContent>
                                                            <Grid container spacing={2}>
                                                                <Grid item xs>
                                                                    <Typography variant="h6">
                                                                        {item.title}
                                                                    </Typography>
                                                                    <Typography variant="caption">
                                                                        {item.description}
                                                                    </Typography>
                                                                </Grid>
                                                                <Grid item sx={{ display: "flex" }}>
                                                                    <Tooltip title={item.isDisplayed ? t("Tile is visible in widget.") : t("Tile is hidden in widget.")}>
                                                                        <IconButton
                                                                            sx={{ mr: 1 }}
                                                                            aria-label="actions"
                                                                            aria-describedby={id}
                                                                            disableRipple={true}
                                                                        >
                                                                            {item.isDisplayed && (
                                                                                <VisibilityIcon style={{ color: 'green' }} />
                                                                            )}
                                                                            {!item.isDisplayed && (
                                                                                <VisibilityOffIcon />
                                                                            )}
                                                                        </IconButton>
                                                                    </Tooltip>
                                                                    <Button
                                                                        sx={{
                                                                            my: "auto",
                                                                            textAlign: 'center',
                                                                            width: 130
                                                                        }}
                                                                        variant="contained"
                                                                        fullWidth
                                                                        size="small"
                                                                        title={item.buttonName}
                                                                        href={item.buttonLink}
                                                                        target="_blank"
                                                                    >
                                                                        {item.buttonName}
                                                                    </Button>
                                                                    <IconButton
                                                                        data-cy-id={"quick_actions_admin_popover_button_" + item.id}
                                                                        aria-label="actions"
                                                                        aria-describedby={item.title}
                                                                        onClick={(e) => handleClickPopover(item, e)}
                                                                    >
                                                                        <MoreVertIcon />
                                                                    </IconButton>
                                                                </Grid>
                                                            </Grid>
                                                        </CardContent>
                                                    </Card>
                                                )}
                                            </Draggable>
                                        );
                                    }
                                )}
                                {provided.placeholder}
                            </div>
                        )}
                    </Droppable>
                </DragDropContext>
            </UcLoading>          
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClosePopover}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                }}
            >
                <MenuList>
                    <MenuItem onClick={editItem}>
                        <ListItemIcon>
                            <EditIcon fontSize="small" color="success" data-cy-id={"quick_actions_admin_popover_edit_button"} />
                        </ListItemIcon>
                        <ListItemText title={t("Edit")}>{t("Edit")}</ListItemText>
                    </MenuItem>
                    <MenuItem onClick={deleteItem}>
                        <ListItemIcon>
                            <DeleteIcon color="error" fontSize="small" />
                        </ListItemIcon>
                        <ListItemText title={t("Delete")}>{t("Delete")}</ListItemText>
                    </MenuItem>
                </MenuList>
            </Popover>
            <QuickActionsWidgetFormDialog
                id={editDialog?.itemToEdit?.id}
                open={editDialog.open}
                delete={editDialog.delete}
                itemToEdit={editDialog.itemToEdit}
                onCancel={closeHandler}
                onSave={saveHandler}
                onError={(error: any) => { console.error(error); }}
                dialogText={(editDialog.itemToEdit !== null && editDialog.itemToEdit.id) > 0 ? t("Edit") : t("Add")}
            />
        </EnsureUserAndModule>
    );
}
