import React, { useEffect, useContext } from 'react';
import { PageContext } from '../lib/PageProvider';
import { SignInButton } from './auth/SignInButton';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import { UcThemeContext } from '../lib/UcThemeProvider';
import { useTheme } from '@mui/material/styles';
import { useTranslation } from "react-i18next";
import { useAuthUser } from './auth/useAuthUser';
import { routePaths } from 'routes';
import { useDomainNavigate } from "domain";
import { UcLoading } from './common/UcLoading';
import OutageMapWidget from './widgets/OutageMapWidget';
import { useResponsive } from 'lib/useResponsive';
import { OrgAppSettingsContext } from 'lib/OrgAppSettingsProvider';

export const LandingPage = (props) => {
    const { t } = useTranslation();
    const theme = useTheme();
    const auth = useAuthUser();
    const navigate = useDomainNavigate();
    const { isMobile } = useResponsive();

    const [themeState] = useContext(UcThemeContext);
    const [pageState, pageDispatch] = useContext(PageContext);
    const { orgAppSettings } = React.useContext(OrgAppSettingsContext);

    let outageMapUrl = "";
    let modules;

    if (orgAppSettings) {
        // @ts-ignore
        outageMapUrl = orgAppSettings?.result?.outageMapUrl;
        const moduleString = orgAppSettings?.result?.modules;
        modules = moduleString ? orgAppSettings?.result?.modules?.split(',') : '';
    }

    if (!auth.isLoading && auth.isLoggedIn)
    {
        // redirect to home page... we are logged in
        navigate(routePaths.homePage.path);
    }

    const handleOnClickRegister = () => {
        navigate(routePaths.accountAuthorizationPage.path);
    }

    const showOutageMap = outageMapUrl && modules.includes('OutageManual');

    useEffect(() => {
        pageDispatch({
            header: "Landing",
        });
    }, [pageState.header, pageDispatch]);

	return (
        <UcLoading isLoading={auth.isLoading || window.isFirstAccess} >
            <div
                data-cy-id={"landingPage"}
                className="landingPage"
                style={{ color: theme.props?.LandingPage?.color }}
            >
                <Grid container direction="row">
                    <Grid item xs={12} sm={showOutageMap ? 5 : 12} sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        mt: !showOutageMap && "100px"
                    }}>
                        <h1>
                            <img
                                style={{ width: '100%', height: 'auto' }}
                                src={theme.props?.LandingPage !== undefined ? theme.props?.LandingPage?.logo : `${process.env.PUBLIC_URL}/themes/${themeState.logo}`}
                                alt={t("CEP Logo")}
                                className="responsiveSplash-img__logo"
                            />
                        </h1>
                        <SignInButton data-cy-id={"landingPage-signIn"} isLandingPage={true} style={{ marginBottom: "2em", maxWidth: showOutageMap ? "70%" : "295px" }} />
                        <Button
                            fullWidth
                            variant={"contained"}
                            style={{ marginBottom: isMobile && "2em", maxWidth: showOutageMap ? "70%" : "295px" }}
                            onClick={handleOnClickRegister}
                        >
                            {t('Register Account')}
                        </Button>                           
                    </Grid>
                    {showOutageMap && (
                        <Grid item xs={12} sm={7}>
                            <OutageMapWidget mobileHeight="500px" isLandingPage={true} />
                        </Grid>
                    )}                   
                </Grid>
            </div>
        </UcLoading>
	);
};
