import { useEffect } from 'react';
import { useAuth } from 'react-oidc-context';

export const SilentRenew = () => {
  const auth = useAuth();

  useEffect(() => {
    auth.startSilentRenew();
  }, [auth]);

  return <div>Loading...</div>;
};