import { orgEmailSettingsValidationSchema, useOrgEmailSettings, saveOrgEmailSettings } from './OrgEmailSettingAPIEndpoints';
import PropTypes from 'prop-types';
import { Formik, Form, Field } from 'formik';
import { useRef, useLayoutEffect, useState } from 'react';
import { TextField } from 'formik-mui';
import { mutateUrls } from "lib/useGenericSWR";
import { useSWRConfig } from "swr";
import { useTranslation } from 'react-i18next';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Button, Grid } from '@mui/material';

/**
 * This component handles the form for creating and editing
 * */
export const OrgEmailSettingForm = (props) => {
    const { t } = useTranslation();
    const swrConfig = useSWRConfig();
    const [reMutateUrls, setReMutateUrls] = useState(false);

    const { cepOrgId } = props;
    const swrEmailSettings = useOrgEmailSettings(cepOrgId);
    const formRef = useRef();
   
    let initialValues = null;
    if (!swrEmailSettings.isLoading && swrEmailSettings.result.length > 0) {
        initialValues = { ...swrEmailSettings.result[0] };
    }
    else {
        initialValues = { 
            id: 0, 
            fromEmail: '', 
            fromName: ''
        };
    }

    useLayoutEffect(() => {
        if (reMutateUrls)
        {
            mutateUrls(swrConfig);
            setReMutateUrls(false);
        }
    }, [reMutateUrls, swrConfig]);

    return (
        <div className='ignoreCustomFormStyles'>
           <h1 className="pageHeader" style={{ marginBottom: 18 }}>
               Email Settings                  
                <Tooltip title={t("Specify the email used as the \"From Address\" and the \"Name\" displayed within your Email Template signatures")}>
                    <IconButton color="info">
                        <InfoOutlinedIcon />
                    </IconButton>
                </Tooltip>
            </h1>
            {!swrEmailSettings.isLoading && (
                <Formik
                    innerRef={formRef}
                    initialValues={initialValues}
                    validationSchema={orgEmailSettingsValidationSchema}
                    onSubmit={async (values) => {
                        values.cepOrgId = cepOrgId;

                        let data =  await saveOrgEmailSettings(values);

                        if (data) {
                            setReMutateUrls(true);
                        }
                    }}
                >
                    <Form>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={12} md={6}>
                                <Field
                                    name={"fromEmail"}
                                    component={TextField}
                                    label={t("From Email")}
                                />
                        </Grid>
                            <Grid item xs={12} sm={12} md={6}>
                                <Field
                                    name={"fromName"}
                                    component={TextField}
                                    label={t("From Name")}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={12}>
                                <Button 
                                    type="submit"
                                    color="primary"
                                    variant="contained"
                                >
                                    {t("Save")}
                                </Button>
                            </Grid>
                        </Grid>
                    </Form>
                </Formik>
            )}
        </div>
    );
}

OrgEmailSettingForm.defaultProps = {
    id: 0,
    dialogSubmit: 0,
    changeIteration: 0,
    onSubmit: (values) => { console.log("onSubmit not implemented", values); },
    onError: (error) => { console.error("onError not implemented", error); },
};

OrgEmailSettingForm.propTypes = {
    id: PropTypes.number,
    changeIteration: PropTypes.number,
    dialogSubmit: PropTypes.number,
};