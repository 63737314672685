import React, { useState } from 'react';
import LoadingButton from '@mui/lab/LoadingButton';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Paper from '@mui/material/Paper';
import Draggable from 'react-draggable';
import PropTypes from 'prop-types';
import { HolidayForm } from './HolidayForm';
import { saveHoliday, deleteHoliday, getHolidaysDefaultItem } from './HolidayAPIEndpoints';
import { useResponsive } from 'lib/useResponsive';
import { useTranslation } from "react-i18next";

function PaperComponent(props) {
    return (
        <Draggable
            handle="#draggable-dialog-title"
            cancel={'[class*="MuiDialogContent-root"]'}
        >
            <Paper {...props} />
        </Draggable>
    );
}

/*
 * This component handles the form for creating and editing
 */
export const HolidayFormDialog = (props) => {
    const { t } = useTranslation();
    const [saving, setSaving] = React.useState(false);
    const [dialogSubmit, setDialogSubmit] = useState(0);
    const { isMobile } = useResponsive();

    let formId = props.id;
    if (!props.open) {
        formId = 0;
    }

    const handleCancel = () => {
        props.onCancel();
    };

    const handleSave = () => {
        setDialogSubmit(dialogSubmit + 1);
    };

    const handleDelete = async () => {
        let data = await deleteHoliday(props.itemToEdit);

        if (data) {
            props.onSave(data);
        }
    };

    return (
        <>
            {props.delete && props.open &&
                <Dialog
                    fullScreen={isMobile}
                    fullWidth={true}
                    maxWidth={"sm"}
                    open={props.open}
                    PaperComponent={PaperComponent}
                    aria-labelledby="draggable-dialog-title"
                >
                    <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
                        {t("Delete Holiday")}
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            {t('Are you sure you want to delete this holiday?')}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button autoFocus onClick={handleCancel} data-cy-id={"cancel_delete_button"}>
                            {t("Cancel")}
                        </Button>
                        <Button onClick={handleDelete} data-cy-id={"delete_button"}>
                            {t("Delete")}
                        </Button>
                    </DialogActions>
                </Dialog>
            }
            {!props.delete && !props.setDefault && props.open &&
                <Dialog
                    fullScreen={isMobile}
                    fullWidth={true}
                    maxWidth={"md"}
                    open={props.open}
                    PaperComponent={PaperComponent}
                    aria-labelledby="draggable-dialog-title"
                >
                    <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
                        {t("Holiday")}
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            <HolidayForm
                                id={formId}
                                itemToEdit={props.itemToEdit}
                                dialogSubmit={dialogSubmit}
                                onSubmit={async (values) => {
                                    setSaving(true);
                                    try {
                                        let data = await saveHoliday(values);

                                        if (data) {
                                            props.onSave(data);
                                        }
                                    } finally {
                                        setSaving(false);
                                    }
                                }}
                                onError={(error) => {
                                    props.onError(error);

                                    setSaving(false);
                                }}
                            />
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button 
                            autoFocus onClick={handleCancel} 
                            data-cy-id={"cancel_button"}
                            variant="outlined"
                        >
                            {t("Cancel")}
                        </Button>
                        <LoadingButton
                            loading={saving}
                            onClick={handleSave}
                            variant="contained"
                            data-cy-id={"save_button"}
                        >
                            {t("Save")}
                        </LoadingButton>
                    </DialogActions>
                </Dialog>
            }
        </>
    );
}

HolidayFormDialog.defaultProps = {
    open: false,
    id: 0,
    itemToEdit: getHolidaysDefaultItem(),
    onCancel: () => { console.log("onCancel not implemented"); },
    onSave: (data) => { console.log("onSave not implemented"); },
    onError: (error) => { console.error("onError not implemented", error); },
};

HolidayFormDialog.propTypes = {
    open: PropTypes.bool,
    id: PropTypes.number,
    itemToEdit: PropTypes.object,
};