import React from 'react';
import { useAuth } from "react-oidc-context";
import { useDomainNavigate } from "domain";
import { useTranslation } from "react-i18next";
import { PageContext } from 'lib/PageProvider';
import { useResponsive } from 'lib/useResponsive';
import { routePaths } from 'routes';
import { AccountVerificationStep1 } from './AccountVerificationStep1';
import { AccountVerificationStep2 } from './AccountVerificationStep2';
import { AccountVerificationStep3 } from './AccountVerificationStep3';
import { AccountVerificationStep4 } from './AccountVerificationStep4';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import MobileStepper from '@mui/material/MobileStepper';
import Paper from '@mui/material/Paper';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Typography from '@mui/material/Typography';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import { useTheme } from '@mui/material/styles';
import LoadingButton from '@mui/lab/LoadingButton';
import { SignInButton } from '../auth/SignInButton';

export const AccountVerificationPage = (props) => {
    const { isRenderedInDialog, onCancel, onReset, onDone } = props;
    const auth = useAuth();
    const theme = useTheme();
    const { t } = useTranslation();
    const { isMobile } = useResponsive();
    const navigate = useDomainNavigate();
    const [dialogSubmit, setDialogSubmit] = React.useState(0);
    const [account, setAccount] = React.useState(null);
    const [method, setMethod] = React.useState(null);
    const [code, setCode] = React.useState(null);
    const [pageState, pageDispatch] = React.useContext(PageContext);
    const [alertSeverity, setAlertSeverity] = React.useState('info');
    const [alertText, setAlertText] = React.useState('Loading...');
    const [showDone, setShowDone] = React.useState(false);
    const [showLogin, setShowLogin] = React.useState(false);
    const [disableNextBtn, setDisableNextBtn] = React.useState(false);
    const [isLoadingNextStep, setIsLoadingNextStep] = React.useState(false);

    const handleOnClickBack = () => {
        if (isRenderedInDialog) {
            onCancel();
        }
        else {
            navigate(routePaths.accountAuthorizationPage.path);
        }
    }

    const handleSubmit = () => {
        setDialogSubmit(dialogSubmit + 1);
    };

    React.useEffect(() => {
        pageDispatch({
            header: "Account Verification",
        });
    }, [pageState.header, pageDispatch]);

    const [activeStep, setActiveStep] = React.useState(0);

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setDisableNextBtn(false);
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleReset = () => {
        setAccount(null);
        setDisableNextBtn(false);
        setActiveStep(0);
        onReset();
    };

    const steps =  React.useMemo(() => {
       return [
            {
                label: t('Account Lookup'),
                component: (
                    <AccountVerificationStep1
                        dialogSubmit={dialogSubmit}
                        onIsLoading={(value) => {
                            setIsLoadingNextStep(value);
                        }}
                        onNext={async (result) => {
                            setAccount(result);
                            handleNext();
                        }}
                    />
                )
            },
            {
                label: t('Verification Method'),
                component: (
                    <AccountVerificationStep2
                        dialogSubmit={dialogSubmit}
                        account={account}
                        onDisableNextBtn={() => {
                            setDisableNextBtn(true);
                        }}
                        onIsLoading={(value) => {
                            setIsLoadingNextStep(value);
                        }}
                        onNext={async (value) => {
                            setMethod(value);
                            handleNext();
                        }}
                    />
                )
            },
            {
                label: t('Verify'),
                component: (
                    <AccountVerificationStep3
                        dialogSubmit={dialogSubmit}
                        account={account}
                        method={method}
                        onIsLoading={(value) => {
                            setIsLoadingNextStep(value);
                        }}
                        loading={isLoadingNextStep}
                        onNext={async (values) => {
                            setAccount({...account, result: {...account.result, email: values.accountEmail}});
                            setCode(values.code);
                            handleNext();
                        }}
                    />
                )
            },
            {
                label: t('Link Account'),
                component: (
                    <AccountVerificationStep4
                        dialogSubmit={dialogSubmit}
                        account={account}
                        code={code}
                        onIsLoading={(value) => {
                            setIsLoadingNextStep(value);
                        }}
                        onNext={async (alertSettings) => {
                            setAlertText(alertSettings.text);
                            setAlertSeverity(alertSettings.severity);
                            if (auth.isAuthenticated) {
                                setShowDone(true);
                            }
                            else {                               
                                setAlertText(alertSettings.text);
                                if (!alertSettings.isNewUser) {
                                    setAlertText(alertSettings.text + t(' You may login to your account to continue.'));
                                }
                                setShowLogin(true);
                            }

                            handleNext();
                        }}
                    />
                )
            }
        ];
    }, [t, dialogSubmit, account, method, code, auth.isAuthenticated, isLoadingNextStep]);

    let paperTheme = theme.palette.mode === 'dark' && "background.default";

    return (
        <Paper sx={{ width: '100%', p: (isMobile ? 0 : 2), backgroundColor: paperTheme}} elevation={isRenderedInDialog ? 0 : 3}>
            {isMobile ? (
                activeStep === steps.length ? (
                    <Box sx={{ width: '100%', p: 2 }}>
                        <Alert severity={alertSeverity}>
                            {alertText}
                        </Alert>

                        <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                            <Box sx={{ flex: '1 1 auto' }} />
                            <Button onClick={handleReset}>{t('Add another account')}</Button>
                            {showLogin && (
                                <SignInButton isLandingPage={false} variant={'contained'} />
                            )}
                            {showDone && (
                                <Button
                                    fullwidth
                                    variant="contained"
                                    onClick={() => {
                                        onDone();
                                    }}
                                >
                                {t('Done')}
                                </Button>
                            )}
                        </Box>
                    </Box>
                ) : (
                    <>
                        <Paper
                            square
                            elevation={0}
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                height: 50,
                                pl: 2,
                                bgcolor: 'background.default'
                            }}
                        >
                            <Typography>{steps[activeStep].label}</Typography>
                        </Paper>
                        <Box sx={{ minHeight: 255, maxWidth: 400, width: '100%', p: 2 }}>
                            {steps[activeStep].component}
                        </Box>
                        <MobileStepper
                            variant="progress"
                            steps={steps.length}
                            position="static"
                            activeStep={activeStep}
                            nextButton={
                                <>
                                    {activeStep <= 2 && (
                                        <LoadingButton
                                            size="small"
                                            loading={isLoadingNextStep}
                                            onClick={handleSubmit}
                                            variant={theme.props?.CancelButton?.variant}
                                            disabled={(activeStep === steps.length - 1) || disableNextBtn}
                                        >
                                            {t("Next")}
                                            {theme.direction === 'rtl' ? (
                                            <KeyboardArrowLeft />
                                            ) : (
                                            <KeyboardArrowRight />
                                            )}
                                        </LoadingButton>
                                    )}
                                    {activeStep === 3 && (
                                        <Button
                                            size="small"
                                            onClick={handleSubmit}
                                            color='primary'
                                            variant={theme.props?.CancelButton?.variant}
                                        >
                                            {t("Link Account")}
                                        </Button>
                                    )}
                                </>
                            }
                            backButton={
                                <Button
                                    size="small"
                                    variant={theme.props?.CancelButton?.variant} color={theme.props?.CancelButton?.color}
                                    onClick={activeStep === 0 ? handleOnClickBack : handleBack}
                                >
                                    {theme.direction === 'rtl' ? (
                                        <KeyboardArrowRight />
                                        ) : (
                                        <KeyboardArrowLeft />
                                    )}
                                    {t('Back')}
                                </Button>
                            }
                        />
                    </>
                )
            ) : (
                <>
                    <Stepper activeStep={activeStep} sx={{ mb: 4 }}>
                        {steps.map((step, index) => {
                            const stepProps = {};
                            const labelProps = {};
                            return (
                                <Step key={step.label} {...stepProps}>
                                    <StepLabel {...labelProps}>{step.label}</StepLabel>
                                </Step>
                            );
                        })}
                    </Stepper>
                    {activeStep === steps.length ? (
                        <>
                            <Alert severity={alertSeverity}>
                                {alertText}
                            </Alert>

                            <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                                <Box sx={{ flex: '1 1 auto' }} />
                                <Button onClick={handleReset}>{t('Add another account')}</Button>
                                {showLogin && (
                                    <SignInButton isLandingPage={false} variant={'contained'} />
                                )}
                                {showDone && (
                                    <Button fullwidth variant="contained" onClick={onDone}>{t('Done')}</Button>
                                )}
                            </Box>
                        </>
                    ) : (
                        <>
                            {steps[activeStep].component}
                            <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                                <Button

                                    onClick={activeStep === 0 ? handleOnClickBack : handleBack}
                                    variant={theme.props?.CancelButton?.variant}
                                    sx={{ mr: 1 }}
                                >
                                    {t('Back')}
                                </Button>
                                <Box sx={{ flex: '1 1 auto' }} />
                                {activeStep <= 2 && (
                                    <LoadingButton
                                        loading={isLoadingNextStep}
                                        disabled={(activeStep === steps.length - 1) || disableNextBtn}
                                        onClick={handleSubmit}
                                        variant={theme.props?.CancelButton?.variant}
                                    >
                                        {t("Next")}
                                    </LoadingButton>
                                )}
                                {activeStep === 3 && (
                                    <LoadingButton
                                        loading={isLoadingNextStep}
                                        onClick={handleSubmit}
                                        color='primary'
                                        variant='contained'
                                    >
                                        {t("Link Account")}
                                    </LoadingButton>
                                )}
                            </Box>
                        </>
                    )}
                </>
            )}
        </Paper>
    );
}