import { ApiClient } from "../../lib/ApiClient";
import { useGenericSWR } from "../../lib/useGenericSWR";
import { mutateMyUserAccounts } from './MyUserAccountAPIEndpoints';
import * as Yup from 'yup';

const apiEndpoint = 'api/v1/MyDelegatedAccount';
//new endpoint

let formIteration = 0;

const getDelegatedAccountsDefaultItem = () => {
    formIteration = formIteration + 1;
    return {
        formIteration: "new" + formIteration,
        id: 0,
    }
};

const DelegatedAccountsValidationSchema = Yup.object().shape({

    accountId: Yup.number().required("Required").integer(),
    userId: Yup.number().required("Required").integer(),
});

const apiClient = new ApiClient();
apiClient.getDefaultItem = () => getDelegatedAccountsDefaultItem();
apiClient.getObjectName = () => {
    return "My Delegated Account";
};

const useDelegatedAccounts = (filter, swrOptions = {}) => {
    // SWR will prevent duplicate requests
    // It will also maintain data state as long as your are using the same urls for your requests
    const params = new URLSearchParams();
    params.append('filter', JSON.stringify(filter));
    return useGenericSWR(apiEndpoint + "?" + params.toString(), apiClient, { ...swrOptions });
};

const saveDelegatedAccount = (MyDelegatedAccount) => {
    if (MyDelegatedAccount.id === 0) {
        return insertDelegatedAccount(MyDelegatedAccount);
    }
    return updateDelegatedAccount(MyDelegatedAccount);
}
const insertDelegatedAccount = (MyDelegatedAccount) => {
    return apiClient.poster(apiEndpoint, MyDelegatedAccount);
};

const updateDelegatedAccount = (MyDelegatedAccount) => {
    return apiClient.putter(apiEndpoint, MyDelegatedAccount);
};

const deleteDelegatedAccount = (MyDelegatedAccount) => {
    return apiClient.deleter(apiEndpoint + "/" + MyDelegatedAccount.id)
    .then((result) => {
        mutateMyUserAccounts();
        return result;
});
};

export {
    getDelegatedAccountsDefaultItem,
    DelegatedAccountsValidationSchema,
    useDelegatedAccounts,
    saveDelegatedAccount,
    insertDelegatedAccount,
    updateDelegatedAccount,
    deleteDelegatedAccount
};