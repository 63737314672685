import { getUserAccountsDefaultItem, UserAccountsValidationSchema } from './MyUserAccountAPIEndpoints';
import PropTypes from 'prop-types';
import { Formik, Form, Field } from 'formik';
import { useRef, useEffect, useState } from 'react';
import { UserSelect } from 'components/admin/Users/UserSelect';
import { AccountSelect } from 'components/customer/accounts/AccountSelect';
import { useTranslation } from "react-i18next";

export const MyUserAccountForm = (props) => {
    const { t } = useTranslation();
    const itemToEdit = props.itemToEdit || getUserAccountsDefaultItem();
    const dialogSubmit = useRef(props.dialogSubmit);
    const [selectedUser, setSelectedUser] = useState({id: itemToEdit.userId || -1, username: itemToEdit.username || ""})
    const [selectedAccount, setSelectedAccount] = useState({id: itemToEdit.accountId || -1, accountName: itemToEdit.accountName || ""})
    const formRef = useRef();

    useEffect(() => {
        if (props.dialogSubmit !== dialogSubmit.current) {
            dialogSubmit.current = props.dialogSubmit;
            if (formRef.current) {
                if (props.accountId) {
                    formRef.current.values.accountId = props.accountId;
                    formRef.current.values.userId = selectedUser.id;
                }
                else {
                    formRef.current.values.accountId = selectedAccount.id
                    formRef.current.values.userId = props.userId;
                }
                formRef.current.handleSubmit();
            }
        }
    });

    return (
        <>
        <Formik
            innerRef={formRef}
            initialValues={itemToEdit}
            validationSchema={UserAccountsValidationSchema}
            onSubmit={(values) => {
                if (props.accountId) {
                    values.accountId = props.accountId;
                }
                else if (props.userId) {
                    values.userId = props.userId;
                }
                props.onSubmit(values);
            }}
        >
        <Form>
            {props.accountId &&
            <Field
                name={"userId"}
                initialValue={selectedUser}
                component={ UserSelect }
                label={t("User")}
                onChange={(event, value) => {
                    setSelectedUser(value);
                }}
            />
            }
          
            <Field
                name={"accountId"}
                initialValue={selectedAccount}
                component={ AccountSelect }
                label={t("Account")}
                isAdmin = {false}
                onChange={(event, value) => {
                    setSelectedAccount(value);
                }}
            />
            
        </Form>
        </Formik>
        </>
    );
}

MyUserAccountForm.defaultProps = {
    id: 0,
    dialogSubmit: 0,
    changeIteration: 0,
    onSubmit: (values) => { console.log("onSubmit not implemented", values); },
    onError: (error) => { console.error("onError not implemented", error); },
};

MyUserAccountForm.propTypes = {
    id: PropTypes.number,
    changeIteration: PropTypes.number,
    dialogSubmit: PropTypes.number,
};