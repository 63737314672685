import React, { useState, useContext, useEffect, useLayoutEffect } from 'react';
import { PageContext } from '../../../lib/PageProvider';
import { RateClassGridStateful } from "./RateClassGridStateful"
import { RateClassFormDialog } from './RateClassFormDialog';
import { mutateUrls } from "../../../lib/useGenericSWR";
import { useSWRConfig } from "swr";
import './RateClassListPage.scss';
import { EnsureUser } from '../../auth/EnsureUser';
import { UcToolbar } from '../../common/UcToolbar';
import { UcListPage } from 'components/common/UcListPage';
import { useHasPermission } from 'components/auth/PermissionApiEndpoint';
import AddIcon from '@mui/icons-material/Add';
import { getRateClassesDefaultItem, rateClassName, rateClassesName } from './RateClassAPIEndpoints';
import { useTranslation } from "react-i18next";
import { Box, Divider, Drawer } from '@mui/material';
import { UcDrawerHeader } from 'components/common/UcDrawerHeader';
import { RateClassDetailsPage } from './RateClassDetailsPage';

export const RateClassListPage = (props) => {
    const { t } = useTranslation();
    const [editDialog, setEditDialog] = useState({ open: false, delete: false });
    const [selectedItem, setSelectedItem] = useState(null);
    const [pageState, pageDispatch] = useContext(PageContext);
    const [generalSearch, setGeneralSearch] = useState('');
    const [disableItemEdit, setDisableItemEdit] = useState(false);
    const [drawerOpen, setDrawerOpen] = useState(false);
    const drawerWidth = "50vw";
    const [reMutateUrls, setReMutateUrls] = useState(false);
    const swrConfig = useSWRConfig();

    const closeHandler = () => {
        setEditDialog({ open: false, itemToEdit: editDialog.dataItem });
    }

    const saveHandler = () => {
        closeHandler();
        setReMutateUrls(true);
    }

    const handleDrawerClose = () => {
        setDrawerOpen(false);
    }

    const openDialog = (dataItem) => {
        if (!dataItem) {
            return;
        }
        if (!dataItem.formIteration) {
            dataItem.formIteration = "edit" + dataItem.id;
        }
        setEditDialog({ open: true, itemToEdit: dataItem, delete: false });
        editRateClassType(dataItem);
    }

    const deleteDialog = (dataItem) => {
        if (!dataItem) {
            return;
        }
        if (!dataItem.formIteration) {
            dataItem.formIteration = "delete" + dataItem.id;
        }
        setEditDialog({ open: true, itemToEdit: dataItem, delete: true, setDefault: false });
    }

    const createItem = () => {
        openDialog(getRateClassesDefaultItem());
    }

    const editRateClassType = (dataItem) => {
        if (dataItem.rateTotal >= 1) {
            setDisableItemEdit(true);
        }
        else
            setDisableItemEdit(false);
    }

    const editItem = (item) => {
        openDialog(item);
    }

    const deleteItem = (item) => {
        deleteDialog(item);
    }

    const actions = [
        {
            icon: <AddIcon data-cy-id={"add_rate_class"}/>,
            title: t('Add {{rateClassName}}', {rateClassName: rateClassName}),
            toolTip: t('Add {{rateClassName}}', {rateClassName: rateClassName}),
            onClick: () => { createItem(); },
            isHidden: !useHasPermission("RateClass:Modify"),
        }
    ];

    useLayoutEffect(() => {
        if (reMutateUrls) {
            mutateUrls(swrConfig);
            setReMutateUrls(false);
        }
    }, [reMutateUrls, swrConfig]);

    useEffect(() => {
        pageDispatch({
            header: rateClassesName,
        });
    }, [pageState.header, selectedItem, pageDispatch]);

    console.log("RateClassListPage - Loaded");
    return (
        <UcListPage>
            <EnsureUser permissions={["RateClass:Read"]}>
                <h1 className="pageHeader">{rateClassesName}</h1>
                    <UcToolbar
                        hasGeneralSearch={true}
                        onGeneralSearchChange={(value) => {
                            setGeneralSearch(value);
                        } }
                        actions={actions}></UcToolbar>
                <RateClassFormDialog
                    open={editDialog.open}
                    delete={editDialog.delete}
                    itemToEdit={editDialog.itemToEdit}
                    onCancel={closeHandler}
                    onSave={saveHandler}
                    disableItemEdit={disableItemEdit}
                />
                {!reMutateUrls &&
                    <RateClassGridStateful
                        onEditItem={editItem}
                        onDeleteItem={deleteItem}
                        generalSearch={generalSearch}
                        onSelected={(selectedRateItem) => setSelectedItem({ ...selectedRateItem })}
                        setDrawerOpen={setDrawerOpen}
                    />
                }
                <Drawer
                    anchor="right"
                    open={drawerOpen}
                    onClose={handleDrawerClose}
                >
                    <UcDrawerHeader justify="flex-start" />
                    <Box
                        sx={{ width: drawerWidth, margin: '10px' }}
                        role="presentation"
                    >
                        <Divider />
                        {selectedItem &&
                            <RateClassDetailsPage rateClassId={selectedItem.id} />
                        }
                    </Box>
                </Drawer>
            </EnsureUser>
        </UcListPage>
    );
};