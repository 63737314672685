import React, { useContext, useEffect } from 'react';
import { UcAdminDetails } from "../../common/UcAdminDetails";
import { PageContext } from '../../../lib/PageProvider';
import {
    useParams,
} from "react-router-dom";
import { EnsureUser } from '../../auth/EnsureUser';
import { useHasPermission } from 'components/auth/PermissionApiEndpoint';
import { OrgUomListPage } from './OrgUomListPage';
import { OrgEmailSettingForm } from './OrgEmailSettingForm';
import OrgFaqSettingsForm from './OrgFaqSettingsForm';
import { useTranslation } from "react-i18next";

export const OrgDetailsPage = (props) => {
    const { t } = useTranslation();
    let { cepOrgId } = useParams();
    cepOrgId = cepOrgId || props.cepOrgId;

    const params = new URLSearchParams(window.location.search);
    const orgParamName = params.get("name");
    const [pageState, pageDispatch] = useContext(PageContext);

    useEffect(() => {
        pageDispatch({
            header: t("Organization Details"),
            variableHeader: orgParamName,
            actionItems: []
        });
    }, [pageState.header, pageDispatch, orgParamName, t]);

    let detailsRender = <></>;

    let tabs = [
        {
            title: t("Unit of Measure"),
            contentRender: <OrgUomListPage cepOrgId={cepOrgId} />,
            isHidden: !useHasPermission("OrgUom:Read")
        },
        {
            title: t("Email Settings"),
            contentRender: <OrgEmailSettingForm cepOrgId={cepOrgId} />,
            isHidden: !useHasPermission("OrgEmailSetting:Read")
        },
        {
            title: t("FAQ Settings"),
            contentRender: <OrgFaqSettingsForm cepOrgId={cepOrgId} />,
            isHidden: !useHasPermission("OrgEmailSetting:Read")
        },
    ];

    return (
        <EnsureUser permissions={["OrgUom:Read", "OrgEmailSetting:Read"]}>
            <UcAdminDetails detailsRender={detailsRender} tabs={tabs} tabInitValue={"Unit of Measure"} />
        </EnsureUser>
    );
};