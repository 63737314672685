import { UcWidget } from 'components/common/UcWidget';
import { useLayoutEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { UcLoading } from 'components/common/UcLoading';
import { Box, Grid, Typography, Alert, Button } from '@mui/material';
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { blue, green, orange, red } from '@mui/material/colors';
import { useHelpRequestMetrics } from '../Metrics/MetricsAPIEndpoints';
import { useSWRConfig } from 'swr';
import { ActionItem } from 'components/common/Common.interfaces';
import { mutateUrls } from "../../../lib/useGenericSWR";

// @ts-ignore
import { routePaths } from "routes";
// @ts-ignore
import { EnsureUser } from "../../auth/EnsureUser";
// @ts-ignore
import { useResponsive } from 'lib/useResponsive';

export default function AdminHelpRequestStatsWidget() {
    const { t } = useTranslation();
    const { isMobile } = useResponsive();
    const navigate = useNavigate();
    const swrConfig = useSWRConfig();
    const swrMetrics = useHelpRequestMetrics();
    const [reMutateUrls, setReMutateUrls] = useState(false);

    const handleClickGoToHelpRequests = () => {
        navigate(routePaths.helpRequestsPage.path);
    };

    const actionItems: ActionItem[] = [
        {
            component: (
                <Button
                    color={"info"}
                    onClick={handleClickGoToHelpRequests}
                    size={"small"}
                    endIcon={<ChevronRightIcon />}
                >
                    {t("View Help Requests")}
                </Button>
            ),
        },
    ];

    useLayoutEffect(() => {
        if (reMutateUrls) {
            mutateUrls(swrConfig);
            setReMutateUrls(false);
        }
    }, [reMutateUrls, swrConfig]);

    console.log("AdminHelpRequestStatsWidget - Loaded");

    return (
        <UcWidget
            title={t("Help Requests")}
            actionItems={actionItems}
            alignTitleLeft={false}
            contentPadding={'1rem'}
            minHeight={isMobile ? '250px' : '150px'}
            maxHeight={isMobile ? '100%' : '195px'}
        >
            <EnsureUser permissions={["Metrics:Read"]}>
                <div data-cy-id="helpRequestMetrics_widget">
                    <UcLoading
                        hasFade={false}
                        hasError={swrMetrics?.isError}
                        isLoading={swrMetrics?.isLoading}
                        height={"8vh"}
                        errorMessage={t(
                            "An error occurred retrieving Help Request metrics."
                        )}
                    >
                        {swrMetrics.result ?
                            <Grid container spacing={1} alignItems="center">
                                <Grid item xs={6} sm={3}>
                                    <Box sx={{borderRight: isMobile ? '0 solid' : '4px solid', borderColor: 'background.default'}}>
                                        <Typography variant='subtitle2'>{t('Last 24 Hours')}</Typography>
                                        <Typography variant="h3" sx={{ color: blue[500] }}>
                                            {swrMetrics.result?.last24HoursCount}
                                        </Typography>
                                    </Box>
                                </Grid>

                                <Grid item xs={6} sm={3}>
                                    <Box sx={{borderRight: isMobile ? '0 solid ' : '4px solid', borderColor: 'background.default'}}>

                                        <Typography variant='subtitle2'>{t('In-Progress > 1 Week')}</Typography>
                                        <Typography variant="h3" sx={{ color: orange[500] }}>
                                            {swrMetrics.result?.inProgressOlderThanAWeekCount}
                                        </Typography>
                                    </Box>
                                </Grid>

                                <Grid item xs={6} sm={3}>
                                    <Box sx={{borderRight: isMobile ? '0 solid' : '4px solid', borderColor: 'background.default'}}>
                                        <Typography variant='subtitle2'>{t('Requests On-hold')}</Typography>
                                        <Typography variant="h3" sx={{ color: red[500] }}>
                                            {swrMetrics.result?.onHoldCount}
                                        </Typography>
                                    </Box>
                                </Grid>

                                <Grid item xs={6} sm={3}>
                                    <Box>
                                        <Typography variant='subtitle2'>{t('Closure Rate')}</Typography>
                                        <Typography variant="h3" sx={{ color: green[500] }}>
                                            {swrMetrics.result?.closureRate}%
                                        </Typography>
                                    </Box>
                                </Grid>
                            </Grid>
                            :
                            <Alert severity="warning">{t("No Help Request metrics were found.")}</Alert>
                        }
                    </UcLoading>
                </div>
            </EnsureUser>
        </UcWidget>
    );
}
