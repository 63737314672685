import React, { useState, useLayoutEffect, useRef } from 'react';
import { DataGridPro } from '@mui/x-data-grid-pro';
import { useRoleUsers } from './RoleUserAPIEndpoints';
import { dateFormatter } from 'lib/Formatters';
import { UcFullPage } from 'components/common/UcFullPage';
import { useTranslation } from "react-i18next";
import { UcIconButtons } from 'components/common/UcIconButtons';
import { getWholeRow } from '../../../lib/DataGridHelpers';
import DeleteIcon from '@mui/icons-material/Delete';

export const RoleUserGrid = (props) => {
    const { t } = useTranslation();
    const [generalSearchInput] = useState(props.generalSearch || "");
    const initialSort = [{ field: "id", sort: "asc" }];
    const sort = props.sort || initialSort;

    const initialDataState = props.dataState || { skip: 0, take: 10, };

    const [filter, setFilter] = useState({
        roleId: parseInt(props.roleId),
        userId: parseInt(props.userId),
        take: initialDataState.take,
        skip: initialDataState.skip,
        sort: sort,
        generalSearch: generalSearchInput,
    });

    let initialFilterModel = {
        items: [
            {columnField: 'id', operatorValue:'contains', value: ''}
        ],
        linkOperator: 'and',
    };

    if (generalSearchInput) {
        initialFilterModel = {
            items: [
                {columnField: 'id', operatorValue:'contains', value: generalSearchInput}
            ],
            linkOperator: 'and',
        }
    }

    const [filterModel] = useState(initialFilterModel);

    const needsDataUpdate = useRef("");

    let defaultDataItems = {
        columns: [
            {field: 'roleDisplay', headerName: t('Role'), width: 200},
            { field: 'userDisplay', headerName: t('User'), flex: 1, minWidth: 200 },
            {
                field: 'id', headerName: '', width: 60,
                valueGetter: getWholeRow,
                renderCell: (params) => {
                    const actions = [
                        {
                            title: t('Delete User'),
                            icon: <DeleteIcon />,
                            color: 'error',
                            isHidden: false,
                            isDisabled: false,
                            requiredPermission: 'RoleUser:Delete',
                            onClick: () => { props.onDeleteItem(params.value); },
                        }
                    ];

                    return (
                        <UcIconButtons actions={actions} />
                    );
                }
            }
        ],
        rows: [],
        rowCount: -1,
    };
    const [dataItems, setDataItems] = useState(defaultDataItems);

    const defaultSelectedItem = { dataItem: {}, selectedId: 0 };
    const [selectedItem, setSelectedItem] = useState(defaultSelectedItem);

    const dataReceived = (swrResult) => {
        var dataItems = swrResult.result;

        var selectedDataItems = dataItems.map((item) => {
            item.startDate = dateFormatter(item.startDate, true);
            item.endDate = dateFormatter(item.endDate, true);

            return { ...item, selected: item.id === selectedItem.selectedId };
        });
        selectedDataItems.iteration = dataItems.iteration;
        selectedDataItems.rowCount = swrResult.rowCount;
        if (selectedDataItems.length > selectedDataItems.rowCount) {
            selectedDataItems.rowCount = selectedDataItems.length;
        }

        setDataItems({...defaultDataItems, rows: [...dataItems], rowCount: swrResult.rowCount});
    };

    const swrRoleUsers = useRoleUsers(filter); //Pass the datastate directly as the filter.  The API will adjust filters, orderbys ect.
    if (!swrRoleUsers.result && defaultDataItems.rowCount !== -1)
    {
        setDataItems(defaultDataItems);
    }

    useLayoutEffect(() => {
        if (swrRoleUsers.result) {
            var filterString = JSON.stringify(filter);
            if (needsDataUpdate.current !== filterString) {
                needsDataUpdate.current = filterString;
                dataReceived(swrRoleUsers);
            }
        }
    });

    const updateFilter = (filter) => {
        setFilter(filter);
        props.onFilterChange(filter);
    };

    return (
        <UcFullPage {...props}>
            <DataGridPro
                disableColumnFilter={true}
                disableColumnSelector={true}
                autoHeight={!props.isFullPage}
                paginationModel={{page: filter.skip / filter.take, pageSize: filter.take}}
                onPaginationModelChange={(model) => updateFilter({ ...filter, skip: model.page * model.pageSize, take: model.pageSize })}
                sortingMode='server'
                paginationMode='server'
                filterMode='server'
                sortModel={filter.sort}
                onSortModelChange={(model) => updateFilter({...filter, sort: model})}
                filterModel={filterModel}

                rowsPerPageOptions={[5, 10, 20]}
                onRowSelectionModelChange={(newSelectionModel) => {
                    if (newSelectionModel.length !== 1)
                    {
                        setSelectedItem(defaultSelectedItem);
                        props.onSelected(defaultSelectedItem.dataItem);
                        return;
                    }
                    const selectedId = newSelectionModel[0];
                    var selectedItem = dataItems.rows.filter(obj => {return obj.id === selectedId});
                    if (selectedItem.length !== 1)
                    {
                        setSelectedItem(defaultSelectedItem);
                        props.onSelected(defaultSelectedItem.dataItem);
                        return;
                    }
                    selectedItem = selectedItem[0];
                    var newlySelectedItem = { dataItem: selectedItem, selectedId: selectedId };

                    setSelectedItem(newlySelectedItem);
                    props.onSelected(newlySelectedItem.dataItem);
                }}
                pagination
                {...dataItems}
                initialState={{ pinnedColumns: { right: ['id'] } }}
            />
        </UcFullPage>
    );
}

RoleUserGrid.defaultProps = {
    isFullPage: true,
    onSelected: (selectedItem) => { console.log("onSelected not implemented", selectedItem); },
    onFilterChange: (filter) => { console.log("onFilterChange not implemented", filter); },
};
