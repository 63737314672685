import React, { useState, useContext, useEffect, useLayoutEffect } from 'react';
import { PageContext } from 'lib/PageProvider';
import { MeterGridStateful } from "./MeterGridStateful"
import { mutateUrls } from "lib/useGenericSWR";
import { useSWRConfig } from "swr";
import { EnsureUser } from 'components/auth/EnsureUser';
import { UcToolbar } from 'components/common/UcToolbar';
import { MeterFormDialog } from './MeterFormDialog';

export const MeterList = (props) => {
    const customerId = props.customerId;
    const accountId = props.accountId;
    const [selectedItem, setSelectedItem] = useState(null);
    const [pageState, pageDispatch] = useContext(PageContext);
    const [editDialog, setEditDialog] = useState({ open: false });

    const [reMutateUrls, setReMutateUrls] = useState(false);
    const swrConfig = useSWRConfig();

    const closeHandler = () => {
        setEditDialog({ open: false, itemToEdit: editDialog.dataItem });
    }

    const saveHandler = () => {
        closeHandler();
        // Also, remutate
        setReMutateUrls(true);
    }

    const openDialog = (dataItem) => {
        if (!dataItem) {
            return;
        }
        if (!dataItem.formIteration) {
            dataItem.formIteration = "edit" + dataItem.accountId;
        }
        setEditDialog({ open: true, itemToEdit: dataItem });
    }

    const editItem = (selectedItem) => {
        openDialog(selectedItem);
    }

    useLayoutEffect(() => {
        if (reMutateUrls) {
            mutateUrls(swrConfig);
            setReMutateUrls(false);
        }
    }, [reMutateUrls, swrConfig]);

    useEffect(() => {
        pageDispatch({
            header: "Meters",
        });
    }, [pageState.header, selectedItem, pageDispatch]);

    const actions = [];
    const [generalSearch, setGeneralSearch] = useState(null);

    return (
        <div className="rateListPage">
            <EnsureUser permissions={["Meter:Read"]}>
                <UcToolbar
                    hasGeneralSearch={true}
                    onGeneralSearchChange={(value) => {
                        setGeneralSearch(value);
                    } }
                    actions={actions}>
                </UcToolbar>
                <MeterFormDialog
                    accountId={accountId}
                    open={editDialog.open}
                    itemToEdit={editDialog.itemToEdit}
                    onCancel={closeHandler}
                    onSave={saveHandler}
                />
                {!reMutateUrls &&
                    <MeterGridStateful
                        generalSearch={generalSearch}
                        onEditItem={editItem}
                        customerId={customerId}
                        accountId={accountId}
                        onSelected={(selectedSearchItem) => {
                            setSelectedItem({ ...selectedSearchItem });
                        }} />
                }
            </EnsureUser>
        </div>
    );
};