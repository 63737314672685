// @ts-ignore
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import React from "react";
import { useTranslation } from "react-i18next";
import { useSWRConfig } from "swr";
import { mutateUrls } from "lib/useGenericSWR";
import {
    Button,
    Card,
    CardContent,
    CardHeader,
    Collapse,
    Grid,
    IconButton,
    ListItemIcon,
    ListItemText,
    MenuItem,
    MenuList,
    Popover,
    Tooltip,
    Typography,
} from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import DeleteIcon from "@mui/icons-material/Delete";
import { Field, Form, Formik, FormikProps } from "formik";
import { TextField } from "formik-mui";
import {
    OrgFaqSetting,
    orgFaqSettingsValidationSchema,
    useOrgFaqSettings,
    saveOrgFaqSettings,
    bulkUpdateOrgFaqSettings,
    deleteOrgFaqSettings,
} from "./OrgFaqSettingsAPIEndpoints";
import { useTheme } from "@mui/material/styles";
import { UcExpandMore } from "components/common/UcExpandMore";

interface OrgFaqSettingsFormProps {
    cepOrgId: number;
}

export default function OrgFaqSettingsForm(props: Readonly<OrgFaqSettingsFormProps>) {
    const { cepOrgId } = props;
    const { t } = useTranslation();
    const theme = useTheme();
    const swrConfig = useSWRConfig();
    const formRef = React.useRef<FormikProps<OrgFaqSetting>>(null);
    const [reMutateUrls, setReMutateUrls] = React.useState(false);
    const [activeItem, setActiveItem] = React.useState(null);
    const [selectedIndex, setSelectedIndex] = React.useState("");
    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
    const open = Boolean(anchorEl);
    const id = open ? "simple-popover" : undefined;
    const swrFaqSettings = useOrgFaqSettings(cepOrgId);
    const initialValues: OrgFaqSetting = {
        id: 0,
        cepOrgId: cepOrgId,
        question: "",
        answer: "",
        sortOrder: 0,
    };

    const handleClickPopover = (
        value: any,
        event: React.MouseEvent<HTMLButtonElement>
    ) => {
        setActiveItem(value);
        setAnchorEl(event.currentTarget);
    };

    const handleDelete = async () => {
        if (activeItem !== null) {
            let data = await deleteOrgFaqSettings(activeItem);
            setActiveItem(null);
            setAnchorEl(null);

            if (data) {
                setReMutateUrls(true);
            }
        }
    };

    const handleClosePopover = () => {
        setAnchorEl(null);
    };

    const handleExpandClick = (index: any) => {
        if (selectedIndex === index) {
            setSelectedIndex("");
        } else {
            setSelectedIndex(index);
        }
    };

    const onDragEnd = (result: any) => {
        if (!result.destination) {
            return;
        }

        const items: any = reorder(
            swrFaqSettings.result,
            result.source.index,
            result.destination.index
        );

        swrFaqSettings.result = items;
        bulkUpdateOrgFaqSettings(swrFaqSettings.result);
    };

    const reorder = (list: any, startIndex: any, endIndex: any) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);

        // assign the new index to the sortorder
        for (let index = 0; index < result.length; index++) {
            const element: any = result[index];
            element.sortOrder = index;
        }

        return result;
    };

    const getDraggableItemStyle = (
        isDragging: boolean,
        draggableStyle: any
    ) => ({
        userSelect: "none",
        background: isDragging ? theme.palette.info.light : "inherit",
        color: isDragging ? theme.palette.info.contrastText : "inherit",
        ...draggableStyle,
    });

    React.useLayoutEffect(() => {
        if (reMutateUrls) {
            mutateUrls(swrConfig);
            setReMutateUrls(false);
        }
    }, [reMutateUrls, swrConfig]);

    return (
        <div className="ignoreCustomFormStyles">
            <h1 className="pageHeader" style={{ marginBottom: 18 }}>
                {t("FAQ Settings")}
                <Tooltip
                    title={t("Specify the Questions/Answers to be displayed within the dedicated FAQ page")}
                >
                    <IconButton color="info">
                        <InfoOutlinedIcon />
                    </IconButton>
                </Tooltip>
            </h1>
            <Formik
                innerRef={formRef}
                initialValues={initialValues}
                validationSchema={orgFaqSettingsValidationSchema}
                onSubmit={async (values, { resetForm }) => {
                    values.cepOrgId = cepOrgId;
                    let data = await saveOrgFaqSettings(values);

                    if (data) {
                        resetForm();
                        setReMutateUrls(true);
                    }
                }}
            >
                <Form>
                    <Grid container spacing={2} sx={{ mb: 3 }}>
                        <Grid item xs={12} sm={12} md={4}>
                            <Field
                                name={"question"}
                                component={TextField}
                                label={t("Question")}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={4}>
                            <Field
                                name={"answer"}
                                component={TextField}
                                label={t("Answer (press enter for new line)")}
                                multiline
                                maxRows={2}
                            />
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            sm={12}
                            md={4}
                            alignItems="stretch"
                            style={{ display: "flex" }}
                        >
                            <Button
                                type="submit"
                                color="primary"
                                variant="contained"
                                fullWidth
                            >
                                {t("Add")}
                            </Button>
                        </Grid>
                    </Grid>
                </Form>
            </Formik>
            <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="droppable">
                    {(provided: any) => (
                        <div
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                        >
                            {swrFaqSettings.result?.map(
                                (item: any, index: any) => {
                                    return (
                                        <Draggable
                                            key={item.id}
                                            draggableId={item.id.toString()}
                                            index={index}
                                        >
                                            {(provided: any, snapshot: any) => (
                                                <Card
                                                    sx={{ mt: 1 }}
                                                    elevation={3}
                                                    key={`entry_${item.id}`}
                                                    ref={provided.innerRef}
                                                    style={getDraggableItemStyle(
                                                        snapshot.isDragging,
                                                        provided.draggableProps
                                                            .style
                                                    )}
                                                    {...provided.draggableProps}
                                                    {...provided.dragHandleProps}
                                                >
                                                    <CardHeader
                                                        action={
                                                            <>
                                                                <UcExpandMore
                                                                    expanded={index === selectedIndex}
                                                                    onClick={() => handleExpandClick(index)}
                                                                    aria-expanded={index}
                                                                    aria-label="show more"
                                                                >
                                                                    <ExpandMoreIcon />
                                                                </UcExpandMore>
                                                                <IconButton
                                                                    aria-label="actions"
                                                                    aria-describedby={id}
                                                                    onClick={(e) => handleClickPopover(item, e)}
                                                                >
                                                                    <MoreVertIcon />
                                                                </IconButton>
                                                            </>
                                                        }
                                                        title={item.question}
                                                    />
                                                    <Collapse
                                                        in={index === selectedIndex}
                                                        timeout="auto"
                                                        unmountOnExit
                                                    >
                                                        <CardContent>
                                                            <Typography
                                                                variant="body1"
                                                                color="text.secondary"
                                                                paragraph
                                                            >
                                                                {item.answer}
                                                            </Typography>
                                                        </CardContent>
                                                    </Collapse>
                                                </Card>
                                            )}
                                        </Draggable>
                                    );
                                }
                            )}
                            {provided.placeholder}
                        </div>
                    )}
                </Droppable>
            </DragDropContext>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClosePopover}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                }}
            >
                <MenuList>
                    <MenuItem onClick={handleDelete}>
                        <ListItemIcon>
                            <DeleteIcon color="error" fontSize="small" />
                        </ListItemIcon>
                        <ListItemText>{t("Delete")}</ListItemText>
                    </MenuItem>
                </MenuList>
            </Popover>
        </div>
    );
}
