import { getDataKeyOriginal } from '../../lib/Formatters';
import './UcLegend.scss';
import {
    Commit as CommitIcon,
} from '@mui/icons-material'

export const UcLegend = (props) => {
    const { payload } = props;

    const getIcon = (entry) => {
        switch (entry.type) {
            case "line":
                return <div style={{display:'inline-block', width: props.iconSize*1.5, height: props.iconSize, backgroundColor: 'transparent', overflow: 'hidden'}}>
                    <CommitIcon style={{width:'115%', height:'115%', marginBottom: 10, fill: entry.color}} />
                </div>;
            default:
                // default to 'rect'
                return <div style={{display:'inline-block', width: props.iconSize, height: props.iconSize, backgroundColor: entry.color, borderRadius: '10px'}}></div>;
        }
    }

    const removeBracketArray = payload?.map((fullItem) =>
    {
        return ({ originalName: fullItem.value, value: getDataKeyOriginal(fullItem.value), color: fullItem.color, type: fullItem.type });
    });

    function isDuplicate(entry, arr) {
        return arr.some(x => (entry.value.toLowerCase() === x.value.toLowerCase()) && // remove duplicates based on value
            (entry.color === x.color)) // remove duplicates based on color
    }

    let updatedArray = [];
    for (const entry of removeBracketArray) {
        if (!isDuplicate(entry, updatedArray)) { updatedArray.push(entry) }
    }

    return (
      <ul className="ucLegend">
        {
          updatedArray
            .filter((entry) => {
                return entry.type !== "none";
            })
            .map((entry, index) => (
            <li key={`item-${index}`}>
                {getIcon(entry)} {entry.value}</li>
          ))
        }
      </ul>
    );
  }

export default UcLegend;


