import { useTranslation } from 'react-i18next';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import LocalFireDepartmentIcon from '@mui/icons-material/LocalFireDepartment';
import AcUnitIcon from '@mui/icons-material/AcUnit';
import WaterDropIcon from '@mui/icons-material/WaterDrop';
import { getDataKeyOriginal, dayFormatter } from './Formatters';

export const UsageTooltip = (props) => {
    const { active, meterUom, orgTempUnit, groupBy } = props;
    let label = props.label
    let payload = props.payload;
    const { t } = useTranslation();
    let orderedPayload = [];
    let usageTotal;
    let usageTotalCost;

    const getPrecipUnit = () => {
        if (orgTempUnit === "F") {
            return t("in.")
        }
        else {
            return t("mm")
        }
    }

    if (!payload)
    {
        // once in a while payload is empty and not sure why
        // When this happens we just won't render anything
        console.log("UsageTooltip payload is empty", props);
        return <></>
    }
    if (payload.length > 0) { // remove y axis cost from payload
        payload = payload.filter(o => o.dataKey !== "Cost");
    }

    const basicRatesPayload = payload[0]?.payload?.Rates
        ?.filter((obj) => obj.rateChargeType !== 'TIMEOFUSE' && obj.rateChargeType !== 'TIERED') || [];

    const ignoreNonUsageNames = (name) => { 
        return (name.match("Temp") === null && name.match("Missing Rate") === null && name.match("Area-Stack") === null && name.match("Precip") === null)
    }

    const getValueDisplayText = (item) => {
        // user must be impersonating to see EST values
        if (item.EST !== null) {
            return `${item?.value?.toFixed(2)} ${meterUom} (${item?.EST.value?.toFixed(2)} ${meterUom} Est.)`;
        }
        else if (item.rateChargeType === 'MONTHLY') {
            return `Total: $${item?.rate?.toFixed(2) ?? '0.00'}`;
        }
        else {
            return `Total: ${item?.value?.toFixed(2)} ${meterUom}, $${item?.cost?.toFixed(2) ?? '0.00'}`;
        }
    };

    const getArrayMergeEST = (arr) => {
        let groupedArr = [];

        groupedArr = arr?.map(item => {
            if (!item.name.includes('EST')) {
                let estPeak = arr.filter((obj) => obj.name === `EST ${item.name}`)[0];
                if (estPeak !== undefined) {
                    item.EST = estPeak;
                }
                return item;
            }
            return undefined;
        }).filter(item => item?.EST !== undefined);

        return groupedArr;
    }

    const genericPeaks = payload?.map(({ name, value, fill, stroke, display, payload}) => ({
        name: (ignoreNonUsageNames(name) &&
            getDataKeyOriginal(name)),
        value: value,
        rate: payload[name + '_rate'] || 0,
        cost: payload[name + '_cost'] || 0,
        fill: (fill === '#fff' ? stroke : fill),
        display: display,
        rateChargeType: payload[name + '_rateChargeType'],
        showOnToolTip: payload[name + '_showOnToolTip'],
        EST: null
    })).filter((item) => item.name);

    let genericPeaksTotaled = Object.values(
        genericPeaks?.reduce((arr, item) => {
            const key = item.name + '-' + item.rate + '-' + item.fill;

            if (arr[key]) {
                arr[key] = {
                    ...item,
                    value: item.value + arr[key].value,
                    cost: item.cost + arr[key].cost
                };
            }
            else {
                arr[key] = {
                    ...item
                };
            }
            return arr;
        }, {})
    );

    payload.forEach(item => {
        if (item.name.indexOf('Temperature') > -1) {
            if (item.name.indexOf('High') > -1) {
                item.newName = t("High (°" + orgTempUnit + ")");
            }
            else if (item.name.indexOf('Low') > -1) {
                item.newName = t("Low (°" + orgTempUnit + ")");
            }
            else {
                item.newName = t("Avg. (°" + orgTempUnit + ")");
            }
            item.newValue = (item.value) + "°" + orgTempUnit;
            orderedPayload.push(item);
        }
        else if (item.name.indexOf('Precip') > -1) {
            item.newName = t("Precipitation: ");
            item.newValue = item.value + " " + getPrecipUnit()
            orderedPayload.push(item);
            
        }
        else if (item.name.indexOf('Missing Rate') > -1) {
            item.newName = item.name;
            item.newValue = item.value.toFixed(2) + " " + meterUom;
            orderedPayload.push(item);
        }
        item.EST = null;
    });

    genericPeaksTotaled = getArrayMergeEST(genericPeaksTotaled);
    orderedPayload = getArrayMergeEST(orderedPayload);

    usageTotal = payload.filter(({ name }) => ((name.match("EST") === null) && name.match("Temp") === null && name.match("Area-Stack") === null && name.match("Precip") === null));
    usageTotal = usageTotal.filter(o => o.display !== "none") // only total displayed usages
                            .reduce((a, b) => a + b.value, 0);

    usageTotal = Number(usageTotal).toFixed(2) + " " + meterUom;

    usageTotalCost = genericPeaksTotaled
        .filter(({ name, rateChargeType }) => {
            const isEst = name.match("EST") !== null;
            return !isEst && ignoreNonUsageNames(name) && (rateChargeType !== "MONTHLY" || groupBy === "month");
        })
        .reduce((total, { rateChargeType, cost, rate }) => {
            const finalCost = rateChargeType !== "MONTHLY" ? cost : rate;
            return total + finalCost;
        }, 0);

    usageTotalCost = "$" + Number(usageTotalCost).toFixed(2);

    let prevHour = "";
    if (groupBy === "none") {
        prevHour = new Date("2023-05-05 " + payload[0]?.payload.localTimestamp); // date itself doesnt matter
        prevHour.setHours(prevHour.getHours() - 1);
        prevHour = prevHour.toLocaleString('en-US', { hour: 'numeric', hour12: true })
        label = prevHour + " - " + label; // Display an hour time range as label
    }
    if (groupBy === "month") {
        if (payload[0]?.payload.billingPeriodStart && dayFormatter(payload[0]?.payload.billingPeriodEnd)) {
            label = dayFormatter(payload[0]?.payload.billingPeriodStart, "", true) + " to " + dayFormatter(payload[0]?.payload.billingPeriodEnd);
        }
        else {
            label = payload[0]?.payload.name;
        }    
    }

    if (active && payload && payload.length) {
        return (
            <List className='usageTooltip'
                sx={{
                    width: '100%',
                    minWidth: '250px',
                    bgcolor: 'background.paper',
                    border: '1px solid #cccccc',
                    borderRadius: '5px'
                }}
                component="nav"
            >
                <ListItem>
                    <Typography gutterBottom variant="h6" component="p">
                        {label}
                    </Typography>
                </ListItem>
                <Divider />
            <ListItem>
                <ListItemText
                    primary={usageTotal + " - " + usageTotalCost}
                    secondary={t('Total')}
                />
            </ListItem>
            {genericPeaksTotaled.map(item => 
                (item.name?.toLowerCase().indexOf('chart-only') === -1 && item.showOnToolTip &&
                    (item.rateChargeType !== 'MONTHLY' ? 
                        (
                            <>
                                <Divider />
                                <ListItem>
                                    <ListItemText
                                        sx={{ color: item.fill }}
                                        primaryTypographyProps={{ fontSize: '15px' }} 
                                        primary={`${item.name} @ $${item.rate}/${meterUom}`}
                                        secondaryTypographyProps={{ fontSize: '13px' }} 
                                        secondary={getValueDisplayText(item)}
                                    />
                                </ListItem>
                            </>
                        ) : (
                            groupBy === "month" && (
                                <>
                                    <Divider />
                                    <ListItem>
                                        <ListItemText
                                            sx={{ color: item.fill }}
                                            primaryTypographyProps={{ fontSize: '15px' }} 
                                            primary={`${item.name} @ $${item.rate.toFixed(2)}/month`}
                                            secondaryTypographyProps={{ fontSize: '13px' }} 
                                            secondary={getValueDisplayText(item)}
                                        />
                                    </ListItem>
                                </>
                            )
                        )
                    )
                )
            )}
            {orderedPayload.length > 0 && (
                <>
                    <Divider />
                    {orderedPayload.map((item, index) =>
                        <>
                            { item.newName?.toLowerCase().includes('missing') && (
                                <>
                                    <ListItem>
                                        <ListItemText
                                            sx={{
                                                color: item.fill
                                            }}
                                            primary={item.newName}
                                            secondary={getValueDisplayText(item)}
                                        />
                                    </ListItem>
                                    { (index + 1) < orderedPayload.length && (<Divider />)}
                                </>
                            )}
                            { !item.newName?.toLowerCase().includes('missing') && (
                                <ListItem
                                    sx={{
                                        pt: ((index === 0) ? '8px' : 0),
                                        pb: ((index + 1) === orderedPayload.length ? '8px' : 0)
                                    }}
                                >
                                    <ListItemText
                                        secondary={`${item.newName} ${(item.newValue)}`}
                                    />
                                    { item.newName?.toLowerCase().includes('high') && (
                                        <LocalFireDepartmentIcon sx={{color: item.fill}} fontSize='small' />
                                    )}
                                     { item.newName?.toLowerCase().includes('low') && (
                                        <AcUnitIcon sx={{color: item.fill}} fontSize='small' />
                                    )}
                                    {item.newName?.toLowerCase().includes('precip') && (
                                        <WaterDropIcon sx={{ color: item.fill }} fontSize='small' />
                                    )}
                                </ListItem>
                            )}
                        </>
                    )}
                </>
            )}
            {basicRatesPayload.length > 0 && (
                <>
                    <Divider />
                    {basicRatesPayload.map((item, index) =>
                        <>
                            <ListItem
                                sx={{
                                    pt: ((index) === 0 ? '8px' : 0),
                                    pb: ((index + 1) === basicRatesPayload.length ? '8px' : 0)
                                }}
                            >
                                <ListItemText
                                    secondary={`${item.name.substring(0, item.name.indexOf('(')).trim() || item.name}`}
                                />
                                    <Typography variant='body2'
                                        sx={{color: item.htmlColor, display: 'block'}}
                                    >
                                        $ {(item.rate.toFixed(2))}
                                    </Typography>

                            </ListItem>
                        </>
                    )}
                </>
            )}
          </List>
        )
    }
  };