import { UcWidget } from '../common/UcWidget';
import { ActionItem, WidgetWrapperProps } from "../common/Common.interfaces";
import { useTranslation } from "react-i18next";
import { UserBillOptionsWidget } from './UserBillOptionsWidget';

export default function UserBillOptionsWidgetWrapper(props: WidgetWrapperProps) {
    const { t } = useTranslation();

    const actionItems: ActionItem[] = [];

    return (!props.hideWidgetBorder) ? (
        <UcWidget
            actionItems={actionItems}
            minHeight={"auto"}
            contentPadding={"1rem"}
            title={!props.hideWidgetTitle ? t("Billing Options") : ""}
            alignTitleLeft={false}
        >
            <UserBillOptionsWidget />
        </UcWidget>
  ) : (
        <UserBillOptionsWidget />
  )
}
