import { ApiClient } from "../../../lib/ApiClient";
import { useGenericSWR } from "../../../lib/useGenericSWR";
import * as Yup from 'yup';

export const orgUomApiEndpoint = 'api/v1/admin/uc/OrgUom';

let formIteration = 0;

const getOrgUomsDefaultItem = () => {
    formIteration = formIteration + 1;
    return {
        formIteration: "new" + formIteration,
        id: 0,
        udmOrgUomId: null,
        name: '',
        description: '',
        logoUrl: '',
        status: '',
        externalUomMapping: '',
        uomDisplay: '',
        externalUomMappingDisplay: '',
        createdBy: 'REACT_FE',
        updatedBy: 'REACT_FE',
    }
};

const orgUomsValidationSchema = Yup.object().shape({
    uom: Yup.string()
        .required("Required")
        .max(256, 'Max of 256 characters'),
    meterClass: Yup.number()
        .required("Required")
        .max(3, 'Max of 3')
        .min(1, 'Min of 1'),
});

// See definition from OrgUom.cs
const apiClient = new ApiClient();
apiClient.getDefaultItem = () => getOrgUomsDefaultItem();
apiClient.getObjectName = () => {
    return "Uom";
};

const useOrgUom = (id, swrOptions = {}) => {
    // SWR will prevent duplicate requests
    // It will also maintain data state as long as your are using the same urls for your requests
    return useGenericSWR(orgUomApiEndpoint + "/" + id, apiClient, { ...swrOptions });
};

const useOrgUoms = (filter, swrOptions = {}, shouldFetch = true) => {
    // SWR will prevent duplicate requests
    // It will also maintain data state as long as your are using the same urls for your requests
    const params = new URLSearchParams();
    params.append('filter', JSON.stringify(filter));
    return useGenericSWR(shouldFetch ? (orgUomApiEndpoint + "?" + params.toString()) : null, apiClient, { ...swrOptions });
};

const saveOrgUom = (OrgUom) => {
    if (OrgUom.id === 0) {
        return insertOrgUom(OrgUom);
    }
    return updateOrgUom(OrgUom);
}
const insertOrgUom = (OrgUom) => {
    return apiClient.poster(orgUomApiEndpoint, OrgUom);
};

const updateOrgUom = (OrgUom) => {
    return apiClient.putter(orgUomApiEndpoint, OrgUom);
};

const deleteOrgUom = (OrgUom) => {
    return apiClient.deleter(orgUomApiEndpoint + "/" + OrgUom.id);
};

export {
    useOrgUom,
    useOrgUoms,
    saveOrgUom,
    insertOrgUom,
    updateOrgUom,
    deleteOrgUom,
    orgUomsValidationSchema,
    getOrgUomsDefaultItem,
};