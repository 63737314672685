// @ts-ignore
import { ApiClient } from "../../../lib/ApiClient";
import { useGenericSWR } from "../../../lib/useGenericSWR";
import * as Yup from 'yup';

const apiEndpoint = 'api/v1/admin/RateClassChangeRequest';

let formIteration = 0;

export interface RateClassChangeRequest {
    id: number;
    cepOrgId: number;
    accountId: number;
    currentRateClassId: number;
    requestedRateClassId: number;
    accountNumber: string;
    currentRateClassName: string;
    requestedRateClassName: string;
    status: string;
}

const getRateClassChangeRequestsDefaultItem = () => {
    formIteration = formIteration + 1;
    return {
        formIteration: "new" + formIteration,
        id: 0,
        createdBy: 'REACT_FE',
        updatedBy: 'REACT_FE',
    }
};

const rateClassChangeRequestsValidationSchema = Yup.object().shape({
    status: Yup.string()
        .required("Required")
        .max(50, 'Max of 50 characters')
});

const apiClient = new ApiClient();
apiClient.getDefaultItem = () => getRateClassChangeRequestsDefaultItem();
apiClient.getObjectName = () => {
    return "Rate Class Change Request";
};

const useRateClassChangeRequest = (id: any, swrOptions: any = {}) => {
    return useGenericSWR(apiEndpoint + "/" + id, apiClient, { ...swrOptions });
};

const useRateClassChangeRequests = (filter: any, swrOptions: any = {}) => {
    const params = new URLSearchParams();
    params.append('filter', JSON.stringify(filter));
    return useGenericSWR(apiEndpoint + "?" + params.toString(), apiClient, { ...swrOptions });
};

const saveRateClassChangeRequest = (RateClassChangeRequest: RateClassChangeRequest) => {
    if (RateClassChangeRequest.id === 0) {
        return insertRateClassChangeRequest(RateClassChangeRequest);
    }
    return updateRateClassChangeRequest(RateClassChangeRequest);
}
const insertRateClassChangeRequest = (RateClassChangeRequest: RateClassChangeRequest) => {
    return apiClient.poster(apiEndpoint, RateClassChangeRequest);
};

const updateRateClassChangeRequest = (RateClassChangeRequest: RateClassChangeRequest) => {
    return apiClient.putter(apiEndpoint, RateClassChangeRequest);
};

const deleteRateClassChangeRequest = (RateClassChangeRequest: RateClassChangeRequest) => {
    return apiClient.deleter(apiEndpoint + "/" + RateClassChangeRequest.id);
};

export {
    rateClassChangeRequestsValidationSchema,
    useRateClassChangeRequest,
    useRateClassChangeRequests,
    getRateClassChangeRequestsDefaultItem,
    saveRateClassChangeRequest,
    deleteRateClassChangeRequest
};