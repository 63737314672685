import * as React from 'react';
import { Box, ListItemIcon, MenuItem, OutlinedInput, Select, useTheme } from '@mui/material';
import ElectricBoltIcon from '@mui/icons-material/ElectricBolt';
import WaterDropIcon from '@mui/icons-material/WaterDrop';
import CachedIcon from '@mui/icons-material/Cached';
import { ThemeProps } from "./Common.interfaces";
import { useTranslation } from "react-i18next";
import { Account } from 'lib/AccountProvider';
// @ts-ignore
import { uomToCommodity, commodityToUom } from '../../lib/Formatters';
import { isMeterInRange } from 'utils/helpers/general.helpers';

interface Option {
    id: string | undefined;
    meterCommodity: string;
    startTimestamp?: string;
    endTimestamp?: string;
    serialNo: string;
    uom?: string;
    meterNickname?: string;
}

interface UcMeterSelectProps {
    account: Account | null;
    startDate?: Date;
    endDate?: Date;
    onlyCommodity: boolean;
    id?: string;
    onChange: Function;
    forcedSize?: "small" | "medium";
    forcedWidth?: string;
    forcedPadding?: string;
    forcedMargin?: string;
    forcedTransform?: string;
    datacyid?: string;
    disabled?: boolean;
    meter?: string;
    commodity?: string;
    uom?: string;
}

export const UcMeterSelectV1 = React.forwardRef((props: UcMeterSelectProps, ref) => {
    const { t } = useTranslation();
    const [open, setOpen] = React.useState(false);
    const [options, setOptions] = React.useState<Option[]>([]);
    const metersJson = props.account?.metersJson;
    const theme: ThemeProps = useTheme();
    const commodity = uomToCommodity(props.uom) || props.commodity;

    let valueOption: Option = { id: undefined, meterCommodity: '', serialNo: 'Loading..' } 
    const [selectedValue, setSelectedValue] = React.useState<Option | undefined>(valueOption);

    const commodityText = (commodity: string, serialNo: string | undefined, meterNickname: string | undefined) => {
        let displayText = serialNo ?? "";
        if (meterNickname) {
            displayText = meterNickname;
        }

        if (commodity.toUpperCase() === "ELECTRIC") {
            return t("Electric - ") + displayText + (serialNo === "All" ? " Meters" : "")
        }
        else {
            return t("Water - ") + displayText + (serialNo === "All" ? " Meters" : "")
        }
    }

    React.useEffect(() => {
        let metersList: Option[] = [];

        let electricMeters = (JSON.parse(metersJson ?? '{}')
            .filter((item: Option) => item.meterCommodity === "ELECTRIC" && isMeterInRange(item, props?.startDate, props?.endDate)));
 
        if (electricMeters.length > 1 || props.onlyCommodity) {
            metersList = [(
                {
                    id: "1",
                    meterCommodity: "ELECTRIC",
                    serialNo: "All", 
                }
            )]
        }

        if (!props.onlyCommodity) {
            metersList = [...metersList, ...electricMeters];
        }

        let waterMeters = (JSON.parse(metersJson ?? '{}')
            .filter((item: Option) => item.meterCommodity === "WATER" && isMeterInRange(item, props?.startDate, props?.endDate)));

        if (waterMeters.length > 1 || props.onlyCommodity) {
            metersList = [...metersList, (
                {
                    id: "2",
                    meterCommodity: "WATER",
                    serialNo: "All",
                }
            )]
        }

        if (!props.onlyCommodity) {
            metersList = [...metersList, ...waterMeters];
        }

        setOptions(metersList);
        let option: Option | undefined;     
        const allSerialsExcluded = metersList.every(meter => meter.serialNo !== "All");

        if (allSerialsExcluded || props.onlyCommodity) {
            option = metersList.find((x: Option) => x.meterCommodity === commodity);
        }
        else {
            option = metersList.find((x: Option) => x.meterCommodity === commodity && x.serialNo === props.meter)
            ?? metersList.find((x: Option) => x.meterCommodity === commodity);
        }
        
        setSelectedValue(option);
    }, [metersJson, commodity, props.meter, props?.startDate, props?.endDate, props.onlyCommodity]);

    let id = React.useId();

    return options.length > 0 ? (
        <Select
            ref={ref}
            id={props.id || "ucMeterSelect" + id}
            data-cy-id={props.datacyid}
            open={open}
            value={selectedValue || null}
            fullWidth={false}
            disabled={props.disabled}
            title={selectedValue?.serialNo}
            size={props.forcedSize ?? 'small'}
            style={{
                width: props.forcedWidth ?? '100%',
                padding: props.forcedPadding ?? 0,
                margin: props.forcedMargin ?? 0,
                transform: props.forcedTransform ?? '',
                minWidth: '250px'
            }}
            onChange={(event) => {
                const {
                    target: { value },
                  } = event;
                let valueId = options.find((x: Option) => x.id === value);
                if (valueId) {
                    valueId.uom = commodityToUom(valueId.meterCommodity);
                }
                setSelectedValue(valueId);
                props.onChange(event, valueId);
            }}
            onOpen={() => {
                setOpen(true);
            }}
            onClose={() => {
                setOpen(false);
            }}
            input={<OutlinedInput  />}
            renderValue={(selected) => (
                (options.length > 0 && selectedValue !== undefined && selected?.serialNo !== "Loading..") ? (
                    selected?.meterCommodity === "ELECTRIC" ? (
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <ElectricBoltIcon sx={{color: (theme.props?.Commodity?.kWhColor), pr: 1}}/>
                            {commodityText("ELECTRIC", selected?.serialNo, selected?.meterNickname)}
                        </Box>
                    ) : (
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <WaterDropIcon sx={{color: (theme.props?.Commodity?.galColor), pr: 1}}/>
                            {commodityText("WATER", selected?.serialNo, selected?.meterNickname)}
                        </Box>
                    )
                ) : (
                    <Box sx={{ display: 'flex', alignItems: 'center'}}>
                        <CachedIcon sx={{color: (theme.props?.Commodity?.galColor), pr: 1}}/>
                        {t("Loading...")}
                    </Box>
                )
                    
            )}
        >
            {options?.map((option: Option) => (
                <MenuItem
                    key={option.id}
                    value={option.id}
                >
                {option.meterCommodity === "ELECTRIC" ? (
                    <>
                        <ListItemIcon>
                            <ElectricBoltIcon sx={{color: (theme.props?.Commodity?.kWhColor)}}/>
                        </ListItemIcon>
                        {commodityText("ELECTRIC", option.serialNo, option.meterNickname)}
                    </>
                ) : (
                    <>
                        <ListItemIcon>
                            <WaterDropIcon sx={{color: (theme.props?.Commodity?.galColor)}}/>
                        </ListItemIcon>
                        {commodityText("WATER", option.serialNo, option.meterNickname)}
                    </>
                )}
                </MenuItem>
            ))}
        </Select>
    ) : <></>;
});

UcMeterSelectV1.defaultProps = {
    onChange: (event: any, value: any) => { console.log("onChange not implemented", event, value); },
};