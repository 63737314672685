// @ts-ignore
import { ApiClient } from "lib/ApiClient";
import { useGenericSWR } from "lib/useGenericSWR";
import * as Yup from "yup";

export const helpRequestCommentsApiEndpoint = "api/v1/HelpRequestComments";
export interface HelpRequestComments {
    id?: number;
    cepOrgId?: number;
    helpRequestId: number;
    userId: number;
    assignedUserId?: number;
    fullName?: string;
    content: string;
    createdBy?: string;
    createdTs?: Date;
}

let formIteration = 0;

const getHelpRequestCommentsDefaultItem = () => {
    formIteration = formIteration + 1;
    return {
        formIteration: "new" + formIteration,
        id: 0,
        helpRequestId: 0,
        userId: 0,
        content: "",
        createdBy: 'REACT_FE',
        updatedBy: 'REACT_FE',
    };
};

const helpRequestCommentsValidationSchema = Yup.object().shape({
    content: Yup.string()
        .required("Required")
        .max(256, "Max of 256 characters"),
});

const apiClient = new ApiClient();
apiClient.requiresAuth = false;
apiClient.getDefaultItem = () => getHelpRequestCommentsDefaultItem();
apiClient.getObjectName = () => {
    return "Help Request Comments";
};

const useHelpRequestComments = (helpRequestId: number, swrOptions: any = {}) => {
    const filter = {
        helpRequestId: helpRequestId,
        take: 100,
        skip: 0,
        sort: [{ field: "createdTs", sort: "desc" }],
    };

    const params = new URLSearchParams();
    params.append("filter", JSON.stringify(filter));
    return useGenericSWR(
        helpRequestCommentsApiEndpoint + "?" + params.toString(),
        apiClient,
        { ...swrOptions }
    );
};

const saveHelpRequestComments = (HelpRequestComments: HelpRequestComments) => {
    if (HelpRequestComments.id === 0) {
        return insertHelpRequestComments(HelpRequestComments);
    }
    return updateHelpRequestComments(HelpRequestComments);
};

const insertHelpRequestComments = (HelpRequestComments: HelpRequestComments) => {
    return apiClient
        .poster(helpRequestCommentsApiEndpoint, HelpRequestComments, "Posting Comment", true)
        .then((result: any) => {
            mutateHelpRequestComments();

            return result;
        });
};

const updateHelpRequestComments = (HelpRequestComments: HelpRequestComments) => {
    return apiClient.putter(helpRequestCommentsApiEndpoint, HelpRequestComments);
};

const mutateHelpRequestComments = () => {
    apiClient.mutateFetchUrls();
};

export {
    helpRequestCommentsValidationSchema,
    useHelpRequestComments,
    saveHelpRequestComments,
    mutateHelpRequestComments,
    getHelpRequestCommentsDefaultItem,
};
