import { ApiClient } from "../../../lib/ApiClient";
import { useGenericSWR } from "../../../lib/useGenericSWR";
import * as Yup from 'yup';
import { ensureLuxonDateOnly } from "lib/Formatters";

const apiEndpoint = 'api/v1/admin/MeterRate';

let formIteration = 0;

const getMeterRatesDefaultItem = (accountMeterId) => {
    formIteration = formIteration + 1;
    return {
        formIteration: "new" + formIteration,
        id: 0,
        rateClassId: 0,
        rateClassName: '',
        accountMeterId: accountMeterId || 0,
        rateStartTimestamp: ensureLuxonDateOnly(new Date()),
        rateEndTimestamp: ensureLuxonDateOnly(new Date()),
        createdBy: 'REACT_FE',
        updatedBy: 'REACT_FE',
        accountName: '',
        accountAddress: '',
        extAccountNumber: '',
        serialNo: ''
    }
};

const meterRatesValidationSchema = Yup.object().shape({
    rateClassId: Yup.number()
        .required('Rate class is required')
        .min(1, 'Rate class is required'),
    accountMeterId: Yup.number()
        .required('Account meter is required')
        .min(1, 'Account meter is required'),
    rateStartTimestamp: Yup.date()
        .required("Required")
        .when('rateEndTimestamp', {
            is: (rateEndTimestamp) => {
                if (rateEndTimestamp) {
                    return true;
                }
                return false;
            },
            then: Yup.date()
                .max(
                Yup.ref("rateEndTimestamp"),
                "Start Date must be less than Meter Rate End Date"),
        }),
    rateEndTimestamp: Yup.date()
        .nullable(),
});

const meterRatesName = "Meter Rates";
const meterRateName = "Meter Rate";

// See definition from MeterRate.cs
const apiClient = new ApiClient();
apiClient.getDefaultItem = () => getMeterRatesDefaultItem();
apiClient.getObjectName = () => {
    return meterRatesName;
};

const useMeterRate = (id, swrOptions = {}) => {
    // SWR will prevent duplicate requests
    // It will also maintain data state as long as your are using the same urls for your requests
    return useGenericSWR(apiEndpoint + "/" + id, apiClient, { ...swrOptions });
};

const useMeterRates = (filter, swrOptions = {}) => {
    // SWR will prevent duplicate requests
    // It will also maintain data state as long as your are using the same urls for your requests
    const params = new URLSearchParams();
    params.append('filter', JSON.stringify(filter));
    return useGenericSWR(apiEndpoint + "?" + params.toString(), apiClient, { ...swrOptions });
};

const saveMeterRate = (MeterRate) => {
    if (MeterRate.id === 0) {
        return insertMeterRate(MeterRate);
    }
    return updateMeterRate(MeterRate);
}
const insertMeterRate = (MeterRate) => {
    return apiClient.poster(apiEndpoint, MeterRate);
};

const updateMeterRate = (MeterRate) => {
    return apiClient.putter(apiEndpoint, MeterRate);
};

const deleteMeterRate = (MeterRate) => {
    return apiClient.deleter(apiEndpoint + "/" + MeterRate.id);
};

export {
    meterRateName,
    meterRatesName,
    useMeterRate,
    useMeterRates,
    saveMeterRate,
    insertMeterRate,
    updateMeterRate,
    deleteMeterRate,
    meterRatesValidationSchema,
    getMeterRatesDefaultItem,
};