import React, { useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Paper from '@mui/material/Paper';
import Draggable from 'react-draggable';

import PropTypes from 'prop-types';
import { MyUserForm } from './MyUserForm';
import { saveMyUser, getUsersDefaultItem } from './MyUserAPIEndpoints';
import { useResponsive } from 'lib/useResponsive';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material/styles';

function PaperComponent(props) {
    return (
        <Draggable
            handle="#draggable-dialog-title"
            cancel={'[class*="MuiDialogContent-root"]'}
        >
            <Paper {...props} />
        </Draggable>
    );
}

export const MyUserFormDialog = (props) => {
    const { t } = useTranslation();
    const [dialogSubmit, setDialogSubmit] = useState(0);
    const { isMobile } = useResponsive();
    const theme = useTheme();

    let formId = props.id;
    if (!props.open) {
        formId = 0;
    }

    const handleCancel = () => {
        props.onCancel();
    };

    const handleSave = () => {
        setDialogSubmit(dialogSubmit + 1);
    };

    return (
        <>
            { props.open &&
                <Dialog
                    fullScreen={isMobile}
                    fullWidth={true}
                    maxWidth={"md"}
                    open={props.open}
                    PaperComponent={PaperComponent}
                    aria-labelledby="draggable-dialog-title"
                >
                    <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
                        {t("Details")}
                    </DialogTitle>
                    <DialogContent style={{padding: '8px 24px'}}>
                        <MyUserForm
                            id={formId}
                            userId={props.userId}
                            itemToEdit={props.itemToEdit}
                            dialogSubmit={dialogSubmit}
                            onSubmit={async (values) => {
                                let data = await saveMyUser(values);

                                if (data) {
                                    props.onSave(data);
                                }
                            }}
                            onError={(error) => { props.onError(error); }}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button
                            autoFocus
                            data-cy-id={"cancel_button"}
                            onClick={handleCancel}
                            variant={theme.props?.CancelButton?.variant || "outlined"}
                            color={theme.props?.CancelButton?.color}
                        >
                            {t("Cancel")}
                        </Button>
                        <Button
                            onClick={handleSave}
                            variant="contained"
                            data-cy-id={"save_button"}
                        >
                            {t("Save")}
                        </Button>
                    </DialogActions>
                </Dialog>
            }
        </>
    );
}

MyUserFormDialog.defaultProps = {
    open: false,
    id: 0,
    itemToEdit: getUsersDefaultItem(),
    onCancel: () => { console.log("onCancel not implemented"); },
    onSave: (data) => { console.log("onSave not implemented"); },
    onError: (error) => { console.error("onError not implemented", error); },
};

MyUserFormDialog.propTypes = {
    open: PropTypes.bool,
    id: PropTypes.number,
    itemToEdit: PropTypes.object,
};