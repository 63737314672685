import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Paper from '@mui/material/Paper';
import Draggable from 'react-draggable';
import PropTypes from 'prop-types';
import { deleteDelegatedAccount, getDelegatedAccountsDefaultItem } from './MyDelegatedAccountAPIEndpoints';

import { useResponsive } from 'lib/useResponsive';
import { useTranslation } from "react-i18next";
import { useTheme } from '@mui/material/styles';

function PaperComponent(props) {
    return (
        <Draggable
            handle="#draggable-dialog-title"
            cancel={'[class*="MuiDialogContent-root"]'}
        >
            <Paper {...props} />
        </Draggable>
    );
}

/**
 * This component handles the form for creating and editing
 * */
export const MyDelegatedAccountFormDialog = (props) => {
    const { t } = useTranslation();
    const { isMobile } = useResponsive();
    const theme = useTheme();

    const handleCancel = () => {
        props.onCancel();
    };

    const handleDelete = async () => {
        console.log(props.itemToEdit);
        let data = await deleteDelegatedAccount(props.itemToEdit);

        if (data) {
            props.onSave(data);
        }
    };

    return (
        <>
            {props.delete && props.open &&
                <Dialog
                    fullScreen={isMobile}
                    fullWidth={true}
                    maxWidth={"sm"}
                    open={props.open}
                    PaperComponent={PaperComponent}
                    aria-labelledby="draggable-dialog-title"
                >
                    <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
                        {t("Remove Delegated Account")}
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            {t("Are you sure you want to delete this delegated account?")}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button autoFocus onClick={handleCancel} variant={theme.props?.CancelButton?.variant}>
                            {t("Cancel")}
                        </Button>
                        <Button onClick={handleDelete} variant={theme.props?.CancelButton?.variant} color={theme.props?.CancelButton?.color}>
                            {t("Delete")}
                        </Button>
                    </DialogActions>
                </Dialog>
            }
        </>
    );
}

MyDelegatedAccountFormDialog.defaultProps = {
    open: false,
    id: 0,
    itemToEdit: getDelegatedAccountsDefaultItem(),
    onCancel: () => { console.log("onCancel not implemented"); },
    onSave: (data) => { console.log("onSave not implemented"); },
    onError: (error) => { console.error("onError not implemented", error); },
};

MyDelegatedAccountFormDialog.propTypes = {
    open: PropTypes.bool,
    id: PropTypes.number,
    itemToEdit: PropTypes.object,
};