import { getRolesDefaultItem, rolesValidationSchema } from './RoleAPIEndpoints';
import PropTypes from 'prop-types';
import { Formik, Form, Field } from 'formik';
import { useRef, useEffect } from 'react';
import { TextField } from 'formik-mui';

export const RoleForm = (props) => {
    const itemToEdit = props.itemToEdit || getRolesDefaultItem();
    const dialogSubmit = useRef(props.dialogSubmit);
    const formRef = useRef();

    useEffect(() => {
        if (props.dialogSubmit !== dialogSubmit.current) {
            dialogSubmit.current = props.dialogSubmit;
            if (formRef.current) {
                formRef.current.handleSubmit();
            }
        }
    });

    return (
        <Formik
            innerRef={formRef}
            initialValues={itemToEdit}
            validationSchema={rolesValidationSchema}
            onSubmit={(values) => {
                props.onSubmit(values);
            }}
        >
            <Form readonly={props.readonly}>
                <Field
                    name={"name"}
                    component={TextField}
                    label={"Name"}
                    disabled={props.disabled}
                />
                <Field
                    name={"description"}
                    component={TextField}
                    label={"Description"}
                    disabled={props.disabled}
                />
            </Form>
        </Formik>
    );
}

RoleForm.defaultProps = {
    id: 0,
    dialogSubmit: 0,
    changeIteration: 0,
    onSubmit: (values) => { console.log("onSubmit not implemented", values); },
    onError: (error) => { console.error("onError not implemented", error); },
};

RoleForm.propTypes = {
    id: PropTypes.number,
    changeIteration: PropTypes.number,
    dialogSubmit: PropTypes.number,
};