import { getMyUserDelegationsDefaultItem, MyUserDelegationsValidationSchema } from './MyUserDelegationAPIEndpoints';
import PropTypes from 'prop-types';
import { Formik, Form, Field } from 'formik';
import { useRef, useEffect, useState } from 'react';
import { TextField } from 'formik-mui';
import { UcEnumSelect } from 'components/common/UcEnumSelect';
import { useTranslation } from 'react-i18next';
import { AccountSelect } from 'components/customer/accounts/AccountSelect';
import { useUser } from 'components/auth/PermissionApiEndpoint';
import { Grid } from '@mui/material';

/**
 * This component handles the form for creating and editing
 * */
export const MyUserDelegationForm = (props) => {
    const { t } = useTranslation();
    const user = useUser();
    const itemToEdit = props.itemToEdit || getMyUserDelegationsDefaultItem();
    const dialogSubmit = useRef(props.dialogSubmit);
    const [selectedAccount, setSelectedAccount] = useState({id: itemToEdit.accountId || -1, delegationName: itemToEdit.accountName || ""})
    const formRef = useRef();

    useEffect(() => {
        if (props.dialogSubmit !== dialogSubmit.current) {
            dialogSubmit.current = props.dialogSubmit;
            if (formRef.current) {
                formRef.current.values.accountId = selectedAccount.id;
                formRef.current.values.accountName = selectedAccount.accountName;
                formRef.current.values.extAccountNumber = selectedAccount.extAccountNumber;
                formRef.current.values.userId = props.userId;
                formRef.current.handleSubmit();
            }
        }
    });
    
    return (
        <>
        <Formik
            innerRef={formRef}
            initialValues={itemToEdit}
            validationSchema={MyUserDelegationsValidationSchema(user.email)}
                onSubmit={(values) => {
                    values.userId = props.userId;
                    props.onSubmit(values);
            }}
        >
        <div className='ignoreCustomFormStyles'>
            <Form>
                <Grid container spacing={2} style={{paddingTop: '8px'}}>
                    <Grid item xs={12} sm={12} md={12}>
                        <Field
                            name={"accountId"}
                            initialValue={selectedAccount}
                            component={ AccountSelect }
                            label={t("Account")}
                            isAdmin={false}
                            showFullLabel={true}
                            onChange={(event, value) => {
                                setSelectedAccount(value);
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12}>
                        <Field
                            name={"delegatedUserEmail"}
                            component={TextField}
                            label={t("Delegated Email")}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12}>
                        <Field
                            name={"accessLevel"}
                        >
                        {({ field: { value }, form: { setFieldValue } }) => (
                            <UcEnumSelect
                                value={value}
                                disabled={props.disableAccessLevel}
                                enumName="DelegationAccessLevel"
                                label={t("Access Level")}
                                onChange={(e, value) => setFieldValue("accessLevel", value.id)} />
                        )}
                        </Field>
                    </Grid>
                </Grid>
            </Form>
        </div>
        </Formik>
        </>
    );
}

MyUserDelegationForm.defaultProps = {
    id: 0,
    dialogSubmit: 0,
    changeIteration: 0,
    onSubmit: (values) => { console.log("onSubmit not implemented", values); },
    onError: (error) => { console.error("onError not implemented", error); },
};

MyUserDelegationForm.propTypes = {
    id: PropTypes.number,
    changeIteration: PropTypes.number,
    dialogSubmit: PropTypes.number,
};