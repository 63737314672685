import * as React from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
// @ts-ignore
import { useUsers } from 'components/adminUc/Users/UserAPIEndpoints';
import { debounce } from "lodash";
import { User } from "./User.interfaces";

interface UserFullNameSelectProps {
    roleType?: string;
    value: number | null;
    disableClearable?: boolean;
    id?: string;
    onChange: Function;
    forcedSize?: any;
    forcedWidth?: string;
    forcedPadding?: string;
    forcedDisplay?: string;
    label?: string;
}

// From: https://mui.com/components/autocomplete/#Asynchronous.js
export const UserFullNameSelect = (props: UserFullNameSelectProps) => {
    const [open, setOpen] = React.useState(false);
    const [options, setOptions] = React.useState<User[]>([]);
    const [inputValue, setInputValue] = React.useState('');
    
    const needsUpdate = React.useRef(true);

    const roleType = props.roleType ? props.roleType : "";

    const [filter, setFilter] = React.useState({
        take: 100,
        skip: 0,
        sort: null,
        generalSearch: "",
        roleType: roleType,
    });

    let loading = true;
    let loadedOptions: User[] = [];

    const [selectedValue, setSelectedValue] = React.useState<User | null>();

    const swrUsers = useUsers(filter);
    if (swrUsers.result && !swrUsers.isLoading && !swrUsers.isError)
    {
        loadedOptions = swrUsers.result;
        loading = false;
        if (needsUpdate.current) {
            needsUpdate.current = false;
            setOptions([...loadedOptions]);
            setSelectedValue(loadedOptions.find((user: User) => user.id === props?.value));
        }
    }
    else
    {
        needsUpdate.current = true;
        loading = true;
    }

    // Simple debounce so we only query every 200 ms pause while typing
    // TODO use a different debounce. (mantine hooks?)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const changeHandler = React.useCallback(debounce((newGeneralSearch) => {
        if (newGeneralSearch === "") {
            newGeneralSearch = null;
        }
        needsUpdate.current = true;
        setFilter({...filter, generalSearch: newGeneralSearch});
    }, 200), [filter]);

    return (
        <Autocomplete
            disableClearable={props.disableClearable}
            id={props.id || "userSelect"}
            open={open}
            value={selectedValue || null}
            onChange={(event, newValue) => {
                props.onChange(event, newValue);
                setSelectedValue(newValue);
            }}
            inputValue={inputValue}
                onInputChange={(event, newInputValue) => {
                setInputValue(newInputValue);
            }}
            onOpen={() => {
                setOpen(true);
            }}
            onClose={() => {
                if (filter.generalSearch !== "")
                {
                    needsUpdate.current = true;
                    setFilter({...filter, generalSearch: ""});
                }
                setOpen(false);
            }}
            isOptionEqualToValue={(option: User, value) => option?.fullName === value?.fullName || option?.id === value?.id}
            getOptionLabel={(option) => option?.fullName || ''}
            options={options}
            loading={loading}

            size={props.forcedSize || 'normal'}
            style={{width: props.forcedWidth || '100%', padding: props.forcedPadding || 0, display: props.forcedDisplay || 'block'}}
            
            renderInput={(params) => (
                <TextField
                    {...params}
                    onChange={(e) => {
                        changeHandler(e.target.value);
                    }}
                    label={(selectedValue === undefined && inputValue === "") ? (props.label || "Users") : ''}
                    InputLabelProps={{
                        shrink: false
                    }}
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                        <React.Fragment>
                            {loading ? <CircularProgress color="inherit" size={20} /> : null}
                            {params.InputProps.endAdornment}
                        </React.Fragment>
                        ),
                    }}
                    onKeyDown= {(e) => {
                          e.stopPropagation();
                    }}
                />
            )}
        />
    );
};

UserFullNameSelect.defaultProps = {
    onChange: (event: any, value: any) => { console.log("onChange not implemented", event, value); },
};