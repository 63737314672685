import { ApiClient } from "lib/ApiClient";
import { globalTranslator } from "lib/GlobalTranslator";
import { useGenericSWR } from "lib/useGenericSWR";
import { mutateUserAccounts } from './UserAccountAPIEndpoints';
import * as Yup from 'yup';

const apiEndpoint = 'api/v1/admin/UserDelegation';
const t = globalTranslator;

let formIteration = 0;

const getUserDelegationsDefaultItem = () => {
    formIteration = formIteration + 1;
    return {
        formIteration: "new" + formIteration,
        id: 0,
        userDelegationId: 0,
        accessLevel: "ReadAccount",
        createdBy: "REACT_FE",
    }
};

// Had to make this a function so that globalTranslator was initialized
const UserDelegationsValidationSchema = () => {
    return Yup.object().shape({
        delegatedUserEmail: Yup.string().email("Delegated user email must be a valid email address.").required(t("Required")),
        accountId: Yup.number().required(t("Required")).integer(),
        userId: Yup.number().required(t("Required")).integer(),
        accessLevel: Yup.string().required(t("Required")),
    });
};

// See definition from UsageRate.cs
const apiClient = new ApiClient();
apiClient.getDefaultItem = () => getUserDelegationsDefaultItem();
apiClient.getObjectName = () => {
    return t("User Delegation");
};

const mutateUserDelegations = () => {
    apiClient.mutateFetchUrls();
}

const useUserDelegation = (id, swrOptions = {}) => {
    // SWR will prevent duplicate requests
    // It will also maintain data state as long as your are using the same urls for your requests
    return useGenericSWR(apiEndpoint + "/" + id, apiClient, { ...swrOptions });
};

const useUserDelegations = (filter, swrOptions = {}) => {
    // SWR will prevent duplicate requests
    // It will also maintain data state as long as your are using the same urls for your requests
    const params = new URLSearchParams();
    params.append('filter', JSON.stringify(filter));
    return useGenericSWR(apiEndpoint + "?" + params.toString(), apiClient, { ...swrOptions });
};

const saveUserDelegation = (UserDelegation) => {
    if (UserDelegation.id === 0) {
        return insertUserDelegation(UserDelegation);
    }
    return updateUserDelegation(UserDelegation);
}
const insertUserDelegation = (UserDelegation) => {
    mutateUserAccounts();
    return apiClient.poster(apiEndpoint, UserDelegation);
};

const updateUserDelegation = (UserDelegation) => {
    return apiClient.putter(apiEndpoint, UserDelegation);
};

const deleteUserDelegation = (UserDelegation) => {
    mutateUserAccounts();
    return apiClient.deleter(apiEndpoint + "/" + UserDelegation.id);
};

export {
    useUserDelegation,
    useUserDelegations,
    saveUserDelegation,
    insertUserDelegation,
    updateUserDelegation,
    deleteUserDelegation,
    UserDelegationsValidationSchema,
    getUserDelegationsDefaultItem,
    mutateUserDelegations,
};