import * as React from 'react';
import { CirclePicker } from 'react-color';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { FormLabel, Tooltip, Popover, Typography, IconButton } from '@mui/material';
import { COLOR_PICKER_OPTIONS } from './Common.constants'

export const UcColorCirclePicker = (props) => {
    const {existingItems, itemToEdit, label, hint, onChange } = props;
    const [intialLoad, setIntialLoad] = React.useState(true);
    const colorsAvail = COLOR_PICKER_OPTIONS.filter(c => 
        !existingItems.some(i => i.htmlColor === c && i.id !== itemToEdit.id)
      ) || [itemToEdit.htmlColor];
    const [selectedColor, setSelectedColor] = React.useState(props.value || colorsAvail[0]);
    const [anchorEl, setAnchorEl] = React.useState(null);

    const openInfo = Boolean(anchorEl);
    const popoverId = openInfo ? 'hint-popover' : undefined;

    const handleClickInfo = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleCloseInfo = () => {
        setAnchorEl(null);
    };

    const handleColorChangeComplete = (color) => {
        setSelectedColor(color.hex);
        onChange(color.hex)
    }

    React.useEffect(() => {
        if (intialLoad && itemToEdit.htmlColor === null) {
            onChange(selectedColor)
            setIntialLoad(false);
        }
    }, [intialLoad, itemToEdit, selectedColor, onChange])

    return (
        <>       
            { hint !== undefined && (
                <Popover
                    id={popoverId}
                    open={openInfo}
                    anchorEl={anchorEl}
                    onClose={handleCloseInfo}
                    onMouseOut={handleCloseInfo}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                    PaperProps={{
                        style: { width: '400px', paddingRight: '20px', paddingLeft: '20px', paddingBottom: '10px' },
                    }}
                >
                    <Typography sx={{ pt: 2 }}>{ hint }</Typography>
                </Popover>
            )}         
            <FormLabel component="legend">
                {label} 
                <Tooltip title="Info">
                    <IconButton
                        aria-describedby={popoverId}
                        variant="contained"
                        onClick={handleClickInfo}
                        onMouseOver={handleClickInfo}
                    >
                        <InfoOutlinedIcon />
                    </IconButton>
                </Tooltip>
            </FormLabel>
            <CirclePicker
                color={selectedColor}
                colors={colorsAvail.length !== 0 ? colorsAvail : [itemToEdit.htmlColor]}
                onChangeComplete={handleColorChangeComplete}
            />
        </>
    );
}