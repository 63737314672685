// @ts-ignore
import React from 'react';
// @ts-ignore
import { useMyOrgAppSettings } from 'components/customer/orgs/MyOrgAPIEndpoints';
import { GenericSwr } from './useGenericSWR.interfaces';

interface OrgAppSettingsProviderProps {
    children: React.ReactNode;
}

interface OrgAppSettingsContextType {
    orgAppSettings: GenericSwr | null;
}

export const OrgAppSettingsContext = React.createContext<OrgAppSettingsContextType>({
    orgAppSettings: null
});

export const OrgAppSettingsProvider = (props: OrgAppSettingsProviderProps) => {
    const orgAppSettings: GenericSwr = useMyOrgAppSettings();

    const orgAppSettingsProviderValue = React.useMemo(() => ({
        orgAppSettings: orgAppSettings,

    }), [orgAppSettings]);

    return (
        <OrgAppSettingsContext.Provider value={orgAppSettingsProviderValue}>
            { props.children }
        </OrgAppSettingsContext.Provider>
    );
};