import React, { useContext, useEffect } from 'react';
import { UcAdminDetails } from "../../common/UcAdminDetails";
import { PageContext } from '../../../lib/PageProvider';
import { useParams } from "react-router-dom";
import { EnsureUser } from '../../auth/EnsureUser';
import { useHasPermission } from 'components/auth/PermissionApiEndpoint';
import { RoleUserList } from 'components/adminUc/Roles/RoleUserList';
import { UserAccountList } from 'components/admin/Users/UserAccountList';
import { UserMeterList } from 'components/admin/Users/UserMeterList';
import { MeterRateList } from 'components/admin/Customers/MeterRateList';
import { MeterCycleListPage } from 'components/admin/Billings/MeterCycleListPage';
import { useTranslation } from 'react-i18next';

export const UserDetailsPage = (props) => {
    let { userId } = useParams();
    const { t } = useTranslation();
    userId = userId || props.user.id;
    const [pageState, pageDispatch] = useContext(PageContext);

    useEffect(() => {
        pageDispatch({
            header: t("User Details") + t("Required"),
            actionItems: [],
        });
    }, [pageState.header, pageDispatch, t]);

    let detailsRender = <></>;
    let tabs = [
        {
            title: t("Roles"),
            contentRender: <RoleUserList userId={userId} />,
            //residential users should not have access to this tab
            isHidden: !useHasPermission("RoleUser:Read") || props.roleType==="UtilityResidential"
        },
        {
            title: t("Accounts"),
            contentRender: <UserAccountList userId={userId} user={props.user} />,
            isHidden: !useHasPermission("UserAccount:Read")
        },
        {
            title: t("Meters"),
            contentRender: <UserMeterList userId={userId} />,
            isHidden: !useHasPermission("UserAccount:Read")
        },
        {
            title: t("Meter Rates"),
            contentRender: <MeterRateList userId={userId} />,
            isHidden: !useHasPermission("MeterRate:Read")
        },
        {
            title: t("Meter Billing Cycle"),
            contentRender: <MeterCycleListPage userId={userId} />,
            isHidden: !useHasPermission("MeterCycle:Read")
        },
    ];

    return (
        <EnsureUser permissions={["User:Read"]}>
            <UcAdminDetails
                detailsRender={detailsRender}
                tabs={tabs}
                tabInitValue={
                    !props.roleType==="UtilityResidential" ? (
                        t("Roles")
                    ) : (
                        t("Accounts")
                    )
                }
            />
        </EnsureUser>
    );
};

UserDetailsPage.defaultProps = {
    showDetails: true,
};