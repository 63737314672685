// @ts-ignore
import React, { useState, useLayoutEffect, useRef, useEffect, ChangeEvent } from 'react';
// @ts-ignore
import { DataGridPro, GridRowId, GridRowSelectionModel, GridToolbar } from '@mui/x-data-grid-pro';
import { MyBillsAPIWrapper } from 'components/user/bills/MyBillsAPIEndpoint';
// @ts-ignore
import { UcPagination } from 'components/common/UcPagination';
// @ts-ignore
import { useTranslation } from 'react-i18next';
// @ts-ignore
import { UcIconButtons } from 'components/common/UcIconButtons';
// @ts-ignore
import { getWholeRow } from '../../../lib/DataGridHelpers';
// @ts-ignore
import LaunchIcon from '@mui/icons-material/Launch';
// @ts-ignore
import { dateFormatter } from '../../../lib/Formatters';
import { Bill } from './MyBills.interfaces';
import { AccountContext } from '../../../lib/AccountProvider';
import { generateRandom } from "../../utils/general.helpers";
import { UcLoading } from '../../common/UcLoading';
import { UcProgress } from '../../common/UcProgress';

interface MyBillsGridProps {
    sort: {
        field: string;
        sort: string;
    }[],
    generalSearch: string | undefined | null,
    dataState: any,
    onSelected: (newValues: {}) => void;
    onFilterChange: (e: ChangeEvent<HTMLInputElement>) => void,
    toolbar: any
}

export const MyBillsGrid = (props: MyBillsGridProps) => {
    const initialSort = [{ field: "statementDate", sort: "desc" }];
    const sort = props.sort || initialSort;
    const { t } = useTranslation();
    const initialDataState = props.dataState || { skip: 0, take: 10, };
    const { account } = React.useContext(AccountContext);
    const [isFetching, setIsFetching] = React.useState(true);
    const [billData, setBillData] = React.useState<Bill | null>(null);
    const [hasError, setHasError] = useState(false);
    const [progress, setProgress] = useState({ current: 0, total: 100 });

    const [filter, setFilter] = useState({
        take: initialDataState.take,
        skip: initialDataState.skip,
        sort: sort,
        generalSearch: props.generalSearch,
    });

    useEffect(() => {
        if (props.generalSearch !== filter.generalSearch) {
            setFilter({ ...filter, generalSearch: props.generalSearch });
        }
    }, [filter, setFilter, props.generalSearch]);

    const needsDataUpdate = useRef("");

    const getFileName = (bill: any) => {
        return encodeURIComponent(dateFormatter(bill.billStartDate, true) + bill.accountNumber);
    }

    let defaultDataItems = React.useMemo(() => {
        return {
            columns: [
                { field: 'accountAddress', headerName: t('Address'), width: 200 },
                { field: 'accountNumber', headerName: t('Account Number'), width: 200 },
                { field: 'statementDate', headerName: t('Statement Date'), width: 150, valueFormatter: (params: any) => { return dateFormatter(params.value, true); } },
                { field: 'dueDate', headerName: t('Bill Due Date'), width: 150, valueFormatter: (params: any) => { return dateFormatter(params.value, true); } },
                { field: 'totalAmountDue', type: 'number', headerName: t('Amount Due'), width: 150, valueFormatter: (params: any) => { return (Math.round(params.value * 100) / 100).toFixed(2); } },
                { field: 'balanceForward', type: 'number', headerName: t('Balance Forward'), width: 150 },
                {
                    field: 'open', headerName: t('Open'), width: 100, sortable: false, filterable: false, disableColumnMenu: true,
                    valueGetter: getWholeRow,
                    renderCell: (params: any) => {
                        const actions = [
                            {
                                title: t('Open'),
                                icon: <LaunchIcon data-cy-id={"myBillsOpenButton"} />,
                                color: 'primary',
                                isHidden: false,
                                isDisabled: false,
                                requiredPermission: 'Org:Modify',
                                href: `api/v1/MyBills/getPDF/${getFileName(params.value)}?accountNumber=${params.value.accountNumber}`,
                                /* href: `customer/MyBills/pdf/${params.value.accountNumber}`, This is using react but can't name the bill*/
                                target: '_blank',
                            },
                        ];
                        return (
                            <UcIconButtons actions={actions} />
                        );
                    }
                }
            ],
            rows: [],
            rowCount: -1,
        }
    }, [t]);
    const [dataItems, setDataItems] = useState<any>(defaultDataItems);
    const defaultSelectedItem = { dataItem: {}, selectedId: 0 };
    const [selectedItem, setSelectedItem] = useState<any>(defaultSelectedItem);

    const isDataLoaded = useRef(false);

    if (!billData && defaultDataItems.rowCount !== -1) {
        setDataItems(defaultDataItems);
    }
    
    useLayoutEffect(() => {
        const dataReceived = (swrResult: any) => {
            let resultItems = swrResult;
    
            let selectedDataItems = resultItems.map((item: any) => {
                return { ...item, selected: item.id === selectedItem.selectedId };
            });
    
            selectedDataItems.iteration = resultItems.iteration;
            selectedDataItems.rowCount = swrResult.rowCount;
    
            if (selectedDataItems.length > selectedDataItems.rowCount) {
                selectedDataItems.rowCount = selectedDataItems.length;
            }
    
            setDataItems({ ...defaultDataItems, rows: [...resultItems], rowCount: swrResult.rowCount });
        };

        if (billData) {
            isDataLoaded.current = true;
            const filterString = JSON.stringify(filter);
            if (needsDataUpdate.current !== filterString) {
                needsDataUpdate.current = filterString;
            }

            dataReceived(billData);
        }
    }, [billData, defaultDataItems, filter, selectedItem.selectedId]);

    const updateFilter = (newFilter: any) => {
        setFilter(newFilter);
        props.onFilterChange(newFilter);
    };

    return (
        <div
            data-cy-id={"myBillsGridWrapper"}
        >
            <UcLoading hasFade={false}
                hasError={hasError}
                isLoading={isFetching}
                height={'20vh'}
                errorMessage={t("An error occurred retrieving account bills.")}
                loadingRender={<UcProgress {...progress} />}
            >
                <DataGridPro
                    localeText={{
                        noRowsLabel: t("No bill data available.")
                    }}
                    slots={{ toolbar: GridToolbar }}
                    slotProps={{
                        toolbar: {
                            showQuickFilter: true,
                        },
                    }}
                    initialState={{
                        pinnedColumns: { right: ['open'] },
                        filter: {
                            filterModel: {
                                items: [],
                                quickFilterValues: [''],
                                quickFilterExcludeHiddenColumns: true,
                            }
                        },
                        pagination: { paginationModel: { pageSize: 10 } },
                    }}
                    autoHeight={true}
                    getRowId={() => generateRandom() }
                    disableColumnFilter={true}
                    disableColumnSelector={true}
                    sortingMode='client'
                    filterMode='client'
                    sortModel={filter.sort}
                    onSortModelChange={(model) => updateFilter({ ...filter, sort: model })}
                    pageSizeOptions={[5, 10, 20]}
                    onRowSelectionModelChange={(newSelectionModel: GridRowSelectionModel) => {
                        if (newSelectionModel.length !== 1) {
                            setSelectedItem(defaultSelectedItem);
                            props.onSelected(defaultSelectedItem.dataItem);
                            return;
                        }
                        const selectedId: GridRowId = newSelectionModel[0];
                        var selectedData = dataItems.rows.filter((obj: any) => { return obj.id === selectedId });
                        if (selectedData.length !== 1) {
                            setSelectedItem(defaultSelectedItem);
                            props.onSelected(defaultSelectedItem.dataItem);
                            return;
                        }
                        selectedData = selectedData[0];
                        var newlySelectedItem = { dataItem: selectedData, selectedId: selectedId };
                        setSelectedItem(newlySelectedItem as any);
                        props.onSelected(newlySelectedItem.dataItem);
                    }}
                    pagination
                    components={{
                        Toolbar: () => {return  props.toolbar || <></>},
                        Pagination: UcPagination,
                    }}
                    {...dataItems}
                />
            </UcLoading>
            {account?.id !== null && (
                <MyBillsAPIWrapper
                    readyForData={!hasError}
                    onDataCompleted={(newData) => {
                        setIsFetching(false);
                        setBillData(newData);
                        setHasError(false);
                    }}
                    onProgress={(progressLocal) => {
                        if (progressLocal.current !== progress.current) {
                            setProgress({ ...progressLocal });
                        }
                    }}
                    onReset={() => {
                        setProgress({ current: 0, total: 100 });
                        setIsFetching(true);
                        setHasError(false);
                    }}
                    onError={(error) => {
                        console.error(error);
                        setIsFetching(false);
                        setHasError(true);
                    }}
                />
            )}
        </div>
    );
}

MyBillsGrid.defaultProps = {
    onSelected: (selectedItem: any) => { console.log("onSelected not implemented", selectedItem); },
    onFilterChange: (filter: any) => { console.log("onFilterChange not implemented", filter); },
};
