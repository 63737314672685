import React, { useEffect, useState } from 'react';
// @ts-ignore
import { DateTime } from 'luxon';
import { useUserUtilitiesClockSettings } from 'components/utils/UserUtilitiesApiEndpoints';
import { GenericSwr } from './useGenericSWR.interfaces';
import { API_CONFIG } from 'utils/constants/api.constants';

interface UserUtilitiesContextType {
    clock: DateTime;
    clockSettings: GenericSwr | null;
}

interface UserUtilitiesProviderProps {
    children: React.ReactNode;
}

const defaultState = {
    clock: DateTime.now(),
    clockSettings: null
};

export const UserUtilitiesContext = React.createContext<UserUtilitiesContextType>(defaultState);

export const UserUtilitiesProvider = (props: UserUtilitiesProviderProps) => {
    const [clock, setClock] = useState(defaultState.clock);
    const clockSettings = useUserUtilitiesClockSettings(clock);

    useEffect(() => {
        const interval = setInterval(() => {
            setClock(DateTime.now());
        }, API_CONFIG.cache_exiration_milliseconds);

        return () => clearInterval(interval);
    }, []);

    const providerValue = React.useMemo(() => ({
        clock: clock,
        clockSettings: clockSettings
    }), [clock, clockSettings]);

    return (
        <UserUtilitiesContext.Provider value={providerValue}>
            {props.children}
        </UserUtilitiesContext.Provider>
    );
};