import React from 'react';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardActionArea from '@mui/material/CardActionArea';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Typography from '@mui/material/Typography';
import Alert from '@mui/material/Alert';
import { UcCardMenu } from './UcCardMenu';
import { useTranslation } from "react-i18next";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import { useTheme } from '@mui/material/styles';
import UcDataItemCardDialog from '../common/UcDataItemCardDialog';

import CircleIcon from '@mui/icons-material/Circle';
import CampaignIcon from '@mui/icons-material/Campaign';
import CrisisAlertIcon from '@mui/icons-material/CrisisAlert';
import NotificationsIcon from '@mui/icons-material/Notifications';
import PushPinIcon from '@mui/icons-material/PushPin';
import CloseIcon from '@mui/icons-material/Close';
import { DateTime } from 'luxon';
import { Box } from '@mui/material';
import { updateUserNotifications } from '../user/UserNotificationAPIEndpoints';

export default function UcNotificationDataItemCards(props) {
    const { t } = useTranslation();
    const { loading, dataItems, actionsHandler, hideBody, hideCard, onChange} = props;
    const [expandedId, setExpandedId] = React.useState(-1);
    const theme = useTheme();
    
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [currentCard, setCurrentCard] = React.useState({ title: null, content: null });

    const handleOpenDialog = (event, title, content, userNotification) => {
        if (userNotification.isAcknowledged === false) {
            userNotification.isAcknowledged = true;
            updateUserNotifications(userNotification, false);
        }
        setAnchorEl(event.currentTarget);
        setCurrentCard({ title: title, content: content });
    };

    const handleCloseMenu = () => {
        setAnchorEl(null);
        onChange();   
    };

    const formatterDisplay = (col, row) => {
        if (col?.valueFormatter !== undefined) {
            return col.valueFormatter({"value": row[col?.field]})
        }
        else {
            return row[col?.field]
        }
    };

    const handleExpandClick = (i) => {
        setExpandedId(expandedId === i ? -1 : i);
    };

    const handleHide = (userNotification) => {
        if (userNotification.isHidden === false) {
            userNotification.isHidden = true;
            updateUserNotifications(userNotification, false);
            onChange();
        }
    };

    const ExpandMore = styled((props) => {
        const { expand, ...other } = props;
        return <IconButton {...other} />;
      })(({ theme, expand }) => ({
        transform: expand !== expandedId ? 'rotate(0deg)' : 'rotate(180deg)',
        marginLeft: 'auto',
        transition: theme.transitions.create('transform', {
          duration: theme.transitions.duration.shortest,
        }),
    }));

    const CardIcon = (props) => {
        const { row } = props;

        if (row.notificationType === "Announcement") {
            return <Tooltip title={row.notificationType}>
                <CampaignIcon
                    data-cy-id={!row.isAcknowledged ? "unread" : "read"}
                    sx={{
                        ml: 2,
                        color: !row.isAcknowledged ?
                            theme.palette.info.light : theme.props?.Widget?.greyIndicator
                    }}
                />
            </Tooltip>;
        }
        else if (row.notificationType.includes("Money") || row.notificationType.includes("Usage")) {
            return <Tooltip title={row.notificationType} >
                <NotificationsIcon
                    data-cy-id={!row.isAcknowledged ? "unread" : "read"}
                    sx={{
                        ml: 2,
                        color: !row.isAcknowledged ?
                            theme.palette.info.light : theme.props?.Widget?.greyIndicator
                    }}
                />
            </Tooltip>;
        }
        else if (row.notificationType.includes("Outage")) {
            return <Tooltip title={row.notificationType} >
                 <CrisisAlertIcon
                    data-cy-id={!row.isAcknowledged ? "unread" : "read"}
                    sx={{
                        ml: 2,
                        color: !row.isAcknowledged ?
                            theme.palette.info.light : theme.props?.Widget?.greyIndicator
                    }}
                />
             </Tooltip>;
        }
        else {
            return <Tooltip title={row.notificationType} >
                <CircleIcon
                    data-cy-id={!row.isAcknowledged ? "unread" : "read"}
                    sx={{
                        ml: 2,
                        color: !row.isAcknowledged ?
                            theme.palette.info.light : theme.props?.Widget?.greyIndicator
                    }}
                />
             </Tooltip>;
        }
    }

    // eslint-disable-next-line no-unused-vars
    const checkIfDateIsRecent = (date, days) => {
        const currentDate = DateTime.now();
        const notificationDate = DateTime.fromJSDate(new Date(date));
        const diff = currentDate.diff(notificationDate, ["days"])

        return (diff["days"] <= days)
    };
    
    return ( 
        <>
            <Grid container spacing={0}>
                { dataItems?.rows.filter(row => !row.isHidden).map((row, index) => (
                    <Grid item sm={12} md={12} xs={12} key={index}>
                        <Card
                            id={`card_dataItem_${row.id}`}
                            raised={true}
                            sx={{
                                opacity: 1,
                                boxShadow: "none",
                                borderTop: 'solid 1px #efefef',
                                borderRadius: 0,
                                backgroundColor: row.isPinned && theme.props?.Widget?.pinnedNotification,
                            }}
                        >
                            <CardActionArea>
                            <Grid container direction="row" alignItems="center" data-cy-id={"notification_item_"+row?.title}>
                                    <Grid item>
                                        <CardIcon row={row}/>  
                                    </Grid>
                                    <Grid item xs
                                        zeroMinWidth id={"notification_data_card_item_" +index}
                                        data-cy-id={"notification_data_card_item_" +row?.title}
                                        title={row?.title}       
                                    >
                                        <div
                                            onClick={(event) => 
                                                {handleOpenDialog(event,
                                                formatterDisplay(dataItems?.cardHeaderField, row),
                                                formatterDisplay(dataItems?.cardDialogField, row),
                                                row
                                            )}}
                                        >
                                            {!hideCard && (
                                                <CardHeader
                                                    title={dataItems?.cardHeaderField && (
                                                        <Typography variant="subtitle1" style={{wordBreak: "break-word", lineHeight: '20px'}}>
                                                            {formatterDisplay(dataItems?.cardHeaderField, row)}
                                                        </Typography>
                                                        )
                                                    }
                                                    subheader = {dataItems?.cardSubHeaderField && (
                                                        <Typography variant="subtitle" style={{wordBreak: "break-word", opacity: 0.5}}>
                                                            {formatterDisplay(dataItems?.cardSubHeaderField, row)}
                                                        </Typography>

                                                    )}
                                                    action={
                                                        <>
                                                            {actionsHandler && (
                                                                <UcCardMenu
                                                                    actions={actionsHandler(row, true)}
                                                                />
                                                            )}
                                                            {dataItems?.cardCollapseField && (
                                                                <ExpandMore
                                                                    expand={index}
                                                                    onClick={() => handleExpandClick(index)}
                                                                    aria-expanded={index}
                                                                    aria-label="show more"
                                                                    >
                                                                    <ExpandMoreIcon />
                                                                </ExpandMore>
                                                            )}
                                                        </>
                                                    }
                                                />
                                            )}
                                        </div>
                                    </Grid>
                                    <Grid item justifyContent="flex-end">
                                        <Box display="flex" justifyContent="flex-end">
                                            {row.isPinned ? (
                                                <Tooltip title={t("Pinned Notification")}>
                                                    <PushPinIcon
                                                        data-cy-id={"notification_pin_"+row?.title}
                                                        sx={{ 
                                                            mr: 3,
                                                            color: theme.palette.info.dark,
                                                            transform: "rotate(40deg)"
                                                        }}
                                                    />    
                                                </Tooltip>
                                            ) : (
                                                <Tooltip title={t("Hide Notification")}>
                                                    <IconButton
                                                    variant="contained"
                                                    sx={{color: 'primary.main', mr: 2}}
                                                    data-cy-id={"notification_hide_"+row?.title}
                                                    onClick={() => handleHide(row)}
                                                    >
                                                        <CloseIcon />
                                                    </IconButton> 
                                                </Tooltip>
                                                )
                                            }   
                                        </Box>
                                    </Grid>
                                    <CardActionArea>
                                        {!hideBody && (
                                            <CardContent sx={{backgroundColor: props.bodyPalette}}>
                                            <Grid container rowSpacing={1}>
                                                { dataItems?.columns.map((col, i) => (!col.hide && !col.cardHide
                                                && col.field!== dataItems?.cardHeaderField && (
                                                    <>
                                                    <Grid item xs={col.cardColumnWidth}>
                                                        <Typography variant="caption" display="block">
                                                            {col.headerName}
                                                        </Typography>
                                                        <Typography variant="subtitle2">
                                                            {formatterDisplay(col, row)}
                                                        </Typography>
                                                    </Grid>
                                                    </>
                                                )))}
                                            </Grid>
                                            </CardContent>
                                        )}
                                    </CardActionArea>
                                    {dataItems?.cardCollapseField && (
                                        <Collapse in={expandedId === index} timeout="auto" unmountOnExit sx={{ mr: 3, ml: 3 }}>
                                            {formatterDisplay(dataItems?.cardCollapseField, row)}
                                        </Collapse>
                                    )}
                                </Grid>
                            </CardActionArea>
                        </Card>
                    </Grid>
                ))}
                {(dataItems?.rows.filter(row => !row.isHidden).length <= 0) && !loading && (
                    <Grid item xs={12} sm={12} md={12}>
                        <Alert severity="info" sx={{mt: 2}}>{t("No recent messages - you're all caught up!")}</Alert>
                    </Grid>
                )}
            </Grid>
            <UcDataItemCardDialog
                anchorEl={anchorEl}
                dialogContent={currentCard}
                onCloseDialog={handleCloseMenu}
            />
        </>
    );
}