import React, { useContext, useState } from 'react';
import { NavLink } from 'react-router-dom';
import ResponsiveAppBar from './ResponsiveAppBar';
import { PageContext } from '../lib/PageProvider';
import { Box, Fab, SpeedDial, SpeedDialAction, SpeedDialIcon, Typography, Breadcrumbs, Link } from '@mui/material';
import { getCurrentRoutePathFromPageState, buildBreadcrumbs } from '../routes'
import './Layout.scss';
import SupervisedUserCircleIcon from '@mui/icons-material/SupervisedUserCircle';
import { UcImpersonateDialog } from './common/UcImpersonateDialog';
import { UcWidget } from './common/obsolete/UcWidget';
import { useTranslation } from 'react-i18next';
import { useUser } from './auth/PermissionApiEndpoint';
import { getUrl } from 'domain';
import { UcFooter } from './common/UcFooter';

export const Layout = (props) => {
    const user = useUser();
    const { t } = useTranslation();
    const [pageState] = useContext(PageContext);
    const routePath = getCurrentRoutePathFromPageState(pageState);
    const breadcrumbs = buildBreadcrumbs(routePath, []);

    const [openImpersonateDialog, setOpenImpersonateDialog] = useState(false);
    const [isOutageMapPage, setIsOutageMapPage] = useState(false);

    React.useEffect(() => {
        if (props.currentRoutePath.includes("outageMap")) {
            setIsOutageMapPage(true);
        }
        else {
            setIsOutageMapPage(false);
        }
    }, [setIsOutageMapPage, props.currentRoutePath]);
    

    const handleOpenImpersonateDialog = () => {
        setOpenImpersonateDialog(true);
    };

    const handleCloseImpersonateDialog = async () => {
        setOpenImpersonateDialog(false);
    };

    return (
        <div className="layout" key="layout">
            <ResponsiveAppBar isOutageMapPage={isOutageMapPage}>
                <Breadcrumbs aria-label="breadcrumb" className="breadcrumb">
                    {breadcrumbs?.length > 0 &&
                        breadcrumbs.reverse().map((crumb, index) => (
                            <Typography variant="caption" key={"layoutBreadCrumbTypography" + crumb.displayName} data-cy-id={"breadcrumb-" + crumb.displayName}>
                                { crumb.path !== undefined ? (
                                    <Link key={"layoutBreadCrumb" + crumb.displayName} underline="hover" color="primary" to={getUrl(crumb.path)} component={NavLink}>{crumb.displayName}</Link>
                                ) : (
                                    crumb.displayName
                                )}
                            </Typography>
                        ))
                    }
                    { breadcrumbs.length > 0 && (
                        <Typography color="inherit" variant="caption">
                            {pageState.variableHeader !== "" ? pageState.variableHeader : pageState.header}
                        </Typography>
                    )}
                </Breadcrumbs>
                <div data-cy-id={props.currentRoutePath}>
                    {(props.currentRoutePath?.includes('admin/') && !props.currentRoutePath?.includes('admin/payments') && !props.currentRoutePath?.includes('admin/utilityDashboard')) ? 
                        <UcWidget>
                            {props.children}
                        </UcWidget>
                    : props.currentRoutePath?.includes('admin') ? // Admin Dashboard
                        <>
                            {props.children}
                        </>
                    :
                        <>
                            {props.children}
                            <UcFooter />
                        </>                          
                    }
                </div>
                {pageState.actionItems.length > 0 &&
                    <SpeedDial
                        ariaLabel={pageState.header + " actions"}
                        sx={{ position: 'absolute', bottom: 16, right: 16 }}
                        icon={<SpeedDialIcon />}
                    >
                        {pageState.actionItems.map((action) => (
                            <SpeedDialAction
                                key={action.title}
                                icon={action.icon}
                                onClick={action.onClick}
                                tooltipTitle={action.title}
                            />
                        ))}
                    </SpeedDial>
                }
            </ResponsiveAppBar>
            {user?.isImpersonating && (
                <>
                    <Box sx={{ position: "fixed", bottom: 16, right: 16 }}>
                        <Fab
                            color="secondary"
                            variant="extended"
                            onClick={handleOpenImpersonateDialog}
                            data-cy-id="impersonateUserDialogOpen"
                        >
                            <SupervisedUserCircleIcon sx={{ mr: 1 }} />
                            {t("Stop Impersonating")}
                        </Fab>

                    </Box>
                    <UcImpersonateDialog
                        open={openImpersonateDialog}
                        isImpersonating={user.isImpersonating}
                        onClose={handleCloseImpersonateDialog}
                    />
                </>
            )}
        </div>
    );
}
