import * as React from 'react';
import { roleApiEndpoint } from 'components/adminUc/Roles/RoleAPIEndpoints';
import { UcSelect } from 'components/common/UcSelect';

export const RoleSelect = (props) => {
    const filter = {
        take: 100,
        skip: 0,
        sort: [],
        generalSearch: ""
    };

    return (
        <UcSelect {...props}
            apiEndPoint={roleApiEndpoint}
            defaultLabel={"Roles"}
            filter={filter} />
    );
};