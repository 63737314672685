import React, { useState } from 'react';
import LoadingButton from '@mui/lab/LoadingButton';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import PropTypes from 'prop-types';
// @ts-ignore
import { useResponsive } from 'lib/useResponsive';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material/styles';
import { UserMeterForm } from './UserMeterForm';
import { ThemeProps } from "components/common/Common.interfaces";
import CustomPaperComponentConstructor from 'components/utils/CustomPaperComponent';
import { saveOutage } from '../Outages/OutageAPIEndpoints';

interface UserMeterDialogProps {
    id: number | null | undefined;
    itemToEdit: any;
    open: boolean;
    onCancel: Function;
    onSave: Function;
    onError: Function;
}

export const UserMeterDialog = (props: UserMeterDialogProps) => {
    const { t } = useTranslation();
    const [saving, setSaving] = React.useState(false);
    const [dialogSubmit, setDialogSubmit] = useState<number>(0);
    const { isMobile } = useResponsive();
    const theme: ThemeProps = useTheme();

    let formId = props.id;
    if (!props.open) {
        formId = 0;
    }

    const handleCancel = () => {
        props.onCancel();
    };

    const handleSendOutageEmail = () => {
        setDialogSubmit(dialogSubmit + 1);
    };

    return (  
        <Dialog
            fullScreen={isMobile}
            fullWidth={true}
            maxWidth={"md"}
            open={props.open}
            PaperComponent={CustomPaperComponentConstructor}
            aria-labelledby="draggable-dialog-title"
        >
            <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
                {t("Send Outage Message")}
            </DialogTitle>
            <DialogContent>
                <UserMeterForm
                    id={formId}
                    disabled={false}
                    itemToEdit={props.itemToEdit}
                    dialogSubmit={dialogSubmit}
                    onSubmit={async (values: any) => {
                        setSaving(true);
                        try {
                            let data = await saveOutage(values);
                            if (data) {
                                props.onSave(data);
                            }
                        } finally {
                            setSaving(false);
                        }
                    }}
                    onError={(error) => {
                        props.onError(error);

                        setSaving(false);
                    }}
                />
            </DialogContent>
            <DialogActions>
                <LoadingButton
                    loading={saving}
                    onClick={handleSendOutageEmail}
                    variant="contained"
                    color="error"
                    style={{ justifyContent:"left", alignItems:"left", display:"flex"} }
                >
                    {t('Send Outage Email To User')}
                </LoadingButton>
                <Button autoFocus onClick={handleCancel} variant={theme.props?.CancelButton?.variant || "outlined"} color={theme.props?.CancelButton?.color}>
                    {t('Cancel')}
                </Button>
            </DialogActions>
        </Dialog>
    );
}

UserMeterDialog.defaultProps = {
    open: false,
    id: 0,
    onCancel: () => { console.log("onCancel not implemented"); }
};

UserMeterDialog.propTypes = {
    open: PropTypes.bool,
    id: PropTypes.number,
    itemToEdit: PropTypes.object,
};