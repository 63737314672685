import * as React from 'react';
import { ApiClient } from "lib/ApiClient";
import { useGenericSWR } from "lib/useGenericSWR";
import { UserInfoContext } from "lib/UserInfoProvider";
import { useFrontEndAppInfo } from "lib/FrontEndAppInfo";

const apiEndpoint = 'api/v1/Permission';
const apiEndpointReset = 'api/v1/Permission/resetPermissionCache';

const apiClient = new ApiClient();
apiClient.getDefaultItem = () => { return { permission: "" }; };
apiClient.getObjectName = () => {
    return "Permission";
};

/// https://swr.vercel.app/docs/options
const usePermissions = (swrOptions = {
    refreshInterval: (15*60*1000), // Refresh every 15 min
}) => {
    const { userInfo } = React.useContext(UserInfoContext);
    apiClient.hideNotLoggedInError = false;
    // SWR will prevent duplicate requests
    // It will also maintain data state as long as your are using the same urls for your requests
    const swrPermission = useGenericSWR(apiEndpoint, apiClient, { ...swrOptions });

    userInfo.cepOrgId = swrPermission.result?.currentCepOrgId;

    return swrPermission;
};

/// https://swr.vercel.app/docs/options
const usePermissionsReset = (swrOptions = {}) => {
    // SWR will prevent duplicate requests
    // It will also maintain data state as long as your are using the same urls for your requests
    return useGenericSWR(apiEndpointReset, apiClient, { ...swrOptions });
};

const useHasPermission = (permission) => {
    const swrPermission = usePermissions();

    if (swrPermission.isLoading || swrPermission.isError) {
        return false;
    }

    if (swrPermission.result.error) {
        return false;
    }

    let result = swrPermission.result.permissions.find(allowed => allowed.permission === permission);
    return !!result;
};

const useHasModule = (module) => {
    const swrPermission = usePermissions();

    if (swrPermission.isLoading || swrPermission.isError) {
        return false;
    }

    if (swrPermission.result.error) {
        return false;
    }

    let result = swrPermission.result.orgModules?.some(item => item.module.includes(module));
    return !!result;
};

const usePermissionIsLoading = () => {
    const swrPermission = usePermissions();
    return swrPermission.isLoading;
}

// Should probably call this file something else now that enums and other app info have made it in here
const useEnums = (enumType) => {
    const swrPermission = usePermissions();

    if (swrPermission.isLoading || swrPermission.isError) {
        return false;
    }

    if (!swrPermission.result) {
        return false;
    }

    let result = swrPermission?.result?.appInfo?.enums[enumType];
    if (!result) {
        return [];
    }
    return result;
};

const useCurrentCepOrgId = () => {
    const swrPermission = usePermissions();

    if (swrPermission.isLoading || swrPermission.isError) {
        return -1;
    }

    let result = swrPermission.result?.currentCepOrgId;
    if (!result) {
        return -1;
    }
    return result;
};

const useOriginalCepOrgId = () => {
    const swrPermission = usePermissions();

    if (swrPermission.isLoading || swrPermission.isError) {
        return -1;
    }

    let result = swrPermission.result?.originalCepOrgId;
    if (!result) {
        return -1;
    }
    return result;
};

const useCepOrgs = () => {
    const swrPermission = usePermissions();

    if (swrPermission.isLoading || swrPermission.isError) {
        return [];
    }

    let result = swrPermission.result?.orgs;
    if (!result) {
        return [];
    }
    return result;
};

const useAppInfo = () => {
    const swrPermission = usePermissions();
    const frontendAppInfo = useFrontEndAppInfo();

    if (swrPermission.isLoading || swrPermission.isError) {
        return {};
    }

    let result = swrPermission.result;
    if (!result) {
        return {};
    }

    if (!frontendAppInfo.isLoading)
    {
        result.appInfo.jsHash = frontendAppInfo.data.jsHash;
        result.appInfo.cssHash = frontendAppInfo.data.cssHash;
        result.appInfo.jsHashTarget = frontendAppInfo.data.jsHashTarget;
        result.appInfo.cssHashTarget = frontendAppInfo.data.cssHashTarget;
    }

    return result;
};

const useUser = () => {
    const swrPermission = usePermissions();

    if (swrPermission.isLoading || swrPermission.isError) {
        return {};
    }

    let result = swrPermission.result?.user;
    if (!result) {
        result = {};
    }
    else {
        result.isLoaded = true;
    }

    let pendingUserNotificationsCount = swrPermission.result?.pendingUserNotificationsCount;
    result.pendingUserNotificationsCount = pendingUserNotificationsCount;

    let impersonations = swrPermission.result?.impersonations;
    result.isImpersonating = impersonations ? (impersonations.length > 0) : false;
    if (result.isImpersonating)
    {
        result.impersonatingUsername = impersonations[0].impersonatingUsername;
        result.impersonatingUserId = impersonations[0].impersonatingUserId;
        result.impersonate = impersonations[0];
    }
    else
    {
        result.impersonatingUsername = null;
        result.impersonatingUserId = null;
        result.impersonate = null;
    }

    return result;
};

// NOTE:
// This isn't used but we can keep it as an example for wrapping a custom hook.
// Allows us to call hook conditionally
const HasPermissionWrapper = ({ setState, permission }) => {
    const hasPermission = useHasPermission(permission);

    React.useEffect(() => {
        setState(hasPermission);
    }, [setState, hasPermission]);

    return null;
};

const mutatePermissions = () => {
    apiClient.mutateFetchUrls();
}

export {
    usePermissions,
    usePermissionsReset,
    useHasPermission,
    useHasModule,
    usePermissionIsLoading,
    useEnums,
    useCurrentCepOrgId,
    useOriginalCepOrgId,
    useCepOrgs,
    useAppInfo,
    useUser,
    HasPermissionWrapper,
    mutatePermissions
};