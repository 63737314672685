
import { ErrorBoundaryMessage } from 'ErrorBoundaryMessage';
import React from 'react';

class ErrorBoundary extends React.Component {
    constructor(props) {
      super(props);
      this.state = { error: null, errorInfo: null, href: null };
    }

    componentDidCatch(error, errorInfo) {
      // Catch errors in any components below and re-render with error message
      this.setState({
        error: error,
        errorInfo: errorInfo,
        href: window?.location?.href
      })
      // You can also log error messages to an error reporting service here
    }

    // Need to reset the state when we navigate to another page
    componentDidUpdate() {
        if (this.state.href !== null && this.state.href !== window.location.href) {
          this.setState({ error: null, errorInfo: null, href: null });
        }
      }

    render() {
      if (this.state.errorInfo) {
        // Error path
        return (
          <ErrorBoundaryMessage {...this.state}  />
        );
      }
      // Normally, just render children
      return this.props.children;
    }
  }

  export default ErrorBoundary;