import { useState, useRef, useEffect } from 'react';
import { getMyHelpRequestsDefaultItem, myHelpRequestValidationSchemaPortal, myHelpRequestValidationSchemaLanding } from './MyHelpRequestAPIEndpoints';
import { Formik, Form, Field } from 'formik';
import { Grid, Alert } from '@mui/material';
import { TextField } from 'formik-mui';
import { useTranslation } from "react-i18next";
import { Account } from '../../lib/AccountProvider';
//@ts-ignore
import { MyAccountSelectCustom } from 'components/customer/accounts/MyAccountSelectCustom';
// @ts-ignore
import { useAuthUser } from "components/auth/useAuthUser";

interface MyHelpRequestFormProps {
    account?: Account | null,
    id: number,
    dialogSubmit: number,
    itemToEdit: {
        cepOrgId?: number | null,
        name?: string | null,
        accountNumber?: string | null,
        email?: string | null,
        formIteration?: string,
        id?: number,
        subject: string | null,
        question: string | null
    },
    disabled: boolean | null | undefined,
    onSubmit: Function,
    onError: Function
}
export const MyHelpRequestForm = (props: MyHelpRequestFormProps) => {
    const authUser = useAuthUser();
    const itemToEdit = props.itemToEdit || getMyHelpRequestsDefaultItem();
    const [selectedName, setSelectedName] = useState<any>(props?.account);
    const [selectedAccountNumber, setSelectedAccountNumber] = useState<any>(props?.account);
    const dialogSubmit = useRef(props.dialogSubmit);
    const formRef = useRef<any>();
    const { t } = useTranslation();

    useEffect(() => {
        if (props.dialogSubmit !== dialogSubmit.current) {
            dialogSubmit.current = props.dialogSubmit;
            if (formRef.current) {
                if (authUser.isLoggedIn) {
                    formRef.current.values.name = selectedName;
                    formRef.current.values.accountNumber = selectedAccountNumber;
                }

                formRef.current.handleSubmit();
            }
        }
    });

    return (
        <div className='ignoreCustomFormStyles' style={{ margin: "15px" }} >
            <Formik
                enableReinitialize
                innerRef={formRef}
                initialValues={itemToEdit}
                validationSchema={authUser.isLoggedIn ? myHelpRequestValidationSchemaPortal : myHelpRequestValidationSchemaLanding}
                onSubmit={(values, { resetForm }) => {
                    props.onSubmit(values);
                    resetForm({
                        values: {
                            subject: "",
                            question: ""
                        }
                    });
                }}
            >
                <Form>
                    <Grid container spacing={2} style={{ paddingTop: 2 }}>
                        <Grid item xs={12} sm={12} md={6} lg={4}>
                            {authUser.isLoggedIn ? (
                                <MyAccountSelectCustom
                                    allowCustomInput={true}
                                    disableClearable={false}
                                    initialValue={selectedName}
                                    label={t("Account Name")}
                                    allowDelegations={true}
                                    showNameOnly={true}
                                    onChange={(event: any, value: any) => {
                                        setSelectedName(value?.accountName);
                                    }}
                                    onInputChange={(event: any, value: string | null) => {
                                        setSelectedName(value);
                                    }}
                                />
                            ) : (
                                <Field
                                    name={"name"}
                                    component={TextField}
                                    disabled={props.disabled}
                                    label={t("Name")}
                                />
                            )}
                        </Grid>
                    </Grid>
                    <Grid container spacing={2} style={{ paddingTop: '20px' }}>
                        <Grid item xs={12} sm={12} md={6} lg={4}>
                            {authUser.isLoggedIn ? (
                                <MyAccountSelectCustom
                                    allowCustomInput={false}
                                    disableClearable={true}
                                    initialValue={selectedName}
                                    label={t("Account Number")}
                                    allowDelegations={true}
                                    showFullLabel={false}
                                    showAccountNumberOnly={true}
                                    onChange={(event: any, value: any) => {
                                        setSelectedAccountNumber(value?.extAccountNumber);
                                    }}
                                    onInputChange={(event: any, value: string | null) => {
                                        setSelectedAccountNumber(value);
                                    }}
                                />
                            ) : (
                                <Field
                                    name={"accountNumber"}
                                    component={TextField}
                                    disabled={props.disabled}
                                    label={t("Account Number")}
                                />
                            )}
                        </Grid>
                    </Grid>
                    <Grid container spacing={2} style={{ paddingTop: '20px' }}>
                        <Grid item xs={12} sm={12} md={6} lg={4}>
                            <Field
                                name={"email"}
                                component={TextField}
                                disabled={props.disabled}
                                label={t("Email")}
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={2} style={{ paddingTop: '20px' }}>
                        <Grid item xs={12} sm={12} md={6} lg={4}>
                            <Field
                                name={"subject"}
                                component={TextField}
                                disabled={props.disabled}
                                label={t("Subject")}
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={2} style={{ paddingTop: '20px' }}>
                        <Grid item xs={12} sm={12} md={9} lg={7}>
                            <Field
                                name={"question"}
                                component={TextField}
                                multiline
                                rows={7}
                                disabled={props.disabled}
                                label={t("Question")}
                            />
                        </Grid>
                    </Grid>
                </Form>
            </Formik>   
            {authUser.isLoggedIn && !selectedName && (
                <Alert severity="warning" sx={{ mt: 1 }}>
                    {t('Name cannot be empty.')}
                </Alert>
            )}  
            {authUser.isLoggedIn && !selectedAccountNumber && (
                <Alert severity="warning" sx={{ mt: 1 }}>
                    {t('Account cannot be empty.')}
                </Alert>
            )}  
        </div>
    );
}

MyHelpRequestForm.defaultProps = {
    id: 0,
    dialogSubmit: 0,
    changeIteration: 0,
    onSubmit: (values: any) => { console.log("onSubmit not implemented", values); },
    onError: (error: any) => { console.error("onError not implemented", error); },
};