import { getRoleUsersDefaultItem, RoleUsersValidationSchema } from './RoleUserAPIEndpoints';
import PropTypes from 'prop-types';
import { Formik, Form, Field } from 'formik';
import { useRef, useEffect, useState } from 'react';
import { UserSelect } from 'components/admin/Users/UserSelect';
import { RoleSelect } from 'components/admin/Roles/RoleSelect';

/**
 * This component handles the form for creating and editing
 * */
export const RoleUserForm = (props) => {
    const itemToEdit = props.itemToEdit || getRoleUsersDefaultItem();
    const dialogSubmit = useRef(props.dialogSubmit);
    const [selectedUser, setSelectedUser] = useState({id: itemToEdit.userId || -1, username: itemToEdit.username || ""})
    const [selectedRole, setSelectedRole] = useState({id: itemToEdit.roleId || -1, roleName: itemToEdit.roleName || ""})
    const formRef = useRef();

    useEffect(() => {
        if (props.dialogSubmit !== dialogSubmit.current) {
            dialogSubmit.current = props.dialogSubmit;
            if (formRef.current) {
                if (props.roleId) {
                    formRef.current.values.roleId = props.roleId;
                    formRef.current.values.userId = selectedUser.id;
                }
                else {
                    formRef.current.values.roleId = selectedRole.id
                    formRef.current.values.userId = props.userId;
                }
                formRef.current.handleSubmit();
            }
        }
    });

    return (
        <>
        <Formik
            innerRef={formRef}
            initialValues={itemToEdit}
            validationSchema={RoleUsersValidationSchema}
            onSubmit={(values) => {
                if (props.roleId) {
                    values.roleId = props.roleId;
                }
                else if (props.userId) {
                    values.userId = props.userId;
                }
                props.onSubmit(values);
            }}
        >
        <Form>
            {props.roleId &&
            <Field
                name={"userId"}
                initialValue={selectedUser}
                component={ UserSelect }
                label={"User"}
                onChange={(event, value) => {
                    setSelectedUser(value);
                }}
            />
            }
            {props.userId &&
            <Field
                name={"roleId"}
                initialValue={selectedRole}
                component={ RoleSelect }
                label={"Role"}
                onChange={(event, value) => {
                    setSelectedRole(value);
                }}
            />
            }
        </Form>
        </Formik>
        </>
    );
}

RoleUserForm.defaultProps = {
    id: 0,
    dialogSubmit: 0,
    changeIteration: 0,
    onSubmit: (values) => { console.log("onSubmit not implemented", values); },
    onError: (error) => { console.error("onError not implemented", error); },
};

RoleUserForm.propTypes = {
    id: PropTypes.number,
    changeIteration: PropTypes.number,
    dialogSubmit: PropTypes.number,
};