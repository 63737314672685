import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import LoadingButton from '@mui/lab/LoadingButton';
import { DialogContentText, Theme as MuiTheme } from '@mui/material';
// @ts-ignore
import { useResponsive } from 'lib/useResponsive';
import { useTranslation } from "react-i18next";
import { useTheme } from '@mui/material/styles';
import { RateClassChangeRequestForm } from './RateClassChangeRequestForm';
import { deleteRateClassChangeRequest, saveRateClassChangeRequest } from './RateClassChangeRequestAPIEndpoints';
import { useState } from 'react';


interface CustomInstanceOfMuiTheme extends MuiTheme {
    props: any;
  }

export const RateClassChangeRequestFormDialog = (props: any) => {
    const { isMobile } = useResponsive();
    const [saving, setSaving] = useState(false);
    const [dialogSubmit, setDialogSubmit] = useState(0);
    const theme = useTheme<CustomInstanceOfMuiTheme>();
    const { t } = useTranslation();

    let formId = props.id;
    if (!props.open) {
        formId = 0;
    }
    
    const handleSave = () => {
        setDialogSubmit(dialogSubmit + 1);
    };

    const handleDelete = async () => {
        let data = await deleteRateClassChangeRequest(props.itemToEdit);

        if (data) {
            props.onSave(data);
        }
    };
    
    const handleCancel = () => {
        props.onCancel();
    };

    return (
        <>
            {props.delete && props.open &&
                <Dialog
                    fullScreen={isMobile}
                    fullWidth={true}
                    maxWidth={"sm"}
                    open={props.open}
                    aria-labelledby="draggable-dialog-title"
                >
                    <DialogTitle id="draggable-dialog-title">
                        {t('Delete Change Request')}
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            {t('Are you sure you want to delete this Change Request?')}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            autoFocus
                            onClick={handleCancel}
                            variant={theme.props?.CancelButton?.variant}
                            data-cy-id={"cancel_delete_button"}
                        >
                            {t("Cancel")}
                        </Button>
                        <Button
                            onClick={handleDelete}
                            variant={theme.props?.CancelButton?.variant}
                            color={theme.props?.CancelButton?.color}
                            data-cy-id={"delete_button"}
                        >
                            {t("Delete")}
                        </Button>
                    </DialogActions>
                </Dialog>
            }
            {!props.delete && props.open &&
                <Dialog
                    fullScreen={isMobile}
                    fullWidth={true}
                    maxWidth={"md"}
                    open={props.open}
                    aria-labelledby="draggable-dialog-title"
                >
                    <DialogTitle id="draggable-dialog-title">
                        {t('Rate Class Change Request')}
                    </DialogTitle>
                    <DialogContent>
                        <RateClassChangeRequestForm
                            id={formId}
                            itemToEdit={props.itemToEdit}
                            dialogSubmit={dialogSubmit}
                            onSubmit={async (values) => {
                                setSaving(true);
                                try {
                                    let data = await saveRateClassChangeRequest(values);

                                    if (data) {
                                        props.onSave(data);
                                    }
                                } finally {
                                    setSaving(false);
                                }
                            }}
                            onError={(error: any) => {
                                props.onError(error);

                                setSaving(false);
                            }}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button autoFocus onClick={handleCancel} variant={theme.props?.CancelButton?.variant || "outlined"} color={theme.props?.CancelButton?.color}>
                            {t('Cancel')}
                        </Button>
                        <LoadingButton
                            loading={saving}
                            onClick={handleSave}
                            variant="contained"
                        >
                            {t('Save')}
                        </LoadingButton>
                    </DialogActions>
                </Dialog>
            }
        </>
    );
}