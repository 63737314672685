import {
    useTheme,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
} from "@mui/material";
// @ts-ignore
import { UcDraggable } from "components/common/UcDraggable";
import { useTranslation } from "react-i18next";
// @ts-ignore
import { useResponsive } from 'lib/useResponsive';
import { ThemeProps } from "../common/Common.interfaces";
// @ts-ignore
import { UcSummaryTable } from '../common/UcSummaryTable';

interface RateClassComparisonPageDialogProps {
    rateClassName: string,
    rateCategories: any,
    uom: string,
    onClose: Function,
    open: any
};

export default function RateClassComparisonPageDialog(props: RateClassComparisonPageDialogProps) {
    const { isMobile } = useResponsive();
    const theme = useTheme() as ThemeProps;
    const { t } = useTranslation();
    const { rateCategories, uom, rateClassName } = props;

    const handleClose = () => {
        props.onClose();
    };

    return (
        <>
            {props.open && (
                <Dialog
                    fullScreen={isMobile}
                    fullWidth={true}
                    maxWidth={"sm"}
                    open={props.open}
                    aria-labelledby="draggable-dialog-title"
                    PaperComponent={UcDraggable}
                >
                    <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
                        {t('Rate Class Details')}
                    </DialogTitle>
                    <DialogContent>
                        <UcSummaryTable
                            rateCategories={rateCategories}
                            uom={uom}                            
                            titleOverride={t("{{rateClass}}", {
                                rateClass: rateClassName,
                            })}
                        />
                    </DialogContent>
                    <DialogActions
                        sx={{
                            position: isMobile && 'absolute',
                            left: isMobile && '75%',
                            top: isMobile && '0'
                        }}>
                        <Button
                            autoFocus
                            onClick={handleClose}
                            variant={theme.props?.CancelButton?.variant || "outlined"}
                            color={theme.props?.CancelButton?.color}
                            data-cy-id={"cancel_button"}
                        >
                            {t('Close')}
                        </Button>
                    </DialogActions>
                </Dialog>
            )}         
        </>
    );
}
