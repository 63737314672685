import { IANAZone, SystemZone } from "luxon";
import { dateFormatter, ensureLuxonDate } from "./Formatters";

export const formatDaysOfWeek = (daysOfWeekIntArray, isShort = true) => {
    var weekdays = {
        0: "Sunday",
        1: "Monday",
        2: "Tuesday",
        3: "Wednesday",
        4: "Thursday",
        5: "Friday",
        6: "Saturday"
    }

    var weekdaysShort = {
        0: "Sun",
        1: "Mon",
        2: "Tue",
        3: "Wed",
        4: "Thur",
        5: "Fri",
        6: "Sat"
    }

    return daysOfWeekIntArray.map((item) => {
        if (isShort) {
            return weekdaysShort[item];
        }
        return weekdays[item];
    }).join(", ");
}

export const formatTimeRanges = (timeRangesArray) => {
    return timeRangesArray.map((item) => {
        return (new Date(item.startDateTime)).toLocaleTimeString('en', { hour: 'numeric', minute: 'numeric' }) + " - " + (new Date(item.endDateTime)).toLocaleTimeString('en', { hour: 'numeric', minute: 'numeric' });
    }).join(",");
}

export const convertToLocalTime = (startDateTime, targetTimezone) => {
    let luxonDate = ensureLuxonDate(startDateTime);
    let currentZone = new SystemZone();
    let myOffsetMinutes = currentZone.offset(new Date());
    let targetZone = IANAZone.create(targetTimezone)
    let targetOffsetMinutes = targetZone.offset(new Date());

    let totalOffset = myOffsetMinutes - targetOffsetMinutes;
    return luxonDate.plus({minute: totalOffset});
}

export const formatTimeRangeWithZone = (startDateTime, endDateTime, timezone) => {
    return (new Date(startDateTime)).toLocaleTimeString('en', { timeZone: timezone, hour: 'numeric', minute: 'numeric' }) + " - " + (new Date(endDateTime)).toLocaleTimeString('en', { timeZone: timezone, hour: 'numeric', minute: 'numeric' });
}

export const formatTimeRangesWithZone = (timeRangesArray, timezone) => {
    return timeRangesArray.map((item) => {
        return formatTimeRangeWithZone(item.startDateTime, item.endDateTime, timezone);
    }).join(",");
}

export const formatExemptDays = (exemptDaysObject) => {
    if (Object.keys(exemptDaysObject).length !== 0) {
        let tempExemptDaysArr = [];

        Object.entries(exemptDaysObject).forEach(([key, value]) => {
            tempExemptDaysArr.push(dateFormatter(key, true) + " - " + value);
        });

        return tempExemptDaysArr.join(', ');
    }
    return "";
}