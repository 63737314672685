import React, { useState, useContext, useEffect, useLayoutEffect } from 'react';
import { PageContext } from 'lib/PageProvider';
import { UserMeterGridStateful } from "components/admin/Users/UserMeterGridStateful"
import { mutateUrls } from "lib/useGenericSWR";
import { useSWRConfig } from "swr";
import { EnsureUser } from 'components/auth/EnsureUser';
import { UcToolbar } from 'components/common/UcToolbar';
import { UserMeterDialog } from './UserMeterDialog';

export const UserMeterList = (props) => {
    const { userId } = props;
    const [selectedItem, setSelectedItem] = useState(null);
    const [pageState, pageDispatch] = useContext(PageContext);
    const [generalSearch, setGeneralSearch] = useState('');
    const [editDialog, setEditDialog] = useState({ open: false });

    const [reMutateUrls, setReMutateUrls] = useState(false);
    const swrConfig = useSWRConfig();

    const closeHandler = () => {
        setEditDialog({ open: false, itemToEdit: editDialog.dataItem });
    }

    const saveHandler = () => {
        closeHandler();
        // Also, remutate
        setReMutateUrls(true);
    }

    const openDialog = (dataItem) => {
        if (!dataItem) {
            return;
        }
        if (!dataItem.formIteration) {
            dataItem.formIteration = "edit" + dataItem.userId;
        }
        setEditDialog({ open: true, itemToEdit: dataItem });
    }

    const editItem = (selectedItem) => {
        openDialog(selectedItem);
    }

    useLayoutEffect(() => {
        if (reMutateUrls) {
            mutateUrls(swrConfig);
            setReMutateUrls(false);
        }
    }, [reMutateUrls, swrConfig]);

    useEffect(() => {
        pageDispatch({
            header: "Meters",
        });
    }, [pageState.header, selectedItem, pageDispatch]);

    const actions = [];

    console.log("UserMeterListPage - Loaded");
    return (
        <EnsureUser permissions={["UserAccount:Read"]}>
            <div className="UserMeterList">
                <UcToolbar
                    hasGeneralSearch={true}
                    onGeneralSearchChange={(value) => {
                        setGeneralSearch(value);
                    }}
                    actions={actions}>
                </UcToolbar>
                <UserMeterDialog
                    open={editDialog.open}
                    itemToEdit={editDialog.itemToEdit}
                    onCancel={closeHandler}
                    onSave={saveHandler}
                />
                {!reMutateUrls &&
                    <UserMeterGridStateful
                        generalSearch={generalSearch}
                        onEditItem={editItem}
                        userId={userId}
                        onSelected={(selectedSearchItem) => {
                            setSelectedItem({ ...selectedSearchItem });
                        }} />
                }
            </div>
        </EnsureUser>
    );
};