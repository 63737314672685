import { ApiClient } from "../../../lib/ApiClient";
import { useGenericSWR } from "../../../lib/useGenericSWR";
import * as Yup from 'yup';

const apiEndpoint = 'api/v1/admin/uc/OutgoingNotification';
const resendApiEndpoint = 'api/v1/admin/uc/OutgoingNotification/resend';

let formIteration = 0;

const getOutgoingNotificationsDefaultItem = () => {
    formIteration = formIteration + 1;
    return {
        formIteration: "new" + formIteration,
        id: 0,
        createdBy: 'REACT_FE',
        updatedBy: 'REACT_FE',
    }
};

const outgoingNotificationsValidationSchema = Yup.object().shape({
    toAddress: Yup.string()
        .required("Required")
        .max(256, 'Max of 256 characters'),
    fromAddress: Yup.string()
        .required("Required")
        .max(256, 'Max of 256 characters'),
    subject: Yup.string()
        .required("Required")
        .max(256, 'Max of 256 characters'),
});

// See definition from OutgoingNotification.cs
const apiClient = new ApiClient();
apiClient.getDefaultItem = () => getOutgoingNotificationsDefaultItem();
apiClient.getObjectName = () => {
    return "Outgoing Notification";
};

const useOutgoingNotification = (id, swrOptions = {}) => {
    // SWR will prevent duplicate requests
    // It will also maintain data state as long as your are using the same urls for your requests
    return useGenericSWR(apiEndpoint + "/" + id, apiClient, { ...swrOptions });
};

const useOutgoingNotifications = (filter, swrOptions = {}) => {
    // SWR will prevent duplicate requests
    // It will also maintain data state as long as your are using the same urls for your requests
    const params = new URLSearchParams();
    params.append('filter', JSON.stringify(filter));
    return useGenericSWR(apiEndpoint + "?" + params.toString(), apiClient, { ...swrOptions });
};

const saveOutgoingNotification = (OutgoingNotification) => {
    if (OutgoingNotification.id === 0) {
        return insertOutgoingNotification(OutgoingNotification);
    }
    return updateOutgoingNotification(OutgoingNotification);
}
const insertOutgoingNotification = (OutgoingNotification) => {
    return apiClient.poster(apiEndpoint, OutgoingNotification);
};

const updateOutgoingNotification = (OutgoingNotification) => {
    return apiClient.putter(apiEndpoint, OutgoingNotification);
};

const deleteOutgoingNotification = (OutgoingNotification) => {
    return apiClient.deleter(apiEndpoint + "/" + OutgoingNotification.id);
};

const resendOutgoingNotification = (OutgoingNotification) => {
    return apiClient.poster(resendApiEndpoint + "/" + OutgoingNotification.id, {}, "Sending Notification", true);
};

export {
    useOutgoingNotification,
    useOutgoingNotifications,
    saveOutgoingNotification,
    insertOutgoingNotification,
    updateOutgoingNotification,
    deleteOutgoingNotification,
    resendOutgoingNotification,
    outgoingNotificationsValidationSchema,
    getOutgoingNotificationsDefaultItem,
};