import React from 'react';
import Draggable from 'react-draggable';
import Paper, { PaperProps } from '@mui/material/Paper';

const CustomPaperComponent: React.FC<PaperProps> = (props) => {
    return (
        // @ts-ignore
        <Draggable
            handle="#draggable-dialog-title"
            cancel={'[class*="MuiDialogContent-root"]'}
        >
            <Paper {...props} />
        </Draggable>
    );
}

// Define CustomPaperComponent as a constructor function that accepts PaperProps
const CustomPaperComponentConstructor: React.JSXElementConstructor<PaperProps> = CustomPaperComponent;

export default CustomPaperComponentConstructor;