// @ts-ignore
import { useState, useLayoutEffect } from "react";
import { mutateUrls } from "../../../lib/useGenericSWR";
import { useSWRConfig } from "swr";
// @ts-ignore
import { EnsureUser } from "../../auth/EnsureUser";
import { useTranslation } from "react-i18next";
import { UcWidget } from "../../common/UcWidget";
// @ts-ignore
import { useResponsive } from 'lib/useResponsive';
import { Box, Alert, Grid, Typography, Link } from "@mui/material";
import { useRateExpirationMetrics } from '../Metrics/MetricsAPIEndpoints';
import { UcLoading } from "../../common/UcLoading";
import { useNavigate } from "react-router-dom";
// @ts-ignore
import { routePaths } from "routes";
import { green, orange } from "@mui/material/colors";

export const RateExpirationStatsWidget = () => {
    const { t } = useTranslation();
    const [reMutateUrls, setReMutateUrls] = useState(false);
    const swrConfig = useSWRConfig();
    const { isMobile } = useResponsive();
    const navigate = useNavigate();

    const swrRateExpirationMetrics = useRateExpirationMetrics();
    let latestRateExpirationMetrics = swrRateExpirationMetrics?.result;

    const handleClickGoToValidRates = () => {
        navigate(routePaths.rateExpirationPage.path, { state: { rateStatus: "Valid" } });
    };

    const handleClickGoToExpiringRates = () => {
        navigate(routePaths.rateExpirationPage.path, { state: { rateStatus: "Expiring" } });
    };

    useLayoutEffect(() => {
        if (reMutateUrls) {
            mutateUrls(swrConfig);
            setReMutateUrls(false);
        }
    }, [reMutateUrls, swrConfig]);

    console.log("AdminResidentialUsersStatsWidget - Loaded");

    return (
        <UcWidget
            title={t("Rate Expiration")}
            alignTitleLeft={false}
            contentPadding={'1rem'}
            minHeight={'150px'}
            maxHeight={isMobile ? '100%' : '195px'}
        >
            <EnsureUser permissions={["Metrics:Read"]}>
                <div data-cy-id="rate_expiration_stats_widget">
                    <UcLoading
                        hasFade={false}
                        hasError={swrRateExpirationMetrics?.isError}
                        isLoading={swrRateExpirationMetrics?.isLoading}
                        height={"8vh"}
                        errorMessage={t(
                            "An error occurred retrieving rate expiration metrics."
                        )}
                    >
                        {latestRateExpirationMetrics ?
                            <Grid container spacing={1} alignItems="center">
                                <Grid item xs={6}>
                                    <Box sx={{ borderRight: '4px solid', borderColor: 'background.default' }}>
                                        <Typography variant='subtitle2'>
                                            <Link component="button" onClick={handleClickGoToValidRates}>{t("Valid Rates")}</Link>
                                        </Typography>
                                        <Typography variant="h3" sx={{ color: green[500] }}>
                                                {latestRateExpirationMetrics?.validRateVersionsCount}
                                            </Typography>
                                        </Box>
                                    </Grid>
                                <Grid item xs={6}>
                                    <Box>
                                        <Typography variant='subtitle2'>
                                            <Link component="button" onClick={handleClickGoToExpiringRates}>{t("Expiring Rates")}</Link>
                                        </Typography>
                                        <Typography variant="h3" sx={{ color: orange[500] }}>
                                                {latestRateExpirationMetrics?.expiringRateVersionsCount}
                                            </Typography>
                                        </Box>
                                    </Grid>
                                </Grid>
                            :
                            <Alert severity="warning">{t("No rate expiration metrics were found.")}</Alert>
                        }
                    </UcLoading>
                </div>               
            </EnsureUser>
        </UcWidget>
    );
};
