import { ApiClient } from "../../../lib/ApiClient";
import { useGenericSWR } from "../../../lib/useGenericSWR";
import * as Yup from 'yup';
import { ensureLuxonDateOnly } from "lib/Formatters";
import { globalTranslator } from "lib/GlobalTranslator";

export const meterApiEndpoint = 'api/v1/admin/AccountMeter';

let formIteration = 0;
const t = globalTranslator;

const getMetersDefaultItem = () => {
    formIteration = formIteration + 1;
    return {
        formIteration: "new" + formIteration,
        accountId: 0,
        accountNumber: "",
        serialNo: "",
        startTimestamp: ensureLuxonDateOnly(new Date()),
        endTimestamp: ensureLuxonDateOnly(new Date()),
    }
};

// See definition from Account History
const apiClient = new ApiClient();
apiClient.getDefaultItem = () => getMetersDefaultItem();
apiClient.getObjectName = () => {
    return "Meter";
};

const useMeters = (filter, swrOptions = {}) => {
    //if (!filter.extAccountNumber && !filter.customerId)
    //{
    //    filter.extAccountNumber = "InvalidNumber";
    //    filter.customerId = "InvalidNumber";
    //}
    // SWR will prevent duplicate requests
    // It will also maintain data state as long as your are using the same urls for your requests
    const params = new URLSearchParams();
    params.append('filter', JSON.stringify(filter));
    return useGenericSWR(meterApiEndpoint + "?" + params.toString(), apiClient, { ...swrOptions });
};

const saveMeter = (AccountMeter) => {
    return updateMeter(AccountMeter);
};

const updateMeter = (AccountMeter) => {
    return apiClient.putter(meterApiEndpoint, AccountMeter);
};

const meterValidationSchema = Yup.object().shape({
    startTimestamp: Yup.date()
        .required("Required")
        .when('endTimestamp', {
            is: (endTimestamp) => {
                if (endTimestamp) {
                    return true;
                }
                return false;
            },
            then: Yup.date()
                .max(
                    Yup.ref("endTimestamp"),
                    t("Start Date must be less than Meter End Date")),
        }),
    endTimestamp: Yup.date()
        .nullable(),
});

export {
    useMeters,
    saveMeter,
    updateMeter,
    getMetersDefaultItem,
    meterValidationSchema
};