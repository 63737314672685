import React, { useState, useLayoutEffect } from 'react';
import { UserNotificationPreferenceGridStateful } from "./UserNotificationPreferenceGridStateful"
import { getUserNotificationPreferenceDefaultItem } from "./UserNotificationPreferenceAPIEndpoints"
import { UserNotificationPreferenceFormDialog } from './UserNotificationPreferenceFormDialog';
import { mutateUrls } from "lib/useGenericSWR";
import { useSWRConfig } from "swr";
import AddIcon from '@mui/icons-material/Add';
import { UcToolbar } from 'components/common/UcToolbar';
import { EnsureUser } from 'components/auth/EnsureUser';
import { useTranslation } from "react-i18next";
import { useResponsive } from 'lib/useResponsive';
import { getWholeRow } from '../../lib/DataGridHelpers';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { UcIconButtons } from 'components/common/UcIconButtons';
import { UcIconButtonsWithLabel } from 'components/common/UcIconButtonsWithLabel';
import { useEnums } from 'components/auth/PermissionApiEndpoint';
import { enumFormatter } from 'lib/EnumFormatter';
import useOrgAppSettings from 'lib/useOrgAppSettings';
import {
    Grid
} from "@mui/material";
import { MyNotificationPreferences } from "./MyNotificationPreferences";
import './UserNotificationPreferenceList.scss';

export const UserNotificationPreferenceList = (props) => {
    const { t } = useTranslation();
    const { orgAppSettings } = useOrgAppSettings();
    const [editDialog, setEditDialog] = useState({ open: false, delete: false });
    const [reMutateUrls, setReMutateUrls] = useState(false);
    const swrConfig = useSWRConfig();
    const { isMobile } = useResponsive();
    const notificationTypeEnums = useEnums("NotificationType");

    const actionsHandler = (itemValue, labelFlag) => {
        const actions = [
            {
                title: t('Edit Notification Setting'),
                icon: <EditIcon data-cy-id={"notification_preference_edit_" + itemValue.name} />,
                color: 'primary',
                isHidden: false,
                isDisabled: false,
                label: t('Edit'),
                labelSx: { display: 'inline-block', width:'100%', ml:1, textAlign:'justify' },
                onClick: () => { editItem(itemValue); },
            },
            {
                title: t('Delete Notification Setting'),
                icon: <DeleteIcon data-cy-id={"notification_preference_delete_" + itemValue.name} />,
                color: 'error',
                isHidden: false,
                isDisabled: ( itemValue.notificationType === "NewBill" || itemValue.notificationType === "BillAlmostDue" ),
                label: t('Delete'),
                labelSx: { display: 'inline-block', width:'100%', ml:1, textAlign:'justify' },
                onClick: () => { deleteItem(itemValue); },
            }
        ];

        return (labelFlag ? (
            <UcIconButtonsWithLabel actions={actions} />
            ) : (
            <UcIconButtons actions={actions} />
            )
        );
    }

    let defaultDataItems = {
        columnVisibilityModel: {
            id: true,
            cepOrgId: false,
            content: false,
            createdBy: false,
            updatedBy: false,
            username: false
        },
        columns: [
            {field: 'name', headerName: t('Name'), width: 200},
            {
                field: 'uom', headerName: t('Unit of Measure'), width: 120, valueFormatter: (params) => {

                    const uomMapping = orgAppSettings?.result?.uomMappings[params.value.toUpperCase()];
                    return uomMapping?.safeUomDisplay ?? params.value
                }
            },
            {field: 'username', headerName: t('User'), width: 200, cardHide: true},
            {field: 'accountName', headerName: t('Account'), minWidth: 200, flex: 1, cardColumnWidth: 12},
            {field: 'frequency', headerName: t('Frequency'), width: 200,  cardColumnWidth: 6},
            {field: 'notificationType', headerName: t('Notification Type'), width: 200, cardColumnWidth: 6, valueFormatter: (params) => { return enumFormatter(notificationTypeEnums, params.value) } },
            {field: 'value', headerName: t('Value'), minWidth: 100, cardColumnWidth: 6, valueFormatter: (params) => { return params.value === 0 ? "" : params.value } },
            {field: 'id', headerName: '', cardHide: true,
                valueGetter: getWholeRow,
                renderCell: (params) => {
                    return actionsHandler(params.value, false);
                }
        }
        ],
        cardHeaderField: {field: "name"},
        rows: [],
        rowCount: -1,
    };

    const closeHandler = () => {
        setEditDialog({ open: false, itemToEdit: editDialog.dataItem });
    }

    const saveHandler = () => {
        closeHandler();
        setReMutateUrls(true);
    }

    const openDialog = (dataItem) => {
        if (!dataItem) {
            return;
        }
        if (!dataItem.formIteration) {
            dataItem.formIteration = "edit" + dataItem.id;
        }
        setEditDialog({ open: true, itemToEdit: dataItem, delete: false });
    }

    const deleteDialog = (dataItem) => {
        if (!dataItem) {
            return;
        }
        if (!dataItem.formIteration) {
            dataItem.formIteration = "delete" + dataItem.id;
        }
        setEditDialog({ open: true, itemToEdit: dataItem, delete: true });
    }

    const createItem = () => {
        openDialog(getUserNotificationPreferenceDefaultItem());
    }

    const editItem = (item) => {
        openDialog(item);
    }

    const deleteItem = (item) => {
        deleteDialog(item);
    }

    const actions = [
        {
            title: t('Add Notification Setting'),
            toolTip: t('Add Notification Setting'),
            icon: <AddIcon data-cy-id={"add_notification_preference"} />,
            onClick: () => { createItem(); },
        }
    ];

    useLayoutEffect(() => {
        if (reMutateUrls)
        {
            mutateUrls(swrConfig);
            setReMutateUrls(false);
        }
    }, [reMutateUrls, swrConfig]);

    return (
        <EnsureUser permissions={["UserNotificationPreference:Read"]}>
            {!isMobile && (
                <Grid item xs={12} sx={{ pb: 2 }}>
                    <MyNotificationPreferences />
                </Grid>
            ) }
    
            <div className="userNotificationPreferenceList">
                {!reMutateUrls &&
                <>
                    {!isMobile && (<UcToolbar actions={actions}></UcToolbar>)}
                    <UserNotificationPreferenceGridStateful
                        isFullPage={props.isFullPage || false}
                        onEditItem={editItem}
                        onDeleteItem={deleteItem}
                        defaultDataItems={defaultDataItems}
                        createItem={createItem}
                        actionsHandler={actionsHandler}
                    />
                </>
                }
                <UserNotificationPreferenceFormDialog
                    open={editDialog.open}
                    delete={editDialog.delete}
                    itemToEdit={editDialog.itemToEdit}
                    onCancel={closeHandler}
                    onSave={saveHandler}
                />
            </div>
        </EnsureUser>
    );
};