import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import Typography from '@mui/material/Typography';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import Paper from '@mui/material/Paper';
import { SlideProps } from '@mui/material';
import { NotificationCardView } from '../common/Common.interfaces';

interface UcMobileNotificationPageContentProps {
    currentView: NotificationCardView,
    onPageClick: Function, 
    onClose: Function,
    navLink: string,
    contentBackgroundColor: string,
    views: Array<NotificationCardView>
}

const Transition = React.forwardRef<unknown, SlideProps & {
    children?: React.ReactElement}>((props, ref) => {
        return <Slide direction='left' ref={ref} {...props} />
})

export const UcMobileNotificationPageContent = (props: UcMobileNotificationPageContentProps) => {
    const { onPageClick, currentView, onClose, views } = props;
    const [value, setValue] = React.useState(props.navLink || "Notifications");

    const handleClose = () => {
        onClose();
    }

    return (
        <Dialog
            TransitionComponent={Transition}
            fullScreen={true}
            open={true}
            onClose={handleClose}
        >
            <div 
                style={{
                    zIndex: 9999, 
                    boxShadow: '0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)'
                }}
            >
                <DialogTitle sx={{ pl: 0, pr: 0 }}>
                    <> 
                        <Button autoFocus onClick={handleClose}><ChevronLeftIcon /></Button>
                        <Typography
                            sx={{ display: 'inline' }}
                            component="span"
                            variant="subtitle1"
                        >
                            {currentView.title}
                        </Typography>                         
                            
                    </>    
                </DialogTitle>
            </div>
            <DialogContent 
                sx={{
                    backgroundColor: props.contentBackgroundColor || 'background.paper',
                    padding: "5px 15px 0 15px"
                }}
            >
                {currentView.contentRender}
            </DialogContent>
            <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0 }} elevation={3}>
                <BottomNavigation
                    showLabels
                    value={value}
                    onChange={(event, newValue) => {
                        onPageClick(newValue);
                        setValue(newValue);
                    }}
                >
                    {views?.map((view) => (
                        <BottomNavigationAction value={view?.title} label={view?.subtitle} icon={view?.icon} />
                    ))}             
                </BottomNavigation>
            </Paper>
        </Dialog>
   
    );
}
