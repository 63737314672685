import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import HighlightOffIcon from '@mui/icons-material/HighlightOff';

export interface UserBillOptionsWidgetErrorDialogProps {
    open: boolean;
    isSubscribed: boolean;
    handleClose: () => void;
}

export default function UserBillOptionsWidgetErrorDialog(props: UserBillOptionsWidgetErrorDialogProps) {
    const { open, isSubscribed, handleClose } = props;
    const { t } = useTranslation();

    return (
        <Dialog maxWidth={'xs'}
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogContent>
                <Box sx={{ textAlign: "center" }}>
                    <HighlightOffIcon sx={{ fontSize: 72 }} color="error" />
                </Box>
                <DialogContentText id="alert-dialog-description" sx={{textAlign: 'center'}}>
                    <Typography variant="h4" gutterBottom sx={{textAlign: 'center'}}>
                        {t("Error")}
                    </Typography>
                    <Typography variant="body1">
                        { isSubscribed 
                            ? t(`You have not successfully unsubscribed to paperless billing. Please try again.`)
                            : t(`You have not successfully subscribed to paperless billing. Please try again.`)
                        }
                    </Typography>
                </DialogContentText>
            </DialogContent>
            <DialogActions sx={{justifyContent: 'center'}}>
                <Button
                    onClick={handleClose}
                    color="primary"
                    variant="outlined"
                >
                    {t("Close")}
                </Button>
            </DialogActions>
        </Dialog>
    );
}
