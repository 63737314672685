import * as React from 'react';
import {
  gridPageSelector,
  gridPageSizeSelector,
  gridRowCountSelector,
  useGridApiContext,
  useGridSelector,
} from '@mui/x-data-grid-pro';
import { TablePagination, TextField } from '@mui/material';
import { useDebouncedValue } from '@mantine/hooks';
import './UcPagination.scss';

export const UcPagination = () => {
  const apiRef = useGridApiContext();
  const page = useGridSelector(apiRef, gridPageSelector);
  const pageSize = useGridSelector(apiRef, gridPageSizeSelector);
  const totalRows = useGridSelector(apiRef, gridRowCountSelector);
  const pageCount = Math.ceil(totalRows / pageSize);

  const [pageValue, setPageValue] = React.useState(page+1);
  const [debounced] = useDebouncedValue(pageValue, 500);

  React.useEffect(() => {
    const newPage = parseInt(debounced);
    if (newPage > 0 && newPage <= pageCount) {
        apiRef.current.setPage(newPage-1);
    }
    else if (pageCount > 0 && newPage > pageCount) {
        apiRef.current.setPage(pageCount - 1);
    }
  }, [pageCount, apiRef, debounced]);

  return (
    <div className="ucPagination">
        <table style={{width:"100%"}}>
            <tbody>
                <tr>
                    <TablePagination
                        color="primary"
                        count={totalRows}
                        page={page}

                        rowsPerPage={pageSize}
                        rowsPerPageOptions={[5, 10, 20, 50, 100]}
                        onPageChange={(event, value) => { apiRef.current.setPage(value); setPageValue(value+1); }}
                        onRowsPerPageChange={(event, value) => {apiRef.current.setPageSize(value.props.value); }}
                    />
                </tr>
            </tbody>
        </table>
        <TextField
        className="input-slim__textField"
        value={pageValue} label={"Page " + (page+1) + " of " + pageCount}
        onChange={(event) => {
            setPageValue(event.target.value);
        }} />
    </div>

  );
}