import React, { useState, useRef, useLayoutEffect } from 'react';
import List from '@mui/material/List';
import ListSubheader from '@mui/material/ListSubheader';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import { NavLink } from 'react-router-dom';
import { getMenuItems } from '../routes';
import { Collapse, Divider, ListItemIcon } from '@mui/material';
import {
    ExpandLess as ExpandLessIcon,
    ExpandMore as ExpandMoreIcon
} from '@mui/icons-material';
import { usePermissions, useUser } from './auth/PermissionApiEndpoint';
import { useTranslation } from 'react-i18next';
import { getUrl } from 'domain';
import { UserInfoContext } from '../lib/UserInfoProvider';
import { AccountContext } from '../lib/AccountProvider';
import { EnsureUser } from './auth/EnsureUser';
import { CurrentAccount } from './customer/accounts/CurrentAccount';
import { useResponsive } from '../lib/useResponsive';
import { OrgAppSettingsContext } from '../lib/OrgAppSettingsProvider';
import { useIsUtilitySupportOrAdmin } from "../lib/useIsUserRole";
import { useMyAccounts } from "./customer/accounts/MyAccountAPIEndpoints";

const NavMenu = (props) => {
    const { userInfo } = React.useContext(UserInfoContext);
    const { account } = React.useContext(AccountContext);
    const { orgAppSettings } = React.useContext(OrgAppSettingsContext);
    const { isMobile, isTablet } = useResponsive();
    const { t } = useTranslation();
    const depth = props.depth || 0;
    const user = useUser();
    const parentMenuName = props.parentMenuName || null;
    const [openItems, setOpenItems] = useState([]);
    const accounts = useMyAccounts();
    const isLoading = (accounts.isLoading);
    const prevIsOpenProp = useRef(props.isOpen);

    useLayoutEffect(() => {
        if (prevIsOpenProp.current !== props.isOpen) {
    
          setOpenItems([]);
    
          prevIsOpenProp.current = props.isOpen;
        }
    }, [props.isOpen]);

    let hasAccounts = true;

    if (accounts && !isLoading) {
        hasAccounts = (accounts.result?.length > 0);
    }

    const permissionSwr = usePermissions();
    const isUtilitySupportOrAdmin = useIsUtilitySupportOrAdmin();

    const orgAppInfo = orgAppSettings?.result;

    const showHelpRequest = orgAppInfo?.supportEmail && orgAppInfo?.supportEmail.trim() !== '';
    let hasOutageMapUrl = orgAppInfo?.outageMapUrl;

    const hiddenRoutesString = orgAppInfo?.hiddenRoutes;
    let hiddenRoutes = hiddenRoutesString ? hiddenRoutesString.split(",") : []; //TODO remove loading hidden routes from appsettings entirely

    !showHelpRequest && hiddenRoutes?.push("myHelpRequestPage");
    !hasOutageMapUrl && hiddenRoutes?.push("outageMapPage");

    let allowedPermissions = [];
    let allowedModules = [];

    if (permissionSwr.result && !permissionSwr.isLoading && !permissionSwr.isError)
    {
        allowedPermissions = permissionSwr.result.permissions;
        allowedModules = permissionSwr.result.orgModules;
    }

    let menuCondition = "normal";
    if (user.isImpersonating) {
        menuCondition = "impersonate";
    }
    else if (!hasAccounts && isUtilitySupportOrAdmin) {
        menuCondition = "displayNoAccountsAdmin";
    }
    
    let menuItems = getMenuItems(userInfo, parentMenuName, allowedPermissions, allowedModules, hiddenRoutes, menuCondition);

    if (depth > 10 || menuItems.length <= 0) {
        return <></>;
    }

    const handleToggleMenuOpen = (routeName) => (event) => {
        if (!props?.isOpen && props?.isOpen !== undefined) {
            props.onHeaderClick();
        }
        let index = openItems.indexOf(routeName);
        if (index >= 0) {
            openItems.splice(index, 1);
        }
        else {
            openItems.push(routeName);
        }
        setOpenItems([...openItems]);
        event.stopPropagation();
    }

    const handleClick = (event) => {
        if (props.onClick) {
            props.onClick(event);
        }
    }
    const showAccountInMenu = isMobile || isTablet;

    return <>
        { depth === 0 && showAccountInMenu &&
            <ListItem  style={{marginTop: 16, marginBottom: 8}}
                data-cy-id={"nav-menu-account"}
                title={account.accountName + " - " + account.accountAddress}
                component={"div"} // was NavLink... however when I use navlink here it redirects instead of using routes
                sx={{ color: "inherit" }}
            >
                <EnsureUser><CurrentAccount /></EnsureUser>
            </ListItem>
        }
        {menuItems.map((route, index) => {
            let subMenuItems = getMenuItems(userInfo, route.name, allowedPermissions, allowedModules, hiddenRoutes, menuCondition);
            let hasSubMenu = subMenuItems.length > 0;

            return <React.Fragment key={route.name}>
                {route.menu &&
                    <>
                        {hasSubMenu &&
                            <Divider />
                        }
                        <ListItem disablePadding style={{marginTop: (index === 0 && depth === 0)}}
                            data-cy-id={"nav-menu-" + route.name}
                            title={route.displayName}
                            onClick={hasSubMenu ? handleToggleMenuOpen(route.name) : handleClick}
                            component={"div"} // was NavLink... however when I use navlink here it redirects instead of using routes
                            sx={{ color: "inherit" }}
                        >
                            {hasSubMenu && !route.path &&
                                <ListItemButton 
                                    disableRipple={!route.menu.collapse}
                                    disableTouchRipple={!route.menu.collapse}
                                    disableFocusRipple={!route.menu.collapse}
                                    sx={{
                                        "&.MuiButtonBase-root:hover": {
                                          bgcolor: !route.menu.collapse && "transparent"
                                        }
                                    }}
                                >
                                    <ListItemIcon title={t(route.displayName)}>{route.menu.startIcon}</ListItemIcon>
                                    <ListItemText primary={t(route.displayName)} />
                                    {route.menu.collapse && ((openItems.indexOf(route.name) >= 0) ? <ExpandMoreIcon /> : <ExpandLessIcon />)}
                                </ListItemButton>
                            }
                            {!hasSubMenu && route.path &&
                                <ListItemButton sx={{ p: 0 }}>
                                    <NavLink style={{ width: "100%", padding: "8px 16px", display: "flex", color: "inherit", textDecoration: "none" }} to={getUrl(route.path)}>
                                        <ListItemIcon>{route.menu.startIcon}</ListItemIcon>
                                        <ListItemText title={t(route.displayName)} primary={t(route.displayName)} />
                                    </NavLink>
                                </ListItemButton>
                            }
                        </ListItem>
                    </>
                }
                {hasSubMenu &&
                    <Collapse in={(openItems.indexOf(route.name) >= 0 && route.menu.collapse) || !route.menu.collapse} timeout="auto" unmountOnExit>
                        {props.isOpen &&
                            <ListSubheader style={{ paddingLeft: "10px" }}>
                                <List component="div" disablePadding>
                                    <NavMenu parentMenuName={route.name} depth={depth + 1} />
                                </List>
                            </ListSubheader>
                        }
                        {!props.isOpen &&
                            <List component="div" disablePadding>
                                <NavMenu parentMenuName={route.name} depth={depth + 1} />
                            </List>
                        }
                    </Collapse>
                }
            </React.Fragment>
        }
        )}
    </>;
};
export default NavMenu;