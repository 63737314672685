import { ensureLuxonDate, ensureLuxonDateOnly, dateOnlyFormatPicker, getDateFromMui } from "lib/Formatters";
import React from "react";
import { DateTime } from 'luxon';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import {TextField} from '@mui/material';
import { convertToLocalTime } from "lib/DateRuleSetFormatter";
import { useMyTimezone } from 'components/customer/orgs/MyOrgAPIEndpoints';

export const UcDatePickerFormik = (props) => {
    const timezone = useMyTimezone();
    const { dateOnly, defaultValue, field, sendValueToForm } = props;
    const [date, setDate] = React.useState(props.field.value || DateTime.now().toISO());

    const handleChangeDate = (value) => {
        let newDate = new Date();
        if (dateOnly) {
            newDate = ensureLuxonDateOnly(convertToLocalTime(getDateFromMui(value), timezone) || new Date(defaultValue));
        }
        else {
            newDate = ensureLuxonDate(convertToLocalTime(getDateFromMui(value), timezone) || new Date(defaultValue));
        }
        setDate(newDate);
    };

    React.useEffect(() => {
        sendValueToForm(date);
    }, [date, sendValueToForm]);


    const dateValue = new Date(field.value);
    if (dateValue > DateTime.utc(2092, 1, 1)) {
        field.value = new Date();
        handleChangeDate(field.value);
    }
  
    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
                inputFormat={dateOnlyFormatPicker}
                {...props}
                value={date}
                onChange={handleChangeDate}
                renderInput={(params) => <TextField {...params} />}
                disableMaskedInput
            />
        </LocalizationProvider>
    );
};