import React from 'react';

export const UcFullPage = (props) => {
    const isFullPage = props.isFullPage;

    return (
        <>
        { isFullPage &&
            <div style={{ height: '100%', width: '100%' }}>
                <div style={{ display: 'flex', height: '100%' }}>
                    <div style={{ flexGrow: 1 }}>
                        {props.children}
                    </div>
                </div>
            </div>
        }
        { !isFullPage &&
            <>
                {props.children}
            </>
        }
        </>
    );
}
