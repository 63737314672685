import React, { forwardRef } from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Paper } from "@mui/material";
import { useAuth } from "react-oidc-context";
import Draggable from "react-draggable";
import { UcThemeContext } from '../../lib/UcThemeProvider';
import { useUser } from 'components/auth/PermissionApiEndpoint';
import { stopImpersonate } from 'components/admin/Impersonate/ImpersonateAPIEndpoints';
import { useTranslation } from "react-i18next";
import { getDomainObj } from 'domain';
import { useTheme } from '@mui/material/styles';
import { useLocalStorage } from '@mantine/hooks';

function PaperComponent(props) {
    return (
        <Draggable
            handle="#draggable-dialog-title"
            cancel={'[class*="MuiDialogContent-root"]'}
        >
            <Paper {...props} />
        </Draggable>
    );
}

export const SignOutButton = forwardRef((props, ref) => {
    const { t } = useTranslation();
    const user = useUser();
    const auth = useAuth();
    const [open, setOpen] = React.useState(false);
    // ommitting syntax for the win
    const [themeState,] = React.useContext(UcThemeContext);
    const domain = getDomainObj();
    const theme = useTheme();
    const [lastSignInOut, setLastSignInOut] = useLocalStorage({ key: 'lastSignInOut', defaultValue: null });

    console.log("LAST SIGN IN/OUT", lastSignInOut);

    const handleCancel = () => {
        setOpen(false);
    };

    const handleLogout = () => {
        if (user?.isImpersonating)
        {
            stopImpersonate(user.impersonate);
        }

        auth.signoutRedirect({
            extraQueryParams: {
                "autoSignout": "true",
                "userThemePreference": themeState.themeMode,
                ...(domain.subDomain && { "subdomain": domain.subDomain })
            },
        })
        setOpen(false);
        setLastSignInOut("SignOut");
    };

    return (
        <>
            <Dialog
                maxWidth={"sm"}
                open={open}
                PaperComponent={PaperComponent}
                aria-labelledby="draggable-dialog-title"
            >
                <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
                    {t("Sign out")}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {t("Are you sure you want to sign out?")}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={handleCancel} variant={theme.props?.CancelButton?.variant}>
                        {t("Cancel")}
                    </Button>
                    <Button data-cy-id={"signOutButton-confirm"} onClick={handleLogout} variant={theme.props?.CancelButton?.variant} color={theme.props?.CancelButton?.color}>
                        {t("Sign out")}
                    </Button>
                </DialogActions>
            </Dialog>
            <Button data-cy-id={"signOutButton"} ref={ref} style={props.style} variant="secondary" onClick={() => setOpen(true)}>{props.children || t("Sign out")}</Button>
        </>
    );
});