import React, { forwardRef } from "react";
import { Switch, FormControlLabel } from '@mui/material';
import { useUserPref } from "components/user/useUserPref";
import { useTranslation } from "react-i18next";
import { DevOneSignalInfo } from "components/dev/DevOneSignalInfo";

export const UcEnablePushNotificationsSwitch = forwardRef((props, ref) => {
    const { t } = useTranslation();
    const enablePushNotifications = useUserPref("enablePushNotifications", false);

    const handleChangeEnablePushNotifications = (event) => {
        enablePushNotifications.setUserPref("enablePushNotifications", !(enablePushNotifications.valueBool));
    }

    // TODO: Make sure to remove DevOneSignalInfo when this is complete
    return (
        <>
            <FormControlLabel 
                sx={{mb: 2}}
                label={t("Test Push Notifications")}
                control={
                    <Switch checked={(enablePushNotifications.valueBool)}
                        onChange={handleChangeEnablePushNotifications}
                        inputProps={{ 'aria-label': 'controlled' }} />
                }
            />
            <DevOneSignalInfo />
        </>       
    );
});