import React from "react";
import { useTranslation } from "react-i18next";
import { Button, Typography, Grid } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import { useResponsive } from 'lib/useResponsive';
import { appendDomainToUrlStr, getFullUrl, domain } from "../../domain";
import { useMyUser } from './MyUserAPIEndpoints';
import { UcThemeContext } from '../../lib/UcThemeProvider';

export const MyUserMFASettings = () => {
    const { t } = useTranslation();
    const [themeState, ] = React.useContext(UcThemeContext);
    const { isMobile } = useResponsive();
    const swrMyUser = useMyUser();
    const subDomain = domain.subDomain;

    let itemToEdit = null;
    if (!swrMyUser.isLoading && swrMyUser.result) {
        itemToEdit = { ...swrMyUser.result };
    }

    const goToManageMFA = () => {
        const authorityUrlPath = appendDomainToUrlStr(`${process.env.REACT_APP_UCAUTH_AUTHORITY}/account/manage/2fa?userThemePreference=${themeState.themeMode}`);

        if (domain.subDomain) {
            window.location = `${authorityUrlPath}&ReturnUrl=${encodeURIComponent(getFullUrl('/myProfile'))}&subdomain=${subDomain}`;
        }
        else {
            window.location = `${authorityUrlPath}&ReturnUrl=${encodeURIComponent(getFullUrl('/myProfile'))}`;
        }
    }

    return (
        <Grid container sx={{ justifyContent: 'center', alignItems: 'center', display: 'flex' }}>
            <Grid item xs={12} sm={12} md={6}>
                <Grid container spacing={2}>
                    {!isMobile && (
                        <>
                            <Grid item xs={9}>
                                <Typography variant="h6" gutterBottom>{t("Multi-Factor Authentication Settings")}</Typography>
                            </Grid>
                            <Grid item xs={3} style={{textAlign: 'right'}}>
                                <Button size="small" sx={{ minWidth: 'auto' }}
                                    data-cy-id={"user-manage-mfa"}
                                    variant="contained"
                                    color="primary"
                                    onClick={goToManageMFA}
                                >
                                    <EditIcon fontSize="small" />
                                </Button>
                            </Grid>
                        </>
                    )}
                    <Grid item xs={12} sm={12} md={12}>
                        <Typography variant="caption" display="block" gutterBottom>
                            {t("Enabled")}
                        </Typography>
                        <Typography variant="body1" display="block" gutterBottom>
                            {itemToEdit?.twoFactorEnabled ? t('Yes') : t('No')}
                        </Typography>
                    </Grid>
                    {isMobile && (
                        <Grid item xs={12} sm={12} md={12}>
                            <Button fullWidth
                                color="primary"
                                data-cy-id={"user-manage-mfa"}
                                variant="contained"
                                onClick={goToManageMFA}
                                startIcon={<EditIcon />}
                            >
                                {t("Manage Two-factor Settings")}
                            </Button>
                        </Grid>
                    )}
                </Grid>
            </Grid>
        </Grid>
    );
}