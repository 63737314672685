import React, { useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Paper from '@mui/material/Paper';
import Draggable from 'react-draggable';
import PropTypes from 'prop-types';
import { UserNotificationPreferenceForm } from './UserNotificationPreferenceForm';
import { saveUserNotificationPreference, deleteUserNotificationPreference, getUserNotificationPreferenceDefaultItem } from './UserNotificationPreferenceAPIEndpoints';
import { useResponsive } from 'lib/useResponsive';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material/styles';

function PaperComponent(props) {
    return (
        <Draggable
            handle="#draggable-dialog-title"
            cancel={'[class*="MuiDialogContent-root"]'}
        >
            <Paper {...props} />
        </Draggable>
    );
}

export const UserNotificationPreferenceFormDialog = (props) => {
    const { t } = useTranslation();
    const [dialogSubmit, setDialogSubmit] = useState(0);
    const [saveDisabled, setSaveDisabled] = useState(false);
    const { isMobile } = useResponsive();
    const theme = useTheme();

    let formId = props.id;
    if (!props.open) {
        formId = 0;
    }

    const handleCancel = () => {
        setSaveDisabled(false);
        props.onCancel();
    };

    const handleSave = () => {
        setDialogSubmit(dialogSubmit + 1);
    };

    const handleDelete = async () => {
        let data = await deleteUserNotificationPreference(props.itemToEdit);

        if (data) {
            props.onSave(data);
        }
    };

    const handleSaveDisabled = (value) => {
        setSaveDisabled(value);
    }

    return (
        <>
            {props.delete && props.open &&
                <Dialog
                    fullScreen={isMobile}
                    fullWidth={true}
                    maxWidth={"sm"}
                    open={props.open}
                    PaperComponent={PaperComponent}
                    aria-labelledby="draggable-dialog-title"
                >
                    <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
                        {t("Delete Notification Preference")}
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            {t("Are you sure you want to delete this Notification Preference?")}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button autoFocus onClick={handleCancel} variant={theme.props?.CancelButton?.variant}>
                            {t("Cancel")}
                        </Button>
                        <Button
                            onClick={handleDelete}
                            variant={theme.props?.CancelButton?.variant}
                            color={theme.props?.CancelButton?.color}
                            data-cy-id={"delete_button"}
                        >
                            {t("Delete")}
                        </Button>
                    </DialogActions>
                </Dialog>
            }
            {!props.delete && props.open &&
                <Dialog
                    fullScreen={isMobile}
                    fullWidth={true}
                    maxWidth={"md"}
                    open={props.open}
                    PaperComponent={PaperComponent}
                    aria-labelledby="draggable-dialog-title"
                >
                    <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
                        {t("Notification Set Up")}
                    </DialogTitle>
                    <DialogContent>
                        <UserNotificationPreferenceForm
                            id={formId}
                            userId={props.userId}
                            itemToEdit={props.itemToEdit}
                            handleSaveDisabled={handleSaveDisabled}
                            dialogSubmit={dialogSubmit}
                            onSubmit={async (values) => {
                                let data = await saveUserNotificationPreference(values);

                                if (data) {
                                    props.onSave(data);
                                }
                            }}
                            onError={(error) => { props.onError(error); }}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button
                            autoFocus onClick={handleCancel}
                            variant={theme.props?.CancelButton?.variant || "outlined"}
                            color={theme.props?.CancelButton?.color}
                            data-cy-id={"cancel_button"}
                        >
                            {t("Cancel")}
                        </Button>
                        {props.itemToEdit?.frequency.toLowerCase() !== 'automated' && (
                            <Button
                                onClick={handleSave}
                                variant="contained"
                                data-cy-id={"save_button"}
                                disabled={saveDisabled}
                            >
                                {t("Save")}
                            </Button>
                        )}
                    </DialogActions>
                </Dialog>
            }
        </>
    );
}

UserNotificationPreferenceFormDialog.defaultProps = {
    open: false,
    id: 0,
    itemToEdit: getUserNotificationPreferenceDefaultItem(),
    onCancel: () => { console.log("onCancel not implemented"); },
    onSave: (data) => { console.log("onSave not implemented"); },
    onError: (error) => { console.error("onError not implemented", error); },
};

UserNotificationPreferenceFormDialog.propTypes = {
    open: PropTypes.bool,
    id: PropTypes.number,
    itemToEdit: PropTypes.object,
};