import React from 'react';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import { useTranslation } from "react-i18next";
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import PhoneIcon from '@mui/icons-material/Phone';
import EmailIcon from '@mui/icons-material/Email';
import HouseIcon from '@mui/icons-material/House';
import { useDomainNavigate } from "domain";
import { PageContext } from 'lib/PageProvider';
import { routePaths } from 'routes';
import { UcLoading } from 'components/common/UcLoading';
import { OrgAppSettingsContext } from '../../lib/OrgAppSettingsProvider';
import {markdownFormatter} from 'lib/Formatters';

export const AccountAuthorizationPage = (props) => {
    const { orgAppSettings } = React.useContext(OrgAppSettingsContext);
    const { isRenderedInDialog, onCancel, onLookupAccount, onProceed } = props;
    const { t } = useTranslation();
    const navigate = useDomainNavigate();
    const [ pageState, pageDispatch ] = React.useContext(PageContext);

    const orgPhoneNumber = orgAppSettings.result?.phoneNumber;
    const disclaimer = orgAppSettings.result?.contactDisclaimer;
    let disclaimerWithNumber = disclaimer?.replace("{PhoneNumber}", orgPhoneNumber);

    const handleOnClickAccountVerification = () => {
        if (isRenderedInDialog) {
            onProceed();
        }
        else {
            navigate(routePaths.accountVerificationPage.path);
        }
    }

    const handleOnClickCancel = () => {
        if (isRenderedInDialog) {
            onCancel();
        }
        else {
            navigate(routePaths.landingPage.path);
        }
    }

    const handleOnClickLookupAccount = (lookupBy) => {
        if (isRenderedInDialog) {
            onLookupAccount(lookupBy);
        }
        else {
            navigate(`${routePaths.accountLookupPage.path}?lookupBy=${lookupBy}`);
        }
    }

    React.useEffect(() => {
        pageDispatch({
            header: "Account Verification",
        });
    }, [pageState.header, pageDispatch]);

    console.log("AccountAuthorizationPage - Loaded");
    return (
        <>
            <Grid container alignItems="center" justifyContent="center" spacing={2}>
                <Grid item xs={12}>
                    <Alert severity="warning" sx={{mb: 2}}>
                        {t('In order to proceed, we need to verify your identity. Please select from the options below.')}
                    </Alert>
                </Grid>
                <Grid item xs={12}>
                    <Button fullWidth
                        color='primary'
                        variant="contained"
                        startIcon={<CheckBoxIcon />}
                        onClick={handleOnClickAccountVerification}
                    >
                        {t('I know my account number')}
                    </Button>
                </Grid>
                {(props.showAdditionalVerification || disclaimerWithNumber) && (
                    <Grid item xs={12}>
                        <Divider>OR</Divider>
                    </Grid>
                )}
                {props.showAdditionalVerification ? (
                    <>
                        <Grid item xs={12}>
                            <Button fullWidth
                                variant="contained"
                                startIcon={<PhoneIcon />}
                                onClick={() => { handleOnClickLookupAccount('Phone')}}
                            >
                                {t('Find my account using my phone number')}
                            </Button>
                        </Grid>
                        <Grid item xs={12}>
                            <Button fullWidth
                                variant="contained"
                                startIcon={<EmailIcon />}
                                onClick={() => { handleOnClickLookupAccount('Email')}}
                            >
                                {t('Find my account using my email')}
                            </Button>
                        </Grid>
                        <Grid item xs={12}>
                            <Button fullWidth
                                variant="contained"
                                startIcon={<HouseIcon />}
                                onClick={() => { handleOnClickLookupAccount('Address')}}
                            >
                                {t('Find my account using my address')}
                            </Button>
                        </Grid>
                    </>
                ) : (
                    disclaimerWithNumber != null && disclaimerWithNumber !== "" &&
                    <UcLoading loading={orgAppSettings?.isLoading}>
                        <Grid item xs={12}>
                            <Alert severity='info'>
                            <>
                                    <AlertTitle>{t('Contact Us')}</AlertTitle>
                                    {markdownFormatter(disclaimerWithNumber)}
                            </>
                            </Alert>
                        </Grid>
                    </UcLoading>
                )}
                <Grid item xs={12}>
                    <Link
                        component="button"
                        onClick={handleOnClickCancel} variant="body2" color='error'>
                            {t('Cancel Account Authorization')}
                    </Link>
                </Grid>
            </Grid>
        </>
    );
}