import { useTranslation } from 'react-i18next';
import type { Payload } from "recharts/types/component/DefaultLegendContent";
import type { LegendProps } from "recharts";

export const RateClassComparisonLegend = (props: LegendProps) => {
    const { payload } = props;
    const { t } = useTranslation();
    let mainPeriodPayload = null;
    let comparePeriodPayload = null;

    if (payload && payload.length >= 2) {
        mainPeriodPayload = payload[0];
        comparePeriodPayload = payload[1];
    }
    const getIcon = (entry: Payload | null) => {
        return <div style={{display:'inline-block', width: props.iconSize, height: props.iconSize, backgroundColor: entry?.color, borderRadius: '10px'}}></div>;
    }

    return (
        // keep ucLegend class for styling
        <ul className="ucLegend" data-cy-id="rateClassComparison-legend"> 
            <li>
                <div title={t("Current Rate Cost")} style={{ color: mainPeriodPayload?.color }}> {getIcon(mainPeriodPayload)} {t("Current Rate Cost")}</div> 
            </li>
            <li>
                <div title={t("Compared Rate Cost")} style={{ color: comparePeriodPayload?.color }}> {getIcon(comparePeriodPayload)} {t("Compared Rate Cost")}</div> 
            </li>          
        </ul>
    );
  }

export default RateClassComparisonLegend;


