import { useTranslation } from "react-i18next";
import { Grid, Typography, Button, Divider } from "@mui/material";
import { UcLoading } from "../common/UcLoading";
import { UcProgress } from "../common/UcProgress";
import EnsureUserAndModule from "../auth/EnsureUserAndModule";
// @ts-ignore
import { useHasModule } from "components/auth/PermissionApiEndpoint";

export const UserBillOptionsWidget = () => {
    const { t } = useTranslation();
    const dummyProgress = { current: 100, total: 100 };
    const hasPaymentusWalletModule = useHasModule("PaymentusWallet");
    const hasPaymentusAutoPayModule = useHasModule("PaymentusAutoPay");

    return (
        <EnsureUserAndModule
            permissions={["Org:Modify"]}
            modules={["Billing"]}
        >
            <div className="dataCyId" data-cy-id="userBillOptions_widget" />
            <UcLoading
                hasFade={false}
                isLoading={false}
                height={"20vh"}
                errorMessage={t(
                    "An error occurred retrieving bill options."
                )}
                loadingRender={<UcProgress {...dummyProgress} />}
            >
                    <Grid container spacing={2}>
                        <Grid item xs>
                            <Typography variant="h6">
                                {t("Pre-Authorized Payments")}
                            </Typography>
                            <Typography variant="caption">
                                {t("Set and forget your payments")}
                            </Typography>
                        </Grid>
                        <Grid item sx={{ display: "flex" }}>
                            <Button
                                color="success"
                                sx={{
                                    color: "#fff",
                                    my: "auto",
                                    minWidth: 112,
                                }}
                                variant="contained"
                                fullWidth
                                size="small"
                            >
                                {t("Get Started")}
                            </Button>
                        </Grid>
                    </Grid> 
                    { hasPaymentusWalletModule && ( 
                        <>
                            <Divider
                                sx={{
                                    my: 2,
                                    borderBottomWidth: 2,
                                }}
                            />
                            <Grid container spacing={2}>
                                <Grid item xs>
                                    <Typography variant="h6">
                                        {t("Wallet")}
                                    </Typography>
                                    <Typography variant="caption">
                                        {t("View and manage your payment methods")}
                                    </Typography>
                                </Grid>
                                <Grid item sx={{ display: "flex" }}>
                                    <Button
                                        variant="contained"
                                        fullWidth
                                        size="small"
                                        href={'/paymentus/wallet'}
                                        target="_blank"
                                        sx={{ my: "auto", minWidth: 112 }}
                                    >
                                        {t("View Wallet")}
                                    </Button>
                                </Grid>
                            </Grid>
                        </>                      
                    )}
                    { hasPaymentusAutoPayModule && (
                        <>
                            <Divider
                                sx={{
                                    my: 2,
                                    borderBottomWidth: 2,
                                }}
                            />
                            <Grid container spacing={2}>
                                <Grid item xs>
                                    <Typography variant="h6">
                                        {t("Schedule Payments")}
                                    </Typography>
                                    <Typography variant="caption">
                                        {t(
                                            "Choose the day you pay for your utilities"
                                        )}
                                    </Typography>
                                </Grid>
                                <Grid item sx={{ display: "flex" }}>
                                    <Button
                                        variant="contained"
                                        fullWidth
                                        size="small"
                                        href={'/paymentus/autoPay'}
                                        target="_blank"
                                        sx={{ my: "auto", minWidth: 112 }}
                                    >
                                        {t("Learn More")}
                                    </Button>
                                </Grid>
                            </Grid>
                        </>   
                    )}                  
                </UcLoading>
            </EnsureUserAndModule>
    );
};
