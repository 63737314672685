import React, { useState, useLayoutEffect, useRef } from 'react';
import { useTranslation } from "react-i18next";
import { DataGridPro } from '@mui/x-data-grid-pro';
import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/Delete';
import { getWholeRow } from 'lib/DataGridHelpers';
import { currencyFormatter } from 'lib/Formatters';
import { UcFullPage } from 'components/common/UcFullPage';
import { UcPagination } from 'components/common/UcPagination';
import { UcIconButtons } from 'components/common/UcIconButtons';
import { useRateBasicRules } from './RateBasicRuleAPIEndpoints';

export default function RateBasicRuleGrid(props) {
    const { t } = useTranslation();
    const rateChargeType = props.rateChargeType;
    const initialSort = [{ field: "rate", sort: "desc" }];
    const sort = props.sort || initialSort;

    const initialDataState = props.dataState || { skip: 0, take: 10, };

    const [filter, setFilter] = useState({
        take: initialDataState.take,
        skip: initialDataState.skip,
        sort: sort,
        rateChargeId: props.rateChargeId,
    });

    let initialFilterModel = {
        items: [
            { columnField: 'id', operatorValue: 'contains', value: '' }
        ],
        linkOperator: 'and',
    };

    const [filterModel] = useState(initialFilterModel);

    const needsDataUpdate = useRef("");

    let defaultDataItems = {
        columns: [
            {field: 'htmlColor', headerName: '', width: 30, renderCell: (params) =>{
                return <div style={{ backgroundColor: params.value, width: '28px', height: '28px', borderRadius: '50%' }}></div>;
            }},
            {field: 'name', headerName: t('Name'), width: 150 },
            {
                field: 'rate',
                headerName: t('Rate'),
                type: 'number',
                width: 100,
                valueFormatter: (params) => {
                    return currencyFormatter(params.value, 6);
                }
            },
            { field: 'id', headerName: '',
                valueGetter: getWholeRow,
                renderCell: (params) => {
                    const actions = [
                        {
                            title: t('Edit {{rateChargeType}} Rule', {rateChargeType: rateChargeType}),
                            icon: <EditIcon />,
                            color: 'primary',
                            isHidden: false,
                            isDisabled: false,
                            requiredPermission: 'RateBasicRule:Modify',
                            onClick: () => { props.onEditItem(params.value); },
                        },
                        {
                            title: t('Delete {{rateChargeType}} Rule', {rateChargeType: rateChargeType}),
                            icon: <DeleteIcon />,
                            color: 'error',
                            isHidden: false,
                            isDisabled: params.value.startingUnit <= 0 && rateChargeType !== 'GENERATION' ? true : false,
                            requiredPermission: 'RateBasicRule:Delete',
                            onClick: () => { props.onDeleteItem(params.value); },
                        }
                    ];

                    return (
                        <UcIconButtons actions={actions} />
                    );
            }
        },
        ],
        rows: [],
        rowCount: -1,
    };
    const [dataItems, setDataItems] = useState(defaultDataItems);

    const defaultSelectedItem = { dataItem: {}, selectedId: 0 };
    const [selectedItem, setSelectedItem] = useState(defaultSelectedItem);

    const isDataLoaded = useRef(false);

    const dataReceived = (swrResult) => {
        var receivedDataItems = swrResult.result;

        var selectedDataItems = receivedDataItems.map((item) => {
            return { ...item, selected: item.id === selectedItem.selectedId };
        });

        selectedDataItems.iteration = receivedDataItems.iteration;
        selectedDataItems.rowCount = swrResult.rowCount;

        if (selectedDataItems.length > selectedDataItems.rowCount) {
            selectedDataItems.rowCount = selectedDataItems.length;
        }

        setDataItems({ ...defaultDataItems, rows: [...receivedDataItems], rowCount: swrResult.rowCount });
    };

    const swrRateBasicRules = useRateBasicRules(filter);
    const isLoading = swrRateBasicRules.isLoading || !isDataLoaded.current;
    if (!swrRateBasicRules.result && defaultDataItems.rowCount !== -1) {
        setDataItems(defaultDataItems);
    }

    useLayoutEffect(() => {
        if (swrRateBasicRules.result) {
            isDataLoaded.current = true;
            var filterString = JSON.stringify(filter);
            if (needsDataUpdate.current !== filterString) {
                needsDataUpdate.current = filterString;
                dataReceived(swrRateBasicRules);
            }
        }
    });

    const updateFilter = (newFilter) => {
        setFilter(newFilter);
        props.onFilterChange(newFilter);
    };

    const isFullPage = props.isFullPage || false;

    return (
        <UcFullPage isFullPage={isFullPage}>
            <div>
                <DataGridPro
                    hideFooter={true}
                    disableColumnFilter={true}
                    disableColumnSelector={true}
                    loading={isLoading}
                    sortingMode='server'
                    autoHeight={!isFullPage}
                    filterMode='server'
                    sortModel={filter.sort}
                    onSortModelChange={(model) => updateFilter({ ...filter, sort: model })}
                    filterModel={filterModel}
                    paginationModel={{page: filter.skip / filter.take, pageSize: filter.take}}
                    onPaginationModelChange={(model) => updateFilter({ ...filter, skip: model.page * model.pageSize, take: model.pageSize })}nge={(newPageSize) => updateFilter({ ...filter, take: newPageSize })}

                    paginationMode='server'
                    rowsPerPageOptions={[5, 10, 20]}

                    onRowSelectionModelChange={(newSelectionModel) => {
                        if (newSelectionModel.length !== 1) {
                            setSelectedItem(defaultSelectedItem);
                            props.onSelected(defaultSelectedItem.dataItem);
                            return;
                        }
                        const selectedId = newSelectionModel[0];
                        var selectedDataItem = dataItems.rows.filter(obj => { return obj.id === selectedId });
                        if (selectedDataItem.length !== 1) {
                            setSelectedItem(defaultSelectedItem);
                            props.onSelected(defaultSelectedItem.dataItem);
                            return;
                        }
                        selectedDataItem = selectedDataItem[0];
                        var newlySelectedItem = { dataItem: selectedDataItem, selectedId: selectedId };

                        setSelectedItem(newlySelectedItem);
                        props.onSelected(newlySelectedItem.dataItem);
                    }}
                    pagination
                    components={{
                        Toolbar: () => {return  props.toolbar || <></>},
                        Pagination: UcPagination,
                    }}
                    {...dataItems}
                    initialState={{ pinnedColumns: { right: ['id'] } }}
                />
            </div>
        </UcFullPage>
    );
}

RateBasicRuleGrid.defaultProps = {
    onSelected: (selectedItem) => { console.log("onSelected not implemented", selectedItem); },
    onFilterChange: (filter) => { console.log("onFilterChange not implemented", filter); },
};
