import React, { useContext, useEffect, useState } from 'react';
import { UserDetailsPage } from 'components/adminUc/Users/UserDetailsPage';
import { useUser } from 'components/auth/PermissionApiEndpoint';
import { useTranslation } from 'react-i18next';
import { PageContext } from 'lib/PageProvider';
import { Navigate } from "react-router-dom";
import EditIcon from '@mui/icons-material/Edit';
import { UcToolbar } from '../../common/UcToolbar';
import { UserFormDialog } from '../../adminUc/Users/UserFormDialog';
import { useUser as useImpersonateUser} from '../../adminUc/Users/UserAPIEndpoints';
import { getUrl } from 'domain';

export const ImpersonatePage = (props) => {
    const user = useUser();
    const impersonateUser = useImpersonateUser(user.impersonatingUserId);
    const { t } = useTranslation();
    const [pageState, pageDispatch] = useContext(PageContext);
    const [editDialog, setEditDialog] = useState({ open: false, delete: false });

    useEffect(() => {
        pageDispatch({
            header: "Impersonate User Details",
        });
    }, [pageState.header, pageDispatch]);

    const actions = [
        {
            icon: <EditIcon />,
            title: t('Edit Impersonated User'),
            toolTip: t('Edit User'),
            onClick: () => { editItem(); },
        }
    ];

    const editItem = () => {
        openDialog(impersonateUser.result);
    }

    const openDialog = (dataItem) => {
        if (!dataItem) {
            return;
        }
        if (!dataItem.formIteration) {
            dataItem.formIteration = "edit" + dataItem.id;
        }
        setEditDialog({ open: true, itemToEdit: dataItem, delete: false });
    }

    const closeHandler = () => {
        setEditDialog({ open: false, itemToEdit: editDialog.dataItem });
    }

    const saveHandler = () => {
        closeHandler();
    }

    console.log("ImpersonatePage - Loaded");
    return (
        <>
            <UcToolbar actions={actions}>
            </UcToolbar>
            <UserFormDialog
                open={editDialog.open}
                itemToEdit={editDialog.itemToEdit}
                onCancel={closeHandler}
                onSave={saveHandler}
            />
            <UserDetailsPage userId={user.impersonatingUserId} showDetails={false} />

            {!user.isImpersonating &&
                <>{t("Invalid Page - Not currently impersonating")}
                    <Navigate replace to={getUrl("/")} />
                </>
            }
        </>
    );
}