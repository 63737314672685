import React, { useState, useContext, useEffect, useLayoutEffect } from 'react';
import { useParams } from "react-router-dom"
import { getRolePermissionsDefaultItem } from './RolePermissionAPIEndpoints';
import { PageContext } from '../../../lib/PageProvider';
import { RolePermissionSwitches } from 'components/admin/Roles/RolePermissionSwitches';
import { RolePermissionFormDialog } from './RolePermissionFormDialog';
import { mutateUrls } from "../../../lib/useGenericSWR";
import { useSWRConfig } from "swr";
import { UcToolbar } from '../../common/UcToolbar';
import { EnsureUser } from 'components/auth/EnsureUser';
import { useHasPermission } from 'components/auth/PermissionApiEndpoint';
import EditIcon from '@mui/icons-material/Edit';

export const RolePermissionList = (props) => {
    let roleId = useParams()['roleId'] || props.roleId;
    const [editDialog, setEditDialog] = useState({ open: false, delete: false });
    const [pageState, pageDispatch] = useContext(PageContext);

    const [reMutateUrls, setReMutateUrls] = useState(false);
    const swrConfig = useSWRConfig();

    const closeHandler = () => {
        setEditDialog({ open: false });
    }

    const saveHandler = () => {
        closeHandler();

        // Also, remutate
        setReMutateUrls(true);
    }

    const openDialog = (dataItem) => {
        if (!dataItem) {
            return;
        }

        if (!dataItem.formIteration) {
            dataItem.formIteration = "edit" + dataItem.id;
        }

        setEditDialog({ open: true, itemToEdit: dataItem, delete: false });
    }

    const editItem = () => {
        openDialog(getRolePermissionsDefaultItem());
    }

    const actions = [
        {
            title: 'Edit Permissions',
            toolTip: 'Edit Permissions of Role',
            icon: <EditIcon />,
            onClick: () => { editItem(); },
            isHidden: !useHasPermission("RolePermission:Modify"),
            isDisabled: false
        },
    ];

    useLayoutEffect(() => {
        if (reMutateUrls) {
            mutateUrls(swrConfig);
            setReMutateUrls(false);
        }
    }, [reMutateUrls, swrConfig]);

    useEffect(() => {
        pageDispatch({
            header: "Role Permissions",
        });
    }, [pageState.header, pageDispatch]);

    console.log("RolePermissionPage - Loaded");
    return (
        <EnsureUser permissions={["RolePermission:Read"]}>
            <div className="rolePermissionList">
                <UcToolbar actions={actions}></UcToolbar>
                <RolePermissionFormDialog
                    roleId={roleId}
                    open={editDialog.open}
                    delete={editDialog.delete}
                    itemToEdit={editDialog.itemToEdit}
                    onCancel={closeHandler}
                    onSave={saveHandler}
                />
                {!reMutateUrls &&
                    <RolePermissionSwitches roleId={roleId} readonly={true} />
                }
            </div>
        </EnsureUser>
    );
}