import React from 'react';
import { Formik, Form, Field } from 'formik';
import { TextField } from 'formik-mui';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import { useTranslation } from "react-i18next";
import { AccountVerificationStep1Schema, LookupAccountWrapper } from './AccountAuthorizationApiEndpoints';

export const AccountVerificationStep1 = (props) => {    
    const { t } = useTranslation();
    const formRef = React.useRef();
    const dialogSubmit = React.useRef(props.dialogSubmit);
    const [account, setAccount] = React.useState(null);
    const [lookupAccount, setLookupAccount] = React.useState(false);
    const initialValues = { accountNumber: '', accountName: '' };

    React.useEffect(() => {
        props.onIsLoading(lookupAccount);

        if (props.dialogSubmit !== dialogSubmit.current) {
            dialogSubmit.current = props.dialogSubmit;
            if (formRef.current) {
                formRef.current.handleSubmit();
            }
        }

        if (account !== null 
            && !account?.isLoading 
            && formRef.current.values.accountNumber !== ''
            && formRef.current.values.accountName !== '') {
            props.onNext(account);
        }
    });

    return (
        <div className='ignoreCustomFormStyles'>
           <Formik
                innerRef={formRef}
                validationSchema={AccountVerificationStep1Schema}
                initialValues={initialValues}
                onSubmit={async () => {
                    setLookupAccount(true);
                }}
            >
                <Form>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Typography component={'p'} variant='body1'>
                                {t('We can lookup how to verify your identity using your account number and name. Please enter it below.')}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Field
                                name={"accountNumber"}
                                placeholder={'(e.g. 1234567)'}
                                component={TextField}
                                label={t("Account Number")}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Field
                                name={"accountName"}
                                component={TextField}
                                label={t("Name (as it appears on bill)")}
                            />
                        </Grid>
                    </Grid>
                </Form>
            </Formik>
            {lookupAccount && (
                <LookupAccountWrapper
                    setState={setAccount}
                    setFetchingState={setLookupAccount}
                    accountNumber={formRef.current?.values?.accountNumber}
                    accountName={formRef.current?.values?.accountName}
                />
            )}
        </div>
    );
}