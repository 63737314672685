import axios from 'axios';
import { urlsToMutate } from './useGenericSWR';
import { toast } from 'material-react-toastify';
import { DateTime } from 'luxon';
import { domain } from 'domain';
import { userInfo } from 'components/auth/UserInfo';
import { delay } from './Delay';
import { API_CONFIG } from '../utils/constants/api.constants';

axios.defaults.baseURL = domain.baseURL;
export class ApiClient {
    constructor() {
        this.getDefaultItem = () => {
            return { id: 0, }
        };
    }

    getObjectName = () => {
        return "";
    };

    doToast = (actionType, actionStatus, toastId, additionalMessage, overrideMainMessage = false) => {
        let messageOptions = this.getToastMessageOptions(actionType, actionStatus, additionalMessage, overrideMainMessage);
        if (!messageOptions) {
            return null;
        }
        if (toastId) {
            return toast.update(toastId, messageOptions);
        }
        return toast(messageOptions.render, messageOptions);
    };
    getDefaultToastMessageOptions = () => {
        return {
            type: toast.TYPE.ERROR,
            autoClose: 5000,
            render: <div>Unexpected error has occurred.  Please try again</div>
        };
    };
    getToastMessageOptions = (actionType, actionStatus, additionalMessage, overrideMainMessage) => {
        let result = this.getDefaultToastMessageOptions();
        let objectName = this.getObjectName();

        let typeAction = actionType.toUpperCase() + "~" + actionStatus.toUpperCase();
        let mainMessageOverride = "";
        if (overrideMainMessage && additionalMessage) {
            mainMessageOverride = additionalMessage;
            additionalMessage = "";
        }
        else if (additionalMessage) {
            additionalMessage = " - " + additionalMessage;
        }

        switch (typeAction) {
            case 'CREATE~START':
                result.type = toast.TYPE.INFO;
                result.autoClose = false;
                result.render = <div>{mainMessageOverride || "Creating " + objectName} {additionalMessage}</div>;
                return result;
            case 'CREATE~SUCCESS':
                result.type = toast.TYPE.SUCCESS;
                result.autoClose = 1000;
                result.render = <div>{mainMessageOverride || "Created " + objectName} Successfully {additionalMessage}</div>;
                return result;
            case 'CREATE~ERROR':
                result.type = toast.TYPE.ERROR;
                result.autoClose = 5000;
                result.render = <div>{mainMessageOverride || "Create " + objectName} Failed {additionalMessage}</div>;
                return result;
            case 'READ~START':
            case 'READ~SUCCESS':
                return null;
            case 'READ~ERROR':
                result.type = toast.TYPE.ERROR;
                result.autoClose = 5000;
                result.render = <div>{mainMessageOverride || "Fetching " + objectName} Failed {additionalMessage}</div>;
                return result;
            case 'UPDATE~START':
                result.type = toast.TYPE.INFO;
                result.autoClose = false;
                result.render = <div>{mainMessageOverride || "Updating " + objectName} {additionalMessage}</div>;
                return result;
            case 'UPDATE~SUCCESS':
                result.type = toast.TYPE.SUCCESS;
                result.autoClose = 1000;
                result.render = <div>{mainMessageOverride || "Updated " + objectName} Successfully {additionalMessage}</div>;
                return result;
            case 'UPDATE~ERROR':
                result.type = toast.TYPE.ERROR;
                result.autoClose = 5000;
                result.render = <div>{mainMessageOverride || "Update " + objectName} Failed {additionalMessage}</div>;
                return result;
            case 'DELETE~START':
                result.type = toast.TYPE.INFO;
                result.autoClose = false;
                result.render = <div>{mainMessageOverride || "Deleting " + objectName} {additionalMessage}</div>;
                return result;
            case 'DELETE~SUCCESS':
                result.type = toast.TYPE.SUCCESS;
                result.autoClose = 1000;
                result.render = <div>{mainMessageOverride || "Deleted " + objectName} Successfully {additionalMessage}</div>;
                return result;
            case 'DELETE~ERROR':
                result.type = toast.TYPE.ERROR;
                result.autoClose = 5000;
                result.render = <div>{mainMessageOverride || "Delete " + objectName} Failed {additionalMessage}</div>;
                return result;
            case 'DELETE~WARNING':
                result.type = toast.TYPE.ERROR;
                result.autoClose = 5000;
                result.render = <div>{mainMessageOverride || "Warning " + objectName} {additionalMessage}</div>;
                return result;
            default:
                return result;
        }
    };

    fetchUrls = [];
    accessToken = "";
    isAuth = null; // isAuth is either true or false.  Need it to be null to start so we know it has not been set yet
    hideNotLoggedInError = false;
    requiresAuth = true;
    swrConfig = null;
    mutateFetchUrls = () => {
        while (this.fetchUrls.length > 0) {
            var url = this.fetchUrls.pop();
            urlsToMutate.push({ url: url, data: null });
        }
    };

    addMutateUrl = (url) => {
        urlsToMutate.push({ url: url, data: null});
    }

    obfuscateToken(token) {
        // Check if the token length is at least 10 characters
        if (token.length < 5) {
            // If not, return the token as is or handle it accordingly
            return token;
        }

        // Take last 5 characters
        const end = token.substring(token.length - 5);

        // Combine them with stars in the middle
        return `*****${end}`;
    }

    getAxiosConfig = async () => {
        const authValue = `Bearer ${window.accessToken}`;

        const hiddenToken = this.obfuscateToken(authValue);
        const myCookieHiddenToken = this.getCookie('AuthCookieObfuscate');

        let subDomain = domain.subDomain;
        if (!subDomain)
        {
            subDomain = "";
        }

        let headers = {
            "X-CSRF": 1,
            "SubDomain": subDomain,
        }

        if (hiddenToken !== myCookieHiddenToken)
        {
            // Only add the Authorization to the request if the cookie doesn't exist
            headers = {
                "Authorization": `Bearer ${window.accessToken}`,
                ...headers
            }
        }

        return {
            headers: headers
        }
    };

    getCookie = (name) => {
        const cookieArray = document.cookie.split(';');
        const cookie = cookieArray.find(c => c.trim().startsWith(`${name}=`));
        return cookie ? cookie.split('=')[1] : undefined;
    };

    // https://stackoverflow.com/a/50572644  (because we have relative urls most of the time)
    setUrlParams = (url, key, value) => {
        url = url.split('?');
        const usp = new URLSearchParams(url[1]);
        usp.set(key, value);
        url[1] = usp.toString();
        return url.join('?');
    };

    getUrl = (url) => {
        return url;
    };

    // Was really hoping not to have to implement something like this
    // SWR should handle this and does within a single route, on changing routes it refreshes everything again
    cacheUrlExipiration = [];
    cacheExirationMs = API_CONFIG.cache_exiration_milliseconds;
    fetcher = async (url, additionalMessage = "", overrideMainMessage = false, translation = null, doToast = true, fetchOptions = {}) => {
        if (!url)
        {
            // No url so just return nothing
            return { result: null };
        }

        if (this.requiresAuth && !userInfo.isLoggedIn) {
            if (userInfo.isLoading || window.isFirstAccess)
            {
                // retry in a bit
                console.log("User is loading url will retry in a second: " , url, window.isFirstAccess, userInfo);
                await delay(500);
                return await this.fetcher(url);
            }

            // Was an error but it always catches in cypress so making it warn
            console.warn("User is not logged in: " + url);
            return { result: null, error: { message: "You must be logged in to access this page: " + url } };
        }

        url = this.getUrl(url);
        let cache = null;
        if (this.swrConfig) {
            cache = this.swrConfig.cache;
        }

        if (cache && this.cacheUrlExipiration[url] && DateTime.now() < this.cacheUrlExipiration[url]) {
            const cachedResult = cache.get(url);
            if (cachedResult)
            {
                return cachedResult;
            }
        }

        // Only add distinct urls
        if (this.fetchUrls.indexOf(url) === -1) {
            this.fetchUrls.push(url);
        }

        if (url.endsWith('/0')) {
            // No need for an api request for a new item
            return this.getDefaultItem();
        }

        if (userInfo.isIdle)
        {
            console.log("User is idle... delaying request");
            await delay(5000);
            return await this.fetch(url);
        }

        var toastStart = undefined;

        if (doToast) {
            toastStart = this.doToast("READ", "START", null, additionalMessage, overrideMainMessage);
        }

        const axiosConfig = await this.getAxiosConfig();
        if (fetchOptions.signal) {
            axiosConfig.signal = fetchOptions.signal;
        }

        return axios.get(url, axiosConfig)
            .then((res) => {
                if (doToast) {
                    this.doToast("READ", "SUCCESS", toastStart, additionalMessage, overrideMainMessage);
                }

                this.cacheUrlExipiration[url] = DateTime.now().plus(this.cacheExirationMs);
                return res.data;
            })
            .catch((error) => {
                console.error('ApiClient.fetcher', error);
                if (error.response.status === 401) {
                    console.log('ApiClient.fetcher', userInfo);
                    sessionStorage.clear();
                    window.location.reload();
                    return;
                }
                if (error.response.status === 403) {
                    additionalMessage = translation !== null ? translation('Permission Denied') : 'Permission Denied';
                }
                if (!this.hideNotLoggedInError && !userInfo.isIdle)
                {
                    if (doToast) {
                        this.doToast("READ", "ERROR", toastStart, additionalMessage, overrideMainMessage);
                    }
                }
                throw error;
            });
    };
    poster = async (url, data, additionalMessage = "", overrideMainMessage = false, translation = null, doToast = true) => {
        url = this.getUrl(url);

        var toastStart = undefined;
        if (doToast) {
            toastStart = this.doToast("CREATE", "START", null, additionalMessage, overrideMainMessage);
        }

        const axiosConfig = await this.getAxiosConfig();
        return axios.post(url, data, axiosConfig)
            .then((res) => {
                if (doToast) {
                    this.doToast("CREATE", "SUCCESS", toastStart, additionalMessage, overrideMainMessage);
                }

                this.mutateFetchUrls();
                return res.data;
            })
            .catch((error) => {
                console.error('ApiClient.poster', error);
                if (error.response.status === 401) {
                    console.log('ApiClient.poster', userInfo);
                    sessionStorage.clear();
                    window.location.reload();
                    return;
                }
                if (error.response.status === 409) {
                    additionalMessage = translation !== null ? translation('Duplicate Entry') : 'Duplicate Entry';
                }
                if (error.response.status === 403) {
                    additionalMessage = translation !== null ? translation('Permission Denied') : 'Permission Denied';
                }
                if (doToast) {
                    this.doToast("CREATE", "ERROR", toastStart, additionalMessage, overrideMainMessage);
                }

                throw error;
            });
    };
    putter = async (url, data, additionalMessage = "", overrideMainMessage = false, translation = null, doToast = true) => {
        url = this.getUrl(url);

        var toastStart = undefined;
        if (doToast) {
            toastStart = this.doToast("UPDATE", "START", null, additionalMessage, overrideMainMessage);
        }

        const axiosConfig = await this.getAxiosConfig();
        return axios.put(url, data, axiosConfig)
            .then((res) => {
                this.mutateFetchUrls();

                if (doToast) {
                    this.doToast("UPDATE", "SUCCESS", toastStart, additionalMessage, overrideMainMessage);
                }

                return res.data;
            })
            .catch((error) => {
                console.error('ApiClient.putter', error);
                if (error.response.status === 401) {
                    console.log('ApiClient.putter', userInfo);
                    sessionStorage.clear();
                    window.location.reload();
                    return;
                }
                if (error.response.status === 409) {
                    additionalMessage = translation !== null ? translation('Duplicate Entry') : 'Duplicate Entry';
                }
                if (error.response.status === 403) {
                    additionalMessage = translation !== null ? translation('Permission Denied') : 'Permission Denied';
                }
                if (doToast) {
                    this.doToast("UPDATE", "ERROR", toastStart, additionalMessage, overrideMainMessage);
                }

                throw error;
            });
    };
    deleter = async (url, additionalMessage = "", overrideMainMessage = false, translation = null, doToast = true, errorMessageOverride = "") => {
        url = this.getUrl(url);

        let toastStart = undefined;
        if (doToast) {
            toastStart = this.doToast("DELETE", "START", additionalMessage, overrideMainMessage);
        }

        const axiosConfig = await this.getAxiosConfig();
        return axios.delete(url, axiosConfig)
            .then((res) => {
                this.mutateFetchUrls();

                if (doToast) {
                    this.doToast("DELETE", "SUCCESS", toastStart, additionalMessage, overrideMainMessage);
                }

                return res.data;
            })
            .catch((error) => {
                console.error('ApiClient.delete', error);
                if (error.response.status === 401) {
                    console.log('ApiClient.deleter', userInfo);
                    sessionStorage.clear();
                    window.location.reload();
                    return;
                }
                
                if (errorMessageOverride !== "") {
                    additionalMessage = translation !== null ? translation(errorMessageOverride) : errorMessageOverride;
                    overrideMainMessage = true;
                }
                if (error.response.status === 403) {
                    additionalMessage = translation !== null ? translation('Permission Denied') : 'Permission Denied';
                }
                if (error.response.status === 409) {
                    const messageText = 'Unable to delete item as it is linked to other content.';
                    additionalMessage = translation !== null ? translation(messageText) : messageText;
                    this.doToast("DELETE", "WARNING", toastStart, errorMessageOverride !== "" ? errorMessageOverride : additionalMessage, overrideMainMessage);
                    return;
                }
                if (doToast) {
                    this.doToast("DELETE", "ERROR", toastStart, errorMessageOverride !== "" ? errorMessageOverride : additionalMessage, overrideMainMessage);
                }

                throw error;
            });
    };
}