import React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import './LayoutLanding.scss';
import { UcFooter } from './common/UcFooter';
import { useResponsive } from 'lib/useResponsive';

export const LayoutLanding = (props) => {
    const theme = useTheme();
    const { isMobile } = useResponsive();

    return (
        <>
            <div className="layout-landing" style={{ paddingTop: isMobile && "30px", background: theme.props?.LandingPage?.background}}>              
                <Box>
                    {props.children}
                </Box>
            </div>
            <div id="footer">
                <UcFooter />
            </div>
        </>
    );
}
