import React, { useContext, useEffect } from 'react';
import { UcAdminDetails } from "../../common/UcAdminDetails";
import { billingCycleName } from './BillingCycleAPIEndpoints';
import { PageContext } from '../../../lib/PageProvider';
import {
    useParams,
} from "react-router-dom";
import { EnsureUser } from '../../auth/EnsureUser';
import { useHasPermission } from 'components/auth/PermissionApiEndpoint';
import { MeterCycleListPage } from './MeterCycleListPage';

export const BillingCycleDetailsPage = (props) => {
    let { billingCycleId } = useParams();
    billingCycleId = billingCycleId || props.billingCycleId;
    
    const params = new URLSearchParams(window.location.search);
    const billingCycleParamName = params.get("name");
    const [pageState, pageDispatch] = useContext(PageContext);

    useEffect(() => {
        pageDispatch({
            header: billingCycleName + " Details",
            variableHeader: billingCycleParamName,
            actionItems: []
        });
    }, [pageState.header, pageDispatch, billingCycleParamName]);

    let detailsRender = <></>;

    let tabs = [
        {
            title: "Meters",
            contentRender: <MeterCycleListPage billingCycleId={billingCycleId} billingCycleName={props.billingCycleName} />,
            isHidden: !useHasPermission("MeterCycle:Read")
        },
    ];

    return (
        <EnsureUser permissions={["BillingCycle:Read"]}>
            <UcAdminDetails detailsRender={detailsRender} tabs={tabs} tabInitValue={"Meters"} />
        </EnsureUser>
    );
};