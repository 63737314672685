// @ts-ignore
import { ApiClient } from "../../../lib/ApiClient";
import { useGenericSWR } from "../../../lib/useGenericSWR";
import { mutateMyOrgFaqSettings } from "components/user/MyOrgFaqSettingAPIEndpoints";
import * as Yup from "yup";

export const orgFaqSettingApiEndpoint = "api/v1/admin/uc/OrgFaqSetting";

export interface OrgFaqSetting {
    id?: number;
    cepOrgId: number;
    question: string;
    answer: string;
    sortOrder?: number;
}

let formIteration = 0;

const getOrgFaqSettingsDefaultItem = () => {
    formIteration = formIteration + 1;
    return {
        formIteration: "new" + formIteration,
        id: 0,
        cepOrgId: null,
        question: "",
        answer: "",
        sortOrder: 0,
    };
};

const orgFaqSettingsValidationSchema = Yup.object().shape({
    question: Yup.string()
        .required("Required")
        .max(256, "Max of 256 characters"),
    answer: Yup.string().required("Required")
        .max(512, "Max of 512 characters"),
});

const apiClient = new ApiClient();
apiClient.getDefaultItem = () => getOrgFaqSettingsDefaultItem();
apiClient.getObjectName = () => {
    return "FAQ Settings";
};

const useOrgFaqSettings = (cepOrgId: number, swrOptions: any = {}) => {
    const filter = {
        take: 100,
        skip: 0,
        sort: [{ field: "sortOrder", sort: "asc" }],
        cepOrgId: cepOrgId,
    };

    const params = new URLSearchParams();
    params.append("filter", JSON.stringify(filter));
    return useGenericSWR(
        orgFaqSettingApiEndpoint + "?" + params.toString(),
        apiClient,
        { ...swrOptions }
    );
};

const bulkUpdateOrgFaqSettings = (OrgFaqSettings: OrgFaqSetting[]) => {
    return apiClient.putter(
        orgFaqSettingApiEndpoint + "/bulkUpdate",
        OrgFaqSettings
    );
};

const saveOrgFaqSettings = (OrgFaqSetting: OrgFaqSetting) => {
    if (OrgFaqSetting.id === 0) {
        return insertOrgFaqSettings(OrgFaqSetting);
    }
    return updateOrgFaqSettings(OrgFaqSetting);
};

const insertOrgFaqSettings = (OrgFaqSetting: OrgFaqSetting) => {
    return apiClient
        .poster(orgFaqSettingApiEndpoint, OrgFaqSetting)
        .then((result: any) => {
            mutateMyOrgFaqSettings();

            return result;
        });
};

const updateOrgFaqSettings = (OrgFaqSetting: OrgFaqSetting) => {
    return apiClient.putter(orgFaqSettingApiEndpoint, OrgFaqSetting);
};

const deleteOrgFaqSettings = (OrgFaqSetting: OrgFaqSetting) => {
    return apiClient
        .deleter(orgFaqSettingApiEndpoint + "/" + OrgFaqSetting.id)
        .then((result: any) => {
            mutateMyOrgFaqSettings();

            return result;
        });
};

export {
    useOrgFaqSettings,
    saveOrgFaqSettings,
    insertOrgFaqSettings,
    updateOrgFaqSettings,
    bulkUpdateOrgFaqSettings,
    deleteOrgFaqSettings,
    orgFaqSettingsValidationSchema,
    getOrgFaqSettingsDefaultItem,
};
