import React, { useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Paper, { PaperProps } from '@mui/material/Paper';
import Draggable from 'react-draggable';
import { MyNicknamePreferencesForm } from './MyNicknamePreferencesForm';
// @ts-ignore
import { useResponsive } from 'lib/useResponsive';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material/styles';
import { updateMyNicknamePreferences } from './MyNicknamePreferencesAPIEndpoints';
import LoadingButton from '@mui/lab/LoadingButton';
import { Theme as MuiTheme } from '@mui/material';
import { UserAccount } from './MyUserAccount.interfaces';

function PaperComponent(props: PaperProps) {
    return (
        <Draggable
            handle="#draggable-dialog-title"
            cancel={'[class*="MuiDialogContent-root"]'}
        >
            <Paper {...props} />
        </Draggable>
    );
}

interface CustomInstanceOfMuiTheme extends MuiTheme {
    props: any;
}

interface MyNicknamePreferencesFormDialogProps {
    initialValues: UserAccount[],
    onCancel: Function,
    onSave: Function,
    open: boolean,
    username: string
}

export const MyNicknamePreferencesFormDialog = (props: MyNicknamePreferencesFormDialogProps) => {
    const { t } = useTranslation();
    const [dialogSubmit, setDialogSubmit] = useState(0);
    const { isMobile } = useResponsive();
    const theme = useTheme<CustomInstanceOfMuiTheme>();
    const [saving, setSaving] = React.useState(false);

    const handleCancel = () => {
        props.onCancel();
    };

    const handleSave = () => {
        setDialogSubmit(dialogSubmit + 1);
    };

    return (
        <>
            { props.open &&
                <Dialog
                    fullScreen={isMobile}
                    fullWidth={true}
                    maxWidth={"md"}
                    open={props.open}
                    PaperComponent={PaperComponent}
                    aria-labelledby="draggable-dialog-title"
                >
                    <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
                        {t("Edit Nicknames")}
                    </DialogTitle>
                    <DialogContent>
                        <MyNicknamePreferencesForm
                            username={props.username}
                            initialValues={props.initialValues}
                            dialogSubmit={dialogSubmit}
                            onSubmit={async (values: any) => {
                                setSaving(true);
                                try {
                                    let data = await updateMyNicknamePreferences(values);
                                    if (data) {
                                        props.onSave(data);
                                    }
                                }
                                finally {
                                    setSaving(false);
                                    window.location.reload();
                                }                                                        
                            }}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button
                            autoFocus
                            data-cy-id={"cancel_button"}
                            onClick={handleCancel}
                            variant={theme.props?.CancelButton?.variant || "outlined"}
                            color={theme.props?.CancelButton?.color}
                        >
                            {t("Cancel")}
                        </Button>
                        <LoadingButton
                            loading={saving}
                            onClick={handleSave}
                            variant="contained"
                        >
                            {t('Save')}
                        </LoadingButton>
                    </DialogActions>
                </Dialog>
            }
        </>
    );
}