import React, { useState } from 'react';
import { DateTime } from 'luxon';
import { useTranslation } from "react-i18next";
import { UcWidget } from '../common/UcWidget';
import { ensureLuxonDate } from 'lib/Formatters';
import { useWeatherData, groupWeatherTimelineByMonthAndYear } from 'components/weather/WeatherApiEndpoint';
import { useResponsive } from 'lib/useResponsive';
import { UcLoading } from '../common/UcLoading';
import { useDomainNavigate } from "domain";
import useOrgAppSettings from '../../lib/useOrgAppSettings';
import ThermostatIcon from '@mui/icons-material/Thermostat';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { useMyTimezone } from 'components/customer/orgs/MyOrgAPIEndpoints';
import { DashboardStatWrapper } from 'components/readings/ReadingMultipleUomAPIEndpoint';
import Alert from '@mui/material/Alert';
import LinearProgress from '@mui/material/LinearProgress';
import './UsageSummaryWidget.scss';
import {
    ResponsiveContainer
} from 'recharts';
import
{
    Box,
    Button,
    Grid,
    Typography,
    useTheme,
} from '@mui/material';
import { UcProgress } from '../common/UcProgress';
import { ElectricBolt, WaterDrop } from '@mui/icons-material';
import { TemperatureUtils } from '../../lib/Utils';

export const UsageSummaryWidget = (props) => {
    const { t } = useTranslation();
    const theme = useTheme();
    const { orgAppSettings } = useOrgAppSettings();
    const { isMobile } = useResponsive();
    const navigate = useDomainNavigate();
    const { account, orgTempUnit } = props;
    const [isFetching, setIsFetching] = React.useState(true);
    const [series, setSeries] = React.useState({});
    const [readingData, setReadingData] = React.useState(null);
    const [weatherData, setWeatherData] = React.useState([]);
    const [initialLoad, setInitialLoad] = React.useState(true);
    const [latestDateAvailable, setLatestDateAvailable] = React.useState();
    const actionItems = [
        {
            component: (
                <Button
                    color={"info"}
                    onClick={() => navigate('/customer/summary')}
                    data-cy-id="summary_widget_view_more_button"
                    size={"small"}
                    title={t('View More')}
                    endIcon={<ChevronRightIcon />}
                >
                    {t('View More')}
                </Button>
            ),
        },
    ];

    const barColors = {
        kWh: theme.props?.Commodity?.kWhColor,
        gal: theme.props?.Commodity?.galColor,
    };

    const geoCoordinates = JSON.parse(account?.metersJson)
        .filter(meter => meter.latitude !== undefined && meter.longitude !== undefined)
        .map(meter => {
            return {
                latitude: meter.latitude,
                longitude: meter.longitude
            }
    });
    
    const [hasError, setHasError] = useState(false);
    const [progress, setProgress] = useState({ current: 0, total: 100 });

    const timezone = useMyTimezone();
    const todaysMonth = ensureLuxonDate(new Date()).toFormat("MMMM");
    const yesterday = new Date();
    yesterday.setDate(yesterday.getDate() - 1);
    const dateStart = DateTime.fromObject({year: yesterday.getFullYear(), month: yesterday.getMonth() + 1, day: yesterday.getDate()}, { zone: timezone }).minus({ months: 3 }).startOf("month").toJSDate();
    const dateEnd = DateTime.fromObject({year: yesterday.getFullYear(), month: yesterday.getMonth() + 1, day: yesterday.getDate()}, { zone: timezone }).endOf("month").toJSDate();
    const swrWeatherData = useWeatherData(dateStart, dateEnd, geoCoordinates, true, { doToast: false});
    const average = array => array.reduce((a, b) => a + b) / array.length;

    const formatReadingName = (reading) => {
        if (reading?.billingPeriodStart && reading?.billingPeriodEnd) {
            if (ensureLuxonDate(reading.billingPeriodEnd) >= ensureLuxonDate(new Date())) {
                return ensureLuxonDate(reading.billingPeriodStart, true).toFormat("MMMM d") + " - "
            }
            else {
                return ensureLuxonDate(reading.billingPeriodStart, true).toFormat("MMMM d") + " - " + ensureLuxonDate(reading.billingPeriodEnd).toFormat("MMMM d, yyyy")
            }
        }
        else {
            return ensureLuxonDate(reading.readTimestampLocal).toFormat("MMMM yyyy")
        }
    };

    React.useEffect(() => {
        const handleChangeWeatherData = () => {
            setInitialLoad(false);
            const groupedData = groupWeatherTimelineByMonthAndYear(swrWeatherData.result?.days, 'dateTime');
            Object.keys(groupedData).forEach(element => {
                const maxTemps = groupedData[element].map(day => {
                    return day.tempMax;
                });

                groupedData[element].avgHigh = average(maxTemps);
            });

            setWeatherData(groupedData);
        }

        if (!swrWeatherData.isLoading && initialLoad && swrWeatherData.result?.days?.length > 0) {
            handleChangeWeatherData();
        }
    }, [initialLoad, swrWeatherData.isLoading, swrWeatherData.result?.days]);

    React.useEffect(() => {
        const checkBillingMonth = (readTimestampLocal, billingPeriodStart = null, billingPeriodEnd = null, ) => {
            const today = ensureLuxonDate(new Date());
            const isCurrentMonth = ensureLuxonDate(readTimestampLocal).toFormat("MMMM") === todaysMonth;

            if (billingPeriodStart && billingPeriodEnd) {
                return ensureLuxonDate(billingPeriodStart) <= today && ensureLuxonDate(billingPeriodEnd) >= today
                    ? t("Current")
                    : t("Previous");
            }

            return isCurrentMonth ? t("Current") : t("Previous");
        };

        if (!isFetching && !hasError && readingData && Object.keys(readingData).length > 0) {
            let newObjectSeries = {};
            Object.keys(readingData).forEach(key => {
                const uomResult = readingData[key];

                uomResult.readings.forEach(reading => {
                    let date = DateTime.fromISO(reading.readTimestampLocal);
                    reading.readTimestampLocalDate = DateTime.fromObject({year: date.year, month: date.month, day: date.day}); // only care about days here)
                });

                uomResult.readings.reduce((prevMinMaxCategory, dataItem) => {
                    let date = dataItem.readTimestampLocalDate;
                    dataItem.readTimestampLocal = date; // so we can ensure they are all in sthe same format
                    if (prevMinMaxCategory.minDate === 0 || prevMinMaxCategory.minDate > date) {
                        prevMinMaxCategory.minDate = date;
                    }

                    if (prevMinMaxCategory.maxDate === 0 || prevMinMaxCategory.maxDate < date) {
                        prevMinMaxCategory.maxDate = date;
                    }
                    return prevMinMaxCategory;
                }, {minDate: 0, maxDate: 0});

                let newSeries = [];
                let totalReadingValues = [];

                uomResult.readings.forEach(reading => {
                    if (newSeries[reading?.readTimestampLocal?.ts] === undefined) {
                        newSeries[reading.readTimestampLocal.ts] = {
                            name: formatReadingName(reading),
                            tsLocal: reading?.readTimestampLocal?.ts,
                            monthType: checkBillingMonth(reading?.readTimestampLocal, reading?.billingPeriodStart, reading?.billingPeriodEnd),
                            billingPeriodStart: reading.billingPeriodStart
                        };
                    }

                    if (isNaN(totalReadingValues[reading.readTimestampLocal.ts]))
                    {
                        totalReadingValues[reading.readTimestampLocal.ts] = 0;
                    }

                    if (newSeries[reading.readTimestampLocal.ts][reading.timeOfUseName] === undefined) {
                        newSeries[reading.readTimestampLocal.ts][reading.timeOfUseName] = 0;
                    }

                    let totalReadingValue = 0;

                    if (reading.showOnUsageChart){
                        totalReadingValues[reading.readTimestampLocal.ts] += reading.readingValue;
                        totalReadingValue = totalReadingValues[reading.readTimestampLocal.ts];
                        newSeries[reading.readTimestampLocal.ts]["totalUsage"] = totalReadingValue;
                    }
                    newSeries[reading.readTimestampLocal.ts]["uom"] = uomResult.readings[0].uom;
                });

                newSeries = Object.keys(newSeries).sort().map(key => {
                    return newSeries[key];
                });

                // Order by timestamp
                newSeries.sort((a, b) => {
                    return b.tsLocal - a.tsLocal;
                });

                newObjectSeries[key] = newSeries;
                // Reset this array
                totalReadingValues = [];
            });

            setSeries(newObjectSeries);

            if (readingData?.readings_gal?.readingStats?.latestDateAvailable) {
                if (readingData?.readings_kWh?.readingStats?.latestDateAvailable >= readingData?.readings_gal?.readingStats?.latestDateAvailable && readingData?.readings_kWh?.readingStats?.latestDateAvailable) {
                    setLatestDateAvailable(readingData.readings_kWh.readingStats.latestDateAvailable);
                }
                else {
                    setLatestDateAvailable(readingData.readings_gal.readingStats.latestDateAvailable);
                }
            }
            else if (readingData?.readings_kWh?.readingStats?.latestDateAvailable) {
                setLatestDateAvailable(readingData.readings_kWh.readingStats.latestDateAvailable);
            }
        }
    }, [hasError, isFetching, readingData, todaysMonth, t]);

    let availableMonths = 3;
    if (Object.keys(series).length !== 0) {
        availableMonths = Math.max(isNaN(series.readings_kWh?.length) ? 0 : series.readings_kWh.length, isNaN(series.readings_gal?.length) ? 0 : series.readings_gal.length);
    }
    if (availableMonths > 3) {
        availableMonths = 3; //max out at only showing 3 months
    }

    let maxGraphDomainKwh = 0;
    let maxGraphDomainGal = 0;

    if (series !== null) {
        series.readings_kWh?.forEach((value, index) => {
            if ((value.totalUsage > maxGraphDomainKwh) && (index < availableMonths)) {
                maxGraphDomainKwh = value.totalUsage;
            }
        });

        series.readings_gal?.forEach((value, index) => {
            if ((value.totalUsage > maxGraphDomainGal) && (index < availableMonths)) {
                maxGraphDomainGal = value.totalUsage;
            }
        });
    }

    const normalise = (value, uom) => {
        if (value >= 0) {
            return (value * 100) / (uom.toLowerCase() === "kwh" ? maxGraphDomainKwh : maxGraphDomainGal);
        }
        else {
            return 0;
        }
    };

    function LinearProgressWithLabel(props) {
        const uomMapping = orgAppSettings?.result?.uomMappings[props.uom.toUpperCase()];

        let labelText = props.value === "N/A" ?
            t('No data ({{uomText}})', { uomText: uomMapping?.safeUomDisplay })
            : props.value?.toFixed(2) + " " + uomMapping?.safeUomDisplay;
    
        return (
            <div class="container"
                data-cy-id="usageSummaryWidget_innerProgressBox"
                title={labelText}
            >
                <Box sx={{ display: 'flex', alignItems: 'center', mb: !props.hasOneUom && "10px" }} className="usageSummaryWidget__innerProgressBox">
                    <Box sx={{ width: '100%', mr: 1, color: `${props.color}` }}>
                        <LinearProgress
                            className="usageSummaryWidget__linearProgressBar"
                            variant="determinate"
                            color='inherit'
                            colorSecondary='inherit'
                            sx={{
                                height: "30px",
                                borderRadius: "4px",
                            }}
                            value={normalise(props.value, uomMapping?.safeUomDisplay)}
                        />
                    </Box>
                    <Box className="usageSummaryWidget__linearProgressBarLabel">
                        <Typography variant="body2" color="white">
                            {uomMapping?.originalUom?.toLowerCase() === "gal" ?
                                <WaterDrop sx={{ mt: "5px", height: "20px" }} />
                                : <ElectricBolt sx={{ mt: "5px", height: "20px" }} />}
                            &nbsp;&nbsp;&nbsp;
                        </Typography>                    
                        <Typography variant="body2" color="white">
                            {labelText}
                        </Typography>
                    </Box>
                </Box>
            </div>
        );
    }

    const renderBarChart = () => {
        let barData = [];
        
        for (let i = 0; i < availableMonths; i++) {          
            let seriesType = null;

            if (series?.readings_kWh && series?.readings_kWh[i]) {
                seriesType = series.readings_kWh[i];
            }
            else if (series?.readings_gal && series?.readings_gal[i]) {
                seriesType = series.readings_gal[i];
            }

            let hasElectricMeter = series?.readings_kWh !== undefined;
            let hasWaterMeter = series?.readings_gal !== undefined;           
            let hasOneUom = hasElectricMeter ^ hasWaterMeter;

            const avgHighValue = weatherData[DateTime.fromJSDate(new Date(seriesType?.tsLocal)).toFormat('yyyy-LL')]?.avgHigh;
            const data =
                {
                    name: seriesType.name,
                    month: seriesType.name.substring(0, seriesType.name.indexOf(" ")),
                    monthType: seriesType.monthType,
                    billingPeriodStart: seriesType.billingPeriodStart,
                    totalUsageKwh: ((hasElectricMeter && series?.readings_kWh[i] !== undefined) ? series.readings_kWh[i]?.totalUsage : "N/A"),
                    totalUsageGal: ((hasWaterMeter && series?.readings_gal[i] !== undefined) ? series.readings_gal[i]?.totalUsage : "N/A"),
                    avgHigh: avgHighValue || 0
                };

            barData.push(
                <Grid container spacing={1} marginLeft='10px' marginBottom='8px'>
                    <Grid item xs={12}>
                        <Typography title={ data.name }>
                            {data.monthType + ": "}
                            {data.name}
                            {(data.monthType === "Current" && data.billingPeriodStart) &&
                                (ensureLuxonDate(latestDateAvailable) < ensureLuxonDate(data.billingPeriodStart) ?
                                    (ensureLuxonDate(new Date()).toFormat("MMMM d, yyyy"))
                                :
                                    (ensureLuxonDate(latestDateAvailable, true).toFormat("MMMM d, yyyy"))
                                )
                            }
                        </Typography>
                    </Grid>
                    <Grid item xs={3} sm={3} md={4} lg={2.5} sx={{ display: 'block' }} id={"average_high_temperature_box_"+i} title={data.avgHigh?.toFixed(0) + String.fromCharCode(176) + orgTempUnit + " - Average High"}>
                        <Box sx={{
                            maxWidth: isMobile ? 110 : 170,
                            height: 70,
                            backgroundColor: (TemperatureUtils.determineColor(avgHighValue, orgTempUnit)),
                            borderRadius: "5px"
                        }}
                        >                           
                            <Grid container
                                direction="row"
                                justifyContent="center"
                                alignItems="center"
                                paddingTop="7px"
                            >
                                <ThermostatIcon style={{ fill: "white", width: '25%', height: '25%', marginRight: (isMobile ? 2 : 5), marginTop:2 }} />
                                <Typography color='white' 
                                    fontSize={(isMobile ? "20px" : "22px")}>
                                    { avgHighValue !== undefined ? (
                                        data.avgHigh?.toFixed(0) + String.fromCharCode(176) + orgTempUnit
                                    ) : (
                                        t('N/A')
                                    )}
                                </Typography>
                            </Grid>
                            <Typography color="white" fontSize={isMobile ? "11px" : "13px" } textAlign="center" justifyContent="center">
                                { avgHighValue !== undefined ? (
                                    t('Average High')
                                ) : (
                                    t('Unavailable')
                                )}
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={8} sm={8} md={7} lg={8.5}>
                        <ResponsiveContainer height={'auto'} fullWidth>
                            <Box sx={{ width: '100%',  mt: hasOneUom && '17px' }}>
                                {hasElectricMeter && (
                                    <LinearProgressWithLabel
                                        hasOneUom={hasOneUom}
                                        value={data.totalUsageKwh}
                                        color={barColors.kWh}
                                        uom={"kWh"}
                                    />
                                )}
                                {hasWaterMeter && (
                                    <LinearProgressWithLabel
                                        hasOneUom={hasOneUom}
                                        value={data.totalUsageGal}
                                        color={barColors.gal}
                                        uom={"gal"}
                                    />
                                )}
                            </Box>
                        </ResponsiveContainer>
                    </Grid>
                </Grid>
            )
        }
        return barData;
    };

    React.useEffect(() => {
        if (account) {
            setHasError(false);
        }
    }, [account]);

    return (
        <UcWidget
            actionItems={actionItems}
            overflowX='hidden'
            overflowY='hidden'
            title={t('3-Month Summary')}
            alignTitleLeft={true}
        >
            <div className="dataCyId" data-cy-id="3_month_summary_widget" />
            <UcLoading
                hasFade={false}
                hasError={hasError}
                isLoading={isFetching || swrWeatherData.isLoading}
                height={'20vh'}
                loadingRender={<UcProgress {...progress} />}
            >
                { Object.keys(series).length !== 0 && (
                     renderBarChart()
                )}

                {availableMonths < 3 && (
                    <Alert severity="warning"> {t("Usage data is missing for {{missingData}} month(s).", { missingData: 3 - availableMonths })} </Alert>
                )}
            </UcLoading>
            {account?.id !== null && (
                <DashboardStatWrapper
                    readyForData={!hasError}
                    onDataCompleted={(newData) => {
                        setIsFetching(false);
                        setReadingData(newData);
                        setHasError(false);
                    }}
                    onProgress={(progressLocal) => {
                        if (progressLocal.current !== progress.current) {
                            setProgress({ ...progressLocal });
                        }
                    }}
                    onReset={() => {
                        setProgress({ current: 0, total: 100 });
                        setIsFetching(true);
                        setHasError(false);
                    }}
                    onError={(error) => {
                        console.error(error);
                        setIsFetching(false);
                        setHasError(true);
                    }}
                />
            )}
        </UcWidget>
    );
}