import { Box } from '@mui/material';
// @ts-ignore
import { useFilterItems } from '../../lib/useFilterItems';
import { UcLoading } from './UcLoading';
// @ts-ignore
import UcNotificationDataItemCards from './UcNotificationDataItemCards';
import { useTheme } from '@mui/material/styles';
import './UcNotificationWidgetInfiniteScroll.scss';
import { UcWidget } from './UcWidget';
import { useLayoutEffect, useState, useRef, useEffect } from 'react';
import { mutateUrls } from '../../lib/useGenericSWR';
import { useSWRConfig } from 'swr';

export interface UcNotificationWidgetInfiniteScrollProps {
    defaultDataItems: any;
    apiEndPoint: string;
    filter: any;
    clickable?: boolean;
    onRowSelectionModelChange?: any;
    contentPadding: string;
    pageSize: number;
    title: string;
    minHeight: string;
}

export const UcNotificationWidgetInfiniteScroll = (props: UcNotificationWidgetInfiniteScrollProps) => {
    const theme = useTheme();
    const { apiEndPoint, contentPadding, defaultDataItems } = props;

    const pageSize = props.pageSize;

    const needsDataUpdate = useRef("");
    const isDataLoaded = useRef(false);
    const [loading, setLoading] = useState(false); // Loading state

    const [dataItems, setDataItems] = useState(defaultDataItems);
    const [page, setPage] = useState(1);
    const [hasMore, sethasMore] = useState(true);
    const observerRef = useRef(null); // Ref for observer

    const [reMutateUrls, setReMutateUrls] = useState(false);
    const swrConfig = useSWRConfig();

    const [filter, setFilter] = useState(props.filter);

    const swrFilteredItems = useFilterItems(apiEndPoint, filter);

    useLayoutEffect(() => {

        const dataReceived = (swrResult: any) => {
            let newItems = swrResult.result;
            setDataItems({...props.defaultDataItems, rows: [...dataItems.rows, ...newItems], rowCount: swrResult.rowCount});
            if (newItems.length === 0 || newItems.length < pageSize) {
                sethasMore(false);
            }
        };

        setLoading(true);
        if (swrFilteredItems.result) {
            isDataLoaded.current = true;
            let filterString = JSON.stringify(filter);
            if (needsDataUpdate.current !== filterString) {
                needsDataUpdate.current = filterString;
                dataReceived(swrFilteredItems);
            }
            setLoading(false);
        }
    }, [swrFilteredItems, filter, dataItems.rows, props.defaultDataItems, pageSize]);

    useLayoutEffect(() => {
        if (reMutateUrls) {
            mutateUrls(swrConfig);
            setReMutateUrls(false);
        }
    }, [reMutateUrls, swrConfig]);

    useEffect(() => {

        const fetchData = () => {
            let newPage = page + 1;
            setPage(newPage);

            let newItemAmount = newPage * pageSize - pageSize;
            let newFilter = {...filter, skip: newItemAmount, take: pageSize}
            setFilter(newFilter);
        };

        if (loading || !hasMore) return; 

        const observer = new IntersectionObserver(
        (entries) => {
            if (entries[0].isIntersecting) { //check if the viewport intersects the obeserverRef div
            fetchData();
            }
        },
        { threshold: 0.5 } 
        );

        if (observerRef.current) {
            observer.observe(observerRef.current); 
        }

        return () => observer.disconnect(); 
    }, [page, loading, hasMore, filter, pageSize]);

    const onChange = () => {
        setReMutateUrls(true);
    }

    return (
        <UcWidget {...props}>
            <div className="dataCyId" data-cy-id="widget_infinite_scroll" />
            <Box className='ucNotificationWidgetInfiniteScroll'
                style={{
                    width: '100%',
                    padding: (contentPadding === undefined ? '1rem' : contentPadding),
                    borderRadius : '4px',
                    border : "4px solid transparent"
                }}
            >
                <UcNotificationDataItemCards
                    loading={!isDataLoaded.current}
                    dataItems={dataItems}
                    backgroundPalette={theme.palette.secondary.light}
                    hideBody={true}
                    onChange={onChange}
                />
                {loading && 
                    <Box sx={{pt: 30}}>
                        <UcLoading isLoading={hasMore} hasFade={false} height={'10px'} />
                    </Box>
                }
                {hasMore && (
                    <Box sx={{pt: 20}}>
                        <div ref={observerRef} style={{ height: '20px'}}/>
                    </Box>
                )}
            </Box>
        </UcWidget>
    );
};