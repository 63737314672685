import { ApiClient } from "../../lib/ApiClient";
import { useGenericSWR } from "../../lib/useGenericSWR";
import { ensureLuxonDate } from "lib/Formatters";
import React from "react";

const apiEndpoint = 'api/v1/MyReading';
const accountApiEndpoint = 'api/v1/MyReading/AccountReading';
const apiAdminEndpoint = 'api/v1/admin/Reading';
const apiEndpointGB = 'api/v1/MyReading/ReadingsGB'

// See definition from UdmReadings in UdmObjects.cs
const apiClient = new ApiClient();
apiClient.getDefaultItem = () => {
    // returning empty object
};
apiClient.getObjectName = () => {
    return "Reading";
};
apiClient.customDataHandler = (data, error, isValidating) => {
    if (data?.mainPeriod && data?.comparePeriod) {
        let results = [];

        results.push({
            startDate: data.mainPeriod.startTimeUtc,
            endDate: data.mainPeriod.endDateUtc,
            result: data.mainPeriod.readings ? data.mainPeriod.readings : null,
            groupings: data.mainPeriod.groupings ? data.mainPeriod.groupings : null,
            rateRules: data.mainPeriod.rateRules ? data.mainPeriod.rateRules : null,
            rowCount: data.mainPeriod.result ? data.mainPeriod.result.totalCount : 0,
            totalCount: data.mainPeriod.result ? data.mainPeriod.result.totalCount : 0,
            skip: data.mainPeriod.result ? data.mainPeriod.result.skip : 0,
            take: data.mainPeriod.result ? data.mainPeriod.result.take : 0
        });

        results.push({
            startDate: data.comparePeriod.startTimeUtc,
            endDate: data.comparePeriod.endDateUtc,
            result: data.comparePeriod.readings ? data.comparePeriod.readings : null,
            groupings: data.comparePeriod.groupings ? data.comparePeriod.groupings : null,
            rateRules: data.comparePeriod.rateRules ? data.comparePeriod.rateRules : null,
            rowCount: data.comparePeriod.result ? data.comparePeriod.result.totalCount : 0,
            totalCount: data.comparePeriod.result ? data.comparePeriod.result.totalCount : 0,
            skip: data.comparePeriod.result ? data.comparePeriod.result.skip : 0,
            take: data.comparePeriod.result ? data.comparePeriod.result.take : 0
        });

        return {
            result: results,
            isLoading: !error && results.length === 0,
            isError: !(!error),
            error: error,
            isValidating: isValidating,
        };
    }
    else {
        return {
            result: data?.result || data,
            groupings: data ? data.groupings : null,
            rowCount: data ? data.totalCount : 0,
            totalCount: data ? data.totalCount : 0,
            skip: data ? data.skip : 0,
            take: data ? data.take : 0,
            isLoading: !error && !data,
            isError: !(!error),
            error: error,
            isValidating: isValidating,
        };
    }
}

const useReadings = (
    startDateTime, 
    endDateTime, 
    groupBy, 
    uom, 
    accountId = null, 
    serialNo = null, 
    isAdmin = false, 
    swrOptions = { doToast: false }) => {
    // SWR will prevent duplicate requests
    const params = new URLSearchParams();
    let endpoint = apiEndpoint;
    if (isAdmin) {
        endpoint = apiAdminEndpoint;
    }

    params.append('accountId', accountId);
    params.append('serialNo', serialNo);
    params.append('startTimeUTC', ensureLuxonDate(startDateTime).toUTC().toISO());
    params.append('endTimeUTC', ensureLuxonDate(endDateTime).toUTC().toISO());
    params.append('groupBy', groupBy);
    params.append('uom', uom);

    let url = endpoint + "?" + params.toString();
    if (accountId == null || serialNo === null || serialNo === "All") {
        url = null;
    }

    return useGenericSWR(url, apiClient, { ...swrOptions });
};

const useAccountReadings = (
    startDateTime, 
    endDateTime, 
    groupBy, 
    uom, 
    accountId = null, 
    startDateCompare = null, 
    endDateCompare = null,
    isAdmin = false,
    swrOptions = {}, 
    isReady = true, 
    currentRateClassId = null,
    comparedRateClassId = null,
    serialNo = null) => {

    // SWR will prevent duplicate requests
    const params = new URLSearchParams();

    params.append('accountId', accountId);
    params.append('startTimeUTC', ensureLuxonDate(startDateTime)?.toUTC().toISO());
    params.append('endTimeUTC', ensureLuxonDate(endDateTime)?.toUTC().toISO());
    params.append('groupBy', groupBy);
    params.append('uom', uom);

    if (currentRateClassId !== null) {
        params.append('currentRateClassId', currentRateClassId);
    }

    if (comparedRateClassId !== null) {
        params.append('comparedRateClassId', comparedRateClassId);
    }

    if (startDateCompare !== null && endDateCompare !== null) {
        params.append('startTimeCompareUTC', ensureLuxonDate(startDateCompare).toUTC().toISO());
        params.append('endTimeCompareUTC', ensureLuxonDate(endDateCompare).toUTC().toISO());
    }


    let url = accountApiEndpoint + "?" + params.toString();
    if (!isReady || accountId === null || (serialNo !== null && serialNo !== "All"))
    {
        url = null;
    }

    return useGenericSWR(url, apiClient, { ...swrOptions });
};

const AccountReadingsWrapperDepricated = ({ setState, setFetchingState, startDateTime, endDateTime, groupBy, uom, accountId }) => {
    const data = useAccountReadings(startDateTime, endDateTime, groupBy, uom, accountId);
    React.useEffect(() => {
        if (!data.isLoading) {
            setState(data);
            setFetchingState(false);
        }
    }, [setState, setFetchingState, data]);

    return null;
};

const useReadingsGB = (startDateTime, endDateTime, groupBy, uom, accountId, isDownloadReady, swrOptions = {}) => {
    // SWR will prevent duplicate requests
    const params = new URLSearchParams();

    params.append('accountId', accountId);
    params.append('startTimeUTC', ensureLuxonDate(startDateTime).toUTC().toISO());
    params.append('endTimeUTC', ensureLuxonDate(endDateTime).toUTC().toISO());
    params.append('groupBy', groupBy);
    params.append('uom', uom);

    let url = apiEndpointGB + "?" + params.toString();
    if (!isDownloadReady) {
        url = "";
    }

    return useGenericSWR(url, apiClient, { ...swrOptions });
};

export const ReadingData = (props) => {
    const { startDate, endDate, startDateCompare, endDateCompare, groupBy, accountId, setDataState, setFetchingState } = props;
    // TODO: Get the uom from a service type selection - like the usage page
    const data = useAccountReadings(startDate, endDate, groupBy, 'all', accountId, startDateCompare, endDateCompare);

    React.useEffect(() => {
        if (!data.isLoading)
        {
            setDataState(data);
            if (setFetchingState !== undefined)
            {
                setFetchingState(false);
            }
        }
    }, [setDataState, setFetchingState, data]);

    return null;
}

export {
    useReadings,
    useAccountReadings,
    AccountReadingsWrapperDepricated,
    useReadingsGB
};