import React, { useState, useContext, useEffect, useLayoutEffect } from 'react';
import { PageContext } from '../../../lib/PageProvider';
import { MeterRateGridStateful } from "./MeterRateGridStateful"
import { MeterRateFormDialog } from './MeterRateFormDialog';
import { mutateUrls } from "../../../lib/useGenericSWR";
import { useSWRConfig } from "swr";
import { EnsureUser } from '../../auth/EnsureUser';
import { UcToolbar } from '../../common/UcToolbar';
import { useHasPermission } from 'components/auth/PermissionApiEndpoint';
import AddIcon from '@mui/icons-material/Add';
import { getMeterRatesDefaultItem, meterRateName, meterRatesName } from './MeterRateAPIEndpoints';
import { useTranslation } from 'react-i18next';

export const MeterRateList = (props) => {
    const { t } = useTranslation();
    const { userId, accountMeterId, accountId, rateClassId, isFromRateClassPath } = props;

    const [editDialog, setEditDialog] = useState({ open: false, delete: false });
    const [selectedItem, setSelectedItem] = useState(null);
    const [pageState, pageDispatch] = useContext(PageContext);
    const [generalSearch, setGeneralSearch] = useState('');

    const [reMutateUrls, setReMutateUrls] = useState(false);
    const swrConfig = useSWRConfig();

    const closeHandler = () => {
        setEditDialog({ open: false, itemToEdit: editDialog.dataItem });
    }

    const saveHandler = () => {
        closeHandler();
        setReMutateUrls(true);
    }

    const openDialog = (dataItem) => {
        if (!dataItem) {
            return;
        }
        if (!dataItem.formIteration) {
            dataItem.formIteration = "edit" + dataItem.id;
        }
        setEditDialog({ open: true, itemToEdit: dataItem, delete: false });
    }

    const createItem = () => {
        openDialog(getMeterRatesDefaultItem(accountMeterId));
    }

    const editItem = (item) => {
        openDialog(item);
    }

    const actions = [
        {
            icon: <AddIcon />,
            title: t('Add {{meterRateName}}', {meterRateName: meterRateName}),
            toolTip: t('Add {{meterRateName}}', {meterRateName: meterRateName}),
            onClick: () => { createItem(); },
            isHidden: !useHasPermission("MeterRate:Modify"),
        }
    ];

    useLayoutEffect(() => {
        if (reMutateUrls) {
            mutateUrls(swrConfig);
            setReMutateUrls(false);
        }
    }, [reMutateUrls, swrConfig]);

    useEffect(() => {
        pageDispatch({
            header: meterRatesName,
        });
    }, [pageState.header, selectedItem, pageDispatch]);

    console.log("MeterRateList - Loaded");
    return (
        <div className="meterRateListPage">
            <EnsureUser permissions={["MeterRate:Read"]}>
                    <UcToolbar
                        hasGeneralSearch={true}
                        onGeneralSearchChange={(value) => {
                            setGeneralSearch(value);
                        } }
                        actions={actions}></UcToolbar>
                <MeterRateFormDialog
                    userId={userId}
                    accountId={accountId}
                    rateClassId={rateClassId}
                    open={editDialog.open}
                    delete={editDialog.delete}
                    isFromRateClassPath={isFromRateClassPath}
                    itemToEdit={editDialog.itemToEdit}
                    onCancel={closeHandler}
                    onSave={saveHandler}
                />
                {!reMutateUrls &&
                    <MeterRateGridStateful
                        userId={userId}
                        accountMeterId={accountMeterId}
                        accountId={accountId}
                        rateClassId={rateClassId}
                        isFromRateClassPath={isFromRateClassPath}
                        generalSearch={generalSearch}
                        onEditItem={editItem}
                        onSelected={(selectedRateItem) => {
                            setSelectedItem({ ...selectedRateItem });
                        }} />
                }

            </EnsureUser>
        </div>
    );
};