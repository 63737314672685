import React, { useContext, useEffect } from 'react';
import { UcAdminDetails } from "../../common/UcAdminDetails";
import { PageContext } from '../../../lib/PageProvider';
import { EnsureUser } from '../../auth/EnsureUser';
import { useHasPermission } from 'components/auth/PermissionApiEndpoint';
import { MeterRateList } from 'components/admin/Customers/MeterRateList';
import { MeterCycleListPage } from 'components/admin/Billings/MeterCycleListPage';
import { UserAccountList } from 'components/admin/Users/UserAccountList';
import { useTranslation } from 'react-i18next';
import { MeterList } from './MeterList';

export const AccountDetailsPage = (props) => {
    let accountId = props.accountId;
    const { t } = useTranslation();
    const [pageState, pageDispatch] = useContext(PageContext);
    useEffect(() => {
        pageDispatch({
            header: t("Account Details") + t("Required"),
            actionItems: [],
        });
    }, [pageState.header, pageDispatch, t]);

    let detailsRender = <></>;
    let tabs = [
        {
            title: t("Users"),
            contentRender: <UserAccountList accountId={accountId} />,
            isHidden: !useHasPermission("User:Read")
        }, 
        {
            title: t("Meters"),
            contentRender: <MeterList accountId={accountId} />,
            isHidden: !useHasPermission("Meter:Read")
        }, 
        {
            title: t("Meter Rates"),
            contentRender: <MeterRateList accountId={accountId} />,
            isHidden: !useHasPermission("MeterRate:Read")
        },
        {
            title: t("Meter Billing Cycles"),
            contentRender: <MeterCycleListPage accountId={accountId} />,
            isHidden: !useHasPermission("MeterCycle:Read")
        }
    ];

    return (
        <EnsureUser permissions={["Account:Read"]}>
            <UcAdminDetails detailsRender={detailsRender} tabs={tabs} tabInitValue={t("Meters")} />
        </EnsureUser>
    );
};

AccountDetailsPage.defaultProps = {
    showDetails: true,
};