import React from 'react';
import { Route, Routes, Navigate, useLocation } from 'react-router-dom';
import { Layout } from './components/Layout';
import { LayoutLanding } from './components/LayoutLanding';
import { LayoutAuthorization } from './components/LayoutAuthorization';
import { ToastContainer } from 'material-react-toastify';
import 'material-react-toastify/dist/ReactToastify.css';
import { routeDetails, routePaths } from './routes';
import './App.scss';
import './Rechart.scss'
import { useAuthUser } from 'components/auth/useAuthUser';
import { UcLoading } from 'components/common/UcLoading';
import { useLocalStorage } from '@mantine/hooks';
import { useTranslation } from 'react-i18next';
import { setGlobalTranslator } from 'lib/GlobalTranslator';
import ErrorBoundary from 'ErrorBoundary';
import { getUrl } from 'domain';
import { cleanOidcStorage } from 'localStorageCleaner';
import { appendDomainToUrlStr, domain } from './domain';
import { LicenseInfo } from '@mui/x-license-pro';
import { UserInfoContext } from './lib/UserInfoProvider';
import { OrgAppSettingsContext } from './lib/OrgAppSettingsProvider';
import { Helmet } from "react-helmet";
import { NotFoundPage } from './NotFoundPage';
import { SilentRenew } from './components/auth/SilentRenew';
import { SignInOidc } from './components/auth/SignInOidc';
import { UnableToLoginPage } from './UnableToLoginPage';
import { UserUtilitiesContext } from './lib/UserUtilitiesProvider';

LicenseInfo.setLicenseKey(process.env.REACT_APP_MUI_LICENSE);

export const App = () => {
    cleanOidcStorage();
    const { t } = useTranslation();
    setGlobalTranslator(t);
    const auth = useAuthUser();
    const location = useLocation();
    const { orgAppSettings } = React.useContext(OrgAppSettingsContext);
    const { clockSettings } = React.useContext(UserUtilitiesContext);
    const { userInfo } = React.useContext(UserInfoContext);
    const [lastSignInOut] = useLocalStorage({ key: 'lastSignInOut', defaultValue: null });

    const browserClockOffset = clockSettings?.result?.clockOffset;
    const currentClockSkewMinutes = clockSettings?.result?.currentClockSkewMinutes;

    if (userInfo.isLoggedIn && lastSignInOut === "SignOut") {
        window.accessToken = ""; // this will force a 401 and clear to the login screen on next load
    }

    const currentRoutePath = location.pathname;
    const [isReady, setIsReady] = React.useState(false);

    if (isReady) {
        if (auth.isLoggedIn && !auth.emailVerified) {
            window.location = appendDomainToUrlStr(`${process.env.REACT_APP_UCAUTH_AUTHORITY}/account/error/confirmEmail`);
        }
    }

    const getRedirectPathElement = (route) => {
        if (!auth.isLoading && !auth.isLoggedIn && !window.isFirstAccess
            && (route.path !== routePaths.landingPage.path
                && route.path !== routePaths.faqPage.path
                && !currentRoutePath.includes('accountAuthorization')
            )) {
            return <Navigate replace to={getUrl(routePaths.landingPage.path)} />;
        }

        return route.element;
    }

    const routesFragment = () => {
        return <ErrorBoundary>
            <Routes>
                <Route path="/signin-oidc" element={<SignInOidc />} />
                <Route path="/silent-renew" element={<SilentRenew />} />
                {routeDetails.map((route) => (
                    <React.Fragment key={route.name} >
                        {route.path &&
                            <Route
                                path={route.path}
                                element={
                                    getRedirectPathElement(route)
                                }
                            />
                        }
                    </React.Fragment>
                ))}
            </Routes>
        </ErrorBoundary>;
    }

    if (!isReady && !auth.isLoading && !window.isFirstAccess) {
        setIsReady(true);
    }

    return (
        <>
            {!orgAppSettings.isLoading && orgAppSettings?.result !== undefined && (
                <Helmet>
                    <meta charSet="utf-8" />
                    <link rel="icon" href="/favicon.ico" />
                    <meta name="description" content={`${orgAppSettings.result.description}`} />
                    <title>{`${orgAppSettings.result.title}`}</title>
                </Helmet>
            )}
            <main>
                <ErrorBoundary>
                    {isReady &&
                        <>
                            {domain.subDomain !== '' ? (
                                <>
                                    {browserClockOffset >= currentClockSkewMinutes ? (
                                        <UnableToLoginPage />
                                    ) : (
                                            <>
                                                {(currentRoutePath === routePaths.landingPage.path || (!auth.isLoggedIn && currentRoutePath === routePaths.faqPage.path)) &&
                                                    <LayoutLanding key="landingLayout">
                                                        {routesFragment()}
                                                    </LayoutLanding>
                                                }
                                                {currentRoutePath.includes('accountAuthorization') && (
                                                    <LayoutAuthorization key="authorizationLayout">
                                                        {routesFragment()}
                                                    </LayoutAuthorization>
                                                )}
                                                {((currentRoutePath !== routePaths.landingPage.path && !currentRoutePath.includes('accountAuthorization') && currentRoutePath !== routePaths.faqPage.path)
                                                    || (currentRoutePath === routePaths.faqPage.path && auth.isLoggedIn)) &&
                                                    <>
                                                        <Layout key="appLayout" currentRoutePath={currentRoutePath}>
                                                            {routesFragment()}
                                                        </Layout>
                                                        <ToastContainer />
                                                    </>
                                                }
                                        </>
                                    )}
                                </>
                            ) : (
                                <NotFoundPage />
                            )}
                        </>
                    }
                    {!isReady &&
                        <UcLoading isLoading={true} />
                    }
                </ErrorBoundary>
            </main>
        </>
    );

}