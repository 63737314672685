///<summary>
/// enumFormatter
///
/// This function is used to format the enum values.
/// usage:
///     const enums = useEnums("UsageRateType");
///     const enumFormatted = enumFormatter(enums, value);
/// </summary>
export const enumFormatter = (enums, value) => {
    if (!enums) // Enums can be null if this is called before api/v1/permission
    {
        return value;
    }

    let result = enums?.find(x => x.id === value);
    if (result) {
        return result.display;
    }
    return value;
}