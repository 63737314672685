import { ApiClient } from "../../../lib/ApiClient";
import { useGenericSWR } from "../../../lib/useGenericSWR";
import * as Yup from 'yup';
import { mutateRateCharge } from './RateChargeApiEndpoints';

const rateTouRuleApiEndpoint = 'api/v1/admin/RateTouRule';
const rateTouRuleDefaultApiEndpoint = 'api/v1/admin/uc/RateTouRuleDefault';

let formIteration = 0;

const getRateTouRulesDefaultItem = (rateChargeId) => {
    formIteration = formIteration + 1;
    return {
        formIteration: "new" + formIteration,
        id: 0,
        rateChargeId: rateChargeId,
        name: '',
        description: '',
        rate: 1,
        htmlColor: null,
        dateRuleSet: {},
        createdBy: 'REACT_FE',
        updatedBy: 'REACT_FE',
        isDefault: false,
        exemptForHolidays: false
    }
};

const rateTouRulesValidationSchema = Yup.object().shape({
    rateChargeId: Yup.number()
        .required('Rate Charge is required')
        .min(1, 'Rate Charge is required'),
    name: Yup.string()
        .required("Required")
        .max(256, 'Max of 256 characters'),
    rate: Yup.number()
        .required('Rate is required')
        .min(0, 'Rate is required'),
});

const rateTouRulesName = "Time of Use Rules";
const rateTouRuleName = "Time of Use Rule";

// See definition from RateTouRule.cs
const apiClient = new ApiClient();
apiClient.getDefaultItem = () => getRateTouRulesDefaultItem();
apiClient.getObjectName = () => {
    return rateTouRulesName;
};

const useRateTouRule = (id, swrOptions = {}) => {
    // SWR will prevent duplicate requests
    // It will also maintain data state as long as your are using the same urls for your requests
    return useGenericSWR(rateTouRuleApiEndpoint + "/" + id, apiClient, { ...swrOptions });
};

const useRateTouRules = (filter, swrOptions = {}) => {
    // SWR will prevent duplicate requests
    // It will also maintain data state as long as your are using the same urls for your requests
    const params = new URLSearchParams();
    params.append('filter', JSON.stringify(filter));
    return useGenericSWR(rateTouRuleApiEndpoint + "?" + params.toString(), apiClient, { ...swrOptions });
};

const saveRateTouRule = (RateTouRule) => {
    if (RateTouRule.id === 0) {
        return insertRateTouRule(RateTouRule);
    }
    return updateRateTouRule(RateTouRule);
}
const insertRateTouRule = (RateTouRule) => {
    return apiClient.poster(rateTouRuleApiEndpoint, RateTouRule)
    .then((result) => {
        mutateRateCharge();
        return result;
    });
};

const updateRateTouRule = (RateTouRule) => {
    return apiClient.putter(rateTouRuleApiEndpoint, RateTouRule)
    .then((result) => {
        mutateRateCharge();
        return result;
    });
};

const deleteRateTouRule = (RateTouRule) => {
    return apiClient.deleter(rateTouRuleApiEndpoint + "/" + RateTouRule.id)
        .then((result) => {
            mutateRateCharge();
            return result;
        });
};

const setDefault = (RateTouRule) => {
    return apiClient.putter(rateTouRuleDefaultApiEndpoint, RateTouRule);
}

export {
    rateTouRuleName,
    rateTouRulesName,
    useRateTouRule,
    useRateTouRules,
    saveRateTouRule,
    insertRateTouRule,
    updateRateTouRule,
    deleteRateTouRule,
    setDefault,
    rateTouRulesValidationSchema,
    getRateTouRulesDefaultItem,
};