import React, { useState, useLayoutEffect } from 'react';
import { UserNotificationGridStateful } from "./UserNotificationGridStateful"
import { saveUserNotificationBulk } from "./UserNotificationAPIEndpoints"
import { mutateUrls } from "lib/useGenericSWR";
import { useSWRConfig } from "swr";
import MarkChatReadIcon from '@mui/icons-material/MarkChatRead';
import { EnsureUser } from 'components/auth/EnsureUser';
import { UcToolbar } from 'components/common/UcToolbar';
import { useTranslation } from "react-i18next";
import { useResponsive } from 'lib/useResponsive';
import { dateFormatter, booleanFormatter, markdownFormatter, removeMarkdown } from 'lib/Formatters';
import { UcIconButtonsWithLabel } from 'components/common/UcIconButtonsWithLabel';
import { useEnums } from 'components/auth/PermissionApiEndpoint';
import { enumFormatter } from 'lib/EnumFormatter';
import { getWholeRow } from 'lib/DataGridHelpers';
import LaunchIcon from '@mui/icons-material/Launch'
import { UcIconButtons } from 'components/common/UcIconButtons';
import UcDataItemCardDialog from '../common/UcDataItemCardDialog';
import Tooltip from '@mui/material/Tooltip';
import './UserNotificationList.scss';

export const UserNotificationList = (props) => {
    const { t } = useTranslation();
    const [selectedItems, setSelectedItems] = useState(null);
    const [reMutateUrls, setReMutateUrls] = useState(false);
    const swrConfig = useSWRConfig();
    const { isMobile } = useResponsive();
    const notificationTypeEnums = useEnums("NotificationType");
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [dialogContent, setDialogContent] = React.useState({ title: null, content: null });

    const handleAcknowledgment = async (dataItem) => {
        const singleSelectedItem = [{
            dataItem:dataItem,
            selectedId:dataItem.id,
        }];
        const finalSelectedItems = dataItem!==true ? singleSelectedItem : selectedItems;
        let data = await saveUserNotificationBulk(finalSelectedItems, true);

        if (data) {
            setReMutateUrls(true);
        }
    };

    const handleOpenDialog = (item) => {
        setDialogContent({
            title: item.title,
            content: markdownFormatter(item.content)
        })
        setAnchorEl(true);
    };

    const onCloseDialog = () => {
        setAnchorEl(null);
    }

    const actionsHandler = (itemValue, labelFlag) => {
        const actions = [
            {
                title: t('Mark as Read'),
                toolTip: t('Mark as Read'),
                icon: <MarkChatReadIcon />,
                color: 'primary',
                onClick: () => { handleAcknowledgment(itemValue); },
                isDisabled: (selectedItems?.length > 0 || !itemValue.isAcknowledged) ? false : true,
                label: t('Mark as Read'),
                labelSx: { display: 'inline-block', width:'100%', ml:1, textAlign:'justify' },
            },
        ];

        return (labelFlag ? (
            <UcIconButtonsWithLabel actions={actions} />
            ) : (
            <UcToolbar actions={actions}></UcToolbar>
            )
        );
    }

    let defaultDataItems = {
        columnVisibilityModel: {
            id: false,
            cepOrgId: false,
            createdBy: false,
            updatedBy: false,
            isSent: false,
            content: false
        },
        columns: [
            { field: 'id', headerName: t('Id'), cardHide: true},
            { field: 'title', headerName: t('Title'), flex: 1, minWidth: 100 },
            {
                field: 'content', headerName: t('Content'), flex: 1, minWidth: 250, cardColumnWidth: 12, valueFormatter: (params) => {
                    return isMobile ? markdownFormatter(params.value) : removeMarkdown(params.value)
                }              
            },
            { field: 'isSent', headerName: t('Is Sent'), width: 200, cardHide: true },
            { field: 'notificationType', headerName: t('Notification Type'), width: 200, valueFormatter: (params) => { return enumFormatter(notificationTypeEnums, params.value) } },
            { field: 'isAcknowledged', headerName: t('Status'), width: 200, cardColumnWidth: 12, valueFormatter: ( params ) => { return booleanFormatter(params.value, "Read", "Unread");}},
            { field: 'createdTs', headerName: t('Created'), width: 200, cardColumnWidth: 12, valueFormatter: (params) => { return dateFormatter(params.value); } },
            {
                field: 'dialog', headerName: t('Content'), width: 100, sortable: false,
                valueGetter: getWholeRow,
                renderCell: (params) => {
                    const actions = [
                        {
                            icon:
                                <Tooltip data-cy-id={"user_notification_dialog"} placement="bottom" title={t('View Notification Content')}>
                                    <LaunchIcon EditIcon data-cy-id={"notification_icon_" + params.row.header} />
                                </Tooltip>,
                            color: 'primary',
                            isDisabled: false,
                            onClick: () => { handleOpenDialog(params.value); },
                        }
                    ];

                    return (
                        <UcIconButtons actions={actions} />
                    );
                }
            },
        ],
        rows: [],
        rowCount: -1,

        cardHeaderField: {field: "title"},
    };

    useLayoutEffect(() => {
        if (reMutateUrls)
        {
            mutateUrls(swrConfig);
            setReMutateUrls(false);
        }
    }, [reMutateUrls, swrConfig]);

    return (
        <>
            <EnsureUser permissions={["UserNotification:Read"]}>
                <div className="userNotificationtList">
                    {!isMobile && actionsHandler(true, false)}
                    <UserNotificationGridStateful
                        defaultDataItems={defaultDataItems}
                        isFullPage={props.isFullPage || false}
                        actionsHandler={actionsHandler}
                        onSelected={(newSelectedItem) => setSelectedItems([...newSelectedItem])}
                    />
                </div>
            </EnsureUser>
            <UcDataItemCardDialog
                anchorEl={anchorEl}
                dialogContent={dialogContent}
                onCloseDialog={onCloseDialog}
            />
        </>      
    );
};