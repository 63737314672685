import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import GppMaybeIcon from "@mui/icons-material/GppMaybe";

export interface UserBillOptionsWidgetDialogProps {
    open: boolean;
    isSubscribed: boolean;
    handleClose: () => void;
    handleSuccess: () => void;
    handleError: () => void;
}

export default function UserBillOptionsWidgetDialog(props: UserBillOptionsWidgetDialogProps) {
    const { open, isSubscribed, handleClose, handleSuccess, handleError } = props;
    const { t } = useTranslation();

    const handleOnSubmit = (isSuccess: boolean) => {
        if (isSuccess) {
            handleSuccess();
        } else {
            handleError();
        }
    };

    return (
        <Dialog maxWidth={'sm'}
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogContent>
                <Box sx={{ textAlign: "center" }}>
                    <GppMaybeIcon sx={{ fontSize: 72 }} color="info" />
                </Box>
                <DialogContentText id="alert-dialog-description">
                    <Typography variant="h4" gutterBottom sx={{textAlign: 'center'}}>
                        {t("Are you sure?")}
                    </Typography>
                    <Typography variant="body1">
                        {isSubscribed
                            ? t(
                                  `Unsubscribing from Paperless billing mean you will return to receiving paper bills. Are you sure you want to unsubscribe?`
                              )
                            : t(`You are signing up for Paperless Billing, you will now only receive your bills through this portal. Do you want to subscribe?`)}
                    </Typography>
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={handleClose}
                    color="primary"
                    variant="outlined"
                >
                    {t("Cancel")}
                </Button>
                <Button
                    onClick={() => handleOnSubmit(false)}
                    autoFocus
                    color={isSubscribed ? "error" : "primary"}
                    variant="outlined"
                >
                    {isSubscribed
                        ? t("Unsubscribe (Error)")
                        : t("Subscribe (Error)")}
                </Button>
                <Button
                    data-cy-id={'userBillOptions_widget_paperlessBillingDialog_submit_btn'}
                    onClick={() => handleOnSubmit(true)}
                    autoFocus
                    color={isSubscribed ? "error" : "primary"}
                    variant="contained"
                >
                    {isSubscribed
                        ? t("Unsubscribe (Success)")
                        : t("Subscribe (Success)")}
                </Button>
            </DialogActions>
        </Dialog>
    );
}
