import { ApiClient } from "../../../lib/ApiClient";
import { useGenericSWR } from "../../../lib/useGenericSWR";
import * as Yup from 'yup';

export const apiEndpoint = 'api/v1/admin/RateCharge';

let formIteration = 0;

const getRateChargeDefaultItem = (rateVersionId) => {
    formIteration = formIteration + 1;
    return {
        formIteration: "new" + formIteration,
        id: 0,
        rateVersionId: rateVersionId,
        name: '',
        description: '',
        createdBy: 'REACT_FE',
        updatedBy: 'REACT_FE',
        ruleSet: { dateRanges: [] },
        type: 'TIMEOFUSE',
        rate: 0.01,
        htmlColor: null,
        basicHtmlColorsJson: []
    }
};

const rateChargeValidationSchema = Yup.object().shape({
    rateVersionId: Yup.number()
        .required('Rate Version is required')
        .min(1, 'Rate Version is required'),
    name: Yup.string()
        .required("Required")
        .max(256, 'Max of 256 characters'),
    rate: Yup.number()
        .when("type", {
            is: (value) => value === "FIXED" || value === " ",
            then: Yup.number()
                     .min(0.0000000001, "Rate must be greater than 0.")
                     .required()
        })     
});

const apiClient = new ApiClient();
apiClient.getDefaultItem = () => getRateChargeDefaultItem();
apiClient.getObjectName = () => {
    return 'Rate Charge';
};

const useRateCharge = (id, swrOptions = {}) => {
    return useGenericSWR(apiEndpoint + "/" + id, apiClient, { ...swrOptions });
};

const useRateCharges = (filter, swrOptions = {}) => {
    const params = new URLSearchParams();
    params.append('filter', JSON.stringify(filter));
    return useGenericSWR(apiEndpoint + "?" + params.toString(), apiClient, { ...swrOptions });
};

const saveRateCharge = (rateCharge) => {
    if (rateCharge.id === 0) {
        return insertRateCharge(rateCharge);
    }
    return updateRateCharge(rateCharge);
}
const insertRateCharge = (rateCharge) => {
    return apiClient.poster(apiEndpoint, rateCharge);
};

const updateRateCharge = (rateCharge) => {
    return apiClient.putter(apiEndpoint, rateCharge);
};

const deleteRateCharge = (rateCharge) => {
    return apiClient.deleter(apiEndpoint + "/" + rateCharge.id);
};

const mutateRateCharge = () => {
    apiClient.mutateFetchUrls();
}

export {
    useRateCharge,
    useRateCharges,
    saveRateCharge,
    insertRateCharge,
    updateRateCharge,
    deleteRateCharge,
    mutateRateCharge,
    rateChargeValidationSchema,
    getRateChargeDefaultItem,
};