import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { CSVLink } from 'react-csv';
import IconButton from '@mui/material/IconButton';
import { Download as DownloadIcon } from '@mui/icons-material'
import { useTranslation } from "react-i18next";
import Menu from '@mui/material/Menu';
import Divider from '@mui/material/Divider';
import LoadingButton from '@mui/lab/LoadingButton';
import { getDataKeyOriginal, getPrecipUnit } from '../../lib/Formatters';

export const UcButtonChartDownload = (props) => {
    const { t } = useTranslation();
    const { data, filename, palletColor, selectedMeter, uom, csvTempUom } = props;
    const csvLink = React.useRef();
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const handleCSVClick = () => {
        csvLink.current.link.click();
        setAnchorEl(null);
    }

    const handleXMLClick = () => {
        props.onXMLClick();
    }

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);

    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    useEffect(() => {
        if (!props.isXmlGenerating) {
            setAnchorEl(null);
        }
    }, [props.isXmlGenerating]);

    const modifiedData = data.map(item => {
        let result = {};
        result["Meter Number"] = selectedMeter;
        result["Interval"] = item.name;
        result[`Total Usage (${uom})`] = item.totalUsage?.toFixed(2);
        result["Total Cost ($)"] = item.Cost?.toFixed(2);
        result["Billing Period Start"] = item.billingPeriodStart;
        result["Billing Period End"] = item.billingPeriodEnd;
        result[`Avg. Temperature (${String.fromCharCode(176) + csvTempUom})`] = item.Temperature?.toFixed(1);
        result[`Precipitation (${getPrecipUnit(csvTempUom)})`] = item.Precip?.toFixed(2);

        for (let key in item) {
            if (/^[a-fA-F0-9]{10}$/.test(key)) {
                result[`${getDataKeyOriginal(key).trim()} - Rate ($)`] = item[`${key}_rate`]?.toFixed(2);
                result[`${getDataKeyOriginal(key).trim()} - Usage (${uom})`] = item[`${key}`]?.toFixed(2);
                result[`${getDataKeyOriginal(key).trim()} - Cost ($)`] = item[`${key}_cost`]?.toFixed(2);
            }
        }

        return result;
    });

    return (
        <>
            <IconButton
                sx={{ color: palletColor }}
                onClick={handleClick}
                title={t('Download')}
            >
                <DownloadIcon />
            </IconButton>
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
            >
                <LoadingButton loading={props.isXmlGenerating} onClick={handleCSVClick} style={{ display: 'inline-block', width: '100%', textAlign: 'justify' }} >
                    <span>
                        <img src="/csv-logo.svg" style={{ margin: '-5px 7px -5px 5px', filter: props.isXmlGenerating ? 'grayscale(1)' : null }}
                            alt="CSV Logo" width="35" height="35" /> CSV
                    </span>
                </LoadingButton>
                <Divider />
                <LoadingButton loading={props.isXmlGenerating} onClick={handleXMLClick} style={{ display: 'inline-block', width: '100%', textAlign: 'justify' }} >
                    <span>
                        <img src="/gba-logo.png" style={{ margin: '0 0 -13px -5px', filter: props.isXmlGenerating ? 'grayscale(1)' : null }}
                            alt="GreenButton Logo" width="55" height="40" />GreenButton (XML)
                    </span>
                </LoadingButton>
            </Menu>
            <CSVLink style={{ display: "none" }}
                data={modifiedData}
                filename={filename}
                ref={csvLink}
            ></CSVLink>

        </>
    )
}

UcButtonChartDownload.propTypes = {
    dataToDownload: PropTypes.array,
    filename: PropTypes.string.isRequired
};