// @ts-ignore
import React from 'react';
import { useTranslation } from 'react-i18next';
import { PageContext, PageState, PageAction } from '../../../lib/PageProvider';
import { BillApiHealthGrid } from './BillApiHealthGrid';
import EnsureUserAndModule from 'components/auth/EnsureUserAndModule';

export const BillApiHealthListPage = () => {
    const { t } = useTranslation();
    const [pageState, pageDispatch] = React.useContext<[PageState, React.Dispatch<PageAction>]>(PageContext);

    React.useEffect(() => {
        pageDispatch({
            header: t('Bill API Health'),
            actionItems: [] as any[],
            variableHeader: "",
            tabs: [],
            onTabChange: () => { },
            tabValue: ''
        });
    }, [pageState.header, pageDispatch, t]);

    console.log("BillApiHealthListPage - Loaded");
    return (
        <EnsureUserAndModule permissions={["Org:Modify"]} modules={['Billing']}>
            <BillApiHealthGrid />
        </EnsureUserAndModule>
    );
}