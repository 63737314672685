import React from 'react';
import { ApiClient } from "../../lib/ApiClient";
import { useGenericSWR } from "../../lib/useGenericSWR";
import * as Yup from 'yup';
import { mutateMyAccounts } from 'components/customer/accounts/MyAccountAPIEndpoints';
import { mutateMyUserAccounts } from 'components/user/MyUserAccountAPIEndpoints';

const apiEndpoint = 'api/v1/AccountAuthorization';
const apiClient = new ApiClient();
apiClient.requiresAuth = false;

const AccountVerificationStep1Schema = Yup.object().shape({
    accountNumber: Yup.string()
        .required("Required"),
    accountName: Yup.string()
        .required("Required")
});

const AccountVerificationStep2Schema = Yup.object().shape({
    method: Yup.string()
        .required("Required")
});

const AccountVerificationStep3Schema = Yup.object().shape({
    code: Yup.string()
        .required("Required")
});

const LookupValueValidationSchema = Yup.object().shape({
    lookupValue: Yup.string()
        .required("Required")
});

const LinkValidationSchema = Yup.object().shape({
    email: Yup.string()
        .required("Required")
});

const sendAccountNumber = (accountNumber, method) => {
    return apiClient.poster(`${apiEndpoint}/SendAccountNumber`, { accountNumber, method });
};

const sendVerificationCode = (accountNumber, accountName, method) => {
    return apiClient.poster(`${apiEndpoint}/SendVerificationCode`, {accountNumber, accountName: encodeURIComponent(accountName), method}, "", true, null, false);
};

const sendCheckVerificationCode = (accountNumber, accountName, code, method) => {
    return apiClient.poster(`${apiEndpoint}/SendCheckVerificationCode`, {accountNumber, accountName: encodeURIComponent(accountName), method, code}, "", false, null, false);
};

const ensureAuthorityUser = (email) => {
    return apiClient.poster(`${apiEndpoint}/EnsureAuthorityUser?email=${email}`, {}, "", false, null, false);
};

const linkAccount = (accountNumber, accountName, email, code) => {
    const data = apiClient.poster(`${apiEndpoint}/LinkAccount`, {accountNumber, accountName: encodeURIComponent(accountName), code, email: encodeURIComponent(email)}, "", false, null, false)
        .then((result) => {
            mutateMyAccounts();
            mutateMyUserAccounts();
            return result;
        });

    return data;
};

const useLookupAccount = (accountNumber, accountName, swrOptions = {}) => {
    const params = new URLSearchParams();
    params.append('accountNumber', accountNumber);
    params.append('accountName', accountName);

    return useGenericSWR(apiEndpoint + "/lookupAccount?" + params.toString(), apiClient, { ...swrOptions });
};

const useLookupAccounts = (lookupBy, lookupValue, swrOptions = {}) => {
    const params = new URLSearchParams();
    params.append('lookupBy', lookupBy);
    params.append('lookupValue', lookupValue);

    return useGenericSWR(apiEndpoint + "/lookupAccounts?" + params.toString(), apiClient, { ...swrOptions });
};

const SetDataState = ({ data, setState, setFetchingState, lookupBy, setShowAlert }) => {
    React.useEffect(() => {
        if (!data.isLoading) {
            setState(data);
            setFetchingState(false);

            if (setShowAlert !== undefined) {
                if (!data.result && lookupBy?.toLowerCase() === 'address') {
                    setShowAlert(true);
                }
                else {
                    setShowAlert(false);
                }
            }
        }
    }, [setState, setFetchingState, data, lookupBy, setShowAlert]);

    return null;
};

const LookupAccountWrapper = ({ setState, setFetchingState, accountNumber, accountName }) => {
    const data = useLookupAccount(accountNumber, accountName, { doToast: false, refreshInterval: 0 });
    
    return SetDataState({data, setState, setFetchingState});
};

const LookupAccountsWrapper = ({ setState, setFetchingState, lookupBy, lookupValue, setShowAlert }) => {
    const data = useLookupAccounts(lookupBy, lookupValue);

    return SetDataState({ data, setState, setFetchingState, lookupBy, setShowAlert });
};


export {    
    AccountVerificationStep1Schema,
    AccountVerificationStep2Schema,
    AccountVerificationStep3Schema,
    LookupValueValidationSchema,
    LinkValidationSchema,
    sendAccountNumber,
    sendVerificationCode,
    sendCheckVerificationCode,
    ensureAuthorityUser,
    linkAccount,
    useLookupAccount,
    useLookupAccounts,
    LookupAccountWrapper,
    LookupAccountsWrapper
};