import { ApiClient } from "../../../lib/ApiClient";
import { useGenericSWR } from "../../../lib/useGenericSWR";
import * as Yup from 'yup';

export const meterClassApiEndpoint = 'api/v1/MeterClass';

let formIteration = 0;

const getMeterClasssDefaultItem = () => {
    formIteration = formIteration + 1;
    return {
        formIteration: "new" + formIteration,
        id: 0,
    }
};

const meterClasssValidationSchema = Yup.object().shape({
    cepOrgId: Yup.number().required("Required"),
});

const apiClient = new ApiClient();
apiClient.getDefaultItem = () => getMeterClasssDefaultItem();
apiClient.getObjectName = () => {
    return "MeterClass";
};

const useMeterClass = (id, swrOptions = {}) => {
    // SWR will prevent duplicate requests
    // It will also maintain data state as long as your are using the same urls for your requests
    return useGenericSWR(meterClassApiEndpoint + "/" + id, apiClient, { ...swrOptions });
};

const useMeterClasss = (filter, swrOptions = {}) => {
    // SWR will prevent duplicate requests
    // It will also maintain data state as long as your are using the same urls for your requests
    const params = new URLSearchParams();
    if (filter)
    {
        params.append('filter', JSON.stringify(filter));
    }
    else {
        params.append('filter', '{}');
    }
    return useGenericSWR(meterClassApiEndpoint + "?" + params.toString(), apiClient, { ...swrOptions });
};

export {
    useMeterClass,
    useMeterClasss,
    meterClasssValidationSchema,
    getMeterClasssDefaultItem,
};