import React from "react";
import { UcLoading } from "../common/UcLoading";
import { SignInButton } from "./SignInButton";
import { usePermissions } from "./PermissionApiEndpoint";
import { useAuthUser } from './useAuthUser';
import './EnsureUser.scss';
import { Alert } from "@mui/material";
import { useMyTimezone } from "../customer/orgs/MyOrgAPIEndpoints";

export const EnsureUser = (props) => {
    const authUser = useAuthUser();
    const isFull = props.isFull || true;

    let isLoggedIn = false;
    if (!authUser.isLoading && authUser.isLoggedIn) {
        isLoggedIn = true;
    }

    const timezone = useMyTimezone();

    const isLoading = (authUser.isLoading && !window.isFirstAccess) || timezone === null;

    const permissionSwr = usePermissions();
    let allowedPermissions = [];
    let permissionsSet = false;
    if (permissionSwr.result && !permissionSwr.isLoading && !permissionSwr.isError) {
        permissionsSet = true;
        allowedPermissions = permissionSwr.result.permissions;
    }

    let className = (props.className || "") + " ensureUser";
    if (isFull) {
        className += " ensureUser__full"
    }

    const permissionsNeeded = props.permissions || [];
    let missingPermissions = [];
    permissionsNeeded.forEach(permission => {
        let allowedPermission = allowedPermissions?.find(allowed => allowed.permission === permission);
        if (!allowedPermission) {
            missingPermissions.push(permission);
        }
    });
    const hasPermission = missingPermissions.length <= 0;
    // if true it will display the missing permisison
    // if false it will hide the component
    let showPermissionError = props.showPermissionError;
    if (showPermissionError === undefined) {
        showPermissionError = true; //Default this to true
    }

    return (
        <>
            {hasPermission &&
                <UcLoading isLoading={isLoading} hasFade={false} >
                    <div className={className}>
                        {isLoggedIn && props.children}
                        {!isLoggedIn &&
                            <SignInButton />
                        }
                    </div>
                </UcLoading>
            }
            {!hasPermission && showPermissionError && permissionsSet && !isLoading &&
                <div>
                    <Alert severity="error">Missing the following permissions: {missingPermissions.join(", ")}</Alert>
                </div>
            }
        </>
    );
}