// @ts-ignore
import React, { useState, useLayoutEffect } from "react";
import { mutateUrls } from "../../../lib/useGenericSWR";
import { useSWRConfig } from "swr";
// @ts-ignore
import { EnsureUser } from "../../auth/EnsureUser";
import { useTranslation } from "react-i18next";
import { UcWidget } from "../../common/UcWidget";
// @ts-ignore
import { useResponsive } from 'lib/useResponsive';
// @ts-ignore
import { Gauge, gaugeClasses } from '@mui/x-charts/Gauge';
import { Alert, Button, Divider, Grid, Popover, Typography } from "@mui/material";
import { ActionItem } from "../../common/Common.interfaces";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
// @ts-ignore
import { routePaths } from "routes";
import { useDomainNavigate } from "../../../domain";
import { useCisSyncImportHeaders } from '../CisSync/CisSyncImportHeaderAPIEndpoints';
import { UcLoading } from "../../common/UcLoading";
// @ts-ignore
import { stringDateFormatter } from '../../../lib/Formatters';
import { green, red } from "@mui/material/colors";

export const AdminAccountSyncStatsWidget = () => {
    const { t } = useTranslation();
    const [reMutateUrls, setReMutateUrls] = useState(false);
    const swrConfig = useSWRConfig();
    const { isMobile } = useResponsive();
    const navigate = useDomainNavigate();
    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
    let hasAccountSyncStats = true;

    const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [filter, setFilter] = useState({
        take: 1,
        skip: 0
    });

    const swrCisSyncImportHeaders = useCisSyncImportHeaders(filter);

    let latestAccountSyncStatistics = swrCisSyncImportHeaders?.result && swrCisSyncImportHeaders?.result?.length > 0 ? swrCisSyncImportHeaders?.result[0] : null;
    const totalAccounts = latestAccountSyncStatistics?.addedAccountsCount + latestAccountSyncStatistics?.deactivatedAccountsCount;
    const netAccounts = latestAccountSyncStatistics?.addedAccountsCount - latestAccountSyncStatistics?.deactivatedAccountsCount;

    if (swrCisSyncImportHeaders?.result?.length < 1 && !swrCisSyncImportHeaders?.isLoading && !swrCisSyncImportHeaders?.isError) {
        hasAccountSyncStats = false;
    }

    const gaugeSettings = {
        width: 200,
        height: 150,
    };

    const handleClickGoToAccounts = () => {
        navigate(routePaths.AccountsPage.path);
    };

    const actionItems: ActionItem[] = [
        {
            component: (
                <Button
                    color={"info"}
                    onClick={handleClickGoToAccounts}
                    size={"small"}
                    endIcon={<ChevronRightIcon />}
                >
                    {t("View Accounts")}
                </Button>
            ),
        },
    ];

    useLayoutEffect(() => {
        if (reMutateUrls) {
            mutateUrls(swrConfig);
            setReMutateUrls(false);
        }
    }, [reMutateUrls, swrConfig]);

    console.log("AdminAccountSyncStatsWidget - Loaded");

    return (
        <UcWidget
            title={t("Account Sync Stats")}
            actionItems={actionItems}
            alignTitleLeft={false}
            contentPadding={isMobile && '0 0 1rem 0'}
        >
            <EnsureUser permissions={["RateClass:Read"]}>
                <div data-cy-id="admin_account_sync_stats_widget">
                    <UcLoading
                        hasFade={false}
                        hasError={swrCisSyncImportHeaders?.isError}
                        isLoading={swrCisSyncImportHeaders?.isLoading}
                        height={"20vh"}
                        errorMessage={t(
                            "An error occurred retrieving account sync stats."
                        )}
                    >
                        {hasAccountSyncStats ?
                            <>
                                <Grid container direction="row" sx={{ justifyContent: "center" }}>
                                    <div
                                        aria-owns={open ? 'mouse-over-popover' : undefined}
                                        aria-haspopup="true"
                                        onMouseEnter={handlePopoverOpen}
                                        onMouseLeave={handlePopoverClose}
                                        role="button"
                                    >
                                        <Gauge
                                            {...gaugeSettings}
                                            value={latestAccountSyncStatistics?.addedAccountsCount}
                                            valueMax={totalAccounts}
                                            valueMin={0}
                                            text={netAccounts.toString()}
                                            startAngle={-90}
                                            endAngle={90}
                                            innerRadius="65%"
                                            sx={(theme: any) => ({
                                                [`& .${gaugeClasses.valueText}`]: {
                                                    fontSize: 45,
                                                    '& tspan': {
                                                        fill: (netAccounts >= 0) ? green[700] : red[500],
                                                    },
                                                },
                                                [`& .${gaugeClasses.valueArc}`]: {
                                                    fill: green[700],
                                                },
                                                [`& .${gaugeClasses.referenceArc}`]: {
                                                    fill: red[500]
                                                }
                                            })}
                                        />
                                    </div>
                                    <Popover
                                        id="mouse-over-popover"
                                        sx={{
                                            pointerEvents: 'none',
                                        }}
                                        open={open}
                                        anchorEl={anchorEl}
                                        anchorOrigin={{
                                            vertical: 'top',
                                            horizontal: 'center',
                                        }}
                                        transformOrigin={{
                                            vertical: 'top',
                                            horizontal: 'center',
                                        }}
                                        onClose={handlePopoverClose}
                                        disableRestoreFocus
                                    >
                                        <Typography color={green[600]} sx={{ p: 1 }}>
                                            {t("New Accounts:")} {latestAccountSyncStatistics?.addedAccountsCount}
                                        </Typography>
                                        <Typography color={red[500]} sx={{ p: 1}}>
                                            {t("Deactivated Accounts:")} {latestAccountSyncStatistics?.deactivatedAccountsCount}
                                        </Typography>
                                    </Popover>
                                </Grid>
                                <Typography fontSize="20px" align="center">
                                    {t("Net accounts from last sync")}
                                </Typography>
                                <Typography sx={{ pb: 2 }} align="center">
                                    {t("Last u1020 sync:")} {stringDateFormatter(latestAccountSyncStatistics?.importStartDate, true)}
                                </Typography>
                                <Divider sx={{ borderBottomWidth: 5, borderColor: 'background.default' }} />
                                <Typography fontSize="20px" sx={{ pt: 2 }} align="center">
                                    {t("Accounts in the sync without valid email/phone:")} {latestAccountSyncStatistics?.accountsWithInvalidEmailAndPhone}
                                </Typography>
                            </>
                            :
                            <Alert severity="warning">{t("No account sync stats were found.")}</Alert>
                        }
                    </UcLoading>
                </div>               
            </EnsureUser>
        </UcWidget>
    );
};
