import React from "react";
import { Box, Button, TextField, InputAdornment } from "@mui/material";
import './UcToolbar.scss';
import { useDebouncedValue } from "@mantine/hooks";
import { useResponsive } from 'lib/useResponsive';
import { useTranslation } from "react-i18next";

export const UcToolbar = (props) => {
    const { isMobile } = useResponsive();
    const { t } = useTranslation();
    const [search, setSearch] = React.useState(props.generalSearch);
    const [searchButton, setSearchButton] = React.useState(props.generalSearch);
    const [debouncedSearch] = useDebouncedValue(search, 500);

    React.useEffect(() => {
        if (!props.useSearchButton) {
            props.onGeneralSearchChange(debouncedSearch);
        }
    }, [props, debouncedSearch]);

    const handleSearchClick = () => {
        props.onGeneralSearchChange(searchButton);
    };

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            handleSearchClick();
        }
    };

    return (
        <Box
            className="ucToolbar"
            sx={{
                display: (isMobile ? 'inline-block' : 'flex'),
                justifyContent: 'space-between',
                mb: 1,
            }}
        >
            <Box
                sx={{ display: 'inline-flex', alignItems: 'right' }}
                title={"General Search"}
            >
                {props.actions.filter(action => !action.isHidden).map((action, index) => (
                    <Box
                        sx={{ display: 'inline-flex', mr: (isMobile ? (props.actions.length > (index + 1) ? 1 : 0) : 1) }}
                        key={action.title}
                        title={action.toolTip || action.title}
                    >
                        <Button
                            variant="contained"
                            size="small"
                            startIcon={action.icon}
                            onClick={action.onClick}
                            disabled={action.isDisabled}
                            color={action.color || 'primary'}
                            data-cy-id={action.dataCyId}
                        >
                            {action.title}
                        </Button>
                    </Box>
                ))}
                {props.customComponents?.map((component, index) => (
                    <Box
                        sx={{ display: 'inline-flex', mr: (isMobile ? (props.customComponents.length > (index + 1) ? 1 : 0) : 1) }}
                        key={`customComponent_index`}
                    >
                        {component}
                    </Box>
                ))}
            </Box>
            {props.hasGeneralSearch &&
                <Box
                    sx={{ display: (isMobile ? 'inline-block' : 'inline-flex'), textAlign: 'right', mt: (isMobile ? 3 : 0), mb: (isMobile ? 2 : 0) }}
                    title={"General Search"}
                >
                    <TextField
                        data-cy-id={"general_search"}
                        fullWidth={isMobile}
                        className="input-slim__textField"
                        label={"General Search"}
                        onChange={(event) => {
                            setSearch(event.target.value);
                        }}
                    />
                </Box>
            }
            {props.useSearchButton &&
                <Box
                    sx={{ display: (isMobile ? 'inline-block' : 'inline-flex'), textAlign: 'right', mt: (isMobile ? 3 : 0), mb: (isMobile ? 2 : 0) }}
                    title={t("General Search")}
                >
                    <TextField
                        data-cy-id={"general_search"}
                        fullWidth={isMobile}
                        className="input-slim__textField"
                        label={t("General Search")}
                        value={searchButton}
                        onChange={(event) => {
                            setSearchButton(event.target.value);
                        }}
                        onKeyDown={handleKeyDown}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <Button onClick={handleSearchClick}>
                                        {t("Search")}
                                    </Button>
                                </InputAdornment>
                            )
                        }}
                    />
                </Box>
            }
        </Box>
    );
};

UcToolbar.defaultProps = {
    onGeneralSearchChange: (value) => { console.log("ucToolbar onGeneralSearchChange not implemented", value); },
};