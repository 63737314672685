import React, { useState, useLayoutEffect, useRef } from 'react';
import { DataGridPro } from '@mui/x-data-grid-pro';
import { useOrgUoms } from './OrgUomAPIEndpoints';
import { UcPagination } from 'components/common/UcPagination';

export const OrgUomGrid = (props) => {
    const [generalSearchInput] = useState(props.generalSearch || "");
    const initialSort = [{ field: "uom", sort: "asc" }];
    const sort = props.sort || initialSort;

    const initialDataState = props.dataState || { skip: 0, take: 10, };

    const [filter, setFilter] = useState({
        take: initialDataState.take,
        skip: initialDataState.skip,
        sort: sort,
        generalSearch: generalSearchInput,
        cepOrgId: props.cepOrgId || null,
    });

    let initialFilterModel = {
        items: [
            {columnField: 'name', operatorValue:'contains', value: ''}
        ],
        linkOperator: 'and',
    };
    if (generalSearchInput) {
        initialFilterModel = {
            items: [
                {columnField: 'name', operatorValue:'contains', value: generalSearchInput}
            ],
            linkOperator: 'and',
        }
    }

    const [filterModel] = useState(initialFilterModel);

    const needsDataUpdate = useRef("");

    let defaultDataItems = {
        columnVisibilityModel: {
            id: false,
            cepOrgId: false,
            content: false,
            createdBy: false,
            updatedBy: false,
        },
        columns: [
            {field: 'id', headerName: 'id'},
            { field: 'uom', headerName: 'Unit of Measure', width: 200 },
            {field: 'uomDisplay', headerName: 'Uom Display', width: 200},
            { field: 'meterCommodity', headerName: 'Commodity', width: 200 },
            { field: 'externalUomMapping', headerName: 'External UOM Mapping', width: 200 },
            { field: 'externalUomMappingDisplay', headerName: 'External UOM Mapping Display', width: 200 },
        ],
        rows: [],
        rowCount: -1,
    };
    const [dataItems, setDataItems] = useState(defaultDataItems);

    const defaultSelectedItem = { dataItem: {}, selectedId: 0 };
    const [selectedItem, setSelectedItem] = useState(defaultSelectedItem);

    const isDataLoaded = useRef(false);

    const dataReceived = (swrResult) => {
        var dataItemsReceived = swrResult.result;

        var selectedDataItems = dataItemsReceived.map((item) => {
            return { ...item, selected: item.id === selectedItem.selectedId };
        });
        selectedDataItems.iteration = dataItemsReceived.iteration;
        selectedDataItems.rowCount = swrResult.rowCount;
        if (selectedDataItems.length > selectedDataItems.rowCount) {
            selectedDataItems.rowCount = selectedDataItems.length;
        }

        setDataItems({...defaultDataItems, rows: [...dataItemsReceived], rowCount: swrResult.rowCount});
    };

    const swrOrgUoms = useOrgUoms(filter); //Pass the datastate directly as the filter.  The API will adjust filters, orderbys ect.
    if (!swrOrgUoms.result && defaultDataItems.rowCount !== -1)
    {
        setDataItems(defaultDataItems);
    }

    useLayoutEffect(() => {
        if (swrOrgUoms.result) {
            isDataLoaded.current = true;
            var filterString = JSON.stringify(filter);
            if (needsDataUpdate.current !== filterString) {
                needsDataUpdate.current = filterString;
                dataReceived(swrOrgUoms);
            }
        }
    });

    const updateFilter = (newFilter) => {
        setFilter(newFilter);
        props.onFilterChange(newFilter);
    };

    return (
        <DataGridPro
            loading={!isDataLoaded.current}
            paginationModel={{page: filter.skip / filter.take, pageSize: filter.take}}
            onPaginationModelChange={(model) => updateFilter({ ...filter, skip: model.page * model.pageSize, take: model.pageSize })}
            sortingMode='server'
            paginationMode='server'
            filterMode='server'
            sortModel={filter.sort}
            onSortModelChange={(model) => updateFilter({...filter, sort: model})}
            filterModel={filterModel}
            rowsPerPageOptions={[5, 10, 20]}
            onRowSelectionModelChange={(newSelectionModel) => {
                if (newSelectionModel.length !== 1)
                {
                    setSelectedItem(defaultSelectedItem);
                    props.onSelected(defaultSelectedItem.dataItem);
                    return;
                }
                const selectedId = newSelectionModel[0];
                var modelChangedSelectedItem = dataItems.rows.filter(obj => {return obj.id === selectedId});
                if (modelChangedSelectedItem.length !== 1)
                {
                    setSelectedItem(defaultSelectedItem);
                    props.onSelected(defaultSelectedItem.dataItem);
                    return;
                }
                modelChangedSelectedItem = modelChangedSelectedItem[0];
                var newlySelectedItem = { dataItem: modelChangedSelectedItem, selectedId: selectedId };

                setSelectedItem(newlySelectedItem);
                props.onSelected(newlySelectedItem.dataItem);
            }}
            pagination
            components={{
                Toolbar: () => {return  props.toolbar || <></>},
                Pagination: UcPagination,
            }}
            {...dataItems}
        />
    );
}

OrgUomGrid.defaultProps = {
    onSelected: (selectedItem) => { console.log("onSelected not implemented", selectedItem); },
    onFilterChange: (filter) => { console.log("onFilterChange not implemented", filter); },
};
