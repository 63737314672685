import * as React from 'react';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
//@ts-ignore
import { useEnums } from '../auth/PermissionApiEndpoint';
import { Enum } from '../common/Common.interfaces';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

interface DeliveryMethodMultiSelectProps {
    enumName: string,
    label: string,
    value: string,
    disabled?: boolean,
    forcedSize?: any,
    onChange: ((event: SelectChangeEvent<any>, child: React.ReactNode) => void) | undefined,
    forcedWidth?: string,
    forcedPadding?: number,
    forcedDisplay: string,
    datacyid: string,
    width?: string,
    disabledOptions?: Enum[],
    alwaysCheckedOptions?: Enum[] 
}

export const DeliveryMethodMultiSelect = (props: DeliveryMethodMultiSelectProps) => {
    const [options, setOptions] = React.useState<any>([]);
    const needsUpdate = React.useRef(true);

    const { alwaysCheckedOptions = [], disabledOptions = []} = props;

    let loadedOptions = [];

    let enums = useEnums(props.enumName);

    if (enums) {
        loadedOptions = enums;
        if (needsUpdate.current)
        {
            needsUpdate.current = false;
            setOptions([...loadedOptions]);
        }
    }
    else {
        needsUpdate.current = true;
    }

    return (
        <div>
            <FormControl sx={{ m: 1, width: props.width ?? 250 }}>
                <InputLabel id="demo-multiple-checkbox-label">{props.label}</InputLabel>
                <Select
                    data-cy-id={props.datacyid}
                    labelId="demo-multiple-checkbox-label"
                    id="demo-multiple-checkbox"
                    multiple
                    value={props.value.split(',')}
                    fullWidth={false}
                    disabled={props.disabled}
                    size={props.forcedSize || 'normal'}
                    onChange={props.onChange}
                    input={<OutlinedInput label={props.label} />}
                    renderValue={(selected) => selected.join(', ')}
                    style={{width: props.forcedWidth ?? '100%', padding: props.forcedPadding ?? 0, display: props.forcedDisplay ?? 'block'}}
                    MenuProps={MenuProps}
                >
                    {options.map((deliveryPreference: Enum) => (
                        <MenuItem
                            key={deliveryPreference.id}
                            value={deliveryPreference.display}
                            disabled={disabledOptions?.some(option => option.display === deliveryPreference.display)}
                        >
                            <Checkbox
                                checked={props.value.indexOf(deliveryPreference.display) > -1
                                || alwaysCheckedOptions?.some(option => option.display === deliveryPreference.display)}
                            />
                            <ListItemText primary={deliveryPreference.display} />
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </div>
    );
}