import React, { useState, useContext, useEffect, useLayoutEffect } from 'react';
import { PageContext } from '../../../lib/PageProvider';
import { BillingCycleGridStateful } from "./BillingCycleGridStateful"
import { BillingCycleFormDialog } from './BillingCycleFormDialog';
import { mutateUrls } from "../../../lib/useGenericSWR";
import { useSWRConfig } from "swr";
import './BillingCycleListPage.scss';
import { EnsureUser } from '../../auth/EnsureUser';
import { UcToolbar } from '../../common/UcToolbar';
import { UcListPage } from 'components/common/UcListPage';
import { useHasPermission } from 'components/auth/PermissionApiEndpoint';
import AddIcon from '@mui/icons-material/Add';
import { getBillingCycleesDefaultItem, billingCycleName, billingCycleesName } from './BillingCycleAPIEndpoints';
import { Box, Divider, Drawer } from '@mui/material';
import { UcDrawerHeader } from 'components/common/UcDrawerHeader';
import { BillingCycleDetailsPage } from './BillingCycleDetailsPage';

export const BillingCycleListPage = (props) => {
    const [editDialog, setEditDialog] = useState({ open: false, delete: false });
    const [selectedItem, setSelectedItem] = useState(null);
    const [pageState, pageDispatch] = useContext(PageContext);
    const [generalSearch, setGeneralSearch] = useState('');
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [reMutateUrls, setReMutateUrls] = useState(false);
    const swrConfig = useSWRConfig();

    const closeHandler = () => {
        setEditDialog({ open: false, itemToEdit: editDialog.dataItem });
    }

    const saveHandler = () => {
        closeHandler();
        // Also, remutate
        setReMutateUrls(true);
    }

    const openDialog = (dataItem) => {
        if (!dataItem) {
            return;
        }
        if (!dataItem.formIteration) {
            dataItem.formIteration = "edit" + dataItem.id;
        }
        setEditDialog({ open: true, itemToEdit: dataItem, delete: false });
    }

    const createItem = () => {
        openDialog(getBillingCycleesDefaultItem());
    }

    const editItem = (item) => {
        openDialog(item);
        handleDrawerClose();
    }

    const actions = [
        {
            icon: <AddIcon />,
            title: 'Add ' + billingCycleName,
            toolTip: 'Add ' + billingCycleName,
            onClick: () => { createItem(); },
            isHidden: !useHasPermission("BillingCycle:Modify"),
        }    
    ];

    useLayoutEffect(() => {
        if (reMutateUrls) {
            mutateUrls(swrConfig);
            setReMutateUrls(false);
        }
    }, [reMutateUrls, swrConfig]);

    useEffect(() => {
        pageDispatch({
            header: billingCycleesName,
        });
    }, [pageState.header, selectedItem, pageDispatch]);

    const handleDrawerClose = () => {
        setDrawerOpen(false);
    }

    const doubleClick = () => {
        if (selectedItem !== null && selectedItem?.selectedId !== 0 && !editDialog.open) {
            setDrawerOpen(true);
        }
    };
    const drawerWidth = "50vw";

    console.log("BillingCycleListPage - Loaded");
    return (
        <UcListPage>
            <EnsureUser permissions={["BillingCycle:Read"]}>
                <h1 className="pageHeader">{billingCycleesName}</h1>
                    <UcToolbar
                        hasGeneralSearch={true}
                        onGeneralSearchChange={(value) => {
                            setGeneralSearch(value);
                        } }
                        actions={actions}></UcToolbar>
                <BillingCycleFormDialog
                    open={editDialog.open}
                    delete={editDialog.delete}
                    itemToEdit={editDialog.itemToEdit}
                    onCancel={closeHandler}
                    onSave={saveHandler}
                />
                {!reMutateUrls &&
                    <BillingCycleGridStateful
                        setDrawerOpen={setDrawerOpen}
                        generalSearch={generalSearch}
                        onEditItem={editItem}
                        doubleClick={doubleClick}
                        onSelected={(newSelectedItem) => {
                            setSelectedItem({ ...newSelectedItem });
                        }} />
                }
                <Drawer
                    anchor="right"
                    open={drawerOpen}
                    onClose={handleDrawerClose}
                >
                    <UcDrawerHeader justify="flex-start"/>
                    <Box
                        sx={{ width: drawerWidth, margin: '10px' }}
                        role="presentation"
                    >
                        <Divider />
                        {selectedItem &&
                            <BillingCycleDetailsPage billingCycleId={selectedItem.id} billingCycleName={selectedItem.name} showDetails={false} />
                        }
                    </Box>
                </Drawer>
            </EnsureUser>
        </UcListPage>
    );
};