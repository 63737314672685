import React, { useContext, useEffect } from "react";
import { PageContext, PageState, PageAction } from "../../../lib/PageProvider";
import { UcWidget } from "../../common/UcWidget";
import { useTranslation } from "react-i18next";
import { Alert } from "@mui/material";
import { UcLoading } from "../../common/UcLoading";
import { useMyWallet } from "../../widgets/PaymentusApiEndpoints";
import EnsureUserAndModule from "../../auth/EnsureUserAndModule";
import './PaymentusPages.scss';

export const PaymentusWalletPage = () => {
    const { t } = useTranslation();
    const [pageState, pageDispatch] = useContext<[PageState, React.Dispatch<PageAction>]>(PageContext);
    const swrPaymentusUrl = useMyWallet();
    let isLoading = true;

    if ((swrPaymentusUrl && swrPaymentusUrl?.isLoading)) {
        isLoading = true;
    }
    else {
        isLoading = false;
    }

    useEffect(() => {
        pageDispatch({
            header: t("Paymentus Wallet"),
            actionItems: [] as any[],
            variableHeader: "",
            tabs: [],
            onTabChange: () => {},
            tabValue: "",
        });
    }, [pageState.header, pageDispatch, t]);

    console.log("PaymentusWalletPage - Loaded");

    return (
        <EnsureUserAndModule modules={['Billing', 'PaymentusWallet']}>
            <UcWidget contentPadding="0" titleRender={false}>
                <UcLoading isLoading={isLoading} hasFade={false}>
                    {swrPaymentusUrl ?
                        <div className="paymentusWallet">
                            <iframe
                                title="PaymentusWallet"
                                allow="payment"
                                src={swrPaymentusUrl?.result}
                                className="paymentus-responsive-iframe"
                                style={{ border: "0px" }}
                            />
                        </div>                   
                        :
                        <Alert severity="warning">{t("Could not load Paymentus wallet.")}</Alert>
                    }
                </UcLoading>
            </UcWidget>  
        </EnsureUserAndModule>
            
    );
};
