import { ApiClient } from "../../../lib/ApiClient";
import { mutateUsers } from './UserAPIEndpoints';

const apiEndpoint = 'api/v1/admin/uc/UserLockout';
const apiClient = new ApiClient();

const unlockUser = (User) => {
    mutateUsers();
    return apiClient.poster(apiEndpoint + '/unlock', User);
};

export {
    unlockUser
};