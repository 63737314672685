import { AdminRateExpirationGrid } from "./AdminRateExpirationGrid"
import { useState, useLayoutEffect, useEffect, ChangeEvent } from "react";
import { useSearchParams } from "react-router-dom";
// @ts-ignore
import { isEqual } from "lib/Compare";
// @ts-ignore
import { FilterQuery } from "components/common/Common.interfaces";

interface AdminRateExpirationGridStatefulProps {
    generalSearch: string | undefined | null,
    id: number | null,
    status: string
}

export const AdminRateExpirationGridStateful = (props: AdminRateExpirationGridStatefulProps) => {
    const { generalSearch, id, status } = props;
    const [searchParams, setSearchParams] = useSearchParams();
    const queryParamName= (id || "adminRateExpirationGrid") + "_state";
    const [isRenderReady, setIsRenderReady] = useState(true);

    const getQueryCurrent = () => {
        let defaultValue = {
            take: 5,
            skip: 0,
            pageSize: 5,
            generalSearch: generalSearch ?? null,
            status: status,
            sort: [{ field: "endDate", sort: "asc" }],
        };

        let valueStr: any = searchParams.get(queryParamName);

        let result: FilterQuery = {
            take: 0,
            skip: 0,
            pageSize: 0,
            generalSearch: '',
            status: 'Expiring',
            sort: [{
                field: '',
                sort: ''
            }]
        };

        try {
            result = JSON.parse(valueStr);
        }
        catch {
            console.error('Invalid Query Param ' + queryParamName, valueStr);
        }
        if (!result) {
            return defaultValue;
        }
        return result;
    };

    let queryCurrent: FilterQuery = getQueryCurrent();
    const [current, setCurrent] = useState<FilterQuery>(queryCurrent);

    const handleFilterChange = (e:ChangeEvent<HTMLInputElement>) => {
        const newCurrent = { ...current, ...e };
        setCurrent(newCurrent);
        searchParams.set(queryParamName, JSON.stringify(newCurrent));
        setSearchParams(searchParams);
    }

    useEffect(() => {
        if (!isEqual(current.generalSearch, generalSearch) || !isEqual(current.status, status)) {
            const newCurrent = { ...current, generalSearch: generalSearch, status: status }
            setCurrent(newCurrent);
            searchParams.set(queryParamName, JSON.stringify(newCurrent));
            setSearchParams(searchParams);
            setIsRenderReady(false);
        }
    }, [current, generalSearch, status, queryParamName, searchParams, setSearchParams]);

    useLayoutEffect(() => {
        if (JSON.stringify(queryCurrent) !== JSON.stringify(current) && isRenderReady) {
            setIsRenderReady(false);
        }

        if (!isRenderReady) {
            setIsRenderReady(true);
            setCurrent(queryCurrent);
        }
    }, [queryCurrent, current, isRenderReady, setIsRenderReady]);

    return (
        <>
            {isRenderReady &&
                <AdminRateExpirationGrid {...props}
                    sort={current.sort}
                    dataState={current}
                    generalSearch={generalSearch}
                    onFilterChange={handleFilterChange}
                    toolbar={undefined}
                />
            }
        </>
    );
}
