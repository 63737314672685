// @ts-ignore
import { ApiClient } from "../../../lib/ApiClient";
import { useGenericSWR } from "../../../lib/useGenericSWR";
import * as Yup from "yup";

export const quickActionsApiEndpoint = "api/v1/QuickActions";
export interface QuickAction {
    id?: number;
    cepOrgId: number;
    title: string;
    description: string;
    buttonName: string;
    buttonLink: string;
    displayOrder: number;
    isDisplayed: boolean;
    isDefault: boolean;
    formIteration?: string;
}

let formIteration = 0;

const getQuickActionDefaultItem = () => {
    formIteration = formIteration + 1;
    return {
        formIteration: "new" + formIteration,
        id: 0,
        cepOrgId: 0,
        title: "",
        description: "",
        buttonName: "",
        buttonLink: "",
        displayOrder: 0,
        isDisplayed: true,
        isDefault: false
    };
};

const quickActionValidationSchema = Yup.object().shape({
    title: Yup.string()
        .required("Required")
        .max(256, 'Max of 256 characters'),
    description: Yup.string()
        .required("Required")
        .max(512, 'Max of 512 characters'),
    buttonName: Yup.string()
        .required("Required")
        .max(255, 'Max of 255 characters'),
    buttonLink: Yup.string()
        .required("Required")
        .max(255, 'Max of 255 characters'),
});

const apiClient = new ApiClient();
apiClient.getDefaultItem = () => getQuickActionDefaultItem();
apiClient.getObjectName = () => {
    return "Quick Action";
};

const useQuickActions = (swrOptions: any = {}) => {
    const filter = {
        take: 100,
        skip: 0,
        sort: [{ field: "displayOrder", sort: "asc" }]
    };

    const params = new URLSearchParams();
    params.append("filter", JSON.stringify(filter));
    return useGenericSWR(
        quickActionsApiEndpoint + "?" + params.toString(),
        apiClient,
        { ...swrOptions }
    );
};

const bulkUpdateQuickActions = (QuickAction: QuickAction[]) => {
    return apiClient.putter(
        quickActionsApiEndpoint + "/bulkUpdate",
        QuickAction
    );
};

const saveQuickAction = (QuickAction: QuickAction) => {
    if (QuickAction.id === 0) {
        return insertQuickAction(QuickAction);
    }
    return updateQuickAction(QuickAction);
};

const insertQuickAction = (QuickAction: QuickAction) => {
    return apiClient
        .poster(quickActionsApiEndpoint, QuickAction)
        .then((result: any) => {
            return result;
        });
};

const updateQuickAction = (QuickAction: QuickAction) => {
    return apiClient.putter(quickActionsApiEndpoint, QuickAction);
};

const deleteQuickAction = (QuickAction: QuickAction) => {
    return apiClient
        .deleter(quickActionsApiEndpoint + "/" + QuickAction.id)
        .then((result: any) => {
            return result;
        });
};

export {
    useQuickActions,
    saveQuickAction,
    insertQuickAction,
    updateQuickAction,
    bulkUpdateQuickActions,
    deleteQuickAction,
    quickActionValidationSchema,
    getQuickActionDefaultItem,
};
