import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Grid,
} from "@mui/material";
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import { sendChangeRequest } from "./RateClassComparisonApiEndpoints";
// @ts-ignore
import { UcSummaryTable } from "../common/UcSummaryTable";
import { useTranslation } from "react-i18next";

interface RateClassComparisonChangeRequestDialogProps {
    accountId: number | null | undefined;
    currentRateClass: any;
    comparedRateClass: any;
    uom: string;
    currentRateCategories: any[];
    comparedRateCategories: any[];
    open: boolean;
    handleClose: () => void;
}

export default function RateClassComparisonChangeRequestDialog(
    props: Readonly<RateClassComparisonChangeRequestDialogProps>
) {
    const { t } = useTranslation();
    const {
        accountId,
        currentRateClass,
        comparedRateClass,
        uom,
        currentRateCategories,
        comparedRateCategories,
        open,
        handleClose,
    } = props;

    const handleConfirm = () => {
        sendChangeRequest(accountId, currentRateClass.id, comparedRateClass.id);
        handleClose();
    };

    return (
        <Dialog
            data-cy-id={'rateClassComparison_SendRequest_Dialog'}
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                {t("Are you sure you want to request a Rate Class change?")}
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description" sx={{ mb: 1 }}>
                    {t(
                        "Confirming will send an email to your utility, requesting to change your rate class from {{currentRateClass}} to {{comparedRateClass}}.",
                        {
                            currentRateClass: currentRateClass?.name,
                            comparedRateClass: comparedRateClass?.name,
                        }
                    )}
                </DialogContentText>
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <UcSummaryTable
                            rateCategories={currentRateCategories}
                            uom={uom}
                            titleOverride={t("{{rateClass}}", {
                                rateClass: currentRateClass?.name,
                            })}
                        />
                    </Grid>
                    <Grid item xs={12} sx={{ textAlign: "center" }}>
                        <ArrowDownwardIcon fontSize="large" />
                    </Grid>
                    <Grid item xs={12}>
                        <UcSummaryTable
                            rateCategories={comparedRateCategories}
                            uom={uom}
                            titleOverride={t("{{rateClass}}", {
                                rateClass: comparedRateClass?.name,
                            })}
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} variant="outlined" data-cy-id={'rateClassComparison_SendRequestCancel_Btn'}>
                    {t("No")}
                </Button>
                <Button onClick={handleConfirm} variant="contained">
                    {t("Yes")}
                </Button>
            </DialogActions>
        </Dialog>
    );
}
