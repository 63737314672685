//@ts-ignore
import { mutateMetrics } from "components/admin/Metrics/MetricsAPIEndpoints";
//@ts-ignore
import { ApiClient } from "../../lib/ApiClient";
import { useGenericSWR } from "../../lib/useGenericSWR";
import { SwrOptions } from '../../lib/useGenericSWR.interfaces';
import { HelpRequest } from "./HelpRequest.interfaces";

const apiEndpoint = 'api/v1/admin/HelpRequest';

const apiClient = new ApiClient();
apiClient.getObjectName = () => {
    return "Help Request";
};

const useHelpRequest = (id: number, swrOptions = {}) => {
    // SWR will prevent duplicate requests
    // It will also maintain data state as long as your are using the same urls for your requests
    return useGenericSWR(apiEndpoint + "/" + id, apiClient, { ...swrOptions as SwrOptions });
};

const useHelpRequests = (filter: any, swrOptions = {}) => {
    // SWR will prevent duplicate requests
    // It will also maintain data state as long as your are using the same urls for your requests
    const params = new URLSearchParams();
    params.append('filter', JSON.stringify(filter));
    return useGenericSWR(apiEndpoint + "?" + params.toString(), apiClient, { ...swrOptions as SwrOptions });
};

const saveHelpRequest = (HelpRequest: HelpRequest) => {
    return insertHelpRequest(HelpRequest);
}
const insertHelpRequest = (HelpRequest: HelpRequest) => {
    return apiClient.poster(apiEndpoint, HelpRequest)
        .then((result: any) => {
            mutateMetrics();
            return result;
        });
};

const updateHelpRequest = (HelpRequest: HelpRequest, doToast: boolean = true) => {
    return apiClient.putter(apiEndpoint, HelpRequest, "", false, null, doToast)
        .then((result: any) => {
            mutateMetrics();
            return result;
        });
};

const deleteHelpRequest = (HelpRequest: HelpRequest) => {
    return apiClient.deleter(apiEndpoint + "/" + HelpRequest.id)
        .then((result: any) => {
            mutateMetrics();
            return result;
        });
};

export {
    useHelpRequest,
    useHelpRequests,
    saveHelpRequest,
    insertHelpRequest,
    updateHelpRequest,
    deleteHelpRequest
};