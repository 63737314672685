const defaultStorageProvider = () => {
    const map = new Map();

    return map
}

// Thought about adding additional providers like localStorage and possibly indexDb
// However, there are issues with storing PII within localStorage
// swrCached results will have PII so we can not do this
const swrStorageProvider = () => {
    return defaultStorageProvider();
}

export {
    swrStorageProvider
};

