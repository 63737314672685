import { ApiClient } from "../../lib/ApiClient";
import { useGenericSWR } from "../../lib/useGenericSWR";
import * as Yup from 'yup';

const apiEndpoint = 'api/v1/MyUser';

let formIteration = 0;

const getUsersDefaultItem = () => {
    formIteration = formIteration + 1;
    return {
        formIteration: "new" + formIteration,
        id: 0,
        cepOrgId: 0,
        username: '',
        email: '',
        firstName: '',
        middleName: '',
        lastName: '',
        address: '',
    }
};

const myUserValidationSchema = Yup.object().shape({
    email: Yup.string().email()
        .required("Required")
        .max(256, 'Max of 256 characters'), //emails have a max total length of 256 characters (source: RFC specifications)
    firstName: Yup.string()
        .required("Required")
        .max(256, 'Max of 256 characters'),
    lastName: Yup.string()
        .required("Required")
        .max(256, 'Max of 256 characters'),
    address: Yup.string()
        .max(1024, 'Max of 1024 characters'),
    phonenumber: Yup.string()
        .max(255, 'Max of 255 characters'),
});

const apiClient = new ApiClient();
apiClient.getDefaultItem = () => getUsersDefaultItem();
apiClient.getObjectName = () => {
    return "My User";
};

const useMyUser = (swrOptions = {}) => {
    const filter = { take: 1, skip: 0 };

    const params = new URLSearchParams();
    params.append('filter', JSON.stringify(filter));
    const swrResult = useGenericSWR(apiEndpoint + "?" + params.toString(), apiClient, { ...swrOptions });

    const modifiedData = swrResult
        ? {
            ...swrResult,
            result: swrResult.result?.length ? swrResult.result[0] : null,
        }
        : null;

    return modifiedData;
};

const saveMyUser = (MyUser) => {
    return updateMyUser(MyUser);
}

const updateMyUser = (MyUser) => {
    return apiClient.putter(apiEndpoint, MyUser);
};

export {
    useMyUser,
    saveMyUser,
    myUserValidationSchema,
    getUsersDefaultItem
};