import React, { useContext } from 'react';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import MenuIcon from '@mui/icons-material/Menu';
import '../ResponsiveAppBar.scss';
import UserMenu from '../UserMenu';
import { useResponsive } from 'lib/useResponsive';
import { EnsureUser } from '../auth/EnsureUser';
import { AccountSelect } from '../customer/accounts/AccountSelect';
import "./UcSharedAppBar.scss";
import { AccountContext } from '../../lib/AccountProvider';
import { OrgAppSettingsContext } from '../../lib/OrgAppSettingsProvider';
import { useUser } from 'components/auth/PermissionApiEndpoint';

export const UcSharedAppBar = (props) => {
    const { isMobile, isTablet, isLaptop } = useResponsive();
    const shortTitle = isMobile || isTablet || isLaptop;
    const { orgAppSettings } = React.useContext(OrgAppSettingsContext);
    const { account, setAccount } = useContext(AccountContext);
    const user = useUser();

    const handleDrawerOpen = () => {
        props.onChange(true);
    };

    const handleAccountChange = (_event, newAccount) => {
        if (newAccount?.id !== account?.id || account === null) {
            setAccount(newAccount);
        }
    }

    const titleContent = () => {
        return (
            <>
                <Typography
                    sx={{ flexGrow: 1, display: { xs: 'flex' }, ml: 1 }}
                    variant="h6"
                    noWrap
                    component="div"
                    title={orgAppSettings.result?.title}
                >
                    {shortTitle ? "" : (<strong>{orgAppSettings.result?.title}</strong>) }
                </Typography>
            </>
        );
    }

    const accountContent = () => {
        let className = 'ucSharedAppBar__accountSelect--box';
        if (isMobile || isTablet) {
            // Hide if mobile or tablet
            return (
                <EnsureUser>
                    {user.id !== undefined && (
                        <AccountSelect sx={{ display: "none" }}
                            onChange={handleAccountChange}
                            showAddressOnly={true}
                            allowDelegations={true}
                            userId={user.id}
                            userEmail={user.email}
                            showAccountNumber={true}
                             />
                    )}
                    
                </EnsureUser>
            );
        }
        return (
            <EnsureUser className={className}>
                {user.id !== undefined && (
                    <AccountSelect
                        onChange={handleAccountChange}
                        showAddressOnly={true}
                        allowDelegations={true}
                        userId={user.id}
                        userEmail={user.email}
                        showAccountNumber={true}
                        showNickname={true}
                     />
                )}
            </EnsureUser>
        );
    }

    const userMenuContent = () => {
        return (
            <EnsureUser>
                <UserMenu />
            </EnsureUser>
        );
    }

    return (
    <>
        <Toolbar disableGutters style={{maxHeight: '64px'}}>
            <Box sx={{ flexGrow: 1, alignItems: 'center', display: { xs: 'flex' } }} style={{ padding: "0 17px" }}>
                <IconButton
                    size="large"
                    aria-label="account of current user"
                    aria-controls="menu-appbar"
                    aria-haspopup="true"
                    onClick={handleDrawerOpen}
                    data-cy-id={"nav-menu-toggle"}
                    color="inherit"
                    edge="start"
                    sx={{
                        marginRight: 1,
                        ...(props.isOpen && { display: "none" })
                    }}
                >
                    <MenuIcon />
                </IconButton>
                { titleContent() }
            </Box>
            <Box>
            { accountContent() }
            </Box>
            <Box sx={{ flexGrow: 0 }} style={{ padding: "0 10px" }}>
                {userMenuContent()}
            </Box>
         </Toolbar>
    </>
    )
};

export default UcSharedAppBar;