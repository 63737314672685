import * as React from 'react';
import { UcSelect } from 'components/common/UcSelect';
import { rateClassApiEndpoint } from './RateClassAPIEndpoints';

export const RateClassAdminSelect = (props) => {
    const filter = {
        take: 100,
        skip: 0,
        sort: [{ field: "name", sort: "asc" }],
        id: props.rateClassId,
        generalSearch: "",
    };

    return (
        <UcSelect {...props}
            apiEndPoint={rateClassApiEndpoint}
            defaultLabel={"Rate Classes"}
            filter={filter} />
    );
};