import { UcChartProvider } from './UcChartProvider';
import { PageProvider } from './PageProvider';
import { CssBaseline } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterLuxon as DateAdapter } from '@mui/x-date-pickers/AdapterLuxon';
import { UcThemeProvider } from './UcThemeProvider';
import { GoNativeProvider } from './GoNativeProvider';
import { IdleTimerProvider } from './IdleTimerProvider';
import { SWRConfig } from 'swr';
import { swrStorageProvider } from './SwrStorageProvider';
import { UserInfoProvider } from './UserInfoProvider';
import { AccountProvider } from './AccountProvider';
import { UserUtilitiesProvider } from './UserUtilitiesProvider';
import { OrgAppSettingsProvider } from './OrgAppSettingsProvider';

// Wrap any providers here so the main app.js looks cleaner
export const AppProvider = ({ children }) => {
    return (
        <OrgAppSettingsProvider>
            <UserUtilitiesProvider>
                <UserInfoProvider>
                    <AccountProvider>
                        <IdleTimerProvider>
                            <GoNativeProvider>
                                <UcChartProvider>
                                    <LocalizationProvider dateAdapter={DateAdapter}>
                                        <SWRConfig value={{ provider: swrStorageProvider }}>
                                            <UcThemeProvider>
                                                <CssBaseline enableColorScheme />
                                                <PageProvider>
                                                    {children}
                                                </PageProvider>
                                            </UcThemeProvider>
                                        </SWRConfig>
                                    </LocalizationProvider>
                                </UcChartProvider>
                            </GoNativeProvider>
                        </IdleTimerProvider>
                    </AccountProvider>
                </UserInfoProvider>
            </UserUtilitiesProvider>
        </OrgAppSettingsProvider>
    );
};