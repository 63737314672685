// @ts-ignore
import { ApiClient } from "../../lib/ApiClient";
// @ts-ignore
import { useGenericSWR } from "../../lib/useGenericSWR";
// @ts-ignore
import { ensureLuxonDate } from "lib/Formatters";
// @ts-ignore
import { DateTime } from "luxon";
import React, { useEffect, useContext, useState } from "react";
import { FetcherOptions } from "./FetcherOptions";
import { UcProgressStats } from "components/common/UcProgress";
// @ts-ignore
import { useMyTimezone } from 'components/customer/orgs/MyOrgAPIEndpoints';
import { AccountContext } from 'lib/AccountProvider';

const getReadingsMultipleUomsApiEndpoint = 'api/v1/MyReading/GetReadingsMultipleUoms';
// See definition from UdmReadings in UdmObjects.cs
const apiClient = new ApiClient();
apiClient.getDefaultItem = () => {
    // returning empty object
};
apiClient.getObjectName = () => {
    return "Reading";
};
apiClient.customDataHandler = (data: any, error: any, isValidating: any) => {
    if (data?.mainPeriod && data?.comparePeriod) {
        let results = [];

        results.push({
            startDate: data.mainPeriod.startTimeUtc,
            endDate: data.mainPeriod.endDateUtc,
            result: data.mainPeriod.readings ? data.mainPeriod.readings : null,
            groupings: data.mainPeriod.groupings ? data.mainPeriod.groupings : null,
            rowCount: data.mainPeriod.result ? data.mainPeriod.result.totalCount : 0,
            totalCount: data.mainPeriod.result ? data.mainPeriod.result.totalCount : 0,
            skip: data.mainPeriod.result ? data.mainPeriod.result.skip : 0,
            take: data.mainPeriod.result ? data.mainPeriod.result.take : 0
        });

        results.push({
            startDate: data.comparePeriod.startTimeUtc,
            endDate: data.comparePeriod.endDateUtc,
            result: data.comparePeriod.readings ? data.comparePeriod.readings : null,
            groupings: data.comparePeriod.groupings ? data.comparePeriod.groupings : null,
            rowCount: data.comparePeriod.result ? data.comparePeriod.result.totalCount : 0,
            totalCount: data.comparePeriod.result ? data.comparePeriod.result.totalCount : 0,
            skip: data.comparePeriod.result ? data.comparePeriod.result.skip : 0,
            take: data.comparePeriod.result ? data.comparePeriod.result.take : 0
        });

        return {
            result: results,
            isLoading: !error && results.length === 0,
            isError: !(!error),
            error: error,
            isValidating: isValidating,
        };
    }
    else {
        return {
            result: data?.result || data,
            groupings: data ? data.groupings : null,
            rowCount: data ? data.totalCount : 0,
            totalCount: data ? data.totalCount : 0,
            skip: data ? data.skip : 0,
            take: data ? data.take : 0,
            isLoading: !error && !data,
            isError: !(!error),
            error: error,
            isValidating: isValidating,
        };
    }
}

interface UseGetReadingsMultipleUomsOptions {
    startDateTime: Date | DateTime | number,
    endDateTime: Date | DateTime | number,
    groupBy: string,
    uoms: string[],
    accountId: number,
    swrOptions?: any,
}

const useGetReadingsMultipleUoms = (options: UseGetReadingsMultipleUomsOptions, readyForData: boolean) => {
    // SWR will prevent duplicate requests
    const params = new URLSearchParams();
    params.append('accountId', options.accountId.toString());
    params.append('startTimeUTC', ensureLuxonDate(options.startDateTime)?.toUTC().toISO());
    params.append('endTimeUTC', ensureLuxonDate(options.endDateTime)?.toUTC().toISO());
    params.append('groupBy', options.groupBy);
    params.append('uomJson', JSON.stringify(options.uoms));

    const swrOptions = options.swrOptions || {};
    let url = getReadingsMultipleUomsApiEndpoint + "?" + params.toString();
    if (!readyForData) {
        url = "";
    }

    return useGenericSWR(url, apiClient, { ...swrOptions });
};

interface GetReadingsMultipleUomsWrapperOptions extends FetcherOptions, UseGetReadingsMultipleUomsOptions {

}

const GetReadingsMultipleUomsWrapper = (options: GetReadingsMultipleUomsWrapperOptions) => {
    const prevOptionsRef = React.useRef<GetReadingsMultipleUomsWrapperOptions>();
    const [progress, setProgress] = React.useState<UcProgressStats>({ current: 0, total: 100 });

    // Check if options is different from prevProp
    useEffect(() => {
        const prevOptionsProp = prevOptionsRef.current;

        const startDateTime = ensureLuxonDate(options.startDateTime)?.toUTC().toISO();
        const endDateTime = ensureLuxonDate(options.endDateTime)?.toUTC().toISO();
        const prevStartDateTime = ensureLuxonDate(prevOptionsProp?.startDateTime)?.toUTC().toISO();
        const prevEndDateTime = ensureLuxonDate(prevOptionsProp?.endDateTime)?.toUTC().toISO();

        const hasChanged = options.accountId !== prevOptionsProp?.accountId ||
            startDateTime !== prevStartDateTime ||
            endDateTime !== prevEndDateTime ||
            options.groupBy !== prevOptionsProp?.groupBy ||
            JSON.stringify(options.uoms) !== JSON.stringify(prevOptionsProp?.uoms);

        if (hasChanged) {
            // Update the ref with the current prop after the comparison
            prevOptionsRef.current = options;
            
            // reset the progress
            const newProgress = { current: 0, total: 100 };
            setProgress(newProgress);

            if (options.onReset) {
                options.onReset();
            }
        }
    }, [options]);

    const data = useGetReadingsMultipleUoms(options, options.readyForData);

    useEffect(() => {
        const interval = setInterval(() => {
            if (progress.current >= progress.total) {
                clearInterval(interval);
                return;
            }
            // Make it so it is never 100%.. this is a fake progress
            const newProgress = { current: progress.current + 1, total: progress.total + 1 };
            setProgress(newProgress);
            if (options.onProgress) {
                options.onProgress(newProgress);
            }
        }, 200);
        return () => { clearInterval(interval); }
    }, [options, progress]);

    React.useEffect(() => {
        if (!data.error && !data.isLoading && options.readyForData && progress.current !== 1 && progress.total !== 1) {
            // data.result?.map()
            // Need to find a way to map data
            if (options.onDataCompleted) {
                options.onDataCompleted(data.result);
            }
            const newProgress = { current: 1, total: 1 };
            setProgress(newProgress); // just complete the progress
            if (options.onProgress) {
                options.onProgress(newProgress);
            }
        }
        else if (data.error && !data.isLoading && options.readyForData && progress.current !== 1 && progress.total !== 1) {
            if (options.onError) {
                options.onError(data.error);
            }
            const newProgress = { current: 1, total: 1 };
            setProgress(newProgress); // just complete the progress
            if (options.onProgress) {
                options.onProgress(newProgress);
            }
        }
    }, [data, options, progress]);


    return null;
};

interface DashboardStatWrapperOptions extends FetcherOptions { }

const DashboardStatWrapper = (options: DashboardStatWrapperOptions) => {
    const { account } = useContext(AccountContext);
    const timezone = useMyTimezone();
    const yesterday = new Date();
    yesterday.setDate(yesterday.getDate() - 1);
    const [hasError, setHasError] = useState(false);

    const dateStart = DateTime.fromObject({
        year: yesterday.getFullYear(),
        month: yesterday.getMonth() + 1,
        day: yesterday.getDate()
    },
        { zone: timezone })
        .minus({ months: 3 })
        .startOf("month").toJSDate();

    const dateEnd = DateTime.fromObject({ year: yesterday.getFullYear(), month: yesterday.getMonth() + 1, day: yesterday.getDate() }, { zone: timezone }).endOf("month").toJSDate();

    // !!!! 
    // NOTE:
    // Due to this check, the calling compoonent MUST handle the reset of readyForData
    // on account context change 
    // !!!!
    if (account?.id === null || !options.readyForData) {
        return <></>
    }

    return (<GetReadingsMultipleUomsWrapper
        startDateTime={dateStart}
        endDateTime={dateEnd}
        groupBy={'month'}
        uoms={['kWh', 'gal']}
        accountId={account?.id as number}
        readyForData={!hasError}
        onDataCompleted={(newData) => {
            options?.onDataCompleted(newData);
            setHasError(false);
        }}
        onProgress={(progressLocal) => {
            options?.onProgress(progressLocal);
        }}
        onReset={() => {
            options?.onReset();
            setHasError(false);
        }}
        onError={(error) => {
            console.error(error);
            options?.onError(error);
            setHasError(true);
        }}
    />);
}

export {
    useGetReadingsMultipleUoms,
    GetReadingsMultipleUomsWrapper,
    DashboardStatWrapper
};