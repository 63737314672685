import React, { useState, useLayoutEffect } from 'react';
import { MyUserAccountGridStateful } from "./MyUserAccountGridStateful"
import { MyUserAccountFormDialog } from './MyUserAccountFormDialog';
import { mutateUrls } from "lib/useGenericSWR";
import { useSWRConfig } from "swr";
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import { UcToolbar } from 'components/common/UcToolbar';
import { EnsureUser } from 'components/auth/EnsureUser';
import { getMyUserDelegationsDefaultItem } from './MyUserDelegationAPIEndpoints';
import { useTranslation } from "react-i18next";
import { useResponsive } from 'lib/useResponsive';
import { MyDelegatedAccountFormDialog } from './MyDelegatedAccountFormDialog';
import { MyUserDelegationFormDialog } from './MyUserDelegationFormDialog';
import { MyUserDelegationGridStateful } from "./MyUserDelegationGridStateful";
import AccountAuthorizationDialog from "../accountAuthorization/AccountAuthorizationDialog";
import { UcIconButtons } from 'components/common/UcIconButtons';
import { enumFormatter } from 'lib/EnumFormatter';
import { accountNameFormatter } from 'lib/Formatters';
import { useEnums, useUser } from 'components/auth/PermissionApiEndpoint';
import { getWholeRow } from 'lib/DataGridHelpers';
import { UcIconButtonsWithLabel } from 'components/common/UcIconButtonsWithLabel';
import './MyUserAccountList.scss';
import './MyUserDelegationList.scss';
import { useDomainNavigate } from "domain";
import { routePaths } from 'routes';

export const MyUserAccountList = (props) => {
    const { t } = useTranslation();
    const { isMobile } = useResponsive();
    const params = new URLSearchParams(window.location.search);
    const autoPopup = Boolean(params.get("autoPopup")) || false;
    const user = useUser();
    const accountId = props.accountId;
    const [myAccountsEditDialog, setMyAccountsEditDialog] = useState({ open: false, delete: false });
    const [sharedWithMeEditDialog, setSharedWithMeEditDialog] = useState({ open: false, delete: false });
    const [sharedWithOtherEditDialog, setSharedWithOtherEditDialog] = useState({ open: false, delete: false });
    const [reMutateUrls, setReMutateUrls] = useState(false);
    const swrConfig = useSWRConfig();
    const navigate = useDomainNavigate();

    const accountsActionsHandler = (itemValue, labelFlag) => {
        let match = undefined;

        if (itemValue.delegatesJson !== undefined) {
            match = JSON.parse(itemValue.delegatesJson).filter((value) => {
                return value.email === user.email;
            })[0];
        }
        const actions = [
            {
                title: t('Delete Account Access'),
                icon: <DeleteIcon />,
                color: 'error',
                isHidden: false,
                isDisabled: false,
                label: t('Delete'),
                labelSx: { display: 'inline-block', width:'100%', ml:1, textAlign:'justify' },
                onClick: () => {
                    if (match !== undefined) {
                        deleteSharedWithMe(match);
                    }
                    else {
                        deleteItem(itemValue);
                    }
                }
            }
        ];

        return (labelFlag ? (
            <UcIconButtonsWithLabel actions={actions} />
            ) : (
            <UcIconButtons actions={actions} />
            )
        );
    }

    const sharedAccountsActionsHandler = (itemValue, labelFlag) => {
        const actions = [
            {
                title: props.delegationId ? t('Delete User') : t('Remove Shared Access'),
                color: 'error',
                isHidden: false,
                isDisabled: false,
                icon: <DeleteIcon />,
                label: t('Delete'),
                labelSx: { display: 'inline-block', width:'100%', ml:1, textAlign:'justify' },
                onClick: () => { deleteSharedWithOtherItem(itemValue); },
            },
        ];

        return (labelFlag ? (
            <UcIconButtonsWithLabel actions={actions} />
            ) : (
            <UcIconButtons actions={actions} />
            )
        );
    }

    let defaultDataItemsForAccounts = {
        columnVisibilityModel: {
            id: true,
            cepOrgId: false,
            content: false,
            createdBy: false,
            updatedBy: false,
            sortPriority: false,
            extAccountId: false,
            extSource: false,
        },
        columns: [
            {field: 'sortPriority', headerName: t('Sort Priority'), cardHide: true},
            {field: 'username', headerName: t('Ownership'), width: 200,
                valueFormatter: (params) => {
                    if (params.value === user.email) {
                        return t("Me");
                    }
                    else {
                        return params.value;
                    }
                }
            },
            {field: 'accountName', headerName: t('Account Name'), width: 400, cardColumnWidth: 12,
                valueGetter: getWholeRow,
                valueFormatter: (params) => {
                    return accountNameFormatter(params.value);
                }
            },
            {field: 'extAccountId', headerName: t('Account Id'), width: 250, cardHide: true},
            {field: 'extAccountNumber', headerName: t('Account Number'), flex: 1, minWidth: 150, cardColumnWidth: 12},
            {field: 'extSource', headerName: t('Source'), minWidth: 100, cardHide: true},
            {field: 'id', headerName: '', minWidth: 50, cardHide: true,
                valueGetter: getWholeRow,
                renderCell: (params) => {
                    return accountsActionsHandler(params.value, false);
                }
            }
        ],
        cardHeaderField: {field: "accountName", valueGetter: getWholeRow,
        valueFormatter: (params) => {
            return accountNameFormatter(params.value);
            }
        },
        rows: [],
        rowCount: -1,
    };

    const accessEnums = useEnums("DelegationAccessLevel");

    let defaultDataItemsForSharedAccounts = {
        columnVisibilityModel: {
            id: true,
            cepOrgId: false,
            content: false,
            createdBy: false,
            updatedBy: false,
            userName: false,
        },
        columns: [
            {field: 'username', headerName: t('User'), width: 200},
            {field: 'delegatedUserEmail', headerName: t('Delegated User'), width: 200},
            {field: 'accountName', headerName: t('Account Name'), flex: 1, minWidth: 400, cardColumnWidth: 12,
                valueGetter: getWholeRow,
                valueFormatter: (params) => {
                    return accountNameFormatter(params.value);
                }
            },
            {field: 'extAccountNumber', headerName: t('Account Number'), flex: 1, minWidth: 150, cardColumnWidth: 6 },
            {field: 'accessLevel', headerName: t('Access'), minWidth: 200, cardColumnWidth: 6,
                valueFormatter: ( params ) => {
                    return enumFormatter(accessEnums, params.value);
                }
            },
            {field: 'id', headerName: '', minWidth: 50, cardHide: true,
                valueGetter: getWholeRow,
                renderCell: (params) => {
                    return sharedAccountsActionsHandler(params.value, false);
                }
            }
        ],
        cardHeaderField: {field: "delegatedUserEmail"},
        rows: [],
        rowCount: -1,
    };

    const closeHandler = () => {
        setMyAccountsEditDialog({ open: false, itemToEdit: myAccountsEditDialog.dataItem });
        setSharedWithMeEditDialog({ open: false, itemToEdit: sharedWithMeEditDialog.dataItem });
        setSharedWithOtherEditDialog({ open: false, itemToEdit: sharedWithOtherEditDialog.dataItem });
    }

    const saveHandler = () => {
        closeHandler();
        setReMutateUrls(true);
    }

    const deleteDialog = (dataItem) => {
        if (!dataItem) {
            return;
        }
        if (!dataItem.formIteration) {
            dataItem.formIteration = "delete" + dataItem.id;
        }
        setMyAccountsEditDialog({ open: true, itemToEdit: dataItem, delete: true });
    }

    const deleteSharedWithMeDialog = (dataItem) => {
        if (!dataItem) {
            return;
        }
        if (!dataItem.formIteration) {
            dataItem.formIteration = "delete" + dataItem.id;
        }
        setSharedWithMeEditDialog({ open: true, itemToEdit: dataItem, delete: true });
    }

    const deleteItem = (item) => {
        deleteDialog(item);
    }

    const deleteSharedWithMe = (item) => {
        deleteSharedWithMeDialog(item);
    }

    const openSharedWithOtherDialog = (dataItem) => {
        if (!dataItem) {
            return;
        }
        if (!dataItem.formIteration) {
            dataItem.formIteration = "edit" + dataItem.id;
        }
        setSharedWithOtherEditDialog({ open: true, itemToEdit: dataItem, delete: false });
    }

    const deleteSharedWithOtherDialog = (dataItem) => {
        if (!dataItem) {
            return;
        }
        if (!dataItem.formIteration) {
            dataItem.formIteration = "delete" + dataItem.id;
        }
        setSharedWithOtherEditDialog({ open: true, itemToEdit: dataItem, delete: true });
    }

    const createSharedWithOtherItem = () => {
        const userDelegation = getMyUserDelegationsDefaultItem();
        userDelegation.delegationId = props.delegationId;
        userDelegation.userId = user.id;
        openSharedWithOtherDialog(userDelegation);
    }
    const deleteSharedWithOtherItem = (dataItem) => {
        deleteSharedWithOtherDialog(dataItem);
    }

    const [openAccountAuthorization, setOpenAccountAuthorization] = React.useState(false);

    const addUserAccount = () => {
        setOpenAccountAuthorization(true);
    }

    const userAccountActions = [
        {
            title: t('Add Account'),
            toolTip: t('Add Account'),
            icon: <AddIcon />,
            onClick: () => { addUserAccount(); },
        }
    ];

    const sharedWithOtherActions = [
        {
            title: props.delegationId ? t('Add User') : t('Share Access'),
            toolTip: props.delegationId ? t('Add User to Delegation') : t('Add Delegation to User'),
            icon: <AddIcon />,
            onClick: () => { createSharedWithOtherItem(); },
        }
    ];

    useLayoutEffect(() => {
        if (reMutateUrls)
        {
            mutateUrls(swrConfig);
            setReMutateUrls(false);
        }
    }, [reMutateUrls, swrConfig]);

    return (
        <EnsureUser permissions={["MyAccount:Read"]}>
            {!props.onlySharedAccounts &&
            <div className="myUserAccountList">
                {!isMobile && <UcToolbar actions={userAccountActions}></UcToolbar>}
                <MyUserAccountFormDialog
                    accountId={accountId}
                    userId={user.id}
                    open={myAccountsEditDialog.open}
                    delete={myAccountsEditDialog.delete}
                    itemToEdit={myAccountsEditDialog.itemToEdit}
                    onCancel={closeHandler}
                    onSave={saveHandler}
                />
                <MyDelegatedAccountFormDialog
                    accountId={accountId}
                    userId={user.id}
                    open={sharedWithMeEditDialog.open}
                    delete={sharedWithMeEditDialog.delete}
                    itemToEdit={sharedWithMeEditDialog.itemToEdit}
                    onCancel={closeHandler}
                    onSave={saveHandler}
                />
                {!reMutateUrls &&
                    <>
                        <MyUserAccountGridStateful
                            isFullPage={props.isFullPage || false}
                            accountId={accountId}
                            userId={user.id}
                            onDeleteItem={deleteItem}
                            onDeleteSharedWithMe={deleteSharedWithMe}
                            defaultDataItems = {defaultDataItemsForAccounts}
                            createItem={addUserAccount}
                            actionsHandler={accountsActionsHandler}
                            onNoResults={() => {
                                if (autoPopup) {
                                    addUserAccount();
                                }
                            }}
                        />
                    </>
                }
            </div>
            }
            {!props.onlyAccounts &&
            <div className="myUserDelegationList">
                {!isMobile && (
                    <>
                        <h3>{t("Shared with Others")}</h3>
                        <UcToolbar actions={sharedWithOtherActions}></UcToolbar>
                    </>
                )}
                <MyUserDelegationFormDialog
                    delegationId={props.delegationId}
                    userId={user.id}
                    open={sharedWithOtherEditDialog.open}
                    delete={sharedWithOtherEditDialog.delete}
                    itemToEdit={sharedWithOtherEditDialog.itemToEdit}
                    onCancel={closeHandler}
                    onSave={saveHandler}
                />
                {!reMutateUrls &&
                    <MyUserDelegationGridStateful
                    isFullPage={props.isFullPage || false}
                    delegationId={props.delegationId}
                    userId={user.id}
                    onDeleteItem={deleteSharedWithOtherItem}
                    defaultDataItems = {defaultDataItemsForSharedAccounts}
                    createItem={createSharedWithOtherItem}
                    actionsHandler={sharedAccountsActionsHandler}
                    />
                }
            </div>
            }
            <AccountAuthorizationDialog
                open={openAccountAuthorization}
                onReset={() => setReMutateUrls(true)}
                onClose={() => {
                    setReMutateUrls(true);
                    setOpenAccountAuthorization(false);
                    if(autoPopup){
                        navigate(`${routePaths.myProfilePage.path}?profileNav_link=Accounts`);
                    }
                }}
            />
        </EnsureUser>
    );
};