import React, { useEffect, useContext } from 'react';
import { PageContext } from 'lib/PageProvider';
import { useTranslation } from "react-i18next";
import { EnvironmentalImpactWidget } from './widgets/EnvironmentalImpactWidget';
import UserBillsWidgetWrapper from './widgets/UserBillsWidgetWrapper';
import { UsageSummaryWidget } from 'components/widgets/UsageSummaryWidget';
import AccountSummaryWidgetWrapper from 'components/widgets/AccountSummaryWidgetWrapper';
import { NotificationsWidget } from 'components/widgets/NotificationsWidget';
import { Grid } from '@mui/material';
import { useResponsive } from 'lib/useResponsive';
import { EnsureAccount } from 'components/customer/accounts/EnsureAccount';
import { useMyOrgs } from 'components/customer/orgs/MyOrgAPIEndpoints';
import { useHasModule } from "components/auth/PermissionApiEndpoint";
import { AccountContext } from 'lib/AccountProvider';
import QuickActionsWidgetWrapper from './widgets/QuickActionsWidgetWrapper';
import { useQuickActions } from "./admin/Dashboard/QuickActionsAPIEndpoints";

export const DashboardPage = () => {
    const { account } = useContext(AccountContext);
    const { t } = useTranslation();
    const { isMobile } = useResponsive();
    const [pageState, pageDispatch] = useContext(PageContext);
    let hasBillingModule = useHasModule("Billing");
    const swrQuickActions = useQuickActions();
    let utilityHasQuickActionTiles = false;

    if (swrQuickActions?.result?.length > 0) {
        utilityHasQuickActionTiles = true;
    }

    const swrMyOrg = useMyOrgs();
    let orgTempUnit = null;
    let orgMeasurementUnit = null;
    let cepOrgId = 0;

    if (!swrMyOrg.isLoading && swrMyOrg.result?.length > 0) {
        orgTempUnit = swrMyOrg.result[0].temperatureUnit;
        orgMeasurementUnit = swrMyOrg.result[0].measurementUnit;
        cepOrgId = swrMyOrg.result[0].cepOrgId;
    }

    useEffect(() => {
        pageDispatch({
            header: t("Dashboard"),
            actionItems: [
            ]
        });
    }, [pageState.header, pageDispatch, t, isMobile]);

    const widgetHeight = '450px';

    return (
        <div className="dashboard" data-cy-id="dashboard">
            <Grid container
                spacing={2}
                direction="row"
                alignItems="stretch"
            >
                {hasBillingModule ? (
                    <>
                        <Grid height={!isMobile && widgetHeight} item xs={12} sm={12} md={6} lg={4}>
                            <UserBillsWidgetWrapper />
                        </Grid>
                        <Grid height={!isMobile && widgetHeight} item xs={12} sm={12} md={6} lg={4}>
                            <AccountSummaryWidgetWrapper account={account}/>
                        </Grid>
                        <Grid height={widgetHeight} item xs={12} sm={12} md={5} lg={4}>
                            <NotificationsWidget />
                        </Grid>
                        <Grid height={!isMobile && widgetHeight} item xs={12} sm={12} md={7} lg={6}>
                            <EnsureAccount>
                                <UsageSummaryWidget
                                    account={account}
                                    orgTempUnit={orgTempUnit}                          
                                />
                            </EnsureAccount>
                        </Grid>
                        <Grid height={!isMobile && widgetHeight} item xs={12} sm={12} md={12} lg={6}>
                            <QuickActionsWidgetWrapper />
                        </Grid>    
                        <Grid height={!isMobile && widgetHeight} item xs={12}>
                            <EnvironmentalImpactWidget
                                account={account}
                                orgMeasurementUnit={orgMeasurementUnit} 
                            />
                        </Grid>    
                    </>
                ) : (
                    <>
                        <Grid height={!isMobile && widgetHeight} item xs={12} sm={12} md={6} lg={4}>
                            <AccountSummaryWidgetWrapper account={account}/>
                        </Grid>
                        <Grid height={!isMobile && widgetHeight} item xs={12} sm={12} md={6} lg={8}>
                            <EnsureAccount>
                                <UsageSummaryWidget
                                    account={account}
                                    orgTempUnit={orgTempUnit}                          
                                />
                            </EnsureAccount>
                        </Grid>
                        {utilityHasQuickActionTiles ? ( 
                            <>
                                <Grid height={!isMobile && widgetHeight} item xs={12} sm={12} md={7}>
                                    <QuickActionsWidgetWrapper
                                        cepOrgId={cepOrgId}
                                    />
                                </Grid>
                                <Grid height={widgetHeight} item xs={12} sm={12} md={5}>
                                    <NotificationsWidget />
                                </Grid>
                                <Grid height={!isMobile && widgetHeight} item xs={12}>
                                    <EnvironmentalImpactWidget
                                        account={account}
                                        orgMeasurementUnit={orgMeasurementUnit}
                                    />
                                </Grid>  
                            </>
                        ) : (
                            <>
                                <Grid height={!isMobile && widgetHeight} item xs={12} sm={12} md={8}>
                                    <EnvironmentalImpactWidget
                                        account={account}
                                        orgMeasurementUnit={orgMeasurementUnit}
                                    />
                                </Grid>  
                                <Grid height={widgetHeight} item xs={12} sm={12} md={4}>
                                    <NotificationsWidget />
                                </Grid>
                            </>
                        )}
                    </>
                )}
            </Grid>
        </div>
    );
}
