import { UserListPage } from "./UserListPage";
import {useEffect, useContext} from 'react';
import {PageContext} from '../../../lib/PageProvider';
import {useTranslation} from 'react-i18next';

export const SuperAdminUserListPage = () => {
    const [pageState, pageDispatch] = useContext(PageContext);
    const { t } = useTranslation();

    useEffect(() => {
        pageDispatch({
            header: t("All Users"),
        });
    }, [pageState.header, pageDispatch, t]);

    return (
        /*user permission is to check for regular user permissions, and Org permission is used 
        to make sure the current user is a super admin*/
        <UserListPage type={'SuperAdmin'} permissions={["User:Read", "Org:Read"]}/>
    );
};