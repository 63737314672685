import React, { useState, useLayoutEffect, useRef } from 'react';
import { DataGridPro } from '@mui/x-data-grid-pro';
import { useRoles } from './RoleAPIEndpoints';
import { UcPagination } from 'components/common/UcPagination';
import { useTranslation } from "react-i18next";
import { UcIconButtons } from 'components/common/UcIconButtons';
import { getWholeRow } from '../../../lib/DataGridHelpers';
import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/Delete';
import ListIcon from '@mui/icons-material/List';

export const RoleGrid = (props) => {
    const { t } = useTranslation();
    const [generalSearchInput] = useState(props.generalSearch || "");
    const initialSort = [{ field: "name", sort: "asc" }];
    const sort = props.sort || initialSort;

    const initialDataState = props.dataState || { skip: 0, take: 10, };

    const [filter, setFilter] = useState({
        take: initialDataState.take,
        skip: initialDataState.skip,
        sort: sort,
        generalSearch: generalSearchInput,
        // roleType: 'custom' // TODO: Decide if we only want to show the new Custom Role Type here.
    });

    let initialFilterModel = {
        items: [
            { columnField: 'name', operatorValue: 'contains', value: '' }
        ],
        linkOperator: 'and',
    };

    if (generalSearchInput) {
        initialFilterModel = {
            items: [
                { columnField: 'name', operatorValue: 'contains', value: generalSearchInput }
            ],
            linkOperator: 'and',
        }
    }

    const isBuiltInRole = (role) => {
        return (role?.roleType?.toLowerCase() !== 'custom');
    }

    const [filterModel] = useState(initialFilterModel);

    const needsDataUpdate = useRef("");

    let defaultDataItems = {
        columnVisibilityModel: {
            cepOrgId: false,
        },
        columns: [
            {
                field: 'details', headerName: '', width: 40,
                valueGetter: getWholeRow,
                renderCell: (params) => {
                    const actions = [
                        {
                            title: t('Roles Details'),
                            icon: <ListIcon data-cy-id={"rolesDetailsButton"} />,
                            color: 'primary',
                            isHidden: false,
                            isDisabled: false,
                            requiredPermission: 'Role:Read',
                            onClick: () => {
                                props.setDrawerOpen(true);
                            }
                        },
                    ];
                    return (
                        <UcIconButtons actions={actions} />
                    );
                }
            },
            { field: 'cepOrgId', headerName: t('CEP Org ID'), width: 200 },
            { field: 'name', headerName: t('Name'), width: 200 },
            { field: 'description', headerName: t('Description'), width: 200 },
            { field: 'isDefault', headerName: t('Is Default'), width: 200, valueFormatter: (params) => { return params.value ? t("Yes") : t("No") } },
            {
                field: 'id', headerName: '',
                valueGetter: getWholeRow,
                renderCell: (params) => {
                    const actions = [
                        {
                            title: t('Edit Role'),
                            icon: <EditIcon />,
                            color: 'primary',
                            isHidden: false,
                            isDisabled: (isBuiltInRole(params.value)),
                            requiredPermission: 'Role:Modify',
                            onClick: () => { props.onEditItem(params.value); },
                        },
                        {
                            title: t('Delete Role'),
                            icon: <DeleteIcon />,
                            color: 'error',
                            isHidden: false,
                            isDisabled: (isBuiltInRole(params.value)),
                            requiredPermission: 'Role:Delete',
                            onClick: () => { props.onDeleteItem(params.value); },
                        }
                    ];

                    return (
                        <UcIconButtons actions={actions} />
                    );
                }
            },
        ],
        rows: [],
        rowCount: -1,
    };
    const [dataItems, setDataItems] = useState(defaultDataItems);

    const defaultSelectedItem = { dataItem: {}, selectedId: 0 };
    const [selectedItem, setSelectedItem] = useState(defaultSelectedItem);

    const isDataLoaded = useRef(false);

    const dataReceived = (swrResult) => {
        var dataItems = swrResult.result;

        var selectedDataItems = dataItems.map((item) => {
            return { ...item, selected: item.id === selectedItem.selectedId };
        });

        selectedDataItems.iteration = dataItems.iteration;
        selectedDataItems.rowCount = swrResult.rowCount;

        if (selectedDataItems.length > selectedDataItems.rowCount) {
            selectedDataItems.rowCount = selectedDataItems.length;
        }

        setDataItems({ ...defaultDataItems, rows: [...dataItems], rowCount: swrResult.rowCount });
    };

    const swrRoles = useRoles(filter); //Pass the datastate directly as the filter.  The API will adjust filters, orderbys ect.
    if (!swrRoles.result && defaultDataItems.rowCount !== -1) {
        setDataItems(defaultDataItems);
    }

    useLayoutEffect(() => {
        if (swrRoles.result) {
            isDataLoaded.current = true;
            var filterString = JSON.stringify(filter);
            if (needsDataUpdate.current !== filterString) {
                needsDataUpdate.current = filterString;
                dataReceived(swrRoles);
            }
        }
    });

    const updateFilter = (filter) => {
        setFilter(filter);
        props.onFilterChange(filter);
    };

    return (
        <div
            data-cy-id={"roleUserGridWrapper"}
            onDoubleClick={props.doubleClick}
        >
            <DataGridPro
                autoHeight={true}
                disableColumnFilter={true}
                disableColumnSelector={true}
                loading={!isDataLoaded.current}
                paginationModel={{page: filter.skip / filter.take, pageSize: filter.take}}
                onPaginationModelChange={(model) => updateFilter({ ...filter, skip: model.page * model.pageSize, take: model.pageSize })}nge={(newPageSize) => updateFilter({ ...filter, take: newPageSize })}

                sortingMode='server'
                paginationMode='server'
                filterMode='server'
                sortModel={filter.sort}
                onSortModelChange={(model) => updateFilter({ ...filter, sort: model })}
                filterModel={filterModel}

                rowsPerPageOptions={[5, 10, 20]}
                onRowSelectionModelChange={(newSelectionModel) => {
                    if (newSelectionModel.length !== 1) {
                        setSelectedItem(defaultSelectedItem);
                        props.onSelected(defaultSelectedItem.dataItem);
                        return;
                    }
                    const selectedId = newSelectionModel[0];
                    var selectedItem = dataItems.rows.filter(obj => { return obj.id === selectedId });
                    if (selectedItem.length !== 1) {
                        setSelectedItem(defaultSelectedItem);
                        props.onSelected(defaultSelectedItem.dataItem);
                        return;
                    }
                    selectedItem = selectedItem[0];
                    var newlySelectedItem = { dataItem: selectedItem, selectedId: selectedId };

                    setSelectedItem(newlySelectedItem);
                    props.onSelected(newlySelectedItem.dataItem);
                }}
                pagination
                components={{
                    Toolbar: () => {return  props.toolbar || <></>},
                    Pagination: UcPagination,
                }}
                {...dataItems}
                initialState={{ pinnedColumns: { right: ['id'] } }}
            />
        </div>
    );
}

RoleGrid.defaultProps = {
    onSelected: (selectedItem) => { console.log("onSelected not implemented", selectedItem); },
    onFilterChange: (filter) => { console.log("onFilterChange not implemented", filter); },
};
