import React, { useLayoutEffect, useState} from "react";
import { useSearchParams } from "react-router-dom";
import { UcTabStrip } from "./UcTabStrip";

export const UcTabStripStateful = (props) => {
    const tabQueryName = (props.id || "tabStrip") + "_tab";
    const [searchParams, setSearchParams] = useSearchParams();
    const [isRenderReady, setIsRenderReady] = useState(true);
    const getValueQuery = () => {
        let defaultValue = props.value || 0;

        let valueStr = searchParams.get(tabQueryName);
        if (!valueStr) {
            return defaultValue;
        }
        return valueStr;
    };
    let valueQueryTab = getValueQuery();
    const [value, setValue] = useState(valueQueryTab);

    const handleChange = (newValue) => {
        // Only update the query params
        if (value !== newValue) {
            searchParams.set(tabQueryName, newValue);
            setSearchParams(searchParams);
        }
    };

    // this will get called on any render (and back / forward)
    useLayoutEffect(() => {
        if (valueQueryTab !== value && isRenderReady)
        {
            setIsRenderReady(false);
        }

        if (!isRenderReady)
        {
            setIsRenderReady(true);
            setValue(valueQueryTab);
        }
    }, [searchParams, valueQueryTab, value, setValue, isRenderReady, setIsRenderReady]);

    return (
        <>
            {isRenderReady &&
            <UcTabStrip
                onChange={handleChange}
                {...props}
                // important to have value at the end... this will override the props
                // props with stateful will only define the default if no query param exists
                value={value} />
            }
        </>
    );
};