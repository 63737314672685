// @ts-ignore
import { AccountContext } from "../../../lib/AccountProvider";
// @ts-ignore
import { ApiClient } from "../../../lib/ApiClient";
import { useGenericSWR } from "../../../lib/useGenericSWR";
import { SwrOptions } from '../../../lib/useGenericSWR.interfaces';
import { FetcherOptions } from "../../readings/FetcherOptions";
import React, { useEffect, useContext, useState } from "react";
import { UcProgressStats } from "../../common/UcProgress";

export const apiEndpoint = 'api/v1/MyBills';
export const apiEndpointPDF = 'api/v1/MyBills/getPDF';

let formIteration = 0;

const getMyBillsDefaultItem = () => {
    formIteration = formIteration + 1;
    return {
        formIteration: "new" + formIteration,
        id: 0,
        accountAddress: '',
        accountNumber: '',
        balanceForward: 0,
        totalAmountDue: 0
    }
};

const apiClient = new ApiClient();
apiClient.getDefaultItem = () => getMyBillsDefaultItem();
apiClient.getObjectName = () => {
    return "My Bills"
};

const useMyBillsAccount = (accountNumber: string | undefined, swrOptions = {} as SwrOptions) => {
    // SWR will prevent duplicate requests
    const params = new URLSearchParams();
    if (accountNumber) {
        params.append('accountNumber', accountNumber);
    }

    return useGenericSWR(apiEndpoint + "?" + params.toString(), apiClient, { ...swrOptions });
};

const useMyBills = ((options: UseMyBillsOptions, readyForData: boolean) => {
    // SWR will prevent duplicate requests
    const params = new URLSearchParams();
    if (options.accountNumber) {
        params.append('accountNumber', options.accountNumber);
    }

    const swrOptions = options.swrOptions || {};
    let url = apiEndpoint + "?" + params.toString();
    if (!readyForData) {
        url = "";
    }

    return useGenericSWR(url, apiClient, { ...swrOptions });
});

const useBillPDF = (shouldFetch: boolean, accountNumber: string | undefined, fileName: string, swrOptions = {} as SwrOptions) => {
    // SWR will prevent duplicate requests
    const params = new URLSearchParams();

    if(accountNumber !== undefined){
        params.append('accountNumber', accountNumber);
    }

    return useGenericSWR(shouldFetch ? apiEndpointPDF + "/" + encodeURIComponent(fileName) + "?" + params.toString() : null, apiClient, { ...swrOptions });
};

interface MyBillsWrapperOptions extends FetcherOptions {

}
const MyBillsAPIWrapper = (options: MyBillsWrapperOptions) => {
    const { account } = useContext(AccountContext);
    const accountNumber = account?.extAccountNumber;

    const [hasError, setHasError] = useState(false);

    if (accountNumber === null || !options.readyForData) {
        return <></>
    }

    return (<MyBillsWrapper
        accountNumber={accountNumber}
        readyForData={!hasError}
        onDataCompleted={(newData) => {
            options?.onDataCompleted(newData);
            setHasError(false);
        }}
        onProgress={(progressLocal) => {
            options?.onProgress(progressLocal);
        }}
        onReset={() => {
            options?.onReset();
            setHasError(false);
        }}
        onError={(error) => {
            console.error(error);
            options?.onError(error);
            setHasError(true);
        }}
    />);
}

interface MyBillsAPIWrapperOptions extends FetcherOptions, UseMyBillsOptions {

}

const MyBillsWrapper = (options: MyBillsAPIWrapperOptions) => {
    const [prevOptions, setPrevOptions] = React.useState(options);
    const [progress, setProgress] = React.useState<UcProgressStats>({ current: 0, total: 100 });

    useEffect(() => {
        const haveOptionsChanged = () => {

            const accountNumber = options.accountNumber;
            const prevAccountNumber = prevOptions.accountNumber;

            const result = ((options.accountNumber ?? 0) !== (prevOptions.accountNumber ?? 0)) ||
                ((accountNumber ?? 0) !== (prevAccountNumber ?? 0))

            return result;
        }

        if (haveOptionsChanged()) {
            setPrevOptions({ ...options });
            const newProgress = { current: 1, total: 100 };
            setProgress(newProgress); // reset the progress
            if (options.onReset) {
                options.onReset();
            }
        }
    }, [options, prevOptions.accountNumber]);


    const data = useMyBills(options, options.readyForData);

    useEffect(() => {
        const interval = setInterval(() => {
            if (progress.current >= progress.total) {
                clearInterval(interval);
                return;
            }
            // Make it so it is never 100%.. this is a fake progress
            const newProgress = { current: progress.current + 1, total: progress.total + 1 };
            setProgress(newProgress);
            if (options.onProgress) {
                options.onProgress(newProgress);
            }
        }, 200);
        return () => { clearInterval(interval); }
    }, [options, progress]);

    React.useEffect(() => {
        if (!data.error && !data.isLoading && options.readyForData && progress.current !== 1 && progress.total !== 1) {
            // data.result?.map()
            // Need to find a way to map data
            if (options.onDataCompleted) {
                options.onDataCompleted(data.result);
            }
            const newProgress = { current: 1, total: 1 };
            setProgress(newProgress); // just complete the progress
            if (options.onProgress) {
                options.onProgress(newProgress);
            }
        }
        else if (data.error && !data.isLoading && options.readyForData && progress.current !== 1 && progress.total !== 1) {
            if (options.onError) {
                options.onError(data.error);
            }
            const newProgress = { current: 1, total: 1 };
            setProgress(newProgress); // just complete the progress
            if (options.onProgress) {
                options.onProgress(newProgress);
            }
        }
    }, [data, options, progress]);


    return null;
};
interface UseMyBillsOptions {
    accountNumber: string | undefined,
    swrOptions?: any,
}

export {
    useMyBills,
    useMyBillsAccount,
    useBillPDF,
    getMyBillsDefaultItem,
    MyBillsAPIWrapper
};