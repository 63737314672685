import { ToggleButton, ToggleButtonGroup } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";

export interface UcDateToggleProps {
    onChange: (type: string) => void;
    initialValue?: "day" | "month" | "year";
}

export default function UcDateToggle(props: Readonly<UcDateToggleProps>) {
    const { t } = useTranslation();
    const [value, setValue] = React.useState(props.initialValue || "day");

    const handleChangeChartType = (
        event: React.MouseEvent<HTMLElement, MouseEvent>,
        value: any
    ) => {
        if (value !== null) {
            setValue(value);
            props.onChange(value);
        }
    };

    return (
        <ToggleButtonGroup
            value={value}
            size="small"
            onChange={handleChangeChartType}
            aria-label="ChartType"
            exclusive={true}
        >
            <ToggleButton color="primary" value="day" data-cy-id={"ucDateToggle-day"}>
                {t("Day")}
            </ToggleButton>
            <ToggleButton color="primary" value="month" data-cy-id={"ucDateToggle-month"}>
                {t("Month")}
            </ToggleButton>
            <ToggleButton color="primary" value="year" data-cy-id={"ucDateToggle-year"}>
                {t("Year")}
            </ToggleButton>
        </ToggleButtonGroup>
    );
}
