import React from "react";
import { CircularProgress, Box, Button, Grid, Typography, Avatar } from "@mui/material";
import EditIcon from '@mui/icons-material/Edit';
import KeyIcon from '@mui/icons-material/Key';
import { useMyUser, saveMyUser } from './MyUserAPIEndpoints';
import { MyUserFormDialog } from './MyUserFormDialog';
import { MyUserForm } from './MyUserForm';
import { useTranslation } from "react-i18next";
import { appendDomainToUrlStr, getFullUrl, domain } from "../../domain";
import { useResponsive } from 'lib/useResponsive';
import { mutateUrls } from "lib/useGenericSWR";
import { useSWRConfig } from "swr";
import { UcThemeContext } from '../../lib/UcThemeProvider';
import { UserInfoContext } from "../../lib/UserInfoProvider";

export const MyUser = () => {
    const { t } = useTranslation();
    const { isMobile } = useResponsive();
    const [themeState, ] = React.useContext(UcThemeContext);
    const swrConfig = useSWRConfig();
    const [editDialog, setEditDialog] = React.useState({ open: false });
    const [reMutateUrls, setReMutateUrls] = React.useState(false);

    const swrMyUser = useMyUser();
    const subDomain = domain.subDomain;
    const { userInfo } = React.useContext(UserInfoContext);

    let itemToEdit = null;
    if (!swrMyUser.isLoading && swrMyUser.result) {
        itemToEdit = { ...swrMyUser.result };
    }

    const goToManagePassword = () => {
        const authorityUrlPath = appendDomainToUrlStr(`${process.env.REACT_APP_UCAUTH_AUTHORITY}/account/manage/password?userThemePreference=${themeState.themeMode}`);
        window.location = `${authorityUrlPath}&ReturnUrl=${encodeURIComponent(getFullUrl('/myProfile'))}`;

        if (domain.subDomain) {
            window.location = `${authorityUrlPath}&subdomain=${subDomain}&ReturnUrl=${encodeURIComponent(getFullUrl('/myProfile'))}`;
        }
    }

    const closeHandler = () => {
        setEditDialog({ open: false, itemToEdit: editDialog.dataItem });
    }

    const saveHandler = () => {
        closeHandler();
        setReMutateUrls(true);
    }

    const openDialog = (dataItem) => {
        if (!dataItem) {
            return;
        }
        if (!dataItem.formIteration) {
            dataItem.formIteration = "edit" + dataItem.id;
        }
        setEditDialog({ open: true, itemToEdit: dataItem });
    }

    React.useLayoutEffect(() => {
        if (reMutateUrls)
        {
            mutateUrls(swrConfig);
            setReMutateUrls(false);
        }
    }, [reMutateUrls, swrConfig]);

    return (
        <>
            {!swrMyUser.isLoading && swrMyUser.result && !userInfo.isLoading && (
                <>
                { isMobile && (
                    <MyUserForm
                        userId={itemToEdit?.id}
                        itemToEdit={itemToEdit}
                        dialogSubmit={0}
                        goToManagePassword={goToManagePassword}
                        onSubmit={async (values) => {
                            let data = await saveMyUser(values);

                            if (data) {
                                saveHandler();
                            }
                        }}
                    />
                )}
                { !isMobile && (
                    <>
                    <MyUserFormDialog
                       open={editDialog.open}
                       userId={itemToEdit?.id}
                       itemToEdit={editDialog?.itemToEdit}
                       onCancel={closeHandler}
                       onSave={saveHandler}
                   />
                   <Grid container sx={{ justifyContent: 'center', alignItems: 'center', display:'flex' }}>
                       <Grid item xs={12} sm={12} md={6}>
                           <Avatar sx={{ width: 100, height: 100, marginBottom: 3 }} />
                           <Grid container spacing={2}>
                               <Grid item xs={6}>
                                   <Typography variant="h6" gutterBottom>{t("Details")}</Typography>
                               </Grid>
                               <Grid item xs={6} style={{textAlign: 'right'}}>
                                   <Button
                                       size="small"
                                       data-cy-id={"editProfileButton"}
                                       sx={{minWidth: 'auto'}}
                                       variant="contained"
                                       color="primary"
                                       onClick={() => openDialog(itemToEdit)}
                                       >
                                        <EditIcon fontSize="small" />
                                   </Button>
                               </Grid>
                               <Grid item xs={12} sm={12} md={12}>
                                   <Typography variant="caption" display="block" gutterBottom>
                                       {t("Full Name")}
                                   </Typography>
                                   <Typography variant="body1" display="block" gutterBottom data-cy-id="user-full-name">
                                       {itemToEdit?.firstName} {itemToEdit?.middleName} {itemToEdit?.lastName}
                                   </Typography>
                               </Grid>
                               <Grid item xs={12} sm={12} md={12}>
                                   <Typography variant="caption" display="block" gutterBottom>
                                       {t("Email")}
                                   </Typography>
                                   <Typography variant="body1" display="block" gutterBottom data-cy-id="user-email">
                                       {itemToEdit?.email}
                                   </Typography>
                               </Grid>
                               <Grid item xs={12} sm={12} md={12}>
                                   <Typography variant="caption" display="block" gutterBottom>
                                       {t("Phone Number")}
                                   </Typography>
                                   <Typography variant="body1" display="block" gutterBottom data-cy-id="user-phone-number">
                                       {itemToEdit?.phoneNumber}
                                   </Typography>
                               </Grid>
                               <Grid item xs={12} sm={12} md={12}>
                                   <Typography variant="caption" display="block" gutterBottom>
                                       {t("Address")}
                                   </Typography>
                                   <Typography variant="body1" display="block" gutterBottom data-cy-id="user-address">
                                       {itemToEdit?.address}
                                   </Typography>
                               </Grid>
                               <Grid item xs={12} sm={12} md={12}>
                                   <Button fullWidth
                                       variant="contained"
                                       data-cy-id="user-change-password"
                                       onClick={goToManagePassword}
                                       endIcon={<KeyIcon />}
                                   >
                                       {t("Change Password")}
                                   </Button>
                               </Grid>
                           </Grid>
                       </Grid>
                   </Grid>
                   </>
                )}
                </>
            )}
            {swrMyUser.isLoading &&
                <Box sx={{ textAlign: 'center' }}>
                    <CircularProgress size={50} />
                </Box>
            }
        </>
    );
}