import { ApiClient } from "../../lib/ApiClient";
import { useGenericSWR } from "../../lib/useGenericSWR";
import * as Yup from 'yup';

const apiEndpoint = 'api/v1/UserNotificationPreference';
let formIteration = 0;

const getUserNotificationPreferenceDefaultItem = () => {
    formIteration = formIteration + 1;
    return {
        formIteration: "new" + formIteration,
        id: 0,
        accountMeterId: -1,
        accountName: "",
        extAccountNumber: "",
        startTimestamp: "",
        name: '',
        serialNo: '',
        username: '',
        frequency: 'Daily',
        notificationType: 'Money',
        value: 0.00,
        createdBy: 'REACT_FE',
        updatedBy: 'REACT_FE',
        showBillValue: true
    }
};

const convertUserNotificationPrefToAccount = (userNotificationPref) => {
    return {
        id: userNotificationPref.accountId,
        address: userNotificationPref.accountAddress,
        name: userNotificationPref.accountName,
        cepOrgId: userNotificationPref.cepOrgId,
        udmOrgId: userNotificationPref.udmOrgId,
        extSource: userNotificationPref.extSource,
        extAccountId: userNotificationPref.extAccountId,
        extAccountNumber: userNotificationPref.extAccountNumber
    };
}

const UserNotificationPreferenceValidationSchema = Yup.object().shape({
    accountId: Yup.number().required("Required").integer(),
    name: Yup.string().required("Required"),
    frequency: Yup.string().required("Required"),
    notificationType: Yup.string().required("Required"),
    value: Yup.number().when('notificationType', {
        is: (notificationType) => (notificationType === "NewBill" || notificationType === "BillAlmostDue"),
        then: Yup.number(),
        otherwise: Yup.number().required('Required')
            .min(0.01, 'Value must be greater than 0.01'),
    })     
});

const apiClient = new ApiClient();
apiClient.getDefaultItem = () => getUserNotificationPreferenceDefaultItem();
apiClient.getObjectName = () => {
    return "My User Notification Preferences";
};

const useUserNotificationPreferences = (filter, swrOptions = {}) => {
    const params = new URLSearchParams();
    params.append('filter', JSON.stringify(filter));
    return useGenericSWR(apiEndpoint + "?" + params.toString(), apiClient, { ...swrOptions });
};

const saveUserNotificationPreference = (UserNotificationPreference) => {
    if (UserNotificationPreference.id === 0) {
        return insertUserNotificationPreference(UserNotificationPreference);
    }
    return updateUserNotificationPreference(UserNotificationPreference);
}
const insertUserNotificationPreference = (UserNotificationPreference) => {
    return apiClient.poster(apiEndpoint, UserNotificationPreference);
};

const updateUserNotificationPreference = (UserNotificationPreference) => {
    return apiClient.putter(apiEndpoint, UserNotificationPreference);
};

const deleteUserNotificationPreference = (UserNotificationPreference) => {
    return apiClient.deleter(apiEndpoint + "/" + UserNotificationPreference.id);
};

export {
    getUserNotificationPreferenceDefaultItem,
    convertUserNotificationPrefToAccount,
    UserNotificationPreferenceValidationSchema,
    useUserNotificationPreferences,
    saveUserNotificationPreference,
    insertUserNotificationPreference,
    updateUserNotificationPreference,
    deleteUserNotificationPreference
};