import { Button } from '@mui/material';
import { UcWidget } from '../common/UcWidget';
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import UserBillsWidget from './UserBillsWidget'
import { ActionItem, WidgetWrapperProps } from "../common/Common.interfaces";
// @ts-ignore
import { routePaths } from "routes";
import { useTranslation } from "react-i18next";
// @ts-ignore
import { useDomainNavigate } from "domain";

export default function UserBillsWidgetWrapper(props: WidgetWrapperProps) {
    const navigate = useDomainNavigate();
    const { t } = useTranslation();

    const handleClickGoToBilling = () => {
        navigate(routePaths.myBillsListPage.path);
    };

    const actionItems: ActionItem[] = [
        {
            component: (
                <Button
                    color={"info"}
                    onClick={handleClickGoToBilling}
                    size={"small"}
                    endIcon={<ChevronRightIcon />}
                >
                    {t("View More")}
                </Button>
            ),
        },
    ];

    return (!props.hideWidgetBorder) ? (
        <UcWidget
            actionItems={props.hideWidgetActionItems ? [] : actionItems}
            minHeight={"auto"}
            title={!props.hideWidgetTitle ? t("Billing Summary") : ""}
            alignTitleLeft={true}
            contentPadding={"1rem"}
        >
            <UserBillsWidget {...props} />
        </UcWidget>
  ) : (
            <UserBillsWidget {...props} />
  )
}
