import React from "react";
import { getOneSignalInfo } from '../../lib/OneSignalInfo';
import { pushOneSignalNotification } from './DevApiEndpoints';
import { Divider, Typography, TextField } from "@mui/material";
import LoadingButton from '@mui/lab/LoadingButton';

export const DevOneSignalInfo = (props) => {
    const oneSignalInfo = getOneSignalInfo();
    const [pushing, setPushing] = React.useState(false);
    const [oneSignalTextField, setOneSignalTextField] = React.useState("");

    const handlePushNotification = async () => {
        try {
            setPushing(true);
            if (window.gonative) {
                await pushOneSignalNotification(oneSignalInfo.info.oneSignalUserId);
            }
            else {
                await pushOneSignalNotification(oneSignalTextField);
            }
        }
        catch (err) {
            console.log(err);
        }
        finally {
            setPushing(false);
        }
    }
    const handleRefreshCache = () => {
        if (window.gonative) {
            window.gonative.webview.clearCache();
        }
        else {
            alert("GoNative is undefined");
        }
    }

    return (
        <>
            <LoadingButton loading={pushing} onClick={handlePushNotification} variant="contained" size="small" sx={{display: 'block'}}>
                Push Test Notification (v6)
            </LoadingButton>
            <p />
            <TextField label="oneSignalUserId" variant="outlined" size="small"
                value={oneSignalTextField}
                onChange={(e) => { setOneSignalTextField(e.target.value) }}
            />
            <p />
            <LoadingButton loading={false} onClick={handleRefreshCache} variant="outlined" size="small">
                Refresh Cache
            </LoadingButton>

            {window.gonative &&
                <>
                    <Typography variant="subtitle1" gutterBottom>Info:</Typography>
                    {Object.keys(oneSignalInfo.info).map((key, index) => {
                        return (
                            <div key={index}>
                                <Typography variant="body1" gutterBottom>
                                    {key}: {oneSignalInfo.info[key]}
                                </Typography>
                                <Divider />
                            </div>
                        );
                    })}
                </>
            }
            {!window.gonative &&
                <>
                    <div>window.gonative is undefined<div>{JSON.stringify(oneSignalInfo)}</div></div>
                </>
            }
        </>
    );
};