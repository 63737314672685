import React, { useEffect, useContext } from 'react';
import { PageContext } from '../lib/PageProvider';
import { SignOutButton } from './auth/SignOutButton';
import { EnsureUser } from './auth/EnsureUser';
import { useAuthUser } from './auth/useAuthUser';
import { DateTime } from 'luxon';
import { useTranslation } from "react-i18next";

export const HomePage = (props) => {
    const { t } = useTranslation();

    const [pageState, pageDispatch] = useContext(PageContext);
    useEffect(() => {
        pageDispatch({
            header: "Home",
            actionItems: [
            ]
        });
    }, [pageState.header, pageDispatch]);

    const user = useAuthUser();

    return (
        <div className="home">
            <h1 className="home-h1">{t('helloWorld')}</h1>
            <p>{t('welcomeText')} {process.env.NODE_ENV}</p>
            <EnsureUser>
                <>
                    Welcome {user.username} (using ensure user component) expires: {DateTime.fromJSDate(new Date()).toLocal().toLocaleString(DateTime.DATETIME_MED_WITH_SECONDS)} {user.expiresAt.toLocal().toLocaleString(DateTime.DATETIME_MED_WITH_SECONDS)} <SignOutButton />
                </>
            </EnsureUser>
        </div>
    );
}
