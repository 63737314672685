import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import { currencyFormatter } from './Formatters';

export const CostEstimateTooltip = ({ active, payload, label }) => {

    if (active && payload && payload.length) {
        return (
            <List className='costEstimateTooltip'
                sx={{
                    minWidth: '150px',
                    bgcolor: 'background.paper',
                    border: '1px solid #cccccc',
                    borderRadius: '10px'
                }}
                component="nav"
            >
                <ListItem>
                    <Typography gutterBottom variant="h7" component="p" sx={{ pl: '2px' }}>
                        <b>{label}</b>
                    </Typography>
                </ListItem>
                <Divider />

                {payload.map(item =>
                    <>
                        <ListItem>
                            <ListItemText
                                sx={{
                                    color: item.color
                                }}
                                primary={currencyFormatter(item.value, 2)}
                                secondary={item.name}
                            />
                        </ListItem>
                    </>
                )}
           </List>
        );
    }
 };