import * as React from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete, { AutocompleteProps } from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
//@ts-ignore
import { useMyAccounts } from './MyAccountAPIEndpoints';
import { useTranslation } from "react-i18next";
import { Account } from '../../../lib/AccountProvider';

interface MyAccountSelectCustomProps {
    value?: Account | null,
    allowCustomInput?: boolean,
    allowDelegations?: boolean,
    disableClearable?: boolean,
    showAddressOnly?: boolean,
    showNameOnly?: boolean,
    showAccountNumberOnly?: boolean,
    userEmail?: string,
    id?: string,
    size?: AutocompleteSize,
    sx?: any,
    isDisabled?: boolean,
    initialValue: any,
    label: string,
    showFullLabel?: boolean,
    onChange: Function,
    onInputChange: Function,
    
}

type AutocompleteSize = AutocompleteProps<any, any, any, any>['size'];

export const MyAccountSelectCustom = (props: MyAccountSelectCustomProps) => {
    const [options, setOptions] = React.useState<any[]>([]);
    const [value, setValue] = React.useState<any>(props.value ?? null);
    const needsUpdate = React.useRef(true);
    const firstValueSet = React.useRef(false);
    const { t } = useTranslation();

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [filter, setFilter] = React.useState({
        take: 100,
        skip: 0,
        sort: [{ field: "accountName", sort: "asc" }],
        generalSearch: "",
        allowDelegations: props.allowDelegations ?? false
    });

    let loading = true;
    let loadedOptions: any[] = [];
    const swrMyAccounts = useMyAccounts(filter);

    if (swrMyAccounts.result && !swrMyAccounts.isLoading && !swrMyAccounts.isError)
    {
        loadedOptions = swrMyAccounts.result;
        loading = false;

        if (needsUpdate.current) {
            needsUpdate.current = false;
            setOptions([...loadedOptions]);         
        }
    }
    else
    {
        needsUpdate.current = true;
        loading = true;
    }

    const propOnChange = props.onChange;

    React.useEffect(() => {
        if (!value && options && options.length >= 1 && !firstValueSet.current) {
            firstValueSet.current = true;
            if (props?.initialValue?.id) {
                setValue(props.initialValue);
                propOnChange(null, props.initialValue);
            }
            else {
                setValue(options[0]);
                propOnChange(null, options[0]);
            }         
        }
    }, [options, value, propOnChange, props.initialValue]);

    const getOptionLabel = (option: any) => {
        let addressString = (option.accountAddress ?? "N/A");
        let accountNumber = option.extAccountNumber    
        let dropdownString = option.accountName + " - " + addressString;
        
        if (props.showNameOnly) {
            dropdownString = option.accountName;
        }
        else if (props.showAddressOnly) {
            dropdownString = addressString;
        }

        if (props.showAccountNumberOnly) {
            dropdownString = accountNumber;
        }
        
        if (option.delegatesJson !== undefined) {
            if (option.username?.toLowerCase() !== props.userEmail?.toLowerCase()) {
                dropdownString += " (" + t("Delegate") + ") ";
            }           
        }

        return dropdownString;
    }

    return (
        <Autocomplete
            freeSolo={props.allowCustomInput}
            disableClearable={props.disableClearable}
            disabled={props.isDisabled}
            id={props.id ?? "myAccountSelectCustom"}
            size={props.size ?? 'medium'}
            onChange={(event, newValue) => {
                setValue(newValue);
                props.onChange(event, newValue);
            }}
            onInputChange={(event, newValue) => {
                props.onInputChange(event, newValue)
            }}
            getOptionLabel={getOptionLabel}
            value={value}
            options={options}
            loading={loading}
            renderInput={(params) => (    
                <TextField
                    {...params}
                    sx={props.sx}
                    label={props.label || "My Accounts"}
                    title={value ? getOptionLabel(value) : ''}
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                        <React.Fragment>
                            {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                {params.InputProps.endAdornment}
                        </React.Fragment>
                        ),
                    }}
                />
            )}
        />
    );
};

MyAccountSelectCustom.defaultProps = {
    onChange: (event: any, value: any) => { console.log("MyAccountSelectCustom onChange not implemented", event, value); },
};