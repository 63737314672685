import { usePermissions } from "components/auth/PermissionApiEndpoint";
import { ApiClient } from "lib/ApiClient";
import React from "react";
import { UserInfoContext } from "../../lib/UserInfoProvider";

const apiEndpoint = 'api/v1/UserPreference';

const apiClient = new ApiClient();
apiClient.getDefaultItem = () => { return { name: "", type: "", value: "" }; };
apiClient.getObjectName = () => {
    return "User Preference";
};

let userInfoCepOrgId = 0;

const useUserPreferences = () => {
    const permissions = usePermissions();
    const { userInfo } = React.useContext(UserInfoContext);
    userInfoCepOrgId = userInfo.cepOrgId;

    return {
        isLoading: permissions.isLoading,
        isError: permissions.isError,
        result: permissions.result?.userPreferences,
    }
};

const updateUserPreference = (name, value) => {
    let userPref = {
        cepOrgId: userInfoCepOrgId,
        name: name,
        value: value
    };

    return apiClient.poster(apiEndpoint, userPref, "", null, false, null, false);
};

const useUserPreference = (preferenceName) => {
    const userPreferences = useUserPreferences();

    const result = userPreferences.result?.find(x => x.name === preferenceName);

    return {
        isLoading: userPreferences.isLoading,
        isError: userPreferences.isError,
        result: result,
    }
};



export {
    useUserPreferences,
    useUserPreference,
    updateUserPreference,
};