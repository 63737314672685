import { orange, yellow, blue, lightBlue, red, deepOrange, grey } from '@mui/material/colors';

interface TemperatureBreakpoint {
    name: string;
    min: number;
    max: number;
    color: string;
}

export class TemperatureUtils {
    static convertCelsiusToFahrenheit(temp: number) {
        if (temp !== undefined) {
            return Math.round((temp * 9 / 5) + 32);
        }
        else {
            return temp;
        }

    }

    static determineColor(temp: number, orgTempUnit: string) {
        let result = "";

        const celsiusBreakpoints: TemperatureBreakpoint[] = [
            { name: 'hot', min: 27, max: Infinity, color: red[900] },
            { name: 'warm', min: 25, max: 27, color: deepOrange[900] },
            { name: 'perfect', min: 20, max: 25, color: orange[900] },
            { name: 'mild', min: 15, max: 20, color: yellow[800] },
            { name: 'cool', min: 10, max: 15, color: lightBlue[600] },
            { name: 'chilly', min: 5, max: 10, color: blue[500] },
            { name: 'cold', min: 0, max: 5, color: blue[700] },
            { name: 'frigid', min: -Infinity, max: 0, color: blue[900] },
        ];

        let correctedBreakpoints: TemperatureBreakpoint[] = [];
        if (orgTempUnit === 'F') {
            celsiusBreakpoints.forEach(breakpoint => {
                const newBreakpoint = {
                    ...breakpoint, 
                    min: this.convertCelsiusToFahrenheit(breakpoint.min),
                    max: this.convertCelsiusToFahrenheit(breakpoint.max)
                }
    
                correctedBreakpoints.push(newBreakpoint);
            });
        }
        else {
            correctedBreakpoints = [...celsiusBreakpoints];
        }
        
        correctedBreakpoints.forEach(breakpoint => {
            if (temp === undefined) {
                result = grey[400];
            }
            else if (temp >= breakpoint.min && temp < breakpoint.max) {
                result = breakpoint.color;
            }
        });

        return result;
    }
}