import { ApiClient } from "../../../lib/ApiClient";
import { useGenericSWR } from "../../../lib/useGenericSWR";
import * as Yup from 'yup';

const apiEndpoint = 'api/v1/admin/BillingCycle';

let formIteration = 0;

const getBillingCycleesDefaultItem = () => {
    formIteration = formIteration + 1;
    return {
        formIteration: "new" + formIteration,
        id: 0,
        name: '',
        description: '',
        status: 'ACTIVE',
        startDay: 1,
        createdBy: 'REACT_FE',
        updatedBy: 'REACT_FE',
    }
};

const billingCycleesValidationSchema = Yup.object().shape({
    name: Yup.string()
        .required("Required")
        .max(256, 'Max of 256 characters'),
    description: Yup.string()
        .max(1028, 'Max of 1028 characters').nullable(),
    status: Yup.string()
        .required("Required")
        .max(64, 'Max of 64 characters'),
    startDay: Yup.number()
        .integer()
        .min(1, 'Min of 1')
        .max(28, 'Max of 28 (Days 29, 30, 31 are not guaranteed every month)')
        .required("Required"),
});

const billingCycleesName = "Billing Cycles";
const billingCycleName = "Billing Cycle";

// See definition from BillingCycle.cs
const apiClient = new ApiClient();
apiClient.getDefaultItem = () => getBillingCycleesDefaultItem();
apiClient.getObjectName = () => {
    return billingCycleesName;
};

const useBillingCycle = (id, swrOptions = {}) => {
    // SWR will prevent duplicate requests
    // It will also maintain data state as long as your are using the same urls for your requests
    return useGenericSWR(apiEndpoint + "/" + id, apiClient, { ...swrOptions });
};

const useBillingCyclees = (filter, swrOptions = {}) => {
    // SWR will prevent duplicate requests
    // It will also maintain data state as long as your are using the same urls for your requests
    const params = new URLSearchParams();
    params.append('filter', JSON.stringify(filter));
    return useGenericSWR(apiEndpoint + "?" + params.toString(), apiClient, { ...swrOptions });
};

const saveBillingCycle = (BillingCycle) => {
    if (BillingCycle.id === 0) {
        return insertBillingCycle(BillingCycle);
    }
    return updateBillingCycle(BillingCycle);
}
const insertBillingCycle = (BillingCycle) => {
    return apiClient.poster(apiEndpoint, BillingCycle);
};

const updateBillingCycle = (BillingCycle) => {
    return apiClient.putter(apiEndpoint, BillingCycle);
};

const deleteBillingCycle = (BillingCycle) => {
    return apiClient.deleter(apiEndpoint + "/" + BillingCycle.id);
};

export {
    billingCycleName,
    billingCycleesName,
    useBillingCycle,
    useBillingCyclees,
    saveBillingCycle,
    insertBillingCycle,
    updateBillingCycle,
    deleteBillingCycle,
    billingCycleesValidationSchema,
    getBillingCycleesDefaultItem,
};