import { getOrgsDefaultItem, orgsValidationSchema } from './OrgAPIEndpoints';
import PropTypes from 'prop-types';
import { Formik, Form, Field } from 'formik';
import { useRef, useEffect } from 'react';
import { TextField, Select } from 'formik-mui';
import { DatePicker } from 'formik-mui-lab';
import MenuItem from '@mui/material/MenuItem';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import Grid from '@mui/material/Grid';
import { useTranslation } from 'react-i18next';

/**
 * This component handles the form for creating and editing
 * */
export const OrgForm = (props) => {
    const itemToEdit = props.itemToEdit || getOrgsDefaultItem();
    const dialogSubmit = useRef(props.dialogSubmit);
    const formRef = useRef();
    const { t } = useTranslation();

    useEffect(() => {
        if (props.dialogSubmit !== dialogSubmit.current) {
            dialogSubmit.current = props.dialogSubmit;
            if (formRef.current) {
                formRef.current.handleSubmit();
            }
        }
    });

    return (
        <div className='ignoreCustomFormStyles'>
            <Formik
                innerRef={formRef}
                initialValues={itemToEdit}
                validationSchema={orgsValidationSchema}
                onSubmit={(values) => {
                    props.onSubmit(values);
                }}
            >
                <Form readonly={props.readonly}>
                    <Grid container spacing={2} style={{paddingTop: '8px'}}>
                        <Grid item xs={12} sm={12} md={6}>
                            <Field
                                name={"name"}
                                component={TextField}
                                disabled={props.disabled}
                                label={t("Organization Name")}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={6}>
                            <Field
                                    name={"status"}
                                    component={Select}
                                    disabled={props.disabled}
                                    label={t("Status")}
                                >
                                    <MenuItem value="ACTIVE">{t("Active")}</MenuItem>
                                    <MenuItem value="INACTIVE">{t("Inactive")}</MenuItem>
                            </Field>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12}>
                            <Field
                                name={"description"}
                                component={TextField}
                                rows={2}
                                multiline
                                disabled={props.disabled}
                                label={t("Description")}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={12}>
                            <Field
                                name={"externalLink"}
                                component={TextField}
                                label={t("External Link")}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={12}>
                            <Field
                                name={"outageMapUrl"}
                                component={TextField}
                                label={t("Outage Map URL")}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={12}>
                            <Field
                                name={"timezone"}
                                component={TextField}
                                label={t("Time Zone")}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={6}>
                            <Field
                                name={"activationTs"}
                                component={DatePicker}
                                disabled={props.disabled}
                                label={t("Activation Date")}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={6}>
                            <Field
                                name={"udmOrgId"}
                                component={TextField}
                                disabled={props.disabled}
                                label={t("Udm Org Id")}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={6}>
                            <Field
                                name={"latitude"}
                                component={TextField}
                                disabled={props.disabled}
                                label={t("Latitude")}                           
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={6}>
                            <Field
                                name={"longitude"}
                                component={TextField}
                                disabled={props.disabled}
                                label={t("Longitude")}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={6}>
                            <Field name={"twoFactorEnabled"}>
                                {({ field: { value: fieldValue }, form: { setFieldValue } }) => (
                                    <FormControlLabel
                                        label={t("Two-factor Enabled")}
                                        control={
                                            <Switch
                                                checked={fieldValue}
                                                onChange={(e, changeValue) => {
                                                    setFieldValue("twoFactorEnabled", changeValue);
                                                }}
                                            />
                                        }
                                    />
                                )}
                            </Field>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12}>
                            <Field name={"onboardingModeEnabled"}>
                                {({ field: { value: fieldValue }, form: { setFieldValue } }) => (
                                    <FormControlLabel
                                        label={t("Onboarding Mode Enabled")}
                                        control={
                                            <Switch
                                                checked={fieldValue}
                                                onChange={(e, changeValue) => {
                                                    setFieldValue("onboardingModeEnabled", changeValue);
                                                }}
                                            />
                                        }
                                    />
                                )}
                            </Field>
                        </Grid>
                    </Grid>
                </Form>
            </Formik>
        </div>
    );
}

OrgForm.defaultProps = {
    id: 0,
    dialogSubmit: 0,
    changeIteration: 0,
    onSubmit: (values) => { console.log("onSubmit not implemented", values); },
    onError: (error) => { console.error("onError not implemented", error); },
};

OrgForm.propTypes = {
    id: PropTypes.number,
    changeIteration: PropTypes.number,
    dialogSubmit: PropTypes.number,
};