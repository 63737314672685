import React, { useState, useContext, useEffect } from 'react';
import { UcAdminDetails } from "../../common/UcAdminDetails";
import { PageContext } from '../../../lib/PageProvider';
import {
    useParams,
} from "react-router-dom";
import { EnsureUser } from '../../auth/EnsureUser';
import { useHasPermission } from 'components/auth/PermissionApiEndpoint';
import { RoleUserList } from './RoleUserList';
import { RolePermissionList } from "./RolePermissionList"

export const RoleDetailsPage = (props) => {
    let roleId = useParams()['roleId'] || props.roleId;
    const [pageState, pageDispatch] = useContext(PageContext);

    // eslint-disable-next-line no-unused-vars
    const [_selectedItem,setSelectedItem] = useState(false);

    useEffect(() => {
        pageDispatch({
            header: "Role Details",
            actionItems: []
        });
    }, [pageState.header, pageDispatch]);

    let detailsRender = <></>;
    let tabs = [
        {
            title: "Users",
            contentRender: <RoleUserList roleId={roleId} />,
            isHidden: !useHasPermission("RoleUser:Read")
        },
        {
            title: "Permissions",
            contentRender: <RolePermissionList isFullPage={false} roleId={roleId} onSelected={(selectedItem) => setSelectedItem({ ...selectedItem })} />,
            isHidden: !useHasPermission("RolePermission:Read")
        }
    ];

    return (
        <EnsureUser permissions={["Role:Read"]}>
            <UcAdminDetails detailsRender={detailsRender} tabs={tabs} tabInitValue={"Users"} />
        </EnsureUser>
    );
};