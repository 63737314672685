import React, { useContext, useEffect } from 'react';
import EnsureUserAndModule from "components/auth/EnsureUserAndModule";
import { TextField, Typography, Grid } from '@mui/material';
// @ts-ignore
import { dateFormatter } from "lib/Formatters";
import { useTranslation } from "react-i18next";
import HelpRequestCommentsForm from './HelpRequestCommentsForm';
import { UcStatusSelect } from 'components/common/UcStatusSelect';
import { updateHelpRequest } from "./HelpRequestAPIEndpoints";
import { UserFullNameSelect } from 'components/admin/Users/UserFullNameSelect';
import { PageAction, PageContext, PageState } from "lib/PageProvider";
import { HelpRequest } from './HelpRequest.interfaces';

interface HelpRequestDetailsPageProps {
    selectedItem: HelpRequest;
    mutateHandler: Function;
};

export const HelpRequestDetailsPage = (props: HelpRequestDetailsPageProps) => {
    const { t } = useTranslation();
    let selectedItem = props.selectedItem;

    const [pageState, pageDispatch] =
        useContext<[PageState, React.Dispatch<PageAction>]>(PageContext);

    useEffect(() => {
        pageDispatch({
            header: selectedItem.subject + " Details",
            actionItems: [] as any[],
            variableHeader: selectedItem.subject,
            tabs: [],
            onTabChange: () => { },
            tabValue: ""         
        });
    }, [pageState.header, pageDispatch, selectedItem.subject]);

    return (
        <EnsureUserAndModule permissions={["HelpRequest:Read"]} modules={['HelpRequest']} isFull={false}>
            <Typography variant="h5" gutterBottom data-cy-id={"helpRequestDetails-ticketId"}>Ticket {selectedItem.ticketId}</Typography>
            <Grid container spacing={2} direction='row' sx={{ pt: 2, pb: 2 }}>
                <Grid item xs={12} sm={6}>
                    <TextField
                        id="outlined-read-only-input"
                        fullWidth
                        label={t("Account Number")}
                        data-cy-id={"helpRequestDetails-accountNumber"}
                        defaultValue={selectedItem.accountNumber}
                        size="small"
                        InputProps={{
                            readOnly: true,
                        }}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        id="outlined-read-only-input"
                        fullWidth
                        label={t("Name")}
                        data-cy-id={"helpRequestDetails-name"}
                        defaultValue={selectedItem.name}
                        size="small"
                        InputProps={{
                            readOnly: true,
                        }}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        id="outlined-read-only-input"
                        fullWidth
                        label={t("Email")}
                        defaultValue={selectedItem.email}
                        size="small"
                        InputProps={{
                            readOnly: true,
                        }}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        id="outlined-read-only-input"
                        fullWidth
                        label={t("Submission Date")}
                        defaultValue={dateFormatter(selectedItem.createdTs)}
                        size="small"
                        InputProps={{
                            readOnly: true,
                        }}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <UserFullNameSelect
                        forcedSize="small"
                        label={t('Assign CSR')}
                        value={selectedItem?.assignedUserId}
                        roleType={'UtilitySupport,UtilityAdmin'}
                        onChange={async (event: any, val: any) => {
                            const helpRequest = {
                                ...selectedItem,
                                assignedUserId: val?.id
                            }
                            try {
                                await updateHelpRequest(helpRequest, false);
                            } finally {
                                props.mutateHandler();
                            }
                        }}
                    />        
                </Grid>
                <Grid item xs={12} sm={6}>
                    <UcStatusSelect
                        forcedSize="small"
                        forceChipHeight={"100%"}
                        forceChipFontSize={"16px"}
                        disableClearable
                        value={selectedItem?.status}
                        onChange={async (event: any, newValue: any) => {
                            const helpRequest = {
                                ...selectedItem,
                                status: newValue,
                                assignedUserId: selectedItem?.assignedUserId === 0 ? null : selectedItem?.assignedUserId
                            }
                            try {
                                await updateHelpRequest(helpRequest, false);
                            } finally {
                                props.mutateHandler();
                            }
                        }}
                    />
                </Grid>
            </Grid>          
            <Grid container spacing={2} direction="column" sx={{ pt: 3, pb: 2 }}>
                <Grid item xs={12}>
                    <TextField
                        id="outlined-read-only-input"
                        fullWidth
                        label={t("Subject")}
                        data-cy-id={"helpRequestDetails-subject"}
                        defaultValue={selectedItem.subject}
                        size="small"
                        InputProps={{
                            readOnly: true,
                        }}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        id="outlined-multiline-static"
                        multiline
                        fullWidth
                        label={t("User Question")}
                        defaultValue={selectedItem.question}
                        rows={6}
                        size="small"
                        InputProps={{
                            readOnly: true,
                        }}
                    />
                </Grid>
            </Grid>
            <HelpRequestCommentsForm
                helpRequestId={selectedItem.id}
            />
        </EnsureUserAndModule>
    );
};