import React from 'react';

export const UcChartProvider = ({ children }) => {
    // Need to add trigger classes here - 
    // There doesn't seem to be a parent element that we can iterate through
    const triggerClasses = [
        'recharts-rectangle', 
        'recharts-rectangle-custom-label',
        'recharts-curve'
    ];

    const handleTooltipVisibility = (e) => {
        let showTooltip = false;
        const tooltips = document.querySelectorAll('.recharts-tooltip-wrapper');
        
        for(let i = 0; i < triggerClasses.length; i++) {
            if(e.target.classList.contains(triggerClasses[i])) {
                showTooltip = true;
                break;
            }
        };

        if (showTooltip) {
            tooltips.forEach(tooltip => {
                tooltip.style.visibility = 'visible';
            }); 
        }
        else {
            tooltips.forEach(tooltip => {
                tooltip.style.visibility = 'hidden';
            });
        }
    }
    
    document.body.addEventListener('click', handleTooltipVisibility);

    return (
        <>
            {children}
        </>
    );
};