import { AccountAdminSelect } from "components/admin/Customers/AccountAdminSelect";
import { MyAccountSelect } from "./MyAccountSelect";

export const AccountSelect = (props) => {
    return (
        <>
            {props.isAdmin &&
                <AccountAdminSelect {...props} />
            }
            {!props.isAdmin &&
                <MyAccountSelect {...props} />
            }
        </>
    );
};