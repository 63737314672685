import { IconButton, IconButtonProps, styled } from "@mui/material";

interface ExpandMoreProps extends IconButtonProps {
    expanded: boolean;
}

export const UcExpandMore = styled((props: ExpandMoreProps) => {
    const { expanded, ...other } = props;
    return <IconButton {...other} />;
})(({ theme, expanded }) => ({
    transform: (expanded) ? "rotate(180deg)" : "rotate(0deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
        duration: theme.transitions.duration.shortest,
    }),
}));
