import React from 'react';
import { useTranslation } from "react-i18next";
import { Formik, Form, Field } from 'formik';
import { useDomainNavigate } from "domain";
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import Divider from '@mui/material/Divider';
import { FormControlLabel, RadioGroup, Alert, FormLabel, FormControl, Radio, Button} from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import Typography from '@mui/material/Typography';
import { sendAccountNumber } from './AccountAuthorizationApiEndpoints';
import { routePaths } from 'routes';

export const AccountLookupList = (props) => {
    const { t } = useTranslation();
    const navigate = useDomainNavigate();
    const { accounts } = props;
    const [selectedIndex, setSelectedIndex] = React.useState(-1);
    const [isSent, setIsSent] = React.useState(false);
    const formRef = React.useRef();
    const initialValues = { method: '' };

    const handleListItemClick = (event, index) => {
      setSelectedIndex(index);
    };

    const handleOnClickGoToVerify = () => {
        navigate(routePaths.accountVerificationPage.path);
    }

    return (
        <>
            {isSent && (
                <>
                    <Alert severity="info">
                        {t('You will receive an email or text message momentarily. If you do not receive it and are sure that this is the correct account, please contact support.')}
                    </Alert>
                    <Typography component={'p'} variant='body1' sx={{mt: 2}}>
                        {t('After receiving your account numbers, please click the button below to proceed.')}
                    </Typography>
                    <Button sx={{mt: 2}}
                        type="button"
                        color='primary'
                        variant="contained"
                        onClick={handleOnClickGoToVerify}
                    >
                        {t('Proceed')}
                    </Button>
                </>
            )}
            {!isSent && (
                <List>
                    { accounts?.result?.map((account, index) => (
                        <div key={index}>
                            <ListItemButton
                                selected={selectedIndex === index}
                                onClick={(event) => handleListItemClick(event, index)}
                                >
                                    <div className='ignoreCustomFormStyles'>
                                        <Formik
                                            innerRef={formRef}
                                            initialValues={initialValues}
                                            onSubmit={async () => {
                                                const data = await sendAccountNumber(account.extAccountNumber, formRef.current.values.method);
                                                if (data.isSuccessful) {
                                                    setIsSent(true);
                                                }
                                                else {
                                                    setIsSent(false);
                                                    const el = document.getElementById(`SendButton-${selectedIndex}`);
                                                    el.innerText = t('Resend')
                                                }
                                            }}
                                        >
                                        <Form style={{width: '100%'}}>
                                            <FormControl>
                                                <Typography gutterBottom component='p' variant='subtitle1'sx={{mb: 3}}>
                                                    {t('Account: {{hint}}', {hint: account?.name})}
                                                </Typography>
                                                <FormLabel id="demo-radio-buttons-group-label">Send Options</FormLabel>
                                                {account.email === undefined && account.phone1 === undefined && (
                                                    <Alert severity='error'>
                                                        {t('No verification options were found. If the problem persists please contact customer support.')}
                                                    </Alert>
                                                )}
                                                <Field component={RadioGroup} name="method" onChange={(e) => { formRef.current.values.method = e.target.value; }}>
                                                    { account.email !== undefined && (
                                                        <FormControlLabel
                                                            value="email"
                                                            control={<Radio />}
                                                            label={t('Send an email to {{hint}}', {hint: account.email})}
                                                        />
                                                    )}
                                                    { account.phone1 !== undefined && (
                                                        <>
                                                                <FormControlLabel
                                                                    value="sms"
                                                                    control={<Radio />}
                                                                    label={t('Send a text to {{hint}}', {hint: account.phone1})}
                                                                />
                                                        </> 
                                                    )}
                                                </Field>
                                                { account.email !== undefined && account.phone1 !== undefined && (
                                                    <Button sx={{mt: 2}} id={`SendButton-${index}`}
                                                        color='primary'
                                                        variant='contained'
                                                        type="submit"
                                                        endIcon={<SendIcon />}
                                                    >
                                                        {t('Send')}
                                                    </Button>
                                                )}
                                            </FormControl>
                                        </Form>
                                    </Formik>
                                </div>
                            </ListItemButton>
                            {(index + 1) < accounts.result.length && (
                                <Divider />
                            )}
                        </div>
                    ))}
                </List>
            )}
        </>
    );
}