import React, { useState } from 'react';
import LoadingButton from '@mui/lab/LoadingButton';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Paper from '@mui/material/Paper';
import Draggable from 'react-draggable';
import PropTypes from 'prop-types';
import { UserForm } from './UserForm';
import { saveUser, deleteUser, getUsersDefaultItem } from './UserAPIEndpoints';
import { useResponsive } from 'lib/useResponsive';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material/styles';
import Alert from '@mui/material/Alert';

function PaperComponent(props) {
    return (
        <Draggable
            handle="#draggable-dialog-title"
            cancel={'[class*="MuiDialogContent-root"]'}
        >
            <Paper {...props} />
        </Draggable>
    );
}

/**
 * This component handles the form for creating and editing
 * */
export const UserFormDialog = (props) => {
    const { t } = useTranslation();
    const [saving, setSaving] = React.useState(false);
    const [dialogSubmit, setDialogSubmit] = useState(0);
    const { isMobile } = useResponsive();
    const theme = useTheme();
    const userType = props.userType;
    const [numOfSelectedRoles, setNumOfSelectedRoles] = React.useState(1);

    let formId = props.id;
    if (!props.open) {
        formId = 0;
    }

    const handleCancel = () => {
        props.onCancel();
    };

    const handleSave = () => {
        setDialogSubmit(dialogSubmit + 1);
    };

    const handleDelete = async () => {
        let data = await deleteUser(props.itemToEdit);

        if (data) {
            props.onSave(data);
        }
    };

    return (
        <>
            {props.delete && props.open &&
                <Dialog
                    fullScreen={isMobile}
                    fullWidth={true}
                    maxWidth={"sm"}
                    open={props.open}
                    PaperComponent={PaperComponent}
                    aria-labelledby="draggable-dialog-title"
                >
                    <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
                        {t('Delete User')}
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            {t('Are you sure you want to delete this user?')}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button autoFocus
                            onClick={handleCancel}
                            variant={theme.props?.CancelButton?.variant}
                            data-cy-id={"cancel_delete_button"}
                        >
                            {t("Cancel")}
                        </Button>
                        <Button 
                            onClick={handleDelete}
                            variant={theme.props?.CancelButton?.variant}
                            color={theme.props?.CancelButton?.color}
                            data-cy-id={"delete_button"}
                        >
                            {t("Delete")}
                        </Button>
                    </DialogActions>
                </Dialog>
            }
            {!props.delete && props.open &&
                <Dialog
                    fullScreen={isMobile}
                    fullWidth={true}
                    maxWidth={"md"}
                    open={props.open}
                    PaperComponent={PaperComponent}
                    aria-labelledby="draggable-dialog-title"
                >
                    <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
                        {t('User')}
                    </DialogTitle>
                    <DialogContent>
                        <UserForm
                            id={formId}
                            itemToEdit={props.itemToEdit}
                            dialogSubmit={dialogSubmit}
                            userType={userType}
                            setNumOfSelectedRoles={setNumOfSelectedRoles}
                            onSubmit={async (values) => {
                                setSaving(true);
                                try {
                                    let data = await saveUser(values, t);

                                    if (data) {
                                        props.onSave(data);
                                    }
                                } finally {
                                    setSaving(false);
                                }
                            }}
                            onError={(error) => {
                                props.onError(error);

                                setSaving(false);
                            }}
                        />
                    </DialogContent>
                    {numOfSelectedRoles < 1 && (
                        <Alert severity="warning">
                            {t("At least one role must be selected for the user.")}
                        </Alert>
                    )}               
                    <DialogActions>
                        <Button 
                            autoFocus
                            onClick={handleCancel}
                            data-cy-id={"cancel_button"}
                            variant={theme.props?.CancelButton?.variant || "outlined"}
                            color={theme.props?.CancelButton?.color}
                        >
                                {t('Cancel')}
                        </Button>
                        <LoadingButton
                            loading={saving}
                            onClick={handleSave}
                            variant="contained"
                            data-cy-id={"save_button"}
                        >
                                {t('Save')}
                        </LoadingButton>
                    </DialogActions>
                </Dialog>
            }
        </>
    );
}

UserFormDialog.defaultProps = {
    open: false,
    id: 0,
    itemToEdit: getUsersDefaultItem(),
    onCancel: () => { console.log("onCancel not implemented"); },
    onSave: (data) => { console.log("onSave not implemented"); },
    onError: (error) => { console.error("onError not implemented", error); },
};

UserFormDialog.propTypes = {
    open: PropTypes.bool,
    id: PropTypes.number,
    itemToEdit: PropTypes.object,
};