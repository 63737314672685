// @ts-ignore
import { ApiClient } from "lib/ApiClient";
import { useGenericSWR } from "lib/useGenericSWR";
import * as Yup from 'yup';
import { SwrOptions } from "../../../lib/useGenericSWR.interfaces";

const apiEndpoint = 'api/v1/admin/Outage';
let formIteration = 0;

const getOutageDefaultItem = () => {
    formIteration = formIteration + 1;
    return {
        formIteration: "new" + formIteration,
        id: 0,
        cepOrgId: 0,
        accountId: -1,
        userId: -1,
        accountMeter: -1,
        extAccountNumber: "",
        accountName: "",
        accountAddress: "",
        username: "",
        serialNo: "",
        uom: "",
        type: "",
        status: "",
        source: "",
        activeTS: null,
        restoredTS: null,
        cancelledTS: null,
        estimatedRestoreTime: "",
        createdBy: 'REACT_FE',
        updatedBy: 'REACT_FE',
    }
};

const OutageValidationSchema = Yup.object().shape({
    type: Yup.string().required("Required"),
    status: Yup.string().required("Required"),
});

const apiClient = new ApiClient();
apiClient.getDefaultItem = () => getOutageDefaultItem();
apiClient.getObjectName = () => {
    return "Outage";
};

const useOutage = (filter: any, swrOptions = {} as SwrOptions) => {
    const params = new URLSearchParams();
    params.append('filter', JSON.stringify(filter));
    return useGenericSWR(apiEndpoint + "?" + params.toString(), apiClient, { ...swrOptions });
};

const saveOutage = (Outage: any) => {
    return apiClient.poster(apiEndpoint, Outage);
}

const updateOutage = (Outage: any) => {
    return apiClient.putter(apiEndpoint, Outage);
};

const mutateOutages = () => {
    apiClient.mutateFetchUrls();
}

export {
    getOutageDefaultItem,
    OutageValidationSchema,
    useOutage,
    saveOutage,
    updateOutage,
    mutateOutages
};