import { ApiClient } from "../../../lib/ApiClient";
import { useGenericSWR } from "../../../lib/useGenericSWR";
import * as Yup from 'yup';

const apiEndpoint = 'api/v1/admin/uc/User';

let formIteration = 0;

const getUsersDefaultItem = () => {
    formIteration = formIteration + 1;
    return {
        formIteration: "new" + formIteration,
        id: 0,
        cepOrgId: 0,
        username: '',
        email: '',
        firstName: '',
        middleName: '',
        lastName: '',
        address: '',
        phoneNumber: '',
        isSave: false,
        isSendInvite: false,
        isSendUserModified: false,
        ignoreUpdate: false,
        statsInviteCount: 0,
        rolesJson: '',
        selectedRoleIds: [],
        lockoutEnd: null
    }
};

const usersValidationSchema = Yup.object().shape({
    selectedRoleIds: Yup.array().min(1, "at least 1 required"),
    email: Yup.string().email().trim()
        .required("Required")
        .max(255, 'Max of 255 characters'),
    firstName: Yup.string().trim()
        .required("Required")
        .max(255, 'Max of 255 characters'),
    lastName: Yup.string().trim()
        .required("Required")
        .max(255, 'Max of 255 characters'),
    address: Yup.string().trim()
        .max(255, 'Max of 255 characters'),
});

const apiClient = new ApiClient();
apiClient.getDefaultItem = () => getUsersDefaultItem();
apiClient.getObjectName = () => {
    return "User";
};

const mutateUsers = () => {
    apiClient.mutateFetchUrls();
}

const useUser = (id, swrOptions = {}) => {
    // SWR will prevent duplicate requests
    // It will also maintain data state as long as your are using the same urls for your requests
    return useGenericSWR(apiEndpoint + "/" + id, apiClient, { ...swrOptions });
};

const useUsers = (filter, swrOptions = {}) => {
    // SWR will prevent duplicate requests
    // It will also maintain data state as long as your are using the same urls for your requests
    const params = new URLSearchParams();
    params.append('filter', JSON.stringify(filter));
    return useGenericSWR(apiEndpoint + "?" + params.toString(), apiClient, { ...swrOptions });
};

const saveUser = (User, t) => {
    if (User.id === 0) {
        return insertUser(User, t);
    }
    return updateUser(User, t);
}

const insertUser = (User, t) => {
    return apiClient.poster(apiEndpoint, User, "", false, t);
};

const updateUser = (User, t) => {
    return apiClient.putter(apiEndpoint, User, "", false, t);
};

const deleteUser = (User) => {
    return apiClient.deleter(apiEndpoint + "/" + User.id);
};

const ensureDefaultPasswordAuthUser = (User, t) => {
    return apiClient.poster(apiEndpoint + '/ensureDefaultPasswordAuthUser', User, "", false, t);
};

export {
    useUser,
    useUsers,
    saveUser,
    insertUser,
    updateUser,
    deleteUser,
    usersValidationSchema,
    getUsersDefaultItem,
    mutateUsers,
    ensureDefaultPasswordAuthUser
};