import * as React from 'react';
import { meterApiEndpoint } from './MeterAPIEndpoints';
import { dateFormatter } from 'lib/Formatters';
import { UcSelect } from 'components/common/UcSelect';

// From: https://mui.com/components/autocomplete/#Asynchronous.js
export const MeterAdminSelect = (props) => {
    const filter = {
        take: 100,
        skip: 0,
        sort: [{ field: "StartTimestamp", sort: "desc" }],
        generalSearch: "",
        extAccountNumber: props.extAccountNumber || null,
        customerId: props.customerId || null,
        userId: props.userId || null,
        accountId: props.value?.accountId || null,
        status: "ACTIVE",
    };

    // Ensure the filter refreshes when the extAccountNumber or customerId changes
    const filterRefresh = (props.customerId || "") + "~" + (props.extAccountNumber || "");
    const valueRefresh = (props.customerId || "") + "~" + (props.extAccountNumber || "");

    const getOptionLabel = (option) =>
    {
        if (option.id > -1 || option.accountId > -1)
        {
            if (props.showFullLabel) {
                return option.accountName + " - " + option.extAccountNumber + " - " + option.serialNo + " (" + dateFormatter(option.startTimestamp, true) + " to " + dateFormatter(option.endTimestamp, true, "present") + ")";
            }
    
            return option.serialNo;
        }
        return '';
    }

    return (
        <UcSelect {...props}
            filterRefresh={filterRefresh}
            valueRefresh={valueRefresh}
            apiEndPoint={meterApiEndpoint}
            defaultLabel={"Meters"}
            getOptionLabel={getOptionLabel}
            filter={filter} />
    );
};