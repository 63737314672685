import { ApiClient } from "lib/ApiClient";

const apiEndpoint = 'api/v1/admin/Impersonate';

let formIteration = 0;

const getImpersonatesDefaultItem = () => {
    formIteration = formIteration + 1;
    return {
        formIteration: "new" + formIteration,
        id: 0,
        impersonateId: 0,
    }
};

// See definition from UsageRate.cs
const apiClient = new ApiClient();
apiClient.getDefaultItem = () => getImpersonatesDefaultItem();
apiClient.getObjectName = () => {
    return "Impersonate";
};

const startImpersonate = async (impersonate) => {
    return insertImpersonate(impersonate);
}
const insertImpersonate = async (impersonate) => {
    return apiClient.poster(apiEndpoint, impersonate);
};
const stopImpersonate = async (impersonate) => {
    return deleteImpersonate(impersonate);
}
const deleteImpersonate = async (impersonate) => {
    return apiClient.deleter(apiEndpoint + "/" + impersonate.id);
};

export {
    startImpersonate,
    stopImpersonate,
    getImpersonatesDefaultItem,
};