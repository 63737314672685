import React, { useState, useLayoutEffect, useRef } from 'react';
import { DataGridPro } from '@mui/x-data-grid-pro';
import Box from '@mui/material/Box';
import { darken, lighten } from '@mui/material/styles';
import { useUserNotifications } from './UserNotificationAPIEndpoints';
import { UcFullPage } from 'components/common/UcFullPage';

export const UserNotificationGrid = (props) => {
    const [generalSearchInput] = useState(props.generalSearch || "");
    const initialSort = [{ field: "Id", sort: "asc" }];
    const sort = props.sort || initialSort;
    const initialDataState = props.dataState || { skip: 0, take: 10, };

    const [filter, setFilter] = useState({
        take: initialDataState.take,
        skip: initialDataState.skip,
        sort: sort,
        generalSearch: generalSearchInput,
    });

    let initialFilterModel = {
        items: [
            {columnField: 'id', operatorValue:'contains', value: ''}
        ],
        linkOperator: 'and',
    };

    if (generalSearchInput) {
        initialFilterModel = {
            items: [
                {columnField: 'id', operatorValue:'contains', value: generalSearchInput}
            ],
            linkOperator: 'and',
        }
    }

    const [filterModel] = useState(initialFilterModel);
    const needsDataUpdate = useRef("");

    const [dataItems, setDataItems] = useState(props.defaultDataItems);

    const defaultSelectedItem = { dataItem: {}, selectedId: 0 };
    const [selectionModel, setSelectionModel] = React.useState([defaultSelectedItem]);
    const isDataLoaded = useRef(false);


    const dataReceived = (swrResult) => {
        var dataItems = swrResult.result;
        var selectedDataItems = dataItems.map((item) => {
            return { ...item, selected: item.id === selectionModel.selectedId };
        });
        selectedDataItems.iteration = dataItems.iteration;
        selectedDataItems.rowCount = swrResult.rowCount;
        if (selectedDataItems.length > selectedDataItems.rowCount) {
            selectedDataItems.rowCount = selectedDataItems.length;
        }

        setDataItems({...props.defaultDataItems, rows: [...dataItems], rowCount: swrResult.rowCount});
    };

    const swrUserNotifications = useUserNotifications(filter);
    if (!swrUserNotifications.result && props.defaultDataItems.rowCount !== -1)
    {
        setDataItems(props.defaultDataItems);
    }

    useLayoutEffect(() => {
        if (swrUserNotifications.result) {
            isDataLoaded.current = true;
            var filterString = JSON.stringify(filter);
            if (needsDataUpdate.current !== filterString) {
                needsDataUpdate.current = filterString;
                dataReceived(swrUserNotifications);
            }
        }
    });

    const updateFilter = (filter) => {
        setFilter(filter);
        props.onFilterChange(filter);
    };

    const getBackgroundColor = (color, mode) =>
        mode === 'dark' ? darken(color, 0.6) : lighten(color, 0.6);

    const getHoverBackgroundColor = (color, mode) =>
        mode === 'dark' ? darken(color, 0.5) : lighten(color, 0.5);

    return (
        <div
            data-cy-id={"alertUserGridWrapper"}
        >
            <UcFullPage {...props}>
                <Box sx={{
                    height: '100%',
                    width: '100%',
                    '& .alertToBeAcknowledged': {
                        bgcolor: (theme) =>
                            getBackgroundColor(theme.palette.error.main, theme.palette.mode),
                        '&:hover': {
                            bgcolor: (theme) =>
                                getHoverBackgroundColor(theme.palette.error.main, theme.palette.mode),
                        },
                    },
                }}
                >
                    <DataGridPro
                        autoHeight={!props.isFullPage}
                        paginationModel={{ page: filter.skip / filter.take, pageSize: filter.take }}
                        loading={!isDataLoaded.current}
                        onPaginationModelChange={(model) => {
                            updateFilter({ ...filter, skip: model.page * model.pageSize, take: model.pageSize })
                        }}
                        sortingMode='server'
                        paginationMode='server'
                        filterMode='server'
                        sortModel={filter.sort}
                        onSortModelChange={(model) => updateFilter({ ...filter, sort: model })}
                        filterModel={filterModel}
                        rowsPerPageOptions={[5, 10, 20]}
                        onRowSelectionModelChange={(newSelectionModel) => {
                            let items = [];
                            newSelectionModel.forEach(id => {
                                var selectedItem = dataItems.rows.filter(obj => { return obj.id === id });
                                var newlySelectedItem = { dataItem: selectedItem, selectedId: id };
                                items.push(newlySelectedItem);
                            });
                            setSelectionModel(items);
                            props.onSelected(items);
                        }}
                        pagination
                        {...dataItems}
                        getRowClassName={(params) => !params.row.isAcknowledged ? 'alertToBeAcknowledged' : ''}
                        isRowSelectable={(params) => !params.row.isAcknowledged}
                        checkboxSelection
                        initialState={{ pinnedColumns: { right: ['dialog'] } }}
                    />
                </Box>
            </UcFullPage>
        </div>
    );
}

UserNotificationGrid.defaultProps = {
    isFullPage: true,
    onSelected: (selectedItem) => { console.log("onSelected not implemented", selectedItem); },
    onFilterChange: (filter) => { console.log("onFilterChange not implemented", filter); },
};
