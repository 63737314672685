import PropTypes from 'prop-types';
import { useRef, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Formik, Form, Field } from 'formik';
// @ts-ignore
import { Grid } from '@mui/material';
// @ts-ignore
import { UcDateTimePickerFormik } from 'components/common/UcDateTimePicker';
// @ts-ignore
import { UcEnumSelect } from 'components/common/UcEnumSelect';
import Typography from '@mui/material/Typography';
import EnsureUserAndModule from '../../auth/EnsureUserAndModule';
import { OutageValidationSchema } from '../Outages/OutageAPIEndpoints';

interface OutageValueProps {
    id: string;
}

interface UserMeterFormProps {
    itemToEdit: any;
    disabled: boolean;
    dialogSubmit: number;
    onSubmit: Function;
}

interface FieldProps {
    field: {
        name: string;
        value: any;
        onChange: (event: React.ChangeEvent<any>) => void;
        onBlur: (event: React.FocusEvent<any>) => void;
    };
    form: {
        setFieldValue: (field: any, event: any) => void;
    };
} 

export const UserMeterForm = (props: UserMeterFormProps) => {
    const { t } = useTranslation();
    const { itemToEdit } = props;
    const dialogSubmit = useRef<number>(props.dialogSubmit);
    const [outageType, setOutageType] = useState<any>();
    const formRef = useRef<any>();

    const outageToSave =
    {
        cepOrgId: itemToEdit?.cepOrgId,
        accountMeterId: itemToEdit?.id,
        type: "Confirmed",
        status: "Active",
        source: "Manual",
        activeTs: new Date()
    };

    useEffect(() => {
        if (props.dialogSubmit !== dialogSubmit.current) {
            dialogSubmit.current = props.dialogSubmit;
            if (formRef.current) {
                formRef.current.handleSubmit();
            }
        }
    });

    const handleChangeOutageTimestamp = (value: string | null) => {
        if (formRef.current !== undefined) {
            formRef.current.values.activeTs = value;
        }
    }

    return (
        <EnsureUserAndModule modules={['OutageManual']} >
            <div className='ignoreCustomFormStyles'>
                <Typography>
                    {t("Are you sure you want to send an outage alert email to this selected account?")} <p />
                    {t("Account address: ") + itemToEdit?.accountAddress} <br />
                    {t("Account name: ") + itemToEdit?.accountName} <br />
                    {t("Meter number: ") + itemToEdit?.serialNo} <br />
                    {t("UOM: ") + itemToEdit?.uom} <p />
                </Typography>
            
                <Formik
                    innerRef={formRef}
                    initialValues={outageToSave}
                    validationSchema={OutageValidationSchema}
                    onSubmit={(values) => {
                        props.onSubmit(values);
                    }}
                >
                    <Form>
                        <Grid container spacing={2} style={{ paddingTop: '8px' }}>
                            <Grid item xs={12} sm={12} md={12}>
                                <Field
                                    name={"type"}
                                >
                                    {({ field: { value }, form: { setFieldValue } }: FieldProps) => (
                                        <UcEnumSelect
                                            showOutageTypes={true}
                                            value={value}
                                            datacyid={"outage_type_dropdown"}
                                            enumName="OutageType"
                                            label={t("Outage Type")}
                                            onChange={(e: any, outageValue: OutageValueProps) => { setFieldValue("type", outageValue?.id); setOutageType(outageValue?.id); }} />
                                    )}
                                </Field>
                            </Grid>
                            {outageType === "Planned" && (
                                <Grid item xs={12} sm={12} md={12}>
                                    <Field
                                        name={"activeTs"}
                                        component={UcDateTimePickerFormik}
                                        disabled={props.disabled}
                                        label={t("Outage Date")}
                                        sendValueToForm={handleChangeOutageTimestamp}
                                    />
                                </Grid>
                            )}                           
                        </Grid>
                    </Form>
                </Formik>
            </div>
        </EnsureUserAndModule>
    );
}

UserMeterForm.defaultProps = {
    id: 0,
    dialogSubmit: 0,
    changeIteration: 0,
    onSubmit: (values: any) => { console.log("onSubmit not implemented", values); },
    onError: (error: any) => { console.error("onError not implemented", error); },
};

UserMeterForm.propTypes = {
    id: PropTypes.number,
    changeIteration: PropTypes.number,
    dialogSubmit: PropTypes.number,
};