import { useState } from 'react';
import PersonIcon from '@mui/icons-material/Person';
import { UcInfiniteScrollList } from '../common/UcInfiniteScrollList';

export interface MyUserAccountCardListProps {
    defaultDataItems: any;
    actionsHandler: any;
}

export const MyUserAccountCardList = (props: MyUserAccountCardListProps) => {
    const pageSize = 5;
    const [generalSearchInput] = useState("");
    const initialSort = [{ field: "Id", sort: "desc" }];
    const sort = initialSort;
    const initialDataState = { skip: 0, take: pageSize, };

    const filter = ({
        take: initialDataState.take,
        skip: initialDataState.skip,
        sort: sort,
        generalSearch: generalSearchInput,
    });
      
    return (
       <>
            <UcInfiniteScrollList
                apiEndPoint={'api/v1/MyAccount'}
                defaultDataItems={props.defaultDataItems}
                filter={filter}
                actionsHandler={props.actionsHandler}
                avatarDefault={<PersonIcon />}
            />
        </>
    );
}
