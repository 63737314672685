import { useRef, useEffect } from 'react';
import { myUserValidationSchema } from './MyUserAPIEndpoints';
import PropTypes from 'prop-types';
import { Formik, Form, Field } from 'formik';
import { TextField } from 'formik-mui';
import { useTranslation } from 'react-i18next';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import EditIcon from '@mui/icons-material/Edit';
import KeyIcon from '@mui/icons-material/Key';
import { useResponsive } from 'lib/useResponsive';
import { useUserPref } from "components/user/useUserPref";

export const MyUserForm = (props) => {  
    const { t } = useTranslation();
    const { isMobile } = useResponsive();
    const itemToEdit = props.itemToEdit;
    const dialogSubmit = useRef(props.dialogSubmit);
    const formRef = useRef();

    const notificationDeliveryMethod = useUserPref(
        "notificationDeliveryMethod",
        "Email"
    );

    useEffect(() => {
        if (props.dialogSubmit !== dialogSubmit.current) {
            dialogSubmit.current = props.dialogSubmit;
            if (formRef.current) {
                formRef.current.values.id = props.userId;
                formRef.current.handleSubmit();
            }
        }
    });

    return (
        <div className='ignoreCustomFormStyles'>
            <Formik
                innerRef={formRef}
                initialValues={itemToEdit}
                validationSchema={myUserValidationSchema}
                onSubmit={async (values) => {
                    values.id = props.userId;
                    if (!values.phoneNumber) {
                        const currentMethods = notificationDeliveryMethod.value.split(','); 
                        // remove 'SMS' from delivery method value string
                        const updatedMethods = currentMethods
                          .map(method => method.trim()) 
                          .filter(method => method !== "SMS") 
                          .join(','); 
                      
                        notificationDeliveryMethod.setUserPref("notificationDeliveryMethod", updatedMethods);
                    }
                    props.onSubmit(values);
                }}
            >
                <Form>
                    <Grid container spacing={2} style={{paddingTop: '8px'}}>
                        <Grid item xs={12} sm={12} md={4}>
                            <Field fullWidth={isMobile}
                                data-cy-id={"user-form-first-name"}
                                name={"firstName"}
                                component={TextField}
                                label={t("First Name")}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={4}>
                            <Field fullWidth={isMobile}
                                data-cy-id={"user-form-middle-name"}
                                name={"middleName"}
                                component={TextField}
                                label={t("Middle Name")}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={4}>
                            <Field fullWidth={isMobile}
                                data-cy-id={"user-form-last-name"}
                                name={"lastName"}
                                component={TextField}
                                label={t("Last Name")}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={4}>
                            <Field fullWidth={isMobile}
                                data-cy-id={"user-form-phone-number"}
                                name={"phoneNumber"}
                                component={TextField}
                                label={t("Phone Number")}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={4}>
                            <Field fullWidth={isMobile}
                                data-cy-id={"user-form-address"}
                                name={"address"}
                                component={TextField}
                                label={t("Address")}
                            />
                        </Grid>
                        { isMobile && (
                            <>
                            <Grid item xs={12} sm={12} md={12}>
                                <Button fullWidth 
                                    type="submit"
                                    color="primary"
                                    variant="contained" 
                                    startIcon={<EditIcon />}
                                >
                                    {t("Save")}
                                </Button>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12}>
                                <Button fullWidth 
                                    color="primary"
                                    variant="contained" 
                                    onClick={props.goToManagePassword}
                                    startIcon={<KeyIcon />}
                                >
                                    {t("Change Password")}
                                </Button>
                            </Grid>
                            </>
                        )}
                    </Grid>
                </Form>
            </Formik>
        </div>
    );
}

MyUserForm.defaultProps = {
    id: 0,
    dialogSubmit: 0,
    changeIteration: 0,
    onSubmit: (values) => { console.log("onSubmit not implemented", values); },
    onError: (error) => { console.error("onError not implemented", error); },
};

MyUserForm.propTypes = {
    id: PropTypes.number,
    changeIteration: PropTypes.number,
    dialogSubmit: PropTypes.number,
};