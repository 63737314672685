import React, { useState, useLayoutEffect } from 'react';
import { getUsersDefaultItem, saveUser, ensureDefaultPasswordAuthUser } from './UserAPIEndpoints';
import { unlockUser } from './UserLockoutAPIEndpoints';
import { UserGridStateful } from "./UserGridStateful"
import { UserFormDialog } from './UserFormDialog';
import { mutateUrls } from "../../../lib/useGenericSWR";
import { useSWRConfig } from "swr";
import { EnsureUser } from '../../auth/EnsureUser';
import { UcToolbar } from '../../common/UcToolbar';
import { UcListPage } from '../../common/UcListPage';
import AddIcon from '@mui/icons-material/Add';
import SendIcon from '@mui/icons-material/Send';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import SettingsSuggestIcon from '@mui/icons-material/SettingsSuggest';
import SupervisedUserCircleIcon from '@mui/icons-material/SupervisedUserCircle';
import { Box, Button, Divider, Drawer, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { UcDrawerHeader } from 'components/common/UcDrawerHeader';
import { UserDetailsPage } from './UserDetailsPage';
import { UcImpersonateDialog } from '../../common/UcImpersonateDialog';
import { useTranslation } from "react-i18next";
import { useHasPermission, useUser, useEnums } from 'components/auth/PermissionApiEndpoint';
import { enumFormatter } from 'lib/EnumFormatter';
import { useLocation } from "react-router-dom";

export const UserListPage = (props) => {
    const { t } = useTranslation();
    const currentUser = useUser();
    const canImpersonate = useHasPermission("Impersonate:Modify") && !currentUser?.isImpersonating;
    const [editDialog, setEditDialog] = useState({ open: false, delete: false });
    const [selectedItem, setSelectedItem] = useState(null);
    const [openResendInvite, setOpenResendInvite] = useState(false);
    const [openImpersonateDialog, setOpenImpersonateDialog] = useState(false);
    const [generalSearch, setGeneralSearch] = useState('');
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [reMutateUrls, setReMutateUrls] = useState(false);
    const swrConfig = useSWRConfig();
    const roleTypeEnums = useEnums("RoleType");
    const location = useLocation();
    const { onlyLockedOut } = location.state || {};
    const permissions = props.permissions;
    let roleType = props.type;
    let formattedRoleType = enumFormatter(roleTypeEnums, roleType).replace("Utility", "");

    let roleTypeTitle = { header: formattedRoleType, button: formattedRoleType };

    if (roleType === "SuperAdmin") {
        roleTypeTitle = { header: "All", button: "" };
    }

    // Resend Invite Dialog
    const handleClickOpenResendInvite = () => {
        setOpenResendInvite(true);
    };
    
    const handleResendInvite = async (sendInvite) => {
        if (sendInvite) {
            let userToInvite = { ...selectedItem };
            userToInvite.isSendInvite = true;
            userToInvite.ignoreUpdate = true;
            userToInvite.selectedRoleIds = JSON.parse(selectedItem.rolesJson).map(r => r.id) || [];

            let data = await saveUser(userToInvite);

            if (data) {
                // Also, remutate
                setReMutateUrls(true);
            }
        }

        setOpenResendInvite(false);
    };

    const handleCloseResendInvite = async () => {
        setOpenResendInvite(false);
    };

    const handleOpenImpersonateDialog = async () => {
        setOpenImpersonateDialog(true);
    };

    const handleCloseImpersonateDialog = async () => {
        setOpenImpersonateDialog(false);
    };

    const handleClickUnlockUser = async () => {
        let data = await unlockUser(selectedItem);

        if (data) {
            setSelectedItem(null);
            setReMutateUrls(true);
        }
    }

    const handleClickEnsureDefautPasswordAuthUser = async () => {
        let data = await ensureDefaultPasswordAuthUser(selectedItem, t);

        if (data) {
            setSelectedItem(null);
            setReMutateUrls(true);
        }
    }

    const closeHandler = () => {
        setEditDialog({ open: false, itemToEdit: editDialog.dataItem });
        setSelectedItem(null);
    }

    const saveHandler = () => {
        closeHandler();
        // Also, remutate
        setReMutateUrls(true);
    }

    const openDialog = (dataItem) => {
        if (!dataItem) {
            return;
        }
        if (!dataItem.formIteration) {
            dataItem.formIteration = "edit" + dataItem.id;
        }
        setEditDialog({ open: true, itemToEdit: dataItem, delete: false });
    }

    const deleteDialog = (dataItem) => {
        if (!dataItem) {
            return;
        }
        if (!dataItem.formIteration) {
            dataItem.formIteration = "delete" + dataItem.id;
        }
        setEditDialog({ open: true, itemToEdit: dataItem, delete: true });
    }

    const createItem = () => {
        openDialog(getUsersDefaultItem());
    }

    const editItem = (item) => {
        openDialog(item);
        handleDrawerClose();
    }

    const deleteItem = (item) => {
        deleteDialog(item);
        handleDrawerClose();
    }

    const getIsLockedOut = () => {
        if (selectedItem !== null)
        {
            return selectedItem.lockoutEnd !== undefined && new Date(selectedItem.lockoutEnd) > new Date() ? true : false;
        }
        return false;
    }

    const canImpersonateButton = () => {
        if (selectedItem !== null && canImpersonate && selectedItem.id !== currentUser.id) {
            if (selectedItem?.rolesJson) {
                const selectedRoles = JSON.parse(selectedItem?.rolesJson).map(r => r.roleType) || [];
                return selectedRoles.indexOf('UtilityResidential') > -1 ? true : false;
            }
        }
        return false;
    }

    let actions = [
        {
            icon: <AddIcon data-cy-id={"add_user"}/>,
            title: t('Add '+roleTypeTitle.button+' User'),
            toolTip: t('Add '+roleTypeTitle.button+' User'),
            onClick: () => { createItem(); },
            isDisabled: false
        },
        {
            icon: <SupervisedUserCircleIcon />,
            color: 'secondary',
            title: t('Impersonate User'),
            toolTip: t('Impersonate User'),
            onClick: () => { handleOpenImpersonateDialog(); },
            isHidden: !canImpersonate || (roleType !== "UtilityResidential" && roleType !== "SuperAdmin"),
            isDisabled: !canImpersonateButton(),
            dataCyId: "impersonateUserDialogOpen"
        },
        {
            icon: <SendIcon />,
            title: t('Invite User'),
            toolTip: t('Invite User'),
            onClick: () => { handleClickOpenResendInvite(); },
            isDisabled: selectedItem === null
        },
        {
            icon: <LockOpenIcon />,
            title: t('Unlock User'),
            toolTip: t('Unlock User'),
            onClick: () => { handleClickUnlockUser(); },
            isHidden: !useHasPermission("UserLockout:Modify"),
            isDisabled: !getIsLockedOut()
        },
        {
            icon: <SettingsSuggestIcon />,
            title: t('Generate Default-Password Sign-In'),
            toolTip: t('Ensures the selected user has a UcAuth user who can sign-in using the "DefaultPassword" provider method'),
            onClick: () => { handleClickEnsureDefautPasswordAuthUser(); },
            isHidden: !useHasPermission("Org:Modify") || props.type !== 'SuperAdmin',
            isDisabled: selectedItem === null
        }
    ];

    useLayoutEffect(() => {
        if (reMutateUrls) {
            mutateUrls(swrConfig);
            setReMutateUrls(false);
        }
    }, [reMutateUrls, swrConfig]);


    const handleDrawerClose = () => {
        setSelectedItem(null);
        setDrawerOpen(false);
    }

    const doubleClick = () => {
        if (selectedItem !== null && selectedItem?.selectedId !== 0 && !editDialog.open) {
            setDrawerOpen(true);
        }
    };
    const drawerWidth = "50vw";

    console.log("UserListPage - Loaded");
    return (
        <UcListPage>
            <EnsureUser permissions={permissions}>
                <h1 className="pageHeader">{t(roleTypeTitle.header+' Users')}</h1>
                <UcToolbar
                    hasGeneralSearch={true}
                    onGeneralSearchChange={(value) => {
                        setGeneralSearch(value);
                    } }
                    actions={actions}>
                </UcToolbar>
                <UserFormDialog
                    open={editDialog.open}
                    delete={editDialog.delete}
                    itemToEdit={editDialog.itemToEdit}
                    onCancel={closeHandler}
                    onSave={saveHandler}
                    userType={roleType}
                />
                {!reMutateUrls &&
                    <UserGridStateful
                        setDrawerOpen={setDrawerOpen}
                        doubleClick={doubleClick}
                        generalSearch={generalSearch}
                        onEditItem={editItem}
                        onDeleteItem={deleteItem}
                        roleType={roleType}
                        onlyLockedOut={onlyLockedOut}
                        onSelected={(newSelectedItem) => {
                            setSelectedItem({ ...newSelectedItem });
                         }}
                    />
                    }
                <Drawer
                    anchor="right"
                    open={drawerOpen}
                    onClose={handleDrawerClose}
                >
                    <UcDrawerHeader justify="flex-start"/>
                    <Box
                        sx={{ width: drawerWidth, margin: '10px' }}
                        role="presentation"
                    >
                        <Divider />
                        {selectedItem &&
                            <UserDetailsPage
                            userId={selectedItem.id}
                            user={selectedItem}
                            showDetails={false}
                            roleType={roleType}/>
                        }
                    </Box>
                </Drawer>
                <Dialog
                    open={openResendInvite}
                    onClose={handleCloseResendInvite}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">
                        {t("Send Invite to User?")}
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            {t('Are you sure you want to send an invite to')}&nbsp;<strong>{selectedItem?.email}</strong>.
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleCloseResendInvite}>Cancel</Button>
                        <Button onClick={handleResendInvite} autoFocus>
                            {t('Send Invite')}
                        </Button>
                    </DialogActions>
                </Dialog>
                {canImpersonate && (
                    <UcImpersonateDialog
                        open={openImpersonateDialog}
                        isImpersonating={false}
                        selectedItem={selectedItem}
                        onClose={handleCloseImpersonateDialog}
                    />
                )}
            </EnsureUser>
        </UcListPage>
    );
};