import { ApiClient } from "../../lib/ApiClient";
import { useGenericSWR, addMutateUrlPrefix } from "../../lib/useGenericSWR";
import { mutatePermissions } from "components/auth/PermissionApiEndpoint";

const apiEndpoint = 'api/v1/UserNotification';
let formIteration = 0;

const getUserNotificationDefaultItem = () => {
    formIteration = formIteration + 1;
    return {
        formIteration: "new" + formIteration,
        id: 0,
        cepOrgId: 0,
        title: "",
        content: "",
        isSent: false,
        isAcknowledged: false,
        createdBy: 'REACT_FE',
        createdTs: null,
        userId: 0,
        userNotificationPreferenceId: 0,
        isPinned: false,
        isHidden: false
    }
};

const apiClient = new ApiClient();
apiClient.getDefaultItem = () => getUserNotificationDefaultItem();
apiClient.getObjectName = () => {
    return "My User Notifications";
};

const useUserNotifications = (filter, swrOptions = {}) => {
    const params = new URLSearchParams();
    params.append('filter', JSON.stringify(filter));
    return useGenericSWR(apiEndpoint + "?" + params.toString(), apiClient, { ...swrOptions });
};

const saveUserNotificationBulk = (UserNotifications, doToast) => {
    const notificationIds = [];
    UserNotifications.forEach(element => {
        notificationIds.push(element.selectedId);
    });
    return apiClient.putter(apiEndpoint + '/bulkUpdate?notificationIdsJson=' + JSON.stringify(notificationIds) + '&isAcknowledged=' + true, null, "", false, null, doToast)
        .then((result) => {
            mutatePermissions();
            addMutateUrlPrefix("api/v1/admin/Announcement");
            addMutateUrlPrefix("api/v1/UserNotification?");
            return result;
        });
}

const updateUserNotifications = (UserNotification, doToast = true) => {
    return apiClient.putter(apiEndpoint, UserNotification, "", false, null, doToast)
        .then((result) => {
            mutatePermissions();
            addMutateUrlPrefix("api/v1/admin/Announcement");
            addMutateUrlPrefix("api/v1/UserNotification?");
            return result;
        });
};

export {
    getUserNotificationDefaultItem,
    useUserNotifications,
    saveUserNotificationBulk,
    updateUserNotifications
};