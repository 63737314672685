import { ApiClient } from "../../lib/ApiClient";
import { useGenericSWR } from "../../lib/useGenericSWR";
import { ensureLuxonDate } from "lib/Formatters";
import { DateTime } from 'luxon';
import { useTranslation } from 'react-i18next';

const apiEndpoint = 'api/v1/Weather';

// See definition from UdmReadings in UdmObjects.cs
const apiClient = new ApiClient();
apiClient.getDefaultItem = () => {
    // returning empty object
};

const useWeatherData = (startDateTime, endDateTime, geoCoordinates = [], isReady = true, swrOptions = {}) => {
    // SWR will prevent duplicate requests
    const params = new URLSearchParams();

    params.append('startTimeUTC', ensureLuxonDate(startDateTime).toUTC().toISO());
    params.append('endTimeUTC', ensureLuxonDate(endDateTime).toUTC().toISO());
    params.append('geoCoordinatesJson', JSON.stringify(geoCoordinates));

    let url = apiEndpoint + "/Timeline?" + params.toString();
    if (!isReady)
    {
        url = "";
    }

    return useGenericSWR(
        url,
        apiClient,
        {
            ...swrOptions,
            refreshInterval:  3600000, // refresh every hour
            errorRetryInterval:  3600000, // refresh every hour
            doToast: false
        });
};

const useWeatherHistorySummary = (startDateTime, endDateTime, geoCoordinates = [], isReady = true, swrOptions = {}) => {
    const { t } = useTranslation();
    // SWR will prevent duplicate requests
    const params = new URLSearchParams();

    params.append('startTimeUTC', ensureLuxonDate(startDateTime).toUTC().toISO());
    params.append('endTimeUTC', ensureLuxonDate(endDateTime).toUTC().toISO());
    params.append('geoCoordinatesJson', JSON.stringify(geoCoordinates));

    let url = apiEndpoint + "/HistorySummary?" + params.toString();
    if (!isReady)
    {
        url = "";
    }

    return useGenericSWR(url, apiClient, {
        ...swrOptions,
        refreshInterval:  3600000, // refresh every hour
        errorRetryInterval:  3600000, // refresh every hour
        doToast: false,
        overrideMainMessage: true,
        additionalMessage: t("Fetching Weather History"),
        errorRetryCount: 0
    });
};

const groupWeatherTimelineByMonthAndYear = (input, key) => {
    return input.reduce((acc, currentValue) => {
      let groupKey = DateTime.fromJSDate(new Date(currentValue[key])).toFormat('yyyy-LL');

      if (!acc[groupKey]) {
        acc[groupKey] = [];
      }

      acc[groupKey].push({...currentValue, avgHigh: 0});

      return acc;
    }, {});
  };

export {
    useWeatherData,
    useWeatherHistorySummary,
    groupWeatherTimelineByMonthAndYear
};