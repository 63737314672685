import React, { useState, useLayoutEffect, useRef, useEffect } from 'react';
import { DataGridPro } from '@mui/x-data-grid-pro';
import { Chip } from '@mui/material';
import { useMeterRates } from './MeterRateAPIEndpoints';
import { useMyTimezone } from 'components/customer/orgs/MyOrgAPIEndpoints';
import { UcFullPage } from '../../common/UcFullPage';
import { UcPagination } from 'components/common/UcPagination';
import { dateFormatterTimezone } from 'lib/Formatters';
import { useTranslation } from 'react-i18next';
import EditIcon from '@mui/icons-material/Edit'
import { UcIconButtons } from 'components/common/UcIconButtons';
import { getWholeRow } from '../../../lib/DataGridHelpers';

export const MeterRateGrid = (props) => {
    const { t } = useTranslation();
    const timezone = useMyTimezone();

    const initialSort = [{ field: "rateStartTimestamp", sort: "desc" }];
    const sort = props.sort || initialSort;
    const initialDataState = props.dataState || { skip: 0, take: 10, };

    const [filter, setFilter] = useState({
        take: initialDataState.take,
        skip: initialDataState.skip,
        sort: sort,
        accountMeterId: props.accountMeterId,
        userId: props.userId || null,
        accountId: props.accountId || null,
        rateClassId: props.rateClassId || null,
        generalSearch: props.generalSearch,
    });

    useEffect(() => {
        if (props.generalSearch !== filter.generalSearch) {
            setFilter({ ...filter, generalSearch: props.generalSearch });
        }
    }, [filter, setFilter, props.generalSearch]);

    let initialFilterModel = {
        items: [
            { columnField: 'id', operatorValue: 'contains', value: '' }
        ],
        linkOperator: 'and',
    };

    const [filterModel] = useState(initialFilterModel);

    const needsDataUpdate = useRef("");

    const getMeterFieldValues = (params) => {
        return {
            "accountName": params.row.accountName,
            "extAccountNumber": params.row.extAccountNumber,
            "serialNo": params.row.serialNo
        };
    }

    let defaultDataItems = {
        columnVisibilityModel: {
            rateClassName: !props.isFromRateClassPath,
            rateClassStatus: !props.isFromRateClassPath
        },
        columns: [
            { field: 'rateClassName', headerName: t('Rate Class'), width: 200 },
            { field: 'rateClassStatus', headerName: t('Rate Class Status'), width: 160,
                renderCell: (params) => {
                    return (
                    <>
                        <Chip
                            label={params.value}
                            color={params.value.toLowerCase() === 'active' ? "success" : "error"}
                            variant="outlined"
                        />
                    </>
                    );
                }
            },
            { field: 'accountMeterId', headerName: t('Meter'), width: 325,
                valueGetter: getMeterFieldValues,
                valueFormatter: (params) => {
                    return params.value.accountName + " - " + params.value.extAccountNumber + " - " + params.value.serialNo;
                }
            },
            { field: 'rateStartTimestamp', headerName: t('Start Date'), width: 170, valueFormatter: (params) => { return dateFormatterTimezone(params.value, timezone); } },
            { field: 'rateEndTimestamp', headerName: t('End Date'), width: 170, valueFormatter: (params) => { return dateFormatterTimezone(params.value, timezone); } },
            {
                field: 'id', headerName: '', width: 58,
                valueGetter: getWholeRow,
                renderCell: (params) => {
                    const actions = [
                        {
                            title: t('Edit Meter Rate'),
                            icon: <EditIcon />,
                            color: 'primary',
                            isHidden: false,
                            requiredPermission: 'MeterRate:Modify',
                            onClick: () => { props.onEditItem(params.value); },
                        }
                    ];

                    return (
                        <UcIconButtons actions={actions} />
                    );
                }
            },
        ],
        rows: [],
        rowCount: -1,
    };
    const [dataItems, setDataItems] = useState(defaultDataItems);

    const defaultSelectedItem = { dataItem: {}, selectedId: 0 };
    const [selectedItem, setSelectedItem] = useState(defaultSelectedItem);

    const isDataLoaded = useRef(false);

    const dataReceived = (swrResult) => {
        var receivedDataItems = swrResult.result;

        var selectedDataItems = receivedDataItems.map((item) => {
            return { ...item, selected: item.id === selectedItem.selectedId };
        });
        selectedDataItems.iteration = receivedDataItems.iteration;
        selectedDataItems.rowCount = swrResult.rowCount;
        if (selectedDataItems.length > selectedDataItems.rowCount) {
            selectedDataItems.rowCount = selectedDataItems.length;
        }

        setDataItems({ ...defaultDataItems, rows: [...receivedDataItems], rowCount: swrResult.rowCount });
    };

    const swrMeterRates = useMeterRates(filter);
    const isLoading = swrMeterRates.isLoading || !isDataLoaded.current;
    if (!swrMeterRates.result && defaultDataItems.rowCount !== -1) {
        setDataItems(defaultDataItems);
    }

    useLayoutEffect(() => {
        if (swrMeterRates.result) {
            isDataLoaded.current = true;
            var filterString = JSON.stringify(filter);
            if (needsDataUpdate.current !== filterString) {
                needsDataUpdate.current = filterString;
                dataReceived(swrMeterRates);
            }
        }
    });

    const updateFilter = (newFilter) => {
        setFilter(newFilter);
        props.onFilterChange(newFilter);
    };

    const isFullPage = props.isFullPage || false;

    return (
        <UcFullPage isFullPage={isFullPage}>
            <div>
                <DataGridPro
                    loading={isLoading}
                    sortingMode='server'
                    autoHeight={!isFullPage}
                    filterMode='server'
                    sortModel={filter.sort}
                    onSortModelChange={(model) => updateFilter({ ...filter, sort: model })}
                    filterModel={filterModel}
                    paginationModel={{page: filter.skip / filter.take, pageSize: filter.take}}
                    onPaginationModelChange={(model) => updateFilter({ ...filter, skip: model.page * model.pageSize, take: model.pageSize })}
                    nge={(newPageSize) => updateFilter({ ...filter, take: newPageSize })}
                    paginationMode='server'
                    rowsPerPageOptions={[5, 10, 20]}
                    onRowSelectionModelChange={(newSelectionModel) => {
                        if (newSelectionModel.length !== 1) {
                            setSelectedItem(defaultSelectedItem);
                            props.onSelected(defaultSelectedItem.dataItem);
                            return;
                        }
                        const selectedId = newSelectionModel[0];
                        var selectedDataItem = dataItems.rows.filter(obj => { return obj.id === selectedId });
                        if (selectedDataItem.length !== 1) {
                            setSelectedItem(defaultSelectedItem);
                            props.onSelected(defaultSelectedItem.dataItem);
                            return;
                        }
                        selectedDataItem = selectedDataItem[0];
                        var newlySelectedItem = { dataItem: selectedDataItem, selectedId: selectedId };

                        setSelectedItem(newlySelectedItem);
                        props.onSelected(newlySelectedItem.dataItem);
                    }}
                    pagination
                    components={{
                        Toolbar: () => {return  props.toolbar || <></>},
                        Pagination: UcPagination,
                    }}
                    {...dataItems}
                    initialState={{ pinnedColumns: { right: ['id'] } }}
                />
            </div>
        </UcFullPage>
    );
}

MeterRateGrid.defaultProps = {
    onSelected: (selectedItem) => { console.log("onSelected not implemented", selectedItem); },
    onFilterChange: (filter) => { console.log("onFilterChange not implemented", filter); },
};
