import React, { useState, useLayoutEffect, useRef } from 'react';
import { DataGridPro } from '@mui/x-data-grid-pro';
import { useUserDelegations } from './UserDelegationAPIEndpoints';
import { dateFormatter } from 'lib/Formatters';
import { UcFullPage } from 'components/common/UcFullPage';
import { useTranslation } from 'react-i18next';
import { enumFormatter } from 'lib/EnumFormatter';
import { useEnums } from 'components/auth/PermissionApiEndpoint';
import { getWholeRow } from 'lib/DataGridHelpers';

export const UserDelegationGrid = (props) => {
    const { t } = useTranslation();
    const [generalSearchInput] = useState(props.generalSearch || "");
    const initialSort = [{ field: "Id", sort: "asc" }];
    const sort = props.sort || initialSort;

    const initialDataState = props.dataState || { skip: 0, take: 10, };

    const [filter, setFilter] = useState({
        accountId: parseInt(props.accountId),
        delegatedUserId: parseInt(props.delegatedUserId),
        userId: parseInt(props.userId),
        take: initialDataState.take,
        skip: initialDataState.skip,
        sort: sort,
        generalSearch: generalSearchInput,
    });

    let initialFilterModel = {
        items: [
            {columnField: 'id', operatorValue:'contains', value: ''}
        ],
        linkOperator: 'and',
    };

    if (generalSearchInput) {
        initialFilterModel = {
            items: [
                {columnField: 'id', operatorValue:'contains', value: generalSearchInput}
            ],
            linkOperator: 'and',
        }
    }

    const [filterModel] = useState(initialFilterModel);

    const needsDataUpdate = useRef("");
    const accessEnums = useEnums("DelegationAccessLevel");

    let defaultDataItems = {
        columnVisibilityModel: {
            id: false,
            cepOrgId: false,
            content: false,
            createdBy: false,
            updatedBy: false,
        },
        columns: [
            {field: 'id', headerName: t('Id')},
            {field: 'username', headerName: t('User'), width: 200},
            {field: 'delegatedUserEmail', headerName: t('Delegated User'), width: 200},
            {field: 'accountName', headerName: t('Account Name'), width: 250,
                valueGetter: getWholeRow,
                valueFormatter: (params) => {
                    return params.value.accountName + (params.value.accountAddress !== undefined ? " - " + params.value.accountAddress : "")
                }
            },
            {field: 'extAccountNumber', headerName: t('Account Number'), flex: 1, minWidth: 200 },
            {field: 'accessLevel', headerName: t('Access'), minWidth: 100, valueFormatter: ( params ) => {return enumFormatter(accessEnums, params.value);} },
        ],
        rows: [],
        rowCount: -1,
    };
    const [dataItems, setDataItems] = useState(defaultDataItems);

    const defaultSelectedItem = { dataItem: {}, selectedId: 0 };
    const [selectedItem, setSelectedItem] = useState(defaultSelectedItem);

    const dataReceived = (swrResult) => {
        var recievedDataItems = swrResult.result;

        var selectedDataItems = recievedDataItems.map((item) => {
            item.startDate = dateFormatter(item.startDate, true);
            item.endDate = dateFormatter(item.endDate, true);

            return { ...item, selected: item.id === selectedItem.selectedId };
        });
        selectedDataItems.iteration = recievedDataItems.iteration;
        selectedDataItems.rowCount = swrResult.rowCount;
        if (selectedDataItems.length > selectedDataItems.rowCount) {
            selectedDataItems.rowCount = selectedDataItems.length;
        }

        setDataItems({...defaultDataItems, rows: [...recievedDataItems], rowCount: swrResult.rowCount});
    };

    const swrUserDelegations = useUserDelegations(filter); //Pass the datastate directly as the filter.  The API will adjust filters, orderbys ect.
    if (!swrUserDelegations.result && defaultDataItems.rowCount !== -1)
    {
        setDataItems(defaultDataItems);
    }

    useLayoutEffect(() => {
        if (swrUserDelegations.result) {
            var filterString = JSON.stringify(filter);
            if (needsDataUpdate.current !== filterString) {
                needsDataUpdate.current = filterString;
                dataReceived(swrUserDelegations);
            }
        }
    });

    const updateFilter = (newFilter) => {
        setFilter(newFilter);
        props.onFilterChange(newFilter);
    };

    return (
        <UcFullPage {...props}>
            <DataGridPro
                autoHeight={!props.isFullPage}
                paginationModel={{page: filter.skip / filter.take, pageSize: filter.take}}
                onPaginationModelChange={(model) => updateFilter({ ...filter, skip: model.page * model.pageSize, take: model.pageSize })}
                sortingMode='server'
                paginationMode='server'
                filterMode='server'
                sortModel={filter.sort}
                onSortModelChange={(model) => updateFilter({...filter, sort: model})}
                filterModel={filterModel}

                rowsPerPageOptions={[5, 10, 20]}
                onRowSelectionModelChange={(newSelectionModel) => {
                    if (newSelectionModel.length !== 1)
                    {
                        setSelectedItem(defaultSelectedItem);
                        props.onSelected(defaultSelectedItem.dataItem);
                        return;
                    }
                    const selectedId = newSelectionModel[0];
                    var selectedItem = dataItems.rows.filter(obj => {return obj.id === selectedId});
                    if (selectedItem.length !== 1)
                    {
                        setSelectedItem(defaultSelectedItem);
                        props.onSelected(defaultSelectedItem.dataItem);
                        return;
                    }
                    selectedItem = selectedItem[0];
                    var newlySelectedItem = { dataItem: selectedItem, selectedId: selectedId };

                    setSelectedItem(newlySelectedItem);
                    props.onSelected(newlySelectedItem.dataItem);
                }}
                pagination
                {...dataItems}
            />
        </UcFullPage>
    );
}

UserDelegationGrid.defaultProps = {
    isFullPage: true,
    onSelected: (selectedItem) => { console.log("onSelected not implemented", selectedItem); },
    onFilterChange: (filter) => { console.log("onFilterChange not implemented", filter); },
};
