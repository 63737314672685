import React, { useState, useLayoutEffect, useRef } from 'react';
import { DataGridPro } from '@mui/x-data-grid-pro';
import { useAccounts } from './AccountAPIEndpoints';
import { dateFormatter } from '../../../lib/Formatters';
import { useTranslation } from "react-i18next";
import { getWholeRow } from '../../../lib/DataGridHelpers';
import { UcIconButtons } from 'components/common/UcIconButtons';
import { UcPagination } from 'components/common/UcPagination';
import ListIcon from '@mui/icons-material/List';
import { useDebouncedValue } from '@mantine/hooks';

export const AccountGrid = (props) => {
    const [generalSearchInput] = useState(props.generalSearch || "");
    const initialSort = [{ field: "name", sort: "asc" }];
    const sort = props.sort || initialSort;
    const { t } = useTranslation();

    const initialDataState = props.dataState || { skip: 0, take: 10, };
    const needsDataUpdate = useRef(true);
    const [debounced] = useDebouncedValue(generalSearchInput, 500);

    const [filter, setFilter] = useState({
        customerId: parseInt(props.customerId),
        take: initialDataState.take,
        skip: initialDataState.skip,
        sort: sort,
        generalSearch: debounced,
    });

    let initialFilterModel = {
        items: [
            { columnField: 'name', operatorValue: 'contains', value: '' }
        ],
        linkOperator: 'and',
    };

    const [filterModel] = useState(initialFilterModel);

    let defaultDataItems = {
        columnVisibilityModel: {
            id: false,
            cepOrgId: false,
            customerId: false,
            extSource: false,
            extAccountId: false,
            content: false,
            createdBy: false,
            updatedBy: false,
        },
        columns: [
            {
                field: 'details', headerName: '', width: 40,
                valueGetter: getWholeRow,
                renderCell: (params) => {
                    const actions = [
                        {
                            title: t('Account Details'),
                            icon: <ListIcon data-cy-id={"accountsDetailsButton"} />,
                            color: 'primary',
                            isHidden: false,
                            isDisabled: false,
                            requiredPermission: 'Account:Read',
                            onClick: () => {
                                props.setDrawerOpen(true);
                            }
                        },
                    ];
                    return (
                        <UcIconButtons actions={actions} />
                    );
                }
            },
            { field: 'id', headerName: t('Id'), flex: 1 },
            { field: 'cepOrgId', headerName: t('CEP Org ID'), flex: 1, width: 200 },
            { field: 'customerId', headerName: t('Customer ID'), flex: 1, width: 200 },
            {
                field: 'activeMeterCount', headerName: t('Active'), flex: 1, minWidth: 100,
                valueFormatter: ( params ) => {
                    return params.value > 0 ? t("Yes") : t("No");
                }
            },
            { field: 'extSource', headerName: t('Ext Source'), flex: 1, width: 200 },
            { field: 'extAccountId', headerName: t('Ext Account ID'), flex: 1, width: 200 },
            { field: 'extAccountNumber', headerName: t('Account Number'), flex: 1, minWidth: 200 },
            { field: 'name', headerName: t('Name'), flex: 1, minWidth: 250 },
            { field: 'address', headerName: t('Address'), flex: 1, minWidth: 200 },
            { field: 'phone1', headerName: t('Phone #1'), flex: 1, minWidth: 150 },
            { field: 'email', headerName: t('Email'), flex: 1, minWidth: 250 },
            { field: 'createdBy', headerName: t('Created By'), flex: 1, width: 200 },
            { field: "createdTs", headerName: t('Created On'), flex: 1, minWidth: 200, valueFormatter: (params) => { return dateFormatter(params.value); } },
            { field: 'updatedBy', headerName: t('Updated By'), flex: 1, width: 200 },
            { field: "updatedTs", headerName: t('Updated On'), flex: 1, minWidth: 200, valueFormatter: (params) => { return dateFormatter(params.value); } },
        ],
        rows: [],
        rowCount: -1,
    };
    const [dataItems, setDataItems] = useState(defaultDataItems);

    const defaultSelectedItem = { dataItem: {}, selectedId: 0 };
    const [selectedItem, setSelectedItem] = useState(defaultSelectedItem);

    const isDataLoaded = useRef(false);

    const dataReceived = (swrResult) => {
        var dataReceivedItems = swrResult.result;

        var selectedDataItems = dataReceivedItems.map((item) => {
            return { ...item, selected: item.id === selectedItem.selectedId };
        });
        selectedDataItems.iteration = dataReceivedItems.iteration;
        selectedDataItems.rowCount = swrResult.rowCount;
        if (selectedDataItems.length > selectedDataItems.rowCount) {
            selectedDataItems.rowCount = selectedDataItems.length;
        }

        setDataItems({ ...defaultDataItems, rows: [...dataReceivedItems], rowCount: swrResult.rowCount });
    };

    const swrAccounts = useAccounts(filter);
    if (!swrAccounts.result && defaultDataItems.rowCount !== -1) {
        setDataItems(defaultDataItems);
    }

    useLayoutEffect(() => {
        if (swrAccounts.result) {
            isDataLoaded.current = true;
            var filterString = JSON.stringify(filter);
            if (needsDataUpdate.current !== filterString) {
                needsDataUpdate.current = filterString;
                dataReceived(swrAccounts);
            }
        }
    });

    React.useEffect(() => {
        if (filter.generalSearch !== debounced) {
            setFilter((prevFilter) => ({
                ...prevFilter,
                generalSearch: debounced,
            }));
        }
    }, [debounced, filter]);

    const updateFilter = (newFilter) => {
        setFilter(newFilter);
        props.onFilterChange(newFilter);
    };

    return (
        <div
            data-cy-id={"accountUserGridWrapper"}
            onDoubleClick={props.doubleClick}
        >
            <DataGridPro
                autoHeight={!props.isFullPage}
                paginationModel={{page: filter.skip / filter.take, pageSize: filter.take}}
                loading={!isDataLoaded.current || !swrAccounts.result}
                onPaginationModelChange={(model) => {
                    updateFilter({ ...filter, skip: model.page * model.pageSize, take: model.pageSize })
                }}
                sortingMode='server'
                paginationMode='server'
                filterMode='server'
                sortModel={filter.sort}
                onSortModelChange={(model) => updateFilter({ ...filter, sort: model })}
                filterModel={filterModel}
                rowsPerPageOptions={[5, 10, 20]}
                onRowSelectionModelChange={(newSelectionModel) => {
                    if (newSelectionModel.length !== 1) {
                        setSelectedItem(defaultSelectedItem);
                        props.onSelected(defaultSelectedItem.dataItem);
                        return;
                    }
                    const selectedId = newSelectionModel[0];
                    var newSelectedItem = dataItems.rows.filter(obj => { return obj.id === selectedId });
                    if (newSelectedItem.length !== 1) {
                        setSelectedItem(defaultSelectedItem);
                        props.onSelected(defaultSelectedItem.dataItem);
                        return;
                    }
                    newSelectedItem = newSelectedItem[0];
                    var newlySelectedItem = { dataItem: newSelectedItem, selectedId: selectedId };
                    
                    setSelectedItem(newlySelectedItem);
                    props.onSelected(newlySelectedItem.dataItem);
                }}
                pagination
                components={{
                    Toolbar: () => {return  props.toolbar || <></>},
                    Pagination: UcPagination,
                }}
                {...dataItems}
            />
        </div>
    );
}

AccountGrid.defaultProps = {
    onSelected: (selectedItem) => { console.log("onSelected not implemented", selectedItem); },
    onFilterChange: (filter) => { console.log("onFilterChange not implemented", filter); },
};
