import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

export const useResponsive = () => {
    const theme = useTheme();

    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const isTablet = useMediaQuery(theme.breakpoints.between('sm', 'md'));
    const isLaptop = useMediaQuery(theme.breakpoints.between('md', 'lg'));
    const isDesktop = useMediaQuery(theme.breakpoints.up('lg'));
    return {
        isMobile: isMobile,
        mobileMediaQuery: theme.breakpoints.down('sm'),
        isTablet: isTablet,
        tabletMediaQuery: theme.breakpoints.between('sm', 'md'),
        isLaptop: isLaptop,
        laptopMediaQuery: theme.breakpoints.between('md', 'lg'),
        isDesktop: isDesktop,
        desktopMediaQuery: theme.breakpoints.up('lg'),
    };
};