import * as React from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
import { useUsers } from 'components/adminUc/Users/UserAPIEndpoints';
import { debounce } from "lodash";

// From: https://mui.com/components/autocomplete/#Asynchronous.js
export const UserSelect = (props) => {
    const [open, setOpen] = React.useState(false);
    const [options, setOptions] = React.useState([]);
    const needsUpdate = React.useRef(true);

    const [filter, setFilter] = React.useState({
        take: 100,
        skip: 0,
        sort: null,
        generalSearch: "",
    });

    let loading = true;
    let loadedOptions = [];

    const swrUsers = useUsers(filter);
    if (swrUsers.result && !swrUsers.isLoading && !swrUsers.isError)
    {
        loadedOptions = swrUsers.result;
        loading = false;
        if (needsUpdate.current) {
            needsUpdate.current = false;
            setOptions([...loadedOptions]);
        }
    }
    else
    {
        needsUpdate.current = true;
        loading = true;
    }

    // Simple debounce so we only query every 200 ms pause while typing
    // TODO use a different debounce. (mantine hooks?)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const changeHandler = React.useCallback(debounce((newGeneralSearch) => {
        if (newGeneralSearch === "") {
            newGeneralSearch = null;
        }
        needsUpdate.current = true;
        setFilter({...filter, generalSearch: newGeneralSearch});
    }, 200), [filter]);

    return (
        <Autocomplete
            id={props.id || "userSelect"}
            open={open}
            value={props.value}
            onChange={(event, newValue) => {
                props.onChange(event, newValue);
            }}
            onOpen={() => {
                setOpen(true);
            }}
            onClose={() => {
                if (filter.generalSearch !== "")
                {
                    needsUpdate.current = true;
                    setFilter({...filter, generalSearch: ""});
                }
                setOpen(false);
            }}
            isOptionEqualToValue={(option, value) => option.username === value.username || option.id === value.id}
            getOptionLabel={(option) => option.username}
            options={options}
            loading={loading}
            renderInput={(params) => (
                <TextField
                    {...params}
                    onChange={(e) => {
                        changeHandler(e.target.value);
                    }}
                    label={props.label || "Users"}
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                        <React.Fragment>
                            {loading ? <CircularProgress color="inherit" size={20} /> : null}
                            {params.InputProps.endAdornment}
                        </React.Fragment>
                        ),
                    }}
                />
            )}
        />
    );
};

UserSelect.defaultProps = {
    onChange: (event, value) => { console.log("onChange not implemented", event, value); },
};