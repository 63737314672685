import Paper from '@mui/material/Paper';
import Draggable from 'react-draggable';

export function UcDraggable(props) {
    return (
        <Draggable
            handle={props.handle || "#draggable-dialog-title"}
            cancel={props.cancel || '[class*="MuiDialogContent-root"]'}
        >
            <Paper {...props}/>
        </Draggable>
    );
}