import React, { useState, useContext, useEffect, useLayoutEffect } from 'react';
import { getOrgsDefaultItem } from './OrgAPIEndpoints';
import { PageContext } from '../../../lib/PageProvider';
import { OrgGridStateful } from "./OrgGridStateful"
import { OrgFormDialog } from './OrgFormDialog';
import { mutateUrls } from "../../../lib/useGenericSWR";
import { useSWRConfig } from "swr";
import './OrgListPage.scss';
import { EnsureUser } from '../../auth/EnsureUser';
import { UcToolbar } from '../../common/UcToolbar';
import { useHasPermission } from 'components/auth/PermissionApiEndpoint';
import { generateBuiltInRoles } from 'components/adminUc/Roles/RoleAPIEndpoints';
import AddIcon from '@mui/icons-material/Add';
import GroupsIcon from '@mui/icons-material/Groups';
import { Box, Divider, Drawer } from '@mui/material';
import { UcDrawerHeader } from 'components/common/UcDrawerHeader';
import { UcListPage } from 'components/common/UcListPage';
import { OrgDetailsPage } from './OrgDetailsPage';

export const OrgListPage = (props) => {
    const [editDialog, setEditDialog] = useState({ open: false });
    const [selectedItem, setSelectedItem] = useState(null);
    const [pageState, pageDispatch] = useContext(PageContext);
    const [generalSearch, setGeneralSearch] = useState('');
    const [orgIdsOnPage, setOrgIdsOnPage] = useState([]);
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [reMutateUrls, setReMutateUrls] = useState(false);
    const swrConfig = useSWRConfig();

    const closeHandler = () => {
        setEditDialog({ open: false, itemToEdit: editDialog.dataItem });
    }

    const saveHandler = () => {
        closeHandler();
        // Also, remutate
        setReMutateUrls(true);
    }

    const openDialog = (dataItem) => {
        if (!dataItem) {
            return;
        }
        if (!dataItem.formIteration) {
            dataItem.formIteration = "edit" + dataItem.id;
        }
        setEditDialog({ open: true, itemToEdit: dataItem });
    }

    const createItem = () => {
        openDialog(getOrgsDefaultItem());
    }

    const editItem = (item) => {
        openDialog(item);
        handleDrawerClose();
    }

    const handleCallback = (orgs) => {
        const activeOrgs = orgs.filter((org) => { 
            if (org.status === 'ACTIVE') { 
                return true; 
            }

            return false; 
        });
        setOrgIdsOnPage(activeOrgs.map(o => {return o.cepOrgId}));
    }

    const generateRoles = () => {
        generateBuiltInRoles(orgIdsOnPage);
    }

    const actions = [
        {
            icon: <AddIcon />,
            title: 'Add Organization',
            toolTip: 'Add Organization',
            onClick: () => { createItem(); },
            isHidden: !useHasPermission("Org:Modify"),
            isDisabled: false
        },
        {
            icon: <GroupsIcon />,
            title: 'Generate Built-in Roles',
            toolTip: 'Generate Built-in Roles for Active Orgs on current page',
            onClick: () => { generateRoles(); },
            isHidden: !useHasPermission("Role:Modify"),
            isDisabled: false
        },
    ];

    useLayoutEffect(() => {
        if (reMutateUrls) {
            mutateUrls(swrConfig);
            setReMutateUrls(false);
        }
    }, [reMutateUrls, swrConfig]);

    useEffect(() => {
        pageDispatch({
            header: "Organizations",
        });
    }, [pageState.header, selectedItem, pageDispatch]);

    const handleDrawerClose = () => {
        setDrawerOpen(false);
    }

    const doubleClick = () => {
        if (selectedItem !== null && selectedItem?.selectedId !== 0 && !editDialog.open) {
            setDrawerOpen(true);
        }
    };
    const drawerWidth = "50vw";

    console.log("OrgListPage - Loaded");
    return (
        <div data-cy-id={"orgListGridWrapper"}>
            <UcListPage>
                <EnsureUser permissions={["Org:Read"]}>
                    <h1 className="pageHeader">Organizations</h1>
                    <UcToolbar
                        hasGeneralSearch={true}
                        onGeneralSearchChange={(value) => {
                            setGeneralSearch(value);
                        } }
                        actions={actions}>
                    </UcToolbar>
                    <OrgFormDialog
                        open={editDialog.open}
                        formType={editDialog.itemToEdit?.id > 0 ? "Edit" : "Add"}
                        itemToEdit={editDialog.itemToEdit}
                        onCancel={closeHandler}
                        onSave={saveHandler}
                    />
                    {!reMutateUrls &&
                        <OrgGridStateful
                            setDrawerOpen={setDrawerOpen} 
                            generalSearch={generalSearch}
                            parentCallback={handleCallback}
                            onEditItem={editItem}
                            doubleClick={doubleClick}
                            onSelected={(gridSelectedItem) => {
                                setSelectedItem({ ...gridSelectedItem });                           
                            }} />
                    }
                    <Drawer
                        anchor="right"
                        open={drawerOpen}
                        onClose={handleDrawerClose}
                    >
                        <UcDrawerHeader justify="flex-start"/>
                        <Box
                            sx={{ width: drawerWidth, margin: '10px' }}
                            role="presentation"
                        >
                            <Divider />
                            {selectedItem &&
                                <OrgDetailsPage cepOrgId={selectedItem.id} showDetails={false} />
                            }
                        </Box>
                    </Drawer>
                </EnsureUser>
            </UcListPage>
        </div>
    );
};