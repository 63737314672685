//@ts-ignore
import { ApiClient } from "../../../lib/ApiClient";
import { useGenericSWR } from "../../../lib/useGenericSWR";
import { SwrOptions } from '../../../lib/useGenericSWR.interfaces';
import { BlacklistedIpAddress } from "./BlacklistedIpAddress.interfaces";

const apiEndpoint = 'api/v1/admin/BlacklistedIpAddress';

const apiClient = new ApiClient();
apiClient.getObjectName = () => {
    return "Blacklisted Ip Address";
};

const useBlacklistedIpAddress = (id: number, swrOptions = {}) => {
    // SWR will prevent duplicate requests
    // It will also maintain data state as long as your are using the same urls for your requests
    return useGenericSWR(apiEndpoint + "/" + id, apiClient, { ...swrOptions as SwrOptions });
};

const useBlacklistedIpAddresses = (filter: any, swrOptions = {}) => {
    // SWR will prevent duplicate requests
    // It will also maintain data state as long as your are using the same urls for your requests
    const params = new URLSearchParams();
    params.append('filter', JSON.stringify(filter));
    return useGenericSWR(apiEndpoint + "?" + params.toString(), apiClient, { ...swrOptions as SwrOptions });
};

const saveBlacklistedIpAddress = (BlacklistedIpAddress: BlacklistedIpAddress) => {
    return insertBlacklistedIpAddress(BlacklistedIpAddress);
}
const insertBlacklistedIpAddress = (BlacklistedIpAddress: BlacklistedIpAddress) => {
    return apiClient.poster(apiEndpoint, BlacklistedIpAddress);
};

const updateBlacklistedIpAddress = (BlacklistedIpAddress: BlacklistedIpAddress, overrideMessage: string, doToast: boolean = true ) => {
    return apiClient.putter(apiEndpoint, BlacklistedIpAddress, overrideMessage, true, null, doToast);
};

export {
    useBlacklistedIpAddress,
    useBlacklistedIpAddresses,
    saveBlacklistedIpAddress,
    insertBlacklistedIpAddress,
    updateBlacklistedIpAddress
};