// @ts-ignore
import { ApiClient } from "lib/ApiClient";
import { useGenericSWR } from "lib/useGenericSWR";

export const orgFaqSettingApiEndpoint = "api/v1/MyOrgFaqSetting";

export interface OrgFaqSetting {
    id?: number;
    cepOrgId: number;
    question: string;
    answer: string;
    sortOrder?: number;
}

let formIteration = 0;

const getOrgFaqSettingsDefaultItem = () => {
    formIteration = formIteration + 1;
    return {
        formIteration: "new" + formIteration,
        id: 0,
        cepOrgId: null,
        question: "",
        answer: "",
        sortOrder: 0,
    };
};

const apiClient = new ApiClient();
apiClient.requiresAuth = false;
apiClient.getDefaultItem = () => getOrgFaqSettingsDefaultItem();
apiClient.getObjectName = () => {
    return "FAQ Settings";
};

const useMyOrgFaqSettings = (swrOptions: any = {}) => {
    const filter = {
        take: 100,
        skip: 0,
        sort: [{ field: "sortOrder", sort: "asc" }],
    };

    const params = new URLSearchParams();
    params.append("filter", JSON.stringify(filter));
    return useGenericSWR(
        orgFaqSettingApiEndpoint + "?" + params.toString(),
        apiClient,
        { ...swrOptions }
    );
};

const mutateMyOrgFaqSettings = () => {
    apiClient.mutateFetchUrls();
};

export {
    useMyOrgFaqSettings,
    mutateMyOrgFaqSettings,
    getOrgFaqSettingsDefaultItem,
};
