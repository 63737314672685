import React from "react";
import { ThemeProvider } from '@mui/material';
import { createTheme } from '@mui/material/styles';
import CircularProgress from '@mui/material/CircularProgress';
import { domain } from "domain";
import { setLightDarkMode, useLightDarkMode } from "components/user/useLightDarkMode";

const themePath = `${process.env.PUBLIC_URL}/themes`;
const fallbackTheme = `{
  "palette": {
    "mode": "light",
    "primary": {
      "main": "#3f51b5"
    },
    "secondary": {
      "main": "#eceff1",
      "contrastText": "#555555"
    }
  },
  "props": {
  }
}`;

const defaultPropsLight = {
    Rechart: {
        axisColor: "black",
        cartesianGridColor: "#ddd",
    }
};

const defaultPropsDark = {
    Rechart: {
        axisColor: "white",
        cartesianGridColor: "#222",
    }
};

const defaultBreakPoints = {
    values: {
      xs: 0,
        sm: 600,
        md: 900,
        lg: 1200,
        xl: 1536,
    },
  };

const fetchTheme = async (themeName) => {
    if (!themeName || themeName === 'undefined') {
        themeName = "default";
    }
    return fetch(`${themePath}/${themeName}.json?cache=2023-06-22`)
        .then((response) => {
            return response.json();
        })
        .then((data) => {
            return data;
        })
        .catch((error) => {
            console.log("THEME ERROR", error, `${themePath}/${themeName}.json`);
            return fallbackTheme;
        });
}

const initialThemeState = { themeName: domain.theme, themeMode: "light", logo: domain.theme + ".light.png" };

const themeStateReducer = (state, action) => {

    if (!action.ignoreSavePreference)
    {
        setLightDarkMode(action.mode);
    }

    return {
        ...state,
        themeName: action.name,
        themeMode: action.mode,
        logo: action.name + "." + action.mode + ".png"
    };
};

export const UcThemeContext = React.createContext({
    themeState: initialThemeState,
    dispatch: () => null
});

export const UcThemeProvider = ({ children }) => {
    const [loading, setLoading] = React.useState(true);
    const [theme, setTheme] = React.useState(createTheme({
        palette: initialThemeState.palette,
    }));
    const [themeState, themeDispatch] = React.useReducer(themeStateReducer, initialThemeState); // initial state is default loading theme?

    const themeMode = useLightDarkMode();

    let themeName = `${themeState.themeName}.${themeMode}`;

    if (themeMode !== themeState.themeMode) {
        themeDispatch({ name: themeState.themeName, mode: themeMode, ignoreSavePreference: true });
    }

    React.useEffect(() => {
        async function fetchThemeLocal() {
            setLoading(true);

            let newTheme = await fetchTheme(themeName);
            if (!newTheme) {
                newTheme = fallbackTheme;
            }

            let defaultProps = defaultPropsLight;
            if (themeMode === "dark") {
                defaultProps = defaultPropsDark;
            }

            if (newTheme?.palette !== undefined) {
                setTheme(createTheme({
                    palette: newTheme.palette,
                    breakpoints: newTheme.breakpoints??defaultBreakPoints,
                    props: {...defaultProps, ...newTheme.props},
                }));
            }

            setLoading(false);
        }
        fetchThemeLocal();

    }, [themeState, themeName, themeMode]);

        return (
            <>
                {!loading &&
                    <UcThemeContext.Provider value={[themeState, themeDispatch]}>
                        <ThemeProvider theme={theme}>
                            {children}
                        </ThemeProvider>
                    </UcThemeContext.Provider>
                }
                {loading &&
                    <div style={{height: "90vh", width: '100%', position: "relative"}}>
                        <CircularProgress style={{position: 'absolute', left: '50%', top: '40%'}}/>
                    </div>
                }
            </>
        );
    };