import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import DialogTitle from '@mui/material/DialogTitle';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import {UcMobileNavPageContent} from './UcMobileNavPageContent';
import { useDomainNavigate } from 'domain';

export const UcMobileNavPage = (props) => {
    const navigate = useDomainNavigate();
    const { views, onChange, value } = props;
    const [open, setOpen] = React.useState(true);
    const [openPage, setOpenPage] = React.useState(false);
    const [pageTitle, setPageTitle] = React.useState('');
    const [pageContent, setPageContent] = React.useState('');

    const handleClose = () => {
        setOpen(false);

        navigate('/');
    };

    const handleClosePage = () => {
        setOpenPage(false);
    };

    const handleClickPage = React.useCallback((title, content) => {
        setPageTitle(title);
        setPageContent(content);
        setOpenPage(true);
        onChange(title);
    }, [onChange]);

    React.useEffect(() => {
        if (value !== 0) {
            let view = views.filter(view => {
                return view.title === value;
            })[0];

            setPageTitle(view?.title);
            setPageContent(view?.contentRender);
            setOpenPage(true);
        }
     }, [value, views]);

    console.log("UcMobileNavPage - Loaded");
    return (
        <>
            <Dialog
                fullScreen={true}
                open={open}
                onClose={handleClose}
                aria-labelledby="responsive-dialog-title"
            >
                <div
                 style={{
                    zIndex: 9999,
                    boxShadow: '0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)'
                }}>
                    <DialogTitle id="responsive-dialog-title" sx={{pl:0}}>
                        <Button autoFocus onClick={handleClose}><ChevronLeftIcon /></Button>
                        <Typography
                                sx={{ display: 'inline' }}
                                component="span"
                                variant="subtitle1"
                            >
                                {props.pageTitle}
                        </Typography>
                    </DialogTitle>
                </div>
                <DialogContent sx={{p: 0}}>
                    <List sx={{ width: '100%' }}>
                        {views.map((view, index) => (!view.isHidden && (
                            <div key={index}>
                                <ListItem alignItems="flex-start" sx={{p: 0}}>
                                    <ListItemButton onClick={() => handleClickPage(view.title, view.contentRender)}>
                                        <ListItemText
                                        primary={view.title}
                                        secondary={
                                            <React.Fragment>
                                                <Typography
                                                    sx={{ display: 'inline' }}
                                                    component="span"
                                                    variant="body2"
                                                    color="text.primary"
                                                >
                                                {view.subtitle}
                                                </Typography>
                                            </React.Fragment>
                                        }
                                        />
                                    </ListItemButton>
                                </ListItem>
                                {views.length > (index + 1) && (
                                    <Divider component="li" />
                                )}
                            </div>
                        )))}
                    </List>
                    <UcMobileNavPageContent
                        open={openPage}
                        pageTitle={pageTitle}
                        pageContent={pageContent}
                        onClose={handleClosePage}
                        contentBackgroundColor={props.contentBackgroundColor}
                    />
                </DialogContent>
            </Dialog>
        </>
    );
}
