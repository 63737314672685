// @ts-ignore
import { ApiClient } from "../../../lib/ApiClient";
import { useGenericSWR } from "../../../lib/useGenericSWR";
import { SwrOptions } from '../../../lib/useGenericSWR.interfaces';

const apiClient = new ApiClient();
const apiEndpoint = 'api/v1/BillingApi';

const useBillApiHealth = (swrOptions = {}) => {
    const url = apiEndpoint + "/BillingApiHealth";

    return useGenericSWR(url, apiClient, { ...swrOptions as SwrOptions});
};

export {
    useBillApiHealth
}