import { getMyOrgsDefaultItem, myOrgValidationSchema } from 'components/customer/orgs/MyOrgAPIEndpoints';
import PropTypes from 'prop-types';
import { Formik, Form, Field } from 'formik';
import {useRef, useEffect, useState} from 'react';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import Grid from '@mui/material/Grid';
import { Typography, Stack, Popover, Box, IconButton, Tooltip } from '@mui/material';
import { useTranslation } from "react-i18next";
import MdEditor from 'react-markdown-editor-lite';
import MarkdownIt from 'markdown-it';
import { TextField } from 'formik-mui';
import InfoIcon from '@mui/icons-material/Info';

export const MyOrgForm = (props) => {
    const { t } = useTranslation();
    const itemToEdit = props.itemToEdit || getMyOrgsDefaultItem();
    const dialogSubmit = useRef(props.dialogSubmit);
    const formRef = useRef();
    const [contactInformation, setContactInformation] = useState(itemToEdit.contactInformation)
    const [footerInformation, setFooterInformation] = useState(itemToEdit.footerInformation)

    const mdParser = new MarkdownIt(/* Markdown-it options */);

    const [anchorElSupportEmailInfo, setAnchorElSupportEmailInfo] = useState(null);
    const openSupportEmailInfoPopover = Boolean(anchorElSupportEmailInfo);
    const idSupportEmailInfoPopover = openSupportEmailInfoPopover ? 'simple-popover' : undefined;

    function handleContactEditorChange({ html, text }) {
        setContactInformation(text);
    }

    function handleFooterEditorChange({ html, text }) {
        setFooterInformation(text);
    }

    useEffect(() => {
        if (props.dialogSubmit !== dialogSubmit.current) {
            dialogSubmit.current = props.dialogSubmit;
            if (formRef.current) {
                formRef.current.handleSubmit();
            }
        }
    });

    const handleClickSupportEmailInfo = (event) => {
        setAnchorElSupportEmailInfo(event.currentTarget);
    };

    const handleCloseDeliveryMethodInfo = () => {
        setAnchorElSupportEmailInfo(null);
    };

    return (
        <div className='ignoreCustomFormStyles'>
            <Formik
                innerRef={formRef}
                initialValues={itemToEdit}
                validationSchema={myOrgValidationSchema}
                onSubmit={(values) => {
                    values.contactInformation = contactInformation;
                    values.footerInformation = footerInformation;
                    props.onSubmit(values);
                }}
            >
                <Form readOnly={props.readonly}>
                    <Grid container spacing={2} style={{paddingTop: '8px'}}>                      
                        <Grid item xs={12} sm={12} md={12}>
                            <Field name={"twoFactorEnabled"}>
                                {({ field: { value: fieldValue }, form: { setFieldValue } }) => (
                                    <FormControlLabel
                                        label={t("Multi-Factor Enabled")}
                                        control={
                                            <Switch
                                                data-cy-id="myorg_form_twofactor"
                                                checked={fieldValue}
                                                onChange={(e, changeValue) => {
                                                    setFieldValue("twoFactorEnabled", changeValue);
                                                }}
                                            />
                                        } 
                                    />
                                )}
                            </Field>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12}>
                            <Stack direction="row" spacing={1} alignItems="center">
                                <Typography> {t("Fahrenheit (°F)")} </Typography>
                                <Field name={"temperatureUnit"}>
                                    {({ field: { value: fieldValue }, form: { setFieldValue } }) => (
                                        <FormControlLabel
                                            control={
                                                <Switch
                                                    data-cy-id="myorg_form_temp"
                                                    checked={fieldValue === "C" ? true : false}
                                                    onChange={(e, changeValue) => {
                                                        setFieldValue("temperatureUnit", changeValue ? "C" : "F");
                                                     }}
                                                />
                                            }
                                        />
                                    )}
                                </Field>
                                <Typography> {t("Celsius (°C)")} </Typography>
                            </Stack>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12}>
                            <Stack direction="row" spacing={1} alignItems="center">
                                <Typography> {t("Imperial")} </Typography>
                                <Field name={"measurementUnit"}>
                                    {({ field: { value: fieldValue }, form: { setFieldValue } }) => (
                                        <FormControlLabel
                                            control={
                                                <Switch
                                                    data-cy-id="myorg_form_measurement"
                                                    checked={fieldValue === "Metric"}
                                                    onChange={(e, changeValue) => {
                                                        setFieldValue("measurementUnit", changeValue ? "Metric" : "Imperial");
                                                    }}
                                                />
                                            }
                                        />
                                    )}
                                </Field>
                                <Typography> {t("Metric")} </Typography>
                            </Stack>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6}>
                            <Grid container>
                                <Grid item xs={11}>
                                    <Field
                                        name={"supportEmail"}
                                        component={TextField} 
                                        data-cy-id="myorg_form_support_email"
                                        label={t("Customer Support Email")}
                                    />
                                </Grid>
                                <Grid item xs={1}>
                                    <Tooltip title="Customer Support Email Info">
                                        <IconButton onClick={handleClickSupportEmailInfo} color="primary" aria-label="support email" sx={{ p: 0, ml: 1, mt: 1.5}}>
                                            <InfoIcon sx={{ fontSize: 30}} />
                                        </IconButton>
                                    </Tooltip>
                                    <Popover
                                            id={idSupportEmailInfoPopover}
                                            open={openSupportEmailInfoPopover}
                                            anchorEl={anchorElSupportEmailInfo}
                                            onClose={handleCloseDeliveryMethodInfo}
                                            anchorOrigin={{
                                                vertical: 'bottom',
                                                horizontal: 'center',
                                            }}
                                        >
                                            <Box
                                                width={300}
                                                sx={{ p: 2 }}>
                                                <Typography variant='body2'>
                                                    {t('The email address specified in this field will be used to receive help requests submitted by utility customers. If no email is provided, the Help Request page will be hidden.')}
                                                </Typography>
                                            </Box>
                                    </Popover>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12}>
                            <Typography variant="subtitle2" gutterBottom> {t("Contact Information Editor:")} </Typography>
                            <div data-cy-id={"myorg_form_contactinfo"}>
                                <MdEditor
                                    renderHTML={text => mdParser.render(text)}
                                    onChange={handleContactEditorChange}
                                    style={{ height: '400px' }}
                                    value={contactInformation}
                                    placeholder={t('Type in Org Contact Information here')}
                                />
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12}>
                            <Typography variant="subtitle2" gutterBottom> {t("Footer Information Editor:")} </Typography>
                            <div data-cy-id={"myorg_form_footerinfo"}>
                                <MdEditor
                                    renderHTML={text => mdParser.render(text)}
                                    onChange={handleFooterEditorChange}
                                    style={{ height: '400px' }}
                                    value={footerInformation}
                                    placeholder={t('Type in Org Footer Information here')}
                                />
                            </div>
                        </Grid>
                    </Grid>
                </Form>
            </Formik>
        </div>
    );
}

MyOrgForm.defaultProps = {
    id: 0,
    dialogSubmit: 0,
    changeIteration: 0,
    onSubmit: (values) => { console.log("onSubmit not implemented", values); },
    onError: (error) => { console.error("onError not implemented", error); },
};

MyOrgForm.propTypes = {
    id: PropTypes.number,
    changeIteration: PropTypes.number,
    dialogSubmit: PropTypes.number,
};