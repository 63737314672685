import React, { useState, useLayoutEffect, useRef, useEffect } from 'react';
import { DataGridPro } from '@mui/x-data-grid-pro';
import { useRateCharges } from './RateChargeApiEndpoints';
import { UcFullPage } from '../../common/UcFullPage';
import { UcPagination } from 'components/common/UcPagination';
import { UcIconButtons } from 'components/common/UcIconButtons';
import { getWholeRow } from 'lib/DataGridHelpers';
import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/Delete';
import ListIcon from '@mui/icons-material/List';
import { useTranslation } from "react-i18next";
import { enumFormatter } from 'lib/EnumFormatter';
import { useEnums } from 'components/auth/PermissionApiEndpoint';

export default function RateChargeGrid(props) {
    const { t } = useTranslation();
    const rateChargeTypeEnums = useEnums("RateChargeType");
    const initialSort = [{ field: "id", sort: "desc" }];
    const sort = props.sort || initialSort;

    const initialDataState = props.dataState || { skip: 0, take: 10, };

    const [filter, setFilter] = useState({
        take: initialDataState.take,
        skip: initialDataState.skip,
        sort: sort,
        rateVersionId: parseInt(props.rateVersionId),
        generalSearch: props.generalSearch,
    });

    useEffect(() => {
        if (props.generalSearch !== filter.generalSearch) {
            setFilter({ ...filter, generalSearch: props.generalSearch });
        }
    }, [filter, setFilter, props.generalSearch]);

    let initialFilterModel = {
        items: [
            { columnField: 'id', operatorValue: 'contains', value: '' }
        ],
        linkOperator: 'and',
    };

    const [filterModel] = useState(initialFilterModel);

    const needsDataUpdate = useRef("");

    let defaultDataItems = {
        columns: [
            {
                field: 'details', headerName: '', width: 50,
                valueGetter: getWholeRow,
                renderCell: (params) => {
                    const actions = [
                        {
                            title: t('Edit Rate Charge Rules'),
                            icon: <ListIcon data-cy-id={"rateChargeDetailsButton"}/>,
                            color: 'primary',
                            isHidden: params.value.type.toUpperCase() === "FIXED" || params.value.type.toUpperCase() === "MONTHLY",
                            isDisabled: false,
                            requiredPermission: 'RateCharge:Read',
                            onClick: () => { props.onEditRules(params.value); },
                        },
                    ];
                    return (
                        <UcIconButtons actions={actions} />
                    );
                }
            },
            { field: 'name', headerName: t('Name'), width: 250 },
            { field: 'type', headerName: t('Type'), width: 150, valueFormatter: (params) => { return enumFormatter(rateChargeTypeEnums, params.value); } },          
            { field: 'ruleSet', headerName: t('Date Range'), width: 250,
                valueFormatter: (params) => {
                    if (params.value.dateRanges.length > 0) {
                        const start = new Date();
                        start.setMonth(params.value.dateRanges[0]?.start?.month - 1);
                        start.setDate(params.value.dateRanges[0]?.start?.day);
                        const startDateString = start.toLocaleDateString('en-US', {
                            month: 'long',
                            day: '2-digit'
                        });

                        const end = new Date();
                        end.setMonth(params.value.dateRanges[0]?.end?.month - 1);
                        end.setDate(params.value.dateRanges[0]?.end?.day);

                        const endDateString = end.toLocaleDateString('en-US', {
                            month: 'long',
                            day: '2-digit'
                        });

                        return params.value.dateRanges[0]?.end?.month !== undefined ? `${startDateString} - ${endDateString}` : startDateString ;
                    }
                    else {
                        return t('Year-round');
                    }
               }
            },
            { field: 'description', headerName: t('Description'), width: 500 },
            { field: 'id', headerName: '',
                valueGetter: getWholeRow,
                renderCell: (params) => {
                    const actions = [
                        {
                            title: t('Edit Rate Charge'),
                            icon: <EditIcon />,
                            color: 'primary',
                            isHidden: false,
                            isDisabled: false,
                            requiredPermission: 'RateCharge:Modify',
                            onClick: () => { props.onEditItem(params.value); },
                        },
                        {
                            title: t('Delete Rate Charge'),
                            icon: <DeleteIcon />,
                            color: 'error',
                            isHidden: false,
                            isDisabled: false,
                            requiredPermission: 'RateCharge:Delete',
                            onClick: () => { props.onDeleteItem(params.value); },
                        }
                    ];

                    return (
                        <UcIconButtons actions={actions} />
                    );
                }
            },
        ],
        rows: [],
        rowCount: -1,
    };
    const [dataItems, setDataItems] = useState(defaultDataItems);

    const defaultSelectedItem = { dataItem: {}, selectedId: 0 };
    const [selectedItem, setSelectedItem] = useState(defaultSelectedItem);

    const isDataLoaded = useRef(false);

    const dataReceived = (swrResult) => {
        var receivedDataItems = swrResult.result;

        var selectedDataItems = receivedDataItems.map((item) => {
            return { ...item, selected: item.id === selectedItem.selectedId };
        });
        selectedDataItems.iteration = receivedDataItems.iteration;
        selectedDataItems.rowCount = swrResult.rowCount;
        if (selectedDataItems.length > selectedDataItems.rowCount) {
            selectedDataItems.rowCount = selectedDataItems.length;
        }
        
        //filter out generation type
        if(props.hideGenerationRate){
            receivedDataItems = receivedDataItems.filter(item => item.type !== 'GENERATION')
        }

        setDataItems({ ...defaultDataItems, rows: [...receivedDataItems], rowCount: swrResult.rowCount });
    };

    const swrRateCharges = useRateCharges(filter);
    const isLoading = swrRateCharges.isLoading || !isDataLoaded.current;
    if (!swrRateCharges.result && defaultDataItems.rowCount !== -1) {
        setDataItems(defaultDataItems);
    }

    useLayoutEffect(() => {
        if (swrRateCharges.result) {
            isDataLoaded.current = true;
            var filterString = JSON.stringify(filter);
            if (needsDataUpdate.current !== filterString) {
                needsDataUpdate.current = filterString;
                dataReceived(swrRateCharges);
            }
        }
    });

    const updateFilter = (newFilter) => {
        setFilter(newFilter);
        props.onFilterChange(newFilter);
    };

    const isFullPage = props.isFullPage || false;

    return (
        <UcFullPage isFullPage={isFullPage}>
            <div onDoubleClick={props.doubleClick}>
                <DataGridPro
                    disableColumnFilter={true}
                    disableColumnSelector={true}
                    loading={isLoading}
                    sortingMode='server'
                    autoHeight={!isFullPage}
                    filterMode='server'
                    sortModel={filter.sort}
                    onSortModelChange={(model) => updateFilter({ ...filter, sort: model })}
                    filterModel={filterModel}
                    paginationModel={{page: filter.skip / filter.take, pageSize: filter.take}}
                    onPaginationModelChange={(model) => updateFilter({ ...filter, skip: model.page * model.pageSize, take: model.pageSize })}nge={(newPageSize) => updateFilter({ ...filter, take: newPageSize })}

                    paginationMode='server'
                    rowsPerPageOptions={[5, 10, 20]}

                    onRowSelectionModelChange={(newSelectionModel) => {
                        if (newSelectionModel.length !== 1) {
                            setSelectedItem(defaultSelectedItem);
                            props.onSelected(defaultSelectedItem.dataItem);
                            return;
                        }
                        const selectedId = newSelectionModel[0];
                        var selectedDataItem = dataItems.rows.filter(obj => { return obj.id === selectedId });
                        if (selectedDataItem.length !== 1) {
                            setSelectedItem(defaultSelectedItem);
                            props.onSelected(defaultSelectedItem.dataItem);
                            return;
                        }
                        selectedDataItem = selectedDataItem[0];
                        var newlySelectedItem = { dataItem: selectedDataItem, selectedId: selectedId };

                        setSelectedItem(newlySelectedItem);
                        props.onSelected(newlySelectedItem.dataItem);
                    }}
                    pagination
                    components={{
                        Toolbar: () => {return  props.toolbar || <></>},
                        Pagination: UcPagination,
                    }}
                    {...dataItems}
                    initialState={{ pinnedColumns: { right: ['id'] } }}
                />
            </div>
        </UcFullPage>
    );
}

RateChargeGrid.defaultProps = {
    onSelected: (selectedItem) => { console.log("onSelected not implemented", selectedItem); },
    onFilterChange: (filter) => { console.log("onFilterChange not implemented", filter); },
};
