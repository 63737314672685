import { useTranslation } from "react-i18next";
import {
    Typography,
    Card,
    CardContent,
    Grid,
    TextField,
    Button,
    Tooltip,
    IconButton,
    ClickAwayListener
} from "@mui/material";
import { useState, useEffect } from "react";
// @ts-ignore
import { useResponsive } from 'lib/useResponsive';
// @ts-ignore
import { useUserAccounts } from './MyUserAccountAPIEndpoints';
import { UcLoading } from "../common/UcLoading";
// @ts-ignore
import { useUser } from 'components/auth/PermissionApiEndpoint';
import { useSWRConfig } from 'swr';
// @ts-ignore
import { mutateUrls } from 'lib/useGenericSWR';
import { MyNicknamePreferencesFormDialog } from './MyNicknamePreferencesFormDialog';
import { AccountMeter, UserAccount } from './MyUserAccount.interfaces';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
// @ts-ignore
import { DateTime } from 'luxon';

export const MyNicknamePreferences = () => {
    const { t } = useTranslation();
    const { isMobile } = useResponsive();
    const initialSort = [{ field: "Id", sort: "desc" }];
    const sort = initialSort;
    const [editDialog, setEditDialog] = useState<any>({
        open: false
    });
    const swrConfig = useSWRConfig();
    const [reMutateUrls, setReMutateUrls] = useState(false);

    const filter = { sort: sort };
    const swrUserAccounts = useUserAccounts(filter)
    const isLoading = swrUserAccounts.isLoading;
    const user = useUser();
    const username = user.email;

    const [openTooltip, setOpenTooltip] = useState(false);

    const handleTooltipClose = () => {
        setOpenTooltip(false);
    };

    const handleTooltipOpen = () => {
        setOpenTooltip(true);
    };

    const closeHandler = () => {
        setEditDialog({ open: false, itemToEdit: editDialog.dataItem });
    };

    const openDialog = (dataItem: any) => {
        if (dataItem && !dataItem.formIteration) {
            dataItem.formIteration = "edit" + dataItem.id;
        }
        setEditDialog({ open: true, itemToEdit: dataItem });
    };

    const saveHandler = () => {
        closeHandler();
        setReMutateUrls(true);
    };

    useEffect(() => {
        if (reMutateUrls) {
            mutateUrls(swrConfig);
            setReMutateUrls(false);
        }
    }, [reMutateUrls, swrConfig]);

    const formattedUserAccounts = swrUserAccounts?.result?.map((account: UserAccount) => {
        const meters = account?.metersJson ? JSON.parse(account.metersJson) : [];
        const yesterday = DateTime.now().minus({ days: 1 });
        const endOfYesterday = DateTime.fromISO(yesterday.toISODate()).plus({ days: 1 }).minus({ milliseconds: 1 });

        return {
            ...account,
            meters: meters?.filter((meter: AccountMeter) => {
                const startDate = DateTime.fromISO(meter.startTimestamp);
                const endDate = DateTime.fromISO(meter.endTimestamp);

                return yesterday >= startDate && endOfYesterday <= endDate;
            }).map((meter: any) => ({
                ...meter,
            })),
        };
    }) || [];

    const renderAccountMeters = (meters: AccountMeter[], isDelegate: boolean =  false) => {
        const groupedMeters: Record<string, any[]> = meters.reduce((result: any, meter: any) => {  
            const key = meter.meterCommodity;
            if (!result[key]) {
                result[key] = [];
            }
            result[key].push(meter);
            return result;
        }, {});

        return (
            <Grid container spacing={1}>
                {Object.keys(groupedMeters).map((commodity) => (
                    <Grid item xs={12} sm={6} md={6} lg={4} key={commodity}>
                        <Typography sx={{ mt: 1 }}>
                            <b>{commodity.toUpperCase() === "WATER" ? "Water" : "Electric"}</b>
                        </Typography>
                        {groupedMeters[commodity].map((meter) => (
                            <Grid container alignItems="center" spacing={1} key={meter.serialNo} sx={{ pb: 1 }}>
                                <Grid item>
                                    <Typography variant="body1">
                                        {t("Meter: ")+ meter.serialNo}
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <TextField
                                        id="outlined-read-only-input"
                                        data-cy-id={"myNicknamePreferences-accountNickname"}
                                        defaultValue={meter?.meterNickname}
                                        disabled={isDelegate}
                                        size="small"
                                        InputProps={{
                                            readOnly: true,
                                        }}
                                    />
                                </Grid>    
                            </Grid>
                        ))}
                    </Grid>
                ))}
            </Grid>
        );
    };

    return (
        <>
            <Card variant="outlined">
                <CardContent>
                    <Grid container direction="row">               
                        <Grid container direction="row" alignItems="center" justifyContent="space-between" sx={{ pb: isMobile && 2} }>
                            <Grid item>
                                <Typography
                                    sx={{ mb: 2 }}
                                    color="text.secondary"
                                    gutterBottom
                                >
                                    {t("Nicknames")}
                                    {isMobile ?
                                        <ClickAwayListener onClickAway={handleTooltipClose}>
                                            <Tooltip
                                                PopperProps={{
                                                    disablePortal: true,
                                                }}
                                                onClose={handleTooltipClose}
                                                open={openTooltip}                                             
                                                title={t("Add a nickname to your account or meter for easier identification. The nickname will appear alongside your account or meter number throughout the portal.")}
                                            >
                                                <IconButton color="info" onClick={handleTooltipOpen}>
                                                    <InfoOutlinedIcon />
                                                </IconButton>
                                            </Tooltip>
                                        </ClickAwayListener>
                                    :
                                        <Tooltip
                                            placement="right-end"
                                            title={t("Add a nickname to your account or meter for easier identification. The nickname will appear alongside your account or meter number throughout the portal.")}
                                        >
                                            <IconButton color="info">
                                                <InfoOutlinedIcon />
                                            </IconButton>
                                        </Tooltip>
                                }                                   
                                </Typography>
                            </Grid>
                            <Grid item>
                                <Button
                                    variant="contained"
                                    onClick={() => openDialog(formattedUserAccounts)}
                                >
                                    {t("Edit Nicknames")}
                                </Button>
                            </Grid>
                        </Grid>
                        <UcLoading isLoading={isLoading} hasFade={false}>
                            {formattedUserAccounts?.map((item: UserAccount) => (
                                <Grid key={item?.id} container spacing={1} sx={{ pb: 2 }}>
                                    <Grid item xs={12}>
                                        <Typography
                                            sx={{
                                                textDecoration: 'underline'
                                            }}>
                                            {t("Current Nicknames for ") + item?.accountAddress}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Grid container alignItems="center" spacing={1}>
                                            <Grid item>
                                                <Typography>
                                                    {t("Account #  ") + item?.extAccountNumber}
                                                </Typography>
                                            </Grid>
                                            <Grid item>
                                                <TextField
                                                    id="outlined-read-only-input"
                                                    data-cy-id={"myNicknamePreferences-accountNickname"}
                                                    defaultValue={item?.accountNickname}
                                                    disabled={item?.username !== username}
                                                    size="small"
                                                    InputProps={{
                                                        readOnly: true,
                                                    }}
                                                />
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12}>
                                            {item.meters && (
                                                renderAccountMeters(item.meters, item?.username !== username)
                                            )}                                           
                                        </Grid>                                   
                                    </Grid>
                                </Grid>   
                            ))}
                        </UcLoading>
                    </Grid>
                </CardContent>
            </Card>
            <MyNicknamePreferencesFormDialog
                open={editDialog.open}
                username={username}
                initialValues={formattedUserAccounts}
                onCancel={closeHandler}
                onSave={saveHandler}
            />
        </>
    );
};