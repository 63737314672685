import React from "react";
import {
    Typography,
    Box,
    useTheme,
    Grid,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    SelectChangeEvent,
    Paper,
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Switch,
    TextField,
    Alert,
    Button,
    IconButton,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import CompareArrowsIcon from "@mui/icons-material/CompareArrows";
import { grey } from "@mui/material/colors";
import { filterTypes } from "utils/Enums";
import { AccountContext } from "../../lib/AccountProvider";
import { UcWidget } from "components/common/UcWidget";
import UcDateToggle from "components/common/UcDateToggle";
import {
    useRateClassComparison,
    useRateClassComparisonSummary,
} from "./RateClassComparisonApiEndpoints";
import { UcLoading } from "components/common/UcLoading";
// @ts-ignore
import { DateTime } from "luxon";
// @ts-ignore
import { useResponsive } from "../../lib/useResponsive";
// @ts-ignore
import { useMyTimezone } from "components/customer/orgs/MyOrgAPIEndpoints";
import {
    ReadingRateSummary,
    ReadingRateSummaryRowCombined,
    ReadingRateSummaryRowCompared,
    DetailsDialog
} from "./RateClassComparison.interfaces";
import { RateClassComparisonGraph } from "./RateClassComparisonGraph";
import EnsureUserAndModule from "../auth/EnsureUserAndModule";
import RateClassComparisonPageDialog from "./RateClassComparisonPageDialog";
// @ts-ignore
import { dateRangeFormatterTimezone } from '../../lib/Formatters';
import { subtractMonths } from './RateClassComparisonUtils';
import RateClassComparisonChangeRequestDialog from "./RateClassComparisonChangeRequestDialog";
import InfoIcon from '@mui/icons-material/Info';
// @ts-ignore
import { UcHint } from 'components/common/UcHint';
import { PageContext, PageState, PageAction } from '../../lib/PageProvider';
// @ts-ignore
import {  UcRateChargeCardList } from '../common/UcRateChargeCardList';
// @ts-ignore
import { useEnums } from 'components/auth/PermissionApiEndpoint';
// @ts-ignore
import { enumFormatter } from 'lib/EnumFormatter';
import useOrgAppSettings from "lib/useOrgAppSettings";
import { UcMeterSelectV1 } from '../common/UcMeterSelectV1';

export default function RateClassComparisonPage(props: any) {
    const { t } = useTranslation();
    const { orgAppSettings } = useOrgAppSettings();
    const { isMobile, isTablet, isLaptop } = useResponsive();
    const theme = useTheme();
    const timezone = useMyTimezone();
    const rateChargeTypeEnums = useEnums("RateChargeType");

    const today = React.useMemo(() => {
        let d = new Date();
        d.setDate(d.getDate() - 1);
        return d;
    }, []);

    const yearStart = React.useMemo(
        () =>
            DateTime.fromObject(
                {
                    year: today.getFullYear(),
                    month: today.getMonth() + 1,
                    day: 1
                },
                { zone: timezone }
            )
                .minus({ months: 12 })
                .startOf("month")
                .toJSDate(),
        [timezone, today]
    );

    // For rate class comparison we only want 12 months for the Year group
    // We also want to use this updatedDate for the Month group
    const updatedDate = subtractMonths(today, 1);

    const yearEnd = React.useMemo(
        () =>
            DateTime.fromObject(
                {
                    year: updatedDate.getFullYear(),
                    month: updatedDate.getMonth() + 1,
                    day: updatedDate.getDate(),
                },
                { zone: timezone }
            )
                .endOf("month")
                .toJSDate(),
        [timezone, updatedDate]
    );

    const monthStart = React.useMemo(
        () =>
            DateTime.fromObject(
                {
                    year: updatedDate.getFullYear(),
                    month: updatedDate.getMonth(),
                    day: updatedDate.getDate(),
                },
                { zone: timezone }
            )
                .startOf("month")
                .toJSDate(),
        [timezone, updatedDate]
    );

    const monthEnd = React.useMemo(
        () =>
            DateTime.fromObject(
                {
                    year: updatedDate.getFullYear(),
                    month: updatedDate.getMonth(),
                    day: updatedDate.getDate(),
                },
                { zone: timezone }
            )
                .endOf("month")
                .toJSDate(),
        [timezone, updatedDate]
    );

    const hourStart = React.useMemo(
        () =>
            DateTime.fromObject(
                {
                    year: today.getFullYear(),
                    month: today.getMonth() + 1,
                    day: today.getDate(),
                },
                { zone: timezone }
            )
                .startOf("day")
                .toJSDate(),
        [timezone, today]
    );

    const hourEnd = React.useMemo(
        () =>
            DateTime.fromObject(
                {
                    year: today.getFullYear(),
                    month: today.getMonth() + 1,
                    day: today.getDate(),
                },
                { zone: timezone }
            )
                .endOf("day")
                .toJSDate(),
        [timezone, today]
    );
    const [startDate, setStartDate] = React.useState(monthStart);
    const [endDate, setEndDate] = React.useState(monthEnd);
    const [billDateRangeText, setBillDateRangeText] = React.useState("");
    const { account } = React.useContext(AccountContext);
    // @ts-ignore
    const [chartType, setChartType] = React.useState("day"); // This will be used for rendering the chart component, day sets default to month view
    const [uom, setUom] = React.useState("KWH");
    const [uomDisplay, setUomDisplay] = React.useState("kWh");
    const [rows, setRows] = React.useState<any[]>([]);
    const [activeTotal, setActiveTotal] = React.useState(0);
    const [activeRows, setActiveRows] = React.useState<any[]>([]);
    const [comparedTotal, setComparedTotal] = React.useState(0);
    const [comparedRows, setComparedRows] = React.useState<any[]>([]);
    const [selectedRateClass, setSelectedRateClass] = React.useState<string>("");
    const [rateCategories, setRateCategories] = React.useState<any>([]);
    const [detailsDialog, setDetailsDialog] = React.useState<DetailsDialog>({ open: false, rateCategories: null, title: "" });
    const [openChangeRequestDialog, setOpenChangeRequestDialog] = React.useState(false);

    const [pageState, pageDispatch] = React.useContext<[PageState, React.Dispatch<PageAction>]>(PageContext);

    React.useEffect(() => {
        pageDispatch({
            header: t(''),
            actionItems: [] as any[],
            variableHeader: "",
            tabs: [],
            onTabChange: () => { },
            tabValue: ''
        });
    }, [pageState.header, pageDispatch, t]);

    const closeHandler = () => {
        setDetailsDialog({ open: false, rateCategories: null, title: "" });
    }

    const openDialog = (rateCategories: any, title: any) => {
        if (!rateCategories || !areRatesAvailable) {
            return;
        }
        
        setDetailsDialog({ open: true, rateCategories: rateCategories, title: title });
    }

    const handleOpenChangeRequestDialog = () => {
        setOpenChangeRequestDialog(true);
    }
    
    const handleCloseChangeRequestDialog = () => {
        setOpenChangeRequestDialog(false);
    }

    const rateClassComparisonSwr = useRateClassComparison(
        account?.id !== null,
        account?.id,
        uom
    );

    const summary = useRateClassComparisonSummary(
        !rateClassComparisonSwr.isLoading &&
        account?.id !== null &&
        !isNaN(Number(selectedRateClass)),
        account?.id,
        rateClassComparisonSwr.result?.activeRateClass?.id as unknown as number,
        selectedRateClass as unknown as number,
        uom,
        chartType,
        startDate,
        endDate
    );

    React.useEffect(() => {
        if (!rateClassComparisonSwr.isLoading) {
            if (rateClassComparisonSwr.result?.availableRateClasses?.length > 0) {
                setSelectedRateClass(
                    rateClassComparisonSwr.result?.availableRateClasses[0].id
                );
            } else {
                setSelectedRateClass("");
            }
        }
    }, [
        rateClassComparisonSwr.isLoading,
        rateClassComparisonSwr.result?.availableRateClasses,
    ]);

    React.useEffect(() => {
        if (
            !summary.isLoading &&
            summary.result?.activeReadings &&
            summary.result?.comparedReadings
        ) {
            let combinedRows: ReadingRateSummaryRowCombined[] = [];
            let activeRows: ReadingRateSummaryRowCompared[] = [];
            let comparedRows: ReadingRateSummaryRowCompared[] = [];

            summary.result.activeReadings?.readingRateSummaries?.forEach(
                (element: ReadingRateSummary) => {
                    combinedRows.push(
                        colsOptionOne(
                            element.uniqueName + "_active_combined",
                            element.name,
                            element.rateChargeType.value,
                            element.consumption,
                            element.cost,
                            "---",
                            element.htmlColor
                        )
                    );

                    activeRows.push(
                        colsOptionTwo(
                            element.uniqueName + "_active",
                            element.name,
                            element.rateChargeType.value,
                            (element.rate !== undefined ? element.rate : '---'),
                            element.cost,
                            element.consumption,
                            element.htmlColor
                        )
                    );
                }
            );

            summary.result.comparedReadings?.readingRateSummaries?.forEach(
                (element: ReadingRateSummary) => {
                    combinedRows.push(
                        colsOptionOne(
                            element.uniqueName + "_compared_combined",
                            element.name,
                            element.rateChargeType.value,
                            element.consumption,
                            "---",
                            element.cost,
                            element.htmlColor
                        )
                    );

                    comparedRows.push(
                        colsOptionTwo(
                            element.uniqueName + "_compared",
                            element.name,
                            element.rateChargeType.value,
                            element.rate ? element.rate : '---',
                            element.cost,
                            element.consumption,
                            element.htmlColor
                        )
                    );
                }
            );

            setRows([...combinedRows]);
            setActiveTotal(summary.result.activeReadings?.totalCost);
            setActiveRows([...activeRows]);
            setComparedTotal(summary.result.comparedReadings?.totalCost);
            setComparedRows([...comparedRows]);
        }
    }, [summary.isLoading, summary.result?.activeReadings, summary.result?.comparedReadings]);

    // Toggle Table
    const [checked, setChecked] = React.useState(true);

    // Toggle Table
    const handleChangeDemo = (event: React.ChangeEvent<HTMLInputElement>) => {
        setChecked(event.target.checked);
    };

    const handleChangeChartType = (value: string) => {
        if (value === filterTypes.YEAR) {
            setStartDate(yearStart);
            setEndDate(yearEnd);
            setChartType("month");
        } else if (value === filterTypes.MONTH) {
            setStartDate(monthStart);
            setEndDate(monthEnd);
            setChartType("day");
        } else if (value === filterTypes.DAY) {
            setStartDate(hourStart);
            setEndDate(hourEnd);
            setChartType("none");
        }
    };

    const handleChangeCommodityType = (value: string) => {
        setUom(value);
        setUomDisplay(orgAppSettings?.result?.uomMappings[value.toUpperCase()].safeUomDisplay);
        setSelectedRateClass("");
    };

    const selectedAccount = account;

    const actionItems = [
        {
            key: "actionItem_rateClassComparison_UcHint",
            component: (
                <UcHint
                    hintText={t("Use our calculator to visualize how each rate class may impact your bill based on your energy consumption and usage timing.")}
                />
            )
        },
        {
            key: "actionItem_rateClassComparison_SendRequest",
            component: (
                <Button
                    data-cy-id={'rateClassComparison_SendRequest_Btn'}
                    sx={{
                        mr: (isMobile || isTablet || isLaptop ? 0 : 1), 
                        mb: (isMobile || isTablet || isLaptop ? 1 : 0), 
                        display: (isMobile ? 'block' : 'inherit')
                    }}
                    variant="outlined"
                    onClick={handleOpenChangeRequestDialog}
                    disabled={!selectedRateClass || comparedTotal <= 0}
                >
                    {t('Request Rate Change')}
                </Button>
            ),
        },
        {
            key: "actionItem_rateClassComparison_UcDateToggle",
            component: <UcDateToggle onChange={handleChangeChartType} initialValue="month"/>,
        },
        {
            key: "actionItem_rateClassComparison_UcCommodityToggle",
            component: <UcMeterSelectV1
                account={account}
                uom={uom}
                onlyCommodity={true}
                forcedWidth={(!isMobile && !isTablet) ? '50%' : '100%'}
                forcedMargin='0.5rem 0 0 0'
                onChange={async (event: any, newValue: { uom: string; }) => {
                    handleChangeCommodityType(newValue?.uom);
                }}
            />,
        },
    ];

    const handleChangeSelectedRateClass = (event: SelectChangeEvent) => {
        setSelectedRateClass(event.target.value);
    };

    function colsOptionOne(
        id: string,
        name: string,
        rateChargeType: string,
        consumption: number | string,
        costCurrent: number | string,
        costCompared: number | string,
        htmlColor: string
    ) {
        return {
            id,
            name,
            rateChargeType,
            consumption,
            costCurrent,
            costCompared,
            htmlColor,
        };
    }

    function colsOptionTwo(
        id: string,
        name: string,
        rateChargeType: string,
        rate: number | '---',
        cost: number,
        consumption: number | string,
        htmlColor: string
    ) {
        return {
            id,
            name,
            rateChargeType,
            rate,
            cost,
            consumption,
            htmlColor
        };
    }
    let dateRangeText: string = "";
    if (timezone) {
        dateRangeText = dateRangeFormatterTimezone(startDate, endDate, timezone);
    }

    let areRatesAvailable: boolean = rateClassComparisonSwr.result?.availableRateClasses.length > 0;

    const tableDisplayFormater = (rateType: string, uom: string, consumption: number | null = null) => {
        // Consumption column
        if (consumption && rateType === "MONTHLY") {
            return "---";
        }
        else if (consumption) {
            return consumption + " " + uom;
        }  
        // Rate column
        else if (rateType === "MONTHLY") {
            return " per month";
        }
        else {
            return "/" + uom;
        }
    };

    return (
        <EnsureUserAndModule modules={['RateClassComparison']}>
            <Switch
                checked={checked}
                onChange={handleChangeDemo}
                inputProps={{ "aria-label": "controlled" }}
                sx={{ display: "none" }} // hiding toggle to adjust table
            />
            <UcWidget
                contentPadding="1rem"
                actionItems={actionItems}
                title={t("Rate Comparison") + " - " + selectedAccount?.accountAddress}
                alignTitleLeft={true}
                subTitle={chartType === "none" ? dateRangeText : billDateRangeText}
            >
                <UcLoading
                    isLoading={rateClassComparisonSwr.isLoading || summary.isLoading}
                >
                    <Grid container spacing={2} data-cy-id={"rateClassComparison-grid"}>
                        <Grid item xs={12} sm={12} md={5}>
                            <Grid container spacing={!isMobile ? 2 : 0}>
                                <Grid item xs={12} >
                                    <Grid container wrap='nowrap'>
                                        <TextField
                                            fullWidth
                                            disabled
                                            id="outlined-disabled"
                                            label={t("Current")}
                                            value={rateClassComparisonSwr.result?.activeRateClass?.name}
                                        />
                                    </Grid>
                                </Grid>
                                {selectedRateClass && (
                                    <Grid item xs={12}>
                                        <Box>
                                            <Paper
                                                elevation={1}
                                                sx={{
                                                    p: 1,
                                                    bgcolor: theme.palette.success.main,
                                                    color: theme.palette.primary.contrastText,
                                                    mt: isMobile && 1
                                                }}
                                            >
                                            <Grid container>
                                                <Grid item xs={10}>
                                                    <Typography gutterBottom variant="h6">
                                                        {t("Total")}
                                                    </Typography>
                                                    <Typography
                                                        variant="h4"
                                                        sx={{ fontWeight: "bold" }}
                                                        data-cy-id={"rateClassComparison-activeTotalCost"}
                                                    >
                                                        ${activeTotal?.toFixed(2)}
                                                    </Typography>
                                                </Grid>
                                                    {(isMobile && (rateCategories.mainPeriod?.KWH || rateCategories.mainPeriod?.GAL) !== undefined) && (
                                                    <Grid item sx={{pr: 1}}>
                                                        <IconButton
                                                            color="default"
                                                            aria-label="info"
                                                            onClick={() => openDialog(rateCategories.mainPeriod?.KWH || rateCategories.mainPeriod?.GAL, rateClassComparisonSwr.result?.activeRateClass?.name)}
                                                        >
                                                            <InfoIcon />
                                                        </IconButton>  
                                                    </Grid>)}
                                                </Grid>
                                            </Paper>
                                        </Box>
                                    </Grid>
                                )}
                                {checked && selectedRateClass && !isMobile && (
                                    <Grid item xs={12}>
                                        <TableContainer component={Paper}>
                                            <Table
                                                sx={{ minWidth: 200 }}
                                                size="small"
                                                aria-label="a dense table"
                                            >
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell align="left">
                                                            <IconButton
                                                                disabled={(rateCategories.mainPeriod?.KWH || rateCategories.mainPeriod?.GAL) === undefined}
                                                                color="default"
                                                                aria-label="info"
                                                                onClick={() => openDialog(rateCategories.mainPeriod?.KWH || rateCategories.mainPeriod?.GAL, rateClassComparisonSwr.result?.activeRateClass?.name)}
                                                            >
                                                                <InfoIcon />
                                                            </IconButton>
                                                        </TableCell>
                                                        <TableCell>{t("Rate Class")}</TableCell>
                                                        <TableCell align="right">{t("Type")}</TableCell>
                                                        <TableCell align="right">{t("Rate")}</TableCell>
                                                        <TableCell align="right">{t("Cost")}</TableCell>
                                                        <TableCell align="right">
                                                            {t("Consumption")}
                                                        </TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {activeRows?.map((row: any) => (
                                                        <TableRow
                                                            key={row.id}
                                                            sx={{
                                                                "&:last-child td, &:last-child th": {
                                                                    border: 0,
                                                                },
                                                            }}
                                                        >
                                                            <TableCell align="left">
                                                                <div
                                                                    style={{
                                                                        width: "18px",
                                                                        height: "18px",
                                                                        margin: "0 10px",
                                                                        backgroundColor: `${row?.htmlColor}`,
                                                                        borderRadius: "50%",
                                                                    }}
                                                                ></div>
                                                            </TableCell>
                                                            <TableCell component="th" scope="row" sx={{ whiteSpace: "nowrap" }}>
                                                                {t("{{rowName}}", { rowName: row.name })}
                                                            </TableCell>
                                                            <TableCell align="right">
                                                                {t("{{rowType}}", { rowType: enumFormatter(rateChargeTypeEnums, row.rateChargeType) })}
                                                            </TableCell>
                                                            <TableCell align="right">{(row.rate && row.rate !== "---") ? `$${row.rate}` + tableDisplayFormater(row.rateChargeType, uomDisplay) : ""}</TableCell>
                                                            <TableCell align="right">{row.cost ? `$${row.cost?.toFixed(2)}` : ""}</TableCell>
                                                            <TableCell align="right">{row.consumption ? tableDisplayFormater(row.rateChargeType, uomDisplay, row.consumption) : ""}</TableCell>
                                                        </TableRow>
                                                    ))}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </Grid>
                                )}
                                { checked && selectedRateClass && isMobile && (
                                    <Paper
                                        elevation={3}
                                        sx={{
                                            p: 1
                                        }}
                                    >
                                        <UcRateChargeCardList
                                            rates={activeRows}
                                            uom={uomDisplay}
                                        />
                                    </Paper>
                                )}
                            </Grid>
                        </Grid>
                        <Grid item xs={12} sm={12} md={2}>
                            <Box sx={{ textAlign: "center", mt: (isMobile ? 1 : 3)}}>
                                <CompareArrowsIcon
                                    sx={{
                                        fontSize: isMobile ? 50 : 112,
                                        alignItems: "center",
                                        color: grey[400],
                                        transform:
                                            isMobile || isTablet ? "rotate(90deg)" : "rotate(0)",
                                    }}
                                />
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={12} md={5}>
                            <Grid container spacing={!isMobile ? 2 : 0}>
                                <Grid item xs={12}>
                                    {areRatesAvailable ? (
                                        <Grid container wrap='nowrap'>
                                            <FormControl fullWidth>
                                                <InputLabel id="simple-select-label">
                                                    {t("Compared")}
                                                </InputLabel>
                                                <Select
                                                    labelId="simple-select-label"
                                                    id="simple-select"
                                                    value={selectedRateClass}
                                                    onChange={handleChangeSelectedRateClass}
                                                    label={t("Rate Class")}
                                                    data-cy-id={"rateClassComparison-dropdown-compared-select"}
                                                >
                                                    {rateClassComparisonSwr.result?.availableRateClasses?.map(
                                                        (item: any) => (
                                                            <MenuItem
                                                                data-cy-id={"rateClassComparison-dropdown-compared-menuItem"}
                                                                key={`rateClass_${item.id}`}
                                                                value={item.id}
                                                            >
                                                                {t("{{itemName}}", { itemName: item.name })}
                                                            </MenuItem>
                                                        )
                                                    )}
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                    ) : (
                                        <Alert severity={"warning"}>
                                            {t("There are no Rate Classes available for comparison")}
                                        </Alert>
                                    )}
                                </Grid>
                                <Grid item xs={12}>
                                    {selectedRateClass ? (
                                        <Box>
                                            <Paper
                                                elevation={1}
                                                sx={{
                                                    p: 1,
                                                    bgcolor: theme.palette.info.main,
                                                    color: theme.palette.info.contrastText,
                                                    mt: isMobile && 1
                                                }}
                                            >
                                                {summary.result?.comparedReadings !== undefined && (
                                                    <>
                                                        <Grid container>
                                                            <Grid item xs={10}>
                                                                <Typography gutterBottom variant="h6">
                                                                    {t("Total")}
                                                                </Typography>
                                                                <Typography
                                                                    variant="h4"
                                                                    sx={{ fontWeight: "bold" }}
                                                                    data-cy-id={"rateClassComparison-comparedTotalCost"}
                                                                >
                                                                    ${comparedTotal?.toFixed(2)}
                                                                </Typography>
                                                            </Grid>
                                                            {(isMobile && (rateCategories.comparePeriod?.KWH || rateCategories.comparePeriod?.GAL) !== undefined) && (
                                                            <Grid item sx={{pr: 1}}>
                                                                <IconButton 
                                                                    color="default"
                                                                    aria-label="info"
                                                                    onClick={() => 
                                                                        openDialog(
                                                                            rateCategories.comparePeriod?.KWH || rateCategories.comparePeriod?.GAL, 
                                                                            rateClassComparisonSwr.result?.availableRateClasses.filter((o: any) => o.id === selectedRateClass)[0]?.name
                                                                        )}
                                                                >
                                                                    <InfoIcon />
                                                                </IconButton>  
                                                            </Grid>)}
                                                        </Grid>
                                                    </>
                                                )}
                                            </Paper>
                                        </Box>
                                    ) : (
                                        rateClassComparisonSwr.result?.availableRateClasses.length > 0 && (
                                            <Alert severity="info">{t('Select a Rate Class to compare')}</Alert>
                                        )
                                    )}
                                </Grid>

                                {checked && selectedRateClass && !isMobile && (
                                    <Grid item xs={12}>
                                        <TableContainer component={Paper}>
                                            <Table
                                                sx={{ minWidth: 200 }}
                                                size="small"
                                                aria-label="a dense table"
                                            >
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell align="left">
                                                            <IconButton
                                                                disabled={(rateCategories.comparePeriod?.KWH || rateCategories.comparePeriod?.GAL) === undefined}
                                                                color="default"
                                                                aria-label="info"
                                                                onClick={() => 
                                                                    openDialog(
                                                                        rateCategories.comparePeriod?.KWH || rateCategories.comparePeriod?.GAL, 
                                                                        rateClassComparisonSwr.result?.availableRateClasses.filter((o: any) => o.id === selectedRateClass)[0]?.name
                                                                    )}
                                                            >
                                                                <InfoIcon />
                                                            </IconButton>
                                                        </TableCell>
                                                        <TableCell>{t("Rate Class")}</TableCell>
                                                        <TableCell align="right">{t("Type")}</TableCell>
                                                        <TableCell align="right">{t("Rate")}</TableCell>
                                                        <TableCell align="right">{t("Cost")}</TableCell>
                                                        <TableCell align="right">
                                                            {t("Consumption")}
                                                        </TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {comparedRows?.map((row: any) => (
                                                        <TableRow
                                                            key={row.id}
                                                            sx={{
                                                                "&:last-child td, &:last-child th": {
                                                                    border: 0,
                                                                },
                                                            }}
                                                        >
                                                            <TableCell align="left">
                                                                <div
                                                                    style={{
                                                                        width: "18px",
                                                                        height: "18px",
                                                                        margin: "0 10px",
                                                                        backgroundColor: `${row?.htmlColor}`,
                                                                        borderRadius: "50%",
                                                                    }}
                                                                ></div>
                                                            </TableCell>
                                                            <TableCell data-cy-id={"rateClassComparison-compared-table-cell"} component="th" scope="row" sx={{ whiteSpace: "nowrap" }}>
                                                                {t("{{rowName}}", { rowName: row.name })}
                                                            </TableCell>
                                                            <TableCell align="right">
                                                                {t("{{rowType}}", { rowType: enumFormatter(rateChargeTypeEnums, row.rateChargeType) })}
                                                            </TableCell>
                                                            <TableCell align="right">{(row.rate && row.rate !== "---") ? `$${row.rate?.toFixed(2)}` + tableDisplayFormater(row.rateChargeType, uomDisplay) : row.rate}</TableCell>
                                                            <TableCell align="right">{row.cost ? `$${row.cost?.toFixed(2)}` : ""}</TableCell>
                                                            <TableCell align="right">{row.consumption ? tableDisplayFormater(row.rateChargeType, uomDisplay, row.consumption) : ""}</TableCell>
                                                        </TableRow>
                                                    ))}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </Grid>
                                )}
                                { checked && selectedRateClass && isMobile && (
                                    <Paper
                                        elevation={3}
                                        sx={{
                                            p: 1
                                        }}
                                    >
                                        <UcRateChargeCardList
                                            rates={comparedRows}
                                            uom={uomDisplay}
                                        />
                                    </Paper>
                                )}
                            </Grid>
                        </Grid>
                        {!checked && selectedRateClass && (
                            <Grid item xs={12} sm={12} md={12}>
                                <TableContainer component={Paper}>
                                    <Table
                                        sx={{ minWidth: 200 }}
                                        size="small"
                                        aria-label="a dense table"
                                    >
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>{t("Rate Class")}</TableCell>
                                                <TableCell align="right">{t("Type")}</TableCell>
                                                <TableCell align="right">{t("Consumption")}</TableCell>
                                                <TableCell align="right">
                                                    {t("Cost (current)")}
                                                </TableCell>
                                                <TableCell align="right">
                                                    {t("Cost (compared)")}
                                                </TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {rows.map((row) => (
                                                <TableRow
                                                    key={row.id}
                                                    sx={{
                                                        "&:last-child td, &:last-child th": { border: 0 },
                                                    }}
                                                >
                                                    <TableCell component="th" scope="row">
                                                        <div
                                                            style={{
                                                                display: "inline-flex",
                                                                width: "12px",
                                                                height: "12px",
                                                                marginRight: 8,
                                                                backgroundColor: `${row?.htmlColor}`,
                                                                borderRadius: "50%",
                                                            }}
                                                        ></div>
                                                        <div
                                                            style={{
                                                                display: "inline-flex",
                                                            }}
                                                        >
                                                            {t("{{rowName}}", { rowName: row.name })}
                                                        </div>
                                                    </TableCell>
                                                    <TableCell align="right">
                                                        {t("{{rowType}}", { rowType: enumFormatter(rateChargeTypeEnums, row.rateChargeType) })}
                                                    </TableCell>
                                                    <TableCell align="right">
                                                        {tableDisplayFormater(row.rateChargeType, uomDisplay, row.consumption)}
                                                    </TableCell>
                                                    <TableCell align="right">
                                                        {row.costCurrent === "---"
                                                            ? row.costCurrent
                                                            : `$${row.costCurrent?.toFixed(2)}`}
                                                    </TableCell>
                                                    <TableCell align="right">
                                                        {row.costCompared === "---"
                                                            ? row.costCompared
                                                            : `$${row.costCompared?.toFixed(2)}`}
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Grid>
                        )}
                    </Grid>
                </UcLoading>
                <RateClassComparisonGraph
                    key={`rate_comp_graph_${chartType}_${selectedAccount?.id}_${rateClassComparisonSwr.result?.activeRateClass?.id}_${selectedRateClass}`}
                    areRatesAvailable={areRatesAvailable}
                    currentRateClassId={rateClassComparisonSwr.result?.activeRateClass?.id}
                    comparedRateClassId={selectedRateClass}
                    showStackedAvg={false}
                    showPredictionToggle={false}
                    showTemperatureToggle={false}
                    groupBy={chartType}
                    uom={uom}
                    accountId={selectedAccount?.id}
                    startDate={startDate}
                    endDate={endDate}
                    setRateCategories={setRateCategories}
                    setBillDateRangeText={setBillDateRangeText}
                />
                <RateClassComparisonPageDialog
                    open={detailsDialog.open}
                    rateCategories={detailsDialog.rateCategories}
                    rateClassName={detailsDialog.title}
                    uom={uomDisplay}
                    onClose={closeHandler}
                />
                { selectedRateClass && areRatesAvailable && rateCategories && (
                    <RateClassComparisonChangeRequestDialog 
                        accountId={selectedAccount?.id}
                        currentRateClass={rateClassComparisonSwr.result?.activeRateClass}
                        comparedRateClass={rateClassComparisonSwr.result?.availableRateClasses.filter((o: any) => o.id === selectedRateClass)[0]}
                        currentRateCategories={rateCategories.mainPeriod?.KWH || rateCategories.mainPeriod?.GAL}
                        comparedRateCategories={rateCategories.comparePeriod?.KWH || rateCategories.comparePeriod?.GAL}
                        uom={uomDisplay}
                        open={openChangeRequestDialog} 
                        handleClose={handleCloseChangeRequestDialog} 
                    />
                )}
            </UcWidget>
        </EnsureUserAndModule>
    );
}
