// @ts-ignore
import { ApiClient } from "../../lib/ApiClient";
// @ts-ignore
import { ensureLuxonDate } from "lib/Formatters";
import { useGenericSWR } from "../../lib/useGenericSWR";

const apiClient = new ApiClient();
const apiEndpoint = "api/v1/RateClassComparison";

const uomToCommodity: { [uom: string]: string } = {
  KWH: "ELECTRIC",
  GAL: "WATER",
  GAS: "GAS",
};

const useRateClassComparison = (
  shouldFetch: boolean,
  accountId: number | null | undefined,
  uom: string,
  swrOptions: any = {}
) => {
  const params = new URLSearchParams();
  if (shouldFetch && accountId) {
    params.append("accountId", accountId.toString());
    params.append("commodity", uomToCommodity[uom]);
  }

  return useGenericSWR(
    shouldFetch ? `${apiEndpoint}?${params.toString()}` : null,
    apiClient,
    { ...swrOptions }
  );
};

const useRateClassComparisonSummary = (
  shouldFetch: boolean,
  accountId: number | null | undefined,
  currentRateClassId: number | null | undefined,
  comparedRateClassId: number | null | undefined,
  uom: string,
  groupBy: string,
  startDateTime: any,
  endDateTime: any,
  swrOptions: any = {}
) => {
  const params = new URLSearchParams();
  if (accountId && currentRateClassId && comparedRateClassId) {
    params.append("accountId", accountId.toString());
    params.append("currentRateClassId", currentRateClassId.toString());
    params.append("comparedRateClassId", comparedRateClassId.toString());
    params.append("uom", uom);
    params.append("groupBy", groupBy);
    params.append('startTimeUTC', ensureLuxonDate(startDateTime)?.toUTC().toISO());
    params.append('endTimeUTC', ensureLuxonDate(endDateTime)?.toUTC().toISO());
  }

  return useGenericSWR(
    shouldFetch
      ? `${apiEndpoint}/compare?${params.toString()}`
      : null,
    apiClient,
    { ...swrOptions }
  );
};

const sendChangeRequest = (
  accountId: number | null | undefined,
  currentRateClassId: string | null | undefined,
  comparedRateClassId: string | null | undefined
) => {
  const params = new URLSearchParams();
  if (accountId && currentRateClassId && comparedRateClassId) {
    params.append("accountId", accountId.toString());
    params.append("currentRateClassId", currentRateClassId.toString());
    params.append("comparedRateClassId", comparedRateClassId.toString());
  }

  return apiClient.poster(`${apiEndpoint}/sendRequest?${params.toString()}`, {}, "", false, null, true);
};

export { useRateClassComparison, useRateClassComparisonSummary, sendChangeRequest };
