import { useState, useLayoutEffect } from 'react';
import { MyBillsGridStateful } from "./MyBillsGridStateful"
import { mutateUrls } from "../../../lib/useGenericSWR";
import { useSWRConfig } from "swr";
import './MyBillsListPage.scss';
import EnsureUserAndModule from 'components/auth/EnsureUserAndModule';

export const MyBillsList = (props: any) => {
    const [generalSearch] = useState<string>('');
    const [reMutateUrls, setReMutateUrls] = useState<boolean>(false);
    const swrConfig = useSWRConfig();

    useLayoutEffect(() => {
        if (reMutateUrls) {
            mutateUrls(swrConfig);
            setReMutateUrls(false);
        }
    }, [reMutateUrls, swrConfig]);

    return (
        <EnsureUserAndModule permissions={["Org:Modify"]} modules={['Billing']}>
            {!reMutateUrls &&
                <MyBillsGridStateful
                    id={null}
                    generalSearch={generalSearch}
                    onSelected={(newSelectedItem: any) => {
                        props.setSelectedItem({ ...newSelectedItem });
                    }}
                />
            }
        </EnsureUserAndModule>
    );
};