import React, { useState, useLayoutEffect, useRef, useEffect } from 'react';
import { DataGridPro } from '@mui/x-data-grid-pro';
import { useMeterCyclees } from './MeterCycleAPIEndpoints';
import { useMyTimezone } from 'components/customer/orgs/MyOrgAPIEndpoints';
import { UcPagination } from 'components/common/UcPagination';
import { dateFormatterTimezone } from 'lib/Formatters';
import { useTranslation } from 'react-i18next';
import EditIcon from '@mui/icons-material/Edit'
import { UcIconButtons } from 'components/common/UcIconButtons';
import { getWholeRow } from '../../../lib/DataGridHelpers';

export const MeterCycleGrid = (props) => {
    const { t } = useTranslation();
    const timezone = useMyTimezone();
    const initialSort = [{ field: "cycleName", sort: "asc" }];
    const sort = props.sort || initialSort;

    const initialDataState = props.dataState || { skip: 0, take: 10, };

    const [filter, setFilter] = useState({
        billingCycleId: props.billingCycleId,
        meterId: props.meterId,
        accountId: props.accountId,
        take: initialDataState.take,
        skip: initialDataState.skip,
        sort: sort,
        userId: props.userId || null,
        generalSearch: props.generalSearch,
    });

    useEffect(() => {
        if (props.generalSearch !== filter.generalSearch) {
            setFilter({ ...filter, generalSearch: props.generalSearch });
        }
    }, [filter, setFilter, props.generalSearch]);

    let initialFilterModel = {
        items: [
            { columnField: 'id', operatorValue: 'contains', value: '' }
        ],
        linkOperator: 'and',
    };

    const [filterModel] = useState(initialFilterModel);

    const needsDataUpdate = useRef("");

    const getMeterFieldValues = (params) => {
        return {
            "accountName": params.row.accountName,
            "extAccountNumber": params.row.extAccountNumber,
            "serialNo": params.row.serialNo
        };
    }

    let defaultDataItems = {
        columns: [
            { field: 'accountMeterId', headerName: t('Meter'), width: 300,
                valueGetter: getMeterFieldValues,
                valueFormatter: (params) => {
                    return params.value.accountName + " - " + params.value.extAccountNumber + " - " + params.value.serialNo;
                }
            },
            { field: 'cycleName', headerName: t('Billing Name'), width: 200 },
            { field: 'startDate', type: 'number', headerName: t('Cycle Start'), width: 170, align: 'left', valueFormatter: (params) => { return dateFormatterTimezone(params.value, timezone); } },
            { field: 'endDate', type: 'number', headerName: t('Cycle End'), flex: 1, minWidth: 170 , valueFormatter: (params) => { return dateFormatterTimezone(params.value, timezone); } },
            {
                field: 'id', headerName: '', width: 60,
                valueGetter: getWholeRow,
                renderCell: (params) => {
                    const actions = [
                        {
                            title: props.billingCycleId ? t('Edit Meter') : (t('Edit Bill Cycle')),
                            icon: <EditIcon />,
                            color: 'primary',
                            isHidden: false,
                            requiredPermission: 'MeterCycle:Modify',
                            onClick: () => { props.onEditItem(params.value); },
                        }
                    ];

                    return (
                        <UcIconButtons actions={actions} />
                    );
                }
            },
        ],
        rows: [],
        rowCount: -1,
    };
    const [dataItems, setDataItems] = useState(defaultDataItems);

    const defaultSelectedItem = { dataItem: {}, selectedId: 0 };
    const [selectedItem, setSelectedItem] = useState(defaultSelectedItem);

    const isDataLoaded = useRef(false);

    const dataReceived = (swrResult) => {
        var dataReceivedItems = swrResult.result;

        var selectedDataItems = dataReceivedItems.map((item) => {
            return { ...item, selected: item.id === selectedItem.selectedId };
        });
        selectedDataItems.iteration = dataReceivedItems.iteration;
        selectedDataItems.rowCount = swrResult.rowCount;
        if (selectedDataItems.length > selectedDataItems.rowCount) {
            selectedDataItems.rowCount = selectedDataItems.length;
        }

        setDataItems({ ...defaultDataItems, rows: [...dataReceivedItems], rowCount: swrResult.rowCount });
    };

    const swrMeterCyclees = useMeterCyclees(filter);

    const isLoading = swrMeterCyclees.isLoading || !isDataLoaded.current;
    if (!swrMeterCyclees.result && defaultDataItems.rowCount !== -1) {
        setDataItems(defaultDataItems);
    }

    useLayoutEffect(() => {
        if (swrMeterCyclees.result) {
            isDataLoaded.current = true;
            var filterString = JSON.stringify(filter);
            if (needsDataUpdate.current !== filterString) {
                needsDataUpdate.current = filterString;
                dataReceived(swrMeterCyclees);
            }
        }
    });

    const updateFilter = (newFilter) => {
        setFilter(newFilter);
        props.onFilterChange(newFilter);
    };

    return (
        <div
            data-cy-id={"meterCycleGridWrapper"}
        >
            <DataGridPro
                autoHeight={true}
                loading={isLoading}
                sortingMode='server'
                filterMode='server'
                sortModel={filter.sort}
                onSortModelChange={(model) => updateFilter({ ...filter, sort: model })}
                filterModel={filterModel}
                paginationModel={{page: filter.skip / filter.take, pageSize: filter.take}}
                onPaginationModelChange={(model) => updateFilter({ ...filter, skip: model.page * model.pageSize, take: model.pageSize })}
                paginationMode='server'
                rowsPerPageOptions={[5, 10, 20]}

                onRowSelectionModelChange={(newSelectionModel) => {
                    if (newSelectionModel.length !== 1) {
                        setSelectedItem(defaultSelectedItem);
                        props.onSelected(defaultSelectedItem.dataItem);
                        return;
                    }
                    const selectedId = newSelectionModel[0];
                    var newSelectedItem = dataItems.rows.filter(obj => { return obj.id === selectedId });
                    if (newSelectedItem.length !== 1) {
                        setSelectedItem(defaultSelectedItem);
                        props.onSelected(defaultSelectedItem.dataItem);
                        return;
                    }
                    newSelectedItem = newSelectedItem[0];
                    var newlySelectedItem = { dataItem: newSelectedItem, selectedId: selectedId };

                    setSelectedItem(newlySelectedItem);
                    props.onSelected(newlySelectedItem.dataItem);
                }}
                pagination
                components={{
                    Toolbar: () => {return  props.toolbar || <></>},
                    Pagination: UcPagination,
                }}
                {...dataItems}
                initialState={{ pinnedColumns: { right: ['id'] } }}
            />
        </div>
    );
}

MeterCycleGrid.defaultProps = {
    onSelected: (selectedItem) => { console.log("onSelected not implemented", selectedItem); },
    onFilterChange: (filter) => { console.log("onFilterChange not implemented", filter); },
};
