import { ApiClient } from "../../../lib/ApiClient";
import { useGenericSWR } from "../../../lib/useGenericSWR";
import * as Yup from 'yup';
import { ensureLuxonDateOnly } from "lib/Formatters";
import { globalTranslator } from "lib/GlobalTranslator";

export const apiEndpoint = 'api/v1/admin/RateVersion';

let formIteration = 0;
const t = globalTranslator;

const getRateVersionDefaultItem = (rateClassId) => {
    formIteration = formIteration + 1;
    return {
        formIteration: "new" + formIteration,
        id: 0,
        rateClassId: rateClassId,
        name: '',
        description: '',
        startDate: ensureLuxonDateOnly(new Date()),
        endDate: ensureLuxonDateOnly(new Date()),
        createdBy: 'REACT_FE',
        updatedBy: 'REACT_FE',
    }
};

const rateVersionValidationSchema = Yup.object().shape({
    rateClassId: Yup.number()
        .required(t('Rate Class is required'))
        .min(1, t('Rate Class is required')),
    name: Yup.string()
        .required(t("Required"))
        .max(256, t('Max of 256 characters')),
    description: Yup.string()
        .max(1028, t('Max of 1028 characters')).nullable(),
    startDate: Yup.date()
        .required(t("Required"))
        .when('endDate', {
            is: (endDate) => {
                return endDate;
            },
            then: Yup.date()
                .max(
                    Yup.ref("endDate"),
                    t("Version start date must be less than end date")),
        }),
    endDate: Yup.date()
        .nullable(),
});

const apiClient = new ApiClient();
apiClient.getDefaultItem = () => getRateVersionDefaultItem();
apiClient.getObjectName = () => {
    return t('Rate Version');
};

const useRateVersion = (id, swrOptions = {}) => {
    return useGenericSWR(apiEndpoint + "/" + id, apiClient, { ...swrOptions });
};

const useRateVersions = (filter, swrOptions = {}) => {
    const params = new URLSearchParams();
    params.append('filter', JSON.stringify(filter));
    return useGenericSWR(apiEndpoint + "?" + params.toString(), apiClient, { ...swrOptions });
};

const saveRateVersion = (rateVersion) => {
    if (rateVersion.id === 0) {
        return insertRateVersion(rateVersion);
    }
    return updateRateVersion(rateVersion);
}
const insertRateVersion = (rateVersion) => {
    return apiClient.poster(apiEndpoint, rateVersion);
};

const updateRateVersion = (rateVersion) => {
    return apiClient.putter(apiEndpoint, rateVersion);
};

const deleteRateVersion = (rateVersion) => {
    return apiClient.deleter(apiEndpoint + "/" + rateVersion.id, "", false, null, true, t("Rate versions that have rate charges cannot be deleted."));
};

export {
    useRateVersion,
    useRateVersions,
    saveRateVersion,
    insertRateVersion,
    updateRateVersion,
    deleteRateVersion,
    rateVersionValidationSchema,
    getRateVersionDefaultItem,
};