import { ApiClient } from "../../../lib/ApiClient";
import { useGenericSWR } from "../../../lib/useGenericSWR";
import { ensureLuxonDateOnly } from "lib/Formatters";
import * as Yup from 'yup';

const apiEndpoint = 'api/v1/admin/MeterCycle';

let formIteration = 0;

const getMeterCyclesDefaultItem = () => {
    formIteration = formIteration + 1;
    return {
        formIteration: "new" + formIteration,
        id: 0,
        billingCycleId: 0,
        accountMeterId: 0,
        startDate: ensureLuxonDateOnly(new Date()),
        createdBy: 'REACT_FE',
        updatedBy: 'REACT_FE',
    }
};

const meterCycleesValidationSchema = Yup.object().shape({
    billingCycleId: Yup.number()
        .required("Billing Cycle is required"),
    accountMeterId: Yup.number()
        .required('Account meter is required')
        .min(1, 'Account meter is required'),
    startDate: Yup.date()
        .required("Required"),
});

// See definition from MeterCycle.cs
const apiClient = new ApiClient();
apiClient.getDefaultItem = () => getMeterCyclesDefaultItem();
apiClient.getObjectName = () => {
    return "Meter Cycles";
};

const useMeterCycle = (id, swrOptions = {}) => {
    // SWR will prevent duplicate requests
    // It will also maintain data state as long as your are using the same urls for your requests
    return useGenericSWR(apiEndpoint + "/" + id, apiClient, { ...swrOptions });
};

const useMeterCyclees = (filter, swrOptions = {}) => {
    // SWR will prevent duplicate requests
    // It will also maintain data state as long as your are using the same urls for your requests
    const params = new URLSearchParams();
    params.append('filter', JSON.stringify(filter));
    return useGenericSWR(apiEndpoint + "?" + params.toString(), apiClient, { ...swrOptions });
};

const saveMeterCycle = (MeterCycle) => {
    if (MeterCycle.id === 0) {
        return insertMeterCycle(MeterCycle);
    }
    return updateMeterCycle(MeterCycle);
}
const insertMeterCycle = (MeterCycle) => {
    return apiClient.poster(apiEndpoint, MeterCycle);
};

const updateMeterCycle = (MeterCycle) => {
    return apiClient.putter(apiEndpoint, MeterCycle);
};

const deleteMeterCycle = (MeterCycle) => {
    return apiClient.deleter(apiEndpoint + "/" + MeterCycle.id);
};

export {
    useMeterCycle,
    useMeterCyclees,
    saveMeterCycle,
    insertMeterCycle,
    updateMeterCycle,
    deleteMeterCycle,
    meterCycleesValidationSchema,
    getMeterCyclesDefaultItem,
};