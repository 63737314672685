import { CircularProgress, Box, Typography } from "@mui/material";

export interface UcProgressStats {
    current: number,
    total: number,
}

interface UcProgressProps extends UcProgressStats {
    title?: string,
    position?: string,
    left?: string,
    top?: string,
    bottom?: string,
    right?: string,
    fontSize?: string | number,
    progressSize?: string | number,
}

export const UcProgress = (props: UcProgressProps) => {
    const percent = props.total === 0 ? 0 : (props.current / props.total * 100);
    const label = props.total === 0 ? "" : (Math.round(percent) + '%');
    const title = (props.title || "Loading...") + " " + label;
    const position = props.position??'absolute';
    const left = props.left?? '0';
    const top = props.top?? '0';
    const bottom = props.bottom?? '0';
    const right = props.right?? '0';
    const fontSize = props.fontSize??'1.5em';
    const progressSize = props.progressSize??'5em';

    const sx = {
        top: top,
        left: left,
        bottom: bottom,
        right: right,
        position: position,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    }

    return (
        <Box sx={sx}>
            <CircularProgress
                aria-label={title}
                title={title}
                size={progressSize}
            />
            <Box sx={sx}>
                <Typography variant="caption" component="div" color="text.secondary" fontSize={fontSize}>
                    {label}
                </Typography>
            </Box>
        </Box>
    );
};