import React, { useMemo } from 'react';

export const UserInfoContext = React.createContext(null);

// must have this in a seperate file or you get circular reference errors
export const defaultUserInfo = {
    username: "",
    isLoggedIn: false,
    isLoading: true,
    isFirstAccess: true,
    emailVerified: false,
}

export const UserInfoProvider = ({ children }) => {
    const [userInfo, setUserInfo] = React.useState(defaultUserInfo);

    const userInfoProviderValue = useMemo(() => ({
        userInfo: userInfo,
        setUserInfo: (newUserInfo) => {
            if (newUserInfo.username !== userInfo.username ||
                newUserInfo.isLoading !== userInfo.isLoading ||
                newUserInfo.isFirstAccess !== userInfo.isFirstAccess) {
                setUserInfo(newUserInfo);
            }
        }
    }), [userInfo, setUserInfo]);

    return (
        <UserInfoContext.Provider value = {userInfoProviderValue}>
            { children }
        </UserInfoContext.Provider>
    );
  };