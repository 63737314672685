import { useContext } from 'react';
import Typography from '@mui/material/Typography';
import { useTranslation } from "react-i18next";
// @ts-ignore
import { useResponsive } from 'lib/useResponsive';
import { useTheme } from '@mui/material/styles';
// @ts-ignore
import { UcThemeContext } from 'lib/UcThemeProvider';
import { Dialog, PaperProps, Theme as MuiTheme } from '@mui/material';
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Paper from '@mui/material/Paper';
import Draggable from 'react-draggable';
import './UcCustomLogo.scss';

function PaperComponent(props: PaperProps) {
    return (
        <Draggable
            handle="#draggable-dialog-title"
            cancel={'[class*="MuiDialogContent-root"]'}
        >
            <Paper {...props} />
        </Draggable>
    );
}
interface UcDataItemCardDialogProps {
    anchorEl: Boolean | null,
    dialogContent: { title: string, content: string },
    onCloseDialog: Function
}
interface CustomInstanceOfMuiTheme extends MuiTheme {
    props: any;
}

export default function UcDataItemCardDialog(props: UcDataItemCardDialogProps) {
    const { t } = useTranslation();
    const { anchorEl, dialogContent } = props;
    const { isMobile } = useResponsive();
    const theme = useTheme<CustomInstanceOfMuiTheme>();    
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [themeState, themeDispatch] = useContext<any>(UcThemeContext);
    
    const handleCloseMenu = () => {
        props.onCloseDialog();
    };
    
    return ( 
        <Dialog
            fullScreen={isMobile}
            fullWidth={true}
            maxWidth={"lg"}
            open={Boolean(anchorEl)}
            onClose={handleCloseMenu}
            PaperComponent={PaperComponent}
            aria-labelledby="draggable-dialog-title"
        >
            <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
                <img 
                    src={`${process.env.PUBLIC_URL}/themes/${themeState.logo}`} 
                    className='ucCustomLogo'
                    alt="logo" 
                    style={{
                        float: "right",
                        marginBottom: isMobile ? '10px' : '0',
                        display: isMobile ? 'block' : 'inline-block'
                    }} 
                />
                {!isMobile && (
                    <span style={{ marginRight: '10px' }}>
                        {dialogContent.title}
                    </span>
                )}
                {isMobile && (
                    <div style={{ clear: 'both' }}>
                        {dialogContent.title}
                    </div>
                )}     
            </DialogTitle>
            <DialogContent data-cy-id={"notification_content"}>
                <DialogContentText>
                    <Typography>{dialogContent.content}</Typography> 
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button
                    autoFocus
                    onClick={handleCloseMenu}
                    variant={theme.props?.CancelButton?.variant}
                    color={theme.props?.CancelButton?.color}
                    data-cy-id={"close_button"}
                >
                    {t('Close')}
                </Button>
            </DialogActions>
        </Dialog>
    );
}