import React from 'react';
import { styled } from '@mui/material/styles';
import { useTranslation } from "react-i18next";
import { PageContext } from '../../../lib/PageProvider';
import { dateFormatter } from '../../../lib/Formatters';
import { useParams } from "react-router-dom";
import { EnsureUser } from '../../auth/EnsureUser';
import RateChargeList from './RateChargeList';
import { mutateUrls } from "../../../lib/useGenericSWR";
import { useSWRConfig } from "swr";
import { getRateVersionDefaultItem, useRateVersions } from './RateVersionApiEndpoints';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import { Alert, AlertTitle, Box, Chip, Divider, Typography, Tooltip, Button, Grid } from '@mui/material';
import RateVersionFormDialog from './RateVersionFormDialog';
import { RateClassFormDialog } from './RateClassFormDialog';
import { useHasPermission } from 'components/auth/PermissionApiEndpoint';
import { useRateClass } from './RateClassAPIEndpoints';

const ListItem = styled('li')(({ theme }) => ({
    margin: theme.spacing(0.5),
  }));

export default function RateVersionListPage(props) {
    const { t } = useTranslation();
    const { rateClassId } = useParams();
    const params = new URLSearchParams(window.location.search);
    const rateClassParamName = params.get("name");
    const rateClassType = params.get("rateType");
    const [selectedRateVersion, setSelectedRateVersion] = React.useState(null);
    const [rateVersionChips, setSelectedRateVersionChips] = React.useState([]);
    const [pageState, pageDispatch] = React.useContext(PageContext);
    const [reMutateUrls, setReMutateUrls] = React.useState(false);
    const swrConfig = useSWRConfig();
    const [editDialog, setEditDialog] = React.useState({ open: false });
    const [initialLoad, setInitialLoad] = React.useState(true);
    const [editRateClassDialog, setEditRateClassDialog] = React.useState({ open: false });
    const rateVersionModifyPerm = useHasPermission("RateVersion:Modify");

    const filter = {
        rateClassId: parseInt(rateClassId),
        take: 100,
        skip: 0,
        sort: [{ field: "Id", sort: "desc" }],
        generalSearch: ""
    };

    const rateVersions = useRateVersions(filter);
    const rateClassItem = useRateClass(rateClassId);

    const closeHandler = () => {
        setEditDialog({ open: false, itemToEdit: editDialog.dataItem });
    }

    const saveHandler = () => {
        closeHandler();
        setReMutateUrls(true);
    }

    const editRateClassItem = (item) => {
        openRateClassDialog(item.result);
    }

    const openRateClassDialog = (dataItem) => {
        if (!dataItem) {
            return;
        }
        if (!dataItem.formIteration) {
            dataItem.formIteration = "edit" + dataItem.id;
        }
        setEditRateClassDialog({ open: true, itemToEdit: dataItem });
    }

    const closeRateClassHandler = () => {
        setEditRateClassDialog({ open: false, itemToEdit: editRateClassDialog.dataItem });
    }

    const saveRateClassHandler = () => {
        closeRateClassHandler();
        setReMutateUrls(true);
    }

    const openDialog = (dataItem) => {
        if (!dataItem) {
            return;
        }
        if (!dataItem.formIteration) {
            dataItem.formIteration = "edit" + dataItem.id;
        }
        setEditDialog({ open: true, itemToEdit: dataItem });
    }

    const createItem = () => {
        openDialog(getRateVersionDefaultItem(rateClassId));
    }

    const editItem = (item) => {
        openDialog(item);
    }

    const handleOnClick = (event, chipToSelect) => {
        const newRateVersionChips = rateVersionChips?.map((chip) => chip.key === chipToSelect.key 
            ? {...chip, isActive: true} 
            : {...chip, isActive: false}
        );
        
        setSelectedRateVersion(chipToSelect);
        setSelectedRateVersionChips([...newRateVersionChips]);
    }

    React.useEffect(() => {
        pageDispatch({
            header:  "Rate Versions",
            variableHeader: rateClassParamName,
            actionItems: []
        });
    }, [pageState.header, pageDispatch, rateClassParamName]);

    React.useEffect(() => {
        if (!rateVersions.isLoading && initialLoad) {
            const newRateVersions = rateVersions.result?.map((version, index) => {
                return {...version, key: (index + 1), isActive: (index === 0 ? true : false)};
            });

            if (newRateVersions.length > 0) {
                setSelectedRateVersion(newRateVersions[0]);
            }

            setSelectedRateVersionChips([...newRateVersions]);
            setInitialLoad(false);
        }
    }, [initialLoad, rateVersions.isLoading, rateVersions.result])

    React.useLayoutEffect(() => {
        if (reMutateUrls) {
            mutateUrls(swrConfig);
            setReMutateUrls(false);
            setInitialLoad(true);
        }
    }, [reMutateUrls, swrConfig]);

    console.log("rateVersionListPage - Loaded");
    return (
        <EnsureUser permissions={["RateVersion:Read"]}>
            <RateVersionFormDialog
                open={editDialog.open}
                delete={editDialog.delete}
                itemToEdit={editDialog.itemToEdit}
                onCancel={closeHandler}
                onSave={saveHandler}
                rateVersions={rateVersions.result}
            />
            <RateClassFormDialog
                open={editRateClassDialog.open}
                itemToEdit={editRateClassDialog.itemToEdit}
                onCancel={closeRateClassHandler}
                onSave={saveRateClassHandler}
                disableItemEdit={false}
            />
            <Typography variant="subtitle1">
                Versions
            </Typography>           
            <Grid container fullWidth direction="row" spacing={1}>
                <Grid item xs={12} sm={9}>
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'left',
                            flexWrap: 'wrap',
                            listStyle: 'none',
                            p: 0,
                            m: 0,
                        }}
                        component="ul"
                    >
                        {!reMutateUrls && (
                            <>
                                <ListItem>
                                    <Chip key={0}
                                        color={'primary'}
                                        label={t('Add Version')}
                                        onClick={createItem}
                                        icon={<AddIcon />}
                                        data-cy-id={'add_version'}
                                        disabled={!rateVersionModifyPerm}
                                    />
                                </ListItem>
                                {rateVersionChips.map((data) => {
                                    return (
                                        <ListItem key={data.key}>
                                            <Tooltip disableFocusListener disableTouchListener title={`${dateFormatter(data.startDate, true)} to ${dateFormatter(data.endDate, true)}`}>
                                                <Chip
                                                    label={data.name}
                                                    onClick={(event) => { handleOnClick(event, data) }}
                                                    color={data.isActive ? 'primary' : 'default'}
                                                    onDelete={rateVersionModifyPerm && (() => { editItem(data) })}
                                                    deleteIcon={<EditIcon data-cy-id={'edit_version_' + data.name} />}
                                                />
                                            </Tooltip>
                                        </ListItem>
                                    );
                                })}
                            </>
                        )}
                    </Box>
                </Grid>
                <Grid item xs={12} sm={3} sx={{ justifyContent: "right", display: "flex", alignItems: "right" }}>
                    <Button variant="contained"
                        sx={{ maxWidth: '175px' }}
                        data-cy-id={"rate_version_rate_class_edit_button"}
                        onClick={() => editRateClassItem(rateClassItem)}
                        disabled={!useHasPermission("RateClass:Modify")}
                    >
                        {t('Edit Rate Class')}
                    </Button>
                </Grid>          
            </Grid>
            
            <Divider sx={{mt: 2, mb: 2}} />
            { selectedRateVersion === null ? (
            <Alert severity="info">
            <AlertTitle>{t('Version Required')}</AlertTitle>
                {t('You\'ll be able to manage Charges once you add a Version.')}
            </Alert>
            ) : (
            <RateChargeList rateVersion={selectedRateVersion} rateType={rateClassType} />  
            )}
        </EnsureUser>
    );
}