
// this is a workaround for non hook react to get translations
// The translator must be set in App.js
export const globalTranslator = (key, options) => {
    if (actualTranslator) {
        return actualTranslator(key, options);
    }
    return key;
}

let actualTranslator = null;

export const setGlobalTranslator = (translator) => {
    actualTranslator = translator;
}