import { MyBillsGrid } from "./MyBillsGrid"
// @ts-ignore
import { useResponsive } from 'lib/useResponsive';
import { useState, useLayoutEffect, useEffect, ChangeEvent } from "react";
import { useSearchParams } from "react-router-dom";
// @ts-ignore
import { isEqual } from "lib/Compare";
// @ts-ignore
import { FilterQuery } from "utils/GridUtils";
// @ts-ignore
import { MyBillsCardList } from "./MyBillsCardList";

interface MyBillsGridStatefulProps {
    generalSearch: string | undefined | null,
    id: number | null,
    onSelected: (newValues: {}) => void;
}

export const MyBillsGridStateful = (props: MyBillsGridStatefulProps) => {
    const [searchParams, setSearchParams] = useSearchParams();
    const queryParamName = (props.id || "myBillsGrid") + "_state";
    const [isRenderReady, setIsRenderReady] = useState(true);
    const { isMobile } = useResponsive();

    const getQueryCurrent = () => {
        let defaultValue = {
            take: 10,
            skip: 0,
            pageSize: 10,
            generalSearch: props.generalSearch || null,
            sort: [{ field: "statementDate", sort: "desc" }],
        };

        let valueStr: any = searchParams.get(queryParamName);
        let result: FilterQuery = {
            take: 0,
            skip: 0,
            pageSize: 0,
            generalSearch: '',
            sort: [{
                field: '',
                sort: ''
            }]
        };

        try {
            result = JSON.parse(valueStr);
        }
        catch {
            console.error('Invalid Query Param ' + queryParamName, valueStr);
        }
        if (!result) {
            return defaultValue;
        }
        return result;
    };
    let queryCurrent: FilterQuery = getQueryCurrent();
    const [current, setCurrent] = useState(queryCurrent);

    const handleFilterChange = (e: ChangeEvent<HTMLInputElement>) => {
        const newCurrent = { ...current, ...e };
        setCurrent(newCurrent);
        searchParams.set(queryParamName, JSON.stringify(newCurrent));
        setSearchParams(searchParams);
    }

    useEffect(() => {
        if (!isEqual(current.generalSearch, props.generalSearch)) {
            const newCurrent = { ...current, generalSearch: props.generalSearch, skip: 0 }
            setCurrent(newCurrent);
            searchParams.set(queryParamName, JSON.stringify(newCurrent));
            setSearchParams(searchParams);
            setIsRenderReady(false);
        }
    }, [current, props.generalSearch, queryParamName, searchParams, setSearchParams]);

    useLayoutEffect(() => {
        if (JSON.stringify(queryCurrent) !== JSON.stringify(current) && isRenderReady) {
            setIsRenderReady(false);
        }

        if (!isRenderReady) {
            setIsRenderReady(true);
            setCurrent(queryCurrent);
        }
    }, [queryCurrent, current, isRenderReady, setIsRenderReady]);

    return (
        <>
            {isRenderReady &&
                (!isMobile ? (
                <MyBillsGrid {...props}
                    sort={current.sort}
                    dataState={current}
                    generalSearch={props.generalSearch}
                    onFilterChange={handleFilterChange}
                    toolbar={undefined}
                    />
                ) : (
                    <>
                        <MyBillsCardList {...props} />
                    </>
                ))
            }
        </>
    );
}
