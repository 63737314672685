import { getRateClassesDefaultItem, rateClassesValidationSchema } from './RateClassAPIEndpoints';
import PropTypes from 'prop-types';
import { Formik, Form, Field } from 'formik';
import { useRef, useEffect } from 'react';
import { TextField } from 'formik-mui';
import { UcEnumSelect } from 'components/common/UcEnumSelect';
import { useTranslation } from "react-i18next";
import { Typography, FormControlLabel, Switch, Grid } from '@mui/material';

export const RateClassForm = (props) => {
    const itemToEdit = props.itemToEdit || getRateClassesDefaultItem();
    const dialogSubmit = useRef(props.dialogSubmit);
    const formRef = useRef();
    const { t } = useTranslation();

    useEffect(() => {
        if (props.dialogSubmit !== dialogSubmit.current) {
            dialogSubmit.current = props.dialogSubmit;
            if (formRef.current) {
                formRef.current.handleSubmit();
            }
        }
    });

    return (
        <>
            <Formik
                innerRef={formRef}
                initialValues={itemToEdit}
                validationSchema={rateClassesValidationSchema}
                onSubmit={(values) => {
                    props.onSubmit(values);
                }}
            >
                <Form readOnly={props.readOnly}>
                    <Field
                        name={"name"}
                        data-cy-id={"rate_class_name"}
                        component={TextField}
                        disabled={props.disabled}
                        label={t("Name")}
                    />
                    <Field
                        name={"description"}
                        data-cy-id={"rate_class_description"}
                        component={TextField}
                        disabled={props.disabled}
                        label={t("Description")}
                    />
                    <Field
                        name={"status"}
                    >
                        {({ field: { value }, form: { setFieldValue } }) => (
                            <UcEnumSelect
                                value={value}
                                datacyid={"rate_class_status"}
                                enumName="RateStatus"
                                label={t("Status")}
                                disabled={props.disabled}
                                onChange={(e, statusValue) => setFieldValue("status", statusValue.id)} />
                        )}
                    </Field>
                    <Field
                    name={"commodity"}
                    >
                        {({ field: { value }, form: { setFieldValue } }) => (
                            <UcEnumSelect
                                value={value}
                                datacyid={"rate_class_commodity"}
                                enumName="Commodity"
                                label={t("Commodity")}
                                disabled={itemToEdit.id > 0}
                                onChange={(e, commodityValue) => setFieldValue("commodity", commodityValue.id)} />
                        )}
                    </Field>
                    <Field sx={{display: itemToEdit.id > 0 ? 'inline-flex' : 'none'}}
                        name={"u1020Key"}
                        data-cy-id={"rate_class_u1020Key"}
                        component={TextField}
                        disabled={true}
                        label={t("U1020 Key")}
                    />
                    <Grid container direction='row' alignItems='center' sx={{ display: 'flex', ml: 2 }}>                    
                        <Field name={"allowComparison"}>
                            {({ field: { value: fieldValue }, form: { setFieldValue } }) => (
                                <FormControlLabel
                                    control={
                                        <Switch
                                            data-cy-id="rate_class_comparison_toggle"
                                            checked={fieldValue}
                                            onChange={(e, changeValue) => {
                                                setFieldValue("allowComparison", changeValue);
                                            }}
                                        />
                                    }
                                />
                            )}
                        </Field> 
                        <Typography> {t("Enable Comparison")} </Typography>
                    </Grid>
                                 
                </Form>
            </Formik>
        </>
    );
}

RateClassForm.defaultProps = {
    id: 0,
    dialogSubmit: 0,
    changeIteration: 0,
    onSubmit: (values) => { console.log("onSubmit not implemented", values); },
    onError: (error) => { console.error("onError not implemented", error); },
};

RateClassForm.propTypes = {
    id: PropTypes.number,
    changeIteration: PropTypes.number,
    dialogSubmit: PropTypes.number,
};