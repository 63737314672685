import React from 'react';
import LoadingButton from '@mui/lab/LoadingButton';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Paper from '@mui/material/Paper';
import Draggable from 'react-draggable';
import PropTypes from 'prop-types';
import { getAnnouncementsDefaultItem } from './AnnouncementAPIEndpoints';
import { useResponsive } from 'lib/useResponsive';
import { useTranslation } from "react-i18next";
import { useTheme } from '@mui/material/styles';
import { dateFormatterTimezone } from 'lib/Formatters';
import { useMyTimezone } from 'components/customer/orgs/MyOrgAPIEndpoints';

function PaperComponent(props) {
    return (
        <Draggable
            handle="#draggable-dialog-title"
            cancel={'[class*="MuiDialogContent-root"]'}
        >
            <Paper {...props} />
        </Draggable>
    );
}

export const AnnouncementSendEmailDialog = (props) => {
    const { t } = useTranslation();
    const { isMobile } = useResponsive();
    const theme = useTheme();
    const timezone = useMyTimezone();

    const handleCancel = () => {
        props.onCancel();
    };

    const handleSendEmail = () => {
        props.itemToEdit.isSendEmails = true;
        props.onSave();
    };

    return (
        <>
            {props.open &&
                <Dialog
                    fullScreen={isMobile}
                    fullWidth={true}
                    maxWidth={"sm"}
                    open={props.open}
                    PaperComponent={PaperComponent}
                    aria-labelledby="draggable-dialog-title"
                >
                    <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
                        {t("Send Emails")}
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            {t('Are you sure you want to send this announcement as an email to all users?')}
                            {props.itemToEdit.lastEmailsSent && (
                                t('Last emails sent: ') + dateFormatterTimezone(props.itemToEdit.lastEmailsSent, timezone)  
                            )}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button autoFocus onClick={handleCancel} variant={theme.props?.CancelButton?.variant}>
                            {t("Cancel")}
                        </Button>
                        <LoadingButton
                            loading={props.loading}
                            onClick={handleSendEmail}
                            variant="contained"
                        >
                            {t("Save & Send Emails")}
                        </LoadingButton>                       
                    </DialogActions>
                </Dialog>
            }           
        </>
    );
}

AnnouncementSendEmailDialog.defaultProps = {
    open: false,
    id: 0,
    itemToEdit: getAnnouncementsDefaultItem(),
    onCancel: () => { console.log("onCancel not implemented"); },
    onSave: (data) => { console.log("onSave not implemented"); },
    onError: (error) => { console.error("onError not implemented", error); },
};

AnnouncementSendEmailDialog.propTypes = {
    open: PropTypes.bool,
    id: PropTypes.number,
    itemToEdit: PropTypes.object,
};