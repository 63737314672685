import { getMetersDefaultItem, meterValidationSchema } from './MeterAPIEndpoints';
import { convertToLocalTime } from "lib/DateRuleSetFormatter";
import { getDateFromMui } from "lib/Formatters"
import { useMyTimezone } from 'components/customer/orgs/MyOrgAPIEndpoints';
import PropTypes from 'prop-types';
import { Formik, Form, Field } from 'formik';
import { useRef, useEffect, useState } from 'react';
import { MeterAdminSelect } from '../Customers/MeterAdminSelect';
import { UcDatePickerFormik } from 'components/common/UcDatePicker';
import { Grid, FormControlLabel, Switch } from '@mui/material';
import { useTranslation } from 'react-i18next';

export const MeterForm = (props) => {
    const { t } = useTranslation();
    const timezone = useMyTimezone();
    const itemToEdit = props.itemToEdit || getMetersDefaultItem();
    const dialogSubmit = useRef(props.dialogSubmit);
    let date = new Date(2099, 1, 1, 23, 59, 59);
    const [hasEndDate, setHasEndDate] = useState(new Date(itemToEdit.endTimestamp) < date && itemToEdit.endTimestamp !== null); 

    const selectedAccountMeter =
        {
            id: itemToEdit.accountMeterId || -1,
            accountId: props.accountId,
            accountMeterId: props.accountMeterId,
            customerName: itemToEdit.customerName || "",
            extAccountNumber: itemToEdit.extAccountNumber || "",
            serialNo: itemToEdit.serialNo || "",
            startTimestamp: itemToEdit.startTimestamp || "",
            endTimestamp: itemToEdit.endTimestamp || "",
        };

    const formRef = useRef();

    useEffect(() => {
        if (props.dialogSubmit !== dialogSubmit.current) {
            dialogSubmit.current = props.dialogSubmit;
            if (formRef.current) {
                formRef.current.values.accountMeterId = selectedAccountMeter?.id;
                formRef.current.handleSubmit();
            }
        }
    });

    const handleChangeStartTimestamp = (value) => {
        if (formRef.current !== undefined) {
            formRef.current.values.startTimestamp = convertToLocalTime(getDateFromMui(value), timezone);
        }
    }

    const handleChangeEndTimestamp = (value) => {
        if (formRef.current !== undefined && hasEndDate) {
            formRef.current.values.endTimestamp = convertToLocalTime(getDateFromMui(value), timezone);
        }
    }

    const handleChangeHasEndDate = (e, value) => {
        if (value) {
            formRef.current.values.endTimestamp = convertToLocalTime(getDateFromMui(date), timezone);
        }
        else {
            formRef.current.values.endTimestamp = null;
        }

        setHasEndDate(value);
    }

    return (
        <div className='ignoreCustomFormStyles'>
            <Formik
                innerRef={formRef}
                initialValues={itemToEdit}
                validationSchema={meterValidationSchema}
                onSubmit={(values) => {
                    props.onSubmit(values);
                }}
            >
                <Form readOnly={props.readOnly}>
                    <Grid container spacing={2} style={{ paddingTop: '8px' }}>
                        <Grid item xs={12} sm={12} md={12}>
                            <Field
                                name={"serialNo"}
                                value={selectedAccountMeter}
                                component={MeterAdminSelect}
                                allMeters={false}
                                showFullLabel={false}
                                disabled={true}
                                label={t("Meter")}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={6}>
                            <Field
                                name={"startTimestamp"}
                                dateOnly={true}
                                component={UcDatePickerFormik}
                                disabled={props.disabled}
                                label={t("Start Date")}
                                sendValueToForm={handleChangeStartTimestamp}
                            />
                        </Grid>
                        { hasEndDate && (
                            <Grid item xs={12} sm={12} md={6}>
                                <Field
                                    name={"endTimestamp"}
                                    dateOnly={true}
                                    component={UcDatePickerFormik}
                                    disabled={!hasEndDate}
                                    label={t("End Date")}
                                    sendValueToForm={handleChangeEndTimestamp}
                                />
                            </Grid>
                        )}
                        <Grid item xs={12} sm={12} md={12}>
                            <FormControlLabel
                                label="Has End Date"
                                control={
                                    <Switch
                                        checked={hasEndDate}
                                        onChange={handleChangeHasEndDate}
                                    />
                                }
                            />
                        </Grid>
                    </Grid>
                </Form>
            </Formik>
        </div>
    );
}

MeterForm.defaultProps = {
    id: 0,
    dialogSubmit: 0,
    changeIteration: 0,
    onSubmit: (values) => { console.log("onSubmit not implemented", values); },
    onError: (error) => { console.error("onError not implemented", error); },
};

MeterForm.propTypes = {
    id: PropTypes.number,
    changeIteration: PropTypes.number,
    dialogSubmit: PropTypes.number,
};