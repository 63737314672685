import React, { useState} from 'react';
// @ts-ignore
import { EnsureUser } from 'components/auth/EnsureUser';
import { UcInfiniteScrollList } from '../common/UcInfiniteScrollList';
import MarkEmailReadIcon from '@mui/icons-material/MarkEmailRead';
import MarkEmailUnreadIcon from '@mui/icons-material/MarkEmailUnread';
import { MyNotificationPreferences } from "./MyNotificationPreferences";
import Box from '@mui/material/Box';

export interface UserNotificationPreferenceCardProps {
    defaultDataItems: any;
    actionsHandler: any;
    generalSearch: string;
}

export const UserNotificationPreferenceCardList = (props: UserNotificationPreferenceCardProps) => {

    const pageSize = 5;
    const [generalSearchInput] = useState(props.generalSearch || "");
    const initialSort = [{ field: "Id", sort: "desc" }];
    const sort = initialSort;
    const initialDataState = { skip: 0, take: pageSize, };

    const filter = ({
        take: initialDataState.take,
        skip: initialDataState.skip,
        sort: sort,
        generalSearch: generalSearchInput,  
        ignoreCustomAlertCreation: true
    });

    return (
        <EnsureUser>
            <Box sx={{ py: 1 }}>
                <MyNotificationPreferences collapseOnMobile />
            </Box>
            <UcInfiniteScrollList
                apiEndPoint={'api/v1/UserNotificationPreference'}
                defaultDataItems={props.defaultDataItems}
                filter={filter}
                actionsHandler={props.actionsHandler}
                renderCondition='isAcknowledged'
                avatarIfRenderConditionTrue={<MarkEmailReadIcon />}
                avatarIfRenderConditionFalse={<MarkEmailUnreadIcon />}
            />
        </EnsureUser>
    );
}


