import { ApiClient } from "lib/ApiClient";
import { globalTranslator } from "lib/GlobalTranslator";
import { useGenericSWR } from "lib/useGenericSWR";
import { mutateMyUserAccounts } from './MyUserAccountAPIEndpoints';
import * as Yup from 'yup';

const apiEndpoint = 'api/v1/MyUserDelegation';
const t = globalTranslator;

let formIteration = 0;

const getMyUserDelegationsDefaultItem = () => {
    formIteration = formIteration + 1;
    return {
        formIteration: "new" + formIteration,
        id: 0,
        userDelegationId: 0,
        accessLevel: "ReadAccount",
        createdBy: "REACT_FE",
    }
};

// Had to make this a function so that globalTranslator was initialized
const MyUserDelegationsValidationSchema = (currentUserEmail) => {
    return Yup.object().shape({
        delegatedUserEmail: Yup.string().email("Delegated user email must be a valid email address.").required(t("Required")).notOneOf([currentUserEmail], t("You cannot delegate to yourself")),
        accountId: Yup.number().required(t("Required")).integer(),
        accessLevel: Yup.string().required(t("Required")),
    });
};

// See definition from UsageRate.cs
const apiClient = new ApiClient();
apiClient.getDefaultItem = () => getMyUserDelegationsDefaultItem();
apiClient.getObjectName = () => {
    return t("User Delegation");
};

const mutateMyUserDelegations = () => {
    apiClient.mutateFetchUrls();
}

const useMyUserDelegation = (id, swrOptions = {}) => {
    // SWR will prevent duplicate requests
    // It will also maintain data state as long as your are using the same urls for your requests
    return useGenericSWR(apiEndpoint + "/" + id, apiClient, { ...swrOptions });
};

const useMyUserDelegations = (filter, swrOptions = {}) => {
    // SWR will prevent duplicate requests
    // It will also maintain data state as long as your are using the same urls for your requests
    const params = new URLSearchParams();
    params.append('filter', JSON.stringify(filter));
    return useGenericSWR(apiEndpoint + "?" + params.toString(), apiClient, { ...swrOptions });
};

const saveMyUserDelegation = (MyUserDelegation) => {
    if (MyUserDelegation.id === 0) {
        return insertMyUserDelegation(MyUserDelegation);
    }
    return updateMyUserDelegation(MyUserDelegation);
}
const insertMyUserDelegation = (MyUserDelegation) => {
    mutateMyUserAccounts();
    return apiClient.poster(apiEndpoint, MyUserDelegation);
};

const updateMyUserDelegation = (MyUserDelegation) => {
    return apiClient.putter(apiEndpoint, MyUserDelegation);
};

const deleteMyUserDelegation = (MyUserDelegation) => {
    mutateMyUserAccounts();
    return apiClient.deleter(apiEndpoint + "/" + MyUserDelegation.id);
};

export {
    useMyUserDelegation,
    useMyUserDelegations,
    saveMyUserDelegation,
    insertMyUserDelegation,
    updateMyUserDelegation,
    deleteMyUserDelegation,
    MyUserDelegationsValidationSchema,
    getMyUserDelegationsDefaultItem,
    mutateMyUserDelegations,
};