import { MeterOption } from "components/common/Common.interfaces";

export const generateRandom = (): string => {
    let length = 8,
        charset = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789",
        retVal = "";
    for (let i = 0, n = charset.length; i < length; ++i) {
        retVal += charset.charAt(Math.floor(Math.random() * n));
    }
    return retVal;
}

export const isMeterInRange = (meter: MeterOption, startDate?: Date, endDate?: Date): boolean => {
    const meterStartDate = meter.startTimestamp ? new Date(meter.startTimestamp) : null;
    const meterEndDate = meter.endTimestamp ? new Date(meter.endTimestamp) : null;

    if (!meterStartDate || !meterEndDate || !startDate || !endDate) {
        return false; 
    }
    return meterEndDate > startDate && meterStartDate < endDate;
};
