import { getOutgoingNotificationsDefaultItem, outgoingNotificationsValidationSchema } from './OutgoingNotificationAPIEndpoints';
import PropTypes from 'prop-types';
import { Formik, Form, Field } from 'formik';
import { useRef, useEffect } from 'react';
import { TextField, Select } from 'formik-mui';
import MenuItem from '@mui/material/MenuItem';

/**
 * This component handles the form for creating and editing
 * */
export const OutgoingNotificationForm = (props) => {
    const itemToEdit = props.itemToEdit || getOutgoingNotificationsDefaultItem();
    const dialogSubmit = useRef(props.dialogSubmit);
    const formRef = useRef();

    useEffect(() => {
        if (props.dialogSubmit !== dialogSubmit.current) {
            dialogSubmit.current = props.dialogSubmit;
            if (formRef.current) {
                formRef.current.handleSubmit();
            }
        }
    });


    return (
        <>
        <Formik
            innerRef={formRef}
            initialValues={itemToEdit}
            validationSchema={outgoingNotificationsValidationSchema}
            onSubmit={(values) => {
                if (!props.readonly) {
                    props.onSubmit(values);
                }
            }}
        >
        <Form readonly={props.readonly}>
            <Field
                name={"toAddress"}
                component={TextField}
                disabled={props.disabled}
                inputProps={{readOnly: props.readonly}}
                label={"To Address"}
            />
            <Field
                name={"fromAddress"}
                component={TextField}
                disabled={props.disabled}
                inputProps={{readOnly: props.readonly}}
                label={"From Address"}
            />
            <Field
                name={"subject"}
                component={TextField}
                className={"double-width-input"}
                disabled={props.disabled}
                inputProps={{readOnly: props.readonly}}
                label={"Subject"}
            />
            <Field
                name={"body"}
                component={TextField}
                disabled={props.disabled}
                inputProps={{readOnly: props.readonly}}
                className={"double-width-input"}
                multiline
                rows={6}
                label={"Body"}
            />
            <Field
                name={"errorMessage"}
                component={TextField}
                disabled={props.disabled}
                inputProps={{readOnly: props.readonly}}
                className={"double-width-input"}
                multiline
                rows={6}
                label={"Error Message"}
            />
            <Field
                name={"isSent"}
                component={Select}
                disabled={props.disabled}
                inputProps={{readOnly: props.readonly}}
                label={"Sent"}
            >
                <MenuItem value="true">Sent</MenuItem>
                <MenuItem value="false">Not Sent</MenuItem>
            </Field>
        </Form>
        </Formik>
        </>
    );
}

OutgoingNotificationForm.defaultProps = {
    id: 0,
    dialogSubmit: 0,
    changeIteration: 0,
    onSubmit: (values) => { console.log("onSubmit not implemented", values); },
    onError: (error) => { console.error("onError not implemented", error); },
};

OutgoingNotificationForm.propTypes = {
    id: PropTypes.number,
    changeIteration: PropTypes.number,
    dialogSubmit: PropTypes.number,
};