import { UserNotificationPreferenceGrid } from "./UserNotificationPreferenceGrid"
import { useState, useLayoutEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { useResponsive } from 'lib/useResponsive';
import AddIcon from '@mui/icons-material/Add';
import { UserNotificationPreferenceCardList } from "./UserNotificationPreferenceCardList";
import { Box, Fab } from '@mui/material';

export const UserNotificationPreferenceGridStateful = (props) => {
    const [searchParams, setSearchParams] = useSearchParams();
    const queryParamName = (props.id || "MyUserNotificationPreferencesGrid") + "_state";
    const [isRenderReady, setIsRenderReady] = useState(true);
    const { isMobile } = useResponsive();

    const getQueryCurrent = () => {
        let defaultValue = {
            take: 10,
            skip: 0,
            pageSize: 10,
            generalSearch: props.generalSearch || null,
            sort: [{field: "id", sort: "desc"}],
        };

        let valueStr = searchParams.get(queryParamName);
        let result = false;

        try {
            result = JSON.parse(valueStr);
        }
        catch{
            console.error('Invalid Query Param ' + queryParamName, valueStr);
        }
        if (!result) {
            return defaultValue;
        }
        return result;
    };
    let queryCurrent = getQueryCurrent();
    const [current, setCurrent] = useState(queryCurrent);

    const handleFilterChange = (e) => {
        const newCurrent = { ...current, ...e };
        setCurrent(newCurrent);
        searchParams.set(queryParamName, JSON.stringify(newCurrent));
        setSearchParams(searchParams);
    }

    useLayoutEffect(() => {
        if (JSON.stringify(queryCurrent) !== JSON.stringify(current) && isRenderReady) {
            setIsRenderReady(false);
        }

        if (!isRenderReady) {
            setIsRenderReady(true);
            setCurrent(queryCurrent);
        }
    }, [queryCurrent, current, isRenderReady, setIsRenderReady]);

    return (
        <>
        {isRenderReady &&
            (!isMobile ? (
                <UserNotificationPreferenceGrid {...props}
                    sort={current.sort}
                    dataState={current}
                    generalSearch={current.generalSearch}
                    onFilterChange={handleFilterChange}
                />
                    
            ) : (
                <>
                    <UserNotificationPreferenceCardList {...props}
                    />
                    <Box sx={{ position: "fixed", bottom: 70, right: 16 }}>
                        <Fab color="primary" aria-label="add" onClick={props.createItem}>
                            <AddIcon/>
                        </Fab>
                    </Box>
                </>
            ))
        }
        </>
    );
}
