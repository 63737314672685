import React, { useContext, useEffect, useLayoutEffect, useState } from "react";
// @ts-ignore

import "./HelpRequestListPage.scss";
// @ts-ignore
import { UcListPage } from "components/common/UcListPage";
import { useTranslation } from "react-i18next";
import { useSWRConfig } from "swr";
import EnsureUserAndModule from "components/auth/EnsureUserAndModule";
import { mutateUrls } from "lib/useGenericSWR";
// @ts-ignore
import { UcToolbar } from "components/common/UcToolbar";
import { HelpRequest } from "./HelpRequest.interfaces";
import { HelpRequestGridStateful } from "./HelpRequestGridStateful";
import { PageAction, PageContext, PageState } from "lib/PageProvider";
import { Box, Drawer } from "@mui/material";
// @ts-ignore
import { UcDrawerHeader } from 'components/common/UcDrawerHeader';
// @ts-ignore
import { HelpRequestDetailsPage } from './HelpRequestDetailsPage';
// @ts-ignore
import { useResponsive } from 'lib/useResponsive';

export const HelpRequestListPage = () => {
    const { t } = useTranslation();
    const { isMobile } = useResponsive();
    const [pageState, pageDispatch] =
        useContext<[PageState, React.Dispatch<PageAction>]>(PageContext);
    const [selectedItem, setSelectedItem] = useState<HelpRequest | null>(null);
    const [drawerOpen, setDrawerOpen] = useState(false);

    const [generalSearch, setGeneralSearch] = useState("");
    const [reMutateUrls, setReMutateUrls] = useState<boolean>(false);
    const swrConfig = useSWRConfig();

    const actions: any[] = [];

    const mutateHandler = () => {
        setReMutateUrls(true);
    }

    const handleDrawerClose = () => {
        setDrawerOpen(false);
    }

    useEffect(() => {
        pageDispatch({
            header: t("Help Request"),
            actionItems: [] as any[],
            variableHeader: "",
            tabs: [],
            onTabChange: () => {},
            tabValue: "",
        });
    }, [pageState.header, pageDispatch, selectedItem, t]);

    useLayoutEffect(() => {
        if (reMutateUrls) {
            mutateUrls(swrConfig);
            setReMutateUrls(false);
        }
    }, [reMutateUrls, swrConfig]);

    const drawerWidth = isMobile ? "70vw" : "50vw";
    console.log("HelpRequestListPage - Loaded");

    return (
        <UcListPage>
            <EnsureUserAndModule permissions={["HelpRequest:Read"]} modules={['HelpRequest']}>
                <h1 className="pageHeader">
                    {t("Help Requests")}
                </h1>
                <UcToolbar
                    hasGeneralSearch={true}
                    onGeneralSearchChange={(value: string) => {
                        setGeneralSearch(value);
                    }}
                    actions={actions}
                ></UcToolbar>
                {!reMutateUrls && (
                    <HelpRequestGridStateful
                        setDrawerOpen={setDrawerOpen}
                        generalSearch={generalSearch}
                        mutateHandler={mutateHandler}
                        onSelected={(selectedItem: any) =>
                            setSelectedItem({ ...selectedItem })
                        }
                    />
                )}
                <Drawer
                    anchor="right"
                    open={drawerOpen}
                    onClose={handleDrawerClose}
                >
                    {!isMobile && (
                        <UcDrawerHeader justify="flex-start" />
                    )}
                    <Box
                        sx={{ width: drawerWidth, margin: '15px' }}
                        role="presentation"
                    >
                        {selectedItem &&
                            <HelpRequestDetailsPage mutateHandler={mutateHandler} selectedItem={selectedItem}  />
                        }
                    </Box>
                </Drawer>
            </EnsureUserAndModule>
        </UcListPage>
    );
};
