import { getMeterCyclesDefaultItem, meterCycleesValidationSchema } from './MeterCycleAPIEndpoints';
import { convertToLocalTime } from "lib/DateRuleSetFormatter";
import { getDateFromMui } from "lib/Formatters"
import { useMyTimezone } from 'components/customer/orgs/MyOrgAPIEndpoints';
import PropTypes from 'prop-types';
import { Formik, Form, Field } from 'formik';
import { useRef, useEffect, useState } from 'react';
import { BillingCycleAdminSelect } from './BillingCycleAdminSelect';
import { MeterAdminSelect } from '../Customers/MeterAdminSelect';
import { UcDatePickerFormik } from 'components/common/UcDatePicker';
import { Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';

export const MeterCycleForm = (props) => {
    const { t } = useTranslation();
    const timezone = useMyTimezone();
    const itemToEdit = props.itemToEdit || getMeterCyclesDefaultItem();
    const dialogSubmit = useRef(props.dialogSubmit);
    const [selectedBillingCycle, setSelectedBillingCycle] = useState({ id: itemToEdit.billingCycleId || -1, name: props.billingCycleName || itemToEdit.cycleName || "" });
    const [selectedAccountMeter, setSelectedAccountMeter] = useState(
        {
            id: itemToEdit.accountMeterId || -1,
            accountId: props.accountId,
            customerName: itemToEdit.customerName || "",
            extAccountNumber: itemToEdit.extAccountNumber || "",
            serialNo: itemToEdit.serialNo || "",
            startTimestamp: itemToEdit.meterStartTimestamp || "",
        });

    const formRef = useRef();

    useEffect(() => {
        if (props.dialogSubmit !== dialogSubmit.current) {
            dialogSubmit.current = props.dialogSubmit;
            if (formRef.current) {
                formRef.current.values.billingCycleName = selectedBillingCycle?.name;
                formRef.current.values.billingCycleId = selectedBillingCycle?.id;
                formRef.current.values.accountMeterId = selectedAccountMeter?.id;
                formRef.current.handleSubmit();
            }
        }
    });

    const handleChangeStartDate = (value) => {
        if (formRef.current !== undefined) {
            formRef.current.values.startDate = convertToLocalTime(getDateFromMui(value), timezone);
        }
    }

    return (
        <div className='ignoreCustomFormStyles'>
            <Formik
                innerRef={formRef}
                initialValues={itemToEdit}
                validationSchema={meterCycleesValidationSchema}
                onSubmit={(values) => {
                    props.onSubmit(values);
                }}
            >
                <Form readOnly={props.readOnly}>
                    <Grid container spacing={2} style={{paddingTop: '8px'}}>
                        <Grid item xs={12} sm={12} md={6}>
                            <Field
                                name={"billingCycleName"}
                                value={selectedBillingCycle}
                                component={BillingCycleAdminSelect}
                                label={t("Billing Cycle")}
                                onChange={(_event, value) => {
                                    setSelectedBillingCycle(value);
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={6}>
                            <Field
                                name={"startDate"}
                                dateOnly={true}
                                component={UcDatePickerFormik}
                                disabled={props.disabled}
                                label={t("Start Date")}
                                sendValueToForm={handleChangeStartDate}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={12}>
                            <Field
                                name={"accountMeterId"}
                                userId={props.userId}
                                value={selectedAccountMeter}
                                component={ MeterAdminSelect }
                                allMeters={false}
                                showFullLabel={false}
                                disabled={itemToEdit.accountMeterId > 0}
                                label={t("Meter")}
                                onChange={(_event, value) => {
                                    setSelectedAccountMeter(value);
                                }}
                            />
                        </Grid>
                    </Grid>
                </Form>
            </Formik>
        </div>
    );
}

MeterCycleForm.defaultProps = {
    id: 0,
    dialogSubmit: 0,
    changeIteration: 0,
    onSubmit: (values) => { console.log("onSubmit not implemented", values); },
    onError: (error) => { console.error("onError not implemented", error); },
};

MeterCycleForm.propTypes = {
    id: PropTypes.number,
    changeIteration: PropTypes.number,
    dialogSubmit: PropTypes.number,
};