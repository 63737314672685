import React, { useState, useLayoutEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSWRConfig } from "swr";
import { mutateUrls } from "lib/useGenericSWR";
import {
    Button,
    Grid,
    Typography,
    Card
} from "@mui/material";
import { Field, Form, Formik, FormikProps } from "formik";
import { TextField } from "formik-mui";
import {
    HelpRequestComments,
    helpRequestCommentsValidationSchema,
    useHelpRequestComments,
    saveHelpRequestComments
} from "./HelpRequestCommentsAPIEndpoints";
// @ts-ignore
import { useUser } from 'components/auth/PermissionApiEndpoint';
// @ts-ignore
import { dateFormatter } from "lib/Formatters";
import EnsureUserAndModule from "../auth/EnsureUserAndModule";
import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown';

const COMMENT_LIMIT = 20;

export default function HelpRequestCommentsForm(props: any) {
    const { helpRequestId } = props;
    const { t } = useTranslation();
    const swrConfig = useSWRConfig();
    const formRef = React.useRef<FormikProps<HelpRequestComments>>(null);
    const [reMutateUrls, setReMutateUrls] = useState(false);
    const user = useUser();
    const [limit, setLimit] = useState(COMMENT_LIMIT);

    const swrHelpRequestComments = useHelpRequestComments(helpRequestId);
    const initialValues: HelpRequestComments = {
        id: 0,
        cepOrgId: user.cepOrgId,
        helpRequestId: helpRequestId,
        userId: user.id,
        content: ""
    };

    const showMoreComments = () => {
        setLimit(limit + 5);
    };

    const renderRowsWithItem = (
        commentData: HelpRequestComments[]
    ) => {
        return commentData?.slice(0, limit).map((item, index) => {
            return (
                <Card variant="outlined" key={item.id + "_index"} style={{ padding: "20px 10px", marginTop: "10px" }}>
                    <Grid container wrap="nowrap" spacing={2}>
                        <Grid justifyContent="left" item xs zeroMinWidth>
                            <Grid container direction={'row'}>
                                <Grid item xs={12} sm={6}>
                                    <Typography fontSize="18px" variant={"subtitle1"} style={{ textAlign: "left" }}>
                                        <strong>{item?.fullName}{item?.userId === item?.assignedUserId && " (CSR)"}:</strong>
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <Typography fontSize="14px" variant={"subtitle1"} style={{ textAlign: "right", color: "grey" }}>
                                        {dateFormatter(item?.createdTs)}
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Typography style={{ textAlign: "left" }}>
                                {item?.content}
                            </Typography>
                        </Grid>
                    </Grid>
                </Card>
            );
        });
    };

    const renderComments = (
        commentData: HelpRequestComments[]
    ) => {
        return (
            <>
                {renderRowsWithItem(commentData)}
                {limit < swrHelpRequestComments?.result?.length && (
                    <Grid item xs={12} style={{ display: "flex", alignItems: "end", justifyContent: "center" }}>
                        <Button
                            endIcon={<KeyboardArrowDown />}
                            onClick={showMoreComments}
                        >
                            {t("Show more comments")}
                        </Button>
                    </Grid>    
                )}         
            </>  
        );
    };

    useLayoutEffect(() => {
        if (reMutateUrls) {
            mutateUrls(swrConfig);
            setReMutateUrls(false);
        }
    }, [reMutateUrls, swrConfig]);

    return (
        <EnsureUserAndModule permissions={["HelpRequestComments:Read"]} modules={['HelpRequest']} isFull={false}>
            <div className="ignoreCustomFormStyles">
                <Typography variant="h6" gutterBottom data-cy-id={"helpRequestComments-title"}>{t("Internal Comments")}</Typography>
                <Formik
                    innerRef={formRef}
                    initialValues={initialValues}
                    validationSchema={helpRequestCommentsValidationSchema}
                    onSubmit={async (values, { resetForm }) => {
                        let data = await saveHelpRequestComments(values);

                        if (data) {
                            resetForm();
                            setReMutateUrls(true);
                        }
                    }}
                >
                    <Form>
                        <Grid container spacing={2} sx={{ mb: 3 }}>
                            <Grid item xs={12}>
                                <Field
                                    name={"content"}
                                    component={TextField}
                                    label={t("Comment here")}
                                    data-cy-id={"helpRequestComments-commentField"}
                                    multiline
                                    maxRows={4}
                                />
                            </Grid>
                            <Grid item xs={12} style={{ display: "flex", alignItems: "end", justifyContent: "end" }}>
                                <Button
                                    type="submit"
                                    color="primary"
                                    variant="contained"
                                    title={t("Comment")}
                                >
                                    {t("Comment")}
                                </Button>
                            </Grid>
                        </Grid>
                    </Form>
                </Formik>
                {renderComments(swrHelpRequestComments?.result)}               
            </div>
        </EnsureUserAndModule>   
    );
}
