import * as React from 'react';
import PropTypes from 'prop-types';
import IconButton from '@mui/material/IconButton';
import { useHasPermission } from 'components/auth/PermissionApiEndpoint';

export const UcIconButtons = (props) => {
    const { actions } = props;

    return (
        <>
            {actions.map((action, index) => (
                <UcIconButton key={index} action={action} />
            ))}
        </>
    );
};

const UcIconButton = (props) => {
    const {action} = props;
    let hasPermission = useHasPermission(action.requiredPermission);

    if (action.requiredPermission === undefined) {
        hasPermission = true;
    }

    return (
        <>
            {(!action.isHidden && hasPermission) && (
                <IconButton
                    title={action.title}
                    color={action.color || 'primary'}
                    onClick={action.onClick}
                    disabled={action.isDisabled}
                    href={action.href}
                    target={action.target}
                    data-cy-id={action.dataCyId}
                >
                    {action.icon}
                </IconButton>
            )}
        </>
    );
}

UcIconButtons.propTypes = {
    actions: PropTypes.array,
};