import { getApiClientGeneric } from './ApiClientGeneric';
import { useGenericSWR } from './useGenericSWR';

const apiClient = getApiClientGeneric();

export const useFilterItems = (apiEndPoint, filter, swrOptions = {}) => {
    // SWR will prevent duplicate requests
    // It will also maintain data state as long as your are using the same urls for your requests
    const params = new URLSearchParams();
    params.append('filter', JSON.stringify(filter));
    return useGenericSWR(apiEndPoint + "?" + params.toString(), apiClient, { ...swrOptions });
};