//@ts-ignore
import { ApiClient } from "../../../lib/ApiClient";
import { useGenericSWR } from "../../../lib/useGenericSWR";
import { SwrOptions } from "../../../lib/useGenericSWR.interfaces";
import { FilterQuery } from "../../common/Common.interfaces";

const apiEndpoint = 'api/v1/admin/CisSyncImportHeader';

let formIteration = 0;

const getCisSyncImportHeadersDefaultItem = () => {
    formIteration = formIteration + 1;
    return {
        formIteration: "new" + formIteration,
        id: 0,
        cepOrgId: null,
        importCode: null,
        fileName: null,
        importStartDate: null,
        importEndDate: null,
        totalMeters: 0,
        successCount: 0,
        failCount: 0,
        isImported: 0,
        accountWithInvalidEmailAndPhone: null,
        accountWithInvalidEmail: null,
        accountWithInvalidPhone: null,
        addedAccountsCount: null,
        deactivatedAccountsCount: null
    }
};

const apiClient = new ApiClient();
apiClient.getDefaultItem = () => getCisSyncImportHeadersDefaultItem();
apiClient.getObjectName = () => {
    return "CisSyncImportHeader";
};

const useCisSyncImportHeader = (id: number, swrOptions = {}) => {
    // SWR will prevent duplicate requests
    // It will also maintain data state as long as your are using the same urls for your requests
    return useGenericSWR(apiEndpoint + "/" + id, apiClient, { ...swrOptions as SwrOptions });
};

const useCisSyncImportHeaders = (filter: FilterQuery, swrOptions = {}) => {
    // SWR will prevent duplicate requests
    // It will also maintain data state as long as your are using the same urls for your requests
    const params = new URLSearchParams();
    params.append('filter', JSON.stringify(filter));
    return useGenericSWR(apiEndpoint + "?" + params.toString(), apiClient, { ...swrOptions as SwrOptions });
};

export {
    useCisSyncImportHeader,
    useCisSyncImportHeaders,
    getCisSyncImportHeadersDefaultItem,
};