import { updateUserPreference } from "./UserPreferenceApiEndpoint";

const { usePermissions } = require("components/auth/PermissionApiEndpoint");

let ignoreSwr = false;

const useLightDarkMode = () => {
    const permissionSwr = usePermissions();

    // using local storage here so we don't have to do an API request every time
    // Also, if the user logs out this setting will persist
    // If we have any user settings that are PII or other sensitive information we should not store them in local storage
    let storageResult = localStorage.getItem("lightDarkMode");
    if (storageResult === 'undefined')
    {
        storageResult = null;
    }

    if (!ignoreSwr) {
        const lightDarkMode = permissionSwr.result?.userPreferences?.find(x => x.name === "lightDarkMode")?.value;

        if (lightDarkMode && lightDarkMode !== storageResult) {
            storageResult = permissionSwr.result?.userPreferences?.lightDarkMode;
            localStorage.setItem("lightDarkMode", storageResult);
        }
    }

    return storageResult || 'light';
};

const setLightDarkMode = (mode) => {
    localStorage.setItem("lightDarkMode", mode);
    updateUserPreference("lightDarkMode", mode);
    ignoreSwr = true; // because the permissionSwr will be dirty
}

export {
    useLightDarkMode,
    setLightDarkMode,
}