import { getRateTouRulesDefaultItem, rateTouRulesValidationSchema } from './RateTouRuleAPIEndpoints';
import PropTypes from 'prop-types';
import { Formik, Form, Field } from 'formik';
import { useRef, useEffect } from 'react';
import { TextField } from 'formik-mui';
import { UcDayOfWeekSelector } from 'components/common/UcDayOfWeekSelector';
import { UcTimeRanges } from 'components/common/UcTimeRanges';
import { UcColorCirclePicker } from 'components/common/UcColorCirclePicker';
import { Grid } from '@mui/material';
import { useTranslation } from "react-i18next";
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import FormLabel from '@mui/material/FormLabel';
import Link from '@mui/material/Link';
import { UcHint } from '../../common/UcHint';
import { getUrl } from 'domain';


export const RateTouRuleForm = (props) => {
    const itemToEdit = props.itemToEdit || getRateTouRulesDefaultItem();
    const existingItems = props.existingItems || [];
    const dialogSubmit = useRef(props.dialogSubmit);
    const formRef = useRef();
    const { t } = useTranslation();

    useEffect(() => {
        if (props.dialogSubmit !== dialogSubmit.current) {
            dialogSubmit.current = props.dialogSubmit;
            if (formRef.current) {
                formRef.current.handleSubmit();
            }
        }
    });

    return (
        <div className='ignoreCustomFormStyles'>
            <Formik
                innerRef={formRef}
                initialValues={itemToEdit}
                validationSchema={rateTouRulesValidationSchema}
                onSubmit={(values) => {
                    props.onSubmit(values);
                }}
            >
                <Form readonly={props.readonly}>
                    <Grid container spacing={2} style={{paddingTop: '8px'}}>
                        <Grid item xs={12} sm={12} md={6}>
                            <Field
                                name={"name"}
                                component={TextField}
                                disabled={props.disabled}
                                label={t("Name")}
                                data-cy-id={"rateTouRule_name"}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={6}>
                            <Field
                                name={"rate"}
                                component={TextField}
                                disabled={props.disabled}
                                label={t("Rate ($)")}
                                data-cy-id={"rateTouRule_rate"}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={12}>
                            <Field
                                name={"description"}
                                component={TextField}
                                multiline
                                rows={4}
                                disabled={props.disabled}
                                label={t("Description")}
                                data-cy-id={"rateTouRule_description"}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={12}>
                            <Field
                                name={"htmlColor"}
                                existingItems={existingItems}
                                itemToEdit={itemToEdit}
                                value={formRef.current?.values?.htmlColor || itemToEdit.htmlColor}
                                component={UcColorCirclePicker}
                                onChange={(newValue) => {
                                    formRef.current.setFieldValue("htmlColor", newValue);
                                }}
                                label={t("Color")}
                                hint={t('This color will be associated with the rule when rendered on a usage graph.')}
                                data-cy-id={"rateTouRule_color"}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={12}>
                            <Field
                                name={"dateRuleSet.validWeekDays"}
                                label={t("Day Of Week")}
                                data-cy-id={"rateTouRule_dayOfWeek"}
                            >
                                {({ field: { value }, form: { setFieldValue } }) => (
                                    <UcDayOfWeekSelector editing={value} onClick={DaysOfWeekArray => setFieldValue("dateRuleSet.validWeekDays", DaysOfWeekArray)} />
                                )}
                            </Field>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12}>
                            <Field
                                name={"dateRuleSet.validTimeRanges"}
                                label={t("Time Ranges")}
                                data-cy-id={"rateTouRule_timeRanges"}
                            >
                                {({ field: { value }, form: { setFieldValue } }) => (
                                    <UcTimeRanges editing={value} onClick={TimeRanges => setFieldValue("dateRuleSet.validTimeRanges", TimeRanges)} />
                                )}
                            </Field>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12}>
                            <Field
                                name={"exemptForHolidays"}
                                label={t("Exempt Holidays")}
                            >
                                {({ field: { value }, form: { setFieldValue } }) => (
                                    <Grid>
                                        <Grid item xs={12}>
                                            <FormLabel
                                            component="legend">{t('Exempt Days')}
                                            <UcHint
                                                hintText="Create entries for holidays on the admin holiday page
                                                (in the sidebar and linked below). checking this box will make
                                                those days exempt for this rule."
                                            />
                                            </FormLabel>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={value}
                                                        data-cy-id={"rateTouRule_exemptHolidays"}
                                                        onChange={(e, value) => setFieldValue("exemptForHolidays", value)}
                                                    />
                                                }
                                                label={"Exempt for Holidays"}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Link
                                                href={getUrl("admin/Holidays")} target="_blank" variant="body2">
                                                    {t('Open Holiday Page')}
                                            </Link>
                                        </Grid>
                                    </Grid>
                                )}
                            </Field>
                        </Grid>
                    </Grid>
                </Form>
            </Formik>
        </div>
    );
}

RateTouRuleForm.defaultProps = {
    id: 0,
    dialogSubmit: 0,
    changeIteration: 0,
    onSubmit: (values) => { console.log("onSubmit not implemented", values); },
    onError: (error) => { console.error("onError not implemented", error); },
};

RateTouRuleForm.propTypes = {
    id: PropTypes.number,
    changeIteration: PropTypes.number,
    dialogSubmit: PropTypes.number,
};