// @ts-ignore
import { ApiClient } from "../../lib/ApiClient";
import * as Yup from 'yup';
// @ts-ignore
import { mutateMyUserAccounts } from 'components/user/MyUserAccountAPIEndpoints';
import { addMutateUrlPrefix } from "../../lib/useGenericSWR";

const apiEndpoint = 'api/v1/MyNicknamePreferences';

let formIteration = 0;

const getMyNicknamePreferencesDefaultItem = () => {
    formIteration = formIteration + 1;
    return {
        formIteration: "new" + formIteration,
        id: 0,
    }
};

const MyNicknamePreferencesValidationSchema = Yup.array().of(
    Yup.object({
        accountNickname: Yup.string()
            .max(50, 'Max of 50 characters')
            .nullable(),
        meters: Yup.array().of(
            Yup.object({
                meterNickname: Yup.string()
                    .max(50, 'Max of 50 characters')
                    .nullable()
            })
        )
    })
);

const apiClient = new ApiClient();
apiClient.getDefaultItem = () => getMyNicknamePreferencesDefaultItem();
apiClient.getObjectName = () => {
    return "My Nickname Preferences";
};

const updateMyNicknamePreferences = (MyNicknamePreferences: any) => {
    return apiClient.putter(apiEndpoint + '/updateNicknames', MyNicknamePreferences)
        .then((result: any) => {
            mutateMyUserAccounts();
            addMutateUrlPrefix("api/v1/MyAccount?");
            return result;
        });
};

export {
    getMyNicknamePreferencesDefaultItem,
    MyNicknamePreferencesValidationSchema,
    updateMyNicknamePreferences
};