import React, { forwardRef } from "react";
import { Button } from "@mui/material";
import { useAuth } from "react-oidc-context";
import { UcThemeContext } from '../../lib/UcThemeProvider';
import { useTranslation } from "react-i18next";
import { getDomainObj } from 'domain';
import { useLocalStorage } from "@mantine/hooks";

export const SignInButton = forwardRef((props, ref) => {
    const { t } = useTranslation();
    const auth = useAuth();
    // ommitting syntax for the win
    const [themeState, ] = React.useContext(UcThemeContext);
    const domain = getDomainObj();
    const [lastSignInOut, setLastSignInOut] = useLocalStorage({ key: 'lastSignInOut', defaultValue: null });

    console.log("LAST SIGN IN/OUT", lastSignInOut);

    const handleLogin = () => {
        setLastSignInOut("SignIn");
        auth.signinRedirect({
            extraQueryParams:
            {
                "userThemePreference": themeState.themeMode,
                ...(domain.subDomain && { "subdomain": domain.subDomain })
            }
         })
    };

    return (
        <>
            { props.isLandingPage &&
                <Button 
                    data-cy-id={props["data-cy-id"]} 
                    ref={ref} 
                    style={props.style} 
                    fullWidth={props.fullWidth !== undefined ? props.fullWidth : true} 
                    variant={props.variant !== undefined ? props.variant : "contained"} 
                    onClick={handleLogin}
                >
                    {props.children || t('Sign In')}
                </Button>
            }
            { !props.isLandingPage &&
                <Button 
                    data-cy-id={props["data-cy-id"]} 
                    ref={ref} 
                    style={props.style} 
                    fullWidth={props.fullWidth !== undefined ? props.fullWidth : false} 
                    variant={props.variant !== undefined ? props.variant : "text"} 
                    onClick={handleLogin}
                >
                    {props.children || t('Sign In')}
                </Button>
            }
        </>
    );
});