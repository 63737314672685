import React, { useState, useLayoutEffect, useRef } from 'react';
import { DataGridPro } from '@mui/x-data-grid-pro';
import { useMeters } from 'components/admin/Customers/MeterAPIEndpoints';
import { dateFormatter } from 'lib/Formatters';
import { UcFullPage } from 'components/common/UcFullPage';
import { useTranslation } from 'react-i18next';
import { getWholeRow } from '../../../lib/DataGridHelpers';
import { UcIconButtons } from 'components/common/UcIconButtons';
import CrisisAlertIcon from '@mui/icons-material/CrisisAlert';
import Tooltip from '@mui/material/Tooltip';
import { useHasModule } from "components/auth/PermissionApiEndpoint";

export const UserMeterGrid = (props) => {
    const [generalSearchInput] = useState(props.generalSearch || "");
    const initialSort = [{ field: "serialNo", sort: "asc" }];
    const sort = props.sort || initialSort;
    const { t } = useTranslation();
    const hasOutageManualModule = useHasModule("OutageManual");

    const initialDataState = props.dataState || { skip: 0, take: 10, };

    const [filter, setFilter] = useState({
        userId: parseInt(props.userId),
        take: initialDataState.take,
        skip: initialDataState.skip,
        sort: sort,
        generalSearch: generalSearchInput,
    });

    let initialFilterModel = {
        items: [
            { columnField: 'id', operatorValue: 'contains', value: '' }
        ],
        linkOperator: 'and',
    };

    if (generalSearchInput) {
        initialFilterModel = {
            items: [
                { columnField: 'id', operatorValue: 'contains', value: generalSearchInput }
            ],
            linkOperator: 'and',
        }
    }

    const [filterModel] = useState(initialFilterModel);

    const needsDataUpdate = useRef("");

    let defaultDataItems = {
        columnVisibilityModel: {
            id: false,
            cepOrgId: false,
            content: false,
            createdBy: false,
            updatedBy: false,
        },
        columns: [
            { field: 'id', headerName: t('Id') },
            { field: 'extAccountNumber', headerName: t('Account Number'), width: 200 },
            { field: 'serialNo', headerName: t('Meter Number'), width: 200 },
            { field: 'startTimestamp', headerName: t('Starting'), width: 200, valueFormatter: (params) => { return dateFormatter(params.value); } },
            { field: 'endTimestamp', headerName: t('Ending'), width: 200, valueFormatter: (params) => { return dateFormatter(params.value); } },
            { field: 'billingCycle', headerName: t('Billing Cycle'), width: 200 },
            {
                field: 'sendOutage', headerName: '', width: 60,
                valueGetter: getWholeRow,
                renderCell: (params) => {
                    const actions = [
                        {
                            icon: <Tooltip data-cy-id={"user_meter_grid_outage_icon"} title={t('Send Outage Message')}><CrisisAlertIcon /></Tooltip>,
                            color: 'primary',
                            isHidden: !hasOutageManualModule,
                            isDisabled: false,
                            requiredPermission: 'Outage:Read',
                            onClick: () => { props.onEditItem(params.value); },
                        },
                    ];
                    return (
                        <UcIconButtons actions={actions} />
                    );
                }
            }
        ],
        rows: [],
        rowCount: -1,
    };
    const [dataItems, setDataItems] = useState(defaultDataItems);

    const defaultSelectedItem = { dataItem: {}, selectedId: 0 };
    const [selectedItem, setSelectedItem] = useState(defaultSelectedItem);

    const dataReceived = (swrResult) => {
        var receivedDataItems = swrResult.result;

        var selectedDataItems = receivedDataItems.map((item) => {
            item.startDate = dateFormatter(item.startDate, true);
            item.endDate = dateFormatter(item.endDate, true);

            return { ...item, selected: item.id === selectedItem.selectedId };
        });
        selectedDataItems.iteration = receivedDataItems.iteration;
        selectedDataItems.rowCount = swrResult.rowCount;
        if (selectedDataItems.length > selectedDataItems.rowCount) {
            selectedDataItems.rowCount = selectedDataItems.length;
        }

        setDataItems({ ...defaultDataItems, rows: [...receivedDataItems], rowCount: swrResult.rowCount });
    };

    const swrUserAccounts = useMeters(filter); //Pass the datastate directly as the filter.  The API will adjust filters, orderbys ect.
    if (!swrUserAccounts.result && defaultDataItems.rowCount !== -1) {
        setDataItems(defaultDataItems);
    }

    useLayoutEffect(() => {
        if (swrUserAccounts.result) {
            var filterString = JSON.stringify(filter);
            if (needsDataUpdate.current !== filterString) {
                needsDataUpdate.current = filterString;
                dataReceived(swrUserAccounts);
            }
        }
    });

    const updateFilter = (newFilter) => {
        setFilter(newFilter);
        props.onFilterChange(newFilter);
    };

    return (
        <UcFullPage {...props}>
            <DataGridPro
                autoHeight={!props.isFullPage}
                paginationModel={{page: filter.skip / filter.take, pageSize: filter.take}}
                onPaginationModelChange={(model) => updateFilter({ ...filter, skip: model.page * model.pageSize, take: model.pageSize })}nge={(newPageSize) => updateFilter({ ...filter, take: newPageSize })}

                sortingMode='server'
                paginationMode='server'
                filterMode='server'
                sortModel={filter.sort}
                onSortModelChange={(model) => updateFilter({ ...filter, sort: model })}
                filterModel={filterModel}

                rowsPerPageOptions={[5, 10, 20]}
                onRowSelectionModelChange={(newSelectionModel) => {
                    if (newSelectionModel.length !== 1) {
                        setSelectedItem(defaultSelectedItem);
                        props.onSelected(defaultSelectedItem.dataItem);
                        return;
                    }
                    const selectedId = newSelectionModel[0];
                    var selectedDataItem = dataItems.rows.filter(obj => { return obj.id === selectedId });
                    if (selectedDataItem.length !== 1) {
                        setSelectedItem(defaultSelectedItem);
                        props.onSelected(defaultSelectedItem.dataItem);
                        return;
                    }
                    selectedDataItem = selectedDataItem[0];
                    var newlySelectedItem = { dataItem: selectedDataItem, selectedId: selectedId };

                    setSelectedItem(newlySelectedItem);
                    props.onSelected(newlySelectedItem.dataItem);
                }}
                pagination
                {...dataItems}
                initialState={{ pinnedColumns: { right: hasOutageManualModule && ['sendOutage'] } }}
            />
        </UcFullPage>
    );
}

UserMeterGrid.defaultProps = {
    onSelected: (selectedItem) => { console.log("onSelected not implemented", selectedItem); },
    onFilterChange: (filter) => { console.log("onFilterChange not implemented", filter); },
};
