import * as React from 'react';
import DialogContent from '@mui/material/DialogContent';
import { UcMobileNotificationPageContent } from './UcMobileNotificationPageContent';
// @ts-ignore
import { useDomainNavigate } from 'domain';
import { NotificationCardView } from '../common/Common.interfaces';

interface UcMobileNotificationPageProps {
    onChange: Function,
    defaultView: NotificationCardView,
    views: Array<NotificationCardView>,
    navLink: string,
    contentBackgroundColor: string,
    navigateBack: string
}

export const UcMobileNotificationPage = (props: UcMobileNotificationPageProps) => {
    const navigate = useDomainNavigate();
    const { views, onChange, defaultView, navLink, navigateBack } = props;
    const [currentView, setCurrentView] = React.useState(defaultView);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [backUrl, setBackUrl] = React.useState(navigateBack);

    const handleClose = () => {
        if (backUrl) {
            navigate(backUrl);
        }
        else {
            navigate('/');
        }     
    };

    const handlePageClick = (pageTitle: string) => {
        let view = views.filter((view: NotificationCardView) => {
            return view.title === pageTitle;
        })[0];
        if (view) {
            setCurrentView(view);
            onChange(view.title);
        }
    };

    React.useEffect(() => {
        if (navLink) {
            let view = views.filter((view: NotificationCardView) => {
                return view.title === navLink;
            })[0];
            if (view) {
                setCurrentView(view);
            }
        }
    }, [navLink, views]);

    console.log("UcMobileNotificationPage - Loaded");
    return (
        <DialogContent sx={{p: 0}}>                
            <UcMobileNotificationPageContent
                    views={views}
                    navLink={navLink}
                    onClose={handleClose}
                    currentView={currentView}
                    onPageClick={handlePageClick}
                    contentBackgroundColor={props.contentBackgroundColor}
            />
        </DialogContent>
    );
}
