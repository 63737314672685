// @ts-ignore
import { UcPagination } from "components/common/UcPagination";
import { useState, useLayoutEffect, useRef } from "react";
// @ts-ignore
import { ticketIdFormatter, dateFormatter } from "lib/Formatters";
import {
    DataGridPro,
    GridFilterModel,
    GridLogicOperator,
} from "@mui/x-data-grid-pro";
import { updateHelpRequest, useHelpRequests } from "./HelpRequestAPIEndpoints";
import ListIcon from '@mui/icons-material/List';
// @ts-ignore
import { UcIconButtons } from 'components/common/UcIconButtons';
import { useTranslation } from "react-i18next";
import { UserFullNameSelect } from 'components/admin/Users/UserFullNameSelect';
import { HelpRequest } from "./HelpRequest.interfaces";
import { UcStatusSelect } from 'components/common/UcStatusSelect';

export const HelpRequestGrid = (props: any) => {
    const { t } = useTranslation();
    const [generalSearchInput] = useState(props.generalSearch || "");
    const initialSort = [{ field: "createdTs", sort: "desc" }];
    const initialDataState = props.dataState || { skip: 0, take: 10 };

    const [filter, setFilter] = useState({
        take: initialDataState.take,
        skip: initialDataState.skip,
        sort: initialSort,
        generalSearch: generalSearchInput,
    });

    let initialFilterModel: GridFilterModel | undefined = {
        items: [{ field: "name", operator: "contains", value: "" }],
        logicOperator: GridLogicOperator.And,
    };

    const [filterModel] = useState<GridFilterModel | undefined>(
        initialFilterModel
    );

    const needsDataUpdate = useRef("");

    let defaultDataItems = {
        columnVisibilityModel: {
            id: true,
            cepOrgId: false,
            createdBy: false,
            updatedBy: false,
        },
        columns: [
            {
                field: 'openTicket', headerName: '', width: 30,
                renderCell: (params: any) => {
                    const actions = [
                        {
                            title: t('Ticket Details'),
                            icon: <ListIcon data-cy-id={"helpRequestOpenTicketButton"} />,
                            color: 'primary',
                            isHidden: false,
                            isDisabled: false,
                            onClick: () => {
                                props.setDrawerOpen(true);
                            }
                        },
                    ];
                    return (
                        <UcIconButtons actions={actions} />
                    );
                }
            },
            {
                field: "ticketId",
                headerName: t("Ticket ID"),
                width: 100,
                valueFormatter: ( params: any ) => {
                    return ticketIdFormatter(params.value);
                },
            },
            {
                field: "accountNumber",
                headerName: t("Account Number"),
                minWidth: 150,
            },
            {
                field: "name",
                headerName: t("Name"),
                width: 200,
            },
            {
                field: "subject",
                headerName: t("Subject"),
                width: 200,
            },
            {
                field: "status",
                headerName: t("Status"),
                width: 200,
                renderCell: (params: any) => {
                    return (
                        <UcStatusSelect
                            disableClearable
                            value = {params.row?.status}
                            onChange={async (event: any, newValue: any) => {
                                const helpRequest : HelpRequest = {
                                    ...params.row,
                                    status : newValue,
                                    assignedUserId : params.row.assignedUserId === 0 ? null : params.row.assignedUserId
                                }
                                try {
                                    await updateHelpRequest(helpRequest, false);
                                } finally {
                                    props.mutateHandler();
                                }
                            }}
                        />
                    );
                },
            },
            {
                field: "assignedUserId",
                headerName: t("CSR Assigned"),
                width: 300,
                renderCell: (params: any) => {
                    return (
                        <UserFullNameSelect
                            label={t('Assign CSR')}
                            value={params.row?.assignedUserId}
                            roleType={'UtilitySupport,UtilityAdmin'}
                            onChange={async (event: any, val: any) => {
                                const helpRequest : HelpRequest = {
                                    ...params.row,
                                    assignedUserId : val?.id 
                                }
                                try {
                                    await updateHelpRequest(helpRequest, false);
                                } finally {
                                    props.mutateHandler();
                                }
                            }}
                        />
                    );
                },
            },
            {
                field: "createdTs",
                headerName: t("Submission Date"),
                width: 200,
                valueFormatter: ( params: any ) => {
                    return dateFormatter(params.value);
                },
            },
        ],
        rows: [],
        rowCount: -1,
    };
    const [dataItems, setDataItems] = useState<any>(defaultDataItems);

    const defaultSelectedItem = { dataItem: {}, selectedId: 0 };
    const [selectedItem, setSelectedItem] = useState<any>(defaultSelectedItem);

    const isDataLoaded = useRef(false);

    const dataReceived = (swrResult: any) => {
        let dataItems = swrResult.result;
        let selectedDataItems = dataItems.map((item: any) => {
            return { ...item, selected: item.id === selectedItem.selectedId };
        });

        selectedDataItems.iteration = dataItems.iteration;
        selectedDataItems.rowCount = swrResult.rowCount;
        if (selectedDataItems.length > selectedDataItems.rowCount) {
            selectedDataItems.rowCount = selectedDataItems.length;
        }

        setDataItems({
            ...defaultDataItems,
            rows: [...dataItems],
            rowCount: swrResult.rowCount,
        });
    };

    const swrHelpRequests = useHelpRequests(filter); //Pass the datastate directly as the filter.  The API will adjust filters, orderbys ect.

    if (
        !swrHelpRequests.result &&
        defaultDataItems.rowCount !== -1
    ) {
        setDataItems(defaultDataItems);
    }

    const updateFilter = (filter: any) => {
        setFilter(filter);
        props.onFilterChange(filter);
    };

    useLayoutEffect(() => {
        if (swrHelpRequests.result) {
            isDataLoaded.current = true;
            let filterString = JSON.stringify(filter);
            if (needsDataUpdate.current !== filterString) {
                needsDataUpdate.current = filterString;
                dataReceived(swrHelpRequests);
            }
        }
    });

    return (
        <div data-cy-id={"HelpRequestsGridWrapper"}>
            <DataGridPro
                autoHeight={true}
                loading={!isDataLoaded.current}
                paginationModel={{
                    page: filter.skip / filter.take,
                    pageSize: filter.take,
                }}
                onPaginationModelChange={(model) =>
                    updateFilter({
                        ...filter,
                        skip: model.page * model.pageSize,
                        take: model.pageSize,
                    })
                }
                sortingMode="server"
                paginationMode="server"
                filterMode="server"
                sortModel={filter.sort}
                onSortModelChange={(model) =>
                    updateFilter({ ...filter, sort: model })
                }
                filterModel={filterModel}
                rowsPerPageOptions={[5, 10, 20]}
                onRowSelectionModelChange={(newSelectionModel) => {
                    if (newSelectionModel.length !== 1) {
                        setSelectedItem(defaultSelectedItem);
                        props.onSelected(defaultSelectedItem);

                        return;
                    }

                    const selectedId = newSelectionModel[0];
                    let selectedItem = dataItems.rows.filter((obj: any) => {
                        return obj.id === selectedId;
                    });

                    if (selectedItem.length !== 1) {
                        setSelectedItem(defaultSelectedItem);
                        props.onSelected(defaultSelectedItem);

                        return;
                    }

                    selectedItem = selectedItem[0];
                    let newlySelectedItem = {
                        dataItem: selectedItem,
                        selectedId: selectedId,
                    };

                    setSelectedItem(newlySelectedItem);
                    props.onSelected(newlySelectedItem.dataItem);
                }}
                pagination
                slots={{
                    toolbar: () => {
                        return props.toolbar || <></>;
                    },
                    pagination: UcPagination,
                }}
                {...dataItems}
                initialState={{ pinnedColumns: { left: ['openTicket'] } }}              
            />
        </div>
    );
};

HelpRequestGrid.defaultProps = {
    onSelected: (selectedItem: any) => {
        console.log("onSelected not implemented", selectedItem);
    },
    onFilterChange: (filter: any) => {
        console.log("onFilterChange not implemented", filter);
    },
};
