import React, { useState, useContext, useEffect, useLayoutEffect } from 'react';
import { getUserAccountsDefaultItem } from './UserAccountAPIEndpoints';
import { getUserDelegationsDefaultItem } from './UserDelegationAPIEndpoints';
import { PageContext } from 'lib/PageProvider';
import { UserAccountGridStateful } from "./UserAccountGridStateful"
import { UserAccountFormDialog } from './UserAccountFormDialog';
import { UserDelegationFormDialog } from './UserDelegationFormDialog';
import { UserDelegationGridStateful } from './UserDelegationGridStateful';
import { mutateUrls } from "lib/useGenericSWR";
import { useSWRConfig } from "swr";
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import { UcToolbar } from 'components/common/UcToolbar';
import { EnsureUser } from 'components/auth/EnsureUser';
import { useHasPermission } from 'components/auth/PermissionApiEndpoint';
import { useTranslation } from "react-i18next";

export const UserAccountList = (props) => {
    const { t } = useTranslation();
    const accountId = props.accountId;
    const delegatedUserId = props.delegatedUserId;
    const userId = props.userId;

    const [userAccountsEditDialog, setUserAccountsEditDialog] = useState({ open: false, delete: false });
    const [sharedWithUserEditDialog, setSharedWithUserEditDialog] = useState({ open: false, delete: false });
    const [sharedWithOtherEditDialog, setSharedWithOtherEditDialog] = useState({ open: false, delete: false });


    const [selectedItem, setSelectedItem] = useState(null);
    const [selectedSharedWithOtherItem, setSelectedSharedWithOtherItem] = useState(null);
    const [pageState, pageDispatch] = useContext(PageContext);

    const [reMutateUrls, setReMutateUrls] = useState(false);
    const swrConfig = useSWRConfig();

    const closeHandler = () => {
        setUserAccountsEditDialog({ open: false, itemToEdit: userAccountsEditDialog.dataItem });
        setSharedWithUserEditDialog({ open: false, itemToEdit: sharedWithUserEditDialog.dataItem });
        setSharedWithOtherEditDialog({ open: false, itemToEdit: sharedWithOtherEditDialog.dataItem });
    }

    const saveHandler = () => {
        closeHandler();
        // Also, remutate
        setReMutateUrls(true);
    }

    const openDialog = (dataItem) => {
        if (!dataItem) {
            return;
        }
        if (!dataItem.formIteration) {
            dataItem.formIteration = "edit" + dataItem.id;
        }
        setUserAccountsEditDialog({ open: true, itemToEdit: dataItem, delete: false });
    }

    const deleteUserAccountDialog = (dataItem) => {
        if (!dataItem) {
            return;
        }
        if (!dataItem.formIteration) {
            dataItem.formIteration = "delete" + dataItem.id;
        }
        setUserAccountsEditDialog({ open: true, itemToEdit: dataItem, delete: true });
    }

    const createUserAccountItem = () => {
        const userAccount = getUserAccountsDefaultItem();
        userAccount.accountId = accountId;
        userAccount.userId = userId;
        openDialog(userAccount);
    }
    const deleteUserAccountItem = (item) => {
        deleteUserAccountDialog(item);
    }

    const deleteSharedWithUserDialog = (dataItem) => {
        if (!dataItem) {
            return;
        }
        if (!dataItem.formIteration) {
            dataItem.formIteration = "delete" + dataItem.id;
        }
        setSharedWithUserEditDialog({ open: true, itemToEdit: dataItem, delete: true });
    }

    const deleteSharedWithUser = (item) => {
        deleteSharedWithUserDialog(item);
    }

    const actions = [
        {
            title: accountId ? 'Add User' : 'Add Account',
            toolTip: accountId ? 'Add User to Account' : 'Add Account to User',
            icon: <AddIcon />,
            onClick: () => { createUserAccountItem(); },
            isHidden: !useHasPermission("UserAccount:Modify"),
        }
    ];

    const openSharedWithOtherDialog = (dataItem) => {
        if (!dataItem) {
            return;
        }
        if (!dataItem.formIteration) {
            dataItem.formIteration = "edit" + dataItem.id;
        }
        setSharedWithOtherEditDialog({ open: true, itemToEdit: dataItem, delete: false });
    }

    const deleteSharedWithOtherDialog = (dataItem) => {
        if (!dataItem) {
            return;
        }
        if (!dataItem.formIteration) {
            dataItem.formIteration = "delete" + dataItem.id;
        }
        setSharedWithOtherEditDialog({ open: true, itemToEdit: dataItem, delete: true });
    }

    const createSharedWithOtherItem = () => {
        const userDelegation = getUserDelegationsDefaultItem();
        userDelegation.userId = userId;
        openSharedWithOtherDialog(userDelegation);
    }
    const deleteSharedWithOtherItem = () => {
        deleteSharedWithOtherDialog(selectedSharedWithOtherItem);
    }


    const sharedWithOtherActions = [
        {
            title: delegatedUserId ? t('Add User') : t('Share Access'),
            toolTip: delegatedUserId ? t('Add User to Delegation') : t('Add Delegation to User'),
            icon: <AddIcon />,
            onClick: () => { createSharedWithOtherItem(); },
            isHidden: (!useHasPermission("UserDelegation:Modify") || (userId ? false : true) || (delegatedUserId ? true : false)),
        },
        {
            title: delegatedUserId ? t('Delete User') : t('Remove Shared Access'),
            toolTip: delegatedUserId ? t('Delete User from Delegation') : t('Remove Shared Access for User'),
            color: 'error',
            icon: <DeleteIcon />,
            onClick: () => { deleteSharedWithOtherItem(); },
            isDisabled: selectedSharedWithOtherItem !== null ? false : true,
            isHidden: !useHasPermission("UserDelegation:Delete"),
        },
    ];


    useLayoutEffect(() => {
        if (reMutateUrls)
        {
            mutateUrls(swrConfig);
            setReMutateUrls(false);
        }
    }, [reMutateUrls, swrConfig]);

    useEffect(() => {
        pageDispatch({
            header: "UsageRates",
            });
    }, [pageState.header, selectedItem, pageDispatch]);

    console.log("UserAccountListPage - Loaded");
    return (
        <EnsureUser permissions={["UserAccount:Read"]}>
            <div className="UserAccountList">
                <UcToolbar actions={actions}></UcToolbar>
                <UserAccountFormDialog
                    accountId={accountId}
                    userId={userId}
                    open={userAccountsEditDialog.open}
                    delete={userAccountsEditDialog.delete}
                    itemToEdit={userAccountsEditDialog.itemToEdit}
                    onCancel={closeHandler}
                    onSave={saveHandler}
                />
                <UserDelegationFormDialog
                    userId={userId}
                    delegatedUserId={delegatedUserId}
                    open={sharedWithUserEditDialog.open}
                    delete={sharedWithUserEditDialog.delete}
                    itemToEdit={sharedWithUserEditDialog.itemToEdit}
                    onCancel={closeHandler}
                    onSave={saveHandler}
                />
                {!reMutateUrls &&
                    <UserAccountGridStateful
                        isFullPage={props.isFullPage || false}
                        accountId={accountId}
                        userId={userId}
                        user={props.user}
                        onDeleteItem={deleteUserAccountItem}
                        onDeleteSharedWithUser={deleteSharedWithUser}
                        onSelected={(selectedItem) => setSelectedItem({ ...selectedItem })}
                    />
                }

            </div>
            <div className="MyUserDelegationList">
                <h3>{t("Shared with Others")}</h3>
                <UcToolbar actions={sharedWithOtherActions}></UcToolbar>
                <UserDelegationFormDialog
                    userId={userId}
                    open={sharedWithOtherEditDialog.open}
                    delete={sharedWithOtherEditDialog.delete}
                    itemToEdit={sharedWithOtherEditDialog.itemToEdit}
                    onCancel={closeHandler}
                    onSave={saveHandler}
                />
                {!reMutateUrls &&
                    <UserDelegationGridStateful
                        isFullPage={props.isFullPage || false}
                        accountId={accountId}
                        delegationId={props.delegationId}
                        userId={userId}
                        onSelected={(selected) => setSelectedSharedWithOtherItem({ ...selected })}
                    />
                }
            </div>
        </EnsureUser>
    );
};