import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import {
    Grid,
    Typography,
    Button,
    Divider,
    Box,
    useTheme
} from "@mui/material";
import { UcLoading } from "../common/UcLoading";
import { AccountContext } from "../../lib/AccountProvider";
import ElectricBoltIcon from '@mui/icons-material/ElectricBolt';
import WaterDropIcon from '@mui/icons-material/WaterDrop';
import { ThemeProps } from "../common/Common.interfaces";
// @ts-ignore
import { DateTime } from 'luxon';
// @ts-ignore
import { routePaths } from "routes";
import { useNavigate } from "react-router-dom";
import Alert from '@mui/material/Alert';
// @ts-ignore
import { useResponsive } from "lib/useResponsive";

export default function AccountSummaryWidget() {
    const { t } = useTranslation();
    const { account } = useContext(AccountContext);
    const theme: ThemeProps = useTheme();
    const navigate = useNavigate();
    const { isMobile } = useResponsive();

    const handleClickGoUsagePageWithMeter = (uom: string) => {
        navigate(routePaths.customerSummaryPage.path, { state: {locUom: uom } });
    };

    const handleClickSetAccountNicknames = () => {
        navigate(`${routePaths.myProfilePage.path}?profileNav_link=Preferences`);
    };

    let metersJson: any = account?.metersJson;
    const now = DateTime.now();
    const yesterday = now.minus({ days: 1 }).startOf('day');
    const endOfYesterday = now.minus({ days: 1 }).endOf('day');

    let electricMeters = JSON.parse(metersJson)
        .filter((item: any) => {
            if (item.meterCommodity !== "ELECTRIC") return false;

            const startDate = DateTime.fromISO(item.startTimestamp);
            const endDate = DateTime.fromISO(item.endTimestamp);
            
            return yesterday >= startDate && endOfYesterday <= endDate;
        })
        .map((item: any) => ({
            serialNo: item.serialNo,
            meterNickname: item?.meterNickname
        }));
    
    let waterMeters = JSON.parse(metersJson)
        .filter((item: any) => {
            if (item.meterCommodity !== "WATER") return false;

            const startDate = DateTime.fromISO(item.startTimestamp);
            const endDate = DateTime.fromISO(item.endTimestamp);
            
            return yesterday >= startDate && endOfYesterday <= endDate;
        })
        .map((item: any) => ({
            serialNo: item.serialNo,
            meterNickname: item?.meterNickname
        }));

    const metersAvailable = (electricMeters.length !== 0 || waterMeters.length !== 0);

    return (
        <Box sx={{padding: '1rem'}}>
            <div
                className="dataCyId"
                data-cy-id="account_summary_dashboard_widget"
            />
            {account?.id !== null ? (
                <>
                <Grid container spacing={0}>
                    <Grid item xs={12}>
                        <Box
                            sx={{
                                display: "flex",
                            }}
                            title={t("Account " + account?.extAccountNumber)}
                        >
                            <Typography variant="h6" width={"100%"}>
                                {t("Account")}
                            </Typography>
                            <Typography
                                variant="h6"
                                width={"100%"}
                                align="right"
                            >
                                {account?.extAccountNumber}
                            </Typography>
                        </Box>
                        <Box
                            sx={{
                                display: "flex",
                            }}
                            title={t("Name " + account?.accountName)}
                        >
                            <Typography variant="h6" width="200px">
                                {t("Name")}
                            </Typography>
                            <Typography
                                variant="h6"
                                width={"100%"}
                                align="right"
                            >
                                {account?.accountName}
                            </Typography>
                        </Box>
                        <Box
                            sx={{
                                display: "flex",
                            }}
                            title={t("Address " + account?.accountAddress)}
                        >
                            <Typography variant="h6" width={"100%"}>
                                {t("Address")}
                            </Typography>
                            <Typography
                                variant="h6"
                                width={"100%"}
                                align="right"
                            >
                                {account?.accountAddress}
                            </Typography>
                        </Box>
                        <Divider
                            sx={{
                                my: 2,
                                borderBottomWidth: 2,
                            }}
                        />
                        <Typography
                            title={t("Set Account Nicknames")}
                            width={"100%"}
                            align="right"
                            fontSize="14px"                          
                            sx={{
                                cursor: 'pointer',
                                textDecoration: 'underline',
                                color: theme.props?.Commodity?.galColor
                            }}
                            onClick={handleClickSetAccountNicknames}
                            data-cy-id="account_statistics_widget_set_nicknames_text"
                        >
                            {t("Set Account Nicknames")}
                        </Typography>
                    </Grid>
                </Grid>      
                <Box
                    sx={{
                        display: "flex",
                    }}
                    title={t("Services")}
                >
                    <Typography variant="h6" width={"100%"} sx={{ pb: 1 }}>
                        {t("Services")}
                    </Typography>
                </Box>
                <Grid container spacing={0}>
                {metersAvailable ? (
                    <>
                        {electricMeters.map((meter: any) => (
                            <Grid container sx={{ pb: 1 }}>
                                <Grid item xs={1} sx={{ alignItems: 'center', display: 'flex' }}>
                                    <ElectricBoltIcon sx={{color: (theme.props?.Commodity?.kWhColor), pr: isMobile && 1}}/>
                                </Grid>
                                <Grid item xs={8} md={6} lg={7} sx={{ alignItems: 'center', display: 'flex' }}>
                                    <Typography fontSize="18px" title={meter.serialNo + (meter.meterNickname ? " " + meter.meterNickname : "")}>
                                        {meter.serialNo} {meter.meterNickname && "(" + meter.meterNickname + ")"}
                                    </Typography>
                                </Grid>
                                <Grid item xs={3} md={5} lg={4} sx={{textAlign: 'right'}}>
                                    <Button
                                        title={t("Electric Usage")}
                                        variant="outlined"
                                        color="info"
                                        onClick={() => handleClickGoUsagePageWithMeter("KWH")}
                                        data-cy-id="account_summary_dashboard_widget_kWh_usage_button"
                                    >
                                        {t("Usage")}
                                    </Button>
                                </Grid>
                            </Grid>
                        ))}
                        {waterMeters.map((meter: any) => (
                            <Grid container sx={{ pb: 1 }}>
                                <Grid item xs={1} sx={{ alignItems: 'center', display: 'flex' }}>
                                    <WaterDropIcon sx={{color: (theme.props?.Commodity?.galColor), pr: isMobile && 1}}/>
                                </Grid>
                                <Grid item xs={8} md={6} lg={7} sx={{ alignItems: 'center', display: 'flex' }}>
                                    <Typography fontSize="18px" title={meter.serialNo + (meter.meterNickname ? " " + meter.meterNickname : "")}>
                                        {meter.serialNo} {meter.meterNickname && "(" + meter.meterNickname + ")"}
                                    </Typography>
                                </Grid>
                                <Grid item xs={3} md={5} lg={4} sx={{textAlign: 'right'}}>
                                    <Button
                                        title={t("Water Usage")}
                                        variant="outlined"
                                        color="info"
                                        onClick={() => handleClickGoUsagePageWithMeter("GAL")}
                                        data-cy-id="account_summary_dashboard_widget_gal_usage_button"
                                    >
                                        {t("Usage")}
                                    </Button>
                                </Grid>
                            </Grid>
                        ))}
                    </>
                ) : (
                    <Box 
                        justifyContent="center" 
                        alignItems="center" 
                        width="100%"
                        >
                        <Alert severity="warning">
                            {t("No meters available.")}
                        </Alert>
                    </Box>
                    )}
                </Grid>
            </>
            ) : (
                <UcLoading
                    hasFade={false}
                    isLoading={true}
                    height={"20vh"}
                />
            )}  
        </Box>
    );
}
