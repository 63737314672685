import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Paper from '@mui/material/Paper';
import Draggable from 'react-draggable';
import PropTypes from 'prop-types';
import { RateTouRuleListPage } from './RateTouRuleListPage';
import { useResponsive } from 'lib/useResponsive';
import { useTranslation } from "react-i18next";
import { RateTierRuleListPage } from './RateTierRuleListPage';
import RateBasicRuleList from './RateBasicRuleList';
import { useTheme } from '@mui/material/styles';

function PaperComponent(props) {
    return (
        <Draggable
            handle="#draggable-dialog-title"
            cancel={'[class*="MuiDialogContent-root"]'}
        >
            <Paper {...props} />
        </Draggable>
    );
}

export default function RateChargeRulesDialog(props) {
    const { t } = useTranslation();
    const { isMobile } = useResponsive();
    const theme = useTheme();

    const handleCancel = () => {
        props.onCancel();
    };

    const components = {
        TIMEOFUSE: <RateTouRuleListPage rateChargeId={props.itemToEdit?.id} existingItems={props.existingItems} />,
        TIERED: <RateTierRuleListPage rateChargeId={props.itemToEdit?.id} rateChargeType={props.itemToEdit?.type} existingItems={props.existingItems} />,
        DISCOUNT: <RateTierRuleListPage rateChargeId={props.itemToEdit?.id} rateChargeType={props.itemToEdit?.type} existingItems={props.existingItems} />,
        FIXED: <RateBasicRuleList rateChargeId={props.itemToEdit?.id} rateChargeType={props.itemToEdit?.type} existingItems={props.existingItems} />,
        GENERATION: <RateTierRuleListPage rateChargeId={props.itemToEdit?.id} rateChargeType={props.itemToEdit?.type} existingItems={props.existingItems} />,
        MONTHLY: <RateBasicRuleList rateChargeId={props.itemToEdit?.id} rateChargeType={props.itemToEdit?.type} existingItems={props.existingItems} />
    }

    return (
        <>
            {props.open &&
                <Dialog
                    fullScreen={isMobile}
                    fullWidth={true}
                    maxWidth={"md"}
                    open={props.open}
                    PaperComponent={PaperComponent}
                    aria-labelledby="draggable-dialog-title"
                >
                    <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
                        {t('Rules')}
                    </DialogTitle>
                    <DialogContent>
                        {components[props.itemToEdit.type]}
                    </DialogContent>
                    <DialogActions>
                        <Button autoFocus onClick={handleCancel} variant={theme.props?.CancelButton?.variant || "outlined"} color={theme.props?.CancelButton?.color}>
                            {t('Close')}
                        </Button>
                    </DialogActions>
                </Dialog>
            }
        </>
    );
}

RateChargeRulesDialog.defaultProps = {
    open: false,
    rateChargeId: 0,
    onCancel: () => { console.log("onCancel not implemented"); },
    onSave: (data) => { console.log("onSave not implemented"); },
    onError: (error) => { console.error("onError not implemented", error); },
};

RateChargeRulesDialog.propTypes = {
    open: PropTypes.bool,
    rateChargeId: PropTypes.number,
};