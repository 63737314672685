import React, { useEffect, useRef, useState } from "react";
import { Button, FormLabel } from "@mui/material";
import { roundDateNearestHour, getDateFromMui } from "../../lib/Formatters"
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Alert from '@mui/material/Alert';
import Grid from '@mui/material/Grid';
import AddIcon from '@mui/icons-material/Add';
import "./UcTimeRanges.scss";
import { StaticTimePicker } from '@mui/x-date-pickers';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { useTranslation } from "react-i18next";
import Dialog from '@mui/material/Dialog';
import { convertToLocalTime, formatTimeRangeWithZone } from "../../lib/DateRuleSetFormatter";
import { useMyTimezone } from 'components/customer/orgs/MyOrgAPIEndpoints';

export const UcTimeRanges = (props) => {
    let endStart = new Date();
    endStart.setHours(endStart.getHours() + 4);

    const [openStartTimePicker, setOpenStartTimePicker] = React.useState(false);
    const [startTime, setStartTime] = useState(roundDateNearestHour(new Date()));
    const [endTime, setEndTime] = useState(roundDateNearestHour(endStart));
    const { t } = useTranslation();
    const timezone = useMyTimezone();

    let tempArray = [];

    if (props.editing) {
        props.editing.map((item) => { // eslint-disable-line
            let timeString = formatTimeRangeWithZone(item.startDateTime, item.endDateTime, timezone) + " (" + timezone + ")";
            let timeObject = {
                TimeString: timeString,
                StartTime: item.startDateTime,
                EndTime: item.endDateTime
            }
            tempArray.push(timeObject);
        });
    }

    const [timeRangesArray, setTimeRangesArray] = useState(tempArray);

    const propsOnClick = props.onClick;
    const firstOnClick = useRef(true);

    useEffect(() => {
        if (firstOnClick.current) {
            propsOnClick(timeRangesArray.map((range) => {
                return {
                    startDateTime: new Date(range.StartTime),
                    endDateTime: new Date(range.EndTime)
                }
            }));
            firstOnClick.current = false;
        }
    }, [propsOnClick, timeRangesArray]);

    const handleRemove = (index) => {
        const tempTimeArray = [...timeRangesArray];
        tempTimeArray.splice(index, 1);
        firstOnClick.current = true;
        setTimeRangesArray(tempTimeArray);
    }

    const handleAdd = (event) => {
        let startTimeLocal = convertToLocalTime(getDateFromMui(startTime), timezone);
        let endTimeLocal = convertToLocalTime(getDateFromMui(endTime), timezone);

        let timeString = formatTimeRangeWithZone(startTimeLocal, endTimeLocal, timezone);
        let timeObject = {
            TimeString: timeString,
            StartTime: startTimeLocal,
            EndTime: endTimeLocal
        }
        let newArray = [...timeRangesArray];
        newArray.push(timeObject);
        newArray.sort((x, y) => {
            return x.StartTime - y.StartTime;
        });
        firstOnClick.current = true;
        setTimeRangesArray(newArray);

    }

    const handleClick = (event) => {
        handleAdd();
        setOpenStartTimePicker(false);
    }

    return (
        <div className="ignoreCustomFormStyles">
            <FormLabel component="legend">Time Ranges</FormLabel>
            <Grid container spacing={2} style={{ paddingTop: '8px' }}>
                <Dialog open={openStartTimePicker} fullWidth maxWidth={"md"}>
                    <Grid container justifyContent="flex-end">
                        <Grid xs={12} sm={12} md={12} sx={{paddingTop: 1, paddingLeft: 1 }}>
                            ({timezone})
                        </Grid>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <Grid item xs={12} sm={6} sx={{ display: 'inline-block' }}>
                                <StaticTimePicker
                                    componentsProps={{
                                        actionBar: {
                                            actions: []
                                        }
                                    }}
                                    label={t("Start Time")}
                                    value={startTime}
                                    onChange={(value) => setStartTime(value)}
                                    views={['hours', 'minutes']}
                                    renderInput={() => null}
                                 />
                            </Grid>
                            <Grid item xs={12} sm={6} sx={{ display: 'inline-block' }}>
                                <StaticTimePicker
                                    componentsProps={{
                                        actionBar: {
                                            actions: []
                                        }
                                    }}
                                    label={t("End Time")}
                                    value={endTime}
                                    onChange={(value) => setEndTime(value)}
                                    views={['hours', 'minutes']}
                                    renderInput={() => null}
                                 />
                            </Grid>
                        </LocalizationProvider>
                        <Grid sx={{paddingBottom: 1, paddingRight: 1 }}>
                            <Button
                                variant="contained"
                                color="secondary"
                                onClick={() => { setOpenStartTimePicker(false) }}
                            >
                                {t('Cancel')}
                            </Button>
                            <Button
                                sx={{ marginLeft: 1 }}
                                variant="contained"
                                color="primary"
                                onClick={handleClick}
                            >
                                {t('Ok')}
                            </Button>
                        </Grid>
                    </Grid>
                </Dialog>
                <Grid item xs={12} sm={12} md={12}>
                    <>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={() => { setOpenStartTimePicker(true)}}
                            startIcon={<AddIcon />}
                        >
                            {t('Add Time Range')}
                        </Button>
                        <> ({timezone})</>
                    </>
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                    { timeRangesArray.length <= 0 &&
                        <Alert severity="info">{t('Saved time ranges will appear here.')}</Alert>
                    }
                    { timeRangesArray.length > 0 &&
                        <TableContainer component={Paper}>
                            <Table aria-label="time-range table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>{t('Time')}</TableCell>
                                        <TableCell align="right">{t('Actions')}</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    { timeRangesArray.map((row, index) => (
                                        <TableRow
                                            key={row.TimeString}
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                        >
                                            <TableCell component="th" scope="row">
                                                {row.TimeString}
                                            </TableCell>
                                            <TableCell align="right">
                                                <Button sx={{ ml: 1 }}
                                                    variant="contained"
                                                    color="error"
                                                    onClick={() => handleRemove(index)}
                                                    size="small"
                                                >
                                                    {t('Remove')}
                                                </Button>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    }
                </Grid>
            </Grid>
        </div>
    );
}