const co2ConversionRate = 0.000433;
const milesDrivenCO2Conversion = 0.000390;
const seedlingsGrownCO2Conversion = 0.060;
const smartPhonesChargedCO2Conversion = 0.00000822;

const calculateConvertedVal = (usageKwhTotal, rate, percentage) => {
    let usageconvertedCo2 = usageKwhTotal * co2ConversionRate;
    let finalval = ((usageconvertedCo2*(percentage/100))/rate).toFixed(0);
    return finalval;
}

export {
    co2ConversionRate,
    milesDrivenCO2Conversion,
    seedlingsGrownCO2Conversion,
    smartPhonesChargedCO2Conversion,
    calculateConvertedVal,
};