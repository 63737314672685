import React, { useState } from 'react';
import { useTranslation, Trans } from "react-i18next";
import { DashboardStatWrapper } from 'components/readings/ReadingMultipleUomAPIEndpoint';
import { UcLoading } from '../common/UcLoading';
import ElectricBoltIcon from '@mui/icons-material/ElectricBolt';
import WaterDropIcon from '@mui/icons-material/WaterDrop';
import { ThemeProps } from "components/common/Common.interfaces";
import
{
    useTheme,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Divider,
    Box,
    Alert
 } from '@mui/material';
import { UcProgress } from '../common/UcProgress';
import useOrgAppSettings from '../../lib/useOrgAppSettings';
// @ts-ignore
import { consumptionDateFormatter, consumptionNumberHourFormatter } from '../../lib/Formatters'; 

export interface ReadingDataProps {
    readings_kWh: any;
    readings_gal: any;
}

export interface DataStatsProps {
    last7DaysHighestConsumptionHour?: Date | null;
    last7DaysHighestAvgConsumptionHour?: number | null;
    last7DaysHighestAvgConsumptionHourAvg?: number | null;

    last1MonthHighestConsumptionDay?: Date | null;
    last1MonthHighestConsumptionDayTotal?: number | null;

    last30DaysHighestConsumptionDay?: Date | null;
    last30DaysHighestConsumptionDayTotal?: number | null;

    last3MonthsHighestConsumptionMonth?: Date | null;
    last3MonthsHighestConsumptionMonthTotal?: number | null;
}

export const AccountStatisticsListViewWidget = (props: { account: any; }) => {
    const { account } = props;
    const { t } = useTranslation();
    const theme: ThemeProps = useTheme();
    const [isFetching, setIsFetching] = React.useState(true);
    const [readingData, setReadingData] = React.useState<ReadingDataProps | null>(null);

    const [electricReadingDataStats, setElectricReadingDataStats] = React.useState<DataStatsProps | null>(null);
    const [waterReadingDataStats, setWaterReadingDataStats] = React.useState<DataStatsProps | null>(null);
    const { orgAppSettings } = useOrgAppSettings();

    const uomMappingKwh = orgAppSettings?.result?.uomMappings["KWH"];
    const uomMappingGal = orgAppSettings?.result?.uomMappings["GAL"];

    const consumptionText = {
        hour: <Trans t={t}>Your highest avg. consumption hour in the last <b>7</b> days</Trans>,
        day: <Trans t={t}>Your highest consumption day in the last <b>30</b> days</Trans>,
        month: <Trans t={t}>Your highest consumption month in the last <b>3</b> months</Trans>
    };

    const [hasError, setHasError] = useState(false);
    const [progress, setProgress] = useState({current: 0, total: 100});

    React.useEffect(() => {
        if (!isFetching && !hasError && readingData && Object.keys(readingData).length > 0) {
            if (readingData?.readings_kWh !== undefined) {
                setElectricReadingDataStats(readingData?.readings_kWh?.readingStats);
            }
            if (readingData?.readings_gal !== undefined) {
                setWaterReadingDataStats(readingData?.readings_gal?.readingStats);
            }
        }
    }, [isFetching, hasError, readingData]);

    const electricUomAvailable: boolean = account?.meterServices?.indexOf('ELECTRIC') > -1;
    const waterUomAvailable: boolean = account?.meterServices?.indexOf('WATER') > -1;
    
    return (
        <>
            <div className="dataCyId" data-cy-id="account_statistics_list_view_widget" />
            <UcLoading  hasFade={false}
                        hasError={hasError}
                        isLoading={isFetching}
                        height={'20vh'}
                        loadingRender={<UcProgress {...progress}/>}
            >
                {(electricUomAvailable || waterUomAvailable) ? (
                    <List dense={true}>
                        <ListItem>
                            <ListItemText
                                primary={consumptionText.hour}
                            />
                        </ListItem>
                            {electricUomAvailable && 
                                <ListItem>
                                    <ListItemIcon sx={{ minWidth: 40 }}>
                                        <ElectricBoltIcon sx={{color: (theme.props?.Commodity?.kWhColor)}}/>
                                    </ListItemIcon>
                                    <ListItemText primary={
                                        electricReadingDataStats?.last7DaysHighestAvgConsumptionHour !== null &&
                                        electricReadingDataStats?.last7DaysHighestAvgConsumptionHour !== undefined ? (
                                            t(electricReadingDataStats?.last7DaysHighestAvgConsumptionHourAvg?.toFixed(2) + " " + uomMappingKwh?.safeUomDisplay + " between " +
                                            consumptionNumberHourFormatter(electricReadingDataStats?.last7DaysHighestAvgConsumptionHour))
                                        ) : (
                                            t('No data ({{uomText}})', { uomText: uomMappingKwh?.safeUomDisplay })
                                        )
                                    } data-cy-id="account_statistics_list_view_widget_hour_text_kWh"/>
                                </ListItem>
                            }
                            {waterUomAvailable && 
                                <ListItem>
                                    <ListItemIcon sx={{ minWidth: 40 }}>
                                        <WaterDropIcon sx={{color: (theme.props?.Commodity?.galColor)}}/>
                                    </ListItemIcon>
                                    <ListItemText primary={
                                        waterReadingDataStats?.last7DaysHighestAvgConsumptionHour !== null &&
                                        waterReadingDataStats?.last7DaysHighestAvgConsumptionHour !== undefined ? (
                                            t(waterReadingDataStats?.last7DaysHighestAvgConsumptionHourAvg?.toFixed(2) + " " + uomMappingGal?.safeUomDisplay + " between " +
                                            consumptionNumberHourFormatter(waterReadingDataStats?.last7DaysHighestAvgConsumptionHour))
                                        ) : (
                                            t('No data ({{uomText}})', { uomText: uomMappingGal?.safeUomDisplay })
                                        )
                                    } data-cy-id="account_statistics_list_view_widget_hour_text_gal"/>
                                </ListItem>
                            }
                        <Divider sx={{ pb: 1}}/>
                        <ListItem>
                            <ListItemText
                                primary={consumptionText.day}
                            />
                        </ListItem>
                        {electricUomAvailable && 
                            <ListItem>
                                <ListItemIcon sx={{ minWidth: 40 }}>
                                    <ElectricBoltIcon sx={{color: (theme.props?.Commodity?.kWhColor)}}/>
                                </ListItemIcon>
                                <ListItemText primary={
                                    electricReadingDataStats?.last30DaysHighestConsumptionDay !== null &&
                                    electricReadingDataStats?.last30DaysHighestConsumptionDay !== undefined ? (
                                        t(electricReadingDataStats?.last30DaysHighestConsumptionDayTotal?.toFixed(2) + " " + uomMappingKwh?.safeUomDisplay + " on " +
                                        consumptionDateFormatter(electricReadingDataStats?.last30DaysHighestConsumptionDay, "day"))
                                    ) : (
                                        t('No data ({{uomText}})', { uomText: uomMappingKwh?.safeUomDisplay })
                                    )
                                } data-cy-id={'account_statistics_list_view_widget_day_text_kWh'}/>
                            </ListItem>
                        }
                        {waterUomAvailable && 
                            <ListItem>
                                <ListItemIcon sx={{ minWidth: 40 }}>
                                    <WaterDropIcon sx={{color: (theme.props?.Commodity?.galColor)}}/>
                                </ListItemIcon>
                                <ListItemText primary={
                                    waterReadingDataStats?.last30DaysHighestConsumptionDay !== null &&
                                    waterReadingDataStats?.last30DaysHighestConsumptionDay !== undefined ? (
                                        t(waterReadingDataStats?.last30DaysHighestConsumptionDayTotal?.toFixed(2) + " " + uomMappingGal?.safeUomDisplay + " on " +
                                        consumptionDateFormatter(waterReadingDataStats?.last30DaysHighestConsumptionDay, "day"))
                                    ) : (
                                        t('No data ({{uomText}})', { uomText: uomMappingGal?.safeUomDisplay })
                                    )
                                } data-cy-id={'account_statistics_list_view_widget_day_text_gal'}/>
                            </ListItem>
                        }
                        <Divider sx={{ pb: 1}}/>
                        <ListItem>
                            <ListItemText
                                primary={consumptionText.month}
                            />
                        </ListItem>
                        {electricUomAvailable && 
                            <ListItem>
                                <ListItemIcon sx={{ minWidth: 40 }}>
                                    <ElectricBoltIcon sx={{color: (theme.props?.Commodity?.kWhColor)}}/>
                                </ListItemIcon>
                                <ListItemText primary={
                                    electricReadingDataStats?.last3MonthsHighestConsumptionMonth !== null &&
                                    electricReadingDataStats?.last3MonthsHighestConsumptionMonth !== undefined ? (
                                        t(electricReadingDataStats?.last3MonthsHighestConsumptionMonthTotal?.toFixed(2) + " " + uomMappingKwh?.safeUomDisplay + " in " +
                                        consumptionDateFormatter(electricReadingDataStats?.last3MonthsHighestConsumptionMonth, "month"))
                                    ) : (
                                        t('No data ({{uomText}})', { uomText: uomMappingKwh?.safeUomDisplay })
                                    )
                                } data-cy-id={'account_statistics_list_view_widget_month_text_kWh'}/>
                            </ListItem>
                        }
                        {waterUomAvailable && 
                            <ListItem>
                                <ListItemIcon sx={{ minWidth: 40 }}>
                                    <WaterDropIcon sx={{color: (theme.props?.Commodity?.galColor)}}/>
                                </ListItemIcon>
                                <ListItemText primary={
                                    waterReadingDataStats?.last3MonthsHighestConsumptionMonth !== null &&
                                    waterReadingDataStats?.last3MonthsHighestConsumptionMonth !== undefined ? (
                                        t(waterReadingDataStats?.last3MonthsHighestConsumptionMonthTotal?.toFixed(2) + " " + uomMappingGal?.safeUomDisplay + " in " +
                                        consumptionDateFormatter(waterReadingDataStats?.last3MonthsHighestConsumptionMonth, "month"))
                                    ) : (
                                        t('No data ({{uomText}})', { uomText: uomMappingGal?.safeUomDisplay })
                                    )
                                } data-cy-id={'account_statistics_list_view_widget_month_text_gal'}/>
                            </ListItem>
                        }
                    </List>
                ) : (
                    <Box 
                        justifyContent="center" 
                        alignItems="center" 
                        width="100%"
                        >
                        <Alert severity="warning" sx={{mt: 5}}>
                            {t("No meters available.")}
                        </Alert>
                    </Box>
                )}
                
            </UcLoading>
            {account?.id !== null && (
                <DashboardStatWrapper
                    readyForData={!hasError}
                    onDataCompleted={(newData) => {
                        setIsFetching(false);
                        setReadingData(newData);
                        setHasError(false);
                    }}
                    onProgress={(progressLocal) => {
                        if (progressLocal.current !== progress.current) {
                            setProgress({...progressLocal});
                        }
                    }}
                    onReset={() => {
                        setProgress({current: 0, total: 100});
                        setIsFetching(true);
                        setHasError(false);
                    }}
                    onError={(error) => {
                        console.error(error);
                        setIsFetching(false);
                        setHasError(true);
                    }}
                />
            )}
        </>
    );
}