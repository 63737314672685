import React, { useState, useContext, useEffect, useLayoutEffect } from 'react';
import { PageContext } from '../../../lib/PageProvider';
import { RateTouRuleGridStateful } from "./RateTouRuleGridStateful"
import { RateTouRuleFormDialog } from './RateTouRuleFormDialog';
import { mutateUrls } from "../../../lib/useGenericSWR";
import { useSWRConfig } from "swr";
import { EnsureUser } from '../../auth/EnsureUser';
import { UcToolbar } from '../../common/UcToolbar';
import { useHasPermission } from 'components/auth/PermissionApiEndpoint';
import AddIcon from '@mui/icons-material/Add';
import ElectricalServicesIcon from '@mui/icons-material/ElectricalServices';
import { getRateTouRulesDefaultItem, rateTouRuleName, rateTouRulesName } from './RateTouRuleAPIEndpoints';
import { useTranslation } from "react-i18next";
import { COLOR_PICKER_OPTIONS } from '../../common/Common.constants'

export const RateTouRuleListPage = (props) => {
    const { t } = useTranslation();
    const rateChargeId = props.rateChargeId;
    const [editDialog, setEditDialog] = useState({ open: false, delete: false });
    const [selectedItem, setSelectedItem] = useState(null);
    const [pageState, pageDispatch] = useContext(PageContext);
    const existingItems = props.existingItems;

    const [reMutateUrls, setReMutateUrls] = useState(false);
    const swrConfig = useSWRConfig();

    const closeHandler = () => {
        setEditDialog({ open: false, itemToEdit: editDialog.dataItem, existingItems: editDialog.existingItems });
    }

    const saveHandler = () => {
        closeHandler();
        setReMutateUrls(true);
    }

    const openDialog = (dataItem) => {
        if (!dataItem) {
            return;
        }
        if (!dataItem.formIteration) {
            dataItem.formIteration = "edit" + dataItem.id;
        }

        setEditDialog({ open: true, itemToEdit: dataItem, delete: false, existingItems: existingItems });
    }

    const deleteDialog = (dataItem) => {
        if (!dataItem) {
            return;
        }
        if (!dataItem.formIteration) {
            dataItem.formIteration = "delete" + dataItem.id;
        }
        setEditDialog({ open: true, itemToEdit: dataItem, delete: true, setDefault: false });
    }

    const setDefaultDialog = (dataItem) => {
        if (!dataItem) {
            return;
        }

        if (!dataItem.formIteration) {
            dataItem.formIteration = "setDefault" + dataItem.id;
        }

        setEditDialog({ open: true, itemToEdit: dataItem, delete: false, setDefault: true });
    }

    const createItem = () => {
        openDialog(getRateTouRulesDefaultItem(rateChargeId));
    }

    const editItem = (item) => {
        openDialog(item);
    }

    const deleteItem = (item) => {
        deleteDialog(item);
    }

    const setIsDefault = () => {
        setDefaultDialog(selectedItem);
    }

    const actions = [
        {
            icon: <AddIcon data-cy-id={"rateTouRule_add"}/>,
            title: t('Add {{rateTouRuleName}}', {rateTouRuleName: rateTouRuleName}),
            toolTip: t('Add {{rateTouRuleName}}', {rateTouRuleName: rateTouRuleName}),
            onClick: () => { createItem(); },
            isHidden: !useHasPermission("RateTouRule:Modify"),
            isDisabled: existingItems?.length - 1 >= COLOR_PICKER_OPTIONS.length
        },
        {
            icon: <ElectricalServicesIcon />,
            title: t('Set Selected as Default'),
            toolTip: t('Set Selected as Default Time-of-use Rule'),
            onClick: () => { setIsDefault(); },
            isHidden: !useHasPermission("RateTouRule:Modify"),
            isDisabled: (selectedItem !== null ? false : true),
            color: 'secondary'
        }
    ];

    useLayoutEffect(() => {
        if (reMutateUrls) {
            mutateUrls(swrConfig);
            setReMutateUrls(false);
        }
    }, [reMutateUrls, swrConfig]);

    useEffect(() => {
        pageDispatch({
            header: rateTouRulesName,
        });
    }, [pageState.header, selectedItem, pageDispatch]);

    console.log("RateTouRuleListPage - Loaded");
    return (
        <div className="rateTouRuleListPage">
            <EnsureUser permissions={["RateTouRule:Read"]}>
                <UcToolbar actions={actions} />
                <RateTouRuleFormDialog
                    open={editDialog.open}
                    delete={editDialog.delete}
                    setDefault={editDialog.setDefault}
                    itemToEdit={editDialog.itemToEdit}
                    existingItems={existingItems}
                    onCancel={closeHandler}
                    onSave={saveHandler}
                />
                {!reMutateUrls &&
                    <RateTouRuleGridStateful
                        onEditItem={editItem}
                        onDeleteItem={deleteItem}
                        rateChargeId={rateChargeId}                       
                        onSelected={(selectedDataItem) => {
                            setSelectedItem({ ...selectedDataItem });
                        }} />
                }

            </EnsureUser>
        </div>
    );
};