import React, { useState, useLayoutEffect, useRef, useEffect } from 'react';
import { DataGridPro } from '@mui/x-data-grid-pro';
import { useBillingCyclees } from './BillingCycleAPIEndpoints';
import { UcPagination } from 'components/common/UcPagination';
import { useEnums } from 'components/auth/PermissionApiEndpoint';
import { enumFormatter } from 'lib/EnumFormatter';
import { useTranslation } from 'react-i18next';
import { UcIconButtons } from 'components/common/UcIconButtons';
import { getWholeRow } from '../../../lib/DataGridHelpers';
import EditIcon from '@mui/icons-material/Edit'
import ListIcon from '@mui/icons-material/List';

export const BillingCycleGrid = (props) => {
    const initialSort = [{ field: "name", sort: "asc" }];
    const sort = props.sort || initialSort;
    const { t } = useTranslation();
    const initialDataState = props.dataState || { skip: 0, take: 10, };

    const [filter, setFilter] = useState({
        take: initialDataState.take,
        skip: initialDataState.skip,
        sort: sort,
        generalSearch: props.generalSearch,
    });

    useEffect(() => {
        if (props.generalSearch !== filter.generalSearch) {
            setFilter({ ...filter, generalSearch: props.generalSearch });
        }
    }, [filter, setFilter, props.generalSearch]);

    let initialFilterModel = {
        items: [
            { columnField: 'id', operatorValue: 'contains', value: '' }
        ],
        linkOperator: 'and',
    };

    const [filterModel] = useState(initialFilterModel);

    const needsDataUpdate = useRef("");
    const statusEnums = useEnums("Status");

    let defaultDataItems = {
        columns: [
            {
                field: 'details', headerName: '', width: 40,
                valueGetter: getWholeRow,
                renderCell: (params) => {
                    const actions = [
                        {
                            title: t('BillingCycle Details'),
                            icon: <ListIcon data-cy-id={"billingCycleDetailsButton"} />,
                            color: 'primary',
                            isHidden: false,
                            isDisabled: false,
                            requiredPermission: 'BillingCycle:Read',
                            onClick: () => {
                                props.setDrawerOpen(true);
                            }
                        },
                    ];
                    return (
                        <UcIconButtons actions={actions} />
                    );
                }
            },
            { field: 'name', headerName: t('Name'), width: 200 },
            { field: 'description', headerName: t('Description'), flex: 1, minWidth: 200 },
            { field: 'status', headerName: t('Status'), width: 150, valueFormatter: ( params ) => {return enumFormatter(statusEnums, params.value);} },
            { field: 'startDay', type: 'number', headerName: t('Start Day'), width: 150 },
            { field: 'u1020Key', type: 'number', headerName: t('Bill Cycle Code'), width: 150 },
            {
                field: 'id', headerName: '', width: 60,
                valueGetter: getWholeRow,
                renderCell: (params) => {
                    const actions = [
                        {
                            title: t('Edit Billing Cycle'),
                            icon: <EditIcon />,
                            color: 'primary',
                            isHidden: false,
                            isDisabled: false,
                            requiredPermission: 'BillingCycle:Modify',
                            onClick: () => { props.onEditItem(params.value); },
                        },
                    ];
                    return (
                        <UcIconButtons actions={actions} />
                    );
                }
            }

        ],
        rows: [],
        rowCount: -1,
    };
    const [dataItems, setDataItems] = useState(defaultDataItems);

    const defaultSelectedItem = { dataItem: {}, selectedId: 0 };
    const [selectedItem, setSelectedItem] = useState(defaultSelectedItem);

    const isDataLoaded = useRef(false);

    const dataReceived = (swrResult) => {
        let resultItems = swrResult.result;

        let selectedDataItems = resultItems.map((item) => {
            return { ...item, selected: item.id === selectedItem.selectedId };
        });
        selectedDataItems.iteration = resultItems.iteration;
        selectedDataItems.rowCount = swrResult.rowCount;
        if (selectedDataItems.length > selectedDataItems.rowCount) {
            selectedDataItems.rowCount = selectedDataItems.length;
        }

        setDataItems({ ...defaultDataItems, rows: [...resultItems], rowCount: swrResult.rowCount });
    };

    const swrBillingCyclees = useBillingCyclees(filter);
    const isLoading = swrBillingCyclees.isLoading || !isDataLoaded.current;
    if (!swrBillingCyclees.result && defaultDataItems.rowCount !== -1) {
        setDataItems(defaultDataItems);
    }

    useLayoutEffect(() => {
        if (swrBillingCyclees.result) {
            isDataLoaded.current = true;
            var filterString = JSON.stringify(filter);
            if (needsDataUpdate.current !== filterString) {
                needsDataUpdate.current = filterString;
                dataReceived(swrBillingCyclees);
            }
        }
    });

    const updateFilter = (newFilter) => {
        setFilter(newFilter);
        props.onFilterChange(newFilter);
    };

    return (
        <div
            data-cy-id={"billingCycleGridWrapper"}
            onDoubleClick={props.doubleClick}
        >
            <DataGridPro
                autoHeight={true}
                disableColumnFilter={true}
                disableColumnSelector={true}
                loading={isLoading}
                sortingMode='server'
                filterMode='server'
                sortModel={filter.sort}
                onSortModelChange={(model) => updateFilter({ ...filter, sort: model })}
                filterModel={filterModel}
                paginationModel={{page: filter.skip / filter.take, pageSize: filter.take}}
                onPaginationModelChange={(model) => {
                    updateFilter({ ...filter, skip: model.page * model.pageSize, take: model.pageSize })
                }}
                paginationMode='server'
                rowsPerPageOptions={[5, 10, 20]}

                onRowSelectionModelChange={(newSelectionModel) => {
                    if (newSelectionModel.length !== 1) {
                        setSelectedItem(defaultSelectedItem);
                        props.onSelected(defaultSelectedItem.dataItem);
                        return;
                    }
                    const selectedId = newSelectionModel[0];
                    var selectedData = dataItems.rows.filter(obj => { return obj.id === selectedId });
                    if (selectedData.length !== 1) {
                        setSelectedItem(defaultSelectedItem);
                        props.onSelected(defaultSelectedItem.dataItem);
                        return;
                    }
                    selectedData = selectedData[0];
                    var newlySelectedItem = { dataItem: selectedData, selectedId: selectedId };

                    setSelectedItem(newlySelectedItem);
                    props.onSelected(newlySelectedItem.dataItem);
                }}
                pagination
                components={{
                    Toolbar: () => {return  props.toolbar || <></>},
                    Pagination: UcPagination,
                }}
                {...dataItems}
                initialState={{ pinnedColumns: { right: ['id'] } }}
            />
        </div>
    );
}

BillingCycleGrid.defaultProps = {
    onSelected: (selectedItem) => { console.log("onSelected not implemented", selectedItem); },
    onFilterChange: (filter) => { console.log("onFilterChange not implemented", filter); },
};
