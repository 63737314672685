import { getUserDelegationsDefaultItem, UserDelegationsValidationSchema } from './UserDelegationAPIEndpoints';
import PropTypes from 'prop-types';
import { Formik, Form, Field } from 'formik';
import { useRef, useEffect, useState } from 'react';
import { UserSelect } from 'components/admin/Users/UserSelect';
import { AccountAdminSelect } from '../Customers/AccountAdminSelect';
import { TextField } from 'formik-mui';
import { UcEnumSelect } from 'components/common/UcEnumSelect';
import { useTranslation } from 'react-i18next';
import Grid from '@mui/material/Grid';

/**
 * This component handles the form for creating and editing
 * */
export const UserDelegationForm = (props) => {
    const { t } = useTranslation();
    const itemToEdit = props.itemToEdit || getUserDelegationsDefaultItem();
    const dialogSubmit = useRef(props.dialogSubmit);
    const [selectedUser, setSelectedUser] = useState({id: itemToEdit.userId || -1, username: itemToEdit.username || ""})
    const [selectedAccount, setSelectedAccount] = useState({id: itemToEdit.accountId || -1, delegationName: itemToEdit.accountName || ""})
    const formRef = useRef();

    useEffect(() => {
        if (props.dialogSubmit !== dialogSubmit.current) {
            dialogSubmit.current = props.dialogSubmit;
            if (formRef.current) {
                if (props.accountId) {
                    formRef.current.values.accountId = props.accountId;
                    formRef.current.values.userId = selectedUser.id;
                }
                else {
                    formRef.current.values.accountId = selectedAccount.id
                    formRef.current.values.userId = props.userId;
                }
                formRef.current.handleSubmit();
            }
        }
    });

    return (
        <>
        <Formik
            innerRef={formRef}
            initialValues={itemToEdit}
            validationSchema={UserDelegationsValidationSchema()}
            onSubmit={(values) => {
                if (props.accountId) {
                    values.accountId = props.accountId;
                }
                else if (props.userId) {
                    values.userId = props.userId;
                }
                props.onSubmit(values);
            }}
        >
        <div className='ignoreCustomFormStyles'>
            <Form>
                <Grid container spacing={2} style={{paddingTop: '8px'}}>
                    <Grid item xs={12} sm={12} md={12}>
                        {props.accountId &&
                        <Field
                            name={"userId"}
                            initialValue={selectedUser}
                            component={ UserSelect }
                            label={t("User")}
                            onChange={(event, value) => {
                                setSelectedUser(value);
                            }}
                        />
                        }
                    </Grid>
                    <Grid item xs={12} sm={12} md={12}>
                        {props.userId &&
                        <Field
                            name={"accountId"}
                            initialValue={selectedAccount}
                            component={ AccountAdminSelect }
                            label={t("Account")}
                            userId={props.userId}
                            onChange={(event, value) => {
                                setSelectedAccount(value);
                            }}
                        />
                        }
                    </Grid>
                    <Grid item xs={12} sm={12} md={12}>
                        <Field
                            name={"delegatedUserEmail"}
                            component={ TextField }
                            label={t("Delegated Email")}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12}>
                        <Field
                            name={"accessLevel"}
                        >
                            {({ field: { value }, form: { setFieldValue } }) => (
                                <UcEnumSelect
                                    value={value}
                                    disabled={props.disableAccessLevel}
                                    enumName="DelegationAccessLevel"
                                    label={t("Access Level")}
                                    onChange={(e, changeValue) => setFieldValue("accessLevel", changeValue.id)} />
                            )}
                        </Field>
                    </Grid>
                </Grid>
            </Form>
        </div>
        </Formik>
        </>
    );
}

UserDelegationForm.defaultProps = {
    id: 0,
    dialogSubmit: 0,
    changeIteration: 0,
    onSubmit: (values) => { console.log("onSubmit not implemented", values); },
    onError: (error) => { console.error("onError not implemented", error); },
};

UserDelegationForm.propTypes = {
    id: PropTypes.number,
    changeIteration: PropTypes.number,
    dialogSubmit: PropTypes.number,
};