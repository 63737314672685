import {
    Alert,
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { useOneTimePayment } from "./PaymentusApiEndpoints";
import { useContext } from "react";
import { AccountContext } from "lib/AccountProvider";
// @ts-ignore
import { useResponsive } from 'lib/useResponsive';
import EnsureUserAndModule from "../auth/EnsureUserAndModule";

export interface UserPayMyBillDialogProps {
    open: boolean;
    handleClose: () => void;
}

export default function UserPayMyBillDialog(props: UserPayMyBillDialogProps) {
    const { open, handleClose} = props;
    const { t } = useTranslation();
    const { account } = useContext(AccountContext);
    const { isMobile } = useResponsive();

    const accountId = open ? account?.id : null;
    const swrPaymentUrl = useOneTimePayment(accountId);

    return (
        <EnsureUserAndModule modules={['Billing', 'PaymentusOneTimePayment']}>
            <Dialog
                maxWidth={'md'}
                open={open}
                fullScreen={isMobile}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogContent sx={{ position: 'relative', overflow: 'hidden' }}>
                <Box
                    data-cy-id="userPayMyBillDialog"
                    height={800}
                    width={800}>
                    {swrPaymentUrl ?
                            <iframe
                                title="UserPayMyBillDialog"
                                src={swrPaymentUrl?.result}
                                className="responsive-iframe"
                            />
                        :
                        <Alert severity="warning">{t("Could not load Paymentus.")}</Alert>
                    }
                </Box>
                </DialogContent>
                <DialogActions sx={{ position: 'relative', zIndex: 1, backgroundColor: '#fff' }}>
                    <Button
                        onClick={handleClose}
                        color="primary"
                        variant="outlined"
                    >
                        {t("Close")}
                    </Button>
                </DialogActions>
            </Dialog>
        </EnsureUserAndModule>
    );
}
