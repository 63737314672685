import React, { useState, useContext, useEffect, useLayoutEffect } from 'react';
import { PageContext } from '../../../lib/PageProvider';
import { OutgoingNotificationGridStateful } from "./OutgoingNotificationGridStateful"
import { OutgoingNotificationFormDialog } from './OutgoingNotificationFormDialog';
import { mutateUrls } from "../../../lib/useGenericSWR";
import { useSWRConfig } from "swr";
import './OutgoingNotificationListPage.scss';
import { EnsureUser } from '../../auth/EnsureUser';
import { UcToolbar } from '../../common/UcToolbar';
import { UcListPage } from 'components/common/UcListPage';
import { useHasPermission } from 'components/auth/PermissionApiEndpoint';
import ScreenSearchDesktopIcon from '@mui/icons-material/ScreenSearchDesktop';
import SendIcon from '@mui/icons-material/Send';
import { resendOutgoingNotification } from './OutgoingNotificationAPIEndpoints';

export const OutgoingNotificationListPage = (props) => {
    const [generalSearch, setGeneralSearch] = useState('');
    const [editDialog, setEditDialog] = useState({ open: false, delete: false });
    const [selectedItem, setSelectedItem] = useState(null);
    const [pageState, pageDispatch] = useContext(PageContext);

    const [reMutateUrls, setReMutateUrls] = useState(false);
    const swrConfig = useSWRConfig();

    const closeHandler = () => {
        setEditDialog({ open: false, itemToEdit: editDialog.dataItem });
    }

    const saveHandler = () => {
        closeHandler();
        // Also, remutate
        setReMutateUrls(true);
    }

    const openDialog = (dataItem) => {
        if (!dataItem) {
            return;
        }
        if (!dataItem.formIteration) {
            dataItem.formIteration = "edit" + dataItem.id;
        }
        setEditDialog({ open: true, itemToEdit: dataItem, delete: false });
    }

    const resendItem = () => {
        resendOutgoingNotification(selectedItem);
    }

    const viewItem = () => {
        openDialog(selectedItem);
    }

    const actions = [
        {
            icon: <SendIcon />,
            title: 'Resend Notification',
            toolTip: 'Resend Notification',
            onClick: () => { resendItem(); },
            isHidden: !useHasPermission("OutgoingNotification:Modify"),
            isDisabled: selectedItem !== null ? false : true
        },
        {
            icon: <ScreenSearchDesktopIcon />,
            title: 'View Outgoing Notification',
            toolTip: 'View Outgoing Notification',
            onClick: () => { viewItem(); },
            isHidden: !useHasPermission("OutgoingNotification:Read"),
            isDisabled: selectedItem !== null ? false : true
        },
    ];

    useLayoutEffect(() => {
        if (reMutateUrls) {
            mutateUrls(swrConfig);
            setReMutateUrls(false);
        }
    }, [reMutateUrls, swrConfig]);

    useEffect(() => {
        pageDispatch({
            header: "Outgoing Notifications",
        });
    }, [pageState.header, selectedItem, pageDispatch]);

    console.log("OutgoingNotificationListPage - Loaded");
    return (
        <UcListPage>
            <EnsureUser permissions={["OutgoingNotification:Read"]}>
                <h1 className="pageHeader">Outgoing Notification</h1>
                <UcToolbar
                    hasGeneralSearch={true}
                    onGeneralSearchChange={(value) => {
                        setGeneralSearch(value);
                    } }
                    actions={actions}></UcToolbar>
                <OutgoingNotificationFormDialog
                    open={editDialog.open}
                    delete={editDialog.delete}
                    itemToEdit={editDialog.itemToEdit}
                    onCancel={closeHandler}
                    onSave={saveHandler}
                />
                {!reMutateUrls &&
                    <OutgoingNotificationGridStateful
                        generalSearch={generalSearch}
                        onSelected={(selectedItem) => setSelectedItem({ ...selectedItem })} />
                }
            </EnsureUser>
        </UcListPage>
    );
};