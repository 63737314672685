import React, { useState } from 'react';
import { useTranslation } from "react-i18next";
import LoadingButton from '@mui/lab/LoadingButton';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Paper from '@mui/material/Paper';
import Draggable from 'react-draggable';

import PropTypes from 'prop-types';
import { RateTierRuleForm } from './RateTierRuleForm';
import { saveRateTierRule, deleteRateTierRule, getRateTierRulesDefaultItem } from './RateTierRuleAPIEndpoints';
import { useResponsive } from 'lib/useResponsive';
import { useTheme } from '@mui/material/styles';

function PaperComponent(props) {
    return (
        <Draggable
            handle="#draggable-dialog-title"
            cancel={'[class*="MuiDialogContent-root"]'}
        >
            <Paper {...props} />
        </Draggable>
    );
}

export const RateTierRuleFormDialog = (props) => {
    const { t } = useTranslation();
    const [saving, setSaving] = React.useState(false);
    const [dialogSubmit, setDialogSubmit] = useState(0);
    const { isMobile } = useResponsive();
    const theme = useTheme();
    const rateChargeType = props.rateChargeType;
    const rateChargeTypeDisplay = props.rateChargeTypeDisplay;

    let formId = props.id;
    if (!props.open) {
        formId = 0;
    }

    const handleCancel = () => {
        props.onCancel();
    };

    const handleSave = () => {
        setDialogSubmit(dialogSubmit + 1);
    };

    const handleDelete = async () => {
        let data = await deleteRateTierRule(props.itemToEdit);

        if (data) {
            props.onSave(data);
        }
    };

    return (
        <>
            {props.delete && props.open &&
                <Dialog
                    fullScreen={isMobile}
                    fullWidth={true}
                    maxWidth={"sm"}
                    open={props.open}
                    PaperComponent={PaperComponent}
                    aria-labelledby="draggable-dialog-title"
                >
                    <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
                        {t("Delete {{rateChargeTypeDisplay}} Rule", {rateChargeTypeDisplay: rateChargeTypeDisplay})}
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            {t("Are you sure you want to delete this {{rateChargeTypeDisplay}} Rule?", {rateChargeTypeDisplay: rateChargeTypeDisplay})}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button autoFocus onClick={handleCancel} variant={theme.props?.CancelButton?.variant}>
                            {t("Cancel")}
                        </Button>
                        <Button onClick={handleDelete} variant={theme.props?.CancelButton?.variant} color={theme.props?.CancelButton?.color}>
                            {t("Delete")}
                        </Button>
                    </DialogActions>
                </Dialog>
            }
            {!props.delete && props.open &&
                <Dialog
                    fullScreen={isMobile}
                    fullWidth={true}
                    maxWidth={"sm"}
                    open={props.open}
                    PaperComponent={PaperComponent}
                    aria-labelledby="draggable-dialog-title"
                >
                    <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
                        {t("{{rateChargeTypeDisplay}} Rule", {rateChargeTypeDisplay: rateChargeTypeDisplay})}
                    </DialogTitle>
                    <DialogContent>
                        <RateTierRuleForm
                            id={formId}
                            itemToEdit={props.itemToEdit}
                            rateChargeType={rateChargeType}
                            existingItems={props.existingItems}

                            dialogSubmit={dialogSubmit}
                            onSubmit={async (values) => {
                                setSaving(true);
                                try {
                                    let data = await saveRateTierRule(values);

                                    if (data) {
                                        props.onSave(data);
                                    }
                                } finally {
                                    setSaving(false);
                                }
                            }}
                            onError={(error) => {
                                props.onError(error);

                                setSaving(false);
                            }}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button autoFocus onClick={handleCancel} variant={theme.props?.CancelButton?.variant || "outlined"} color={theme.props?.CancelButton?.color}>
                            {t("Cancel")}
                        </Button>
                        <LoadingButton
                            loading={saving}
                            onClick={handleSave}
                            variant="contained"
                        >
                            {t('Save')}
                        </LoadingButton>
                    </DialogActions>
                </Dialog>
            }
        </>
    );
}

RateTierRuleFormDialog.defaultProps = {
    open: false,
    id: 0,
    itemToEdit: getRateTierRulesDefaultItem(),
    onCancel: () => { console.log("onCancel not implemented"); },
    onSave: (data) => { console.log("onSave not implemented"); },
    onError: (error) => { console.error("onError not implemented", error); },
};

RateTierRuleFormDialog.propTypes = {
    open: PropTypes.bool,
    id: PropTypes.number,
    itemToEdit: PropTypes.object,
};