import React, { useState } from 'react';
import LoadingButton from '@mui/lab/LoadingButton';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Paper from '@mui/material/Paper';
import Draggable from 'react-draggable';
import PropTypes from 'prop-types';
import { RoleForm } from './RoleForm';
import { saveRole, deleteRole, setDefault, getRolesDefaultItem } from './RoleAPIEndpoints';
import { useResponsive } from 'lib/useResponsive';
import { useTranslation } from "react-i18next";
import { useTheme } from '@mui/material/styles';

function PaperComponent(props) {
    return (
        <Draggable
            handle="#draggable-dialog-title"
            cancel={'[class*="MuiDialogContent-root"]'}
        >
            <Paper {...props} />
        </Draggable>
    );
}

/*
 * This component handles the form for creating and editing
 */
export const RoleFormDialog = (props) => {
    const { t } = useTranslation();
    const [saving, setSaving] = React.useState(false);
    const [dialogSubmit, setDialogSubmit] = useState(0);
    const { isMobile } = useResponsive();
    const theme = useTheme();

    let formId = props.id;
    if (!props.open) {
        formId = 0;
    }

    const handleCancel = () => {
        props.onCancel();
    };

    const handleSave = () => {
        setDialogSubmit(dialogSubmit + 1);
    };

    const handleDelete = async () => {
        let data = await deleteRole(props.itemToEdit);

        if (data) {
            props.onSave(data);
        }
    };

    const handleSetDefault = async () => {
        let tempItem = {...props.itemToEdit};
        tempItem.isDefault = true;

        let data = await setDefault(tempItem);

        if (data) {
            props.onSave(data);
        }
    };

    return (
        <>
            {props.setDefault && props.open &&
                <Dialog
                    fullScreen={isMobile}
                    fullWidth={true}
                    maxWidth={"sm"}
                    open={props.open}
                    PaperComponent={PaperComponent}
                    aria-labelledby="draggable-dialog-title"
                >
                    <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
                        {t("Set as Default Role")}
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            {t("Are you sure you want to set this as the default user role?")}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button autoFocus onClick={handleCancel}>
                            {t("Cancel")}
                        </Button>
                        <Button onClick={handleSetDefault}>
                            {t("Save")}
                        </Button>
                    </DialogActions>
                </Dialog>
            }
            {props.delete && props.open &&
                <Dialog
                    fullScreen={isMobile}
                    fullWidth={true}
                    maxWidth={"sm"}
                    open={props.open}
                    PaperComponent={PaperComponent}
                    aria-labelledby="draggable-dialog-title"
                >
                    <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
                        {t('Delete Role')}
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            {t('Are you sure you want to delete this role?')}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button autoFocus onClick={handleCancel} variant={theme.props?.CancelButton?.variant}>
                            {t("Cancel")}
                        </Button>
                        <Button onClick={handleDelete} variant={theme.props?.CancelButton?.variant} color={theme.props?.CancelButton?.color}>
                            {t("Delete")}
                        </Button>
                    </DialogActions>
                </Dialog>
            }
            {!props.delete && !props.setDefault && props.open &&
                <Dialog
                    fullScreen={isMobile}
                    fullWidth={true}
                    maxWidth={"md"}
                    open={props.open}
                    PaperComponent={PaperComponent}
                    aria-labelledby="draggable-dialog-title"
                >
                    <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
                        {t("Role")}
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            <RoleForm
                                id={formId}
                                itemToEdit={props.itemToEdit}
                                dialogSubmit={dialogSubmit}
                                onSubmit={async (values) => {
                                    setSaving(true);
                                    try {
                                        let data = await saveRole(values);

                                        if (data) {
                                            props.onSave(data);
                                        }
                                    } finally {
                                        setSaving(false);
                                    }
                                }}
                                onError={(error) => {
                                    props.onError(error);

                                    setSaving(false);
                                }}
                            />
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button autoFocus onClick={handleCancel} variant={theme.props?.CancelButton?.variant || "outlined"} color={theme.props?.CancelButton?.color}>
                            {t("Cancel")}
                        </Button>
                        <LoadingButton
                            loading={saving}
                            onClick={handleSave}
                            variant="contained"
                        >
                            {t("Save")}
                        </LoadingButton>
                    </DialogActions>
                </Dialog>
            }
        </>
    );
}

RoleFormDialog.defaultProps = {
    open: false,
    id: 0,
    itemToEdit: getRolesDefaultItem(),
    onCancel: () => { console.log("onCancel not implemented"); },
    onSave: (data) => { console.log("onSave not implemented"); },
    onError: (error) => { console.error("onError not implemented", error); },
};

RoleFormDialog.propTypes = {
    open: PropTypes.bool,
    id: PropTypes.number,
    itemToEdit: PropTypes.object,
};