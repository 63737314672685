import { Formik, Form, Field } from 'formik';
import { TextField } from 'formik-mui';
import { Grid, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useRef, useEffect } from 'react';
import { MyNicknamePreferencesValidationSchema } from './MyNicknamePreferencesAPIEndpoints';
// @ts-ignore
import { useResponsive } from 'lib/useResponsive';
import { UserAccount } from './MyUserAccount.interfaces';

interface MyNicknamePreferencesFormProps {
    dialogSubmit: number,
    initialValues: UserAccount[],
    onSubmit: Function,
    username: string
}

export const MyNicknamePreferencesForm = (props: MyNicknamePreferencesFormProps) => {
    const { t } = useTranslation();
    const { isMobile } = useResponsive();
    const dialogSubmit = useRef(props.dialogSubmit);
    const formRef = useRef<any>();

    useEffect(() => {
        if (props.dialogSubmit !== dialogSubmit.current) {
            dialogSubmit.current = props.dialogSubmit;
            if (formRef.current) {
                formRef.current.handleSubmit();
            }
        }
    });

    const isNotDelegate = (account: UserAccount) => {
        return props.username === account.username;
    };

    return (
        <Formik
            innerRef={formRef}
            initialValues={props.initialValues}     
            validationSchema={MyNicknamePreferencesValidationSchema}
            onSubmit={async (values) => {
                props.onSubmit(values);
            }}
        >
            {({ values }) => (
                <Form>
                    <Grid container direction="row">
                        {values.map((account: UserAccount, accountIndex: number) => (
                            <>
                                {isNotDelegate(account) && (
                                    <Grid container spacing={1} key={account?.id}>
                                        <Grid item xs={12}>
                                            <Typography sx={{ textDecoration: 'underline' }}>
                                                {t("Set Nicknames for ") + account.accountAddress}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Grid container alignItems="center" spacing={0}>
                                                <Grid item>
                                                    <Typography>
                                                        {t("Account #  ") + account.extAccountNumber}
                                                    </Typography>
                                                </Grid>
                                                <Grid item>
                                                    <Field
                                                        name={`[${accountIndex}].accountNickname`}
                                                        component={TextField}
                                                        disabled={account.username !== props.username}
                                                        size="small"
                                                        value={account.accountNickname}
                                                        placeholder={t("Enter Nickname")}
                                                        FormHelperTextProps={{ style: { marginTop: '-12px' } }}
                                                        style={{
                                                            minWidth: isMobile && '250px',
                                                        }}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={8} sx={{ pb: isMobile && '20px' }}>
                                            {account?.meters?.map((meter: any, meterIndex: number) => (
                                                <Grid container alignItems="center" spacing={0} key={`${meter?.serialNo}-${meterIndex}`}>
                                                    <Grid item xs={6}>
                                                        <Typography variant="body2">
                                                            {meter.meterCommodity} {t("Meter: ") + meter.serialNo}
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <Field
                                                            style={{
                                                                minWidth: isMobile && '200px',
                                                                margin: 'auto',
                                                                paddingBottom: '20px',
                                                                position: 'relative',
                                                            }}
                                                            component={TextField}
                                                            name={`[${accountIndex}].meters[${meterIndex}].meterNickname`}
                                                            size="small"
                                                            placeholder={t("Enter Nickname")}
                                                            value={meter.meterNickname}
                                                            FormHelperTextProps={{ style: { marginTop: '-12px' } }}
                                                        />
                                                    </Grid>
                                                </Grid>
                                            ))}
                                        </Grid>
                                    </Grid>
                                )}                              
                            </>                      
                        ))}
                    </Grid>
                </Form>
            )}
        </Formik>
    );
};