import { useState, useLayoutEffect, useRef, useEffect } from 'react';
// @ts-ignore
import { useFilterItems } from '../../lib/useFilterItems';
import { UcLoading } from 'components/common/UcLoading';
// @ts-ignore
import UcDataItemCards from '../common/UcDataItemCards';
import { Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

export interface UcInfiniteScrollListProps {
    defaultDataItems: any;
    actionsHandler: any;
    apiEndPoint: string;
    filter: any;
    renderCondition?: string;
    avatarIfRenderConditionTrue?: any;
    avatarIfRenderConditionFalse?: any;
    avatarDefault?: any;
    clickable?: boolean;
    onRowSelectionModelChange?: any;
}

export const UcInfiniteScrollList = (props: UcInfiniteScrollListProps) => {

    const pageSize = 5;
    const { t } = useTranslation();
    const needsDataUpdate = useRef("");
    const isDataLoaded = useRef(false);
    const [loading, setLoading] = useState(false); // Loading state

    const [dataItems, setDataItems] = useState(props.defaultDataItems);
    const [page, setPage] = useState(1);
    const [hasMore, sethasMore] = useState(true);
    const observerRef = useRef(null); // Ref for observer
    const apiEndPoint = props.apiEndPoint;

    const [filter, setFilter] = useState(props.filter);

    const swrFilteredItems = useFilterItems(apiEndPoint, filter);

    useLayoutEffect(() => {

        const dataReceived = (swrResult: any) => {
            let newItems = swrResult.result;
            setDataItems({...props.defaultDataItems, rows: [...dataItems.rows, ...newItems], rowCount: swrResult.rowCount});
            if (newItems.length === 0 || newItems.length < pageSize) {
                sethasMore(false);
            }
        };

        setLoading(true);
        if (swrFilteredItems.result) {
            isDataLoaded.current = true;
            let filterString = JSON.stringify(filter);
            if (needsDataUpdate.current !== filterString) {
                needsDataUpdate.current = filterString;
                dataReceived(swrFilteredItems);
            }
            setLoading(false);
        }
    }, [swrFilteredItems, filter, dataItems.rows, props.defaultDataItems]);

    useEffect(() => {

        const fetchData = () => {
            let newPage = page + 1;
            setPage(newPage);

            let newItemAmount = newPage * pageSize - pageSize;
            let newFilter = {...filter, skip: newItemAmount, take: pageSize}
            setFilter(newFilter);
        };

        if (loading || !hasMore) return; 

        const observer = new IntersectionObserver(
        (entries) => {
            if (entries[0].isIntersecting) { //check if the viewport intersects the obeserverRef div
            fetchData();
            }
        },
        { threshold: 0.5 } 
        );

        if (observerRef.current) {
            observer.observe(observerRef.current); 
        }

        return () => observer.disconnect(); 
    }, [page, loading, hasMore, filter]);

    return (
       <>
            <UcDataItemCards
                loading={!isDataLoaded.current}
                dataItems={dataItems}
                actionsHandler={props.actionsHandler}
                renderCondition={props.renderCondition}
                avatarIfRenderConditionTrue={props.avatarIfRenderConditionTrue}
                avatarIfRenderConditionFalse={props.avatarIfRenderConditionFalse}
                avatarDefault={props.avatarDefault}
                clickable={props.clickable}
                onRowSelectionModelChange={props.onRowSelectionModelChange}
            />
            {loading && 
                <Box sx={{pt: 5}}>
                    <UcLoading isLoading={hasMore} hasFade={false} height={'10px'} />
                </Box>
            }
            {hasMore && (
                <Box sx={{pt: 20}}>
                    <div ref={observerRef} style={{ height: '20px'}}/>
                </Box>
            )}
            {!hasMore && (
                <Box sx={{pt: 2, pb: 15, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <Typography variant="subtitle2">{t("{{dataCount}} results shown", {dataCount: dataItems.rows.length})}</Typography>
                </Box>
            )}
        </>
    );
}


