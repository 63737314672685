import React from 'react';
import { Formik, Form, Field } from 'formik';
import { TextField } from 'formik-mui';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import { useTranslation } from "react-i18next";
import { useDomainNavigate } from "domain";
import { LookupValueValidationSchema, LookupAccountsWrapper } from './AccountAuthorizationApiEndpoints';
import { AccountLookupList } from './AccountLookupList';
import { Alert, Button, Paper, Link } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { routePaths } from 'routes';

export const AccountLookupPage = (props) => {
    const { isRenderedInDialog, onCancel, onProceed } = props;
    const { t } = useTranslation();
    const navigate = useDomainNavigate();
    const formRef = React.useRef();
    const [accounts, setAccounts] = React.useState(null);
    const [lookupAccounts, setLookupAccounts] = React.useState(false);
    const [showAlert, setShowAlert] = React.useState(false);
    const initialValues = { lookupValue: '' };
    const theme = useTheme();

    const params = new URLSearchParams(window.location.search);
    const lookupBy = params.get("lookupBy") || props.lookupBy;

    const handleOnClickGoToVerify = () => {
        if (isRenderedInDialog) {
            onProceed();
        }
        else {
            navigate(routePaths.accountVerificationPage.path);
        }
    }

    const handleOnClickBack = () => {
        if (isRenderedInDialog) {
            onCancel();
        }
        else {
            navigate(routePaths.accountAuthorizationPage.path);
        }
    }

    const handleAlertClose = () => {
        setShowAlert(false);
    };

    let paperTheme = theme.palette.mode === 'dark' && "background.default";

    return (
        <>
            <Paper
                className='ignoreCustomFormStyles'
                sx={{ width: '100%', p: 2, backgroundColor: paperTheme }} elevation={isRenderedInDialog ? 0 : 3}
            >
                {showAlert &&
                    <Alert onClose={handleAlertClose} severity="error" sx={{ width: '100%' }}>
                        {t('The address you inputted does not have an account attached. Please make sure the address inputted is exactly what it appears on your bill.')}
                    </Alert>
                }

                {accounts !== null && lookupBy?.toLowerCase() === 'address' && (
                    <AccountLookupList accounts={accounts} />
                )}
                { accounts !== null && lookupBy?.toLowerCase() !== 'address' && (
                    <>
                        <Alert severity="info">
                            {t('If your account(s) were found, you will receive an email or text message momentarily. If you do not receive it and are sure that the information provide was correct, please contact support.')}
                        </Alert>
                        <Typography component={'p'} variant='body1' sx={{mt: 2}}>
                            {t('After receiving your account number(s), please click the button below to proceed.')}
                        </Typography>
                        <Button sx={{mt: 2, mb: 2}}
                            type="button"
                            color='primary'
                            variant="contained"
                            onClick={handleOnClickGoToVerify}
                        >
                            {t('Proceed')}
                        </Button>
                    </>
                )}
                { !accounts?.result && (
                    <div className='ignoreCustomFormStyles'>
                        <Formik
                            innerRef={formRef}
                            validationSchema={LookupValueValidationSchema}
                            initialValues={initialValues}
                            onSubmit={(values) => {
                                setLookupAccounts(true);
                            }}
                        >
                            <Form>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <b>Find My Account</b>
                                        <Typography component={'p'} variant='body1'>
                                            {lookupBy?.toLowerCase() === 'address' && (
                                            <>
                                                 <p>{t('We can lookup your account number(s) using your address. This address MUST match exactly to the address on your bill.')}</p>
                                                 <p>{t('Example) 123 West St ≠ 123 West Street')}</p>
                                            </>
                                            )}
                                            {lookupBy?.toLowerCase() === 'phone' && (
                                                <>
                                                    <p>{t('We can lookup your account number(s) using your phone number. Please enter your phone number below.')}</p>
                                                </>
                                            )}
                                            {lookupBy?.toLowerCase() === 'email' && (
                                                <>
                                                    <p>{t('We can lookup your account number(s) using your email address. Please enter your email below.')}</p>
                                                </>
                                            )}

                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Field
                                            name={"lookupValue"}
                                            placeholder={(lookupBy?.toLowerCase() === 'email' ? '(e.g john@example.com)' : ( lookupBy?.toLowerCase() === 'phone' ? '(e.g. 555-555-5555)' : '(e.g 123 Street City Name)'))}
                                            component={TextField}
                                            label={t(lookupBy)}
                                            disabled={false}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Button
                                            type="submit"
                                            variant="contained"
                                        >
                                            {t('Lookup')}
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Form>
                        </Formik>
                        {lookupAccounts && (
                            <LookupAccountsWrapper
                                setState={setAccounts}
                                setFetchingState={setLookupAccounts}
                                lookupBy={lookupBy}
                                lookupValue={formRef.current?.values?.lookupValue}
                                setShowAlert={setShowAlert}
                            />
                        )}
                    </div>
                )}
            </Paper>
            <div style={{textAlign: 'left', width: '100%', padding: '16px'}}>
                <Link
                    component="button"
                    onClick={handleOnClickBack} variant="body2" color='error' style={{display: 'block'}}>
                      {t('Cancel Account Lookup')}
                </Link>
            </div>
        </>
    );
}