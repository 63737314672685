import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import Typography from '@mui/material/Typography';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="left" ref={ref} {...props} />;
  });

export const UcMobileNavPageContent = (props) => {
    const {open, onClose, pageTitle, pageContent} = props;
    
    const handleClose = () => {
        onClose();
    };

    return (
        <>
            <Dialog
                TransitionComponent={Transition}
                fullScreen={true}
                open={open}
                onClose={handleClose}
            >
                <div 
                    style={{
                        zIndex: 9999, 
                        boxShadow: '0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)'
                    }}
                >
                    <DialogTitle sx={{pl: 0}}>
                        <Button autoFocus onClick={handleClose}><ChevronLeftIcon /></Button> 
                        <Typography
                            sx={{ display: 'inline' }}
                            component="span"
                            variant="subtitle1"
                        >
                            {pageTitle}
                        </Typography>
                    </DialogTitle>
                </div>
                <DialogContent 
                    sx={{
                        backgroundColor: props.contentBackgroundColor || 'background.paper',
                        padding: "5px 15px 0 15px"
                    }}
                >
                    {pageContent}
                </DialogContent>
            </Dialog>
        </>
    );
}
