import React, { useState } from 'react';
import LoadingButton from '@mui/lab/LoadingButton';
import Draggable from 'react-draggable';
import { QuickActionsWidgetForm } from './QuickActionsWidgetForm';
import { saveQuickAction, deleteQuickAction, QuickAction } from './QuickActionsAPIEndpoints';
// @ts-ignore
import { useResponsive } from 'lib/useResponsive';
import { useTranslation } from "react-i18next";
import { useTheme } from '@mui/material/styles';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Paper, Theme as MuiTheme } from '@mui/material';
function PaperComponent(props: any) {
    return (
        <Draggable
            handle="#draggable-dialog-title"
            cancel={'[class*="MuiDialogContent-root"]'}
        >
            <Paper {...props} />
        </Draggable>
    );
}

interface CustomInstanceOfMuiTheme extends MuiTheme {
    props: any;
}

interface QuickActionsWidgetFormDialogProps {
    id: number;
    itemToEdit: QuickAction;
    dialogText: string;
    open: boolean;
    delete: boolean;
    onCancel: Function;
    onSave: Function;
    onError: Function;
}

export const QuickActionsWidgetFormDialog = (props: QuickActionsWidgetFormDialogProps) => {
    const { t } = useTranslation();
    const { itemToEdit } = props;
    const [saving, setSaving] = React.useState(false);
    const [dialogSubmit, setDialogSubmit] = useState(0);
    const { isMobile } = useResponsive();
    const theme = useTheme<CustomInstanceOfMuiTheme>();

    if (props.id === null) {
        props.id = 0;
    }

    let formId = props.id;
    if (!props.open) {
        formId = 0;
    }

    const handleCancel = () => {
        props.onCancel();
    };

    const handleSave = () => {
        setDialogSubmit(dialogSubmit + 1);
    };

    const handleDelete = async () => {
        let data = await deleteQuickAction(itemToEdit);

        if (data) {
            props.onSave(data);
        }
    };

    return (
        <>
            {props.delete && props.open &&
                <Dialog
                    fullScreen={isMobile}
                    fullWidth={true}
                    maxWidth={"sm"}
                    open={props.open}
                    PaperComponent={PaperComponent}
                    aria-labelledby="draggable-dialog-title"
                >
                    <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
                        {t("Delete Tile")}
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            {t('Are you sure you want to delete this tile?')}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            autoFocus
                            onClick={handleCancel}
                            variant={theme.props?.CancelButton?.variant}
                            data-cy-id={"cancel_delete_button"}
                        >
                            {t("Cancel")}
                        </Button>
                        <Button
                            onClick={handleDelete}
                            variant={theme.props?.CancelButton?.variant}
                            color={theme.props?.CancelButton?.color}
                            data-cy-id={"delete_button"}
                        >
                            {t("Delete")}
                        </Button>
                    </DialogActions>
                </Dialog>
            }
            {!props.delete && props.open &&
                <Dialog
                    fullScreen={isMobile}
                    fullWidth={true}
                    maxWidth={"md"}
                    open={props.open}
                    PaperComponent={PaperComponent}
                    aria-labelledby="draggable-dialog-title"
                >
                    <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
                        {props.dialogText + t(" Tile")}
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            <QuickActionsWidgetForm
                                id={formId}
                                itemToEdit={itemToEdit}
                                dialogSubmit={dialogSubmit}
                                onSubmit={async (values) => {                                
                                    setSaving(true);
                                    try {
                                        let data = await saveQuickAction(values);

                                        if (data) {
                                            props.onSave(data);
                                        }
                                    } finally {
                                        setSaving(false);
                                    }
                                }}
                                onError={(error) => {
                                    props.onError(error);
                                    setSaving(false);
                                }}
                            />
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>                                                  
                        <Button
                            autoFocus
                            onClick={handleCancel}
                            variant={theme.props?.CancelButton?.variant || "outlined"}
                            color={theme.props?.CancelButton?.color}
                            data-cy-id={"quick_actions_admin_dialog_cancel_button"}
                        >
                            {t("Cancel")}
                        </Button>
                        <LoadingButton
                            loading={saving}
                            onClick={handleSave}
                            variant="contained"
                            data-cy-id={"save_button"}
                        >
                            {t("Save")}
                        </LoadingButton>
                    </DialogActions>
                </Dialog>
            }          
        </>
    );
}