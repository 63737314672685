import { UserListPage } from "./UserListPage";
import {useEffect, useContext} from 'react';
import {PageContext} from '../../../lib/PageProvider';
import {useTranslation} from 'react-i18next';

export const ResidentialUserListPage = () => {
    const [pageState, pageDispatch] = useContext(PageContext);
    const { t } = useTranslation();

    useEffect(() => {
        pageDispatch({
            header: t("Residential Users"),
        });
    }, [pageState.header, pageDispatch, t]);

    return (
        <UserListPage type={'UtilityResidential'} permissions={["User:Read"]}/>
    );
};