// @ts-ignore
import React, { useState, useLayoutEffect, useEffect } from "react";
import { AdminRateExpirationGridStateful } from "./AdminRateExpirationGridStateful";
// @ts-ignore
import { mutateUrls } from "../../../lib/useGenericSWR";
import { useSWRConfig } from "swr";
import "./AdminRateExpirationListPage.scss";
// @ts-ignore
import { EnsureUser } from "../../auth/EnsureUser";
// @ts-ignore
import { UcToolbar } from "../../common/UcToolbar";
// @ts-ignore
import { UcListPage } from "components/common/UcListPage";
import { ToggleButton, ToggleButtonGroup } from "@mui/material";
// @ts-ignore
import { useEnums } from "components/auth/PermissionApiEndpoint";
import { Enum } from "components/common/Common.interfaces";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

export const AdminRateExpirationListPage = () => {
    const { t } = useTranslation();
    const rateVersionStatusEnums = useEnums("RateVersionStatus");
    const [generalSearch, setGeneralSearch] = useState("");
    const location = useLocation();
    const { rateStatus } = location.state || {};
    const [status, setStatus] = React.useState(rateStatus || "Expiring");
    const [toggleButtonColor, setToggleButtonColor] = useState<"error" | "success" | "warning">("error");
    const [reMutateUrls, setReMutateUrls] = useState(false);
    const swrConfig = useSWRConfig();
    const actions: any[] = [];

    const handleStatusChange = (event: React.MouseEvent<HTMLElement>,
        newStatus: string | null
        ) => {
        if (newStatus !== null) {
            setStatus(newStatus);
            setReMutateUrls(true);
        }
    };

    const customComponents: React.ReactNode[] = [
        <ToggleButtonGroup
            key={"customComponent_1"}
            color={toggleButtonColor}
            value={status}
            exclusive
            size="small"
            onChange={handleStatusChange}
            aria-label="Status"
        >
            {rateVersionStatusEnums !== false && rateVersionStatusEnums?.map((rateVersionStatus: Enum) => (
                <ToggleButton
                    data-cy-id={'rateExpirationPageToggle_'+rateVersionStatus.display}
                    key={`customComponent_1_${rateVersionStatus.id}`}
                    value={rateVersionStatus.id}
                >
                    {rateVersionStatus.display}
                </ToggleButton>
            ))}
        </ToggleButtonGroup>,
    ];

    useEffect(() => {
        switch (status) {
            case "Valid":
            setToggleButtonColor("success");
            break;
            case "Expiring":
            setToggleButtonColor("warning");
            break;
            case "Expired":
            setToggleButtonColor("error");
            break;
        }
    }, [status]);

    useLayoutEffect(() => {
        if (reMutateUrls) {
            mutateUrls(swrConfig);
            setReMutateUrls(false);
        }
    }, [reMutateUrls, swrConfig]);

    console.log("AdminRateExpiration - Loaded");

    return (
        <UcListPage>
            <EnsureUser permissions={["RateClass:Read"]}>
            <div className="RateExpiration" data-cy-id="admin_rate_expiration">
                <h1 className="PageHeader">{t('Rate Expiration')}</h1>
                <UcToolbar
                    hasGeneralSearch={true}
                    onGeneralSearchChange={(value: any) => {
                        setGeneralSearch(value);
                    }}
                    actions={actions}
                    customComponents={customComponents}
                />
                    {!reMutateUrls && (
                        <AdminRateExpirationGridStateful
                            generalSearch={generalSearch}
                            status={status}
                            id={null}
                        />
                    )}
            </div>
            </EnsureUser>
        </UcListPage>
    );
};
