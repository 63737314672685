import * as React from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
import { useRates } from './RateAPIEndpoints';
import { debounce } from "lodash";

// From: https://mui.com/components/autocomplete/#Asynchronous.js
export const RateAdminSelect = (props) => {
    const [open, setOpen] = React.useState(false);
    const [options, setOptions] = React.useState([]);
    const [value, setValue] = React.useState(props.value || null);
    const needsUpdate = React.useRef(true);
    const firstValueSet = React.useRef(false);

    const [filter, setFilter] = React.useState({
        take: 100,
        skip: 0,
        sort: [{ field: "startDate", sort: "desc" }], // Make sure the most recent rates are first
        generalSearch: "",
        rateClassId: parseInt(props.rateClassId || null),
        rateType: props.rateType,
    });

    let loading = true;
    let loadedOptions = [];

    const swrRates = useRates(filter);
    if (swrRates.result && !swrRates.isLoading && !swrRates.isError)
    {
        loadedOptions = swrRates.result;
        loading = false;
        if (needsUpdate.current) {
            needsUpdate.current = false;

            setOptions([...loadedOptions]);
        }
    }
    else
    {
        needsUpdate.current = true;
        loading = true;
    }

    const propOnChange = props.onChange;

    React.useEffect(() => {
        // Set the value to the first option if it is not set
        if (!value && options && options.length >= 1)
        {
            // Only do this once
            if (!firstValueSet.current)
            {
                firstValueSet.current = true;
                // Adding this auto select causes a strange error when switching tabs
                propOnChange(null, options[0]);
                setValue(options[0]);
            }
        }
        else if (value) {
            firstValueSet.current = true;
        }
    }, [options, value, propOnChange]);

    // Simple debounce so we only query every 200 ms pause while typing
    // TODO use a different debounce. (mantine hooks?)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const changeHandler = React.useCallback(debounce((newGeneralSearch) => {
        if (newGeneralSearch === "") {
            newGeneralSearch = null;
        }
        needsUpdate.current = true;
        setFilter({...filter, generalSearch: newGeneralSearch});
    }, 200), [filter]);

    return (
        <Autocomplete
            id={props.id || "rateAdminSelect"}
            open={open}
            value={value}
            onChange={(event, newValue) => {
                setValue(newValue);
                props.onChange(event, newValue);
            }}
            onOpen={() => {
                setOpen(true);
            }}
            onClose={() => {
                if (filter.generalSearch !== "")
                {
                    needsUpdate.current = true;
                    setFilter({...filter, generalSearch: ""});
                }
                setOpen(false);
            }}
            isOptionEqualToValue={(option, equalValue) => option.id === equalValue.id}
            getOptionLabel={(option) => option.name}
            options={options}
            loading={loading}
            renderInput={(params) => (
                <TextField
                {...params}
                onChange={(e) => {
                    changeHandler(e.target.value);
                }}
                label={props.label || "Rates"}
                InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                    <React.Fragment>
                        {loading ? <CircularProgress color="inherit" size={20} /> : null}
                        {params.InputProps.endAdornment}
                    </React.Fragment>
                    ),
                }}
                />
        )}
        />
    );
};

RateAdminSelect.defaultProps = {
    onChange: (event, value) => { console.log("rateAdminSelect onChange not implemented", event, value); },
};