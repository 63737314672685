import * as React from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import { useHasPermission } from 'components/auth/PermissionApiEndpoint';

export const UcIconButtonsWithLabel = (props) => {
    const { actions } = props;

    return (
        <>
            {actions.map((action, index) => (
                <UcIconButtonWithLabel key={index} action={action} />
            ))}
        </>
    );
};

const UcIconButtonWithLabel = (props) => {
    const {action} = props;
    let hasPermission = useHasPermission(action.requiredPermission);

    if (action.requiredPermission === undefined) {
        hasPermission = true;
    }

    return (
        <>
            {(!action.isHidden && hasPermission) && (
                <Button
                    title={action.title}
                    color={action.color || 'primary'}
                    onClick={action.onClick}
                    disabled={action.isDisabled}
                    href={action.href}
                    target={action.target}
                    data-cy-id={action.dataCyId}
                    startIcon={action.icon}
                    sx={action.labelSx}
                >
                    {action.label}
                </Button>
            )}
        </>
    );
}

UcIconButtonsWithLabel.propTypes = {
    actions: PropTypes.array,
};