import { useGenericSWR } from "./useGenericSWR";
//@ts-ignore
import { ApiClient } from "./ApiClient";
const assetManifestUrl: string = '/asset-manifest.json?cachebust=' + new Date().getTime();

interface ResourceHashes {
    jsHash: string;
    cssHash: string;
}

const apiClient = new ApiClient();

export function useFrontEndAppInfo () {
    const swrOptions = {
        additionalMessage: "",
        overrideMainMessage: "",
        doToast: false,
        errorRetryInterval: 60,
        refreshInterval: (15*60*1000)
    };
    const manifestSwr = useGenericSWR(assetManifestUrl, apiClient, swrOptions);

    const data = {
        jsHash: "",
        cssHash: "",
        jsHashTarget: "",
        cssHashTarget: "",
    }

    if (!manifestSwr.isLoading) {
        data.jsHashTarget = (manifestSwr.result?.files['main.js'] || '').replace('main.', '').replace('.js', '').replace('/static/js/', '');
        data.cssHashTarget = (manifestSwr.result?.files['main.css'] || '').replace('main.', '').replace('.css', '').replace('/static/css/', '');
    }

    const resourceHashes = getLoadedResourceHashes();

    data.jsHash = resourceHashes.jsHash;
    data.cssHash = resourceHashes.cssHash;

    return {
      data: data,
      isLoading: manifestSwr.isLoading,
      isError: manifestSwr.error,
      mutate: manifestSwr.mutate
    }
}

function getLoadedResourceHashes(): ResourceHashes {
    let jsHash = '';
    let cssHash = '';

    // Get all script tags
    const scripts = document.querySelectorAll<HTMLScriptElement>('script');
    scripts.forEach((script) => {
      if (script.src.includes('main')) {
        const match = script.src.match(/main\.([a-z0-9]+)\.js$/);
        if (match) {
          jsHash = match[1]; // Extract the hash part
        }
      }
    });

    // grab for localhost debugging
    if (jsHash === ''){
        scripts.forEach((script) => {
            if (script.src.includes('bundle')) {
                const match = script.src.match(/bundle.js$/);
                if (match) {
                    jsHash = script.src; //there will be no hash part
                }
            }
          });
    }

    // Get all link tags for CSS
    const links = document.querySelectorAll<HTMLLinkElement>('link[rel="stylesheet"]');
    links.forEach((link) => {
      if (link.href.includes('main')) {
        const match = link.href.match(/main\.([a-z0-9]+)\.css$/);
        if (match) {
          cssHash = match[1]; // Extract the hash part
        }
      }
    });

    return { jsHash, cssHash };
}