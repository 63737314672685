import { UcWidget } from 'components/common/UcWidget';
//@ts-ignore
import { UcTabStrip } from '../common/UcTabStrip';

export interface WidgetTabsWrapperProps {
  views: any;
  initialValue: string;
}

export const WidgetTabsWrapper = (props: WidgetTabsWrapperProps) => {

  return (
    <UcWidget
      minHeight={"auto"}
      contentPadding={"0"}

    >
      <UcTabStrip
          showInMenu={true}
          showTabsInMobile={true}
          tabs={props.views}
          value={props.initialValue}
      />
    </UcWidget>
  );
}