import { updateUserPreference } from "./UserPreferenceApiEndpoint";
const { usePermissions } = require("components/auth/PermissionApiEndpoint");

let forceStorage = false;

const useLanguage = () => {
    const permissionSwr = usePermissions();

    // using local storage here so we don't have to do an API request every time
    // Also, if the user logs out this setting will persist
    // If we have any user settings that are PII or other sensitive information we should not store them in local storage
     let storageResult = localStorage.getItem("language");

    if (forceStorage) {
        return storageResult;
    }

    const language = permissionSwr.result?.userPreferences?.find(x => x.name === "language")?.value;

    if (language && language !== storageResult) {
        storageResult = permissionSwr.result?.userPreferences?.language;

        localStorage.setItem("language", storageResult);
    }

    return language || storageResult || navigator.language;
};

const setLanguage = (lng) => {
    localStorage.setItem("language", lng);
    updateUserPreference("language", lng);
    forceStorage = true; // because the permissionSwr will be dirty
}

export {
    useLanguage,
    setLanguage,
}