import { useTranslation } from "react-i18next";
import { useMemo } from 'react';
import { WidgetTabsWrapper } from './WidgetTabsWrapper';
import AccountSummaryWidget from 'components/widgets/AccountSummaryWidget';
import { AccountStatisticsListViewWidget } from 'components/widgets/AccountStatisticsListViewWidget';
// @ts-ignore
import { useResponsive } from 'lib/useResponsive';

export default function AccountSummaryWidgetWrapper(props: { account: any; }) {
    const { t } = useTranslation();
    const { isMobile } = useResponsive();

    const views = useMemo(() => {
        return [
            {
                title: t("Account Summary"),
                tabRender: <div>{t("Account Summary")}</div>,
                contentRender: <AccountSummaryWidget />,
                contentPadding: "2rem 0 0 0"
            },
            {
                title: t("Account Statistics"),
                tabRender: <div>{t(!isMobile ? "Account Statistics" : "Account Stats")}</div>,
                contentRender: <AccountStatisticsListViewWidget account={props.account} />,
                contentPadding: "0 0 0 0"
            }
        ];
    }, [t, props.account, isMobile]);

    return (
        <WidgetTabsWrapper
            views={views}
            initialValue={"Account Summary"}
        />
    )
}
