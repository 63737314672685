import { useHasPermission } from "components/auth/PermissionApiEndpoint";

export const useIsUtilitySupportOrAdmin = () => {

    const userRead = useHasPermission("User:Read");
    const orgRead = useHasPermission("Org:Read");

    // We assume that if a user does not have this permission,
    // they are in Utility Support/Admin Roles
    return (userRead && !orgRead);

};

export const useIsSuperAdmin = () => {

    const userRead = useHasPermission("User:Read");
    const orgRead = useHasPermission("Org:Read");

    // If a user has both permissions, we assume they are Super Admin
    return (userRead && orgRead);

};
