import { getRolePermissionsDefaultItem, rolePermissionsValidationSchema } from './RolePermissionAPIEndpoints';
import PropTypes from 'prop-types';
import { Formik, Form, Field } from 'formik';
import { useRef, useEffect, useState } from 'react';
import { RolePermissionSwitches } from 'components/admin/Roles/RolePermissionSwitches';


export const RolePermissionForm = (props) => {
    const itemToEdit = props.itemToEdit || getRolePermissionsDefaultItem();
    const dialogSubmit = useRef(props.dialogSubmit);
    const [selectedRolePermissions, setSelectedRolePermissions] = useState(itemToEdit.permissions || []);
    const formRef = useRef();

    useEffect(() => {
        if (props.dialogSubmit !== dialogSubmit.current) {
            dialogSubmit.current = props.dialogSubmit;
            if (formRef.current) {
                formRef.current.values.roleId = props.roleId;
                formRef.current.values.permissions = selectedRolePermissions;
                formRef.current.handleSubmit();
            }
        }
    });

    const syncSelectedPermissions = (permissions) => {
        setSelectedRolePermissions(permissions);
    };

    return (
        <Formik
            innerRef={formRef}
            initialValues={itemToEdit}
            validationSchema={rolePermissionsValidationSchema}
            onSubmit={(values) => {
                values.id = itemToEdit.id;
                values.roleId = props.roleId;
                props.onSubmit(values);
            }}
        >
            <Form readonly={props.readonly}>
                <Field
                    name={"permissions"}
                    label={""}
                    readonly={false}
                    roleId={props.roleId}
                    component={RolePermissionSwitches}
                    syncSelectedPermissions={syncSelectedPermissions}
                />
            </Form>
        </Formik>

    );
}

RolePermissionForm.defaultProps = {
    id: 0,
    dialogSubmit: 0,
    changeIteration: 0,
    onSubmit: (values) => { console.log("onSubmit not implemented", values); },
    onError: (error) => { console.error("onError not implemented", error); },
};

RolePermissionForm.propTypes = {
    id: PropTypes.number,
    changeIteration: PropTypes.number,
    dialogSubmit: PropTypes.number,
};