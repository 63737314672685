import { ApiClient } from "../../../lib/ApiClient";
import { useGenericSWR } from "../../../lib/useGenericSWR";
import * as Yup from 'yup';

const apiEndpoint = 'api/v1/admin/uc/RolePermission';
const apiEndpointAvail = 'api/v1/AvailPermission';

let formIteration = 0;

const getRolePermissionsDefaultItem = () => {
    formIteration = formIteration + 1;

    return {
        formIteration: "new" + formIteration,
        roleId: 0,
        permissions: []
    }
};

const rolePermissionsValidationSchema = Yup.object().shape({
    roleId: Yup.number().required("Required").integer(),
});

const apiClient = new ApiClient();
apiClient.getDefaultItem = () => getRolePermissionsDefaultItem();
apiClient.getObjectName = () => {
    return "Role Permission";
};

const useRolePermission = (id, swrOptions = {}) => {
    // SWR will prevent duplicate requests
    // It will also maintain data state as long as your are using the same urls for your requests
    return useGenericSWR(apiEndpoint + "/" + id, apiClient, { ...swrOptions });
};

const useRolePermissions = (filter, swrOptions = {}) => {
    // SWR will prevent duplicate requests
    // It will also maintain data state as long as your are using the same urls for your requests
    const params = new URLSearchParams();
    params.append('filter', JSON.stringify(filter));

    return useGenericSWR(apiEndpoint + "?" + params.toString(), apiClient, { ...swrOptions });
};

const useAvailPermissions = (filter, swrOptions = {}) => {
    const params = new URLSearchParams();
    params.append('filter', JSON.stringify(filter));

    return useGenericSWR(apiEndpointAvail + "?" + params.toString(), apiClient, { ...swrOptions });
};

const saveRolePermission = (RolePermission) => {
    if (RolePermission.id === 0) {
        return insertRolePermission(RolePermission);
    }
    else {
        return updateRolePermission(RolePermission);
    }
}

const saveRolePermissionBulk = (RolePermission) => {
    return apiClient.poster(apiEndpoint + '/bulkInsert?roleId=' + RolePermission.roleId + '&permissionsJson=' + JSON.stringify(RolePermission.permissions));
}

const insertRolePermission = (RolePermission) => {
    return apiClient.poster(apiEndpoint, RolePermission);
};

const updateRolePermission = (RolePermission) => {
    return apiClient.putter(apiEndpoint, RolePermission);
};

const deleteRolePermission = (RolePermission) => {
    return apiClient.deleter(apiEndpoint + "/" + RolePermission.id);
};

export {
    useRolePermission,
    useRolePermissions,
    useAvailPermissions,
    saveRolePermission,
    saveRolePermissionBulk,
    insertRolePermission,
    updateRolePermission,
    deleteRolePermission,
    rolePermissionsValidationSchema,
    getRolePermissionsDefaultItem,
};