import { useState, useLayoutEffect, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
// @ts-ignore
import { isEqual } from "lib/Compare";
import { BlacklistedIpAddressGrid } from "./BlacklistedIpAddressGrid";
import { BlacklistedIpAddressGridStatefulProps } from "./BlacklistedIpAddress.interfaces";

export const BlacklistedIpAddressGridStateful = (props: BlacklistedIpAddressGridStatefulProps) => {
    const [searchParams, setSearchParams] = useSearchParams();
    const queryParamName = (props.id || "BlacklistedIpAddressGrid") + "_state";
    const [isRenderReady, setIsRenderReady] = useState(true);
    const getQueryCurrent = () => {
        let defaultValue = {
            take: 10,
            skip: 0,
            pageSize: 10,
            generalSearch: props.generalSearch || null,
            sort: [{field: "id", sort: "desc"}],
        };

        let valueStr = searchParams.get(queryParamName);
        let result = false;

        try {
            if (valueStr !== null) {
                result = JSON.parse(valueStr);
            }
        }
        catch{
            console.error('Invalid Query Param ' + queryParamName, valueStr);
        }
        if (!result) {
            return defaultValue;
        }
        return result;
    };
    let queryCurrent = getQueryCurrent();
    const [current, setCurrent] = useState<any>(queryCurrent);

    const handleFilterChange = (e: any) => {
        const newCurrent = {...current, ...e};
        setCurrent(newCurrent);
        searchParams.set(queryParamName, JSON.stringify(newCurrent));
        setSearchParams(searchParams);
    }

    useEffect(() => {
        if (!isEqual(current.generalSearch, props.generalSearch)) {
            const newCurrent = { ...current, generalSearch: props.generalSearch, skip: 0 }
            setCurrent(newCurrent);
            searchParams.set(queryParamName, JSON.stringify(newCurrent));
            setSearchParams(searchParams);
            setIsRenderReady(false);
        }
    }, [current, props.generalSearch, queryParamName, searchParams, setSearchParams]);

    useLayoutEffect(() => {
        if (JSON.stringify(queryCurrent) !== JSON.stringify(current) && isRenderReady)
        {
            setIsRenderReady(false);
        }

        if (!isRenderReady)
        {
            setIsRenderReady(true);
            setCurrent(queryCurrent);
        }
    }, [queryCurrent, current, isRenderReady, setIsRenderReady]);

    return (
        <>
            {isRenderReady &&
                <BlacklistedIpAddressGrid
                    {...props}
                    sort={current.sort}
                    dataState={current}
                    generalSearch={current.generalSearch}
                    onFilterChange={handleFilterChange}
                />
            }
        </>
    );
}
