import { MyUserDelegationGrid } from "./MyUserDelegationGrid"
import { useState, useLayoutEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { useResponsive } from 'lib/useResponsive';
import AddIcon from '@mui/icons-material/Add';
import { MyUserDelegationCardList } from "./MyUserDelegationCardList";
import {Box, Fab} from '@mui/material';

export const MyUserDelegationGridStateful = (props) => {
    const [searchParams, setSearchParams] = useSearchParams();
    const queryParamName = (props.id || "MyUserDelegationGrid") + "_state";
    const [isRenderReady, setIsRenderReady] = useState(true);
    const { isMobile } = useResponsive();
    const getQueryCurrent = () => {
        let defaultValue = {
            take: 10,
            skip: 0,
            pageSize: 10,
            generalSearch: null,
            delegationId: props.delegationId || null,
            userId: props.userId || null,
            sort: [{field: "Id", sort: "asc"}],
        };

        let valueStr = searchParams.get(queryParamName);
        let result = false;

        try {
            result = JSON.parse(valueStr);
        }
        catch{
            console.error('Invalid Query Param ' + queryParamName, valueStr);
        }
        if (!result) {
            return defaultValue;
        }
        return result;
    };
    let queryCurrent = getQueryCurrent();
    const [current, setCurrent] = useState(queryCurrent);

    const handleFilterChange = (e) => {
        const newCurrent = {...current, ...e};
        setCurrent(newCurrent);
        searchParams.set(queryParamName, JSON.stringify(newCurrent));
        setSearchParams(searchParams);
    }

    useLayoutEffect(() => {
        if (JSON.stringify(queryCurrent) !== JSON.stringify(current) && isRenderReady)
        {
            setIsRenderReady(false);
        }

        if (!isRenderReady)
        {
            setIsRenderReady(true);
            setCurrent(queryCurrent);
        }
    }, [queryCurrent, current, isRenderReady, setIsRenderReady]);

    return (
        <>
            {isRenderReady &&
            (!isMobile ? (
                <MyUserDelegationGrid {...props}
                    sort={current.sort}
                    dataState={current}
                    generalSearch={current.generalSearch}
                    onFilterChange={handleFilterChange}
                    defaultDataItems={props.defaultDataItems}
                    />
                ) : (
                    <>
                    <MyUserDelegationCardList {...props}
                    />
                    <Box sx={{ position: "fixed", bottom: 16, right: 16 }}>
                        <Fab color="primary" aria-label="add" onClick={props.createItem}>
                            <AddIcon/>
                        </Fab>
                    </Box>
                </>
                ))
            }
        </>
    );
}
