import { ApiClient } from "../../../lib/ApiClient";
import { useGenericSWR } from "../../../lib/useGenericSWR";
import { useRef, useEffect } from 'react';

const apiEndpoint = 'api/v1/admin/Account';

let formIteration = 0;

const getAccountsDefaultItem = () => {
    formIteration = formIteration + 1;
    return {
        formIteration: "new" + formIteration,
        id: 0,
        cepOrgId: null,
        customerId: null,
        isValidated: 0,
        extSource: '',
        extAccountId: '',
        extAccountNumber: '',
        name: '',
        address: '',
        phone1: '',
        phoneType1: '',
        phone2: '',
        phoneType2: '',
        phone3: '',
        phoneType3: '',
        createdBy: 'REACT_FE',
        updatedBy: 'REACT_FE',
    }
};

// See definition from Account.cs
const apiClient = new ApiClient();
apiClient.getDefaultItem = () => getAccountsDefaultItem();
apiClient.getObjectName = () => {
    return "Account";
};

const useAccount = (id, swrOptions = {}) => {
    // SWR will prevent duplicate requests
    // It will also maintain data state as long as your are using the same urls for your requests
    return useGenericSWR(apiEndpoint + "/" + id, apiClient, { ...swrOptions });
};

const useAccounts = (filter, swrOptions = {}) => {
    const controllerRef = useRef(new AbortController());
    const { signal } = controllerRef.current;

    // SWR will prevent duplicate requests
    // It will also maintain data state as long as your are using the same urls for your requests
    const params = new URLSearchParams();
    params.append('filter', JSON.stringify(filter));

    useEffect(() => {
        return () => {
            controllerRef.current.abort();
            controllerRef.current = new AbortController();
        };
    }, [filter.generalSearch]);

    return useGenericSWR(apiEndpoint + "?" + params.toString(), apiClient, {
        ...swrOptions,
        signal: signal
    });
};

const saveAccount = (Account) => {
    if (Account.id === 0) {
        return insertAccount(Account);
    }
    return updateAccount(Account);
}
const insertAccount = (Account) => {
    return apiClient.poster(apiEndpoint, Account);
};

const updateAccount = (Account) => {
    return apiClient.putter(apiEndpoint, Account);
};

const deleteAccount = (Account) => {
    return apiClient.deleter(apiEndpoint + "/" + Account.id);
};

export {
    useAccount,
    useAccounts,
    saveAccount,
    insertAccount,
    updateAccount,
    deleteAccount,
    getAccountsDefaultItem,
};