import React, { useState, useContext, useEffect, useLayoutEffect } from 'react';
import { getRoleUsersDefaultItem } from './RoleUserAPIEndpoints';
import { PageContext } from 'lib/PageProvider';
import { RoleUserGridStateful } from "./RoleUserGridStateful"
import { RoleUserFormDialog } from './RoleUserFormDialog';
import { mutateUrls } from "lib/useGenericSWR";
import { useSWRConfig } from "swr";
import AddIcon from '@mui/icons-material/Add';
import { UcToolbar } from 'components/common/UcToolbar';
import { EnsureUser } from 'components/auth/EnsureUser';

export const RoleUserList = (props) => {
    const roleId = props.roleId;
    const userId = props.userId;
    const [editDialog, setEditDialog] = useState({ open: false, delete: false });
    const [selectedItem, setSelectedItem] = useState(null);
    const [pageState, pageDispatch] = useContext(PageContext);

    const [reMutateUrls, setReMutateUrls] = useState(false);
    const swrConfig = useSWRConfig();

    const closeHandler = () => {
        setEditDialog({ open: false, itemToEdit: editDialog.dataItem });
    }

    const saveHandler = () => {
        closeHandler();
        // Also, remutate
        setReMutateUrls(true);
    }

    const openDialog = (dataItem) => {
        if (!dataItem) {
            return;
        }
        if (!dataItem.formIteration) {
            dataItem.formIteration = "edit" + dataItem.id;
        }
        setEditDialog({ open: true, itemToEdit: dataItem, delete: false });
    }

    const deleteDialog = (dataItem) => {
        if (!dataItem) {
            return;
        }
        if (!dataItem.formIteration) {
            dataItem.formIteration = "delete" + dataItem.id;
        }
        setEditDialog({ open: true, itemToEdit: dataItem, delete: true });
    }

    const createItem = () => {
        const roleUser = getRoleUsersDefaultItem();
        roleUser.roleId = roleId;
        roleUser.userId = userId;
        openDialog(roleUser);
    }
    const deleteItem = (item) => {
        deleteDialog(item);
    }

    const actions = [
        {
            title: roleId ? 'Add User' : 'Add Role',
            toolTip: roleId ? 'Add User to Role' : 'Add Role to User',
            icon: <AddIcon />,
            onClick: () => { createItem(); },
            isHidden: true, // !useHasPermission("RoleUser:Modify")
        },
    ];


    useLayoutEffect(() => {
        if (reMutateUrls)
        {
            mutateUrls(swrConfig);
            setReMutateUrls(false);
        }
    }, [reMutateUrls, swrConfig]);

    useEffect(() => {
        pageDispatch({
            header: "UsageRates",
            });
    }, [pageState.header, selectedItem, pageDispatch]);

    console.log("RoleUserList - Loaded");
    return (
        <EnsureUser permissions={["RoleUser:Read"]}>
            <div className="RoleUserList">
                <UcToolbar actions={actions}></UcToolbar>
                <RoleUserFormDialog
                    roleId={roleId}
                    userId={userId}
                    open={editDialog.open}
                    delete={editDialog.delete}
                    itemToEdit={editDialog.itemToEdit}
                    onCancel={closeHandler}
                    onSave={saveHandler}
                />
                {!reMutateUrls &&
                    <RoleUserGridStateful isFullPage={props.isFullPage || false} onDeleteItem={deleteItem} roleId={roleId} userId={userId} onSelected={(selectedItem) => setSelectedItem({ ...selectedItem })} />
                }

            </div>
        </EnsureUser>
    );
};