import * as React from 'react';
import { Paper, Box, Typography, Grid, Popover} from '@mui/material';
import { useResponsive } from '../../../lib/useResponsive';
import { useTheme } from '@mui/material/styles';

export const UcWidget = (props) => {
    const theme = useTheme();
    const { isMobile } = useResponsive();
    const { children, actionItems, title, popoverTitle, gradientStrength, headerPadding, contentPadding, subTitle } = props;
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    let gradientString, isUsingGradient = true;

    const handlePopoverOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
    };

    switch (gradientStrength){
        case "gradient10":
            gradientString = theme.props?.GradientStrength?.gradient10;
            break;
        case "gradient25":
            gradientString = theme.props?.GradientStrength?.gradient25;
            break;
        case "gradient30":
            gradientString = theme.props?.GradientStrength?.gradient30;
            break;
        case "gradient70":
            gradientString = theme.props?.GradientStrength?.gradient70;
            break;
        default:
            gradientString = theme.props?.GradientStrength?.gradientNone;
            isUsingGradient = false;
    };

    return (
        <Paper elevation={1}
            sx={{
                width: '100%',
                height: '100%',
                background: isUsingGradient ? gradientString : 'background.paper',
                minHeight: props.minHeight || '358px',
                display: props.alignContent ? 'flex' : 'block',
                flexDirection: props.alignContent ? 'column' : 'unset',
                borderRadius: 3,
                justifyContent: props.alignContent ? 'center' : 'unset'
            }}
        >
            {(actionItems?.length > 0 || title !== undefined) && (
                <Box
                    sx={{
                        backgroundColor: isUsingGradient ? theme.props?.GradientStrength?.gradientNone : 'none',
                        borderTopLeftRadius: '4px',
                        borderTopRightRadius: '4px',
                        width: '100%'
                    }}
                    style={{padding: (headerPadding === undefined ? '1rem' : headerPadding)}}
                >
                    <Grid container>
                        {(title !== undefined && (props.forceTitle || !isMobile || actionItems === undefined)) && (
                            <Grid item sm={actionItems?.length <= 0 ? 12 : 8}>
                                <Typography variant="h6" sx={{ mt: '6px', ml: '5px' }}>
                                    {(!popoverTitle) ?
                                        title
                                        :
                                        <div>
                                            <Typography
                                                aria-owns={open ? 'mouse-over-popover' : undefined}
                                                aria-haspopup="true"
                                                onMouseEnter={handlePopoverOpen}
                                                onMouseLeave={handlePopoverClose}
                                                color={theme.props?.Widget?.titleColor}
                                                fontSize="24px"
                                              >
                                                {title}
                                              </Typography>
                                              <Popover
                                                id="mouse-over-popover"
                                                sx={{
                                                  pointerEvents: 'none',
                                                }}
                                                open={open}
                                                anchorEl={anchorEl}
                                                anchorOrigin={{
                                                  vertical: 'bottom',
                                                  horizontal: 'left',
                                                }}
                                                transformOrigin={{
                                                  vertical: 'top',
                                                  horizontal: 'left',
                                                }}
                                                onClose={handlePopoverClose}
                                                disableRestoreFocus
                                              >
                                                <Typography sx={{ p: 1, whiteSpace: "pre-line" }}>
                                                    {popoverTitle}
                                                </Typography>
                                              </Popover>
                                        </div>
                                    }
                                </Typography>
                                {subTitle &&
                                    <Typography variant="subtitle2" sx={{ fontWeight: 'light', mt: '1px', ml: '5px' }}>
                                        {subTitle}
                                    </Typography>
                                }
                            </Grid>
                        )}
                        {actionItems?.length > 0 && (
                            <Grid item xs={title !== undefined && !isMobile ? 4 : 12} sx={{textAlign: title !== undefined && !isMobile ? 'right' : 'left'}}>
                                {actionItems.map((item) => (
                                    <span key={item.component}>
                                        {item.component}
                                    </span>
                                ))}
                            </Grid>
                        )}
                    </Grid>
                </Box>
            )}
            <Box
                style={{
                    width: '100%',
                    padding: (contentPadding === undefined ? '1rem' : contentPadding),
                }}
            >
                {children}
            </Box>
        </Paper>
    );
};