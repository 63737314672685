import React, { useState, useContext, useEffect, useLayoutEffect } from 'react';
import { getAnnouncementsDefaultItem } from './AnnouncementAPIEndpoints';
import { PageContext } from '../../../lib/PageProvider';
import { AnnouncementGridStateful } from "./AnnouncementGridStateful"
import { AnnouncementFormDialog } from './AnnouncementFormDialog';
import { mutateUrls } from "../../../lib/useGenericSWR";
import { useSWRConfig } from "swr";
import { EnsureUser } from '../../auth/EnsureUser';
import { UcToolbar } from '../../common/UcToolbar';
import { UcListPage } from '../../common/UcListPage';
import { useHasPermission } from 'components/auth/PermissionApiEndpoint';
import AddIcon from '@mui/icons-material/Add';
import GroupsIcon from '@mui/icons-material/Groups';
import { useTranslation } from "react-i18next";

export const AnnouncementListPage = (props) => {
    const { t } = useTranslation();
    const [editDialog, setEditDialog] = useState({ open: false, delete: false, setDefault: false });
    const [selectedItem, setSelectedItem] = useState(null);
    const [pageState, pageDispatch] = useContext(PageContext);
    const [generalSearch, setGeneralSearch] = useState('');

    const [reMutateUrls, setReMutateUrls] = useState(false);
    const swrConfig = useSWRConfig();

    const closeHandler = () => {
        setEditDialog({ open: false, itemToEdit: editDialog.dataItem });
    }

    const saveHandler = () => {
        closeHandler();

        // Also, remutate
        setReMutateUrls(true);
    }

    const openDialog = (dataItem) => {
        if (!dataItem) {
            return;
        }

        if (!dataItem.formIteration) {
            dataItem.formIteration = "edit" + dataItem.id;
        }

        setEditDialog({ open: true, itemToEdit: dataItem, delete: false, setDefault: false });
    }

    const deleteDialog = (dataItem) => {
        if (!dataItem) {
            return;
        }
        if (!dataItem.formIteration) {
            dataItem.formIteration = "delete" + dataItem.id;
        }
        setEditDialog({ open: true, itemToEdit: dataItem, delete: true, setDefault: false });
    }

    const setDefaultDialog = (dataItem) => {
        if (!dataItem) {
            return;
        }

        if (!dataItem.formIteration) {
            dataItem.formIteration = "setDefault" + dataItem.id;
        }

        setEditDialog({ open: true, itemToEdit: dataItem, delete: false, setDefault: true });
    }

    const createItem = () => {
        openDialog(getAnnouncementsDefaultItem());
    }

    const editItem = (item) => {
        openDialog(item);
    }

    const deleteItem = (item) => {
        deleteDialog(item);
    }

    const setIsDefault = () => {
        setDefaultDialog(selectedItem);
    }

    const actions = [
        {
            icon: <AddIcon data-cy-id={"add_announcement"}/>,
            title: t('Add Announcement'),
            toolTip: t('Add Announcement'),
            onClick: () => { createItem(); },
            isHidden: !useHasPermission("Announcement:Modify"),
            isDisabled: false
        },
        {
            icon: <GroupsIcon />,
            title: t('Set Selected as Default'),
            toolTip: t('Set Selected as Default Announcement'),
            onClick: () => { setIsDefault(); },
            isHidden: !useHasPermission("AnnouncementDefault:Modify"),
            isDisabled: (selectedItem !== null ? false : true),
            color: 'secondary'
        }
    ];

    useLayoutEffect(() => {
        if (reMutateUrls) {
            mutateUrls(swrConfig);
            setReMutateUrls(false);
        }
    }, [reMutateUrls, swrConfig]);

    useEffect(() => {
        pageDispatch({
            header: "Announcements",
        });
    }, [pageState.header, selectedItem, pageDispatch]);

    console.log("AnnouncementListPage - Loaded");
    return (
        <UcListPage>
            <EnsureUser permissions={["Announcement:Read"]}>
                <h1 className="pageHeader">{t("Announcements")}</h1>
                <UcToolbar
                    hasGeneralSearch={true}
                    onGeneralSearchChange={(value) => {
                        setGeneralSearch(value);
                    } }
                    actions={actions}>
                </UcToolbar>
                <AnnouncementFormDialog
                    open={editDialog.open}
                    delete={editDialog.delete}
                    setDefault={editDialog.setDefault}
                    itemToEdit={editDialog.itemToEdit}
                    onCancel={closeHandler}
                    onSave={saveHandler}
                />
                {!reMutateUrls &&
                    <AnnouncementGridStateful
                    onEditItem={editItem}
                    onDeleteItem={deleteItem}
                    generalSearch={generalSearch}
                    onSelected={(announcementGridSelectedItem) => {
                        setSelectedItem({ ...announcementGridSelectedItem });
                    }} />
                }

            </EnsureUser>
        </UcListPage>
    );
}