import { Formik, Form, Field, FormikProps } from "formik";
import { useEffect, useRef } from "react";
import { TextField } from "formik-mui";
import { RateClassChangeRequest, getRateClassChangeRequestsDefaultItem, rateClassChangeRequestsValidationSchema } from "./RateClassChangeRequestAPIEndpoints";
import { useTranslation } from "react-i18next";
// @ts-ignore
import { UcEnumSelect } from "components/common/UcEnumSelect";
import { Enum } from "components/common/Common.interfaces";
import { Grid } from "@mui/material";

interface RateClassChangeRequestFormProps {
    id: any;
    dialogSubmit: number;
    itemToEdit: RateClassChangeRequest;
    onSubmit: (values: any) => void;
    onError: (error: any) => void;
}

export const RateClassChangeRequestForm = (
    props: RateClassChangeRequestFormProps
) => {
    const { t } = useTranslation();
    const itemToEdit =
        props.itemToEdit || getRateClassChangeRequestsDefaultItem();
    const formRef = useRef<FormikProps<RateClassChangeRequest>>(null);
    const dialogSubmit = useRef(props.dialogSubmit);

    useEffect(() => {
        if (props.dialogSubmit !== dialogSubmit.current) {
            dialogSubmit.current = props.dialogSubmit;
            if (formRef.current) {
                formRef.current.handleSubmit();
            }
        }
    });

    return (
        <div className="ignoreCustomFormStyles">
            <Formik
                innerRef={formRef}
                initialValues={itemToEdit}
                validationSchema={rateClassChangeRequestsValidationSchema}
                onSubmit={(values) => {
                    props.onSubmit(values);
                }}
            >
                <Form>
                    <Grid container spacing={2} style={{ paddingTop: "8px" }}>
                        <Grid item xs={12} sm={12} md={6}>
                            <Field
                                name={"accountNumber"}
                                component={TextField}
                                disabled={true}
                                label={t("Account Number")}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={6}>
                            <Field
                                name={"currentRateClassName"}
                                component={TextField}
                                disabled={true}
                                label={t("Current Rate Class")}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={6}>
                            <Field
                                name={"requestedRateClassName"}
                                component={TextField}
                                disabled={true}
                                label={t("Requested Rate Class")}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={6}>
                            <Field name={"status"}>
                                {({
                                    field: { value },
                                    form: { setFieldValue },
                                }: any) => (
                                    <UcEnumSelect
                                        value={value}
                                        datacyid={
                                            "rate_class_change_request_status"
                                        }
                                        enumName="RateClassChangeRequestStatus"
                                        label={t("Status")}
                                        onChange={(
                                            e: MouseEvent,
                                            statusValue: Enum
                                        ) =>
                                            setFieldValue(
                                                "status",
                                                statusValue.id
                                            )
                                        }
                                    />
                                )}
                            </Field>
                        </Grid>
                    </Grid>
                </Form>
            </Formik>
        </div>
    );
};
