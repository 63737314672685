import { ApiClient } from "lib/ApiClient";
import { useGenericSWR } from "lib/useGenericSWR";
import * as Yup from 'yup';

const apiEndpoint = 'api/v1/admin/uc/RoleUser';

let formIteration = 0;

const getRoleUsersDefaultItem = () => {
    formIteration = formIteration + 1;
    return {
        formIteration: "new" + formIteration,
        id: 0,
        roleUserId: 0,
    }
};

const RoleUsersValidationSchema = Yup.object().shape({

    roleId: Yup.number().required("Required").integer(),
    userId: Yup.number().required("Required").integer(),
});

// See definition from UsageRate.cs
const apiClient = new ApiClient();
apiClient.getDefaultItem = () => getRoleUsersDefaultItem();
apiClient.getObjectName = () => {
    return "Role User";
};

const useRoleUser = (id, swrOptions = {}) => {
    // SWR will prevent duplicate requests
    // It will also maintain data state as long as your are using the same urls for your requests
    return useGenericSWR(apiEndpoint + "/" + id, apiClient, { ...swrOptions });
};

const useRoleUsers = (filter, swrOptions = {}) => {
    // SWR will prevent duplicate requests
    // It will also maintain data state as long as your are using the same urls for your requests
    const params = new URLSearchParams();
    params.append('filter', JSON.stringify(filter));
    return useGenericSWR(apiEndpoint + "?" + params.toString(), apiClient, { ...swrOptions });
};

const saveRoleUser = (RoleUser) => {
    if (RoleUser.id === 0) {
        return insertRoleUser(RoleUser);
    }
    return updateRoleUser(RoleUser);
}
const insertRoleUser = (RoleUser) => {
    return apiClient.poster(apiEndpoint, RoleUser);
};

const updateRoleUser = (RoleUser) => {
/*    RoleUser.dateRuleSet = {
        validTimeRanges: [],
        validWeekDays: [3, 5, 6],
        exemptDays: []
    }*/
    console.log(RoleUser);
    return apiClient.putter(apiEndpoint, RoleUser);
};

const deleteRoleUser = (RoleUser) => {
    return apiClient.deleter(apiEndpoint + "/" + RoleUser.id);
};

export {
    useRoleUser,
    useRoleUsers,
    saveRoleUser,
    insertRoleUser,
    updateRoleUser,
    deleteRoleUser,
    RoleUsersValidationSchema,
    getRoleUsersDefaultItem,
};