import React, { useState } from 'react';
import LoadingButton from '@mui/lab/LoadingButton';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Paper from '@mui/material/Paper';
import Draggable from 'react-draggable';
import PropTypes from 'prop-types';
import { AnnouncementForm } from './AnnouncementForm';
import { saveAnnouncement, deleteAnnouncement, getAnnouncementsDefaultItem } from './AnnouncementAPIEndpoints';
import { AnnouncementSendEmailDialog } from './AnnouncementSendEmailDialog';
import { useResponsive } from 'lib/useResponsive';
import { useTranslation } from "react-i18next";
import { useTheme } from '@mui/material/styles';
import { useUser } from 'components/auth/PermissionApiEndpoint';

function PaperComponent(props) {
    return (
        <Draggable
            handle="#draggable-dialog-title"
            cancel={'[class*="MuiDialogContent-root"]'}
        >
            <Paper {...props} />
        </Draggable>
    );
}

export const AnnouncementFormDialog = (props) => {
    const { t } = useTranslation();
    const [saving, setSaving] = React.useState(false);
    const [dialogSubmit, setDialogSubmit] = useState(0);
    const { isMobile } = useResponsive();
    const theme = useTheme();
    const [sendEmailDialog, setSendEmailDialog] = useState({ open: false });
    const [publishCheckbox, setPublishCheckbox] = useState(false);
    const user = useUser();

    let userIsUtilityAdmin = false;
    let userRole = '';

    if (user) {
        userRole = JSON.parse(user?.rolesJson).map(r => r.name);
    }

    if (userRole.includes("Utility Admin") || userRole.includes("Super Admin")) {
        userIsUtilityAdmin = true;
    }

    let formId = props.id;
    if (!props.open) {
        formId = 0;
    }

    const handleSendEmail = () => {
        openSendEmailDialog(props.itemToEdit);
    }

    const openSendEmailDialog = (dataItem) => {
        if (!dataItem) {
            return;
        }

        if (!dataItem.formIteration) {
            dataItem.formIteration = "edit" + dataItem.id;
        }

        setSendEmailDialog({ open: true, itemToEdit: dataItem });
    }

    const closeSendEmailHandler = () => {
        setSendEmailDialog({ open: false, itemToEdit: sendEmailDialog.dataItem });
    }

    const handleCancel = () => {
        props.onCancel();
    };

    const handleSave = () => {
        setDialogSubmit(dialogSubmit + 1);
    };

    const handleDelete = async () => {
        let data = await deleteAnnouncement(props.itemToEdit);

        if (data) {
            props.onSave(data);
        }
    };

    return (
        <>
            {props.delete && props.open &&
                <Dialog
                    fullScreen={isMobile}
                    fullWidth={true}
                    maxWidth={"sm"}
                    open={props.open}
                    PaperComponent={PaperComponent}
                    aria-labelledby="draggable-dialog-title"
                >
                    <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
                        {t("Delete Announcement")}
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            {t('Are you sure you want to delete this announcement?')}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            autoFocus
                            onClick={handleCancel}
                            variant={theme.props?.CancelButton?.variant}
                            data-cy-id={"cancel_delete_button"}
                        >
                            {t("Cancel")}
                        </Button>
                        <Button
                            onClick={handleDelete}
                            variant={theme.props?.CancelButton?.variant}
                            color={theme.props?.CancelButton?.color}
                            data-cy-id={"delete_button"}
                        >
                            {t("Delete")}
                        </Button>
                    </DialogActions>
                </Dialog>
            }
            {!props.delete && !props.setDefault && props.open &&
                <Dialog
                    fullScreen={isMobile}
                    fullWidth={true}
                    maxWidth={"md"}
                    open={props.open}
                    PaperComponent={PaperComponent}
                    aria-labelledby="draggable-dialog-title"
                >
                    <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
                        {t("Announcement")}
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            <AnnouncementForm
                                id={formId}
                                itemToEdit={props.itemToEdit}
                                userIsUtilityAdmin={userIsUtilityAdmin}
                                setPublishCheckbox={setPublishCheckbox}
                                dialogSubmit={dialogSubmit}
                                onSubmit={async (values) => {                                
                                    values.isSendEmails = props.itemToEdit.isSendEmails;
                                    setSaving(true);
                                    try {
                                        let data = await saveAnnouncement(values);

                                        if (data) {
                                            props.onSave(data);
                                        }
                                    } finally {
                                        setSaving(false);
                                        closeSendEmailHandler()
                                    }
                                }}
                                onError={(error) => {
                                    props.onError(error);

                                    setSaving(false);
                                    closeSendEmailHandler()
                                }}
                            />
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        {userIsUtilityAdmin && (
                            <Button autoFocus data-cy-id={"send_emails_button"} disabled={!publishCheckbox} onClick={handleSendEmail} variant="outlined" color={theme.props?.CancelButton?.color}>
                                {t("Send Emails")}
                            </Button>   
                        )}                                         
                        <Button
                            autoFocus
                            onClick={handleCancel}
                            variant={theme.props?.CancelButton?.variant || "outlined"}
                            color={theme.props?.CancelButton?.color}
                            data-cy-id={"cancel_button"}
                        >
                            {t("Cancel")}
                        </Button>
                        <LoadingButton
                            loading={saving}
                            onClick={handleSave}
                            variant="contained"
                            data-cy-id={"save_button"}
                        >
                            {t("Save")}
                        </LoadingButton>
                    </DialogActions>
                </Dialog>
            }
            <AnnouncementSendEmailDialog
                open={sendEmailDialog.open}
                itemToEdit={sendEmailDialog.itemToEdit}
                onCancel={closeSendEmailHandler}
                onSave={handleSave}
                loading={saving}
            />
        </>
    );
}

AnnouncementFormDialog.defaultProps = {
    open: false,
    id: 0,
    itemToEdit: getAnnouncementsDefaultItem(),
    onCancel: () => { console.log("onCancel not implemented"); },
    onSave: (data) => { console.log("onSave not implemented"); },
    onError: (error) => { console.error("onError not implemented", error); },
};

AnnouncementFormDialog.propTypes = {
    open: PropTypes.bool,
    id: PropTypes.number,
    itemToEdit: PropTypes.object,
};