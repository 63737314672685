import React, { forwardRef, useContext } from "react";
import { UcThemeContext } from "lib/UcThemeProvider";
import { Switch, Typography, Stack } from '@mui/material';
import { useTranslation } from "react-i18next";

export const UcThemeToggleSwitch = forwardRef((props, ref) => {
    const { t } = useTranslation();
    const [themeState, themeDispatch] = useContext(UcThemeContext);

    const themeToggle = (event) => {
        const mode = event.target.checked ? 'dark' : 'light';
        themeDispatch({ name: themeState.themeName, mode: mode })
    };

    return (
        <Stack direction="row" spacing={0} alignItems="center">
            <Typography>{t("Light Mode")}</Typography>
                <Switch onChange={themeToggle} checked={themeState.themeMode !== 'light'} ref={ref} />
            <Typography>{t("Dark Mode")}</Typography>
        </Stack>
    );
});