// @ts-ignore
import { UcPagination } from "components/common/UcPagination";
import { useState, useLayoutEffect, useRef } from "react";
// @ts-ignore
import { getWholeRow } from "lib/DataGridHelpers";
// @ts-ignore
import { enumFormatter } from "lib/EnumFormatter";
import {
    DataGridPro,
    GridFilterModel,
    GridLogicOperator,
} from "@mui/x-data-grid-pro";
import { useRateClassChangeRequests } from "./RateClassChangeRequestAPIEndpoints";
// @ts-ignore
import { UcIconButtons } from "components/common/UcIconButtons";
// @ts-ignore
import { useEnums } from "components/auth/PermissionApiEndpoint";
import { Box, Chip } from "@mui/material";
import { useTranslation } from "react-i18next";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";

export const RateClassChangeRequestGrid = (props: any) => {
    const { t } = useTranslation();
    const statusEnums = useEnums("RateClassChangeRequestStatus");
    const initialSort = [{ field: "sentDate", sort: "desc" }];
    const sort = props.sort || initialSort;
    const initialDataState = props.dataState || { skip: 0, take: 10 };
    const [filter, setFilter] = useState({
        take: initialDataState.take,
        skip: initialDataState.skip,
        sort: sort,
    });

    let initialFilterModel: GridFilterModel | undefined = {
        items: [{ field: "name", operator: "contains", value: "" }],
        logicOperator: GridLogicOperator.And,
    };

    const [filterModel] = useState<GridFilterModel | undefined>(
        initialFilterModel
    );

    const needsDataUpdate = useRef("");

    let defaultDataItems = {
        columnVisibilityModel: {
            id: false,
            cepOrgId: false,
            content: false,
            createdBy: false,
            updatedBy: false,
        },
        columns: [
            {
                field: "accountNumber",
                headerName: "Account Number",
                width: 200,
            },
            {
                field: "currentRateClassName",
                headerName: "Current Rate Class",
                minWidth: 200,
            },
            {
                field: "requestedRateClassName",
                headerName: "Requested Rate Class",
                width: 200,
            },
            {
                field: "status",
                headerName: "Status",
                width: 200,
                renderCell: (params: any) => {
                    return (
                        <Box
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                            minHeight="100vh"
                        >
                            <Chip
                                label={enumFormatter(statusEnums, params.value)}
                                color={
                                    params.value === "Approved"
                                        ? "success"
                                        : params.value === "Error"
                                        ? "error"
                                        : params.value === "Refused"
                                        ? "warning"
                                        : "info"
                                }
                                variant="outlined"
                            />
                        </Box>
                    );
                },
            },
            {
                field: "icons",
                headerName: "",
                valueGetter: getWholeRow,
                renderCell: (params: any) => {
                    const actions = [
                        {
                            title: t("Edit Rate Class"),
                            icon: (
                                <EditIcon
                                    data-cy-id={
                                        "rate_class_edit_" + params.row.name
                                    }
                                />
                            ),
                            color: "primary",
                            isHidden: false,
                            isDisabled: false,
                            requiredPermission: "RateClassChangeRequest:Modify",
                            onClick: () => {
                                props.onEditItem(params.value);
                            },
                        },
                        {
                            title: t("Delete Rate Class"),
                            icon: (
                                <DeleteIcon
                                    data-cy-id={
                                        "rate_class_delete_" + params.row.name
                                    }
                                />
                            ),
                            color: "error",
                            isHidden: false,
                            isDisabled: false,
                            requiredPermission: "RateClassChangeRequest:Delete",
                            onClick: () => {
                                props.onDeleteItem(params.value);
                            },
                        },
                    ];

                    return <UcIconButtons actions={actions} />;
                },
            },
        ],
        rows: [],
        rowCount: -1,
    };
    const [dataItems, setDataItems] = useState<any>(defaultDataItems);

    const defaultSelectedItem = { dataItem: {}, selectedId: 0 };
    const [selectedItem, setSelectedItem] = useState<any>(defaultSelectedItem);

    const isDataLoaded = useRef(false);

    const dataReceived = (swrResult: any) => {
        let dataItems = swrResult.result;
        let selectedDataItems = dataItems.map((item: any) => {
            return { ...item, selected: item.id === selectedItem.selectedId };
        });

        selectedDataItems.iteration = dataItems.iteration;
        selectedDataItems.rowCount = swrResult.rowCount;
        if (selectedDataItems.length > selectedDataItems.rowCount) {
            selectedDataItems.rowCount = selectedDataItems.length;
        }

        setDataItems({
            ...defaultDataItems,
            rows: [...dataItems],
            rowCount: swrResult.rowCount,
        });
    };

    const swrRateClassChangeRequests = useRateClassChangeRequests(filter); //Pass the datastate directly as the filter.  The API will adjust filters, orderbys ect.
    if (
        !swrRateClassChangeRequests.result &&
        defaultDataItems.rowCount !== -1
    ) {
        setDataItems(defaultDataItems);
    }

    const updateFilter = (filter: any) => {
        setFilter(filter);
        props.onFilterChange(filter);
    };

    useLayoutEffect(() => {
        if (swrRateClassChangeRequests.result) {
            isDataLoaded.current = true;
            let filterString = JSON.stringify(filter);
            if (needsDataUpdate.current !== filterString) {
                needsDataUpdate.current = filterString;
                dataReceived(swrRateClassChangeRequests);
            }
        }
    });

    return (
        <div data-cy-id={"rateClassChangeRequestsUserGridWrapper"}>
            <DataGridPro
                initialState={{ pinnedColumns: { right: ['icons'] } }}
                autoHeight={true}
                loading={!isDataLoaded.current}
                paginationModel={{
                    page: filter.skip / filter.take,
                    pageSize: filter.take,
                }}
                onPaginationModelChange={(model) =>
                    updateFilter({
                        ...filter,
                        skip: model.page * model.pageSize,
                        take: model.pageSize,
                    })
                }
                sortingMode="server"
                paginationMode="server"
                filterMode="server"
                sortModel={filter.sort}
                onSortModelChange={(model) =>
                    updateFilter({ ...filter, sort: model })
                }
                filterModel={filterModel}
                rowsPerPageOptions={[5, 10, 20]}
                onRowSelectionModelChange={(newSelectionModel) => {
                    if (newSelectionModel.length !== 1) {
                        setSelectedItem(defaultSelectedItem);
                        props.onSelected(defaultSelectedItem.dataItem);

                        return;
                    }

                    const selectedId = newSelectionModel[0];
                    let selectedItem = dataItems.rows.filter((obj: any) => {
                        return obj.id === selectedId;
                    });

                    if (selectedItem.length !== 1) {
                        setSelectedItem(defaultSelectedItem);
                        props.onSelected(defaultSelectedItem.dataItem);

                        return;
                    }

                    selectedItem = selectedItem[0];
                    let newlySelectedItem = {
                        dataItem: selectedItem,
                        selectedId: selectedId,
                    };

                    setSelectedItem(newlySelectedItem);
                    props.onSelected(newlySelectedItem.dataItem);
                }}
                pagination
                slots={{
                    toolbar: () => {
                        return props.toolbar || <></>;
                    },
                    pagination: UcPagination,
                }}
                {...dataItems}
            />
        </div>
    );
};

RateClassChangeRequestGrid.defaultProps = {
    onSelected: (selectedItem: any) => {
        console.log("onSelected not implemented", selectedItem);
    },
    onFilterChange: (filter: any) => {
        console.log("onFilterChange not implemented", filter);
    },
};
