import { convertUserNotificationPrefToAccount, getUserNotificationPreferenceDefaultItem, UserNotificationPreferenceValidationSchema } from './UserNotificationPreferenceAPIEndpoints';
import PropTypes from 'prop-types';
import { Formik, Form, Field } from 'formik';
import { useRef, useEffect, useState } from 'react';
import { TextField } from 'formik-mui';
import Grid from '@mui/material/Grid';
import { UcEnumSelect } from 'components/common/UcEnumSelect';
import UcCommodityToggle from "components/common/UcCommodityToggle";
import { useTranslation } from 'react-i18next';
import Divider from '@mui/material/Divider';
import { AccountSelect } from 'components/customer/accounts/AccountSelect';
import { Alert, Typography, Box, IconButton, Popover, Link } from '@mui/material';
import { useDomainNavigate } from "domain";
import InfoIcon from '@mui/icons-material/Info';
import { useUserPref } from "components/user/useUserPref";
import useOrgAppSettings from '../../lib/useOrgAppSettings';

export const UserNotificationPreferenceForm = (props) => {
    const { t } = useTranslation();
    const { orgAppSettings } = useOrgAppSettings();
    const navigate = useDomainNavigate();
    const itemToEdit = props.itemToEdit || getUserNotificationPreferenceDefaultItem();
    const dialogSubmit = useRef(props.dialogSubmit);
    const formRef = useRef();
    const [uom, setUom] = useState(itemToEdit.uom || "KWH");
    const [commodityDisabled] = useState(itemToEdit.notificationType === "NewBill" || itemToEdit.notificationType === "BillAlmostDue");
    const [selectedAccount, setSelectedAccount] = useState(convertUserNotificationPrefToAccount(itemToEdit));
    const [billNotificationSelected] = useState(itemToEdit.frequency === "Automated");
    const [showNotificationTypeUnitError, setShowNotificationTypeUnitError] = useState(false);
    const notificationDeliveryMethod = useUserPref("notificationDeliveryMethod", "Email");
    const [anchorElDeliveryMethodInfo, setAnchorElDeliveryMethodInfo] = useState(null);
    const openDeliveryMethodInfoPopover = Boolean(anchorElDeliveryMethodInfo);
    const idDeliveryMethodInfoPopover = openDeliveryMethodInfoPopover ? 'simple-popover' : undefined;
    const [alertFrequency, setAlertFrequency] = useState(itemToEdit.frequency);
    const [notificationType, setNotificationType] = useState(itemToEdit.notificationType);

    useEffect(() => {
        if (props.dialogSubmit !== dialogSubmit.current) {
            dialogSubmit.current = props.dialogSubmit;
            if (formRef.current) {
                formRef.current.values.accountId = selectedAccount?.id;
                formRef.current.values.uom = uom;
                formRef.current.handleSubmit();
            }
        }
    });

    const handleChangeCommodityType = (value) => {
        setUom(value);
        handleShowNotificationTypeUnitError(value);
    };

    const handleShowNotificationTypeUnitError = (newUom = null, newNotificationType = null) => {
        if (newUom !== null) {
            if (newUom === 'ALL' && formRef.current.values.notificationType === 'Usage') {
                setShowNotificationTypeUnitError(true);
                props.handleSaveDisabled(true);
            }
            else {
                setShowNotificationTypeUnitError(false);
                props.handleSaveDisabled(false);
            }
        }
        else if (newNotificationType !== null) {
            if (newNotificationType === 'Usage' && uom === 'ALL') {
                setShowNotificationTypeUnitError(true);
                props.handleSaveDisabled(true);
            }
            else {
                setShowNotificationTypeUnitError(false);
                props.handleSaveDisabled(false);
            }
        }
    }

    const handleClickDeliveryMethodInfo = (event) => {
        setAnchorElDeliveryMethodInfo(event.currentTarget);
    };

    const handleCloseDeliveryMethodInfo = () => {
        setAnchorElDeliveryMethodInfo(null);
    };

    const valueHelperText = () => {
        let commodity;
        switch (uom) {
            case 'KWH':
                commodity = 'electricity';
                break;
            case 'GAL':
                commodity = 'water';
                break;
            case 'ALL':
                commodity = 'electricity and water';
                break;
            default:
                commodity = 'unknown';
                break;
        }
        if (notificationType === 'Money') {
            return t(`Input a {{alertFreq}} dollar amount spent on {{type}}, you will be notified when this amount is surpassed.`, {alertFreq: alertFrequency?.toLowerCase(), type: commodity});
        }
        else {
            return t(`Input a {{alertFreq}} {{type}} usage amount, you will be notified when this amount is surpassed.`, {alertFreq: alertFrequency?.toLowerCase(), type: commodity});
        }
    }

    const valueLabel = () => {
        if (notificationType === 'Money') {
            return '$';
        }
        else {
            const uomMapping = orgAppSettings?.result?.uomMappings[uom.toUpperCase()];
            if (uom === 'GAL') {
                return uomMapping?.safeUomDisplay;
            }
            else {
                return uomMapping?.safeUomDisplay;
            }
        }
    }

    return (
        <div className='ignoreCustomFormStyles sentenceFields'>
            <UcCommodityToggle
                allowUomAll={true}
                selectedAccount={selectedAccount}
                selectedUom={itemToEdit.uom}
                onChange={handleChangeCommodityType}
                isDisabled={commodityDisabled}
            />
            <Formik
                innerRef={formRef}
                initialValues={itemToEdit}
                validationSchema={UserNotificationPreferenceValidationSchema}
                onSubmit={(values) => {
                    values.deliveryMethod = notificationDeliveryMethod.value;
                    props.onSubmit(values);
                }}
            >
                <Form>
                    <Grid container spacing={2} style={{ paddingTop: '8px' }}>
                        <Grid item xs={12} sm={12} md={6}>
                            <Field fullWidth
                                name={"name"}
                                data-cy-id={"notification_preference_name"}
                                component={TextField}
                                label={t("Notification Name")}
                                disabled={billNotificationSelected}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={6}>
                            <AccountSelect
                                isDisabled={billNotificationSelected}
                                initialValue={selectedAccount}
                                label={t("Account")}
                                isAdmin={false}
                                allowDelegations={true}
                                showFullLabel={true}
                                showAccountNumber={true}
                                onChange={(event, value) => {
                                    setSelectedAccount(value);
                                }}
                            />
                        </Grid>
                        <Divider style={{ width: '100%' }} />
                        <br />
                        <Grid item xs={12}>
                            <Box sx={{ mb: 1 }}>
                                <Typography variant='caption'>
                                    {t('Delivery Method')}
                                    <IconButton onClick={handleClickDeliveryMethodInfo} color="primary" aria-label="add to shopping cart" size='small' sx={{ p: 0, ml: 1 }}>
                                        <InfoIcon sx={{ fontSize: 20 }} />
                                    </IconButton>
                                </Typography>
                                <Typography color={'primary'} fontWeight={'bold'}>{notificationDeliveryMethod.value}</Typography>
                                <Popover
                                    id={idDeliveryMethodInfoPopover}
                                    open={openDeliveryMethodInfoPopover}
                                    anchorEl={anchorElDeliveryMethodInfo}
                                    onClose={handleCloseDeliveryMethodInfo}
                                    anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'left',
                                    }}
                                >
                                    <Box
                                        sx={{ p: 2 }}>
                                        <Typography variant='body2'>
                                            {t('This is a global setting, managed in your profile settings.')}
                                            <br />
                                            <Link
                                                target='_blank'
                                                component={'button'}
                                                onClick={() => navigate('/myProfile?profileNav_link=Preferences')} variant="body2">
                                                {t('Click here to edit')}
                                            </Link>
                                        </Typography>
                                    </Box>
                                </Popover>
                            </Box>
                            <Field name={"frequency"} >
                                {({ field: { value }, form: { setFieldValue } }) => (
                                    <UcEnumSelect
                                        label={t("Alert Frequency")}
                                        datacyid={"notification_preference_frequency"}
                                        style={{ flex: 1 }}
                                        forcedWidth="50%"
                                        forcedPadding={"15px 8px 15px 0px"}
                                        forcedDisplay="inline-flex"
                                        value={value}
                                        enumName="Frequency"
                                        hideAutomatedFreq={!billNotificationSelected}
                                        onChange={(e, value) => {
                                            setFieldValue("frequency", value.id);
                                            setAlertFrequency(value.id);
                                        }}
                                        disabled={billNotificationSelected}
                                    />
                                )}
                            </Field>
                            <br />
                            {showNotificationTypeUnitError && (
                                <Alert severity='error'>{t('You cannot configure an alert for all commodities when Usage is selected. Change it to proceed.')}</Alert>
                            )}
                            <Field name={"notificationType"}>
                                {({ field: { value }, form: { setFieldValue } }) => (
                                    <UcEnumSelect
                                        label={t("Notification Type")}
                                        datacyid={"notification_preference_notification_type"}
                                        forcedWidth="100%"
                                        forcedPadding={"15px 0px 20px 0px"}
                                        forcedDisplay="inline-flex"
                                        value={value}
                                        enumName="NotificationType"
                                        onChange={(e, value) => {
                                            setFieldValue("notificationType", value.id);
                                            setNotificationType(value.id);
                                            handleShowNotificationTypeUnitError(null, value.id);
                                        }}
                                        disabled={billNotificationSelected}
                                    />
                                )}
                            </Field>
                            <br />
                            {!billNotificationSelected && (
                                <Field fullWidth
                                    name={"value"}
                                    data-cy-id={"notification_preference_value"}
                                    label={valueLabel()}
                                    component={TextField}
                                    forcedPadding={"15px 8px 15px 8px"}
                                    helperText={valueHelperText()}
                                />
                            )}
                        </Grid>
                    </Grid>
                </Form>
            </Formik>
        </div>
    );
}

UserNotificationPreferenceForm.defaultProps = {
    id: 0,
    dialogSubmit: 0,
    changeIteration: 0,
    onSubmit: (values) => { console.log("onSubmit not implemented", values); },
    onError: (error) => { console.error("onError not implemented", error); },
};

UserNotificationPreferenceForm.propTypes = {
    id: PropTypes.number,
    changeIteration: PropTypes.number,
    dialogSubmit: PropTypes.number,
};