import { useTranslation } from 'react-i18next';
// @ts-ignore
import { useBillApiHealth } from './BillApiHealthApiEndpoints';
// @ts-ignore
import { UcLoading } from 'components/common/UcLoading';
import { Alert, Grid } from '@mui/material';
import Typography from '@mui/material/Typography';
// @ts-ignore
import { dateFormatter } from '../../../lib/Formatters';

export const BillApiHealthGrid = () => {
    const { t } = useTranslation();
    const swrBillApiHealth = useBillApiHealth();
    const isLoading = swrBillApiHealth.isLoading;

    return (
        <UcLoading isLoading={isLoading} hasFade={false}>
            <h1 className="pageHeader">{t("Bill API Health")}</h1>
            {swrBillApiHealth.isError && (
                <Alert severity="error" sx={{ mb: 2 }}>
                    {t('Error retrieving DPS Api Billing Health')}
                </Alert>
            )}
            <Grid container spacing={8} justifyContent="center" minHeight="50vh" display="flex" padding="1rem">
                <Grid item sm={4} xs={6}>
                    <Typography variant="h6" gutterBottom>{t("App Name")}</Typography>
                    <Typography>{swrBillApiHealth?.result?.appName}</Typography>
                </Grid>
                <Grid item sm={4} xs={6}>
                    <Typography variant="h6" gutterBottom>{t("Environment")}</Typography>
                    <Typography>{swrBillApiHealth?.result?.environment}</Typography>
                </Grid>
                <Grid item sm={4} xs={6}>
                    <Typography variant="h6" gutterBottom>{t("App Version")}</Typography>
                    <Typography>{swrBillApiHealth?.result?.appVersion}</Typography>
                </Grid>
                <Grid item sm={4} xs={6}>
                    <Typography variant="h6" gutterBottom>{t("Oracle Db Health Status")}</Typography>
                    <Typography>{swrBillApiHealth?.result?.isOracleDbHealthy ? "Healthy" : "Not Healthy"}</Typography>
                </Grid>
                <Grid item sm={4} xs={6}>
                    <Typography variant="h6" gutterBottom>{t("Last Oracle Db Check")}</Typography>
                    <Typography>{dateFormatter(swrBillApiHealth?.result?.oracleDbLastCheck)}</Typography>
                </Grid>
                <Grid item sm={4} xs={6}>
                    <Typography variant="h6" gutterBottom>{t("Current Time")}</Typography>
                    <Typography>{dateFormatter(swrBillApiHealth?.result?.currentTime)}</Typography>
                </Grid>
            </Grid>
        </UcLoading>
    );
}