import React from "react";
import {
    Avatar,
    ToggleButton,
    ToggleButtonGroup,
    useTheme,
    Theme as MuiTheme,
} from "@mui/material";
import ElectricBoltIcon from "@mui/icons-material/ElectricBolt";
import WaterDropIcon from "@mui/icons-material/WaterDrop";
import LocalFireDepartmentIcon from "@mui/icons-material/LocalFireDepartment";
import { AccountContext } from "lib/AccountProvider";
import { useTranslation } from "react-i18next";

interface CustomInstanceOfMuiTheme extends MuiTheme {
    props: any;
}

const toggleStyle = {
    border: 'none',
    borderColor: 'transparent',
    "&.Mui-disabled": {
        border: 'none',
    },
    "&.Mui-selected, &.Mui-selected:hover": {
        backgroundColor: "transparent",
    },
};

export interface UcCommodityToggleProps {
    allowUomAll: boolean;
    selectedAccount?: any;
    selectedUom?: string;
    isDisabled?: boolean;
    onChange: (value: string) => void;
}

export default function UcCommodityToggle(props: UcCommodityToggleProps) {
    const { selectedAccount, allowUomAll, selectedUom, isDisabled, onChange } = props;
    const { t } = useTranslation();
    const theme = useTheme<CustomInstanceOfMuiTheme>();
    const { account } = React.useContext(AccountContext);
    const [initialLoad, setInitialLoad] = React.useState(true);
    const [uom, setUom] = React.useState("KWH");
    const [meterServices, setMeterServices] = React.useState<
        string[] | undefined
    >([]);

    const handleChangeUom = (
        event: React.MouseEvent<HTMLElement, MouseEvent>,
        value: string
    ) => {
        if (value !== null) {
            setUom(value);
            onChange(value);
        }
    };

    React.useEffect(() => {
        if (initialLoad && selectedUom) {
            setUom(selectedUom);
            setInitialLoad(false);
        }
    }, [initialLoad, selectedUom]);

    React.useEffect(() => {
        if (selectedAccount?.metersJson) {
            const metersJson = JSON.parse(selectedAccount?.metersJson);
            setMeterServices(metersJson?.map((r: any) => r.meterCommodity));
        } else {
            setMeterServices(account?.meterServices);
        }
    }, [account, selectedAccount]);

    return  (
        <>
            {meterServices && (
                <ToggleButtonGroup
                    value={uom}
                    size="small"
                    onChange={handleChangeUom}
                    exclusive={true}
                    aria-label="ChartType"
                    sx={{ display: 'inline-block', justifyContent: 'flex-end' }}
                >
                    {(allowUomAll && meterServices?.indexOf("ELECTRIC") > -1 && meterServices?.indexOf("WATER") > -1) && (
                        <ToggleButton
                            color="primary"
                            value="ALL"
                            title={t("All")}
                            disabled={isDisabled}
                            sx={{ ...toggleStyle }} 
                        >
                            <Avatar
                                sx={{
                                    width: 32, height: 32,
                                    bgcolor: !isDisabled && uom === "ALL" && theme.props?.Commodity?.allColor,
                                    fontSize: 12,
                                    letterSpacing: 1,
                                }}
                            >
                                {t("All")}
                            </Avatar>
                        </ToggleButton>
                    )}
                    {meterServices?.indexOf("ELECTRIC") > -1 && (
                        <ToggleButton
                            data-cy-id={"ucCommodityToggle-electric"}
                            color="primary"
                            value="KWH"
                            title={t("Electricity")}
                            disabled={isDisabled}
                            sx={{ ...toggleStyle }}
                        >
                            <Avatar
                                sx={{ 
                                    width: 32, height: 32,
                                    bgcolor:
                                        !isDisabled && (uom === "KWH" || uom === "ALL") &&
                                        theme.props?.Commodity?.kWhColor,
                                }}
                            >
                                <ElectricBoltIcon />
                            </Avatar>
                        </ToggleButton>
                    )}
                    {meterServices?.indexOf("WATER") > -1 && (
                        <ToggleButton
                            data-cy-id={"ucCommodityToggle-water"}
                            color="primary"
                            value="GAL"
                            title={t("Water")}
                            disabled={isDisabled}
                            sx={{ ...toggleStyle }}
                        >
                            <Avatar
                                sx={{
                                    width: 32, height: 32,
                                    bgcolor:
                                        !isDisabled && (uom === "GAL" || uom === "ALL") &&
                                        theme.props?.Commodity?.galColor,
                                }}
                            >
                                <WaterDropIcon />
                            </Avatar>
                        </ToggleButton>
                    )}
                    {meterServices?.indexOf("GAS") > -1 && (
                        <ToggleButton
                            data-cy-id={"ucCommodityToggle-gas"}
                            color="primary"
                            value="GAS"
                            title={t("Gas")}
                            disabled={isDisabled}
                            sx={{ ...toggleStyle }}
                        >
                            <Avatar
                                sx={{
                                    width: 32, height: 32,
                                    bgcolor:
                                        !isDisabled && (uom === "GAS" || uom === "ALL") &&
                                        theme.props?.Commodity?.gasColor,
                                }}
                            >
                                <LocalFireDepartmentIcon />
                            </Avatar>
                        </ToggleButton>
                    )}
                </ToggleButtonGroup>
            )}
        </>
    );
}
