import { ApiClient } from "../../../lib/ApiClient";
import { useGenericSWR } from "../../../lib/useGenericSWR";
import * as Yup from 'yup';

export const rateClassApiEndpoint = 'api/v1/admin/RateClass';
export const rateClassTreeApiEndpoint = 'api/v1/admin/RateClass/Tree';

let formIteration = 0;

const getRateClassesDefaultItem = () => {
    formIteration = formIteration + 1;
    return {
        formIteration: "new" + formIteration,
        id: 0,
        name: '',
        description: '',
        status: 'ACTIVE',
        classification: 'RESIDENTIAL',
        commodity: 'ELECTRIC',
        createdBy: 'REACT_FE',
        updatedBy: 'REACT_FE',
        allowComparison: false
    }
};

const rateClassesValidationSchema = Yup.object().shape({
    name: Yup.string()
        .required("Required")
        .max(256, 'Max of 256 characters'),
    description: Yup.string()
        .max(1028, 'Max of 1028 characters').nullable(),
    status: Yup.string()
        .required("Required")
        .max(64, 'Max of 64 characters'),
    classification: Yup.string()
        .required("Required")
        .max(64, 'Max of 64 characters'),
    commodity: Yup.string()
        .required("Required")
        .max(64, 'Max of 64 characters'),
});

const rateClassesName = "Rate Classes";
const rateClassName = "Rate Class";

// See definition from RateClass.cs
const apiClient = new ApiClient();
apiClient.getDefaultItem = () => getRateClassesDefaultItem();
apiClient.getObjectName = () => {
    return rateClassesName;
};

const useRateClass = (id, swrOptions = {}) => {
    // SWR will prevent duplicate requests
    // It will also maintain data state as long as your are using the same urls for your requests
    return useGenericSWR(rateClassApiEndpoint + "/" + id, apiClient, { ...swrOptions });
};

const useRateClasses = (filter, swrOptions = {}) => {
    // SWR will prevent duplicate requests
    // It will also maintain data state as long as your are using the same urls for your requests
    const params = new URLSearchParams();
    params.append('filter', JSON.stringify(filter));
    return useGenericSWR(rateClassApiEndpoint + "?" + params.toString(), apiClient, { ...swrOptions });
};

const useRateClassTrees = (filter, swrOptions = {}) => {
    // SWR will prevent duplicate requests
    // It will also maintain data state as long as your are using the same urls for your requests
    const params = new URLSearchParams();
    params.append('filter', JSON.stringify(filter));
    return useGenericSWR(rateClassTreeApiEndpoint + "?" + params.toString(), apiClient, { ...swrOptions });
};

const saveRateClass = (RateClass) => {
    if (RateClass.id === 0) {
        return insertRateClass(RateClass);
    }
    return updateRateClass(RateClass);
}
const insertRateClass = (RateClass) => {
    return apiClient.poster(rateClassApiEndpoint, RateClass);
};

const updateRateClass = (RateClass) => {
    return apiClient.putter(rateClassApiEndpoint, RateClass);
};

const deleteRateClass = (RateClass) => {
    return apiClient.deleter(rateClassApiEndpoint + "/" + RateClass.id);
};

export {
    rateClassName,
    rateClassesName,
    useRateClass,
    useRateClasses,
    useRateClassTrees,
    saveRateClass,
    insertRateClass,
    updateRateClass,
    deleteRateClass,
    rateClassesValidationSchema,
    getRateClassesDefaultItem,
};